import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Card, FormControlLabel, Checkbox } from '@mui/material';
import GenericTable from '../GenericTable';
import LoaderInline from '../LoaderInline';
import APIService from '../../services/APIService';
import { get, uniq, compact, map, isEqual, startCase, includes, findIndex } from 'lodash';
import { getSelectedFarm } from '../../actions/api/farms';
import { receiveFarm } from '../../actions/companies/farms';
import { receiveStorageLoads, getStorageLoads } from '../../actions/companies/storages';
import StorageLoadsCard from './StorageLoadsCard';
import { getCountryLabel, isSystemCompany, isObserver, showTargetMoistureTab, currentUser, getPageCache, validateUnitForStockOperation } from '../../common/utils';
import {
  getSpecColumns, getLoadsWithBalanceWithoutUnshrunkTonnage, numberFormaterByLoadType, numberFormatterByValue,
  loadReferencesDisplay, getLoadReferences, getLoadsWithBalanceWithTargetMoistureTonnage
} from './utils';
import {
  setHeaderText, setBreadcrumbs, forceStopLoader
} from '../../actions/main';
import CreateStorageInload from '../../containers/CreateStorageInload';
import UpdateInload from '../../containers/UpdateInload';
import CreateStorageOutload from '../../containers/CreateStorageOutload';
import UpdateOutload from '../../containers/UpdateOutload';
import SideDrawer from '../common/SideDrawer';
import CommonListingButton from '../common/CommonListingButton';
import {
  showHideTitleTransferSideDrawer,
  showViewTitleTransferSideDrawer
} from '../../actions/companies/contracts';
import TitleTransferDetails from '../../components/title-transfers/TitleTransferDetails';
import {
  receiveTitleTransfer,
  getSelectedTitleTransfer
} from '../../actions/companies/contracts';
import StorageToStorageTransferDialog from './StorageToStorageTransferDialog';
import StockSwapDialog from './StockSwapDialog';
import { OPTION_TYPE_STOCK_EMPTY_LOAD, OPTION_TYPE_STORAGE_EMPTY_LOAD, STORAGE_STOCK_EMPTY_UPDATE_OPTION_TYPES } from '../../common/constants';
import ManageStorageAndOwnershipStocks from './ManageStorageAndOwnershipStocks';

const MULTIPLE = 'Multiple';
const netWeightFormatter = load => numberFormaterByLoadType(load, 'tonnage');
const balanceFormatter = load => numberFormatterByValue(load, 'balance');
const quantityFormatter = load => numberFormaterByLoadType(load, 'quantity');
const quantityBalanceFormatter = load => numberFormatterByValue(load, 'quantityBalance');


const getColumns = (commodity, storage) => {
  const specColumns = commodity ? getSpecColumns(commodity, 'specs') || [] : [];
  const unit = commodity?.requestedUnit || currentUser()?.unit
  const quantityLabel = `${getCountryLabel('tonnage')} (${unit})`
  const balanceColumns =  [{header: `Balance (${unit})`, formatter: balanceFormatter, className: 'small'},];
  let columns = [
    {header: 'Date & Time', key: 'date'},
    {header: 'Reference(s)', formatter: loadReferencesDisplay, default: getLoadReferences},
    {header: 'Freight Provider', key: 'freightProvider', className: 'small'},
    {header: getCountryLabel('rego'), key: 'rego'},
    {header: 'Season', key: 'season', className: 'xsmall'},
    {header: 'NGR', key: 'ngr', className: 'medium'},
    {header: 'Variety', key: 'varietyName'},
    {header: 'Grade', key: 'gradeName', className: 'xsmall'},
    ...specColumns,
    {header: quantityLabel, formatter: netWeightFormatter, className: 'medium'},
    ...balanceColumns,
    {header: 'User', key: 'createdBy'},
  ];
  if (showTargetMoistureTab() && storage?.type !== 'container' && includes(storage?.targetMoistureCommodityIds || [], commodity?.id)) {
    const index = findIndex(columns, column => column.header === quantityLabel)
    columns.splice(index, 1, {header: `${getCountryLabel('tonnage')} (${unit})`, key: 'tonnage', className: 'medium'})
    columns.splice(index + 1, 0, {header: `${getCountryLabel('tonnage')} With Shrinkage (${unit})`, className: 'small', formatter: load => numberFormaterByLoadType(load, 'tonnageWithTargetMoisture')})
  }
  if (commodity?.isQuantityBased) {
    columns.splice(10, 0, {header: 'Quantity', key: 'quantity', formatter: quantityFormatter, className: 'small'});
    columns.splice(11, 0, {header: 'Quantity Balance', key: 'quantityBalance', formatter: quantityBalanceFormatter, className: 'small'});
  }
  return columns;
};

const getContainerColumns = commodity => {
  const specColumns = commodity ? getSpecColumns(commodity, 'specs') : [];
  const balanceColumns =  [{header: 'Balance', formatter: balanceFormatter, className: 'small'},];
  return [
    {header: 'Date & Time', key: 'date'},
    {header: 'Reference(s)', formatter: loadReferencesDisplay, default: getLoadReferences},
    {header: 'Season', key: 'season', className: 'xsmall'},
    {header: 'NGR', key: 'ngr', className: 'medium'},
    {header: 'Variety', key: 'varietyName'},
    {header: 'Grade', key: 'gradeName', className: 'xsmall'},
    ...specColumns,
    {header: 'Tonnage', formatter: netWeightFormatter, className: 'medium'},
    ...balanceColumns,
    {header: 'User', key: 'createdBy'},
  ];
};

class StorageLoads extends React.Component {
  constructor(props) {
    super(props);
    this.setQueryParamsInContext(props);
    this.orderedLoads = false;

    this.state = {
      includeVoid: false,
      openTitleTransferSideDrawer: false,
      isAddLoadSideDrawerOpened: false,
      isEditLoadSideDrawerOpened: false,
      handleSiloToSiloTransfer: false,
      handleStocksSwap: false,
      load: null,
      movement: null,
      ownerId: this.ownerId,
      storageId: this.storageId,
      farmId: this.farmId,
      ngrId: this.ngrId,
      season: this.season,
      commodityId: this.commodityId,
      gradeId: this.gradeId,
      commodity: null,
      grade: null,
      variety: null,
      storage: null,
      loads: null,
      summary: null,
      loadType: null,
      isLoaded: false,
      isFetchingStorage: false,
      isFetchingCommodity: false,
      manageStorageOwnershipSideDrawerOpened: false
    };
    this.openAddLoadSideDrawer = this.openAddLoadSideDrawer.bind(this);
    this.closeAddLoadSideDrawer = this.closeAddLoadSideDrawer.bind(this);
    this.closeEditLoadSideDrawer = this.closeEditLoadSideDrawer.bind(this);
  }

  closeSideDraw = () => {
    this.props.showHideTitleTransferSideDrawer(false);
    this.props.showViewTitleTransferSideDrawer(false);
    this.setState({ openTitleTransferSideDrawer: false, });
  };

  setQueryParamsInContext(props) {
    if(!props)
      return;
    const { match } = props;
    const queryParams = this.getQueryParams(props);
    this.farmId = parseInt(queryParams.get('farmId') || get(match, 'params.farm_id')) || null;
    this.ownerId = parseInt(queryParams.get('ownerId')) || null;
    this.storageId = parseInt(get(match, 'params.storage_id') || queryParams.get('storageId')) || null;
    this.season = queryParams.get('season') || null;
    this.gradeId = parseInt(queryParams.get('gradeId')) || null;
    this.commodityId = parseInt(queryParams.get('commodityId')) || null;
    this.ngrId = parseInt(queryParams.get('ngrId')) || null;
  }

  getQueryParams(props) {
    props = props || this.props;
    const { location } = props;
    return new URLSearchParams(get(location, 'search'));
  }

  componentDidMount() {
    this.fetchDataIfNeeded();
    this.fetchLoads();
    this.props.dispatch(forceStopLoader())
  }

  componentDidUpdate(prevProps) {
    const breadcrumbs = this.getBreadcrumbs();
    const headerText = this.getHeaderText();
    if(!isEqual(breadcrumbs, this.props.breadcrumbs))
      this.props.dispatch(setBreadcrumbs(breadcrumbs));
    if(!isEqual(headerText, this.props.headerText))
      this.props.dispatch(setHeaderText(headerText));
    this.fetchStorageIfNeeded();
    if(prevProps.farm?.id !== this.props.farm?.id) {
      this.fetchFarmIfNeeded();
    }
    this.fetchCommodityIfNeeded();
    this.fetchGradeIfNeeded();
    this.fetchVarietyIfNeeded();
    if (this.props.storageLoads !== prevProps.storageLoads) {
      this.setState({isLoaded: true, loads: this.props.paginationData?.results || [], summary: this.props.paginationData?.summary}, () => this.props.dispatch(forceStopLoader()))
    }
  }

  getReturnTo() {
    const searchParams = this.props.location.search;

    if(searchParams) {
      const queryParams = queryString.parse(searchParams);
      return get(searchParams.split('returnTo='), '[1]') || get(queryParams, 'returnTo');
    }
  }

  getBreadcrumbs() {
    const { farm, breadcrumbs } = this.props;
    const { storage } = this.state;
    if(farm && storage)
      return [
        {text: 'Stocks', route: '/stocks'},
        {text: farm.name, route: this.getReturnTo()},
        {text: `${storage.name} Loads`},
      ];
    return breadcrumbs;
  }

  getHeaderText() {
    const { farm, headerText } = this.props;
    if(farm)
      return farm.name;
    return headerText;
  }

  fetchDataIfNeeded() {
    this.fetchStorageIfNeeded();
    this.fetchFarmIfNeeded();
    this.fetchCommodityIfNeeded();
    this.fetchGradeIfNeeded();
    this.fetchVarietyIfNeeded();
  }

  getQueryStringFromState() {
    const { ngrId, gradeId, season, commodityId, includeVoid } = this.state;
    let query = '?';
    if(commodityId)
      query += `commodity_id=${commodityId}&`;
    if(ngrId && ngrId.toString().toLowerCase() !== MULTIPLE.toLowerCase())
      query += `ngr_id=${ngrId}&`;
    if(season && season.toLowerCase() !== MULTIPLE.toLowerCase())
      query += `season=${season}&`;
    if(gradeId && gradeId.toString().toLowerCase() !== MULTIPLE.toLowerCase())
      query += `grade_id=${gradeId}&`;
    if(includeVoid)
      query += 'include_void=true&'
    query += 'summary=true';
    return query;
  }

  toggleVoid = () => this.setState({includeVoid: !this.state.includeVoid, isLoaded: false}, this.fetchLoads)

  fetchFarmIfNeeded() {
    const { farmId } = this.state;
    const { farm, fetchFarm } = this.props;
    if((!farm || farm.id !== farmId) && farmId)
      fetchFarm(farmId);
  }

  fetchCommodityIfNeeded() {
    const { commodityId, commodity } = this.state;
    if((!commodity || commodity.id !== commodityId) && commodityId && !this.state.isFetchingCommodity)
      this.setState({isFetchingCommodity: true}, () => this.fetchCommodity());
  }

  fetchGradeIfNeeded() {
    const { gradeId, grade } = this.state;
    if((!grade || grade.id !== gradeId) && gradeId)
      this.fetchGrade();
  }

  fetchVarietyIfNeeded() {
    const { varietyId, variety } = this.state;
    if((!variety || variety.id !== varietyId) && varietyId)
      this.fetchVariety();
  }

  fetchStorageIfNeeded() {
    const { storageId, storage } = this.state;
    if((!storage || storage.id !== storageId) && storageId && !this.state.isFetchingStorage)
      this.setState({isFetchingStorage: true}, () => this.fetchStorage());
  }

  fetchCommodity() {
    const { commodityId } = this.state;
    APIService.commodities(commodityId).get().then(
      commodity => this.setState({commodity: commodity, isFetchingCommodity: false})
    );
  }

  fetchGrade() {
    const { gradeId } = this.state;
    APIService.commodities().grades(gradeId).get().then(
      grade => this.setState({grade: grade})
    );
  }

  fetchVariety() {
    const { varietyId } = this.state;
    APIService.commodities().varieties(varietyId).get().then(
      variety => this.setState({variety: variety})
    );
  }

  fetchStorage() {
    const { storageId } = this.state;
    APIService.storages(storageId).minimal().get().then(
      storage => this.setState({storage: storage, isFetchingStorage: false})
    );
  }

  fetchLoads() {
    const { storageId } = this.state;
    this.getFetchLoadsService().get().then(response => {
        this.props.dispatch(receiveStorageLoads(response, storageId))
        this.setState({isLoaded: true, loads: response?.results || [], summary: response?.summary}, () => this.props.dispatch(forceStopLoader()))
      });
  }

  getFetchLoadsService = (pageSize, page, searchStr) => {
    let queryString = this.getQueryStringFromState();
    const { storageId } = this.state;
    const cache = getPageCache();
    const cachedPageSize = pageSize || get(cache, 'pageSize');
    const cachedPage = page || get(cache, 'page');
    let joiner = '&';
    if(cachedPageSize)
      queryString += `${joiner}page_size=${cachedPageSize}`;
    if(cachedPage)
      queryString += `${joiner}page=${cachedPage}`;
    if(searchStr)
      queryString += `${joiner}searchStr=${searchStr}`

    return APIService.storages(storageId).loads().appendToUrl(`minimal/${queryString}`)
  }

  getSeasonText() {
    return this.state.season || this.getAttrFromLoads('season');
  }

  getAttrFromLoads(attr) {
    const { loads } = this.state;
    if(this.state.loads) {
      const values = uniq(compact(map(loads, attr)));
      if(values.length > 1)
        return MULTIPLE;
      return values[0];
    }
  }

  openAddLoadSideDrawer(type, callback) {
    if (validateUnitForStockOperation()) {
      this.setState({
        isAddLoadSideDrawerOpened: true, isEditLoadSideDrawerOpened: false,
        loadType: type
      }, () => {
        if(callback)
          callback();
      });
    }
  }

  closeAddLoadSideDrawer() {
    this.setState({ isAddLoadSideDrawerOpened: false, loadType: null });
  }

  closeEditLoadSideDrawer() {
    this.setState({ isEditLoadSideDrawerOpened: false, handleStocksSwap: false,
      load: null, movement: null, loadType: null, handleSiloToSiloTransfer: false });
  }

  getStorageColumns(commodity, storage) {
    return get(storage, 'type') === 'container' ? getContainerColumns(commodity) : getColumns(commodity, storage);
  }

  shouldConsiderTonnageWithTargetMoisture() {
    return showTargetMoistureTab() && this.state.storage?.type !== 'container' && includes(this.state.storage?.targetMoistureCommodityIds || [], this.state.commodityId)
  }

  handleDefaultCellClick = load => {
    if (load.freightMovementId)
      window.open(`#/freights/movements/${load.freightMovementId}/details`);
    else if (load.titleTransferNumber && validateUnitForStockOperation()) {
      this.setState({
        openTitleTransferSideDrawer: true
      }, () => {
        this.props.dispatch(getSelectedTitleTransfer(load.titleTransferId, receiveTitleTransfer, false));
      });
    }
    else if (load.optionType == -3 && validateUnitForStockOperation())
      this.setState({
        openTitleTransferSideDrawer: false,
        isEditLoadSideDrawerOpened: false,
        isAddLoadSideDrawerOpened: false,
        handleSiloToSiloTransfer: true,
        manageStorageOwnershipSideDrawerOpened: false,
        handleStocksSwap: false,
        load: load,
        loadType: load.type,
      });
    else if (load.optionType == -4 && validateUnitForStockOperation())
      this.setState({
        openTitleTransferSideDrawer: false,
        isEditLoadSideDrawerOpened: false,
        isAddLoadSideDrawerOpened: false,
        handleSiloToSiloTransfer: false,
        manageStorageOwnershipSideDrawerOpened: false,
        handleStocksSwap: true,
        load: load,
        loadType: load.type,
      });
    else if ((window.MANAGE_OWNERSHIP_STORAGES || isSystemCompany() || isObserver()) && includes(STORAGE_STOCK_EMPTY_UPDATE_OPTION_TYPES, load.optionType) && validateUnitForStockOperation())
      this.setState({
        openTitleTransferSideDrawer: false,
        isEditLoadSideDrawerOpened: false,
        isAddLoadSideDrawerOpened: false,
        handleSiloToSiloTransfer: false,
        manageStorageOwnershipSideDrawerOpened: true,
        handleStocksSwap: false,
        load: load,
        loadType: load.type,
      });
    else if (validateUnitForStockOperation())
      this.setState({
        openTitleTransferSideDrawer: false,
        isEditLoadSideDrawerOpened: true,
        isAddLoadSideDrawerOpened: false,
        manageStorageOwnershipSideDrawerOpened: false,
        load: load,
        loadType: load.type,
      });

  };

  render() {
    const { commodity, loads, loadType, load, isLoaded, storage, includeVoid } = this.state;
    const columns = this.getStorageColumns(commodity, storage);
    const isContainer = get(storage, 'type') === 'container';
    const isExternallySyncSourceSite = get(this.props, 'farm.externallySyncSource');
    return (
      <div>
        <StorageLoadsCard
          {...this.state}
          ngrText={this.getAttrFromLoads('ngr')}
          seasonText={this.getAttrFromLoads('season')}
          gradeText={this.getAttrFromLoads('gradeName')}
          varietyText={this.getAttrFromLoads('varietyName')}
        />
        <Card style={{marginTop: '10px', padding: '10px'}}>
          <div>
            <div style={{textAlign: 'right'}}>
              {
                !isContainer &&
                  <FormControlLabel
                    size='small'
                    label="Show Void"
                    control={
                      <Checkbox
                        size='small'
                        checked={includeVoid}
                        onChange={this.toggleVoid}
                        disabled={!isLoaded}
                      />
                    }
                  />
              }
              {
                !isContainer && !isExternallySyncSourceSite &&
                  <CommonListingButton
                    style={{zIndex: 1}}
                    showMenus={true}
                    showDownLoadIcon={false}
                    optionMapper={[
                      {name: 'Add Inload', fx: callback => this.openAddLoadSideDrawer('inload', callback)},
                      {name: 'Add Outload', fx: callback => this.openAddLoadSideDrawer('outload', callback)},
                    ]}
                    title='Add Loads'
                    name='Add Loads'
                  />
              }
            </div>
            {
              isLoaded ?
                <div className="col-xs-12 padding-reset" style={!isContainer ? {marginTop: '-38px'} : {}}>
              <React.Fragment>
                <GenericTable
                  columns={columns}
                  items={this.shouldConsiderTonnageWithTargetMoisture() ? getLoadsWithBalanceWithTargetMoistureTonnage(loads) : getLoadsWithBalanceWithoutUnshrunkTonnage(loads)}
                  orderBy={['dateTime', 'id']}
                  order={["desc", "asc"]}
                  handleDefaultCellClick={this.handleDefaultCellClick}
                  rowHighlightedMap={
                    {"void": 'void-table-row'}
                  }
                  globalSearch
                  noCache
                  hardClear
                  displayIDColumn='Reference(s)'
                  { ...this.props }
                />
              </React.Fragment>
              </div>
             : <LoaderInline containerClassName="inline-loader-container" />
            }
          </div>
        </Card>
        {
          loadType === 'inload' &&
          <SideDrawer
            isOpen={this.state.isAddLoadSideDrawerOpened}
            title={`Add ${startCase(loadType)}`}
            size="big"
            onClose={this.closeAddLoadSideDrawer}
            app="load"
            >
            <CreateStorageInload
              companyId={this.props.companyId}
              farmId={this.props.farmId}
              storageId={this.props.storageId}
              closeDrawer={this.closeAddLoadSideDrawer}
              showNgrField={this.props.showNgrField}
              isCreate={true}
            />
          </SideDrawer>
        }
        {
          loadType === 'inload' &&
          <SideDrawer
            isOpen={this.state.isEditLoadSideDrawerOpened}
            title={`Edit ${startCase(loadType)}`}
            size="big"
            onClose={this.closeEditLoadSideDrawer}
            app="load"
            >
            <UpdateInload
              companyId={this.props.companyId}
              farmId={this.props.farmId}
              storageId={this.props.storageId}
              inload={load}
              closeDrawer={this.closeEditLoadSideDrawer}
              showNgrField={this.props.showNgrField}
              isCreate={false}
            />
          </SideDrawer>
        }
        {
          loadType === 'outload' &&
          <SideDrawer
            isOpen={this.state.isAddLoadSideDrawerOpened}
            title={`Add ${startCase(loadType)}`}
            size="big"
            onClose={this.closeAddLoadSideDrawer}
            app="load"
            >
            <CreateStorageOutload
              companyId={this.props.companyId}
              farmId={this.props.farmId}
              storageId={this.props.storageId}
              closeDrawer={this.closeAddLoadSideDrawer}
              showNgrField={this.props.showNgrField}
              isCreate={true}
            />
          </SideDrawer>
        }
        {
          loadType === 'outload' &&
          <SideDrawer
            isOpen={this.state.isEditLoadSideDrawerOpened}
            title={`Edit ${startCase(loadType)}`}
            size="big"
            onClose={this.closeEditLoadSideDrawer}
            app="load"
            >
            <UpdateOutload
              companyId={this.props.companyId}
              farmId={this.props.farmId}
              storageId={this.props.storageId}
              outload={load}
              closeDrawer={this.closeEditLoadSideDrawer}
              showNgrField={this.props.showNgrField}
              isCreate={false}
            />
          </SideDrawer>
        }
        {
          this.props.isViewTitleTransferSideDrawerOpened &&
          <SideDrawer
            isOpen={this.state.openTitleTransferSideDrawer}
            title="Title Transfer"
            onClose={this.closeSideDraw}
            size='big'>
            <TitleTransferDetails onClose={this.closeSideDraw} />
          </SideDrawer>
        }
        {this.state.handleSiloToSiloTransfer &&
          <StorageToStorageTransferDialog
            item={load}
            isEdit={true}
            isOpen={this.state.handleSiloToSiloTransfer}
            toggleDialog={this.closeEditLoadSideDrawer} />
        }
        {this.state.handleStocksSwap &&
          <SideDrawer isOpen={this.state.handleStocksSwap} title="Stock Swap" size='small' onClose={this.toggleUpdateStorageDialog}>
            <StockSwapDialog
              isEdit={true}
              token={this.props.token}
              item={this.state.item}
              isOpen={this.state.handleStocksSwap}
              toggleDialog={this.toggleUpdateStorageDialog}
            />
          </SideDrawer>
        }
        {this.state.manageStorageOwnershipSideDrawerOpened &&
        <SideDrawer
          isOpen={this.state.manageStorageOwnershipSideDrawerOpened}
          title={includes([OPTION_TYPE_STOCK_EMPTY_LOAD, OPTION_TYPE_STORAGE_EMPTY_LOAD], load.optionType) ? "Stock Empty" : "Stock Update"}
          size='big'
          onClose={() => this.setState({manageStorageOwnershipSideDrawerOpened: false})}
        >
          <ManageStorageAndOwnershipStocks load={load} onClose={() => this.setState({manageStorageOwnershipSideDrawerOpened: false})}/>
        </SideDrawer>
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  farm: state.companies.farms.selectedFarm,
  breadcrumbs: state.main.breadcrumbs,
  headerText: state.main.headerText,
  isViewTitleTransferSideDrawerOpened: state.companies.contracts.isViewTitleTransferSideDrawerOpened,
  storageLoads: state.companies.systemStorages.storageLoads,
  paginationData: state.companies.systemStorages.paginationData,
});

const mapDispatchToProps = dispatch => ({
  showHideTitleTransferSideDrawer: (flag) => dispatch(showHideTitleTransferSideDrawer(flag)),
  showViewTitleTransferSideDrawer: (flag) => dispatch(showViewTitleTransferSideDrawer(flag)),
  fetchFarm: (farmId) => dispatch(getSelectedFarm(farmId, receiveFarm)),
  navigateTo: url => dispatch(getStorageLoads(url, true)),
  changePageSize: (url, pageSize) => {
    if (includes(url, '?')){
      url = `${url}&page_size=${pageSize}`;
    } else {
      url = `${url}?page_size=${pageSize}`;
    }
    dispatch(getStorageLoads(url, true));
  },
  dispatch,
});


export default connect(mapStateToProps, mapDispatchToProps)(StorageLoads);
