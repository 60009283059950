import React from 'react';
import CommonTextField from '../common/CommonTextField';
import CommonButton from '../common/CommonButton';

const NGRField = ({label, helperText, onBlur, onChange, value, enableFetchNgrDetails, isFetchNgrDisabled, onNgrFetchNgrClick}) => {

  return (
    <React.Fragment>
    <CommonTextField
    id="ngrNumber"
    label={label}
    type="number"
    placeholder='Please enter 8 digits NGR number'
    value={value}
    helperText={helperText}
    onBlur={onBlur}
    onChange={onChange}
    fullWidth
    />
    {
        enableFetchNgrDetails &&
        <CommonButton
            type='button'
            label='Fetch NGR Details'
            variant='text'
            size="small"
            default
            color="primary"
            style={{margin: "5px 10px", minWidth: "100px", marginLeft: "0px", marginTop: "10px", textTransform: "none"}}
            disabled={isFetchNgrDisabled}
            onClick={onNgrFetchNgrClick}
        />
    }
    </React.Fragment>
  )
}

export default NGRField;