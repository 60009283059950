import React from 'react';
import { Button, Tooltip } from '@mui/material'
import APIService from '../../services/APIService'
import alertifyjs from 'alertifyjs'

const ImpexDocsConnect = props => {
    const onClick = () => {
      if (props.companyId) {
          APIService.companies(props.companyId).appendToUrl('impex-docs/authorize/').post({
            clientId: props.clientId,
            clientSecret: props.clientSecret
          }).then(res => {
            if (res?.isConnected)
              alertifyjs.success('ImpexDocs connected successfully', window.location.reload());
            else
              alertifyjs.error(res?.error || 'ImpexDocs connection failed');
          })
      }
    }

  return (
    <Tooltip arrow title={props.title || 'Connect ImpexDocs'}>
      <Button
        disabled={props.disabled}
        size='large'
        variant='contained'
        onClick={onClick}
      >
        {props.label || 'Connect ImpexDocs'}
      </Button>
    </Tooltip>
  )
}

export default ImpexDocsConnect;
