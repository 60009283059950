import { connect } from 'react-redux';
import GenericTable from '../components/GenericTable';
import {
  invoicePaymentsResponse,
  getInvoicePayments,
  getInvoicePaymentSummary,
  INVOICE_PAYMENT_CONFIRMED,
  invoicePaymentRejectDialog,
  canVoidInvoicePayment
} from '../actions/companies/invoices';
import get from "lodash/get";
import includes from "lodash/includes";
import APIService from "../services/APIService";
import {currentUserToken, getInvoiceIdFromCurrentRoute} from '../common/utils';
import React from "react";
import Button from "@mui/material/Button";
import isEmpty from "lodash/isEmpty";
import alertifyjs from "alertifyjs";
import InvoicePaymentRejectForm from "../components/invoices/InvoicePaymentRejectForm";

const mapStateToProps = (state) => {
  const getAcceptRejectActions = (item, index, dispatch) => {
    if(item.confirmable) {
      return <div className="actions">
        <Button
          variant="outlined"
          color="primary"
          size="small"
          style={{marginRight: '8px'}}
          onClick={(event) => onAcceptClick(event, item, dispatch)}>
          Accept
        </Button>
        <Button
          variant="outlined"
          size="small"
          className="btn-red-outlined"
          onClick={(event) => onRejectClick(event, item, dispatch)}>
          Reject
        </Button>
        <InvoicePaymentRejectForm invoicePayment={item} />
      </div>;
    }
  };

  return {
    columns: [
      { key: 'paymentDate', header: 'Payment Date', className: 'small' },
      { key: 'paymentDetails', header: 'Payment Description'},
      { key: 'paidBy', header: 'Paid By'},
      { key: 'statusDisplayName', header: 'Payment Status', className: 'capitalize' },
      { key: 'amount', header: 'Amount', className: 'small', type: 'currency', },
      { urlKey: 'attachmentUrls', header: 'Attachment', fieldType: "url-conditional-multi", link: true,
        valueFunction: (item) => {
        return get(item, `attachmentUrls.0.name`);
      }},
      { key: 'rejectionReason', header: 'Rejection Reason', className: 'medium', },
      { key: 'source', header: 'Source', className: 'medium', valueFunction: item => {
        if(item?.xeroStatus && item?.xeroId)
          return `Xero: ${item?.xeroStatus}`
        return 'AgriChain'
      }},
    ],
    items: state.companies.invoices.invoicePayments,
    scrollToTopOnUpdate: false,
    handleDefaultCellClick: false,
    orderBy: 'paymentDate',
    order: 'desc',
    rowHighlightedMap: {'isHighlighted': 'delhi-blue'},
    clearSearch: invoicePaymentsResponse,
    customColumnGenerator: getAcceptRejectActions,
    customColumnClass: 'large',
    optionsItems: [{ key: 'void', text: 'Void' }],
    displayIDColumn: 'paymentDetails'
  };
};

export const onRejectClick = (event, item, dispatch) => {
  event.stopPropagation();
  event.preventDefault();
  dispatch(invoicePaymentRejectDialog(item, true));
};

export const onAcceptClick = (event, item, dispatch) => {
  event.stopPropagation();
  event.preventDefault();
  APIService.invoices().appendToUrl(`invoice-payments/${item.id}/confirm/`).put({}, currentUserToken())
    .then((json) => {
      if(!isEmpty(json.errors)){
        alertifyjs.error(json.errors[0]);
      } else {
        alertifyjs.success(INVOICE_PAYMENT_CONFIRMED);
        dispatch(getInvoicePaymentSummary(item.invoiceId));
        dispatch(getInvoicePayments(item.invoiceId));
      }
    });
};

const mapDispatchToProps = dispatch => {
  return {
    navigateTo: (url) => {
      dispatch(getInvoicePayments(null, url, true));
    },
    changePageSize: (url, pageSize) => {
      if (includes(url, '?')){
        url = `${url}&page_size=${pageSize}`;
      } else {
        url = `${url}?page_size=${pageSize}`;
      }
      dispatch(getInvoicePayments(null, url, true));
    },
    getSearchSortUrl: (pageSize, page, searchText, orderBy, order) => {
      const service = APIService.invoices(getInvoiceIdFromCurrentRoute()).appendToUrl('invoice-payments/');
      service.appendToUrl(`?page_size=${pageSize}`);
      if (page) {
        service.appendToUrl(`&page=${page}`);
      }
      if(searchText) {
        service.appendToUrl(`&search=${searchText}`);
      }
      if(orderBy) {
        service.appendToUrl(`&order_by=${orderBy}&order=${order}`);
      }

      return service.URL;
    },
    handleOptionClick: (index, key, id, item) => {
      if (key === 'void'){
        dispatch(canVoidInvoicePayment(item));
      }
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GenericTable);
