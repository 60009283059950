import React, { useEffect, useState  } from "react";
import { APIProvider, Map, AdvancedMarker, Pin,  useMap , useMapsLibrary} from "@vis.gl/react-google-maps";
import { get, map, isEqual } from "lodash";
import { GOOGLE_MAPS_API_KEY } from "../../common/constants";

const DEFAULT_LOCATION = {
  formatted_address: 'Australia',
  lat: -23.7001391,
  lng: 133.8810526,
};

const Directions = ({origin, finalDestination, wayPoints}) => {
  const map = useMap();
  const routesLibrary =  useMapsLibrary('routes');
  const [directionsService, setDirectionsService] = useState(null);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);

  useEffect(() => {
    if (!routesLibrary || !map) return;
    setDirectionsService(new routesLibrary.DirectionsService());
    setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map, suppressMarkers: true }));
  },[map, routesLibrary]);

  useEffect(() => {
    if (!directionsService || !directionsRenderer) return;
    if (!origin || !finalDestination) return;

    let finalDest = { lat: finalDestination.lat(), lng: finalDestination.lng() };
    let finalOrigin = { lat: origin.lat(), lng: origin.lng() };

    directionsService.route({
      origin: finalOrigin,
      destination: finalDest,
      travelMode: window.google.maps.TravelMode.DRIVING,
      waypoints : wayPoints
    }, (response, status) => {
      if (status !== window.google.maps.DirectionsStatus.OK)
        console.error(`error fetching directions ${response}`);
      else
        directionsRenderer.setDirections(response);
    });
  },[directionsService, directionsRenderer, origin, finalDestination, wayPoints]);
  return null;
}

const GoogleMapDirection = ({ source, destination, waypoints, slotStatus, slotType, movementStatus }) => {
  const [origin, setOrigin] = useState(null);
  const [finalDestination, setFinalDestination] = useState(null);
  const [wayPoints, setWayPoints] = useState([]);

  useEffect(() => {
    if (!window.google || !window.google.maps) return;

    let tempWayPoints = map(waypoints, point => ({location: new window.google.maps.LatLng(point.latitude, point.longitude)}))
    let tempOrigin;

    if (isEqual(slotType, "outload") && get(waypoints, "0") && isEqual(movementStatus, "confirmed")) {
      destination = source;
      this.setState({
        slotStatus
      })
    } else if (isEqual(slotType, "inload") && source && isEqual(movementStatus, "confirmed")) {
      tempWayPoints.push({location: new window.google.maps.LatLng(get(source, 'latitude'), get(source, 'longitude')), stopover: true});
    }

    tempOrigin = source
    ? new window.google.maps.LatLng(get(source, 'latitude'), get(source, 'longitude'))
    : new window.google.maps.LatLng(get(get(waypoints, '0'), 'latitude'), get(get(waypoints, '0'), 'longitude'));

    if(!destination)
      destination = get(waypoints, '0');

    destination= new window.google.maps.LatLng(get(destination, 'latitude'), get(destination, 'longitude'))

    setOrigin(tempOrigin);
    setFinalDestination(destination);
    setWayPoints(tempWayPoints);

  }, [source, destination, waypoints, slotType, movementStatus]);


  return (
    <div className="map">
          <div className="map-container" >
            <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
              <Map
                id="map-search-box"
                defaultCenter={{ lat: DEFAULT_LOCATION.lat, lng: DEFAULT_LOCATION.lng }}
                defaultZoom={7}
                mapId="DEMO_MAP_ID"
                mapTypeId="roadmap"
                draggableCursor={ "true"}
                draggable={true}
                style={{ height: "400px" }}
              >
                {origin && (
                  <AdvancedMarker position={origin}>
                    <Pin/>
                  </AdvancedMarker>
                )}
                {finalDestination && (
                    <AdvancedMarker position={finalDestination }>
                       <Pin />
                    </AdvancedMarker>
                )}
                { get(waypoints, '0') && (
                  <AdvancedMarker position={new window.google.maps.LatLng(get(waypoints, '0').latitude, get(waypoints, '0').longitude)}>
                    <img src="/truck_icon.svg" alt="Truck Icon" width="30" height="30" />
                  </AdvancedMarker>
                )}
                {origin && finalDestination && (<Directions origin={origin} finalDestination={finalDestination} wayPoints = {wayPoints}/>)}
              </Map>
            </APIProvider>
          </div>
    </div>
  );
};


export default GoogleMapDirection;
