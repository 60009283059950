import React from 'react';
import { connect } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import '../common/subTab.scss';

import CompanyCashPrices from './CompanyCashPrices';
import AllCashPrices from './AllCashPrices';
import ArchivedCashPrices from './ArchivedCashPrices';
import SiteArchivedCashPrices from './SiteArchivedCashPrices';
import { getTracks } from '../../actions/main/index';
import CompanySitesCashPrices from './CompanySitesCashPrices';
import { TAB } from './constants';
import { get, includes } from 'lodash';
class CashPrices extends React.Component {
  constructor(props) {
    super(props);

    this.MY_PRICES_URL = '/cash-board/cash-prices/active';
    this.CASH_PRICES_URL = '/cash-board/cash-prices';
    this.MY_PRICES_SITES_URL = '/cash-board/cash-prices/site-active-prices';
    this.ARCHIVED_PRICES_URL = '/cash-board/cash-prices/archived';
    this.SITE_ARCHIVED_PRICES_URL = '/cash-board/cash-prices/site';
    this.TABS = {
      1: this.CASH_PRICES_URL,
      2: this.MY_PRICES_URL,
      3: this.MY_PRICES_SITES_URL,
      4: this.ARCHIVED_PRICES_URL,
      5: this.SITE_ARCHIVED_PRICES_URL,
      6: this.SITE_ARCHIVED_PRICES_URL,
    }
    this.state = {
      value: '#' + this.props.location.pathname,
    };
  }

  getParentURL() {
    let url = this.props.location.pathname;
    if(includes(this.props.location.pathname, this.MY_PRICES_URL))
      return url.split(this.MY_PRICES_URL)[0];
    if(includes(this.props.location.pathname, this.CASH_PRICES_URL))
      return url.split(this.CASH_PRICES_URL)[0];
    if(includes(this.props.location.pathname, this.MY_PRICES_SITES_URL))
      return url.split(this.MY_PRICES_SITES_URL)[0];
    if(includes(this.props.location.pathname, this.ARCHIVED_PRICES_URL))
      return url.split(this.ARCHIVED_PRICES_URL)[0];
    if(includes(this.props.location.pathname, this.SITE_ARCHIVED_PRICES_URL))
      return url.split(this.SITE_ARCHIVED_PRICES_URL)[0];
  }

  componentDidMount() {
    this.props.dispatch(getTracks());
    if (this.props.selectedTab != TAB.ALL_CASH_PRICE)
      this.setState({ value: get(this.TABS, this.props.selectedTab) });
  }

  getURL(pathname) {
    const parentURL = this.getParentURL();
    return '#' + parentURL + pathname;
  }

  tabChanges = (event, value) => {
    this.setState({ value });
    window.location.hash = value;
  };

  render() {
    const { value } = this.state;
    const myPricesUrl = this.getURL(this.MY_PRICES_URL);
    const cashPricesUrl = this.getURL(this.CASH_PRICES_URL);
    const myPricesSitesUrl = this.getURL(this.MY_PRICES_SITES_URL);
    const archivedPricesUrl = this.getURL(this.ARCHIVED_PRICES_URL);
    const siteArchivedPricesUrl = this.getURL(this.SITE_ARCHIVED_PRICES_URL);
    return (
      <div className="subTab">
        <Tabs className="subTab-header" value={value} indicatorColor="primary" onChange={this.tabChanges}>
          <Tab value={cashPricesUrl} label="Cash Prices" className={value !== cashPricesUrl ? 'unselected-subtab' : ''} />
          <Tab value={myPricesUrl} label="My Active Prices" className={value !== myPricesUrl ? 'unselected-subtab' : ''} />
          <Tab value={myPricesSitesUrl}  label="Active Cash Prices (Site)" className={value !== myPricesSitesUrl ? 'unselected-subtab' : ''} />
          <Tab value={archivedPricesUrl} label="My Archived Prices" className={value !== archivedPricesUrl ? 'unselected-subtab' : ''} />
          <Tab value={siteArchivedPricesUrl} label="Site Archived Prices" className={value !== siteArchivedPricesUrl ? 'unselected-subtab' : ''} />
        </Tabs>
        {
          value === cashPricesUrl && <AllCashPrices {...this.props} />
        }
        {
          value === myPricesUrl && <CompanyCashPrices {...this.props} />
        }
        {
          value === myPricesSitesUrl && <CompanySitesCashPrices {...this.props} />
        }
        {
          value === archivedPricesUrl && <ArchivedCashPrices {...this.props} />
        }
        {
          value === siteArchivedPricesUrl && <SiteArchivedCashPrices {...this.props} />
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.main.user.user,
    breadcrumbs: state.main.breadcrumbs,
    headerText: state.main.headerText,
    selectedTab: state.companies.cashBoard.selectedTab,
  };
};

export default connect(mapStateToProps)(CashPrices);
