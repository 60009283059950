import React from 'react';
import moment from 'moment'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';

import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import EditIcon from '@mui/icons-material/Edit';

import isArray from 'lodash/isArray'
import map from 'lodash/map'
import without from 'lodash/without'
import isEmpty from 'lodash/isEmpty'

import APIService from '../../services/APIService'
import SideDrawer from './SideDrawer';
import LoaderInline from '../LoaderInline'

const ENTITIES = [
  {id: 'freights/contracts', label: 'Movement'},
  {id: 'freights/orders', label: 'Order'},
  {id: 'contracts', label: 'Contract'},
  {id: 'contracts/title-transfer', label: 'Title Transfer'},
  {id: 'loads', label: 'Load'},
  {id: 'invoices', label: 'Invoice'},
  {id: 'vendor_decs', label: 'Vendor Declaration'},
  {id: 'companies', label: 'Company'},
  {id: 'profiles', label: 'Employee'},
  {id: 'ngrs', label: 'NGR'},
  {id: 'trucks', label: 'Truck'},
  {id: 'farms', label: 'Farm/Site'},
  {id: 'storages', label: 'Storage'},
  {id: 'farm_fields', label: 'Farm Field'},
  {id: 'company_sites/slots', label: 'Slot'},
  {id: 'alerts', label: 'Alert'},
]

const Snapshot = ({ snapshot }) => {
  return isEmpty(snapshot) ? null : (
    <div className='col-xs-12' style={{backgroundColor: 'rgba(0, 0, 0, 0.05)', padding: '8px', marginTop: '8px', borderRadius: '4px'}}>
      <Typography
        sx={{ flex: '1 1 100%', fontSize: '16px' }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Snapshot
      </Typography>

      <TableContainer sx={{maxHeight: '300px'}}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>
                Field
              </TableCell>
              <TableCell>
                Value
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              map(snapshot, (value, key) => (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{JSON.stringify(value, undefined, 2)}</TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

const Diff = ({ diff }) => {
  return isEmpty(diff) ? null : (
    <div className='col-xs-12' style={{backgroundColor: 'rgba(0, 0, 0, 0.05)', padding: '8px', marginTop: '8px', borderRadius: '4px'}}>
      <Typography
        sx={{ flex: '1 1 100%', fontSize: '16px' }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Diff
      </Typography>

      <TableContainer sx={{maxHeight: '300px'}}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>
                Field
              </TableCell>
              <TableCell>
                Old
              </TableCell>
              <TableCell>
                New
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              map(diff, (values, key) => (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{JSON.stringify(values.old, undefined, 2)}</TableCell>
                  <TableCell>{JSON.stringify(values['new'], undefined, 2)}</TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}


const HistoryDrawer = () => {
  const [open, setOpen] = React.useState(false)
  const [entity, setEntity] = React.useState('')
  const [entityId, setEntityId] = React.useState('')
  const [events, setEvents] = React.useState(false)
  const [badResponse, setBadResponse] = React.useState(false)
  const [diffs, setDiffs] = React.useState([])
  const [snapshots, setSnapshots] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)

  const getKey = event => `${event.entity}-${event.history_id}`
  const toggle = () => setOpen(!open)
  const toggleDiff = event => {
    const key = getKey(event)
    setDiffs(diffs.includes(key) ? [...without(diffs, key)] : [...diffs, key])
  }
  const toggleSnapshot = event => {
    const key = getKey(event)
    setSnapshots(snapshots.includes(key) ? [...without(snapshots, key)] : [...snapshots, key])
  }

  const listenKey = event => {
    const isCtrlO = event.keyCode === 79 && event.ctrlKey;

    if (!isCtrlO)
      return;
    toggle()
  };

  React.useEffect(() => {
    document.body.addEventListener("keydown", listenKey)
    return () => document.body.removeEventListener("keydown", listenKey)
  }, [])

  const fetchEvents = () => {
    if(entity?.id && entityId) {
      setIsLoading(true)
      setBadResponse(false)
      setEvents(false)
      APIService.new().overrideURL('/' + entity.id + '/').appendToUrl(entityId + '/history/').get().then(response => {
        if(isArray(response)) {
          setEvents(response)
        } else {
          setBadResponse(response)
        }
        setIsLoading(false)
      })
    }
  }

  const onSubmit = event => {
    event.preventDefault()
    event.stopPropagation()
    fetchEvents()
  }

  const getTitle = (event, action) => {
    let title = `${action} ${event.entity}`
    return title
  }

  const getSubTitle = event => {
    let title = `by`
    if(event.history_user)
      title += ` ${event.history_user} of ${event.history_user_company}`
    return title
  }

  const getIcon = event => {
    if(event.history_type === '+')
      return {icon: <AddIcon fontSize='small' />, color: 'primary', title: getTitle(event, 'Created')}
    if(event.history_type === '-')
      return {icon: <RemoveIcon fontSize='small' />, color: 'error', title: getTitle(event, 'Deleted')}
    return {icon: <EditIcon fontSize='small' />, color: 'warning', title: getTitle(event, 'Updated')}
  }

  return (
    <SideDrawer isOpen={open} title='History Panel' size='big' onClose={toggle}>
      <div className='col-xs-12 padding-reset' style={{marginTop: '16px', display: 'flex', alignItems: 'enter'}} id='history-panel'>
        <Autocomplete
          sx={{width: '250px'}}
          size='small'
          disablePortal
          options={ENTITIES}
          renderInput={(params) => <TextField size='small' {...params} label="Entity" />}
          value={entity}
          onChange={(event, newValue) => setEntity(newValue)}
        />
        <TextField
          sx={{width: '200px', marginLeft: '16px'}}
          size='small'
          label="Entity ID"
          type='number'
          value={entityId}
          onChange={event => setEntityId(event.target.value || undefined)}
        />
        <Button sx={{marginLeft: '16px'}} disabled={!entityId || !entity?.id || isLoading} onClick={onSubmit}>
          Submit
        </Button>
      </div>
      <div className='col-xs-12 padding-reset' style={{marginTop: '8px'}}>
        {
          isArray(events) ?
            (
              events.length === 0 ?
                <p style={{paddingLeft: '8px'}}>Response: No History Found</p> :
              <Timeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                  opacity: isLoading ? 0.5 : 1,
                  marginTop: 0
                }}
              >
                {
                  map(events, (event, index) => {
                    const {icon, color, title} = getIcon(event)
                    const isLast = events.length === (index + 1)
                    const key = getKey(event)
                    const isDiffOpen = diffs.includes(key)
                    const isSnapshotOpen = snapshots.includes(key)
                    return (
                      <TimelineItem key={key} id={key}>
                        <TimelineSeparator>
                          <TimelineDot color={color}>
                            {icon}
                          </TimelineDot>
                          { !isLast && <TimelineConnector /> }
                        </TimelineSeparator>
                        <TimelineContent>
                          <Typography component="span" sx={{fontSize: '14px'}}>
                            {title}
                          </Typography>
                          <Typography sx={{color: 'rgba(0, 0, 0, 0.5)', fontSize: '12px'}}>
                            {getSubTitle(event)}
                          </Typography>
                          <Typography sx={{color: 'rgba(0, 0, 0, 0.5)', fontSize: '12px'}}>
                            {moment(event.history_date).format('lll')}
                          </Typography>
                          <Typography component="span" sx={{fontSize: '14px'}}>
                            <Button disabled={isEmpty(event.diff)} size='small' variant='text' sx={{textTransform: 'none'}} onClick={() => toggleDiff(event)}>
                              {isEmpty(event.diff) ? 'No Diff' : 'Diff'}
                            </Button>
                            <Button size='small' variant='text' sx={{textTransform: 'none', marginLeft: '8px'}} onClick={() => toggleSnapshot(event)}>
                              Snapshot
                            </Button>
                            <Collapse in={isDiffOpen}>
                              <Diff diff={event.diff} />
                            </Collapse>
                            <Collapse in={isSnapshotOpen}>
                              <Snapshot snapshot={event.snapshot} />
                            </Collapse>
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                    )
                  })
                }
              </Timeline>
            ) :
            (
              (isLoading && events === false) ?
                <LoaderInline containerClassName="inline-loader-container" /> :
              (
              badResponse !== false &&
                <p style={{paddingLeft: '8px'}}>
                  Response: {JSON.stringify(badResponse, undefined, 2)}
                </p>
              )
            )
        }
      </div>
    </SideDrawer>
  )
}

export default HistoryDrawer;
