import alertifyjs from 'alertifyjs';
import isEmpty from 'lodash/isEmpty';
import axios from 'axios';
import { isPlainObject } from 'lodash';
import { apiURL } from '../main';
import APIService from '../../services/APIService';
import { addFarmErrors, editFarmErrors, farmAccepted, farmRejected } from '../../actions/companies/farms';
import { myProfileUpdated, forceStopLoader } from '../../actions/main';
import { receivePaginatedFarms } from '../companies/farms';
import { isAtGlobalFarms, addPageCacheQueryParamsToUrl, isAtFarmPage, isAtCompanySites, isEnabledForCurrentUser } from '../../common/utils';
import packageJson from '../../../package.json';
import { receivePaginatedCompanySites } from '../companies/company-sites';
const VERSION = packageJson?.version
const MESSAGES = {
  CREATE_SUCCESS: 'Farm was successfully added',
  UPDATE_SUCCESS: 'Farm was successfully updated',
  FARM_ACCEPTED: 'Farm was successfully accepted',
  FARM_REJECTED: 'Farm was successfully rejected',
  FARM_REQUEST_SENT_TO_GROWER: 'Farm request was successfully sent to grower',
  FARM_ADDED_TO_DIR: 'Farm was successfully added to directory',
  TRANSFER_SUCCESS: ' has been successfully transferred'
};

export const getCompanyFarms = (companyId, actionCreator, includeUnaccepted = false, callback, isActive) => (dispatch, getState) => {
  const { token } = getState().main.user;
  let query = {};

  if (isPlainObject(includeUnaccepted)) {
    query = { ...query, ...includeUnaccepted };
  } else if (includeUnaccepted) {
    query['include_unaccepted'] = true;
  }
  if (isActive !== undefined)
    query['is_active'] = isActive;
  if (companyId) {
    APIService.companies(companyId)
      .farms()
      .get(token, undefined, query)
      .then(items => {
        if (actionCreator) dispatch(actionCreator(items));
        if (callback) callback(items);
      });
  }
};

export const getCompanyFarmsWeb = (companyId, url, _forceStopLoader, includeUnaccepted = true, isActive, isSite=false) => (dispatch, getState) => {
  const { token } = getState().main.user;

  const config = {
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${token}`,
      'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'WEB-VERSION': VERSION,
      'REFERER-COUNTRY': localStorage.current_country
    },
  };

  let URL;

  if (url) {
    URL = url;
  } else {
    URL = `${apiURL}/companies/${companyId}/farms/web/?include_unaccepted=${includeUnaccepted}`;
  }
  if (isActive !== undefined)
    URL += `&is_active=${isActive}`;

  if(isAtGlobalFarms() || isAtFarmPage() || isAtCompanySites()) {
    URL = addPageCacheQueryParamsToUrl(URL)
  }
  if(isAtGlobalFarms() && isEnabledForCurrentUser("farms"))
    URL += '&is_global=True'

  axios
    .get(URL, config)
    .then(response => {
      const receiverAction = isSite ? receivePaginatedCompanySites: receivePaginatedFarms
      dispatch(receiverAction(response.data));
      if (_forceStopLoader) {
        dispatch(forceStopLoader());
      }
    })
    .catch(error => {
      const receiverAction = isSite ? receivePaginatedCompanySites: receivePaginatedFarms
      dispatch(receiverAction(error.response ? error.response.data : error.message));
    });
};

export const createFarm = (companyId, data, actionCreator) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.companies(companyId)
    .farms()
    .post(data, token)
    .then(item => {
      if (item.id == null) {
        dispatch(addFarmErrors(item.errors));
      } else {
        const user = getState().main.user.user;
        if (user.companyId === companyId) {
          dispatch(myProfileUpdated({ isIndependentFarmerCompany: user.isIndependentFarmerCompany, independentFarmId: null }));
        }
        let message = MESSAGES.CREATE_SUCCESS;
        if (!item.isManagedByUser && item.isInUserDirectory) {
          message = MESSAGES.FARM_ADDED_TO_DIR;
        } else if (item.isGrowerAcceptanceRequired) {
          message = MESSAGES.FARM_REQUEST_SENT_TO_GROWER;
        }
        alertifyjs.success(message);
        if (!item.isGrowerAcceptanceRequired) {
          window.location = `#/farms/${item.id}/settings/details`;
        } else {
          dispatch(actionCreator(item));
        }
      }
    });
};

export const updateFarm = (companyId, farmId, data, actionCreator) => (dispatch, getState) => {
  const { token } = getState().main.user;
  data.companyId = companyId;
  APIService.farms(farmId)
    .put(data, token)
    .then(item => {
      if (!isEmpty(item.errors)) {
        dispatch(editFarmErrors(item.errors));
      } else {
        dispatch(actionCreator(item));
        alertifyjs.success(MESSAGES.UPDATE_SUCCESS);
      }
    });
};

export const acceptFarm = (farmId, data) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.farms(farmId)
    .put(data, token)
    .then(item => {
      if (isEmpty(item.errors)) {
        dispatch(farmAccepted(item));
        alertifyjs.success(MESSAGES.FARM_ACCEPTED);
        window.location = `#/farms/${farmId}/settings/details`;
      }
    });
};

export const brokerRejectFarm = (farmId, data) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.farms(farmId)
    .put(data, token)
    .then(item => {
      if (isEmpty(item.errors)) {
        dispatch(farmRejected(farmId));
        alertifyjs.success(MESSAGES.FARM_REJECTED);
        document.location.hash = '#/farms';
      }
    });
};

export const growerRejectFarm = farmId => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.farms(farmId)
    .delete(token)
    .then(() => {
      dispatch(farmRejected(farmId));
      alertifyjs.success(MESSAGES.FARM_REJECTED);
      document.location.hash = '#/farms';
    });
};

export const getSelectedFarm = (farmId, actionCreator) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.farms(farmId)
    .get(token, {}, '?properties=is_alone,company_slot_order_booking')
    .then(item => {
      dispatch(actionCreator(item));
    });
};

export const transferFarmAsset = (farmId, data) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.farms(farmId)
    .appendToUrl('transfer-asset/')
    .put(data, token)
    .then(()=> {
        alertifyjs.notify((data.asset === 'farmfield' ? 'Field' : 'Storage') + MESSAGES.TRANSFER_SUCCESS, 'success', 3, () => { window.location.reload(); });
    });
};
