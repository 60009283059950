import { connect } from 'react-redux';
import GenericTable from '../components/GenericTable';
import { CONTRACTS_TEMPLATES_HEADERS, CONTRACTS_TEMPLATES_SUPER_ADMIN_HEADERS } from "../common/constants";
import APIService from '../services/APIService';
import { includes } from 'lodash';
import { isSystemCompany } from '../common/utils';
import { deleteEmployeeTemplates, getEmployeeTemplates, getEmployeeTemplatesResponse } from '../actions/company-settings/employees';

const mapStateToProps = (state, ownProps) => {
	let optionItems = [{ key: 'delete', text: 'Delete' }];
	let columns = isSystemCompany() ? CONTRACTS_TEMPLATES_SUPER_ADMIN_HEADERS : CONTRACTS_TEMPLATES_HEADERS;
	let templates = state.companySettings.employees.templates;
	let paginatedTemplates = state.companySettings.employees.paginationData;
	return {
		columns: columns,
		items: templates,
		scrollToTopOnUpdate: false,
		handleDefaultCellClick: false,
		clearSearch: getEmployeeTemplatesResponse,
		globalSearch: true,
		optionsItems: optionItems,
		paginationData: paginatedTemplates,
		type: ownProps.type,
		displayIDColumn: 'templateName'
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		handleOptionClick: (index, key, id, item) => {
			if (key == 'delete') {
				dispatch(deleteEmployeeTemplates(item.id, ownProps.type, getEmployeeTemplates));
			}
		},
		navigateTo: (url) => {
			dispatch(getEmployeeTemplates(url, true, ownProps.type));
		},
		changePageSize: (url, pageSize) => {
			if (includes(url, '?')) {
				url = `${url}&page_size=${pageSize}`;
			} else {
				url = `${url}?page_size=${pageSize}`;
			}
			dispatch(getEmployeeTemplates(url, true, ownProps.type));
		},
		getSearchSortUrl: (pageSize, page, searchText, orderBy, order) => {
			const templateService = APIService.profiles().appendToUrl(`templates/${ownProps.type}/`);
			templateService.appendToUrl(`?page_size=${pageSize}`);
			if (page) {
				templateService.appendToUrl(`&page=${page}`);
			}
			if (searchText) {
				templateService.appendToUrl(`&search=${searchText}`);
			}
			if (orderBy) {
				templateService.appendToUrl(`&order_by=${orderBy}&order=${order}`);
			}
			return templateService.URL;
		},
		dispatch
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GenericTable);
