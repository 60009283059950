import React from 'react';
import { connect } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import '../common/subTab.scss';

import { getTracks } from '../../actions/main/index';
import ContractBids from './ContractBids';
import MyActiveContractBids from './MyActiveContractBids';
import ArchivedContractBids from './ArchivedContractBids';

class ContractBidsTab extends React.Component {
  constructor(props) {
    super(props);

    this.CONTRACT_BIDS = '/contract-bids';
    this.MY_ACTIVE_CONTRACT_BIDS = '/contract-bids/active';
    this.ARCHIVED_CONTRACT_BIDS = '/contract-bids/archived';
    
    this.state = {
      value: this.props.location.pathname,
    };
  }
  componentDidMount() {
    this.props.dispatch(getTracks());
  }


  tabChanges = (event, value) => {
    this.setState({ value });
    window.location.hash = value;
  };


  render() {
    const { value } = this.state;
    return (
      <div className="subTab">
        <Tabs className="subTab-header" value={value} indicatorColor="primary" onChange={this.tabChanges}>
          <Tab value={this.CONTRACT_BIDS} label="Contract Bids" className={value !== this.CONTRACT_BIDS ? 'unselected-subtab' : ''} />
          <Tab value={this.MY_ACTIVE_CONTRACT_BIDS} label="My Active Contract Bids" className={value !== this.MY_ACTIVE_CONTRACT_BIDS ? 'unselected-subtab' : ''} />
          <Tab value={this.ARCHIVED_CONTRACT_BIDS}  label="Archived Contract Bids" className={value !== this.ARCHIVED_CONTRACT_BIDS ? 'unselected-subtab' : ''} />
        </Tabs>
        {
          value === this.CONTRACT_BIDS && <ContractBids {...this.props}/>
        }
        {
          value === this.MY_ACTIVE_CONTRACT_BIDS && <MyActiveContractBids {...this.props} tab={value}/>
        }
        {
          value === this.ARCHIVED_CONTRACT_BIDS && <ArchivedContractBids {...this.props} tab={value} />
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.main.user.user,
    breadcrumbs: state.main.breadcrumbs,
    headerText: state.main.headerText,
  };
};

export default connect(mapStateToProps)(ContractBidsTab);
