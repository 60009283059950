import React from 'react';

import { connect } from 'react-redux';
import { RadioGroup, FormControlLabel, Radio, Autocomplete, TextField, CircularProgress } from '@mui/material';
import APIService from '../../../services/APIService';
import isEmpty from 'lodash/isEmpty';
import CommonButton from '../../common/CommonButton';
import CommonTextField from '../../common/CommonTextField';
import { required } from '../../../common/validators';
import {get, set, find, includes, debounce, isEqual}  from 'lodash';
import { isLoading, forceStopLoader } from "../../../actions/main";
import alertifyjs from 'alertifyjs';
import { getCountryLabel, getCountryMovementDisplayUnit } from '../../../common/utils';
import CommonSelect from '../../common/select/CommonSelect';
import CommonAutoSelect from '../../common/autocomplete/CommonAutoSelect';

class AllocateOrder extends React.Component {
  constructor(props) {
    super(props);
    this.countryTonnageLabel = getCountryLabel('tonnage');
    this.state = {
      companies: [],
      customer: [],
      listAssignFoOptions: {
        contract: { label: "Contract", items: [], reasons: [] },
      },
      baseEntity: {
        value: [],
        validators: [required()],
        errors: []
      },
      buttons: 'contract',
      inload: {
        stockOwner: {
          value: '',
          errors: [],
        },
        ngrId: {
          value: '',
          errors: [],
          validators: [],
        }
      },
      outload: {
        stockOwner: {
          value: '',
          errors: [],
        },
        ngrId: {
          value: '',
          errors: [],
          validators: [],
        }
      },
    };
    this.setEntity = this.setEntity.bind(this);
    this.isLoading = this.props.isLoading;
    this.handleSelectFieldChange = this.handleSelectFieldChange.bind(this);
    this.getDirectoryCompanies = this.getDirectoryCompanies.bind(this);
    this.onCompanyChange = this.onCompanyChange.bind(this);
    this.getAssignParentReasons = this.getAssignParentReasons.bind(this);
    this.onSave = this.onSave.bind(this);
    this.fetchEntityData = this.fetchEntityData.bind(this);
    this.handleEntityInputChange = this.handleEntityInputChange.bind(this);
  }

  componentDidMount() {
    this.getDirectoryCompanies();
  }

  fetchEntityData = debounce(searchStr => {
    const apiURL = APIService.contracts()
    let queryParams = '?search_in_assigning_movements=true'
    const searchKey = 'contracts'
    const countryMovementDisplayUnit = getCountryMovementDisplayUnit()
    const headers = countryMovementDisplayUnit ? {'REFERER-UNIT' : countryMovementDisplayUnit, 'REFERER-UNIT-FOR-REQUEST': true} : null;
    apiURL
      .appendToUrl(`global-search/${encodeURIComponent(encodeURIComponent(searchStr))}/${queryParams}`)
      .get(this.props.token, headers)
      .then(data => {
        const newState = { ...this.state };
        set(newState, `listAssignFoOptions.${this.state.buttons}.items`, get(data, searchKey));
        newState.isLoadingSearchResults = false;
        this.setState(newState);
      });
  }, 500);

  OnSubmit = (event) => {
    event.preventDefault();
    this.setFieldErrors('inload', 'ngrId');
    this.setFieldErrors('outload', 'ngrId');
    let isFormInvalid = this.state.inload.ngrId.errors.length > 0 || this.state.outload.ngrId.errors.length > 0;
    if(!isFormInvalid)
      alertifyjs.confirm(
        'Warning',
        "Please verify the Contract Number and Customer Name before saving. Do you want to proceed or go back?",
        this.onSave,
        () => { }
      ).set({ labels: { ok: 'Proceed', cancel: 'Go Back' } });
  };

  allocateOrder() {
    this.props.allocateOrder(
      { commodityContract: this.state.baseEntity.value,
        inloadNgrId: get(this.state.inload, 'ngrId.value'),
        outloadNgrId: get(this.state.outload, 'ngrId.value'),
      }, this.props.order.id);
  }

  getMaxAllowedTonnage() {
    let maxAllowedTonnage = get(this.state.baseEntity.value, 'totalTonnageWithTolerance') - (get(this.state.baseEntity.value, 'deliveredTonnage') + get(this.state.baseEntity.value, 'progressTonnage'));
    if (includes(['planned', 'confirmed', 'open'], get(this.props, 'movement.status')))
      maxAllowedTonnage = get(this.state.baseEntity.value, 'unaccountedTonnage')
    return maxAllowedTonnage;
  }

  onSave() {
    if (isEmpty(this.state.baseEntity.errors) && (!isEmpty(this.state.baseEntity.value))) {
      this.allocateOrder()
    }
  }

  setEntity = id => {
    const newState = { ...this.state };
    if (id) {
      let deliveryOnus = (get(find(get(this.state.listAssignFoOptions, `${[this.state.buttons]}.items`, []), { id: id }), 'deliveryOnus'));
      let selectedEntity = (find(get(this.state.listAssignFoOptions, `${[this.state.buttons]}.items`, []), { id: id }));
      let customer = [];
      if (deliveryOnus == 'Seller')
        customer = selectedEntity.seller;
      if (deliveryOnus == 'Buyer')
        customer = selectedEntity.buyer;
      if (get(selectedEntity, 'customer'))
        customer = selectedEntity.customer;
      set(newState, 'baseEntity.value', selectedEntity);
      set(newState, 'customer', customer);
      set(newState, 'baseEntity.errors', '');
      let sellerCompanyId = get(selectedEntity.seller, 'companyId');
      let buyerCompanyId = get(selectedEntity.buyer, 'companyId');
      const fetchNGRsFor = []
      if(sellerCompanyId && !isEqual(this.state.outload.stockOwner.value, sellerCompanyId)) {
        fetchNGRsFor.push({party: 'seller', id: 'outload', value: sellerCompanyId})
      }
      if(buyerCompanyId && !isEqual(this.state.inload.stockOwner.value, buyerCompanyId)) {
        fetchNGRsFor.push({party: 'buyer', id: 'inload', value: buyerCompanyId})
      }
      newState.searchText = selectedEntity.identifier || selectedEntity.referenceNumber;
      newState.isLoadingSearchResults = Boolean(id && this.state.isLoadingSearchResults);
      if(!isEmpty(fetchNGRsFor)) {
        const party1 = fetchNGRsFor[0]
        const party2 = get(fetchNGRsFor, 1)
        this.fetchNgrs(party1.value, party1.id, ngrs => {
          const newState = { ...this.state };
          set(newState, `${party1.id}Ngrs`, ngrs)
          newState[party1.id].ngrId.value = get(selectedEntity[party1.party], 'ngrId');
          newState[party1.id].stockOwner.value = get(selectedEntity[party1.party], 'companyId');
          newState[party1.id].ngrId.validators = [required()];
          this.setState(newState, () => {
            if(!isEmpty(party2)) {
              this.fetchNgrs(party2.value, party2.id, ngrs => {
                const newState = { ...this.state };
                set(newState, `${party2.id}Ngrs`, ngrs)
                newState[party2.id].ngrId.value = get(selectedEntity[party2.party], 'ngrId');
                newState[party2.id].stockOwner.value = get(selectedEntity[party2.party], 'companyId');
                newState[party2.id].ngrId.validators = [required()];
                this.setState(newState);
              });
            }
          });
        });
      }
    }
    else {
      set(newState, 'baseEntity.value', '');
      set(newState, 'customerNgrId', '');
      const value = get(newState, `baseEntity.value`);
      const validators = get(this.state, `baseEntity.validators`, []);
      validators.forEach((validator) => {
        if (validator.isInvalid(value)) {
          set(newState, 'baseEntity.errors', validator.message);
        }
      });
    }
    this.setState(newState);
  }

  getAssignParentReasons = async (orderId, entityId) => {
    const entity = 'contract'
    const response = await APIService.freights()
      .orders(orderId)
      .appendToUrl(`assign-parent/?entity=${entity}&entity_id=${entityId}`)
      .get(this.props.token);
    return response.reasons
  }

  getDirectoryCompanies() {
    const { user } = this.props;
    APIService.companies().appendToUrl('directory/names/?excludeGroups=true')
      .get()
      .then(companies => {
        const newState = { ...this.state };
        newState.companies = [
          { id: user.companyId, name: user.company.name },
          ...companies
        ];
        newState.inload.ngrId.validators = [required()]
        newState.outload.ngrId.validators = [required()]
        this.setState(newState);
      });
  }

  setFieldErrors(load_type, key) {
    const newState = { ...this.state };
    set(newState, `${load_type}.${key}.errors`, this.getFieldErrors(load_type, key));
    this.setState(newState);
  };

  getFieldErrors(load_type, key) {
    let errors = [];
    const value = get(this.state, `${load_type}.${key}.value`);
    const validators = get(this.state, `${load_type}.${key}.validators`) || [];
    validators.forEach(validator => {
        if (validator.isInvalid(value)) {
            errors.push(validator.message);
        }
    });

    return errors;
  }

  onCompanyChange = (value, id) => {
    const newState = { ...this.state };
    if (get(this.state, `${id}.stockOwner.value`) != value) {
      set(newState, `${id}.stockOwner.value`, value);
      set(newState, `${id}.ngrId.value`, '');
      set(newState, `${id}Ngrs`, []);
      this.setState(newState, () => {
        if (value)
          this.fetchNgrs(value, id);
      });
    }
  }

  fetchNgrs(value, id, callback) {
    if(get(this.state, `{id}Ngrs.0.companyId`) !== value) {
      APIService.companies(value).ngrs().appendToUrl('minimal/')
        .get()
        .then(ngrs => {
          if(callback)
            callback(ngrs)
          else {
            const newState = { ...this.state };
            set(newState, `${id}Ngrs`, ngrs);
            this.setState(newState);
          }
        });
    }
  }

  handleSelectFieldChange(value, id) {
    const newState = { ...this.state };
    set(newState, `${id}.value`, value);
    this.setState(newState);
  }

  resetDefaults() {
    const newState = { ...this.state };
    set(newState, 'baseEntity.value', []);
    set(newState, 'searchText', '');
    set(newState, `listAssignFoOptions.${this.state.buttons}.reasons`, []);
    this.setState(newState);
  }

  handleEntityInputChange = (event, value) => {
    const newState = { ...this.state }
    set(newState, `listAssignFoOptions.${this.state.buttons}.reasons`, []);
    set(newState, 'searchText', value);
    set(newState, 'isLoadingSearchResults', Boolean(value));
    set(newState, 'baseEntity.value.id', get(value, 'id', ''))
    this.setState(newState, () => {
      if(value && value.length > 2)
        this.fetchEntityData(value);
    });
  };

  handleContractChange = async (event, value) => {
    if(value){  
      const reasons = await this.getAssignParentReasons(this.props.order.id, Number(value.id));
      if(isEmpty(reasons))
        this.setEntity(value ? value.id : null, 'FmOptionsId', value)
      else{
        const newState = { ...this.state }
        set(newState, `listAssignFoOptions.${this.state.buttons}.reasons`, reasons);
        this.setState(newState);
      }
    }
  };

  isSearchableInput() {
    return get(this.state.searchText, 'length') > 2;
  }

  getEntityUrl() {
    if (!get(this.state.baseEntity.value, 'isIndependent')) {
      const reason = get(this.state.baseEntity.value, 'maxAllowedTonnageAndReason.reason')
      const canAmendRelatedEntity = get(this.state.baseEntity.value, 'maxAllowedTonnageAndReason.canAmendRelatedEntity');
      if (reason) {
        let url = ''
        let identifier = ''
        if (reason === 'contract') {
          identifier = get(this.state.baseEntity.value, 'contractNumber')
          url = canAmendRelatedEntity ? <a rel="noopener noreferrer" target='_blank' href={`#/contracts/${get(this.state.baseEntity.value, 'commodityContractId')}/edit`} onClick={event => window.open(event.target.href, '_blank')}>{identifier}</a> : identifier;
        }
        else if (reason === 'pickup order') {
          identifier = get(this.state.baseEntity.value, 'pickupOrder.identifier')
          url = canAmendRelatedEntity ? <a rel="noopener noreferrer" target='_blank' href={`#/freights/orders/${get(this.state.baseEntity.value, 'pickupOrder.id')}/edit`} onClick={event => window.open(event.target.href, '_blank')}>{identifier}</a> : identifier;
        }
        else if (reason === 'delivery order') {
          identifier = get(this.state.baseEntity.value, 'deliveryOrder.identifier')
          url = canAmendRelatedEntity ? <a rel="noopener noreferrer" target='_blank' href={`#/freights/orders/${get(this.state.baseEntity.value, 'deliveryOrder.id')}/edit`} onClick={event => window.open(event.target.href, '_blank')}>{identifier}</a> : identifier;
        }
        else if (reason === 'parent order') {
          identifier = get(this.state.baseEntity.value, 'parentOrderIdentifier')
          url = canAmendRelatedEntity ? <a rel="noopener noreferrer" target='_blank' href={`#/freights/orders/${get(this.state.baseEntity.value, 'parentOrderId')}/edit`} onClick={event => window.open(event.target.href, '_blank')}>{identifier}</a> : identifier;
        }
        return url
      }
    }
  }

  buttonsMapping() {
    return {
      'contract': 'contract',
    }
  }

  render() {
    const isSearchableInput = this.isSearchableInput();
    const selectedOption = this.state.listAssignFoOptions[this.state.buttons];
    return (<div className='col-xs-12 padding-reset'>
      <form onSubmit={this.OnSubmit} noValidate>
        <div className="cardForm cardForm--drawer" id='assign-to-contract-or-freight-order'>
          <div className="cardForm-content row">
            <RadioGroup onChange={e => this.setState({ buttons: e.target.value }, () => { this.resetDefaults();} )} value={this.state.buttons} row className="col-sm-12 form-wrap-70">
              <FormControlLabel key={1} value="contract" control={<Radio color="primary" />} label={this.state.listAssignFoOptions.contract.label} />
            </RadioGroup>

            {<div className="col-sm-12 form-wrap-70">
              <Autocomplete
                fullWidth
                blurOnSelect
                options={selectedOption.items || []}
                value={find(selectedOption.items, {id: get(this.state.baseEntity, 'value.id')})}
                id="FmOptionsId"
                key={this.state.buttons}
                style={{float: 'left'}}
                getOptionLabel={option => get(option, 'referenceNumber') || get(option, 'identifier')}
                loading={this.state.isLoadingSearchResults}
                loadingText={isSearchableInput ? 'Loading...' : 'Type at least 3 characters to search'}
                noOptionsText={this.state.searchText ? "No results" : 'Start typing...'}
                renderInput={params => (
                  <TextField
                    {...params}
                    value={this.state.searchText}
                    label={selectedOption.label}
                    placeholder={`${selectedOption.label} (Type at least 3 characters to search)`}
                    errorText={!isEmpty(get(this.state.baseEntity, 'errors', ''))}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {
                            this.state.isLoadingSearchResults &&
                            <CircularProgress color="inherit" size={20} />
                          }
                          { params.InputProps.endAdornment }
                        </React.Fragment>
                      ),
                    }}
                    helperText={get(this.state.baseEntity, 'errors', '')}
                    fullWidth
                  />
                )}
                onChange={(event, value) => this.handleContractChange(event, value)}
                onInputChange={this.handleEntityInputChange}
              />
             </div>
            }

            {(selectedOption.reasons && get(this.state.baseEntity, 'value.id')) && 
            <React.Fragment>
              <div className="col-sm-12 form-wrap-70">
                <CommonTextField
                  id="customerName"
                  label="Customer Name"
                  value={get(this.state.customer, 'companyName')}
                  disabled />
              </div>
              <div className='col-sm-12 form-wrap padding-reset'>
                <div className='col-sm-6 form-wrap'>
                  <CommonAutoSelect
                    items={this.state.companies}
                    id='outload'
                    label='Outload Stock Owner'
                    onChange={this.onCompanyChange}
                    style={{ float: 'right' }}
                    errorText={get(this.state.outload.stockOwner, 'errors[0]')}
                    value={get(this.state.outload.stockOwner, 'value')}
                    disabled
                    notClearable={true}
                  />
                </div>
                <div className='col-sm-6 form-wrap'>
                  <CommonAutoSelect
                    items={this.state.companies}
                    id='inload'
                    label='Inload Stock Owner'
                    onChange={this.onCompanyChange}
                    style={{ float: 'right' }}
                    errorText={get(this.state.inload.stockOwner, 'errors[0]')}
                    value={get(this.state.inload.stockOwner, 'value')}
                    disabled
                    notClearable={true}
                  />
                </div>
              </div>
              <div className='col-sm-12 form-wrap padding-reset'>
                <div className='col-sm-6 form-wrap'>
                  <CommonSelect
                    id='outload.ngrId'
                    floatingLabelText={"Outload Ngr"}
                    items={this.state.outload.stockOwner.value ? this.state.outloadNgrs : []}
                    selectConfig={{ value: 'id', text: 'ngrNumber' }}
                    onChange={this.handleSelectFieldChange}
                    value={this.state.outload.ngrId.value}
                    errorText={get(this.state.outload.ngrId, 'errors[0]')}
                    disabled={isEmpty(this.state.outloadNgrs)}
                    required
                  />
                </div>
                <div className='col-sm-6 form-wrap'>
                  <CommonSelect
                    id='inload.ngrId'
                    floatingLabelText={"Inload NGR"}
                    items={this.state.inload.stockOwner.value ? this.state.inloadNgrs : []}
                    selectConfig={{ value: 'id', text: 'ngrNumber' }}
                    onChange={this.handleSelectFieldChange}
                    value={this.state.inload.ngrId.value}
                    errorText={get(this.state.inload.ngrId, 'errors[0]')}
                    disabled={isEmpty(this.state.inloadNgrs)}
                    required
                  />
                </div>
              </div>
            </React.Fragment>
            }
          </div>
          {!isEmpty(selectedOption.reasons) && (<div>
              <p>
              Cannot assign the order to the {selectedOption.label} because:
              <ul>
                {selectedOption.reasons.map((reason, index) => {
                  if(typeof reason === 'string') {
                    return <li key={index}>{reason}</li>;
                  } else if (typeof reason === 'object') {
                    return (
                      <li key={index}>
                        {reason.error}:
                        {reason.description.map((desc, index) => (
                          <ul style={{ listStyle: 'none' }}>
                          <li key={index}>
                          {desc}
                          </li>
                          </ul>
                        )
                      )}
                        {reason && reason.others ? (
                          reason.others.map((other, index) => (
                            <ul style={{ marginLeft: '20px' }}>
                              <li key={index}>
                                {other}
                              </li>
                            </ul>
                          ))
                        ) : null}
                      </li>
                    );
                  }
                })}
              </ul>
            </p>
          </div>)}
          <div className="col-sm-12 cardForm-action top15 padding-reset">
            <CommonButton
              type="button"
              variant="outlined"
              label="Cancel"
              default
              onClick={this.props.handleCancel}
            />

            <CommonButton
              primary={true}
              variant="contained"
              label={"Submit"}
              type="submit"
              disabled={!(isEmpty(selectedOption.reasons) && get(this.state.baseEntity, 'value.id') || this.state.buttons == 'independent')}
            />
          </div>
        </div>
      </form>
    </div>);
  }
};

const mapStateToProps = state => {
  return {
    token: state.main.user.token,
    user: state.main.user.user,
  };
};

const mapDispatchToProps = dispatch => ({
  isLoading: (component) => dispatch(isLoading(component)),
  forceStopLoader: () => dispatch(forceStopLoader()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllocateOrder);
