import { connect } from 'react-redux';
import FreightInvoiceForm from '../components/invoices/FreightInvoiceForm';
import {createAmendInvoice, createInvoice, deleteInvoice, editInvoice, generateInvoice} from '../actions/companies/invoices';
import {clickAddEmployeeButton} from "../actions/company-settings/employees";

const mapDispatchToProps = (dispatch) => {
  return {
    submit: (data, successCallback) => {
      dispatch(createInvoice(data, true, false, successCallback));
    },
    createAmendInvoice: (data, successCallback) => {
      dispatch(createAmendInvoice(data, true, false, successCallback));
    },
    editInvoice: (data, invoiceId, contractId) => {
      dispatch(editInvoice(data, invoiceId, contractId));
    },
    deleteInvoice: (invoiceId) => {
      dispatch(deleteInvoice(invoiceId));
    },
    generateInvoice: (invoiceId, contractId, orderId, data) => {
      dispatch(generateInvoice(invoiceId, contractId, orderId, data));
    },
    handleAddEmployeeButtonClick: () => {
      dispatch(clickAddEmployeeButton());
    }
  };
};

export default connect(null, mapDispatchToProps)(FreightInvoiceForm);
