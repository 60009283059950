import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import CommonDatePicker from './CommonDatePicker';
import { Tooltip } from '@mui/material';
import CommonButton from './CommonButton';
import { useEffect } from 'react';
import { getCurrentCountry } from '../../common/utils';
import APIServiceProvider from '../../services/APIService';
import { isLoading, forceStopLoader } from '../../actions/main';
import CommonMultiSelect from './autocomplete/CommonMultiSelect';
import map from 'lodash/map';
import CompanyAutocomplete from './autocomplete/CompanyAutocomplete';
import { get } from 'lodash';
import PreDefinedDateRangeSelector from './PreDefinedDateRangeSelector';

let InvoiceFilters = props =>{
  let token = useSelector(state => {
    return state.main.user.token;
  });

  let [initData, setInitData] = useState({});

  const dispatch = useDispatch();

  let getInitData = async ()=>{
    dispatch(isLoading(''));
    let commodities = await APIServiceProvider.commodities().get(token, null, { simplify_names: true, countryId: getCurrentCountry().id });
    setInitData({
      'commodities': commodities,
      'invoiceType': [{ id: 'Brokerage', name: 'Brokerage' },
                      { id: 'Commodity Contract', name: 'Commodity Contract' },
                      { id: 'Freight', name: 'Freight' },
                      { id: 'WarehouseFee', name: 'Warehouse Fee' }],
      'statuses': [{id: "paid", name: "Overpaid/Paid"},
                   {id: "generated", name: "Action Pending/Awaiting Confirmation"},
                   {id: "pending_payments", name: "Pending Payment Confirmation/Awaiting Payment Confirmation"},
                   {id: "not_confirmed", name: "Pending Payment/Awaiting Payment"},
                   {id: "rejected", name: "Rejected"},
                   {id: "void", name: "Void"}],
      'contractInvoicing': [{ id: 'Buyer RCTI', name: "Buyer RCTI"},
                            { id: 'Seller to Invoice Buyer', name: "Seller to Invoice Buyer"},
                            { id: 'Broker to Invoice Buyer', name: "Broker to Invoice Buyer"}],
      'orderInvoicing': [{ id: 'Customer RCTI', name: "Customer RCTI"},
                         { id: 'Freight Provider to Invoice Customer', name: 'Freight Provider to Invoice Customer'},
                         { id: 'Freight Provider to Invoice Broker', name: 'Freight Provider to Invoice Broker'},
                         { id: 'Packed Provider to Invoice Customer', name: 'Packed Provider to Invoice Customer'}]
    });
    dispatch(forceStopLoader());
  };

  let invoiceResetFilterData = {
    updated_at_date_range: '',
    updated_at__gte: '',
    updated_at__lte: '',
    payment_due_date_range: '',
    payment_due_date__gte: '',
    payment_due_date__lte: '',
    type__in: [],
    payee__company_id__in: [],
    payer__company_id__in: [],
    status__in: [],
    contract__commodity__id__in: [],
    contract__administration__invoicing__in: []
  };

  let handleSubmit = () => {
    const data = cloneDeep(filtersStruct)
    data.payer__company_id__in = map(data.payer__company_id__in, 'id')
    data.payee__company_id__in = map(data.payee__company_id__in, 'id')
    data.payment_due_date__gte = data.payment_due_date_range === 'custom' ? data.payment_due_date__gte : '';
    data.payment_due_date__lte = data.payment_due_date_range === 'custom' ? data.payment_due_date__lte : '';
    data.updated_at__gte = data.updated_at_date_range === 'custom' ? data.updated_at__gte : '';
    data.updated_at__lte = data.updated_at_date_range === 'custom' ? data.updated_at__lte : '';
    props.handleFilterState('filters', data);
    props.handleFilterState('applyFilters', true);
    props.handleFilterState('openSideDrawer', false);
  };

  const resetFilters = () => {
    props.handleFilterState('filters', invoiceResetFilterData);
    props.handleFilterState('applyFilters', true);
    props.handleFilterState('openSideDrawer', false);
  };


  const [filtersStruct, setFiltersStruct] = useState(cloneDeep(props.filters));

  useEffect(() => { getInitData() }, []);

  return (
    <div className='cardForm cardForm--drawer'>
      <div className='cardForm-content row'>
        {
          !props.receivableInvoice && !props.freightReceivableInvoice &&
            <div className={(props.payableInvoice || props.freightPayableInvoice) ? "col-sm-12" : "col-sm-6"}>
              <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}>
                {props.payableInvoice || props.freightPayableInvoice ? 'Payee' : 'Bill For'}
              </h4>
              <CompanyAutocomplete
                multiple
                disableCloseOnSelect
                id='payee__company_id__in'
                label={props.payableInvoice ? 'Payee' : 'Bill For'}
                getOptionLabel={option => option.name}
                selected={filtersStruct.payee__company_id__in}
                onChange={items => setFiltersStruct({...filtersStruct, 'payee__company_id__in': items})}
                urlPath='directory/names/'
                queryParams={{include_self: true, excludeGroups: true}}
                minLength={3}
                limitTags={3}
              />
            </div>
        }
        {
          !props.payableInvoice && !props.freightPayableInvoice &&
            <div className={(props.receivableInvoice || props.freightReceivableInvoice) ? "col-sm-12" : "col-sm-6"}>
              <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}>
                {(props.receivableInvoice || props.freightReceivableInvoice) ? 'Payer' : 'Bill To'}
              </h4>
              <CompanyAutocomplete
                multiple
                disableCloseOnSelect
                id='payer__company_id__in'
                label={props.receivableInvoice ? 'Payer' : 'Bill To'}
                getOptionLabel={option => option.name}
                selected={filtersStruct.payer__company_id__in}
                onChange={items => setFiltersStruct({...filtersStruct, 'payer__company_id__in': items})}
                urlPath='directory/names/'
                queryParams={{include_self: true, excludeGroups: true}}
                minLength={3}
                limitTags={3}
              />
            </div>
        }
      </div>
      {(props.freightReceivableInvoice || props.freightPayableInvoice) &&
      <div className='cardForm-content row'>
        <div className="col-sm-12">
          <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}> Commodity </h4>
          <CommonMultiSelect
            id="freight_order__commodity__id__in"
            items={initData.commodities}
            selectedItems={filtersStruct.freight_order__commodity__id__in}
            displayField="displayName"
            onChange={(id, selectedItems) =>
              setFiltersStruct({...filtersStruct, 'freight_order__commodity__id__in': map(selectedItems, 'id')})}
          />
        </div>
      </div>
      }
      {!props.freightReceivableInvoice && !props.freightPayableInvoice &&
        <div className='cardForm-content row'>
        {
          props.payableInvoice || props.receivableInvoice ?
            <div className="col-sm-12">
              <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}> Commodity </h4>
              <CommonMultiSelect
                id="type__in"
                items={initData.commodities}
                selectedItems={filtersStruct.contract__commodity__id__in}
                displayField="displayName"
                onChange={(id, selectedItems) =>
                  setFiltersStruct({...filtersStruct, 'contract__commodity__id__in': map(selectedItems, 'id')})}
              />
            </div> :
          <div className="col-sm-12">
            <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}> Invoice type </h4>
            <CommonMultiSelect
              id="type__in"
              items={initData.invoiceType}
              selectedItems={filtersStruct.type__in}
              displayField="name"
              onChange={(id, selectedItems) =>
                setFiltersStruct({...filtersStruct, 'type__in': map(selectedItems, 'id')})}
            />
          </div>
        }
      </div>
      }
      {
        !props.payableInvoice && !props.receivableInvoice && !props.freightReceivableInvoice && !props.freightPayableInvoice &&
          <div className='cardForm-content row'>
            <div className="col-sm-12">
              <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}> Status </h4>
              <CommonMultiSelect
                id="status__in"
                items={initData.statuses}
                selectedItems={filtersStruct.status__in}
                displayField="name"
                onChange={(id, selectedItems) =>
                  setFiltersStruct({...filtersStruct, 'status__in': map(selectedItems, 'id')})}
              />
            </div>
          </div>
      }
      {
        props.receivableInvoice && !props.freightReceivableInvoice &&
          <div className='cardForm-content row'>
            <div className="col-sm-12">
              <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}> Contract Invoicing Type </h4>
              <CommonMultiSelect
                id="contract__administration__invoicing__in"
                items={initData.contractInvoicing}
                selectedItems={filtersStruct.contract__administration__invoicing__in}
                displayField="name"
                onChange={(id, selectedItems) =>
                  setFiltersStruct({...filtersStruct, 'contract__administration__invoicing__in': map(selectedItems, 'id')})}
              />
            </div>
          </div>
      }
      {(props.freightReceivableInvoice || props.freightPayableInvoice) &&
      <div className='cardForm-content row'>
        <div className="col-sm-12">
          <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}> Order Invoicing Type </h4>
          <CommonMultiSelect
            id="freight_order__invoicing__in"
            items={initData.orderInvoicing}
            selectedItems={filtersStruct.freight_order__invoicing__in}
            displayField="name"
            onChange={(id, selectedItems) =>
              setFiltersStruct({...filtersStruct, 'freight_order__invoicing__in': map(selectedItems, 'id')})}
          />
        </div>
      </div>
      }
      <div className='cardForm-content row'>
        <div className='col-md-12' style={{paddingTop: '10px'}}>
          <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}>Payment Due Date</h4>
            <div className='col-md-12' style={{padding: '0px 0px'}}>
              <PreDefinedDateRangeSelector
                id="payment_due_date_range"
                predefinedDateRangeFilters={filtersStruct}
                setDateRange={setFiltersStruct}
              />
            </div>
        </div>
        {(get(filtersStruct, 'payment_due_date_range') === 'custom') &&
          <>
            <div className='col-md-6' style={{paddingTop: '15px'}}>
              <CommonDatePicker
                id="payment_due_date__gte"
                floatingLabelText="After"
                onChange={val => setFiltersStruct({
                  ...filtersStruct,
                  payment_due_date__gte: val
                })}
                value={filtersStruct.payment_due_date__gte}
                variant="outlined"
              />
            </div>
            <div className='col-md-6' style={{paddingTop: '15px'}}>
              <CommonDatePicker
                id="payment_due_date__lte"
                floatingLabelText="Before"
                onChange={val => setFiltersStruct({
                  ...filtersStruct,
                  payment_due_date__lte: val
                })}
                value={filtersStruct.payment_due_date__lte}
                variant="outlined"
              />
            </div>
          </>
        }
      </div>

      {
        !props.payableInvoice && !props.receivableInvoice && !props.freightReceivableInvoice && !props.freightPayableInvoice &&
          <div className='cardForm-content row'>
            <div className='col-md-12' style={{paddingTop: '10px'}}>
              <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}>Last Updated Date</h4>
                <div>
                  <PreDefinedDateRangeSelector
                    id="updated_at_date_range"
                    predefinedDateRangeFilters={filtersStruct}
                    setDateRange={setFiltersStruct}
                  />
                </div>
            </div>
            {(get(filtersStruct, 'updated_at_date_range') === 'custom') &&
            <>
              <div className='col-md-6' style={{paddingTop: '15px'}}>
                <CommonDatePicker
                  id="updated_at__gte"
                  floatingLabelText="After"
                  onChange={val => setFiltersStruct({
                    ...filtersStruct,
                    updated_at__gte: val
                  })}
                  value={filtersStruct.updated_at__gte}
                  variant="outlined"
                />
              </div>
              <div className='col-md-6' style={{paddingTop: '15px'}}>
                <CommonDatePicker
                  id="updated_at__lte"
                  floatingLabelText="Before"
                  onChange={val => setFiltersStruct({
                    ...filtersStruct,
                    updated_at__lte: val
                  })}
                  value={filtersStruct.updated_at__lte}
                  variant="outlined"
                />
              </div>
              </>
            }
          </div>
      }
      <div className='col-sm-12 cardForm-action top15 padding-reset'>
        <Tooltip title='Reset applied filters' placement='top'>
          <CommonButton label='RESET FILTERS' default={true} variant='outlined' onClick={resetFilters} />
        </Tooltip>

        <Tooltip title='Clear temporary saved filter' placement='top'>
          <CommonButton label='Cancel' secondary onClick={() => props.handleFilterState('openSideDrawer', false)} variant="contained" />
        </Tooltip>

        <Tooltip title='Apply Filters' placement='top'>
          <CommonButton type='submit' label='APPLY' primary={true} variant="contained" onClick={handleSubmit} />
        </Tooltip>
      </div>
    </div>
  );

};

export default InvoiceFilters;
