import React, {Component} from 'react';
import {connect} from 'react-redux';


import './orderDetails.scss';
import {
  forceStopLoader,
  isLoading,
  setBreadcrumbs,
  setHeaderText, setSubHeaderText,
} from '../../../actions/main';
import { get, includes, isEqual, some, isEmpty } from "lodash";
import {getOrderHeaderText, getOrderSubHeaderText} from "../../../common/utils";
import OrderCounterParties from "./OrderCounterParties";
import OrderDetailsSection from "./OrderDetailsSection";
import OrderConditionsSection from "./OrderConditionsSection";
import {RejectionDetails} from "../../rejections/RejectionDetails";
import {
  PICKUP_REQUEST_ORDER_TYPE_ID, DELIVERY_REQUEST_ORDER_TYPE_ID, PACK_ORDER_TYPE_ID,
  ORDER_TYPE_ROUTE_MAPPING
} from "../../../common/constants";
import OrderNotes from "./OrderNotes";
import {ImpexDocsStatus} from './ImpexDocsStatus';

class OrderDetails extends Component {
  componentDidMount() {
    this.props.showLoader();
    this.setLayout();
    if (this.props.breadcrumbsFunc) {
      this.props.breadcrumbsFunc();
    }
  }

  componentDidUpdate() {
    this.setLayout();
    if (this.props.order && this.props.isLoading && this.props.waitForComponent !== 'movementFormFromContract') {
      this.props.stopLoader();
    }
  }


  isRequestOrder = () => includes([PICKUP_REQUEST_ORDER_TYPE_ID, DELIVERY_REQUEST_ORDER_TYPE_ID], get(this.props.order, 'typeId'));

  isPickupOrder = () => get(this.props.order, 'typeId') === PICKUP_REQUEST_ORDER_TYPE_ID;

  isDeliveryOrder = () => get(this.props.order, 'typeId') === DELIVERY_REQUEST_ORDER_TYPE_ID;

  isPackOrder = () => get(this.props.order, 'typeId') === PACK_ORDER_TYPE_ID

  setLayout() { 
    const orderTypeId = get(this.props, 'order.typeId')
    const orderType = get(ORDER_TYPE_ROUTE_MAPPING, orderTypeId, 'freights')

    if (this.props.order && !this.props.dontSetBreadcrumbs) {
      let headerText = getOrderHeaderText(this.props.order);
      this.props.dispatch(setHeaderText(headerText));
      this.props.dispatch(setSubHeaderText(getOrderSubHeaderText(this.props.order)));

      const breadcrumbs = [
        {text: 'Orders', route: `/orders/${orderType}`},
        {text: get(this.props.order, 'identifier', '')}
      ];
      if (!isEqual(this.props.breadcrumbs, breadcrumbs)) {
        this.props.dispatch(setBreadcrumbs(breadcrumbs));
      }
    }
  }

  render() {
    const { order } = this.props;
    const isPickupOrder = this.isPickupOrder();
    const isDeliveryOrder = this.isDeliveryOrder();
    const note  = get(this.props, 'order.note');
    const requestReasonDisplay = get(order, 'requestReasonDisplay');
    const rejectReasonDisplay = get(order, 'rejectionReasonDisplay');
    const requestReason = get(order, 'requestReasonDisplay.requestReason');
    const rejectionReason = get(order, 'rejectionReasonDisplay.rejectionReason');
    const canViewConditions = get(order, 'canViewConditions');
    const customerCompany = get(order, 'customer.company');
    const hasErrors = some(order?.impexDocsErrors, error => !isEmpty(error))
    return (
      <div className="order-details-container">
        {order &&
         <React.Fragment>
           {requestReason && <RejectionDetails
                               rejectionReasonObject={requestReasonDisplay}
                               className="order-details-section-container"/>
           }
           {rejectionReason && <RejectionDetails
                                 rejectionReasonObject={rejectReasonDisplay}
                                 className="order-details-section-container"/>
           }
           {
            customerCompany?.impexDocsEnabled && isEqual(order?.typeId, PACK_ORDER_TYPE_ID) && hasErrors &&
            <ImpexDocsStatus
              className="order-details-section-container"
              errors={order?.impexDocsErrors}
              shipmentDocsId={order?.freightShipping?.impexShipmentDocsId}
              bookingDocsId={order?.freightShipping?.impexBookingId}
              salesOrderId={order?.commodityContract?.salesOrderId}
              heading="Impex Docs Status"
            />
           }
           <OrderCounterParties {...this.props} isRequestOrder={isPickupOrder || isDeliveryOrder} isPickupOrder={isPickupOrder} isDeliveryOrder={isDeliveryOrder} />
           <OrderDetailsSection {...this.props} isPickupOrder={isPickupOrder} isDeliveryOrder={isDeliveryOrder} />
           {canViewConditions && <OrderConditionsSection {...this.props}/>}
           {note && <OrderNotes {...this.props} entity={get(this.props, 'order')}/>}
         </React.Fragment>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    breadcrumbs: state.main.breadcrumbs,
    isLoading: state.main.isLoading,
    waitForComponent: state.main.waitForComponent
  };
};

const mapDispatchToProps = dispatch => ({
  setHeaderText: (text) => dispatch(setHeaderText(text)),
  setBreadcrumbs: (breadcrumbs) => dispatch(setBreadcrumbs(breadcrumbs)),
  showLoader: () => dispatch(isLoading('orderDetail')),
  stopLoader: () => dispatch(forceStopLoader()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
