import { connect } from 'react-redux';
import React from 'react';
import { startCase } from 'lodash';
import APIService from '../services/APIService';
import alertifyjs from 'alertifyjs';
import GenericTable from '../components/GenericTable';
import { currentUserCompany, getCompanyIdFromCurrentRoute, isCompanyAdminOrObserver, isSystemCompany } from '../common/utils';
import { showSideDrawerForTransfer, showSideDrawerForMerge } from '../actions/companies';
import { includes } from 'lodash';
import { receiveFarm } from '../actions/companies/farms';
import { getCompanyFarmsWeb, getSelectedFarm } from '../actions/api/farms';
import { receivePaginatedCompanySites } from '../actions/companies/company-sites';


const mapStateToProps = (state, ownProps) => {
  const config={
    displayIDColumn: 'name',
    paginationData: state.companies.companySites.paginationData,
    columns: [
      {
        key: 'name',
        header: 'Site Name',
        className: 'medium',
        default: farm => (
          <div>
            <div>{farm.name}</div>
            {
              farm.externallySyncSource &&
                <div style={{color: 'gray', fontSize: '12px'}}>
                  <i>{`(Synced from ${startCase(farm.externallySyncSource)})`}</i>
                </div>
            }
          </div>
        )
      },
      { key: 'addressName', secondaryKey: 'address.address', header: 'Address', className: 'medium',
        map: {
          name: 'address.address',
          lat: 'address.latitude',
          lng: 'address.longitude'
        },
        inlineMap: true
      },
      { key: 'mobile', header: 'Phone/Mobile', className: 'xsmall'},
      { key: 'marketZoneName', header: 'Market Zone' , className: 'medium'},
      { key: 'regionName', header: 'Region' , className: 'medium'},
    ],
    items: state.companies.companySites.items,
    scrollToTopOnUpdate: false,
    orderBy: 'name',
    order: 'asc',
    optionsItems: (item) => {
      let options = ownProps.isArchived ? [] : [
        { key: 'settings', text: 'Settings' },
      ];
      const stockOptions = ownProps.isArchived ? [] : [{ key: 'stocks', text: 'Stocks' }];
      let moreOptions = [];
      if (isSystemCompany() && !ownProps.isArchived )
        moreOptions =  [{ key: 'transfer', text: 'Transfer' }];

      if (isSystemCompany() && !ownProps.isArchived )
        moreOptions.push({ key : 'merge', text: 'Merge' });

      options = [...options, ...moreOptions];
      if(item?.isManagedByUser || isSystemCompany() || (ownProps?.siteCompanyId === currentUserCompany().id))
        options = [...options, ...stockOptions]

      if (isSystemCompany() || (ownProps?.siteCompanyId == currentUserCompany()?.id && isCompanyAdminOrObserver())) {
        var option = ownProps.isArchived ? { key : 'unarchive', text: 'Unarchive' } : { key : 'archive', text: 'Archive' };
        options = [...options, option]
      }

      return options;
    },
    clearSearch: receivePaginatedCompanySites,
    globalSearch: true,
    hardClear: true
  };

  return config;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    navigateTo: (url) => {
      dispatch(getCompanyFarmsWeb(null, url, true, true, !ownProps.isArchived, true));
    },
    handleDefaultCellClick: (item) => {
      if (ownProps.isArchived) {
        var message = (isCompanyAdminOrObserver() || isSystemCompany()) ? 'Please unarchive this site to view details.' : 'To view details please contact the company admin to unarchive this site.';
        alertifyjs.alert(
          'Warning',
          message,
          () => {}
          );
      } else
        document.location.hash = `#/farms/${item.id}/settings/details`;
    },
    handleOptionClick: (index, key, id, item) => {
      if (key === 'transfer') {
        item.entity = 'farm';
        dispatch(showSideDrawerForTransfer(item, true));
      }
      else if (key === 'merge') {
        dispatch(showSideDrawerForMerge(item, true));
      }
      else if (includes(['archive', 'unarchive'], key)) {
        APIService.farms(item.id).put({'isActive': Boolean(ownProps.isArchived)}).then(response=> {
          if(response?.errors)
            alertifyjs.alert('Warning',response.errors);
          else {
          let keyWord = ownProps.isArchived ? 'Unarchived' : 'Archived';
          alertifyjs.success(`Site ${keyWord}`, 3, () => {
            window.location.reload()
          })
          }
        })
      }
      else if(key == 'settings'){
        dispatch(receiveFarm(null));
        dispatch(getSelectedFarm(id, receiveFarm));
        document.location.hash = `#/farms/${id}/settings/details`;
      }
      else if(key == 'stocks')
        document.location.hash = `#/stocks/storages-view?farmId=${id}`;
    },
    getSearchSortUrl: (pageSize, page, searchText, orderBy, order) => {
      const companyId = getCompanyIdFromCurrentRoute() || currentUserCompany().id;
      const companyService = APIService.companies(companyId);
      companyService.appendToUrl(`farms/web/search/?include_unaccepted=true&page_size=${pageSize}`);
      if (page) {
        companyService.appendToUrl(`&page=${page}`);
      }
      if(searchText) {
        companyService.appendToUrl(`&search=${searchText}`);
      }
      if(orderBy) {
        companyService.appendToUrl(`&order_by=${orderBy}&order=${order}`);
      }

      return companyService.URL;
    },
    changePageSize: (url, pageSize) => {
      if (includes(url, '?')){
        url = `${url}&page_size=${pageSize}`;
      } else {
        url = `${url}?page_size=${pageSize}`;
      }
      dispatch(getCompanyFarmsWeb(null, url, true, true, !ownProps.isArchived, true));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericTable);
