import axios from 'axios';
import alertifyjs from 'alertifyjs';
import {apiURL, forceStopLoader, loaded, setBreadcrumbs, setHeaderText, setSubHeaderText, isLoading} from '../main';
import APIService from "../../services/APIService";
import {raiseOrderAmendRequest, receiveFreight} from "./freights";
import {
  getZendeskURL, openURLInNewTab, isAtGlobalOrders, getPageCache,
  addPageCacheQueryParamsToUrl, currentUserCompany
} from '../../common/utils';
import includes from 'lodash/includes';
import compact from 'lodash/compact';
import {CALL_ON_GRAIN_TYPE_ID, PICKUP_REQUEST_ORDER_TYPE_ID, DELIVERY_REQUEST_ORDER_TYPE_ID, PACK_ORDER_TYPE_ID, REQUEST_ORDER_TYPE_IDS, REQUEST_ORDER_TYPE, FREIGHT_CONTRACT_TYPE} from '../../common/constants';
import get from "lodash/get";
import has from 'lodash/has';
import {canCreateFreightInvoiceForOrder} from "./invoices";
import isEmpty from "lodash/isEmpty";
import isArray from "lodash/isArray";
import packageJson from '../../../package.json';
import { CHECKPOINT_ORDER_CREATION_PERMISSIONS } from '../../components/freights/Constants';

const VERSION = packageJson?.version
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_PAGINATED_FREIGHT_ORDERS_SUCCESS = 'GET_PAGINATED_FREIGHT_ORDERS_SUCCESS';
export const GET_PAGINATED_ORDERS_SUCCESS = 'GET_PAGINATED_ORDERS_SUCCESS';
export const ADD_ORDER_FAILURE = 'ADD_ORDER_FAILURE';
export const GET_ORDER_FIXTURES_SUCCESS = 'GET_ORDER_FIXTURES_SUCCESS';
export const SET_SELECTED_ORDER_ID = 'SET_SELECTED_ORDER_ID';
export const SET_SELECTED_ALLOCATION_ID = 'SET_SELECTED_ALLOCATION_ID';
export const START_FETCHING_SELECTED_ORDER = 'START_FETCHING_SELECTED_ORDER';
export const RESET_IS_FETCHING_SELECTED_ORDER = 'RESET_IS_FETCHING_SELECTED_ORDER';
export const RECEIVE_ORDER = 'RECEIVE_ORDER';
export const RECEIVE_ALLOCATION = 'RECEIVE_ALLOCATION';
export const CLEAR_ORDER = 'CLEAR_ORDER';
export const FREIGHT_ORDER_ACTION_OPTIONS = 'FREIGHT_ORDER_ACTION_OPTIONS';
export const CAN_RAISE_VOID_REQUEST_FOR_ORDER = 'CAN_RAISE_VOID_REQUEST_FOR_ORDER';
export const CAN_RAISE_VOID_AND_DUPLICATE_REQUEST_FOR_ORDER = 'CAN_RAISE_VOID_AND_DUPLICATE_REQUEST_FOR_ORDER';
export const CAN_CLOSE_OUT_FOR_ORDER = 'CAN_CLOSE_OUT_FOR_ORDER';
export const CAN_ASSIGN_PARENT_FOR_ORDER = 'CAN_ASSIGN_PARENT_FOR_ORDER';
export const SHOULD_FETCH_ORDER = 'SHOULD_FETCH_ORDER';
export const CLICKED_OPTION = 'CLICKED_OPTION';
export const ALLOCATED_CONTRACT_ORDER_WARNING = 'ALLOCATED_CONTRACT_ORDER_WARNING';
const MESSAGES = {
  CREATE_SUCCESS: 'Successfully created!',
  UPDATE_SUCCESS: 'Order was successfully updated'
};
export const allocatedContractOrderWarning = (orderWarningFlag, orderWarningList) => ({
  orderWarningFlag,
  orderWarningList,
  type: ALLOCATED_CONTRACT_ORDER_WARNING,
});
export const addOrderFailure = (errors) => {
  return ({ type: ADD_ORDER_FAILURE, errors });
};
export const getOrdersResponse = (orders) => {
  return ({ type: GET_ORDERS_SUCCESS, orders });
};
export const getPaginatedFreightOrdersResponse = (paginatedOrders) => {
  return ({ type: GET_PAGINATED_FREIGHT_ORDERS_SUCCESS, paginatedOrders});
};
export const getPaginatedOrdersResponse = (paginatedOrders) => {
  return ({ type: GET_PAGINATED_ORDERS_SUCCESS, paginatedOrders});
};
export const getOrderFixturesResponse = (fixtures) => {
  return ({ type: GET_ORDER_FIXTURES_SUCCESS, fixtures });
};

const startFetchingSelectedOrder = () => ({
  type: START_FETCHING_SELECTED_ORDER,
});

const resetIsFetchingSelectedOrder = () => ({
  type: RESET_IS_FETCHING_SELECTED_ORDER,
});

export const receiveOrder = item => ({
  type: RECEIVE_ORDER,
  item
});

export const receiveAllocation = item => ({
  type: RECEIVE_ALLOCATION,
  item
});

export const clearOrder = item => ({
  type: CLEAR_ORDER,
  item
});

export const freightOrderActionOptions = subItems => {
  return { type: FREIGHT_ORDER_ACTION_OPTIONS, subItems };
};

export const canRaiseVoidRequestForSelectedFreightOrder = flag => ({
  type: CAN_RAISE_VOID_REQUEST_FOR_ORDER,
  flag,
});

export const canRaiseVoidAndDuplicateRequestForSelectedFreightOrder = flag => ({
  type: CAN_RAISE_VOID_AND_DUPLICATE_REQUEST_FOR_ORDER,
  flag,
});

export const canCloseOutForSelectedFreightOrder = flag => ({
  type: CAN_CLOSE_OUT_FOR_ORDER,
  flag
});

export const canAssignParentForSelectedFreightOrder = flag => ({
  type: CAN_ASSIGN_PARENT_FOR_ORDER,
  flag
});

export const shouldFetchFreightOrder = flag => ({
  type: SHOULD_FETCH_ORDER,
  flag,
});

export const setClickedOption = option => ({
  type: CLICKED_OPTION,
  option,
});

export const createOrdersForAllocatedContract = (contractId, path, callback) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.contracts().appendToUrl(`${contractId}${path}`).get(token)
    .then(response => {
      response = response.filter((item) => !get(item, 'counterPartyTransactionParticipator'));
      if (response.length > 0) {
        dispatch(allocatedContractOrderWarning(true, response));
      }
      else {
        dispatch(allocatedContractOrderWarning(false, []));
        callback();
      }
  });
}

export const getOrders = (parentOrderId='', commodityContractId='', url='', _forceStopLoader=false, onlyLinked=false, unit) => {
  return (dispatch, getState) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${getState().main.user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };
    if(unit)
      config.headers['REFERER-UNIT'] = unit


    let orderListUrl = (url) ? url : (parentOrderId) ? `${apiURL}/freights/orders/web/?parent_order_id=${parentOrderId}&type_id=1&type_id=2` : (commodityContractId) ? `${apiURL}/freights/orders/web/?commodity_contract_id=${commodityContractId}&type_id=1&type_id=2` : `${apiURL}/freights/orders/web/?type_id=1&type_id=2`;
    if (includes([CALL_ON_GRAIN_TYPE_ID, PICKUP_REQUEST_ORDER_TYPE_ID, DELIVERY_REQUEST_ORDER_TYPE_ID], get(getState(), 'companies.orders.selectedOrder.typeId')))
      orderListUrl += '&linked=true'
    if(includes(orderListUrl, '?')) {
      const urlParts = orderListUrl.split('?');
      const queryString = new URLSearchParams(urlParts[1]);
      queryString.delete('type_id');
      orderListUrl = urlParts[0] + '?' + queryString.toString() + '&type_id=1&type_id=2';
    }

    let cache = getPageCache();
    if(!parentOrderId && !commodityContractId && isAtGlobalOrders()) {
      orderListUrl = addPageCacheQueryParamsToUrl(orderListUrl, 'orders/freights')  
    } else if(has(cache, 'pageSize')) {
      const pageSize = get(cache, 'pageSize');
      let joiner = '?';
      if(orderListUrl.indexOf('?') > -1)
        joiner = '&';
      orderListUrl += `${joiner}page_size=${pageSize}`;
    }

    if(onlyLinked)
      orderListUrl += orderListUrl.indexOf('?') > -1 ? '&linked=true' : '?linked=true'

    axios.get(orderListUrl, config)
      .then((response) => {
        dispatch(getPaginatedFreightOrdersResponse(response.data));
        if(_forceStopLoader) {
          dispatch(forceStopLoader());
        }
      })
      .catch((error) => {
        throw (error);
      });
  };
};


export const getCallOnGrainOrders = (parentOrderId='', commodityContractId='', url='', _forceStopLoader=false, unit) => {
  return (dispatch, getState) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${getState().main.user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };
    if(unit)
      config.headers['REFERER-UNIT'] = unit


    let orderListUrl = (url) ? url : (parentOrderId) ? `${apiURL}/freights/orders/web/?parent_order_id=${parentOrderId}&type_id=3` : (commodityContractId) ? `${apiURL}/freights/orders/web/?commodity_contract_id=${commodityContractId}&type_id=3` : `${apiURL}/freights/orders/web/?type_id=3`;

    if(includes(orderListUrl, '?')) {
      const urlParts = orderListUrl.split('?');
      const queryString = new URLSearchParams(urlParts[1]);
      queryString.delete('type_id');
      queryString.set('type_id', 3);
      orderListUrl = urlParts[0] + '?' + queryString.toString();
    }

    if(!includes(orderListUrl, 'cog')) {
      if(!includes(orderListUrl, '?'))
        orderListUrl += '?cog=true';
      else
        orderListUrl += '&cog=true';
    }

    if(!parentOrderId && !commodityContractId && isAtGlobalOrders()) {
      orderListUrl = addPageCacheQueryParamsToUrl(orderListUrl, 'orders/grain')
    }

    axios.get(orderListUrl, config)
      .then((response) => {
        dispatch(getPaginatedOrdersResponse(response.data));
        if(_forceStopLoader) {
          dispatch(forceStopLoader());
        }
      })
      .catch((error) => {
        throw (error);
      });
  };
};

export const getRequestOrders = (url='', _forceStopLoader=false, callback = () => {}) => {
  return (dispatch, getState) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${getState().main.user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };

    let orderListUrl = (url) ? url : `${apiURL}/freights/orders/requests/web/`;

    if(includes(orderListUrl, '?')) {
      const urlParts = orderListUrl.split('?');
      const queryString = new URLSearchParams(urlParts[1]);
      orderListUrl = urlParts[0] + '?' + queryString.toString();
    }

    if(isAtGlobalOrders()) {
      orderListUrl = addPageCacheQueryParamsToUrl(orderListUrl, 'orders/requests')
    }

    axios.get(orderListUrl, config)
      .then((response) => {
        dispatch(getPaginatedOrdersResponse(response.data));
        if(_forceStopLoader) {
          dispatch(forceStopLoader());
        }
        callback(response?.data?.results || [])
      })
      .catch((error) => {
        throw (error);
      });
  };
};

export const getPackOrders = (url='', _forceStopLoader=false, callback = () => {}) => {
  return (dispatch, getState) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${getState().main.user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };

    let orderListUrl = (url) ? url : `${apiURL}/freights/orders/web/?type_id=6`;

    if(includes(orderListUrl, '?')) {
      const urlParts = orderListUrl.split('?');
      const queryString = new URLSearchParams(urlParts[1]);
      queryString.delete('type_id');
      orderListUrl = urlParts[0] + '?' + queryString.toString() + '&type_id=6';
    }

    if(isAtGlobalOrders()) {
      orderListUrl = addPageCacheQueryParamsToUrl(orderListUrl, 'orders/packing')
    }

    axios.get(orderListUrl, config)
      .then((response) => {
        dispatch(getPaginatedOrdersResponse(response.data));
        if(_forceStopLoader) {
          dispatch(forceStopLoader());
        }
        callback(response?.data?.results || [])
      })
      .catch((error) => {
        throw (error);
      });
  };
};

export const create = (data, reload=false, isCallOnGrain=false, isOrderTemplate=false, isParentCallOnGrain=false) => (dispatch, getState) => {
  fetch(`${apiURL}/freights/orders/`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${getState().main.user.token}`
    },
  }).then(response => response.json())
    .then((json) => {
      dispatch(loaded());
      if(json.id == null) {
        if (has(json, 'errors.identifier')) {
          alertifyjs.error(json.errors['identifier'][0]);
          dispatch(forceStopLoader());
        }
        else if(has(json.errors, '_All_')){
          alertifyjs.error(json.errors._All_[0]);
        } else {
          dispatch(forceStopLoader());
          dispatch(addOrderFailure(json.errors));
        }
      } else {
        const orderType = isCallOnGrain ? 'grain' : 'freights';
        alertifyjs.success(MESSAGES.CREATE_SUCCESS, 1, () => {
          if(reload)
            window.location.reload();
          else {
            if(isOrderTemplate) {
              dispatch(forceStopLoader());
              return
            }
            let ordersLocation = '#/orders/freights';
            if((!json.parentOrderId || isParentCallOnGrain) && json.commodityContractId){
              document.location = '#/contracts/' + json.commodityContractId + '/orders/' + orderType +
                `?orderId=${json.id}&orderType=fo`;
                window.location.reload();
            } else if (!json.parentOrderId && !json.commodityContractId) {
              document.location = '#/freights/orders/' + json.id + '/order';
            } else if(json.parentOrderId && isCallOnGrain) {
              document.location =  `#/freights/orders/${json.parentOrderId}?orderId=${json.id}&orderType=fa`;
            } else if(json.parentOrderId){
                document.location = `#/freights/orders/${json.parentOrderId}/allocations?orderId=${json.id}&orderType=fa`;
            } else {
              document.location = ordersLocation;
            }
          }
        });
      }
    });
};

export const getOrderFixtures = () => {
  return (dispatch, getState) => {
    const currentUser = getState().main.user;
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${currentUser.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };

    const URL = `${apiURL}/freights/contracts/fixtures/`;
    axios(URL, config)
      .then((response) => {
        dispatch(getOrderFixturesResponse(response.data));
      })
      .catch((error) => {
        throw (error);
      });
  };
};

export const setSelectedOrderId = orderId => {
  return {
    type: SET_SELECTED_ORDER_ID,
    orderId,
  };
};

export const setSelectedAllocationId = orderId => {
  return {
    type: SET_SELECTED_ALLOCATION_ID,
    orderId,
  };
};


export const getSelectedOrder = (orderId, actionCreator,redirectPage = true, inActivatedUserToken, isEditingMode = false, setHeaderAndBreadcrumbs = true, _forceStopLoader = true, unit) => (dispatch,getState) => {
  const token = getState().main.user.token || inActivatedUserToken;
  const { isFetchingSelectedOrder } = getState().companies.orders;
  if (!isFetchingSelectedOrder) {
    dispatch(receiveFreight(null));
    dispatch(startFetchingSelectedOrder());
    const headers = unit ? {'REFERER-UNIT' : unit} : null
    APIService.freights().orders(orderId).get(token, headers).then((item) => {
      dispatch(actionCreator(item));
      if(_forceStopLoader)
        dispatch(forceStopLoader());

      const orderType = item.isCallOnGrain ? 'grain' : 'freights';
      if(setHeaderAndBreadcrumbs) {
        const headerText = item ? ('Freight Order ' + item.identifier) : '';
        const subHeaderText = (item && item.commodity) ? ('(' + item.commodity.displayName) : '';
        const subHeader2 = (item && item.plannedTonnage) ? (parseFloat(item.plannedTonnage).toFixed(2) + ' ' + (item.isStrictQuantityBasedCommodity ? item.quantityUnit : item.tonnageUnit || 'MT') + ')') : ')';
        const breadcrumbs = [
          {text: 'Orders', route: `/orders/${orderType}`},
          {text: (item && item.identifier) ? item.identifier : ''}
        ];
        dispatch(setHeaderText(headerText));
        dispatch(setSubHeaderText(subHeaderText + ' ' + subHeader2));
        dispatch(setBreadcrumbs(breadcrumbs));
      }
      if(redirectPage && isEditingMode){
        document.location = '#/orders/' + orderId + '/edit';
      } else if (isEditingMode || redirectPage){
        document.location='#/orders/' + orderId + '/order';
      }
    }).catch(() => {
      dispatch(resetIsFetchingSelectedOrder());
    });
  }
};

export const getSelectedOrderForMenu = (orderId, actionCreator,redirectPage = true, inActivatedUserToken, isEditingMode = false, setHeaderAndBreadcrumbs = true, _forceStopLoader = true) => (dispatch,getState) => {
  const token = getState().main.user.token || inActivatedUserToken;
  dispatch(receiveFreight(null));
  APIService.freights().orders(orderId).get(token).then((item) => {
    dispatch(actionCreator(item));
    if(_forceStopLoader)
      dispatch(forceStopLoader());

    const orderType = item.isCallOnGrain ? 'grain' : 'freights';
    if(setHeaderAndBreadcrumbs) {
      const headerText = item ? ('Freight Order ' + item.identifier) : '';
      const subHeaderText = (item && item.commodity) ? ('(' + item.commodity.displayName) : '';
      const subHeader2 = (item && item.plannedTonnage) ? (parseFloat(item.plannedTonnage).toFixed(2) + ' ' + (item.isStrictQuantityBasedCommodity ? item.quantityUnit : item.tonnageUnit || 'MT') + ')') : ')';
      const breadcrumbs = [
        {text: 'Orders', route: `/orders/${orderType}`},
        {text: (item && item.identifier) ? item.identifier : ''}
      ];
      dispatch(setHeaderText(headerText));
      dispatch(setSubHeaderText(subHeaderText + ' ' + subHeader2));
      dispatch(setBreadcrumbs(breadcrumbs));
    }
    if(redirectPage && isEditingMode){
      document.location = '#/orders/' + orderId + '/edit';
    } else if (isEditingMode || redirectPage){
      document.location='#/orders/' + orderId + '/order';
    }
  }).catch(() => {
    dispatch(resetIsFetchingSelectedOrder());
  });
};


export const canCreateMovementForOrder = (orderId) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService['freights/orders'](orderId)['freight/contracts/new']().get(token).then(
    (res) => {
      if(res.result) {
        dispatch(isLoading('movementFormFromContract'));
        window.location.hash = `#/freights/movements/new?orderId=${orderId}`;
      } else {
        const reasons = "<li>" + res.reasons.join("</li><li>");
        alertifyjs.alert(
          'Permission Denied',
          `<div className=""><p>You do not have permission to create a Freight Movement for this Order because:</p><ul>${reasons}</ul><div>Please follow <a href=${getZendeskURL()} target='_blank'>FAQs</a> for more details</div></div>`,
          () => {}
        );
      }
    });
};

export const canCreateAllocationForOrder = (orderId, isRequestOrder) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService['freights/orders'](orderId)['freight/allocations/new']().get(token).then(
    (res) => {
      if(res.result) {
        dispatch(isLoading('orderFormFromOrder'));
        let url = `#/freights/orders/new?orderId=${orderId}`
        if(isRequestOrder)
          url += '&parent=requestorder'
        window.location.hash = url;
      } else {
        const reasons = "<li>" + res.reasons.join("</li><li>");
        alertifyjs.alert(
          'Permission Denied',
          `<div className=""><p>You do not have permission to create Order because:</p><ul>${reasons}</ul><div>Please follow <a href=${getZendeskURL()} target='_blank'>FAQs</a> for more details</div></div>`,
          () => {}
        );
      }
    });
};

export const getFreightOrderActionOptions = (orderId, type='default') => (dispatch, getState) => {
  dispatch(freightOrderActionOptions(undefined));
  const token = getState().main.user.token;
  const freightOrder = APIService.freights().orders(orderId);
  freightOrder.appendToUrl(`action-options/?type=${type}`);
  freightOrder.get(token).then(subItems => {
    dispatch(freightOrderActionOptions(get(subItems, '[0].subItems')));
  });
};


export const canFreightOrderBeVoided = (event, orderId, callback) => (dispatch, getState) => {
  dispatch(canRaiseVoidRequestForSelectedFreightOrder(false));
  const token = getState().main.user.token;
  const order = APIService.freights().orders(orderId);
  order.appendToUrl(`action-options/?type=can-void`);
  order.get(token).then(res => {
    let reasons = get(res[0], 'cannotRaiseVoidRequestReasons', undefined);
    const isAnyPlannedMovementExists = get(res[0], 'isAnyPlannedMovementExists');
    const entityName = get(res[0], 'entityName', undefined);
      if (!isEmpty(reasons)) {
        reasons = '<li>' + reasons.join('</li><li>');
        alertifyjs.alert(
            'Permission Denied',
            `<div><p>You cannot void this ${entityName || 'Freight Order'} because:</p><ul>${reasons}</ul></div>`,
            () => {}
          );
        event.preventDefault();
      } else if(isArray(reasons) && isEmpty(reasons)){
        if(isAnyPlannedMovementExists)
          alertifyjs.confirm(
            'Warning',
            'Some of the Freight Movements are planned. This will mark all your planned movements as void. Are you sure to Void this order ?',
            () => {
              if (callback)
                callback();
              dispatch(canRaiseVoidRequestForSelectedFreightOrder(true));
            },
            () => {}
          );
        else{
          if (callback)
            callback();
          dispatch(canRaiseVoidRequestForSelectedFreightOrder(true));
        }
      }
    });
};

export const canRequestOrderBeDuplicated = orderObj => {
  if(!REQUEST_ORDER_TYPE_IDS.includes(orderObj.typeId))
    return true

  if(isRequestOrderCreationAllowed(orderObj))
    return true

  const permission = orderObj.customerCheckpointOrderCreationPermission
  const employeeLabel = permission === CHECKPOINT_ORDER_CREATION_PERMISSIONS.SITE_ONLY ? 'site employees' : 'site employees and grain owners';
  alertifyjs.alert(
    'Permission Denied',
    `Only ${employeeLabel} can create the Pickup/Delivery orders for this site. Please contact the site employees for generating the Pickup/Delivery Order numbers.`
  )
  return false
}

export const canGrainOrderBeDuplicated = orderObj => {
  const canCreateCallOnGrainOrder = get(orderObj, 'canCreateCallOnGrainOrder')

  if(canCreateCallOnGrainOrder)
    return true

  alertifyjs.alert(
    'Permission Denied',
    `<div><p>You cannot duplicate this Grain Order since you don't have the Delivery Onus for the related contract. Please contact the other contract party to create another order.</p></div>`,
    () => {}
  );
  return false
}

export const isRequestOrderCreationAllowed = orderObj => {
  const customerCheckpointOrderCreationPermission = orderObj.customerCheckpointOrderCreationPermission;

  const handler = (orderObj.typeId == REQUEST_ORDER_TYPE.DELIVERY_ORDER) ? get(orderObj, 'freightDelivery.consignee.handler') : get(orderObj,'freightPickup.consignor.handler')

  const handlerCompanyId = get(handler, 'companyId') || orderObj.handlerCompanyId
  const customerCompanyId = get(orderObj, 'customer.companyId') || orderObj.customerCompanyId
  const currentUserCompanyId = currentUserCompany().id

  let isAllowed = true;
  if(customerCheckpointOrderCreationPermission === CHECKPOINT_ORDER_CREATION_PERMISSIONS.SITE_ONLY)
    isAllowed = currentUserCompanyId === handlerCompanyId;
  else if (customerCheckpointOrderCreationPermission && customerCompanyId){
    const validIds = compact([customerCompanyId, handlerCompanyId])
    isAllowed = includes(validIds, currentUserCompanyId);
  }

  return isAllowed
}

export const redirectDuplicateRequestOrder = orderObj => {
  if (canRequestOrderBeDuplicated(orderObj)){
    const customerCheckpointOrderCreationPermission = orderObj.customerCheckpointOrderCreationPermission;
    if(customerCheckpointOrderCreationPermission === CHECKPOINT_ORDER_CREATION_PERMISSIONS.GRAIN_OWNER_AND_SITE_ACCEPTANCE_FROM_SITE)
      alertifyjs.confirm(
        'Warning',
        `This site requires acceptance from the site employees before creating the Pickup/Delivery orders. Are you sure want to Duplicate this order?`,
        () => document.location.hash = `#/orders/requests/new?copyFrom=${orderObj.id}`,
        () => {}
      ).set('labels', { ok: 'Proceed', cancel: 'Cancel' });
    else
      document.location.hash = `#/orders/requests/new?copyFrom=${orderObj.id}`;
  }
}

export const canFreightOrderBeVoidedAndDuplicated = (event, existingOrder, callback) => dispatch => {
  if(REQUEST_ORDER_TYPE_IDS.includes(existingOrder.typeId) && !canRequestOrderBeDuplicated(existingOrder))
    return

  if(existingOrder.typeId === FREIGHT_CONTRACT_TYPE.CALL_ON_GRAIN && !canGrainOrderBeDuplicated(existingOrder))
    return

  dispatch(canRaiseVoidAndDuplicateRequestForSelectedFreightOrder(false));
  const order = APIService.freights().orders(existingOrder.id);
  order.appendToUrl(`action-options/?type=can-void`);
  order.get().then(res => {
    let reasons = get(res[0], 'cannotRaiseVoidRequestReasons', []);
    const isAnyPlannedMovementExists = get(res[0], 'isAnyPlannedMovementExists');
    const entityName = get(res[0], 'entityName', undefined);

    if (!isEmpty(reasons)) {
      reasons = '<li>' + reasons.join('</li><li>');
      alertifyjs.alert(
          'Permission Denied',
          `<div><p>You cannot void this ${entityName || 'Freight Order'} because:</p><ul>${reasons}</ul></div>`,
          () => {}
        );
      event.preventDefault();
    } else if(isArray(reasons) && isEmpty(reasons)){
      const customerCheckpointOrderCreationPermission = existingOrder.customerCheckpointOrderCreationPermission;
      let warnings = [];

      if(isAnyPlannedMovementExists)
        warnings.push('Some of the Freight Movements are planned. This will mark all your planned movements as void.');

      if(customerCheckpointOrderCreationPermission === CHECKPOINT_ORDER_CREATION_PERMISSIONS.GRAIN_OWNER_AND_SITE_ACCEPTANCE_FROM_SITE)
        warnings.push('This site requires acceptance from the site employees before creating the Pickup/Delivery orders.');

      let warningString = '';
      if(warnings.length > 0)
        warningString = warnings[0]

      if(warnings.length > 1)
        warningString = '<ul>' + warnings.map(warning => `<li>${warning}</li>`).join('') + '</ul>'

      alertifyjs.confirm(
          'Warning',
          `This will void the current order and allow you to create a new one with similar details. ${warningString}`,
          () => {
            if (callback)
              callback();
            dispatch(canRaiseVoidAndDuplicateRequestForSelectedFreightOrder(true));
          },
          () => {}
      ).set('labels', { ok: 'Proceed', cancel: 'Cancel' });
      }
    });
};

export const canFreightOrderBeAmended = (event, orderId, callback, entity) => (dispatch, getState) => {
  const token = getState().main.user.token;
  const order = APIService.freights().orders(orderId);
  order.appendToUrl(`action-options/?type=can-amend`);
  order.get(token).then(res => {
    let reasons = get(res[0], 'cannotRaiseAmendRequestReasons', undefined);
      if (!isEmpty(reasons)) {
        reasons = '<li>' + reasons.join('</li><li>');
        alertifyjs.alert(
            'Permission Denied',
            `<div><p>You cannot amend this Order because:</p><ul>${reasons}</ul></div>`,
            () => {}
          );
        event.preventDefault();
      } else if(isArray(reasons) && isEmpty(reasons)){
         if (callback)
           callback();
        if(entity && (entity.typeId === 4 || entity.typeId === 5))
          window.location.hash = `#/orders/requests/${orderId}/edit`;
        else if (entity && entity.typeId === 6)
          window.location.hash = `#/pack/orders/${orderId}/edit`;
        else
          window.location.hash = `#/freights/orders/${orderId}/edit`;
      }
    });
};

export const assignParentToOrder = (orderId, data, successMsg) => () => {
  APIService.freights()
  .orders(orderId)
  .appendToUrl('assign-parent/')
  .post(data)
  .then(response => {
    if (has(response, 'errors'))
      alertifyjs.error(get(response, 'errors.0', 'An Error Occurred!'));
    else
      alertifyjs.success(successMsg, 1, () => window.location.reload());
  });
};

export const canAssignParentToFreightOrder = (event, orderId, item, callback) => (dispatch) => {
  dispatch(canAssignParentForSelectedFreightOrder(false));
  const freightOrder = APIService.freights().orders(orderId);
  freightOrder.appendToUrl(`action-options/?type=can-assign-parent`);
  freightOrder.get().then(res => {
    let reasons = get(res[0], 'cannotAssignParentReasons', undefined);
    if (!isEmpty(reasons)) {
      reasons = '<li>' + reasons.join('</li><li>');
      alertifyjs.alert(
        'Permission Denied',
        `<div><p>You cannot assign parent to this Freight Order because:</p><ul>${reasons}</ul></div>`,
        () => { }
      );
      event.preventDefault();
    } else if (isArray(reasons) && isEmpty(reasons)) {
      if (callback)
        callback();
      dispatch(setClickedOption(item));
      dispatch(canAssignParentForSelectedFreightOrder(true));
    }
  });
};

export const canFreightOrderBeClosedOut = (event, orderId, callback) => (dispatch, getState) => {
  dispatch(isLoading('dialog'));
  dispatch(canCloseOutForSelectedFreightOrder(false));
  const token = getState().main.user.token;
  const freightOrder = APIService.freights().orders(orderId);
  freightOrder.appendToUrl(`action-options/?type=can-close-out`);
  freightOrder.get(token).then(res => {
    dispatch(forceStopLoader());
    let reasons = get(res[0], 'cannotCloseOutReasons', undefined);
    if (!isEmpty(reasons)) {
      reasons = '<li>' + reasons.join('</li><li>');
      alertifyjs.alert(
        'Permission Denied',
        `<div><p>You cannot close out this Freight Order because:</p><ul>${reasons}</ul></div>`,
        () => { }
      );
      event.preventDefault();
    } else if (isArray(reasons) && isEmpty(reasons)) {
      if (callback)
        callback();
      dispatch(canCloseOutForSelectedFreightOrder(true));
    }
  });
};

export const closeOutFreightOrder = (orderId, data) => (dispatch, getState) => {
    dispatch(isLoading('dialog'));
    const token = getState().main.user.token;
    const freightOrder = APIService.freights().orders(orderId);
    freightOrder.appendToUrl(`close-out/`);
    freightOrder.post(data, token).then(res => {
        dispatch(forceStopLoader());
        let reasons = get(res, 'reasons', undefined);
        let result = get(res, 'result', undefined);
        if (!result && !res.errors) {
          reasons = '<li>' + reasons.join('</li><li>');
          alertifyjs.alert(
            'Permission Denied',
            `<div><p>You cannot close out this Freight Order because:</p><ul>${reasons}</ul></div>`,
            () => { }
          );
        } else if (isEmpty(reasons) && result) {
          alertifyjs.success('Order Closed Out', 3);
          window.location.reload();
        }
      });
};

export const handleFreightOrderOptionsMapper = (event, item, baseEntity, mappingFrom='listing') => dispatch => {
  if (mappingFrom === 'listing'){
    dispatch(setSelectedOrderId(null));
  }
  dispatch(setClickedOption(item));
  dispatch(freightOrderActionOptions([]));
  if (item.key === 'view_in_new_tab') {
    const url = `/#/freights/orders/${baseEntity.id}/order`;
    openURLInNewTab(url);
  } else if (item.key === 'freight_allocations_add') {
    dispatch(canCreateAllocationForOrder(baseEntity.id));
  } else if (item.key === 'freight_allocations_view') {
    document.location.hash = `#/freights/orders/${baseEntity.id}/allocations`;
  } else if (item.key === 'freight_movements_add') {
    dispatch(canCreateMovementForOrder(baseEntity.id));
  }  else if (item.key === 'vendor_dec_add_fo'){
    if (item.type == 'allocation')
      dispatch(setSelectedAllocationId(baseEntity.id));
    else
      dispatch(setSelectedOrderId(baseEntity.id));
    if (mappingFrom === 'listing'){
      dispatch(shouldFetchFreightOrder(true));
    }
  } else if (item.key === 'vendor_dec_view_fo') {
    if (item.type == 'allocation')
      dispatch(setSelectedAllocationId(baseEntity.id));
    else
      dispatch(setSelectedOrderId(baseEntity.id));
  } else if (item.key === 'vendor_dec_request_fo') {
    if (item.type == 'allocation')
      dispatch(setSelectedAllocationId(baseEntity.id));
    else{
      dispatch(clearOrder());
      dispatch(setSelectedOrderId(baseEntity.id));
    }
    if (mappingFrom === 'listing') {
      dispatch(shouldFetchFreightOrder(true));
    }
  } else if (item.key === 'freight_movements_view') {
    document.location.hash = `#/freights/orders/${baseEntity.id}/movements`;
  } else if (item.key === 'freight_invoice_add') {
    dispatch(canCreateFreightInvoiceForOrder(baseEntity.id));
  } else if (item.key === 'freight_invoice_view') {
    document.location.hash = `#/freights/orders/${baseEntity.id}/freights/invoices`;
  } else if (item.key === 'amend') {
    dispatch(canFreightOrderBeAmended(event, baseEntity.id, null, baseEntity));
    dispatch(setSelectedOrderId(baseEntity.id));
  } else if (item.key === 'void') {
    dispatch(canFreightOrderBeVoided(event, baseEntity.id));
    if(item.type == 'allocation'){
      dispatch(setSelectedOrderId(null));
      dispatch(setSelectedAllocationId(baseEntity.id));
    }
    else {
      dispatch(setSelectedAllocationId(null));
      dispatch(setSelectedOrderId(baseEntity.id));
    }
  }  else if(item.key === 'duplicate') {
    document.location.hash = (baseEntity.typeId == PACK_ORDER_TYPE_ID) ? `#/orders/pack/new?copyFrom=${baseEntity.id}`: `#/freights/orders/new?copyFrom=${baseEntity.id}`
  } else if (item.key === 'void_and_duplicate') {
    dispatch(canFreightOrderBeVoidedAndDuplicated(event, baseEntity, () => {
      if(item.type == 'allocation'){
        dispatch(setSelectedOrderId(null));
        dispatch(setSelectedAllocationId(baseEntity.id));
      }
      else {
        dispatch(setSelectedAllocationId(null));
        (mappingFrom == "contractDetailsModule") ? dispatch(setSelectedOrderId(baseEntity.id)) : dispatch(receiveOrder(baseEntity));
      }
    }));
  }
  else if (item.key === 'download_pdf') {
    dispatch(getFreightOrderActionOptions(baseEntity.id, 'download'));
  } else if (item.key === 'download_customer_pdf' && item.url) {
    openURLInNewTab(item.url);
  } else if (item.key === 'download_provider_pdf' && item.url) {
    openURLInNewTab(item.url);
  } else if (item.key === 'download_common_pdf' && item.url) {
    openURLInNewTab(item.url);
  } else if (item.key === 'can_or_cannot_customer_create_freight_movements') {
    dispatch(
      raiseOrderAmendRequest(baseEntity.id, {'canCustomerCreateMovements': !baseEntity.canCustomerCreateMovements},
        'Successfully Allowed', false)
    );
  } else if (item.key === 'review_amendment' && mappingFrom === 'listing') {
    dispatch(shouldFetchFreightOrder(true));
    dispatch(setSelectedOrderId(baseEntity.id));
    dispatch(isLoading('editFreightOrderReview'));
  } else if (item.key == 'close_out') {
    dispatch(canFreightOrderBeClosedOut(event, baseEntity.id));
    if (item.type == 'allocation')
      dispatch(setSelectedAllocationId(baseEntity.id));
    else
      dispatch(setSelectedOrderId(baseEntity.id));
  } else if (item.key == 'assign_to') {
    dispatch(canAssignParentToFreightOrder(event, baseEntity.id, item));
    dispatch(setSelectedOrderId(baseEntity.id));
    if (mappingFrom === 'listing')
      dispatch(shouldFetchFreightOrder(true));
  } else if (item.key == 'pack_movements_view') {
    document.location.hash = `#/pack/orders/${baseEntity.id}/movements`;
  }
  else if(item.key == 'mark_movements_delivered')
    dispatch(setSelectedOrderId(baseEntity.id));
  else if (item.key === 'show_hierarchy')
    dispatch(setSelectedOrderId(baseEntity.id));
};

export const handleCallOnGrainOrderOptionsMapper = (event, item, baseEntity, mappingFrom='listing', isRequestOrder=false) => dispatch => {
  if (mappingFrom === 'listing'){
    dispatch(clearOrder());
  }
  dispatch(setClickedOption(item));
  dispatch(freightOrderActionOptions([]));
  if (item.key === 'view_in_new_tab') {
    const url = `/#/freights/orders/${baseEntity.id}/order`;
    openURLInNewTab(url);
  } else if (item.key === 'freight_orders_add') {
    dispatch(canCreateAllocationForOrder(baseEntity.id, isRequestOrder));
  } else if (item.key === 'freight_orders_view') {
    document.location.hash = `#/freights/orders/${baseEntity.id}`;
  } else if (item.key === 'freight_movements_add') {
    dispatch(canCreateMovementForOrder(baseEntity.id));
  } else if (item.key === 'freight_movements_view') {
    document.location.hash = `#/freights/orders/${baseEntity.id}/movements`;
  } else if (item.key === 'amend') {
    dispatch(canFreightOrderBeAmended(event, baseEntity.id, null, baseEntity));
    dispatch(setSelectedOrderId(baseEntity.id));
  } else if (item.key === 'void') {
    dispatch(canFreightOrderBeVoided(event, baseEntity.id));
    dispatch(setSelectedOrderId(baseEntity.id));
  }  else if(item.key === 'duplicate') {
    if(REQUEST_ORDER_TYPE_IDS.includes(baseEntity.typeId))
      redirectDuplicateRequestOrder(baseEntity);
    else if(baseEntity.typeId === FREIGHT_CONTRACT_TYPE.CALL_ON_GRAIN){
      if (canGrainOrderBeDuplicated(baseEntity))
        document.location.hash = `#/freights/grain/new?copyFrom=${baseEntity.id}`;
    }
  } else if (item.key === 'void_and_duplicate') {
    dispatch(canFreightOrderBeVoidedAndDuplicated(event, baseEntity, () =>
      dispatch(receiveOrder(baseEntity))
    ));
  } else if (item.key === 'download_pdf') {
    dispatch(getFreightOrderActionOptions(baseEntity.id, 'download'));
  } else if (item.key === 'download_creator_pdf' && item.url) {
    openURLInNewTab(item.url);
  } else if (item.key === 'can_or_cannot_customer_create_freight_movements') {
    dispatch(
      raiseOrderAmendRequest(baseEntity.id, {'canCustomerCreateMovements': !baseEntity.canCustomerCreateMovements},
        'Successfully Allowed', false)
    );
  } else if (item.key === 'review_amendment' && mappingFrom === 'listing') {
    dispatch(shouldFetchFreightOrder(true));
    dispatch(setSelectedOrderId(baseEntity.id));
    dispatch(isLoading('editFreightOrderReview'));
  } else if (item.key === 'vendor_dec_add_cog'){
    dispatch(setSelectedOrderId(baseEntity.id));
    if (mappingFrom === 'listing'){
      dispatch(shouldFetchFreightOrder(true));
    }
  } else if (item.key === 'vendor_dec_view_cog'){
    dispatch(setSelectedOrderId(baseEntity.id));
  } else if (item.key === 'vendor_dec_request_cog'){
    dispatch(setSelectedOrderId(baseEntity.id));
    if (mappingFrom === 'listing'){
      dispatch(shouldFetchFreightOrder(true));
    }
  } else if (item.key == 'close_out') {
    dispatch(canFreightOrderBeClosedOut(event, baseEntity.id));
    dispatch(setSelectedOrderId(baseEntity.id));
  } else if (item.key == 'assign_to') {
    dispatch(canAssignParentToFreightOrder(event, baseEntity.id, item));
    dispatch(setSelectedOrderId(baseEntity.id));
    if (mappingFrom === 'listing')
      dispatch(shouldFetchFreightOrder(true));
  } else if(item.key == 'mark_movements_delivered')
    dispatch(setSelectedOrderId(baseEntity.id));
  else if (item.key === 'show_hierarchy')
    dispatch(setSelectedOrderId(baseEntity.id));
};

export const markMovementsDelivered = orderId => {
  APIService.freights()
    .orders(orderId)
    .appendToUrl('deliver/')
    .put()
    .then(() => alertifyjs.success('Marked Movements Delivered', 3, () => window.location.reload()));
}
