import React from 'react';
import { connect } from 'react-redux';
import OrderDetails from '../freights/order-details/OrderDetails';
import {OrderDetailsBasicInfo} from "../freights/order-details/OrderDetailsBasicInfo";
import {receiveOrder, receiveAllocation, getSelectedOrder} from "../../actions/companies/orders";
import {
  setBreadcrumbs, isLoading
} from '../../actions/main';
import get from 'lodash/get';
import Orders from "../freights/Orders";
import { getAbsoluteUrl } from '../../common/utils';

class ContractFreightOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setBreadcrumbs: true,
      unit: this.props.unit || this.props.displayUnit
    };
    this.handleBackClick = this.handleBackClick.bind(this);
    this.setBreadcrumbsForOrderDetails = this.setBreadcrumbsForOrderDetails.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(receiveOrder(null));
    this.props.dispatch(setBreadcrumbs([]));
  }

  handleBackClick() {
    this.setState({setBreadcrumbs: true}, () => {
      if(this.props.selectedOrder || this.props.isFetchingSelectedOrder) {
        this.props.dispatch(receiveOrder(null));
      }
      if (this.props.selectedAllocation) {
        this.props.dispatch(receiveAllocation(null));
      }
      document.location.hash = getAbsoluteUrl(document.location.hash);
    });
  }

  componentWillUnmount() {
    this.props.dispatch(receiveOrder(null));
    this.props.dispatch(receiveAllocation(null));
  }

  componentDidUpdate() {
    if(
      this.props.selectedOrder &&
      !this.props.location.search &&
      !this.props.canRaiseVoidAndDuplicateRequestForOrder &&
      !this.props.canRaiseVoidRequestForOrder
    )
      this.props.dispatch(receiveOrder(null))
    if(this.props.selectedAllocation && !this.props.location.search)
      this.props.dispatch(receiveAllocation(null))
  }


  getSelectedOrder() {
    return this.props.selectedOrder || this.props.selectedAllocation;
  }

  setBreadcrumbsForOrderDetails() {
    const selectedOrder = this.getSelectedOrder();
    if(selectedOrder && this.props.breadcrumbs && this.state.setBreadcrumbs) {
      let breadcrumbs = this.props.breadcrumbs.slice(0, 2).concat([
        {text: 'Orders', route: '/contracts/' + selectedOrder.commodityContractId + '/orders', onClick: this.handleBackClick },
        {text: get(selectedOrder, 'identifier', '')}
      ]);
      this.setState({setBreadcrumbs: false}, () => {
        this.props.dispatch(setBreadcrumbs(breadcrumbs));
      });
    }
  }

  onUnitChange = unit => this.setState({unit: unit}, () => {
    let order = this.getSelectedOrder()
    if(order) {
      let receiver = order.parentOrderId ? receiveAllocation : receiveOrder
      this.props.dispatch(receiver(null))
      this.props.dispatch(isLoading('nonExistingComponent'));
      this.props.dispatch(getSelectedOrder(order.id, receiver, false, false, false, false, true, this.state.unit))
    }
  })

  render() {
    const selectedOrder = this.getSelectedOrder();
    const { isOrderListingApplicable } = this.props;
    return (
      <div>
        {
          selectedOrder && !isOrderListingApplicable ?
          <div>
            <OrderDetailsBasicInfo {...this.props} onUnitChange={this.props.onUnitChange || this.onUnitChange} order={selectedOrder} backButtonHandler={this.handleBackClick} displayUnit={this.state.unit || this.props.displayUnit} nested />
            <OrderDetails {...this.props} order={selectedOrder} dontSetBreadcrumbs={true} breadcrumbsFunc={this.setBreadcrumbsForOrderDetails} nested />
          </div>
          :
            !this.props.isFetchingSelectedOrder &&
            <Orders dontRedirect={true} dontSetBreadcrumbs={true} {...this.props} contractId={this.props.contractId} contract={this.props.contract} handleAddOrderButtonClick={this.props.onHandleAddOrderButtonClick} handleAddCOGButtonClick={this.props.onHandleAddCOGButtonClick} nested />
        }
      </div>
    );

  }
}

const mapStateToProps = state => {
  return {
    selectedOrder: state.companies.orders.selectedOrder,
    selectedAllocation: state.companies.orders.selectedAllocation,
    isFetchingSelectedOrder: state.companies.orders.isFetchingSelectedOrder,
    breadcrumbs: state.main.breadcrumbs,
    canRaiseVoidRequestForOrder: state.companies.orders.canRaiseVoidRequestForOrder,
    canRaiseVoidAndDuplicateRequestForOrder: state.companies.orders.canRaiseVoidAndDuplicateRequestForOrder,
  };
};
export default connect(mapStateToProps)(ContractFreightOrders);
