import React from 'react';

import get from 'lodash/get';
import Paper from '@mui/material/Paper';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { EMPTY_VALUE } from '../../../common/constants';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {getCountryLabel, toBankAccountFormat, toBranchNumberFormat} from '../../../common/utils';

const renderBasedOnExpandedFlag = expanded => {
	if (expanded) {
		return (<KeyboardArrowUp
		style={{fill: '#112c42', height: '20px', width: '20px'}}
		/>);
	}
	return (<KeyboardArrowDown style={{fill: '#112c42', height: '20px', width: '20px'}}/>);
};

class InvoiceHowToPaySection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: true
		};
	}

	toggleExpanded = () => {
		this.setState(prevState => ({expanded: !prevState.expanded}));
	};



	render() {
		const { expanded } = this.state;
		const payee = get(this.props,'invoiceDetails.payee');
    const bankDetails = get(payee, 'bankDetails');

		return (
			<Paper className="invoice-details-section-container" style={{paddingBottom: '10px', marginBottom: '0px'}}>
        <h2 onClick={this.toggleExpanded}>
          How To Pay
          <span className="expand-icon">
            {renderBasedOnExpandedFlag(expanded)}
          </span>
        </h2>
        {
          expanded && (
            <div className="action-centre-group invoice-section-group">
              <h5 className="padding-left-30" style={{fontWeight: '500'}}>Electronic Transfer</h5>
              <div className="table-container">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="xxsmall padding-left-30" width="8%">NGR</TableCell>
                      <TableCell className="xxsmall padding-left-30" width="10%">Shareholder %</TableCell>
                      <TableCell className="small padding-left-30" width="7%">ABN</TableCell>
                      <TableCell width="27%" align="center">Entity</TableCell>
                      <TableCell width="18%" align="center">Bank Name</TableCell>
                      <TableCell width="15%" align="center">Account Name</TableCell>
                      <TableCell width="6%" align="center">{getCountryLabel('bsb')} Number</TableCell>
                      <TableCell width="10%" align="center">Account No</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
									{
										bankDetails &&
										bankDetails.map(item => {
											return (
												<TableRow key={item.accountNumber}>
													<TableCell className="xxsmall padding-left-30" width="8%">
														{
															get(this.props.invoiceDetails, 'payee.ngrNumber', EMPTY_VALUE)
														}
													</TableCell>
													<TableCell align="center" width="10%">
														{
															(get(item, 'shareholderPercent') || '100') + '%'
														}
													</TableCell>
													<TableCell width="7%" >
														{
															get(item, 'abn') || get(payee, 'abn', EMPTY_VALUE)
														}
													</TableCell>
													<TableCell align="center" width="27%">
														{
															get(item, 'entityName') || get(payee, 'entityName', EMPTY_VALUE)
														}
													</TableCell>
													<TableCell align="center" width="18%">{get(item, 'name') || EMPTY_VALUE}</TableCell>
													<TableCell align="center" width="15%">{get(item, 'accountName') || EMPTY_VALUE}</TableCell>
													<TableCell align="center" width="3%">{toBranchNumberFormat(get(item, 'bsbNumber'))}</TableCell>
													<TableCell className="padding-left-20	" align="center" width="10%">{toBankAccountFormat(item)}</TableCell>
												</TableRow>
											);
										})
									}
                  </TableBody>
                </Table>
              </div>
            </div>
          )
        }
			</Paper>
		);
	}
}

export default InvoiceHowToPaySection;
