import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { required, selected, numRegex } from '../../common/validators';
import CommonSelect from '../common/select/CommonSelect';
import CommonDatePicker from '../common/CommonDatePicker';
import CommodityAutoComplete from '../common/autocomplete/CommodityAutoComplete';
import Notes from "../common/Notes";
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import VarietyAutoComplete from '../common/autocomplete/VarietyAutoComplete';
import GradeAutoComplete from '../common/autocomplete/GradeAutoComplete';
import CommonTextField from '../common/CommonTextField';
import alertifyjs from 'alertifyjs';
import IconButton from '@mui/material/IconButton';
import ArrowForward from '@mui/icons-material/ArrowForward';
import SeasonSelect from '../common/select/SeasonSelect';
import InputAdornment from '@mui/material/InputAdornment';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {AddCircleIconWithTooltip} from "../common/icons/AddCircleIconWithTooltip";
import Dialog from '@mui/material/Dialog';
import CreateGeneralCondition from '../../containers/CreateGeneralCondition';
import CreateSpecialCondition from '../../containers/CreateSpecialCondition';
import PackOrderPreview from './order-preview/PackOrderPreview';
import CustomEmailDialog from '../common/CustomEmailDialog';
import {raiseOrderAmendRequest} from "../../actions/companies/freights";
import SideDrawer from '../common/SideDrawer';
import { positiveDecimalFilter } from '../../common/input-filters';
import {
  set, get, isEmpty, mapValues, isNumber, forEach, find, without, keys, includes, isArray,
  map, compact, uniq, values, pick, cloneDeep, merge, isObject, omit, has, isEqual, every, filter, min } from 'lodash';
import { INVOICINGS_PACK_ORDERS } from './fixtures';
import { getGeneralConditions, clickAddGeneralConditionButton,
  clickAddSpecialConditionButton, getSpecialConditions } from '../../actions/companies/conditions';
import { create, getOrderFixtures } from '../../actions/companies/orders';
import moment from 'moment';
import CommonButton from '../common/CommonButton';
import APIService from '../../services/APIService';
import { isLoading, setBreadcrumbs, setHeaderText } from '../../actions/main/index';
import { forceStopLoader } from "../../actions/main";
import { generateIdentifier, generateUpdatedIdentifier, getAutoSelectFocusField, getCountryFormats, getCountryLabel, isSystemCompany } from '../../common/utils';
import { FIELD, MT_UNIT, PRIMARY_COLOR_GREEN, SYSTEM_COMPANY_IDS, FERTILISER_IDS, SEASON_NA, DATE_DB_FORMAT } from '../../common/constants';
import { LOADING_PORT_LIST } from './Constants'
import { Autocomplete, Grid, Switch, TextField, createFilterOptions } from '@mui/material';
import { ListboxComponent } from '../common/ListboxComponent';
import NumberField from '../common/NumberField';
import CommodityContractParties from './CommodityContractParties';

const DEFAULT_SIDE_DRAWERS_STATE = {
  generalConditionSideDrawerIsOpen: false,
  specialConditionSideDrawerIsOpen: false,
};

const autocompleteFilters = createFilterOptions();

class PackOrderForm extends Component {
    constructor(props) {
       super(props);
       this.isEditForm = Boolean(this.props.match.params.order_id);
       const hashQueryParams = new URLSearchParams(window.location.hash.split('?')[1]);
       this.isDuplicatingOrder = Boolean(hashQueryParams.get('copyFrom'))
       this.copyFrom = hashQueryParams.get('copyFrom')
       this.customerFields = {
        companyId: {
          value: null,
          validators: [required(), selected()],
          errors: [],
        },
        contactId: {
          value: null,
          validators: [required(), selected()],
          errors: [],
        },
        ngrId: {
          value: null,
          validators: [required(), selected()],
          errors: [],
        },
      };
      this.packingProviderFields = {
        companyId: {
          value: null,
          validators: [required(), selected()],
          errors: [],
        },
        contactId: {
          value: null,
          validators: [required(), selected()],
          errors: [],
        },
      };
      this.identifierGenerated = generateIdentifier('pack_order');
      this.tonnageLabel = getCountryLabel('tonnage')
      this.state = {
        countryFormats: getCountryFormats(),
        directoryCompanies: [],
        customerParties: [],
        sellerBuyerParties: [],
        customerContacts: [],
        sellerContacts: [],
        buyerContacts: [],
        customerNgr: [],
        sellerNgrs: [],
        buyerNgrs: [],
        packingProviderParties: [],
        packingProviderContacts: [],
        shippingLineParties: [],
        loadingPortList: LOADING_PORT_LIST,
        consignors: [],
        consignorSites: [],
        emptyContainerAOList: [],
        packedContainerAOList: [],
        emailPopupParties: [],
        generalConditionsSelector: undefined,
        specialConditionsSelector: undefined,
        selectedGeneralConditionText: undefined,
        selectedSpecialConditionText: undefined,
        selectedCustomer: undefined,
        selectedPackingProvider: undefined,
        selectedShippingLine: undefined,
        base64Logo: null,
        preview: false,
        selectedConsignor: undefined,
        setFieldErrors: true,
        isFetchingSelectedOrder: false,
        isPopulated: false,
        isPlatformContract: false,
        selectedContract: undefined,
        seller: undefined,
        buyer: undefined,
        isUniqueIdentifierErrorExists: false,
        packProviderCompanyName: null,
        fields: {
           typeId: {
            value: 6,
            validators: [required()],
            errors: []
           },
           status: {
            value: 'planned',
           },
           packOrderType: {
            value: 'ship_packing_order',
            validators: [required()],
            errors: [],
           },
           identifier: {
            value: this.identifierGenerated,
            validators: [required()],
            errors: [],
           },
           customer: cloneDeep(this.customerFields),
           packingProvider: cloneDeep(this.packingProviderFields),
           freightShipping: {
            lloyedNumber: {
              value: undefined,
              validators: [],
              errors: [],
             },
             shipperRefNumber: {
              value: undefined,
              validators: [],
              errors: [],
             },
             customsRefNumber: {
              value: undefined,
              validators: [],
              errors: [],
             },
             exportDeclarationNumber: {
              value: undefined,
              validators: [],
              errors: [],
             },
             importPermitNumber: {
              value: undefined,
              validators: [],
              errors: [],
             },
             importPermitDate: {
              value: undefined,
              validators: [],
              errors: [],
             },
             rfp: {
              value: undefined,
              validators: [],
              errors: [],
             },
             shippingLine: {
              value: undefined,
              validators: [],
              errors: [],
             },
             vesselName: {
              value: undefined,
              validators: [],
              errors: [],
             },
             loadingPort: {
              value: undefined,
              validators: [required()],
              errors: [],
             },
             destinationPort: {
              value: undefined,
              validators: [],
              errors: [],
             },
             finalDestination: {
              value: undefined,
              validators: [],
              errors: [],
             },
             shippedTo: {
              value: undefined,
              validators: [],
              errors: [],
             },
             impexShipmentFolderDetailId: null,
             impexShipmentDocsId: null,
             impexBookingId: null,
           },
          commodityId: {
            value: undefined,
            validators: [required()],
            errors: [],
          },
          varietyId: {
            value: undefined,
            validators: [],
            errors: [],
          },
          plannedGradeId: {
            value: undefined,
            validators: [required()],
            errors: [],
          },
          season: {
            value: undefined,
            validators: [required()],
            errors: [],
          },
          plannedTonnage: {
            value: undefined,
            validators: [required()],
            errors: [],
          },
          freightContainer: {
            releaseNumbers: {
              value: [],
              validators: [],
              errors: [],
            },
            numberOfContainers: {
              value: undefined,
              validators: [numRegex(), required()],
              errors: [],
            },
            dualSeal: {
              value: undefined,
              validators: [required()],
              errors: [],
            },
            containerLining: {
              value: undefined,
              validators: [required()],
              errors: [],
            },
            fumigation: {
              value: undefined,
              validators: [required()],
              errors: [],
            },
            packByDate: {
              value: undefined,
              validators: [required()],
              errors: [],
            },
            deliverByDate: {
              value: undefined,
              validators: [required()],
              errors: [],
            },
            emptyContainerAoId: {
              value: undefined,
              validators: [required()],
              errors: [],
            },
            emptyAoRef: {
              value: undefined,
              validators: [],
              errors: [],
            },
            packedContainerAoId: {
              value: undefined,
              validators: [required()],
              errors: [],
            },
            packedAoRef: {
              value: undefined,
              validators: [],
              errors: [],
            },
            consignor: {
              handlerId: {
                value: undefined,
                validators: [required()],
                errors: [],
              },
              site: {
                locationType: {
                  value: undefined,
                  validators: [],
                  errors: [],
                },
                locationId: {
                  value: undefined,
                  validators: [required()],
                  errors: [],
                }
              },
            }
          },
          invoicing: {
            value: undefined,
            validators: [],
            errors: [],
          },
          paymentTermId: {
            value: undefined,
            validators: [],
            errors: [],
          },
          ratePacking: {
            value: undefined,
            validators: [],
            errors: [],
          },
          fumigationFee: {
            value: undefined,
            validators: [],
            errors: [],
          },
          wharfDeliveryFee: {
            value: undefined,
            validators: [],
            errors: [],
          },
          wharfBookingFee: {
            value: undefined,
            validators: [],
            errors: [],
          },
          generalConditions: {
            value: undefined,
            validators: [],
            errors: [],
          },
          specialConditions: {
            value: undefined,
            validators: [],
            errors: [],
          },
          note: {
            description: '',
            attachments: [],
            companyId: this.props.currentUser.companyId,
            errors : []
          },
          externalReferenceNumber: {
            value: '',
            validators: [],
            errors: [],
          },
          sellerDetails: {
            companyId: { ...cloneDeep(FIELD) },
            contactId: { ...cloneDeep(FIELD) },
            ngrId: { ...cloneDeep(FIELD) },
          },
          buyerDetails: {
            companyId: { ...cloneDeep(FIELD) },
            contactId: { ...cloneDeep(FIELD) },
            ngrId: { ...cloneDeep(FIELD) },
          },
          commodityContractId: {
            value: undefined,
            validators: [],
            errors: []
          }
         }
        };

      this.editOrderId = get(this.props, 'match.params.order_id');
      this.editOrder = null;
      Promise.all([
        this.props.dispatch(getGeneralConditions('freight')),
        this.props.dispatch(getSpecialConditions('freight')),
        this.props.dispatch(getOrderFixtures())
      ]);

      if (!this.isEditForm && !this.isDuplicatingOrder) {
        this.props.dispatch(isLoading());
      }
      else {
        this.props.dispatch(isLoading('nonExistentComponent'));
      }
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleBlur = this.handleBlur.bind(this);
      this.handlePackOrderTypeChange = this.handlePackOrderTypeChange.bind(this);
      this.handleIdentifierChange = this.handleIdentifierChange.bind(this);
      this.handleCustomerChange = this.handleCustomerChange.bind(this);
      this.handleCustomerContactChange = this.handleCustomerContactChange.bind(this);
      this.handleCustomerNGRChange = this.handleCustomerNGRChange.bind(this);
      this.handleLloyedNumberChange = this.handleLloyedNumberChange.bind(this);
      this.handleShippersRefNoChange = this.handleShippersRefNoChange.bind(this);
      this.handleCustomRefChange = this.handleCustomRefChange.bind(this);
      this.handleExportDeclarationNumberChange = this.handleExportDeclarationNumberChange.bind(this);
      this.handleImportPermitNumberChange = this.handleImportPermitNumberChange.bind(this);
      this.handleImportPermitDateChange = this.handleImportPermitDateChange.bind(this);
      this.handleRFPChange = this.handleRFPChange.bind(this);
      this.handleShippingLineChange = this.handleShippingLineChange.bind(this);
      this.handleVesselNameChange = this.handleVesselNameChange.bind(this);
      this.handleLoadingPortChange = this.handleLoadingPortChange.bind(this);
      this.handleDestinationPortChange = this.handleDestinationPortChange.bind(this);
      this.handleFinalDestinationChange = this.handleFinalDestinationChange.bind(this);
      this.handleShippedToChange = this.handleShippedToChange.bind(this);
      this.handleCommodityChange = this.handleCommodityChange.bind(this);
      this.handleVarietyChange = this.handleVarietyChange.bind(this);
      this.handleGradeChange = this.handleGradeChange.bind(this);
      this.handleSeasonFieldChange = this.handleSeasonFieldChange.bind(this);
      this.handleTonnageFieldChange = this.handleTonnageFieldChange.bind(this);
      this.handleNumberOfContainersChange = this.handleNumberOfContainersChange.bind(this);
      this.handleContainerDetailsFieldChange = this.handleContainerDetailsFieldChange.bind(this);
      this.handlePackByDateChange = this.handlePackByDateChange.bind(this);
      this.handleDeliverByDateChange = this.handleDeliverByDateChange.bind(this);
      this.handlePackSiteChange = this.handlePackSiteChange.bind(this);
      this.handlePackSiteStorageChange = this.handlePackSiteStorageChange.bind(this);
      this.handleEmptyContainerAOChange = this.handleEmptyContainerAOChange.bind(this);
      this.handleEmptyAoRefChange = this.handleEmptyAoRefChange.bind(this);
      this.handlePackedContainerAOChange = this.handlePackedContainerAOChange.bind(this);
      this.handlePackedAoRefChange = this.handlePackedAoRefChange.bind(this);
      this.handleInvoicingChange = this.handleInvoicingChange.bind(this);
      this.handleRateChange = this.handleRateChange.bind(this);
      this.handleConditionSelector = this.handleConditionSelector.bind(this);
      this.appendCustomCondition = this.appendCustomCondition.bind(this);
      this.handlePackingProviderChange = this.handlePackingProviderChange.bind(this);
      this.handleCancelButtonClick = this.handleCancelButtonClick.bind(this);
      this.getContacts = this.getContacts.bind(this);
      this.getCustomerContacts = this.getCustomerContacts.bind(this);
      this.getCustomerNgrs = this.getCustomerNgrs.bind(this);
      this.getNgrs = this.getNgrs.bind(this);
      this.getPackingProviderContacts = this.getPackingProviderContacts.bind(this);
      this.handlePackingProviderContactChange = this.handlePackingProviderContactChange.bind(this);
      this.addGeneralConditionButton = this.addGeneralConditionButton.bind(this);
      this.addSpecialConditionButton = this.addSpecialConditionButton.bind(this);
      this.openGeneralConditionSideDrawer = this.openGeneralConditionSideDrawer.bind(this);
      this.openSpecialConditionSideDrawer = this.openSpecialConditionSideDrawer.bind(this);
      this.closeGeneralConditionSideDrawer = this.closeGeneralConditionSideDrawer.bind(this);
      this.closeSpecialConditionSideDrawer = this.closeSpecialConditionSideDrawer.bind(this);
      this.setDefaultGeneralCondition = this.setDefaultGeneralCondition.bind(this);
      this.appendGeneralConditions = this.appendGeneralConditions.bind(this);
      this.appendSpecialConditions = this.appendSpecialConditions.bind(this);
      this.focusOnFirstErrorField = this.focusOnFirstErrorField.bind(this);
      this.fieldsOrder = [
        "packOrderType", "identifier", "customer.companyId", "customer.contactId", "customer.ngrId",
        "packingProvider.companyId", "packingProvider.contactId", "freightShipping.lloyedNumber", "freightShipping.shipperRefNumber", "freightShipping.customsRefNumber",
        "freightShipping.exportDeclarationNumber", "freightShipping.importPermitNumber", "freightShipping.importPermitDate", "freightShipping.rfp", "freightShipping.shippingLine", "freightShipping.vesselName",
        "freightShipping.loadingPort", "freightShipping.destinationPort", "freightShipping.finalDestination", "freightShipping.shippedTo", "commodityId", "varietyId", "plannedGradeId",
        "season", "plannedTonnage", "freightContainer.releaseNumbers", "freightContainer.numberOfContainers", "freightContainer.dualSeal", "freightContainer.containerLining", "freightContainer.fumigation",
        "freightContainer.packByDate", "freightContainer.deliverByDate", "freightContainer.consignor.handlerId", "freightContainer.consignor.site.locationId", "freightContainer.emptyContainerAoId", "freightContainer.emptyAoRef",
        "freightContainer.packedContainerAoId", "freightContainer.packedAoRef", "invoicing", "paymentTermId", "ratePacking", "fumigationFee",
        "wharfDeliveryFee", "wharfBookingFee", "generalConditionsSelector", "generalConditions", "note",
        "generalConditionViewer", "specialConditionsSelector", "specialConditions", "generalConditionViewer", "sellerDetails.companyId", "sellerDetails.contactId", "sellerDetails.ngrId",
        "buyerDetails.companyId", "buyerDetails.contactId", "buyerDetails.ngrId"
      ];

      this.formRef = React.createRef();
      this.fieldRef = {};
      this.fieldsOrder.forEach(e => (this.fieldRef[e] = React.createRef()));
    }

    componentDidMount() {
      this.props.dispatch(setHeaderText("Pack Orders"));
      let breadcrumbs = [{ text: 'Pack Orders', route: '/orders/packing' }];

      if (this.isEditForm) {
        breadcrumbs = breadcrumbs.concat([
          { text: get(this.editOrder, 'displayName'), route: `/pack/orders/${this.editOrderId}/order` },
          { text: 'Edit' },
        ]);
      } else
        breadcrumbs = breadcrumbs.concat([{ text: 'New' }]);

      this.props.dispatch(setBreadcrumbs(breadcrumbs));
      this.__setParties();
      const query = new URLSearchParams(this.props.location.search);
      const commodityContractId = query.get('commodityContractId') || get(this.props.match.params, 'contract_id');
      if (commodityContractId) {
        const newState = {...this.state}
        newState.fields.commodityContractId.value = commodityContractId;
        newState.isPlatformContract = true;
        this.setState(newState, () => this.fetchContract())
      }
      if ((this.isEditForm) && !this.state.isFetchingSelectedOrder) {
        this.setState({ isFetchingSelectedOrder: true, isPopulated: false }, () =>
          this.setOrder(this.editOrderId)
        );
      }
      else if ((this.isDuplicatingOrder) && !this.state.isFetchingSelectedOrder) {
        this.setState({ isFetchingSelectedOrder: true, isPopulated: false }, () =>
          this.setOrder(this.copyFrom)
        );
      }
      else {
        this.props.dispatch(forceStopLoader());
      }
    }

    setOrder = async (orderId) => {
      await APIService.freights()
                    .orders(orderId)
                    .get(this.props.userToken)
                    .then(response => {
                      this.editOrder = response;
                      this.props.dispatch(isLoading(null, 10000000));
                      this.populateRequiredFields();
                      Promise.all([
                        this.props.dispatch(getGeneralConditions('freight')),
                        this.props.dispatch(getSpecialConditions('freight')),
                      ]);
                      let breadcrumbs = [
                        { text: 'Pack Orders', route: '/orders/packing' },
                        { text: get(this.editOrder, 'displayName'), route: `/pack/orders/${this.editOrderId}/order` },
                        { text: 'Edit' },
                      ];
                      this.props.dispatch(setBreadcrumbs(breadcrumbs));
                      setTimeout(() => this.props.dispatch(forceStopLoader()), 1000);
                    });
    };

    componentDidUpdate() {
      if (!this.props.isLoading && !this.state.isPopulated && (this.isEditForm || this.isDuplicatingOrder))
        this.props.dispatch(isLoading());
      if (this.state.packingProviderParties && this.state.packProviderCompanyName && !this.state.selectedPackingProvider) {
        const newState = {...this.state};
        newState.selectedPackingProvider = find(this.state.packingProviderParties, company => isEqual(company?.displayName.toLowerCase(), this.state.packProviderCompanyName.toLowerCase()));
        newState.fields.packingProvider.companyId.value = get(newState.selectedPackingProvider, 'id');
        this.setState(newState, () => this.getPackingProviderContacts(this.state.selectedPackingProvider));
      }
    }

    populateRequiredFields = () => {
      const newState = {...this.state};
      const {editOrder} = this;

      const status = get(editOrder, 'orderStatusDisplayName');
      if(this.isDuplicatingOrder && status != 'Void')
        editOrder.identifier = generateUpdatedIdentifier(editOrder.identifier[0])

      forEach([
        'customer.companyId', 'customer.contactId', 'customer.ngrId', 'freightShipping.lloyedNumber', "freightShipping.shipperRefNumber", "freightShipping.customsRefNumber",
        "freightShipping.exportDeclarationNumber", "freightShipping.importPermitNumber", "freightShipping.importPermitDate", "freightShipping.rfp", "freightShipping.vesselName",
        "freightShipping.loadingPort", "freightShipping.destinationPort", "freightShipping.finalDestination", "freightShipping.shippedTo",
        'commodityId', 'varietyId', 'plannedGradeId', 'season', 'identifier', 'packOrderType', 'plannedTonnage', 'invoicing',
        'paymentTermId', 'generalConditions', 'specialConditions', 'ratePacking', 'fumigationFee', 'wharfDeliveryFee', 'wharfBookingFee', "freightContainer.releaseNumbers", "freightContainer.numberOfContainers",
        "freightContainer.packByDate", "freightContainer.deliverByDate", "freightContainer.emptyContainerAoId", "freightContainer.emptyAoRef",
        "freightContainer.packedContainerAoId", "freightContainer.packedAoRef", "externalReferenceNumber"
      ], key => set(newState.fields, `${key}.value`, get(editOrder, key)));
      newState.fields.freightShipping.shippingLine.value = editOrder.freightShipping.shippingLineId;
      newState.fields.packingProvider.companyId.value = editOrder.providerId;
      newState.fields.packingProvider.contactId.value = editOrder.assignToId;
      newState.fields.freightContainer.dualSeal.value = editOrder.freightContainer.dualSeal ? 'yes' : 'no';
      newState.fields.freightContainer.containerLining.value = editOrder.freightContainer.containerLining ? 'yes' : 'no';
      newState.fields.freightContainer.fumigation.value = editOrder.freightContainer.fumigation ? 'yes' : 'no';
      newState.fields.freightContainer.emptyAoRef.value = editOrder.freightContainer.emptyAoRef;
      newState.fields.freightContainer.packedAoRef.value = editOrder.freightContainer.packedAoRef;
      newState.fields.sellerDetails.companyId.value = get(editOrder, 'seller.companyId');
      newState.fields.sellerDetails.contactId.value = get(editOrder, 'seller.contactId');
      newState.fields.sellerDetails.ngrId.value = get(editOrder, 'seller.ngrId');
      newState.fields.buyerDetails.companyId.value = get(editOrder, 'buyer.companyId');
      newState.fields.buyerDetails.contactId.value = get(editOrder, 'buyer.contactId');
      newState.fields.buyerDetails.ngrId.value = get(editOrder, 'buyer.ngrId');
      newState.isPlatformContract = Boolean(get(editOrder, 'commodityContractId'));
      newState.fields.commodityContractId.value = get(editOrder, 'commodityContractId');
      this.setState(newState, () => {
        this.setHandlerSites();
        this.setCustomerParties();
        this.fetchContract();
        this.isDuplicatingOrder = false
      });
    }

    setHandlerSites = async () => {
      const consignor = get(this.editOrder, 'freightContainer.consignor.handler');
      this.handlePackSiteChange(consignor.id, 'freightContainer.consignor.handlerId', consignor);
      this.handlePackSiteStorageChange(get(this.editOrder, 'freightContainer.consignor.sites.0.locationId'), 'freightContainer.consignor.site.locationId.value', get(this.editOrder, 'freightContainer.consignor.sites.0.location'));
    }

    async fetchHandlers(value) {
      await APIService.companies(value)
        .company_sites()
        .get(this.props.token)
        .then(storedHandlers => {
          this.setState({ consignors: filter(storedHandlers, handler => handler?.isActive == true) });
        });
    }

    async __setParties() {
      const directoryCompanies = await APIService.companies(this.props.currentUser.companyId).companies().appendToUrl('minimal/').get(this.props.token, null, { include_parent_company: true });
      const newState = {...this.state};
      newState.customerParties = directoryCompanies;
      newState.packingProviderParties = directoryCompanies;
      newState.shippingLineParties = directoryCompanies;
      newState.sellerBuyerParties = directoryCompanies;
      newState.directoryCompanies = directoryCompanies
      this.setState(newState);
    }

    handleBlur(event) {
      this.setFieldErrors(event.target.id, event.target.value);
    }

    getSelectedCommodity = commodityId => {
      const id = commodityId || this.state.fields.commodityId.value
      return id ? find(this.props.commodities, {id: id}) : null
    }

    priceUnit(commodityId) {
      return get(this.getSelectedCommodity(commodityId), 'priceUnit', MT_UNIT)
    }

    setFieldValue(id, value, validateAfterSet = false, callback) {
      const newState = { ...this.state };
      set(newState.fields, id + '.value', value);
      this.setState(newState, () => {
        if (validateAfterSet) this.setFieldErrors(id, value);
        if (callback) callback();
      });
    }

    setFieldErrors(id, value) {
      const newState = { ...this.state };
      if (newState.setFieldErrors)
        this.getFieldErrors(get(newState.fields, id), value, id);
      this.setState(newState);
    }

    getFieldErrors(field, value, id) {
      if (has(field, 'value') && has(field, 'validators')) {
        field.errors = [];
        value = value || field.value;
        field.validators.forEach(validator => {
          if (validator.isInvalid(value || field.value)) {
            field.errors.push(validator.message);
          }
        });
        if (id === 'plannedTonnage' && value) {
          const maxAllowedTonnage = this.getMaximumAllowedTonnage();
          if (parseFloat(value) > maxAllowedTonnage)
            field.errors.push(`Cannot be greater than ${maxAllowedTonnage} ${this.priceUnit()}`);
        }
      }
    }

    setFieldCustomErrors = (id, msg) => {
      const newState = { ...this.state };
      const field = get(newState.fields, id);
      if (has(field, 'errors') && isArray(field.errors)) {
        if (msg) field.errors.push(msg);
        else field.errors = [];
      }
      this.setState(newState);
    };

    getEmailPopupParties = () => {
      let parties = [];
      if (this.isSellerToBuyer() || this.state.fields.commodityContractId.value) {
        parties.push('seller');
        parties.push('buyer');
      }
      else
        parties.push('customer');
      parties.push('packProvider');
      return parties;
    }

    openEmailDialog = (data) => {
      let emailPopupParties = this.getEmailPopupParties();
      this.setState({ showEmailDialog: true, emailPopupParties: emailPopupParties });
      this.payloadData = data;
    }

    getSubmitData = () => {
      let data = mapValues(this.state.fields, 'value');
      data.identifier = data.identifier.toUpperCase();
      data.freightShipping = mapValues(this.state.fields.freightShipping, 'value');
      data.freightShipping.shippingLineId = data.freightShipping.shippingLine;
      data.freightShipping.impexBookingId = this.state.fields.freightShipping.impexBookingId;
      data.freightShipping.impexShipmentFolderDetailId = this.state.fields.freightShipping.impexShipmentFolderDetailId;
      data.freightShipping.impexShipmentDocsId = this.state.fields.freightShipping.impexShipmentDocsId;
      delete data.freightShipping.shippingLine;
      delete data.freightShipping.shipper_ref_number;
      data.freightContainer = mapValues(this.state.fields.freightContainer, 'value');
      data.freightContainer.dualSeal = data.freightContainer.dualSeal === 'yes' ? true : false;
      data.freightContainer.fumigation = data.freightContainer.fumigation === 'yes' ? true: false;
      data.freightContainer.containerLining = data.freightContainer.containerLining === 'yes' ? true: false;
      data.freightContainer.numberOfContainers = parseInt(data.freightContainer.numberOfContainers);
      data.fumigationFee = parseInt(data.fumigationFee);
      data.plannedTonnage = parseInt(data.plannedTonnage);
      data.ratePacking = parseInt(data.ratePacking);
      data.wharfBookingFee = parseInt(data.wharfBookingFee);
      data.wharfDeliveryFee = parseInt(data.wharfDeliveryFee);
      if (this.isSellerToBuyer() || this.state.fields.commodityContractId.value) {
        data.seller = mapValues(this.state.fields.sellerDetails, 'value');
        data.buyer = mapValues(this.state.fields.buyerDetails, 'value');
      }
      data.customer = mapValues(this.state.fields.customer, 'value');
      data.assignToId = this.state.fields.packingProvider.contactId.value;
      data.providerId = this.state.fields.packingProvider.companyId.value;
      set(data, 'freightPickup.dateTime', `${moment(new Date()).format("YYYY-MM-DD")} 00:00:00Z`);
      set(data, 'freightDelivery.dateTime', `${moment(new Date()).format("YYYY-MM-DD")} 00:00:00Z`);
      if (this.state.fields.freightContainer.consignor.handlerId.value) {
        data.freightContainer.consignor = {
          handlerId: this.state.fields.freightContainer.consignor.handlerId.value,
          sites: [],
        };
        if (this.state.fields.freightContainer.consignor.site.locationId.value && this.state.fields.freightContainer.consignor.site.locationType.value) {
          data.freightContainer.consignor.sites = [
            {
              locationId: this.state.fields.freightContainer.consignor.site.locationId.value,
              locationType: this.state.fields.freightContainer.consignor.site.locationType.value,
            },
          ];
        }
      }

      let note = this.state.fields.note;
      note = omit(note,['errors']);
      data.note = note;
      return data;
    }

    applyValidatorsOn(fields) {
      Object.entries(fields).forEach(([key, field]) => {
        this.getFieldErrors(field, undefined, key);
      });
    }

    setAllFieldErrors() {
      const newState = { ...this.state };
      this.applyValidatorsOn(newState.fields);
      this.applyValidatorsOn(newState.fields.freightContainer);
      this.applyValidatorsOn(newState.fields.freightContainer.consignor);
      this.applyValidatorsOn(newState.fields.freightShipping);
      this.applyValidatorsOn(newState.fields.customer);
      this.applyValidatorsOn(newState.fields.sellerDetails);
      this.applyValidatorsOn(newState.fields.buyerDetails);
      this.applyValidatorsOn(newState.fields.packingProvider);
      this.setState(newState);
      window.scrollTo(0, 0);
    }

    focusOnFirstErrorField() {
      const nestedFields = ["customer.companyId", "customer.contactId", "customer.ngrId","packingProvider.companyId",
      "packingProvider.contactId", "freightShipping.shippingLine", "freightShipping.loadingPort",
      "freightContainer.numberOfContainers", "freightContainer.dualSeal", "freightContainer.containerLining",
      "freightContainer.fumigation", "freightContainer.packByDate", "freightContainer.deliverByDate", 
      "freightContainer.consignor.handlerId", "freightContainer.consignor.site.locationId", "freightContainer.emptyContainerAoId",
      "freightContainer.packedContainerAoId", "sellerDetails.companyId", "sellerDetails.contactId", "sellerDetails.ngrId",
      "buyerDetails.companyId", "buyerDetails.contactId", "buyerDetails.ngrId"];

      const autoCompleteFields = ["commodityId", "plannedGradeId",
                                "generalConditionsSelector", "specialConditionsSelector"];
      for(let i = 0; i < this.fieldsOrder.length; i++) {
        const formField = this.fieldRef[this.fieldsOrder[i]];
        const field = this.state.fields[this.fieldsOrder[i]];
        if (nestedFields.indexOf(this.fieldsOrder[i]) !== -1) {
          if ((this.fieldsOrder[i] === "customer.companyId" && this.state.fields.customer.companyId.errors.length) ||
              (this.fieldsOrder[i] === 'customer.contactId' && this.state.fields.customer.contactId.errors.length > 0) ||
              (this.fieldsOrder[i] === "packingProvider.companyId" && this.state.fields.packingProvider.contactId.errors.length) ||
              (this.fieldsOrder[i] === "packingProvider.contactId" && this.state.fields.packingProvider.contactId.errors.length > 0) ||
              (this.fieldsOrder[i] === "sellerDetails.companyId" && this.state.fields.sellerDetails.companyId.errors.length) ||
              (this.fieldsOrder[i] === 'sellerDetails.contactId' && this.state.fields.sellerDetails.contactId.errors.length > 0) ||
              (this.fieldsOrder[i] === 'sellerDetails.ngrId' && this.state.fields.sellerDetails.contactId.errors.length > 0) ||
              (this.fieldsOrder[i] === "buyerDetails.companyId" && this.state.fields.buyerDetails.companyId.errors.length) ||
              (this.fieldsOrder[i] === 'buyerDetails.contactId' && this.state.fields.buyerDetails.contactId.errors.length > 0) ||
              (this.fieldsOrder[i] === 'buyerDetails.ngrId' && this.state.fields.buyerDetails.contactId.errors.length > 0)
              ) {
            getAutoSelectFocusField(this.fieldRef, this.fieldsOrder[i]);
            formField?.current?.scrollIntoView({ block: 'center' });
            break;
          }
          if (
            (this.fieldsOrder[i] === 'freightContainer.consignor.handlerId' && this.state.fields.freightContainer.consignor.handlerId.errors.length > 0 && formField.current.node)){
            formField.current.node.previousSibling.focus();
            break;
          }
          const field = get(this.state.fields, this.fieldsOrder[i]);
          if (field.errors.length > 0) {
            formField.current.focus();
            formField.current.scrollIntoView({ block: 'center' });
            break;
          }
        } else if (autoCompleteFields.indexOf(this.fieldsOrder[i]) !== -1) {
          if (field && field.errors.length > 0) {
            getAutoSelectFocusField(this.fieldRef, this.fieldsOrder[i]);
            formField.current.scrollIntoView({ block: 'center' });
            break;
          }
        } else if (field && field.errors.length > 0) {
          if (formField.current.node) {
            formField.current.node.previousSibling.focus();
            break;
          } else {
            formField.current.focus();
            formField.current.scrollIntoView({ block: 'center' });
            break;
          }
        }
      }
    }

    isValueChanged = field => {
      return get(this.state.fields, `${field}.value`) != get(this.editOrder, field);
    };

    getEditData = () => {
      let data = {};
      forEach([
        'packOrderType', 'identifier','customer.companyId', 'customer.contactId', 'customer.ngrId',
        'freightShipping.lloyedNumber', 'freightShipping.shipperRefNumber', 'freightShipping.customsRefNumber',
        'freightShipping.exportDeclarationNumber', 'freightShipping.importPermitNumber', 'freightShipping.importPermitDate', 'freightShipping.rfp', 'freightShipping.vesselName',
        'freightShipping.loadingPort', 'freightShipping.destinationPort', 'freightShipping.finalDestination', 'freightShipping.shippedTo', "freightShipping.destinationPort", "freightShipping.finalDestination", "freightShipping.shippedTo", "commodityId", "varietyId", "plannedGradeId",
        "season", "plannedTonnage", 'freightContainer.releaseNumbers', 'freightContainer.numberOfContainers',
        'freightContainer.packByDate', 'freightContainer.deliverByDate', 'freightContainer.emptyContainerAoId', 'freightContainer.emptyAoRef',
        'freightContainer.packedContainerAoId', 'freightContainer.packedAoRef', 'freightContainer.consignor.handlerId', 'commodityId', 'varietyId', 'plannedGradeId',
        'season', 'plannedTonnage', 'invoicing', 'paymentTermId', 'ratePacking', 'fumigationFee', 'wharfDeliveryFee', 'wharfBookingFee',
        'generalConditions', 'specialConditions',
      ], field => {
        if (this.isValueChanged(field)){
          set(data, field, get(this.state.fields, `${field}.value`));
        }
      });

      if (this.state.fields.packingProvider.companyId.value != this.editOrder.providerId) {
        set(data, 'providerId', this.state.fields.packingProvider.companyId.value);
      }

      if (this.state.fields.packingProvider.contactId.value != this.editOrder.assignToId) {
        set(data, 'assignToId', this.state.fields.packingProvider.contactId.value);
      }

      if (this.state.fields.freightShipping.shippingLine.value != this.editOrder.freightShipping.shippingLineId) {
        set(data, 'freightShipping.shippingLineId', this.state.fields.freightShipping.shippingLine.value);
      }

      forEach([
        'freightContainer.dualSeal', 'freightContainer.containerLining', 'freightContainer.fumigation'
      ], field => {
        const fieldVal = get(this.state.fields, `${field}.value`)==='yes' ? true : false;
        if (fieldVal != get(this.editOrder,field)) {
          set(data, field, fieldVal);
        }
      });

      if (this.state.fields.freightContainer.consignor.site.locationId.value != get(this.editOrder, 'freightContainer.consignor.sites.0.locationId')) {
        if (!has(data, 'freightContainer'))
          data.freightContainer = {};
        data.freightContainer.consignor = {
          handlerId: this.state.fields.freightContainer.consignor.handlerId.value,
          sites: [{
            locationId: this.state.fields.freightContainer.consignor.site.locationId.value,
            locationType: this.state.fields.freightContainer.consignor.site.locationType.value,
          }],
        };
      }

      if (this.editOrder.note != null && this.isNoteChanged()) {
        let note = get(this.state.fields, 'note');
        note = omit(note, ['errors']);
        data.note = note;
      }

      return data;

    }

    isNoteChanged = () => {
      return (get(this.state.fields, `note.description`) != get(this.editOrder, 'note.description') ||
              !isEqual(get(this.state.fields, `note.attachments`), get(this.editOrder, 'note.attachments')));
    };

    valid(fields) {
      return every(fields, field => {
        if (has(field, 'errors')) return field.errors.length === 0;
        else return true;
      });
    }

    async handleSubmit(event) {
      event.preventDefault();
      event.stopPropagation();
      this.setAllFieldErrors();

      let queryParams = '';
      if (this.isEditForm && this.editOrderId)
        queryParams += `?order_id=${this.editOrderId}`;
      await APIService.freights().orders().appendToUrl(`${this.state.fields.identifier.value}/identifier-unique/${queryParams}`)
      .get()
      .then(response => {
        this.setState((prevState) => ({
          isUniqueIdentifierErrorExists: Boolean(response?.errors),
          fields: {
            ...prevState.fields,
            identifier: {
              ...prevState.fields.identifier,
              errors: response?.errors ? [response?.errors] : [],
            },
          },
        }));
      });
      this.focusOnFirstErrorField();
      setTimeout(() => {
        if (
          this.valid(this.state.fields.freightContainer.consignor) &&
          this.valid(this.state.fields.packingProvider) &&
          this.valid(this.state.fields.customer) &&
          this.valid(this.state.fields.sellerDetails) &&
          this.valid(this.state.fields.buyerDetails) &&
          this.valid(this.state.fields.freightContainer) &&
          this.valid(this.state.fields.freightShipping) &&
          this.valid(this.state.fields)
        ) {
          const data = this.getSubmitData();
          const isSellerToBuyer = this.isSellerToBuyer();
          if(!isSellerToBuyer && !includes([get(data, 'customer.companyId'), get(data, 'providerId'), ...SYSTEM_COMPANY_IDS], this.props.currentUser.companyId)){
            alertifyjs.error('Your company has to be one of the parties for this order');
          }
          if (isSellerToBuyer && !includes([get(data, 'seller.companyId'), get(data, 'buyer.companyId'), get(data, 'providerId'), ...SYSTEM_COMPANY_IDS], this.props.currentUser.companyId)) {
            alertifyjs.error('Your company has to be one of the parties for this order');
          }
          else{
            if (this.state.preview) {
              if (this.isEditForm) {
                const editData = this.getEditData();
                if (isEmpty(editData) || isEqual(keys(editData), 'communication')) {
                  alertifyjs.alert('Pack Order', 'Please update the order before re-submitting.');
                }
                else if(Object.entries(editData).filter(val =>  val[1] != null).length == 1 && has(editData,"note")){
                  this.props.dispatch(isLoading('genericTableWithData'));
                  this.props.dispatch(raiseOrderAmendRequest(this.editOrderId, editData));
                }
                else {
                  this.openEmailDialog(editData);
                }
              }
              else {
                this.openEmailDialog(data);
              }
            }
            else {
              this.handleOpenPreview();
            }
          }
        }
      }, 200);
    }

    handleOpenPreview = () => {
      this.setState({ preview: true });
    };

    handleClosePreview = () => {
      this.setState({ preview: false });
    };

    getPartyEmails = () => {
      let parties = {
        customer: get(find(this.state.customerContacts, {id: this.state.fields.customer.contactId.value}), 'email'),
        packProvider: get(find(this.state.packingProviderContacts, {id: this.state.fields.packingProvider.contactId.value}), 'email'),
      };
      return parties;
    }

    getEmailPopupPartiesCompanyIds() {
      const parties = this.getEmailPopupParties();
      const ids = {};
      forEach(parties, party => {
        if(party === 'customer' || get(party, 'label'))
          ids.customer = this.state.fields.customer.companyId.value;
        else if(party === 'packProvider')
          ids.packProvider = this.state.fields.packingProvider.companyId.value;
        else if(party === 'seller')
          ids.seller = this.state.fields.sellerDetails.companyId.value;
        else if(party === 'buyer')
          ids.buyer = this.state.fields.buyerDetails.companyId.value;
      });
      return ids;
    }

    async getPartyContacts() {
      if(this.gotOncePartyContacts)
        return;

      this.gotOncePartyContacts = true;
      const parties = this.getEmailPopupPartiesCompanyIds();
      const partiesWithoutContacts = without(keys(parties), 'customer', 'packProvider', 'seller', 'buyer');
      const contacts = {};
      forEach(parties, (id, party) => {
        contacts[party] = [];
        if(party === 'customer')
          contacts[party] = this.state.customerContacts;
        if(party === 'packProvider')
          contacts[party] = this.state.packingProviderContacts;
        if(party === 'seller')
          contacts[party] = this.state.sellerContacts;
        if(party === 'buyer')
          contacts[party] = this.state.buyerContacts;
      });
      if(!isEmpty(partiesWithoutContacts)) {
        const companyIds = uniq(compact(values(pick(parties, partiesWithoutContacts))));
        if(isEmpty(companyIds))
          return contacts;
        const companyQueryString = map(companyIds, id => `company_ids=${id}`).join('&');
        const employees = await APIService.profiles().appendToUrl(`employees-signature/?${companyQueryString}`).get(this.props.userToken);
        forEach(partiesWithoutContacts, party => {
          contacts[party] = filter(employees, {companyId: parties[party]});
        });
      }
      return contacts;
    }

    getEmailSubject = () => {
      const companyName = get(this.props.currentUser,'company.name',"");
      const amendingText = this.isEditForm ? "[Amendment] " : "";
      const identifier = get(this.state.fields, 'identifier.value', '').toUpperCase();
      return `${amendingText}${companyName} Pack Order #${identifier}`;
    }

    getFooterNote = () => {
      return "Pack Order Pdf will be automatically sent as part of the email";
    }

    closeEmailDialog = (communicationData, justClose) => {
      if(justClose) {
        this.gotOncePartyContacts = false;
        this.setState({showEmailDialog: false});
      }
      else if(this.state.showEmailDialog) {
        let data = this.payloadData;
        if(communicationData) {
          data['communication'] = communicationData;
        }
        this.setState({showEmailDialog: false}, () => {
          const {dispatch} = this.props;
          if (this.isEditForm) {
            dispatch(isLoading('genericTableWithData'));
            dispatch(raiseOrderAmendRequest(this.editOrderId, data));
          } else {
            dispatch(isLoading('genericTableWithData'));
            dispatch(create(data, false, false));
          }
        });
      }
      this.handleClosePreview();
    }

    getPreviewFields = () => {
      const fields = this.state.fields;
      const currentUserCompany = this.props.currentUser.company;
      let consignorName = get(this.state.selectedConsignor, 'name') || get(this.editOrder, 'freightContainer.consignor.handler.name');
      const releaseNumbers = fields.freightContainer.releaseNumbers.value || [];
      const freightContainer = {
        'RELEASE NUMBERS': releaseNumbers.join(', '),
        'NUMBER OF CONTAINERS': fields.freightContainer.numberOfContainers.value,
        'DUAL SEAL': fields.freightContainer.dualSeal.value,
        'CONTAINER LINING': fields.freightContainer.containerLining.value,
        'FUMIGATION': fields.freightContainer.fumigation.value,
        'PACK BY DATE': moment(fields.freightContainer.packByDate.value).format(this.state.countryFormats.dateDisplay),
        'DELIVERED BY DATE': moment(fields.freightContainer.deliverByDate.value).format(this.state.countryFormats.dateDisplay),
        'PACK SITE': consignorName,
        'PACK SITE STORAGE': get(find(this.state.consignorSites, { id: fields.freightContainer.consignor.site.locationId.value }), 'name'),
        'EMPTY CONTAINER AO': get(find(this.state.packingProviderContacts, {id: fields.freightContainer.emptyContainerAoId.value}), 'name'),
        'PACKED CONTAINER AO': get(find(this.state.packingProviderContacts, {id: fields.freightContainer.packedContainerAoId.value}), 'name'),
      };
      const freightShipping = {
        'LLOYD NUMBER': fields.freightShipping.lloyedNumber.value,
        'SHIPPERS REF NO.': fields.freightShipping.shipperRefNumber.value,
        'CUSTOMS REF.': fields.freightShipping.customsRefNumber.value,
        'EXPORT DECLARATION NO.': fields.freightShipping.exportDeclarationNumber.value,
        'IMPORT PERMIT NO.': fields.freightShipping.importPermitNumber.value,
        'IMPORT PERMIT DATE': fields.freightShipping.importPermitDate.value ? moment(fields.freightShipping.importPermitDate.value).format(this.state.countryFormats.dateDisplay) : '',
        'SHIPPING LINE': get(find(this.state.shippingLineParties, {id: fields.freightShipping.shippingLine.value }), 'displayName'),
        'VESSEL NAME': fields.freightShipping.vesselName.value,
        'LOADING PORT':get(find(this.state.loadingPortList, {id: fields.freightShipping.loadingPort.value}), 'name'),
        'DESTINATION PORT': fields.freightShipping.destinationPort.value,
        'FINAL DESTINATION': fields.freightShipping.finalDestination.value,
        'SHIPPED TO': fields.freightShipping.shippedTo.value
      };
      const commodity = find(this.props.commodities, { id: fields.commodityId.value })
      return {
        priceUnit: get(commodity, 'priceUnit'),
        identifier: this.state.fields.identifier.value,
        dateIssued: moment(new Date()).format(this.state.countryFormats.dateDisplay),
        currentUser: this.props.currentUser,
        currentUserCompanyName: get(currentUserCompany, 'name'),
        currentUserCompanyWebsite: get(currentUserCompany, 'website'),
        currentUserCompanyPhone: get(currentUserCompany, 'mobile'),
        currentUserCompanyAbn: get(currentUserCompany, 'abn'),
        currentUserCompanyAddress: get(currentUserCompany, 'address.address'),
        commodity: commodity,
        variety: find(this.props.varieties, { id: fields.varietyId.value }),
        plannedGrade: find(this.props.grades, { id: fields.plannedGradeId.value }),
        plannedTonnage: fields.plannedTonnage.value,
        season: fields.season.value,
        generalConditions: fields.generalConditions.value,
        customer: this.state.selectedCustomer,
        customerDetails: {
          customer: this.state.selectedCustomer,
          customerContact: find(this.state.customerContacts, {id: fields.customer.contactId.value}),
          customerNgr: find(this.state.customerNgr, {id:fields.customer.ngrId.value}),
        },
        packingProvider: find(this.state.packingProviderParties, { id: fields.packingProvider.companyId.value }),
        packingProviderContact: find(this.state.packingProviderContacts, { id: fields.packingProvider.contactId.value }),
        invoicingDetails: {
          invoicing: fields.invoicing.value,
          paymentTerm: get(find(this.props.paymentTerms, { id: fields.paymentTermId.value }), 'name'),
          packingRate: this.state.fields.ratePacking.value ? `$${this.state.fields.ratePacking.value} /MT`: '',
          fumigationFee: this.state.fields.fumigationFee.value ? `$${this.state.fields.fumigationFee.value} /MT`: '',
          wharfDeliveryFee: this.state.fields.wharfDeliveryFee.value,
          wharfBookingFee: this.state.fields.wharfBookingFee.value,
        },
        externalReferenceNumber: this.state.fields.externalReferenceNumber.value,
        contractNumber: get(this.state.selectedContract, 'identifier'),
        freightContainer,
        freightShipping,
      };
    }

    handlePackOrderTypeChange(value) {
      const newState = {...this.state};
      newState.fields.packOrderType.value = value;
      newState.fields.packOrderType.errors = [];
      this.setState(newState);
    }

    handleIdentifierChange(event) {
      const newState = {...this.state};
      newState.fields.identifier.value = event.target.value?.toUpperCase();
      newState.fields.identifier.errors = [];
      this.setState(newState)
    }

    handleIdentifierBlur = () => {
      if (this.state.fields.identifier.value && this.props.currentUser.company?.manualShipmentCreationForImpexDocs) {
        APIService.freights().orders().appendToUrl(`impex-docs/${this.state.fields.identifier.value}`).get().then(data => {
          if (data)
            this.populateFieldsWithImpexDocsData(data)
        })
      }
    }

    getCompanyByAbnOrBusinessName = (abn, name) => {
      let companyName = name && name.toLowerCase();
      return find(this.state.directoryCompanies, company => isEqual(company.abn, abn) || isEqual(company.displayName.toLowerCase(), companyName))
    }

    populateFieldsWithImpexDocsData(data) {
      const newState = {...this.state};
      const dateFormat = 'MM/DD/YYYY'
      const shipment = get(data, 'shipment');
      const product = get(data, 'product');
      const contract = get(data, 'salesOrder');
      newState.selectedContract = contract;
      newState.isPlatformContract = Boolean(contract);
      newState.selectedCustomer = this.getCompanyByAbnOrBusinessName(get(shipment, 'exporter Code'), get(shipment, 'exporter Name'));
      newState.fields.customer.companyId.value = newState.selectedCustomer?.id
      newState.fields.buyerDetails.companyId.value = contract?.buyer?.companyId || this.getCompanyByAbnOrBusinessName(shipment?.buyerCode, shipment?.buyerName)?.id;
      newState.fields.sellerDetails.companyId.value = newState.selectedCustomer?.id || contract?.seller?.companyId;
      newState.selectedPackingProvider = this.getCompanyByAbnOrBusinessName(get(shipment, 'packer/Supplier Code'), get(shipment, 'packer/Supplier Name'));
      newState.packProviderCompanyName = get(shipment, 'packer/Supplier Name');
      newState.fields.packingProvider.companyId.value = newState.selectedPackingProvider?.id;
      newState.fields.commodityContractId.value = contract?.id;
      newState.fields.freightShipping.impexBookingId = shipment?.booking?.bookingId;
      newState.fields.freightShipping.impexShipmentDocsId = shipment?.shipmentdocsId;
      newState.fields.freightShipping.impexShipmentFolderDetailId = shipment?.shipmentfolderdetailId;
      newState.fields.freightShipping.shipperRefNumber.value = shipment?.booking?.bookingNumber;
      newState.fields.freightShipping.loadingPort.value = find(this.state.loadingPortList, port => isEqual(port.name.toLowerCase(), shipment?.booking?.placeOfOriginDesc.toLowerCase()))?.id;
      newState.fields.freightShipping.destinationPort.value = shipment?.booking?.dischargePortDesc;
      newState.fields.freightShipping.finalDestination.value = shipment?.booking?.finalDestination;
      newState.fields.freightShipping.lloyedNumber.value = shipment?.booking?.lloyds;
      newState.selectedShippingLine = this.getCompanyByAbnOrBusinessName(shipment?.booking?.shippingLine, shipment?.booking?.shippingLineDesc);
      newState.fields.freightShipping.shippingLine.value = newState.selectedShippingLine?.id;
      newState.fields.freightShipping.vesselName.value = shipment?.booking?.vessel;
      newState.fields.freightContainer.packByDate.value = moment(shipment?.orderDate, dateFormat).format(DATE_DB_FORMAT)
      newState.fields.freightContainer.deliverByDate.value = moment((shipment?.booking?.departureDate || shipment?.booking?.estimatedArrivalDate), dateFormat).format(DATE_DB_FORMAT)
      newState.fields.freightContainer.releaseNumbers.value = compact([shipment?.booking?.releaseNumber]);
      newState.fields.freightContainer.numberOfContainers.value = shipment?.booking?.noOfContainers;
      newState.fields.plannedTonnage.value = shipment?.booking?.quantityMt;
      newState.fields.commodityId.value = product?.commodityId;
      newState.fields.varietyId.value = product?.varietyId;
      newState.fields.season.value = get(shipment, 'productList.0.productClass')
      newState.fields.plannedGradeId.value = product?.gradeId || product?.id;
      this.setState(newState, () => {
        this.fetchContracts('from_impex_docs=true');
        this.fetchContract();
        if (this.state.selectedCustomer) {
          this.getCustomerContacts(this.state.selectedCustomer);
          this.getCustomerNgrs(this.state.fields.customer.companyId.value)
        }
        if (this.state.selectedPackingProvider)
          this.getPackingProviderContacts(this.state.selectedPackingProvider);
      })
    }

    handleCustomerChange(value, id, item) {
      const newState = {...this.state};
      if (this.state.fields.customer.companyId.value !== value) {
        newState.fields.customer.contactId.value = null;
        newState.fields.customer.ngrId.value = null;
        newState.fields.customer.companyId.value = value;
        newState.fields.customer.companyId.errors = [];
      }
      if (item) {
        newState.selectedCustomer = item;
        this.getCustomerContacts(item);
        this.getCustomerNgrs(this.state.fields.customer.companyId.value);
      }
      this.setState(newState);
    }

    handleCustomerContactChange(value, id) {
      this.setFieldValue(id, value);
      this.setFieldErrors(id, value);
    }

    async getCustomerContacts(company, customerContactId='') {
      this.props.dispatch(isLoading())
      const contacts = await this.getContacts(company);
      let customerKeyContactId = this.state.isDependent ? (customerContactId || this.getKeyContactId(contacts, company.id)) : null;
      this.setState({customerContacts: contacts, isPopulated: true}, () => {
        if(customerKeyContactId) {
          this.handleCustomerContactChange(customerKeyContactId, 'customer.contactId');
        }
        this.props.dispatch(forceStopLoader());
      });
    }

    async getContacts(company) {
      return get(company, 'id') ? await APIService.contracts().companies(company.id).contacts().get(this.props.userToken, {}) : [];
    }

    getKeyContactId(contacts, entityId) {
      const keyContacts = filter(contacts, {keyContact: true});
      let keyContactId = null;
      if (keyContacts && keyContacts.length === 1) {
        keyContactId = keyContacts[0].id;
      } else {
        const companyKeyContact = find(keyContacts, { companyId: entityId });
        if (companyKeyContact) {
          keyContactId = companyKeyContact.id;
        }
      }
      return keyContactId;
    }

    async getCustomerNgrs(customerId) {
      let customerNgr = await this.getNgrs(customerId);
      const newState = {...this.state};
      newState.customerNgr = customerNgr;
      newState.customerNgr = customerNgr;
      this.setState(newState);
    }

    async getNgrs(customerId) {
      return customerId ? await APIService.companies(customerId).ngrs().appendToUrl('minimal/').get(this.props.userToken) : [];
    }

    handleCustomerNGRChange(value, id) {
      this.setFieldValue(id, value);
      this.setFieldErrors(id, value);
    }

    async getSiteStorages(siteId) {
      let sites = [];
      let homeStorages = await APIService.farms(siteId)
                                         .appendToUrl('storages/home/minimal/')
                                         .get(this.props.userToken);
      let farmFields = await APIService.farms(siteId)
                                       .farm_fields()
                                       .get(this.props.userToken);
      homeStorages = map(homeStorages, storage => {
        return merge(storage, {
          id: storage.id,
        });
      });
      farmFields = map(farmFields, ff => {
        return merge(ff, { entity: 'farmfield' });
      });
      sites = farmFields.concat(homeStorages);
      this.setState({consignorSites: sites});
    }

    handlePackingProviderChange(value, id, item) {
      const newState = {...this.state};
      if (this.state.fields.packingProvider.companyId.value !== value) {
        newState.fields.packingProvider.contactId.value = null;
        newState.fields.packingProvider.companyId.value = value;
        newState.fields.packingProvider.companyId.errors = [];
      }
      if (item) {
        newState.selectedPackingProvider = item;
        this.getPackingProviderContacts(item);
      }
      if (value) {
        this.fetchHandlers(value);
      }
      this.setState(newState);
    }

    async getPackingProviderContacts(company, customerContactId='') {
      const contacts = await this.getContacts(company);
      let packingProviderKeyContactId = this.state.isDependent ? (customerContactId || this.getKeyContactId(contacts, company.id)) : null;
      this.setState({packingProviderContacts: contacts}, () => {
        if(packingProviderKeyContactId) {
          this.handlePackingProviderContactChange(packingProviderKeyContactId, 'customer.contactId');
        }
      });
    }

    handlePackingProviderContactChange(value, id) {
      this.setFieldValue(id, value);
      this.setFieldErrors(id, value);
    }

    handleLloyedNumberChange(event) {
      const newState = {...this.state};
      newState.fields.freightShipping.lloyedNumber.value = event.target.value;
      newState.fields.freightShipping.lloyedNumber.errors = [];
      this.setState(newState);
    }

    handleShippersRefNoChange(event) {
      const newState = {...this.state};
      newState.fields.freightShipping.shipperRefNumber.value = event.target.value;
      newState.fields.freightShipping.shipperRefNumber.errors = [];
      this.setState(newState);
    }

    handleCustomRefChange(event) {
      const newState = {...this.state};
      newState.fields.freightShipping.customsRefNumber.value = event.target.value;
      newState.fields.freightShipping.customsRefNumber.errors = [];
      this.setState(newState);
    }

    handleExportDeclarationNumberChange(event) {
      const newState = {...this.state};
      newState.fields.freightShipping.exportDeclarationNumber.value = event.target.value;
      newState.fields.freightShipping.exportDeclarationNumber.errors = [];
      this.setState(newState);
    }

    handleImportPermitNumberChange(event) {
      const newState = {...this.state};
      newState.fields.freightShipping.importPermitNumber.value = event.target.value;
      newState.fields.freightShipping.importPermitNumber.errors = [];
      this.setState(newState);
    }

    handleImportPermitDateChange(value) {
      const newState = {...this.state};
      newState.fields.freightShipping.importPermitDate.value = value;
      newState.fields.freightShipping.importPermitDate.errors = [];
      this.setState(newState);
    }

    handleRFPChange(event) {
      const newState = {...this.state};
      newState.fields.freightShipping.rfp.value = event.target.value;
      newState.fields.freightShipping.rfp.errors = [];
      this.setState(newState);
    }

    handleShippingLineChange(value, id, item) {
      const newState = {...this.state};
      if (this.state.fields.freightShipping.shippingLine.value !== value) {
        newState.fields.freightShipping.shippingLine.value = value;
      }
      if (item) {
        newState.selectedShippingLine = item;
      }
      newState.fields.freightShipping.shippingLine.errors = [];
      this.setState(newState);
    }
    handleVesselNameChange(event) {
      const newState = {...this.state};
      let value = event.target.value.trim()
      newState.fields.freightShipping.vesselName.value = value ? value : null;
      newState.fields.freightShipping.vesselName.errors = [];
      this.setState(newState);
    }

    handleLoadingPortChange(value) {
      const newState = {...this.state};
      newState.fields.freightShipping.loadingPort.value = value;
      newState.fields.freightShipping.loadingPort.errors = [];
      this.setState(newState);
    }

    handleDestinationPortChange(event) {
      const newState = {...this.state};
      newState.fields.freightShipping.destinationPort.value = event.target.value;
      newState.fields.freightShipping.destinationPort.errors = [];
      this.setState(newState);
    }

    handleFinalDestinationChange(event) {
      const newState = {...this.state};
      newState.fields.freightShipping.finalDestination.value = event.target.value;
      newState.fields.freightShipping.finalDestination.errors = [];
      this.setState(newState);
    }

    handleShippedToChange(event) {
      const newState = {...this.state};
      newState.fields.freightShipping.shippedTo.value = event.target.value;
      newState.fields.freightShipping.shippedTo.errors = [];
      this.setState(newState);
    }

    handleCommodityChange(value) {
      const pastCommodityId = this.state.fields.commodityId.value
      if (this.state.fields.commodityId.value != value) {
        const newState = {...this.state};
        newState.fields.commodityId.value = value;
        newState.fields.commodityId.errors = [];
        if(FERTILISER_IDS.includes(value))
          newState.fields.season.value = SEASON_NA
        else if(FERTILISER_IDS.includes(pastCommodityId) && this.state.fields.season.value)
          newState.fields.season.value = null
        this.setState(newState);
      }
    }

    handleVarietyChange(value) {
      if (this.state.fields.varietyId.value != value) {
        const newState = { ...this.state };
        newState.fields.varietyId.value = value || null;
        this.setState(newState);
      }
    }

    handleGradeChange(valueObject, id) {
      this.setFieldValue(id, valueObject.id);
      this.setFieldErrors(id, valueObject.id);
    }

    handleSeasonFieldChange(value, id) {
      this.setFieldValue(id, value);
      this.setFieldErrors(id, value);
    }

    handleTonnageFieldChange(event) {
      this.setFieldValue(event.target.id, event.target.value);
      this.setFieldErrors(event.target.id, event.target.value);
    }

    handleNumberOfContainersChange(event) {
      this.setFieldValue(event.target.id, event.target.value);
      this.setFieldErrors(event.target.id, event.target.value);
    }

    handleContainerDetailsFieldChange(value, id) {
      this.setFieldValue(id, value);
      this.setFieldErrors(id, value);
    }

    handlePackByDateChange(value) {
      const newState = {...this.state};
      newState.fields.freightContainer.packByDate.value = value;
      newState.fields.freightContainer.packByDate.errors = [];
      this.setState(newState);
    }

    handleDeliverByDateChange(value) {
      const newState = {...this.state};
      newState.fields.freightContainer.deliverByDate.value = value;
      newState.fields.freightContainer.deliverByDate.errors = [];
      this.setState(newState);
    }

    handlePackSiteChange(value, id, item) {
      if (this.state.fields.freightContainer.consignor.handlerId.value != value) {
        const newState = { ...this.state };
        newState.fields.freightContainer.consignor.site.locationId.value = null;
        newState.fields.freightContainer.consignor.site.locationType.value = null;
        newState.fields.freightContainer.consignor.handlerId.value = value;
        newState.pickupSettings = {};
        if (get(item, 'externallySyncSource')) {
          newState.fields.freightShipping.shipperRefNumber.validators = [required()];
          newState.fields.freightContainer.releaseNumbers.validators = [required()];
        }
        else {
          newState.fields.freightShipping.shipperRefNumber.validators = [];
          newState.fields.freightShipping.shipperRefNumber.errors = [];
          newState.fields.freightContainer.releaseNumbers.validators = [];
          newState.fields.freightContainer.releaseNumbers.errors = [];
        }
        let selectedConsignor;
        if (isObject(item)) {
          selectedConsignor = item;
          this.getSiteStorages(value);
        } else {
          selectedConsignor = undefined;
          newState.consignorSites = [];
        }
          this.setState({...newState, selectedConsignor});
      }
    }

    handlePackSiteStorageChange(value, id, item) {
      if (isObject(item)) {
        const newState = {...this.state};
        newState.fields.freightContainer.consignor.site.locationId.value = item.id;
        newState.fields.freightContainer.consignor.site.locationType.value = item.entity;
        this.setState(newState);
      }
    }

    handleEmptyContainerAOChange(value, id) {
      this.setFieldValue(id, value);
      this.setFieldErrors(id, value);
    }

    handleEmptyAoRefChange(event) {
      this.setFieldValue(event.target.id, event.target.value);
      this.setFieldErrors(event.target.id, event.target.value);
    }

    handlePackedContainerAOChange(value, id) {
      this.setFieldValue(id, value);
      this.setFieldErrors(id, value);
    }

    handlePackedAoRefChange(event) {
      this.setFieldValue(event.target.id, event.target.value);
      this.setFieldErrors(event.target.id, event.target.value);
    }

    handleInvoicingChange(value, id) {
      this.setFieldValue(id, value);
      this.setFieldErrors(id, value);
    }

    handleRateChange(event) {
      this.setFieldValue(event.target.id, event.target.value);
      this.setFieldErrors(event.target.id, event.target.value);
    }

    handleConditionSelector(value, id, chosenItem) {
      var key = null;
      if (id === 'generalConditionsSelector') {
        key = 'selectedGeneralConditionText';
      } else if (id === 'specialConditionsSelector') {
        key = 'selectedSpecialConditionText';
      }
      const newState = { ...this.state };
      if (chosenItem) {
        newState[key] = chosenItem.details;
        newState[id] = value;
      } else {
        newState[key] = undefined;
        newState[id] = undefined;
      }
      this.setState(newState);
    }

    appendCustomCondition(e, field) {
      const newState = { ...this.state };
      newState.fields[field].value = e.target.value;
      this.setState(newState);
    }

    noteHandler = val => {
      this.setState({fields: {...this.state.fields, note: val}});
    };

    handleCancelButtonClick() {
      window.location.hash = '/orders/packing';
    }

    handleSideDrawer(type) {
      const sideDrawer = cloneDeep(DEFAULT_SIDE_DRAWERS_STATE);
      if (type) {
        sideDrawer[type] = true;
      }
      this.setState(sideDrawer);
    }

    openGeneralConditionSideDrawer() {
      const { dispatch } = this.props;
      dispatch(clickAddGeneralConditionButton());
      this.handleSideDrawer('generalConditionSideDrawerIsOpen');
    }

    openSpecialConditionSideDrawer() {
      const { dispatch } = this.props;
      dispatch(clickAddSpecialConditionButton());
      this.handleSideDrawer('specialConditionSideDrawerIsOpen');
    }

    addGeneralConditionButton() {
      return <AddCircleIconWithTooltip id='addGeneralCondition' title='Add General Condition' onClick={() => this.openGeneralConditionSideDrawer()} />;
    }

    addSpecialConditionButton() {
      return <AddCircleIconWithTooltip id='addSpecialCondition' title='Add Special Condition' onClick={() => this.openSpecialConditionSideDrawer()} />;
    }

    closeGeneralConditionSideDrawer() {
      this.setState({ generalConditionSideDrawerIsOpen: false });
    }

    closeSpecialConditionSideDrawer() {
      this.setState({ specialConditionSideDrawerIsOpen: false });
    }

    setDefaultGeneralCondition(condition) {
      this.handleSideDrawer();
      if (condition) {
        this.handleConditionSelector(condition.id, 'generalConditionsSelector', condition);
      }
    }

    setDefaultSpecialCondition(condition) {
      this.handleSideDrawer();
      if (condition) {
        this.handleConditionSelector(condition.id, 'specialConditionsSelector', condition);
      }
    }

    appendGeneralConditions() {
      const newState = { ...this.state };
      if (isEmpty(newState.fields.generalConditions.value))
        newState.fields.generalConditions.value = newState.selectedGeneralConditionText;
      else if (!includes(newState.fields.generalConditions.value, newState.selectedGeneralConditionText))
        newState.fields.generalConditions.value = newState.fields.generalConditions.value + '\n\n' + newState.selectedGeneralConditionText;
      this.setState(newState);
    }

    appendSpecialConditions() {
      const newState = { ...this.state };
      if (isEmpty(newState.fields.specialConditions.value))
        newState.fields.specialConditions.value = newState.selectedSpecialConditionText;
      else if (!includes(newState.fields.specialConditions.value, newState.selectedSpecialConditionText))
        newState.fields.specialConditions.value = newState.fields.specialConditions.value + '\n\n' + newState.selectedSpecialConditionText;
      this.setState(newState);
    }

    handleReleaseNumbersChange = (id, selectedItems) => {
      if (selectedItems) {
        const newState = { ...this.state };
        let releaseNumbers = selectedItems.filter((obj, _, arr) => arr.filter(o => o.inputValue === obj.inputValue).length === 1)
        newState.fields.freightContainer.releaseNumbers.value = releaseNumbers.map(item => item.inputValue || item.name);
        this.setState(newState);
      }
    }

    isExternallySyncedSite = () => Boolean(get(this.state.selectedConsignor, 'externallySyncSource'));

    platformContractCheck = event => {
      const newState = {...this.state};
      newState.isPlatformContract = event.target.checked;
      this.setState(newState, () => this.fetchContracts());
    }

    async fetchContracts(params='') {
      let contractsService = APIService.contracts();
      let queryParams = 'status=open&status=in_progress&status=confirmed&status=delivered&pack=true';
      if (params)
        queryParams += `&${params}`;
      contractsService.appendToUrl(`minimalistic/?${queryParams}`);
      const contracts = await contractsService.get();
      this.setState({ contracts: filter(contracts, 'canCreateFreightOrder')});
    }

    onFieldChange = event => {
      const newState = { ...this.state };
      var value = event.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
      set(newState.fields, `${event.target.id}.value`, value);
      if (value) {
        newState.fields.sellerDetails.companyId.validators = [required()];
        newState.fields.sellerDetails.contactId.validators = [required()];
        newState.fields.sellerDetails.ngrId.validators = [required()];
        newState.fields.buyerDetails.companyId.validators = [required()];
        newState.fields.buyerDetails.contactId.validators = [required()];
        newState.fields.buyerDetails.ngrId.validators = [required()];
      }
      else {
        newState.fields.sellerDetails.companyId.validators = [];
        newState.fields.sellerDetails.contactId.validators = [];
        newState.fields.sellerDetails.ngrId.validators = [];
        newState.fields.buyerDetails.companyId.validators = [];
        newState.fields.buyerDetails.contactId.validators = [];
        newState.fields.buyerDetails.ngrId.validators = [];
      }
      this.setState(newState);
    };

    isSellerToBuyer = () => Boolean(this.state.fields.externalReferenceNumber.value || this.state.fields.commodityContractId.value);

    handleSellerChange = (value, id, item) => {
      const newState = {...this.state};
      set(newState, `fields.${id}.value`, value);
      this.setState(newState, () => {
        this.setFieldErrors(id, value);
        if (id === 'sellerDetails.companyId') {
          this.getContactsForCompanyParty('seller', item);
          this.getPartyNgrs('seller', value);
          this.setCustomerParties();
        }
      })
    }

    handleBuyerChange = (value, id, item) => {
      const newState = {...this.state};
      set(newState, `fields.${id}.value`, value);
      this.setState(newState, () => {
        this.setFieldErrors(id, value);
        if (id === 'buyerDetails.companyId') {
          this.getContactsForCompanyParty('buyer', item);
          this.getPartyNgrs('buyer', value);
          this.setCustomerParties();
        }
      })
    }

    setCustomerParties() {
      if (this.state.fields.sellerDetails.companyId.value && this.state.fields.buyerDetails.companyId.value) {
        const newState = {...this.state};
        newState.customerParties = this.state.sellerBuyerParties.filter(obj => includes([this.state.fields.sellerDetails.companyId.value, this.state.fields.buyerDetails.companyId.value], obj.id));
        this.setState(newState);
      }
    }

    async getContactsForCompanyParty(party, company) {
      const contacts = await this.getContacts(company);
      const newState = {...this.state};
      set(newState, `${party}Contacts`, contacts)
      this.setState(newState);
    }

    async getPartyNgrs(party, companyId) {
      let partyNgr = await this.getNgrs(companyId);
      const newState = {...this.state};
      set(newState, `${party}Ngrs`, partyNgr)
      this.setState(newState);
    }

    handleContractChange = (value, id, item) => {
      const newState = {...this.state};
      newState.fields.commodityContractId.value = value;
      this.setState(newState, () => this.fetchContract())
      newState.fields.sellerDetails.companyId.value = get(item, 'seller.companyId');
      newState.fields.sellerDetails.companyId.value = get(item, 'seller.companyId');

    }

    async fetchContract() {
      if (this.state.fields.commodityContractId.value) {
        this.props.dispatch(isLoading());
        let contract = await APIService.contracts(this.state.fields.commodityContractId.value).get();
        const newState = {...this.state};
        newState.selectedContract = contract;
        newState.seller = get(contract, 'seller');
        newState.buyer = get(contract, 'buyer');
        newState.fields.sellerDetails.companyId.value = get(contract, 'seller.companyId');
        newState.fields.sellerDetails.contactId.value = get(contract, 'seller.contactId');
        newState.fields.sellerDetails.ngrId.value = get(contract, 'seller.ngrId');
        newState.fields.buyerDetails.companyId.value = get(contract, 'buyer.companyId');
        newState.fields.buyerDetails.contactId.value = get(contract, 'buyer.contactId');
        newState.fields.buyerDetails.ngrId.value = get(contract, 'buyer.ngrId');
        newState.fields.commodityId.value = get(contract, 'commodityId');
        newState.fields.plannedGradeId.value = get(contract, 'gradeId');
        newState.fields.season.value = get(contract, 'season');
        newState.fields.varietyId.value = get(contract, 'varietyId');
        this.setState(newState, () => {
          this.props.dispatch(forceStopLoader());
          this.setCustomerParties();
        });
      }
    }

    getMaximumAllowedTonnage() {
      if (this.state.selectedContract) {
        let totalUnpackedTonnage = get(this.state.selectedContract, 'unpackedTonnage') + (get(this.editOrder, 'plannedTonnage') || 0);
        let totalUndeliveredTonnage = (get(this.state.selectedContract, 'totalTonnageWithTolerance') - get(this.state.selectedContract, 'totalDeliveredTonnage')) + (get(this.editOrder, 'plannedTonnage') || 0);
        return min([totalUnpackedTonnage, totalUndeliveredTonnage]);
      }
    }

    getTonnageLabel() {
      let tonnageLabel = this.tonnageLabel;
      const maxAllowedTonnage = this.getMaximumAllowedTonnage();
      return `${tonnageLabel}${maxAllowedTonnage ? ` (Maximum Allowed Tonnage: ${maxAllowedTonnage} ${this.state.selectedContract.inferredTonnageUnit})` : '' }`;
    }

    render() {
      const hasAnyChildren = get(this.editOrder, 'hasAnyChildren')
      const buttons = (
        <div className='cardForm-action col-md-12 top-15'>
          <CommonButton label='Cancel' default={true} variant='outlined' onClick={this.handleCancelButtonClick} />
          <CommonButton type='submit' label={'Continue and Review'} primary={true} variant='raised' />
        </div>
      );
      let invoicing = INVOICINGS_PACK_ORDERS;
      const isSellerToBuyer = this.isSellerToBuyer();
      const isAgainstInternalContract = Boolean(this.state.isPlatformContract && this.state.selectedContract);
      const query = new URLSearchParams(this.props.location.search);
      const isAgainstContract = Boolean(query.get('commodityContractId')) || Boolean(get(this.editOrder, 'commodityContractId'));
      return (
        <div ref={this.formRef}>
          <form onSubmit={this.handleSubmit} noValidate>
            <div className='cardForm'>
              <h4 className='cardForm-title'>General</h4>
              <div className='cardForm-content'>
                <div className='col-md-5'>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonSelect
                      id='packOrderType'
                      setRef={this.fieldRef['packOrderType']}
                      onChange={this.handlePackOrderTypeChange}
                      floatingLabelText='Pack Order Type'
                      errorText={this.state.fields.packOrderType.errors[0]}
                      selectConfig={{ text: 'name', value: 'id' }}
                      items={[ {id: 'ship_packing_order', name: 'Ship Packing Order'}, {id: 'rail_packing_order', name: 'Rail Packing Order'}, {id: 'road_packing_order', name: 'Road Packing Order'}]}
                      value={this.state.fields.packOrderType.value}
                      disabled={false}
                    />

                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonTextField
                      id='identifier'
                      style={{ float: 'left' }}
                      setRef={this.fieldRef['identifier']}
                      error={!isEmpty(this.state.fields.identifier.errors[0])}
                      label='Packing Order No'
                      value={this.state.fields.identifier.value}
                      helperText={this.state.fields.identifier.errors[0]}
                      onChange={this.handleIdentifierChange}
                      onBlur={this.handleIdentifierBlur}
                      fullWidth
                      inputProps={{
                          maxLength: 22,
                      }}
                      disabled={this.isEditForm && get(this.editOrder, 'externalArgs')}
                    />
                  </div>
                  {!isAgainstContract &&
                    <div className="col-md-12 padding-reset form-wrap" style={{minHeight: '50px', height: '50px'}}>
                      <Grid container alignItems="center" spacing={1} wrap='nowrap'>
                        <Grid item style={{ fontSize: '15px', color: 'rgba(0, 0, 0, 0.54)', paddingRight: '50px' }}>Contract exists</Grid>
                        <Grid item>Outside AgriChain</Grid>
                        <Grid item>
                          <Switch color='primary' checked={this.state.isPlatformContract} onChange={this.platformContractCheck} name="contractExists" />
                        </Grid>
                        <Grid item>On AgriChain</Grid>
                      </Grid>
                    </div>
                  }
                </div>
                <div className='col-md-5 col-md-offset-1'>
                  <div className='col-md-12 padding-reset form-wrap'>
                    {this.state.isPlatformContract ?
                      isAgainstContract && this.state.selectedContract ?
                      <CommonTextField
                        value={get(this.state.selectedContract, 'identifier')}
                        disabled
                      />
                      :
                      <CommonAutoSelect
                        items={this.state.contracts}
                        id='commodityContractId'
                        setRef={this.fieldRef['commodityContractId']}
                        dataSourceConfig={{ text: 'displayName', value: 'id' }}
                        label='Contract Number'
                        value={this.state.fields.commodityContractId.value}
                        style={{ float: 'left' }}
                        onChange={this.handleContractChange}
                        disabled={(this.state.queryParamExists || this.isEditForm) && !this.isParentRequestOrder}
                        dontAutoselectSingleItem
                      />
                      : <CommonTextField
                          id='externalReferenceNumber'
                          placeholder='Contract Reference No.'
                          setRef={this.fieldRef["externalReferenceNumber"]}
                          maxLength={25}
                          value={this.state.fields.externalReferenceNumber.value}
                          onChange={this.onFieldChange}
                          helperText={this.state.fields.externalReferenceNumber.errors[0]}
                        />
                    }
                  </div>
                </div>
              </div>
            </div>
            {isSellerToBuyer &&
            <div className='cardForm'>
              <h4 className='cardForm-title'>Contract Parties</h4>
              <div className='cardForm-content'>
                <div className='col-md-5'>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonAutoSelect
                      id='sellerDetails.companyId'
                      items={this.state.sellerBuyerParties}
                      setRef={this.fieldRef['sellerDetails.companyId']}
                      onChange={this.handleSellerChange}
                      label='Seller Name'
                      errorText={this.state.fields.sellerDetails.companyId.errors[0]}
                      value={this.state.fields.sellerDetails.companyId.value}
                      dontAutoselectSingleItem
                      disabled={this.isEditForm}
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonAutoSelect
                      id='sellerDetails.contactId'
                      setRef={this.fieldRef['sellerDetails.contactId']}
                      onChange={this.handleSellerChange}
                      label='Seller Contact'
                      errorText={this.state.fields.sellerDetails.contactId.errors[0]}
                      items={this.state.sellerContacts}
                      value={this.state.fields.sellerDetails.contactId.value}
                      dontAutoselectSingleItem
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonAutoSelect
                      id='sellerDetails.ngrId'
                      setRef={this.fieldRef['sellerDetails.ngrId']}
                      dataSourceConfig={{ text: 'ngrNumber', value: 'id' }}
                      onChange={this.handleSellerChange}
                      label='Seller NGR'
                      errorText={this.state.fields.sellerDetails.ngrId.errors[0]}
                      items={this.state.sellerNgrs || []}
                      value={this.state.fields.sellerDetails.ngrId.value}
                      dontAutoselectSingleItem
                    />
                  </div>
                </div>
                <div className='col-md-5 col-md-offset-1'>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonAutoSelect
                      id='buyerDetails.companyId'
                      items={this.state.sellerBuyerParties}
                      setRef={this.fieldRef['buyerDetails.companyId']}
                      onChange={this.handleBuyerChange}
                      label='Buyer Name'
                      errorText={this.state.fields.buyerDetails.companyId.errors[0]}
                      value={this.state.fields.buyerDetails.companyId.value}
                      dontAutoselectSingleItem
                      disabled={this.isEditForm}
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonAutoSelect
                      id='buyerDetails.contactId'
                      setRef={this.fieldRef['buyerDetails.contactId']}
                      onChange={this.handleBuyerChange}
                      label='Buyer Contact'
                      errorText={this.state.fields.buyerDetails.contactId.errors[0]}
                      items={this.state.buyerContacts || []}
                      value={this.state.fields.buyerDetails.contactId.value}
                      dontAutoselectSingleItem
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonAutoSelect
                      id='buyerDetails.ngrId'
                      setRef={this.fieldRef['buyerDetails.ngrId']}
                      dataSourceConfig={{ text: 'ngrNumber', value: 'id' }}
                      onChange={this.handleBuyerChange}
                      label='Buyer NGR'
                      errorText={this.state.fields.buyerDetails.ngrId.errors[0]}
                      items={this.state.buyerNgrs || []}
                      value={this.state.fields.buyerDetails.ngrId.value}
                      dontAutoselectSingleItem
                    />
                  </div>
                </div>
              </div>
            </div>
            }
            { isAgainstInternalContract &&
             <div className='contract-details-container'>
               <CommodityContractParties
                 seller={get(this.state, 'seller')}
                 buyer={get(this.state, 'buyer')}
                 buyerContact={get(this.state, 'buyer.contact')}
                 sellerContact={get(this.state, 'seller.contact')}
                 title='Commodity Contract Parties'
               />
             </div>
            }
            <div className='cardForm'>
              <h4 className='cardForm-title'>Counterparties</h4>
              <div className='cardForm-content'>
                <div className='col-md-5'>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonAutoSelect
                      id='customer.companyId'
                      items={this.state.customerParties}
                      setRef={this.fieldRef['customer']}
                      onChange={this.handleCustomerChange}
                      label='Customer'
                      errorText={this.state.fields.customer.companyId.errors[0]}
                      value={this.state.fields.customer.companyId.value}
                      selectedItem={this.state.selectedCustomer}
                      dontAutoselectSingleItem
                      disabled={(this.isEditForm || (isSellerToBuyer && !this.state.fields.sellerDetails.companyId.value && !this.state.fields.buyerDetails.companyId.value)) && !isSystemCompany()}
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonAutoSelect
                      id='customer.contactId'
                      setRef={this.fieldRef['customer.contactId']}
                      onChange={this.handleCustomerContactChange}
                      label='Customer Contact'
                      errorText={this.state.fields.customer.contactId.errors[0]}
                      items={this.state.customerContacts}
                      value={this.state.fields.customer.contactId.value}
                      dontAutoselectSingleItem
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonAutoSelect
                      id='customer.ngrId'
                      setRef={this.fieldRef['customer.ngrId']}
                      dataSourceConfig={{ text: 'ngrNumber', value: 'id' }}
                      onChange={this.handleCustomerNGRChange}
                      label='Customer NGR'
                      errorText={this.state.fields.customer.ngrId.errors[0]}
                      items={this.state.customerNgr || []}
                      value={this.state.fields.customer.ngrId.value}
                      dontAutoselectSingleItem
                    />
                  </div>
                </div>
                <div className='col-md-5 col-md-offset-1'>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonAutoSelect
                      id='packingProvider.companyId'
                      setRef={this.fieldRef['packingProvider.companyId']}
                      onChange={this.handlePackingProviderChange}
                      label='Packing Provider'
                      errorText={this.state.fields.packingProvider.companyId.errors[0]}
                      items={this.state.packingProviderParties}
                      value={this.state.fields.packingProvider.companyId.value}
                      selectedItem={this.state.selectedPackingProvider}
                      disabled={this.isEditForm}
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonAutoSelect
                      id='packingProvider.contactId'
                      setRef={this.fieldRef['packingProvider.contactId']}
                      onChange={this.handlePackingProviderContactChange}
                      label='Packing Provider Contact'
                      errorText={this.state.fields.packingProvider.contactId.errors[0]}
                      items={this.state.packingProviderContacts}
                      value={this.state.fields.packingProvider.contactId.value}
                      dontAutoselectSingleItem
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='cardForm'>
              <h4 className='cardForm-title'>Shipping Details</h4>
              <div className='cardForm-content'>
                <div className='col-md-5'>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonTextField
                      id='freightShipping.lloyedNumber'
                      style={{ float: 'left' }}
                      setRef={this.fieldRef['freightShipping.lloyedNumber']}
                      error={!isEmpty(this.state.fields.freightShipping.lloyedNumber.errors[0])}
                      label='Lloyd Number (Optional)'
                      value={this.state.fields.freightShipping.lloyedNumber.value}
                      helperText={this.state.fields.freightShipping.lloyedNumber.errors[0]}
                      onChange={this.handleLloyedNumberChange}
                      fullWidth
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonTextField
                      id='freightShipping.shipperRefNumber'
                      style={{ float: 'left' }}
                      setRef={this.fieldRef['freightShipping.shipperRefNumber']}
                      error={!isEmpty(this.state.fields.freightShipping.shipperRefNumber.errors[0])}
                      label={`Shippers Ref No${this.isExternallySyncedSite() ? '' :' (Optional)'}`}
                      value={this.state.fields.freightShipping.shipperRefNumber.value}
                      helperText={this.state.fields.freightShipping.shipperRefNumber.errors[0]}
                      onChange={this.handleShippersRefNoChange}
                      fullWidth
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonTextField
                      id='freightShipping.customsRefNumber'
                      style={{ float: 'left' }}
                      setRef={this.fieldRef['freightShipping.customsRefNumber']}
                      error={!isEmpty(this.state.fields.freightShipping.customsRefNumber.errors[0])}
                      label='Customs Ref (Optional)'
                      value={this.state.fields.freightShipping.customsRefNumber.value}
                      helperText={this.state.fields.freightShipping.customsRefNumber.errors[0]}
                      onChange={this.handleCustomRefChange}
                      fullWidth
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonTextField
                      id='freightShipping.exportDeclarationNumber'
                      style={{ float: 'left' }}
                      setRef={this.fieldRef['freightShipping.exportDeclarationNumber']}
                      error={!isEmpty(this.state.fields.freightShipping.exportDeclarationNumber.errors[0])}
                      label='Export Declaration Number (Optional)'
                      value={this.state.fields.freightShipping.exportDeclarationNumber.value}
                      helperText={this.state.fields.freightShipping.exportDeclarationNumber.errors[0]}
                      onChange={this.handleExportDeclarationNumberChange}
                      fullWidth
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonTextField
                      id='freightShipping.importPermitNumber'
                      style={{ float: 'left' }}
                      setRef={this.fieldRef['freightShipping.importPermitNumber']}
                      error={!isEmpty(this.state.fields.freightShipping.importPermitNumber.errors[0])}
                      label='Import Permit Number (Optional)'
                      value={this.state.fields.freightShipping.importPermitNumber.value}
                      helperText={this.state.fields.freightShipping.importPermitNumber.errors[0]}
                      onChange={this.handleImportPermitNumberChange}
                      fullWidth
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonDatePicker
                      id='freightShipping.importPermitDate'
                      floatingLabelText='Import Permit Date (Optional)'
                      onChange={this.handleImportPermitDateChange}
                      errorText={this.state.fields.freightShipping.importPermitDate.errors[0]}
                      value={this.state.fields.freightShipping.importPermitDate.value}
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonTextField
                      id='freightShipping.rfp'
                      style={{ float: 'left' }}
                      setRef={this.fieldRef['freightShipping.rfp']}
                      error={!isEmpty(this.state.fields.freightShipping.rfp.errors[0])}
                      label='RFP (Optional)'
                      value={this.state.fields.freightShipping.rfp.value}
                      helperText={this.state.fields.freightShipping.rfp.errors[0]}
                      onChange={this.handleRFPChange}
                      fullWidth
                    />
                  </div>
                </div>
                <div className='col-md-5 col-md-offset-1'>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonAutoSelect
                      id='freightShipping.shippingLine'
                      setRef={this.fieldRef['freightShipping.shippingLine']}
                      onChange={this.handleShippingLineChange}
                      label='Shipping Line (Optional)'
                      errorText={this.state.fields.freightShipping.shippingLine.errors[0]}
                      items={this.state.shippingLineParties}
                      value={this.state.fields.freightShipping.shippingLine.value}
                      selectedItem={this.state.selectedShippingLine}
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonTextField
                      id='freightShipping.vesselName'
                      style={{ float: 'left' }}
                      setRef={this.fieldRef['freightShipping.vesselName']}
                      error={!isEmpty(this.state.fields.freightShipping.vesselName.errors[0])}
                      label='Vessel Name (Optional)'
                      value={this.state.fields.freightShipping.vesselName.value}
                      helperText={this.state.fields.freightShipping.vesselName.errors[0]}
                      onChange={this.handleVesselNameChange}
                      fullWidth
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonAutoSelect
                      id='freightShipping.loadingPort'
                      setRef={this.fieldRef['freightShipping.loadingPort']}
                      onChange={this.handleLoadingPortChange}
                      label='Loading Port'
                      errorText={this.state.fields.freightShipping.loadingPort.errors[0]}
                      items={this.state.loadingPortList}
                      value={this.state.fields.freightShipping.loadingPort.value}
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonTextField
                      id='freightShipping.destinationPort'
                      style={{ float: 'left' }}
                      setRef={this.fieldRef['freightShipping.destinationPort']}
                      error={!isEmpty(this.state.fields.freightShipping.destinationPort.errors[0])}
                      label='Destination Port (Optional)'
                      value={this.state.fields.freightShipping.destinationPort.value}
                      helperText={this.state.fields.freightShipping.destinationPort.errors[0]}
                      onChange={this.handleDestinationPortChange}
                      fullWidth
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonTextField
                      id='freightShipping.finalDestination'
                      style={{ float: 'left' }}
                      setRef={this.fieldRef['freightShipping.finalDestination']}
                      error={!isEmpty(this.state.fields.freightShipping.finalDestination.errors[0])}
                      label='Final Destination (Optional)'
                      value={this.state.fields.freightShipping.finalDestination.value}
                      helperText={this.state.fields.freightShipping.finalDestination.errors[0]}
                      onChange={this.handleFinalDestinationChange}
                      fullWidth
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonTextField
                      id='freightShipping.shippedTo'
                      style={{ float: 'left' }}
                      setRef={this.fieldRef['freightShipping.shippedTo']}
                      error={!isEmpty(this.state.fields.freightShipping.shippedTo.errors[0])}
                      label='Shipped To (Optional)'
                      value={this.state.fields.freightShipping.shippedTo.value}
                      helperText={this.state.fields.freightShipping.shippedTo.errors[0]}
                      onChange={this.handleShippedToChange}
                      fullWidth
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='cardForm'>
              <h4 className='cardForm-title'>Commodity Details</h4>
              <div className='cardForm-content'>
                <div className='col-md-5'>
                  <div className='col-md-12 form-wrap padding-reset'>
                    <CommodityAutoComplete
                      id='commodityId'
                      setRef={this.fieldRef['commodityId']}
                      onChange={this.handleCommodityChange}
                      floatingLabelText='Commodity'
                      commodityId={this.state.fields.commodityId.value}
                      style={{ float: 'left' }}
                      errorText={this.state.fields.commodityId.errors[0]}
                      disabled={hasAnyChildren || isAgainstInternalContract}
                    />
                  </div>
                  <div className='col-md-12 form-wrap padding-reset'>
                    <VarietyAutoComplete
                      id='varietyId'
                      setRef={this.fieldRef['varietyId']}
                      label='Variety (Optional)'
                      commodityId={this.state.fields.commodityId.value}
                      varietyId={this.state.fields.varietyId.value}
                      dependsOnCommodity
                      onChange={this.handleVarietyChange}
                      style={{ float: 'left' }}
                      errorText={this.state.fields.varietyId.errors[0]}
                      onBlur={this.handleBlur}
                      disabled={isAgainstInternalContract && !isSystemCompany()}
                    />
                  </div>
                  <div className='col-md-12 form-wrap padding-reset'>
                    <GradeAutoComplete
                      id="plannedGradeId"
                      setRef={this.fieldRef["plannedGradeId"]}
                      style={{float: 'left'}}
                      commodityId={this.state.fields.commodityId.value}
                      varietyId={this.state.fields.varietyId.value}
                      varietyWarning={msg => this.setFieldCustomErrors('varietyId', msg)}
                      gradeId={this.state.fields.plannedGradeId.value}
                      selectedGradeId={this.state.fields.plannedGradeId.value}
                      season={this.state.fields.season.value}
                      dependsOnCommodity
                      dependsOnSeason
                      onChange={this.handleGradeChange}
                      errorText={this.state.fields.plannedGradeId.errors[0]}
                      onBlur={this.handleBlur}
                      disabled={(hasAnyChildren || isAgainstInternalContract) && !isSystemCompany()}
                    />
                  </div>
                </div>
                <div className='col-md-5 col-md-offset-1'>
                  <div className='col-md-12 form-wrap padding-reset'>
                    <SeasonSelect
                      id='season'
                      setRef={this.fieldRef['season']}
                      onChange={this.handleSeasonFieldChange}
                      season={this.state.fields.season.value}
                      style={{ float: 'right' }}
                      errorText={this.state.fields.season.errors[0]}
                      disabled={(hasAnyChildren || isAgainstInternalContract) && !isSystemCompany()}
                    />
                  </div>
                  <div className="col-md-12 form-wrap padding-reset">
                    <NumberField
                      id="plannedTonnage"
                      label={this.getTonnageLabel()}
                      setRef={this.fieldRef["plannedTonnage"]}
                      value={this.state.fields.plannedTonnage.value}
                      maxValue={999999999.99}
                      helperText={this.state.fields.plannedTonnage.errors[0]}
                      onChange={this.handleTonnageFieldChange}
                      onBlur={this.handleTonnageFieldChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='cardForm'>
              <h4 className='cardForm-title'>Container Details</h4>
              <div className='cardForm-content'>
                <div className='col-md-5'>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <Autocomplete
                      style={{ marginTop: '5px' }}
                      multiple
                      disableCloseOnSelect
                      ListboxComponent={ListboxComponent}
                      id='freightContainer.releaseNumbers'
                      options={this.state.fields.freightContainer.releaseNumbers.value.map(value => ({
                        inputValue: value,
                        name: value
                      }))}
                      getOptionLabel={(option) => option.inputValue || option.name}
                      filterOptions={(options, params) => {
                        const filtered = autocompleteFilters(options, params);
                        if (params.inputValue !== '') {
                          filtered.push({
                            inputValue: params.inputValue,
                            name: `Add "${params.inputValue}"`
                          });
                        }
                        return filtered;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoComplete='off'
                          fullWidth
                          placeholder={`Release Numbers${this.isExternallySyncedSite() ? '' :' (Optional)'}`}
                          helperText={this.state.fields.freightContainer.releaseNumbers.errors[0]}
                          error={!!this.state.fields.freightContainer.releaseNumbers.errors[0]}
                          label={`Release Numbers${this.isExternallySyncedSite() ? '' :' (Optional)'}`}
                          variant='standard'
                        />
                      )}
                      value={this.state.fields.freightContainer.releaseNumbers.value.map(value => ({ inputValue: value, name: value }))}
                      onChange={this.handleReleaseNumbersChange}
                      limitTags={5}
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonTextField
                      id='freightContainer.numberOfContainers'
                      style={{ float: 'left' }}
                      setRef={this.fieldRef['freightContainer.numberOfContainers']}
                      error={!isEmpty(this.state.fields.freightContainer.numberOfContainers.errors[0])}
                      label='Number Of Containers'
                      type='number'
                      value={this.state.fields.freightContainer.numberOfContainers.value}
                      helperText={this.state.fields.freightContainer.numberOfContainers.errors[0]}
                      onChange={this.handleNumberOfContainersChange}
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonAutoSelect
                      id='freightContainer.dualSeal'
                      setRef={this.fieldRef['freightContainer.dualSeal']}
                      onChange={this.handleContainerDetailsFieldChange}
                      label='Dual Seal'
                      errorText={this.state.fields.freightContainer.dualSeal.errors[0]}
                      items={[{ id: 'yes', value: 'Yes' }, { id: 'no', value: 'No' }]}
                      selectConfig={{ text: 'value', value: 'id' }}
                      value={this.state.fields.freightContainer.dualSeal.value}
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonAutoSelect
                      id='freightContainer.containerLining'
                      setRef={this.fieldRef['freightContainer.containerLining']}
                      onChange={this.handleContainerDetailsFieldChange}
                      label='Container Lining'
                      errorText={this.state.fields.freightContainer.containerLining.errors[0]}
                      items={[{ id: 'yes', value: 'Yes' }, { id: 'no', value: 'No' }]}
                      selectConfig={{ text: 'value', value: 'id' }}
                      value={this.state.fields.freightContainer.containerLining.value}
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonAutoSelect
                      id='freightContainer.fumigation'
                      setRef={this.fieldRef['freightContainer.fumigation']}
                      onChange={this.handleContainerDetailsFieldChange}
                      label='Fumigation'
                      errorText={this.state.fields.freightContainer.fumigation.errors[0]}
                      items={[{ id: 'yes', value: 'Yes' }, { id: 'no', value: 'No' }]}
                      selectConfig={{ text: 'value', value: 'id' }}
                      value={this.state.fields.freightContainer.fumigation.value}
                    />
                  </div>
                </div>
                <div className='col-md-5 col-md-offset-1'>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonDatePicker
                      id='freightContainer.packByDate'
                      floatingLabelText='Pack By Date'
                      onChange={this.handlePackByDateChange}
                      errorText={this.state.fields.freightContainer.packByDate.errors[0]}
                      value={this.state.fields.freightContainer.packByDate.value}
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonDatePicker
                      id='freightContainer.deliverByDate'
                      floatingLabelText='Deliver By Date'
                      onChange={this.handleDeliverByDateChange}
                      errorText={this.state.fields.freightContainer.deliverByDate.errors[0]}
                      value={this.state.fields.freightContainer.deliverByDate.value}
                      minDate={moment(this.state.fields.freightContainer.packByDate.value)}
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonAutoSelect
                      id='freightContainer.consignor.handlerId'
                      setRef={this.fieldRef['freightContainer.consignor.handlerId']}
                      onChange={this.handlePackSiteChange}
                      label='Pack Site'
                      items={this.state.consignors}
                      value={this.state.fields.freightContainer.consignor.handlerId.value}
                      errorText={this.state.fields.freightContainer.consignor.handlerId.errors[0]}
                      selectedItem={this.state.selectedConsignor}
                      dataSourceConfig={{ text: 'displayName', value: 'id' }}
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonAutoSelect
                      id='freightContainer.consignor.site.locationId'
                      setRef={this.fieldRef['freightContainer.consignor.site.locationId']}
                      onChange={this.handlePackSiteStorageChange}
                      label='Pack Site Storage (Optional)'
                      errorText={this.state.fields.freightContainer.consignor.site.locationId.errors[0]}
                      items={this.state.consignorSites}
                      value={this.state.fields.freightContainer.consignor.site.locationId.value}
                      disabled={!this.state.fields.freightContainer.consignor.handlerId.value}
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <div className='col-md-7 padding-reset form-wrap'>
                      <CommonAutoSelect
                        id='freightContainer.emptyContainerAoId'
                        setRef={this.fieldRef['freightContainer.emptyContainerAoId']}
                        onChange={this.handleEmptyContainerAOChange}
                        label='Empty Container AO'
                        errorText={this.state.fields.freightContainer.emptyContainerAoId.errors[0]}
                        items={this.state.packingProviderContacts}
                        value={this.state.fields.freightContainer.emptyContainerAoId.value}
                      />
                    </div>
                    <div className='col-md-4' style={{ float: 'right' }}>
                      <CommonTextField
                        id='freightContainer.emptyAoRef'
                        setRef={this.fieldRef['freightContainer.emptyAoRef']}
                        error={!isEmpty(this.state.fields.freightContainer.emptyAoRef.errors[0])}
                        label='Empty AO Ref'
                        value={this.state.fields.freightContainer.emptyAoRef.value}
                        helperText={this.state.fields.freightContainer.emptyAoRef.errors[0]}
                        onChange={this.handleEmptyAoRefChange}
                      />
                    </div>
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <div className='col-md-7 padding-reset form-wrap'>
                      <CommonAutoSelect
                        id='freightContainer.packedContainerAoId'
                        setRef={this.fieldRef['freightContainer.packedContainerAoId']}
                        onChange={this.handlePackedContainerAOChange}
                        label='Packed Container AO'
                        errorText={this.state.fields.freightContainer.packedContainerAoId.errors[0]}
                        items={this.state.packingProviderContacts}
                        value={this.state.fields.freightContainer.packedContainerAoId.value}
                      />
                    </div>
                    <div className='col-md-4' style={{ float: 'right' }}>
                      <CommonTextField
                        id='freightContainer.packedAoRef'
                        setRef={this.fieldRef['freightContainer.packedAoRef']}
                        error={!isEmpty(this.state.fields.freightContainer.packedAoRef.errors[0])}
                        label='Packed AO Ref'
                        value={this.state.fields.freightContainer.packedAoRef.value}
                        helperText={this.state.fields.freightContainer.packedAoRef.errors[0]}
                        onChange={this.handlePackedAoRefChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='cardForm'>
              <h4 className='cardForm-title'>Invoicing</h4>
              <div className='cardForm-content'>
                <div className='col-md-5'>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonSelect
                      id='invoicing'
                      setRef={this.fieldRef['invoicing']}
                      onChange={this.handleInvoicingChange}
                      floatingLabelText='Invoicing (Optional)'
                      errorText={this.state.fields.invoicing.errors[0]}
                      items={invoicing}
                      style={{ float: 'left' }}
                      value={this.state.fields.invoicing.value}
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonAutoSelect
                      id='paymentTermId'
                      setRef={this.fieldRef['paymentTermId']}
                      onChange={this.handleInvoicingChange}
                      label='Payment Term (Optional)'
                      value={this.state.fields.paymentTermId.value}
                      errorText={this.state.fields.paymentTermId.errors[0]}
                      items={this.props.paymentTerms}
                    />
                  </div>
                </div>
                <div className='col-md-5 col-md-offset-1'>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonTextField
                      id='ratePacking'
                      setRef={this.fieldRef['ratePacking']}
                      label='Packing Rate Per MT (Optional)'
                      onKeyDown={event => positiveDecimalFilter(event, 2, 10000)}
                      helperText={this.state.fields.ratePacking.errors[0]}
                      value={this.state.fields.ratePacking.value}
                      onChange={this.handleRateChange}
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start' style={{ color: 'rgb(162,162,162)' }}>
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonTextField
                      id='fumigationFee'
                      setRef={this.fieldRef['fumigationFee']}
                      label='Fumigation Fee Per MT (Optional)'
                      onKeyDown={event => positiveDecimalFilter(event, 2, 10000)}
                      helperText={this.state.fields.fumigationFee.errors[0]}
                      value={this.state.fields.fumigationFee.value}
                      onChange={this.handleRateChange}
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start' style={{ color: 'rgb(162,162,162)' }}>
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonTextField
                      id='wharfDeliveryFee'
                      setRef={this.fieldRef['wharfDeliveryFee']}
                      label='Wharf Delivery Fee Per Container (Optional)'
                      onKeyDown={event => positiveDecimalFilter(event, 2, 10000)}
                      helperText={this.state.fields.wharfDeliveryFee.errors[0]}
                      value={this.state.fields.wharfDeliveryFee.value}
                      onChange={this.handleRateChange}
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start' style={{ color: 'rgb(162,162,162)' }}>
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonTextField
                      id='wharfBookingFee'
                      setRef={this.fieldRef['wharfBookingFee']}
                      label='Wharf Booking Fee Per Container (Optional)'
                      onKeyDown={event => positiveDecimalFilter(event, 2, 10000)}
                      helperText={this.state.fields.wharfBookingFee.errors[0]}
                      value={this.state.fields.wharfBookingFee.value}
                      onChange={this.handleRateChange}
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start' style={{ color: 'rgb(162,162,162)' }}>
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='cardForm'>
              <h4 className='cardForm-title'>Conditions</h4>
              <div className='cardForm-content col-md-12'>
                <div className='col-md-5 form-wrap padding-reset'>
                  <CommonAutoSelect
                    items={this.props.generalConditionsAll}
                    setRef={this.fieldRef['generalConditionsSelector']}
                    style={{ float: 'left' }}
                    id='generalConditionsSelector'
                    value={this.state.generalConditionsSelector}
                    label='Conditions'
                    onChange={this.handleConditionSelector}
                    dontAutoselectSingleItem
                  />
                  {this.addGeneralConditionButton()}
                  <CommonTextField
                     id='generalConditionViewer'
                     setRef={this.fieldRef['generalConditionViewer']}
                     value={this.state.selectedGeneralConditionText}
                     multiline={true}
                     rows={2}
                     disabled={true}
                     rowsMax={2}
                   />
                </div>
                <div className='cardForm-content col-md-1 arrow-button'>
                   <IconButton
                     disabled={!isNumber(this.state.generalConditionsSelector)}
                     onClick={this.appendGeneralConditions}
                     size="large">
                     <ArrowForward color={PRIMARY_COLOR_GREEN} />
                   </IconButton>
                 </div>
                <div className='col-md-5 form-wrap padding-reset'>
                   <div className='field-label'>Conditions (Optional)</div>
                   <CommonTextField
                     id='generalConditions'
                     setRef={this.fieldRef['generalConditions']}
                     className='text-area'
                     label=''
                     value={this.state.fields.generalConditions.value}
                     multiline={true}
                     rows={6}
                     rowsMax={6}
                     onBlur={e => this.appendCustomCondition(e, 'generalConditions')}
                   />
                 </div>
              </div>
              <div className='cardForm-content col-md-12'>
                 <div className='col-md-5 form-wrap padding-reset'>
                   <CommonAutoSelect
                     items={this.props.specialConditionsAll}
                     setRef={this.fieldRef['specialConditionsSelector']}
                     id='specialConditionsSelector'
                     value={this.state.specialConditionsSelector}
                     label='Special'
                     onChange={this.handleConditionSelector}
                     dontAutoselectSingleItem
                   />
                   {this.addSpecialConditionButton()}
                   <CommonTextField
                     id='specialConditionViewer'
                     setRef={this.fieldRef['specialConditionViewer']}
                     value={this.state.selectedSpecialConditionText}
                     multiline={true}
                     rows={2}
                     disabled={true}
                     rowsMax={2}
                     onBlur={this.handleBlur}
                   />
                 </div>
                 <div className='cardForm-content col-md-1 arrow-button'>
                   <IconButton
                     disabled={!isNumber(this.state.specialConditionsSelector)}
                     onClick={this.appendSpecialConditions}
                     size="large">
                     <ArrowForward color={PRIMARY_COLOR_GREEN} />
                   </IconButton>
                 </div>
                 <div className='col-md-5 form-wrap padding-reset'>
                   <div className='field-label'>Conditions (Optional)</div>
                   <CommonTextField
                     id='specialConditions'
                     setRef={this.fieldRef['specialConditions']}
                     className='text-area'
                     label=''
                     value={this.state.fields.specialConditions.value}
                     multiline={true}
                     rows={6}
                     rowsMax={6}
                     onBlur={e => this.appendCustomCondition(e, 'specialConditions')}
                   />
                 </div>
               </div>
            </div>
            <div className='cardForm'>
              <h4 className='cardForm-title'>Notes</h4>
              <div className='cardForm-content col-md-5'>
                <Notes
                  title=''
                  handler={this.noteHandler}
                  note={this.state.fields.note}
                  disabled={false}
                />
              </div>
            </div>
            <div style={{ textAlign: 'right' }}>{buttons}</div>
          </form>
          <Dialog open={this.state.preview} onClose={this.handleOpenPreview} scroll='paper' fullScreen>
            <DialogTitle>Pack Order Preview</DialogTitle>
            <DialogContent>
              <PackOrderPreview previewFields={this.getPreviewFields()} ref={el => (this.componentRef = el)} />
            </DialogContent>
            <DialogActions style={{paddingBottom: "40px"}}>
              <CommonButton label='Back' key='closeButton' default onClick={this.handleClosePreview} variant='flat' />
              <CommonButton label='Submit' key='submitButton' primary={true} onClick={this.handleSubmit} variant='flat' />
            </DialogActions>
          </Dialog>
          {
          this.state.showEmailDialog &&
          <CustomEmailDialog
            parties={this.state.emailPopupParties}
            selectedParties='customer'
            title="Email PDF copies to"
            partyEmails={this.getPartyEmails()}
            partyContacts={this.getPartyContacts()}
            subject={this.getEmailSubject()}
            noBody={true}
            footer={this.getFooterNote()}
            open={this.state.showEmailDialog}
            onClose={this.closeEmailDialog}
            disableAcceptanceRequired
            isAcceptanceRequired={false}
            forceSetAcceptanceRequired={false}
          />
          }
          <SideDrawer
            isOpen={ this.state.generalConditionSideDrawerIsOpen }
            title="Add General Condition"
            onClose={ this.closeGeneralConditionSideDrawer }
            app="condition"
            >
            <CreateGeneralCondition
              selectedConditionType= "freight"
              closeDrawer={this.closeGeneralConditionSideDrawer}
              onSuccess={this.setDefaultGeneralCondition}
            />
          </SideDrawer>
          <SideDrawer
            isOpen={this.state.specialConditionSideDrawerIsOpen}
            title="Add Special Condition"
            onClose={this.closeSpecialConditionSideDrawer}
            app="condition"
            >
            <CreateSpecialCondition
              selectedConditionType="freight"
              closeDrawer={this.closeSpecialConditionSideDrawer}
              onSuccess={this.setDefaultSpecialCondition}
            />
          </SideDrawer>
        </div>
      );
    }
}

PackOrderForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  types: PropTypes.array.isRequired,
  generalConditionsAll: PropTypes.array,
  specialConditionsAll: PropTypes.array,
  providers: PropTypes.array,
  providerEmployees: PropTypes.array,
  location: PropTypes.object,
  currentUser: PropTypes.object,
  userCompanyId: PropTypes.number.isRequired,
  userParties: PropTypes.array,
  commodities: PropTypes.array.isRequired,
  userToken: PropTypes.string.isRequired,
  grades: PropTypes.array,
  varieties: PropTypes.array,
  defaultGeneralConditionId: PropTypes.string,
  isConditionSideDrawerOpened: PropTypes.bool,
  paymentTerms: PropTypes.array.isRequired,
  allDirectoryCompanies: PropTypes.array,
};

const mapStateToProps = state => {
  var types = state.companies.orders.types;

  const commodities = state.master.commodities.items || [];
  const varieties = state.master.varieties.items || [];
  const paymentTerms = state.companies.orders.paymentTerms;
  return {
    types,
    paymentTerms,
    currentUser: state.main.user.user,
    generalConditionsAll: state.companies.conditions.generalConditions,
    specialConditionsAll: state.companies.conditions.specialConditions,
    grades: state.master.grades.items || [],
    allDirectoryCompanies: state.companies.companies.companyParties,
    providers: state.companies.companies.companyParties,
    providerEmployees: state.companySettings.employees.items,
    contracts: state.companies.contracts.items,
    selectedOrder: state.companies.orders.selectedOrder,
    userCompanyId: state.main.user.user.companyId,
    userToken: state.main.user.token,
    commodities,
    varieties,
    isConditionSideDrawerOpened: state.companies.conditions.isCreateFormDisplayed,
    isLoading: state.main.isLoading,
  };
};

export default connect(mapStateToProps)(PackOrderForm);
