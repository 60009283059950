import React, { useEffect, useRef } from "react";
import { APIProvider, Map, AdvancedMarker, Pin, useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { GOOGLE_MAPS_API_KEY } from "../../common/constants";

const SearchBoxComponent = ({ searchBoxText, onSearchBoxChange,onPlacesChanged }) => {
  const placesLib = useMapsLibrary("places");
  const map = useMap();
  const inputRef = useRef(null);

  useEffect(() => {
    if (!placesLib || !map || !inputRef.current) return;

    const autocomplete = new placesLib.Autocomplete(inputRef.current);

    autocomplete.addListener("place_changed", () => {

      const place = autocomplete.getPlace();
      if (!place.geometry) return;
      const newLocation = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        formatted_address: place.formatted_address,
      };

      const address_components = place.address_components || [];
      onPlacesChanged(newLocation, address_components);
      map.setCenter(newLocation);
    });
  }, [placesLib]);

  return (
    <div style={{ position: "absolute", top: "10px", left: "50%", transform: "translateX(-50%)", zIndex: 1000 }}>
      <input
        ref={inputRef}
        type="text"
        value={searchBoxText}
        onChange={(e) => onSearchBoxChange(e.target.value)}
        placeholder="Search location..."
        style={{
          width: "300px",
          height: "40px",
          padding: "10px",
          fontSize: "16px",
          borderRadius: "8px",
          border: "1px solid #ccc", // eslint-disable-next-line spellcheck/spell-checker
          boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
          outline: "none",
        }}
      />
    </div>
  );
};

const GoogleMapSearchBox = ({ position, onSearchBoxChange,searchBoxText , onMarkerDragEnd, onPlacesChanged}) => {

  return (
    <div className="map">
      <div className="map-container">
        <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
          <Map
            id="map-search-box"
            defaultCenter={position}
            defaultZoom={15}
            mapId="DEMO_MAP_ID"
            mapTypeId="roadmap"
            draggableCursor={ "true"}
            draggable={true}
            style={{ width: "100%", height: "500px" }}
            options={{
              zoomControl: true,
            }}
          >
            <SearchBoxComponent
              onSearchBoxChange ={onSearchBoxChange}
              searchBoxText={searchBoxText}
              onPlacesChanged ={onPlacesChanged}
              />
            <AdvancedMarker
              position={position}
              draggable={true}
              onDragEnd={(event) => onMarkerDragEnd(event)}
            >
              <Pin/>
            </AdvancedMarker>
          </Map>
        </APIProvider>
      </div>
    </div>
  );
};

export default GoogleMapSearchBox;