import React from 'react';
import { connect } from 'react-redux';
import alertifyjs from 'alertifyjs';
import { InputAdornment } from '@mui/material/';
import { required } from '../../common/validators';
import '../../common/forms.scss';
import moment from 'moment';
import CommonButton from '../common/CommonButton';
import CommonDatePicker from '../common/CommonDatePicker';
import CommonMultiSelect from '../common/autocomplete/CommonMultiSelect';
import CommodityMultiSelect from '../common/autocomplete/CommodityMultiSelect';
import CommonTextField from '../common/CommonTextField';
import APIService from '../../services/APIService';
import { mapValues, map, isEmpty, get, includes, compact, filter, set } from 'lodash';
import SideDrawer from '../common/SideDrawer';
import { getCommodities } from '../../actions/api/commodities';
import { cartesian, getDateTimeFromUTC, isSystemCompany } from '../../common/utils';

const tomorrow = moment().add(1, 'days').startOf('day');

class ShrinkageForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      companies: [],
      sites: [],
      open: false,
      allowPastDate: false,
      fields: {
        siteIds: {
          value: [],
          validators: [required()],
          errors: [],
        },
        commodityIds: {
          value: [],
          validators: [required()],
          errors: [],
        },
        percentage: {
          value: undefined,
          validators: [required()],
          errors: [],
        },
        forCompanyIds: {
          value: [],
          validators: [],
          errors: [],
        },
        startDate: {
          value: '',
          validators: [required()],
          errors: [],
        },
        endDate: {
          value: '',
          validators: [],
          errors: [],
        },
      }
    };
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    const { open, dispatch, selected, type } = this.props;
    this.getSites();
    if(type === 'special')
      this.getCompanies();
    const startDateTime = getDateTimeFromUTC(tomorrow);
    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        startDate: {
          ...this.state.fields.startDate,
          value: startDateTime.date,
        }}
    })

    dispatch(getCommodities());
    if(this.state.open != open)
      this.setState({open: open});
    if(selected)
      this.setAllFieldValuesFromSelectedShrinkage();
  }

  componentDidUpdate() {
    const { open } = this.props;
    if(this.state.open != open)
      this.setState({open: open});
  }

  setAllFieldValuesFromSelectedShrinkage() {
    const { selected } = this.props;
    if(selected) {
      const newState = {...this.state};
      newState.fields.percentage.value = selected.percentage;
      newState.fields.siteIds.value = [selected.siteId];
      newState.fields.commodityIds.value = [selected.commodityId];
      newState.fields.forCompanyIds.value = compact([selected.forCompanyId]);
      newState.fields.startDate.value = selected.startDate;
      newState.fields.endDate.value = selected.endDate;
      this.setState(newState, () => {
        this.checkLoadExists();
      });
    }
  }

  getCompanies() {
    APIService.companies().appendToUrl('directory/names/?excludeGroups=true&include_self=true').get().then(companies => {
      this.setState({companies: companies});
    });
  }

  getSites() {
    const { companyId } = this.props;
    APIService.companies(companyId).company_sites().appendToUrl('minimal/').get().then(sites => this.setState({sites: map(sites, site => ({id: site.id, name: site.name, isActive: site.isActive}))}));
  }

  getPayload() {
    let data = mapValues(this.state.fields, 'value');
    const {commodityIds, forCompanyIds, siteIds, percentage, startDate, endDate} = data;

    let args = [[percentage], commodityIds, siteIds];
    if(!isEmpty(forCompanyIds))
      args.push(forCompanyIds);

    let results = cartesian(...args);
    let payload = map(results, result => {
      return {
        percentage: result[0],
        commodityId: result[1],
        siteId: result[2],
        forCompanyId: result[3],
        startDate: startDate,
        endDate: endDate || '9999-12-31',
      };
    });

    return payload;
  }

  isValid() {
    const { fields } = this.state;
    const standardCondition = !isEmpty(fields.commodityIds.value) &&
                              !isEmpty(fields.siteIds.value) &&
                              fields.percentage.value;
    if(this.props.type === 'special')
      return standardCondition && !isEmpty(fields.forCompanyIds.value);

    return standardCondition;
  }

  handleSubmit = event => {
    event.preventDefault();
    if(!this.isValid()) {
      alertifyjs.error('All Fields are mandatory.');
      return;
    }

    let payload = null;

    let action = 'post';
    let path = 'shrinkages/';
    if(this.props.selected) {
      action = 'put';
      payload = {}
      if (this.props.selected?.percentage != this.state.fields.percentage.value)
        set(payload, 'percentage', this.state.fields.percentage.value);
      if (moment(this.state.fields.startDate.value).format("YYYY-MM-DD") !=  moment(this.props.selected?.startDate).format("YYYY-MM-DD")) {
        set(payload, 'startDate', moment(this.state.fields.startDate.value));
        set(payload, 'datesUpdated', true);
      }
      if (moment(this.state.fields.endDate.value).format("YYYY-MM-DD") !=  moment(this.props.selected?.endDate).format("YYYY-MM-DD")) {
        set(payload, 'endDate', moment(this.state.fields.endDate.value));
        set(payload, 'datesUpdated', true);
      }
      path += `${this.props.selected.id}/`;
    }
    else
      payload = this.getPayload();

    APIService.farms().appendToUrl(path)[action](payload).then(data => {
      if(get(data, 'details[0]')) {
        if(includes(data.details[0], 'duplicate key') || includes(data.details, 'Overlapping Tenure'))
          alertifyjs.error('You are trying to create duplicate Shrinkage for one or more Site/Commodity combination. Please fix that and re-try!');
        else
          alertifyjs.error('An Error Occurred!');
      } else {
        alertifyjs.success('Successfully created!');
        this.onClose(null, true);
      };
    });
  };

  onMultiSelectChange = (id, selectedItems) => {
    const newState = {...this.state};
    newState.fields[id].value = map(selectedItems, 'id');
    this.setState(newState, () => {
      if(includes(['siteIds', 'commodityIds'], id))
        this.checkLoadExists();
    });
  };

  onClose = (event, refresh) => {
    this.setState({open: false}, () => this.props.onClose(refresh));
  };

  onPercentageChange(value) {
    const newState = {...this.state};
    newState.fields.percentage.value = value;
    this.setState(newState);
  }

  checkLoadExists() {
    if (!isEmpty(this.state.fields.commodityIds.value) && !isEmpty(this.state.fields.siteIds.value))
      APIService.loads().appendToUrl(`load-exists/?commodity_ids=${this.state.fields.commodityIds.value.join(',')}&farm_ids=${this.state.fields.siteIds.value.join(',')}`).get().then(response => {
        this.setState({allowPastDate: !response});
      });
  }

  handleDateChange(value, id) {
    const newState = {...this.state};
    newState.fields[id].value = value;
    this.setState(newState);
  }

  render() {
    const { commodities, type, selected } = this.props;
    const { sites, fields, open, companies } = this.state;
    const isEdit = Boolean(selected);
    return (
      <SideDrawer isOpen={open} title={isEdit ? 'Edit Shrinkage' : 'Shrinkage'} onClose={this.onClose}>
        <form noValidate>
          <div className="cardForm cardForm--drawer">
            <div className="cardForm-content row">
              <div className="col-sm-12 form-wrap-70" style={{margin: '10px 0'}}>
                {
                  isEdit ?
                  <div style={{marginTop: '25px'}}>
                    <CommonTextField
                      id="siteIds"
                      label="Site"
                      value={get(selected, 'siteName')}
                      variant='outlined'
                      disabled
                    />
                  </div>
                  : <CommonMultiSelect
                      id="siteIds"
                      items={filter(sites, site => site?.isActive)}
                      selectedItems={fields.siteIds.value}
                      displayField='name'
                      onChange={this.onMultiSelectChange}
                      placeholder="Select Site(s)..."
                      label='Site'
                      selectAll
                      clearAll
                    />
                }
              </div>
              {
                type === 'special' &&
                <div className="col-sm-12 form-wrap-70" style={{marginBottom: '10px'}}>
                  {
                    isEdit ?
                    <div style={{marginTop: '25px'}}>
                    <CommonTextField
                      id="customer"
                      label="Customer"
                      value={get(selected, 'customerName')}
                      variant='outlined'
                      disabled
                    />
                  </div>
                  : <CommonMultiSelect
                      id="forCompanyIds"
                      items={companies}
                      selectedItems={fields.forCompanyIds.value}
                      displayField='name'
                      onChange={this.onMultiSelectChange}
                      placeholder="Select Customer(s)..."
                      label='Customer'
                      selectAll
                      clearAll
                    />
                  }
                </div>
              }
              <div className="col-sm-12 form-wrap-70" style={{margin: '10px 0'}}>
                {
                  isEdit ?
                  <div style={{marginTop: '15px'}}>
                    <CommonTextField
                      id="commodityId"
                      label="Commodity"
                      value={get(selected, 'commodityName')}
                      variant='outlined'
                      disabled
                    />
                  </div>
                  : <CommodityMultiSelect
                      id="commodityIds"
                      commodities={commodities}
                      selectedCommodities={fields.commodityIds.value}
                      onChange={this.onMultiSelectChange}
                      placeholder='Select Commodities...'
                      label='Commodity'
                    />
                }
              </div>
              <div className="col-sm-12 form-wrap-70" style={{marginTop: '25px'}}>
                <CommonTextField
                  id='percentage'
                  label="Percentage"
                  value={this.state.fields.percentage.value}
                  helperText={this.state.fields.percentage.errors[0]}
                  onChange={event => this.onPercentageChange(event.target.value)}
                  variant='outlined'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        %
                      </InputAdornment>
                    ),
                  }}

                />
              </div>
              <div className="col-sm-12 form-wrap-70" style={{marginTop: '25px'}}>
                <CommonDatePicker
                  id='startDate'
                  floatingLabelText='Start Date'
                  onChange={this.handleDateChange}
                  errorText={get(this.state.fields.startDate, 'errors[0]')}
                  value={this.state.fields.startDate.value}
                  minDate={this.state.allowPastDate || isSystemCompany() ? null : tomorrow}
                />
              </div>
              <div className="col-sm-12 form-wrap-70" style={{marginTop: '25px'}}>
                <CommonDatePicker
                  id='endDate'
                  floatingLabelText='End Date (Optional)'
                  onChange={this.handleDateChange}
                  errorText={get(this.state.fields.endDate, 'errors[0]')}
                  value={this.state.fields.endDate.value}
                  minDate={this.state.fields.startDate.value ? moment(this.state.fields.startDate.value) : tomorrow}
                />
              </div>
              <div className="col-sm-12 form-wrap-70" style={{marginTop: '5px', textAlign: 'right', paddingRight: '0px'}}>
                <CommonButton
                  variant="contained"
                  onClick={this.onClose}
                  label='Cancel'
                />
                <CommonButton
                  variant="contained"
                  type='submit'
                  primary
                  onClick={this.handleSubmit}
                  label='Submit'
                />
              </div>
            </div>
          </div>
        </form>
      </SideDrawer>
    );
  }
}

const mapStateToProps = state => {
  return {
    commodities: state.master.commodities.items,
  };
};

export default connect(mapStateToProps)(ShrinkageForm);
