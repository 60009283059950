import React from 'react';
import { Virtuoso } from 'react-virtuoso'
import { Paper, Button } from '@mui/material';
import MailIcon from '@mui/icons-material/ForwardToInbox';
import Tooltip from '@mui/material/Tooltip';
import Skeleton from '@mui/material/Skeleton';
import Chip from '@mui/material/Chip';
import ExpandIcon from '@mui/icons-material/Expand';
import CollapseIcon from '@mui/icons-material/UnfoldLess';
import { connect } from 'react-redux';
import { get, find, isEmpty, isEqual, cloneDeep, map, filter, some, compact, flatten, values, sum, isArray, without, uniq, orderBy } from 'lodash';
import { forceStopLoader, setBreadcrumbs, setHeaderText, isLoading, setLoadingText } from '../../actions/main';
import APIService from '../../services/APIService';
import { PaymentRunBasicDetails } from './PaymentRunBasicDetails';
import { getCountryCurrency, toDateFormat } from '../../common/utils';
import InvoicePayableTable from '../../containers/InvoicePayableTable';
import { PAYABLE_INVOICE_ITEMS_COLUMNS } from '../../common/constants';
import { RejectionDetails } from '../rejections/RejectionDetails';
import Communications from '../common/Communications';

class PaymentRunDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentRunId: get(props, 'match.params.payment_run_id'),
      paymentRunDetails: undefined,
      invoicesItems: undefined,
      invoiceItemsGroupedByPayee: [],
      communications: [],
      selectedObj: false,
      expanded: [],
      loadingItemsFor: []
    }
  }

  toggleCommunications = (event, obj) => {
    event.stopPropagation()
    event.preventDefault()

    this.setState({selectedObj: obj || false})

    return false
  }

  componentDidMount() {
    this.props.dispatch(isLoading('paymentRunDetails'));
    this.props.dispatch(setLoadingText('Getting Payment Run Items...'));
    this.fetchPaymentRunDetails();
    this.fetchInvoices();
  }

  setHeaderAndBreadcrumbs() {
    const breadcrumbs = [
      {text: 'Payment Runs', route: '/invoices/payment-runs'},
      {text: get(this.state.paymentRunDetails, 'identifier', '')},
    ]
    const headerText = 'Payment Run';
    this.props.dispatch(setHeaderText(headerText));
    if (!isEqual(this.props.breadcrumbs, breadcrumbs)) {
      this.props.dispatch(setBreadcrumbs(breadcrumbs));
    }
  }

  fetchInvoices() {
    APIService.invoices().appendToUrl(`payment-run/${this.state.paymentRunId}/items/?items=false`).get().then(response =>
      this.setState({
        invoicesItems: response,
        expanded: response.length == 1 ? [response[0].companyId] : []
      }, this.fetchPaymentRunCommunications)
    );
  }

  fetchItems = (event, payeeCompanyId) => {
    event.preventDefault()
    event.stopPropagation()
    if(payeeCompanyId && this.state.expanded.includes(payeeCompanyId)) {
      this.setState({expanded: without(this.state.expanded, payeeCompanyId)})
    }
    else if(payeeCompanyId && !this.state.expanded.includes(payeeCompanyId) && !this.state.loadingItemsFor.includes(payeeCompanyId)) {
      this._fetchItems(payeeCompanyId)
    }
  }

  _fetchItems = (payeeCompanyId, onComplete=null) => {
    if(payeeCompanyId && !this.state.loadingItemsFor.includes(payeeCompanyId)) {
      this.setState({expanded: uniq([...this.state.expanded, payeeCompanyId]), loadingItemsFor: uniq([...this.state.loadingItemsFor, payeeCompanyId])}, () => {
        let payee = find(this.state.invoicesItems, {companyId: payeeCompanyId})
        if(!isEmpty(payee?.items)) {
          const newState = {...this.state}
          newState.loadingItemsFor = without(newState.loadingItemsFor, payeeCompanyId)
          this.setState(newState, () => {
            if (typeof onComplete === 'function') {
              onComplete();
            }
          })
        } else {
          APIService.invoices()
            .appendToUrl(`payment-run/${this.state.paymentRunId}/items/?payee_company_id=${payeeCompanyId}&only_items=true`)
            .get()
            .then(response => {
              const newState = {...this.state}
              payee.items = response[0]?.items || []
              payee.chemicalApplicationItems = response[0]?.chemicalApplicationItems || []
              payee = {...payee, ...response[0]}
              newState.loadingItemsFor = without(newState.loadingItemsFor, payeeCompanyId)
              this.setState(newState, () => {
                this.getPayableInvoiceItems(payeeCompanyId, false)
                if (typeof onComplete === 'function') {
                  onComplete();
                }
              })
            })
            .catch(() => {
              const newState = {...this.state}
              newState.loadingItemsFor = without(newState.loadingItemsFor, payeeCompanyId)
              this.setState(newState, () => {
                if (typeof onComplete === 'function') {
                  onComplete();
                }
              })
            });
        }
      })
    } else if (typeof onComplete === 'function') {
      onComplete();
    }
  }

  getPayableInvoiceItems(payeeCompanyId, canForceStopLoader=true) {
    const { invoicesItems } = this.state;
    if (!isEmpty(invoicesItems) && isArray(invoicesItems)) {
      let items = invoicesItems
      if(payeeCompanyId)
        items = filter(invoicesItems, {companyId: payeeCompanyId})
      map(items, obj => {
        obj.payeeName = obj.companyName;
        map(obj.items, invoiceItem => {
          invoiceItem.paymentDue = toDateFormat(invoiceItem.paymentDue)
          if (invoiceItem.epr) {
            invoiceItem.total = invoiceItem.total + invoiceItem.eprTotal;
          }
          if (invoiceItem.levy) {
            invoiceItem.total = invoiceItem.total + invoiceItem.levy;
          }
          if (invoiceItem.carry) {
            invoiceItem.total += invoiceItem.carry;
          }
        });
        obj.communications = this.getCommunications(obj.invoiceIds?.length ? obj.invoiceIds : map(obj.items, 'invoiceId'))
        map(obj.chemicalApplicationItems, chemicalApplicationItem => {
          let index = obj.items.findIndex(item => item.itemId === chemicalApplicationItem.chemicalAppliedOnLoadId);
          if (index !== -1) {
            let blendLoadItem = get(obj.items, index);
            chemicalApplicationItem.ref = get(blendLoadItem, 'ref');
            chemicalApplicationItem.itemUrl = get(blendLoadItem, 'itemUrl');
            chemicalApplicationItem.loadRef = get(blendLoadItem, 'loadRef');
            chemicalApplicationItem.contract = get(blendLoadItem, 'contract');
            chemicalApplicationItem.contractUrl = get(blendLoadItem, 'contractUrl');
            chemicalApplicationItem.invoiceUrl = get(blendLoadItem, 'invoiceUrl');
            chemicalApplicationItem.invoice = get(blendLoadItem, 'invoice');
            chemicalApplicationItem.confirmedInvoice = get(blendLoadItem, 'confirmedInvoice');
            chemicalApplicationItem.contractInvoicing = get(blendLoadItem, 'contractInvoicing');
            chemicalApplicationItem.paymentDue = get(blendLoadItem, 'paymentDue');
            chemicalApplicationItem.tonnageDisplayName = get(blendLoadItem, 'tonnageDisplayName');
            chemicalApplicationItem.rateDisplayName = `${getCountryCurrency()} ${chemicalApplicationItem.rate} / ${get(chemicalApplicationItem, 'adjustments.loadUnit')}`;
            chemicalApplicationItem.sellerNgrDetails = get(blendLoadItem, 'sellerNgrDetails');
            obj.items.splice(index + 1, 0, chemicalApplicationItem);
          }
        });
      });
    }
    const newState = {...this.state};
    newState.invoiceItemsGroupedByPayee = invoicesItems
    this.setState(newState, () => {
      this.props.dispatch(setLoadingText(null))
      if(canForceStopLoader)
        this.props.dispatch(forceStopLoader())
    });
  }

  getCommunications = invoiceIds => filter(this.state.communications, comm => invoiceIds.includes(comm.invoiceId))

  fetchPaymentRunDetails() {
    if (this.state.paymentRunId) {
      APIService.invoices()
        .appendToUrl(`payment-run/${this.state.paymentRunId}/`)
        .get()
        .then(response => this.setState({paymentRunDetails: response}, this.setHeaderAndBreadcrumbs));
    }
  }

  fetchPaymentRunCommunications() {
    if (this.state.paymentRunId) {
      APIService.invoices()
        .appendToUrl(`payment-run/${this.state.paymentRunId}/communications/`)
        .get()
        .then(response => this.setState({communications: response}, this.getPayableInvoiceItems));
    }
  }

  getColumns() {
    let columns = cloneDeep(PAYABLE_INVOICE_ITEMS_COLUMNS);
    map(columns, obj => {
      if (obj.key === 'levy' || obj.key === 'epr') {
        obj.isColumnEditable = false;
      }
    });
    columns = columns.filter(column => column.key !== 'confirmedInvoice');
    if (get(this.state.paymentRunDetails, 'statusDisplay') !== 'Void') {
      const invoice = {
        urlKey: 'invoiceUrl',
        header: 'Invoice',
        fieldType: 'url-conditional',
        link: true,
        key: 'invoice',
        className: 'small'
      }
      columns.splice(2, 0, invoice);
    }
    return columns;
  }

  onExpandAll = event => {
    event.stopPropagation()
    event.preventDefault()
    const payeeCompanyIds = compact(map(orderBy(this.state.invoiceItemsGroupedByPayee, 'companyName'), 'companyId'))
    if(payeeCompanyIds?.length) {
      this.props.dispatch(isLoading('LoadingExpandAll'))
      let remainingRequests = payeeCompanyIds.length

      this.setState({expanded: [...payeeCompanyIds]}, () => {
        if(remainingRequests === 0) {
          this.props.dispatch(forceStopLoader())
          return
        }

        const onItemComplete = () => {
          remainingRequests--
          if(remainingRequests <= 0) {
            this.props.dispatch(forceStopLoader())
          }
        }

        payeeCompanyIds.forEach(id => {
          this._fetchItems(id, onItemComplete)
        })
      })
    }
  }

  onCollapseAll = () => this.setState({expanded: []})

  render() {
    const { invoiceItemsGroupedByPayee, selectedObj } = this.state;
    const invoiceItems = orderBy(invoiceItemsGroupedByPayee, obj => obj?.payeeName?.toLowerCase())
    const tableColumns = this.getColumns();
    let reasonobject = {}
    reasonobject.requestReason = get(this.state.paymentRunDetails, 'voidReason');
    reasonobject.action = "Payment Run Void"
    const isExpandAll = Boolean(
      this.state.expanded?.length === this.state.invoiceItemsGroupedByPayee?.length &&
      this.state.expanded?.length
    )
    const collapseButtonLabel =  invoiceItemsGroupedByPayee?.length > 1 ? "Collapse All": "Collapse"
    const expandButtonLabel = invoiceItemsGroupedByPayee?.length > 1 ? "Expand All": "Expand"

    return (
      <Paper className='paper-table'>
        <PaymentRunBasicDetails
          paymentRunDetails={this.state.paymentRunDetails}
        />
        {
          reasonobject &&
            <RejectionDetails
              rejectionReasonObject={reasonobject}
              className="order-details-section-container"
            />
        }
        <div style={{ display: 'flex', justifyContent: 'right', marginBottom: '10px', alignItems: 'center' }}>
          <Chip
            icon={isExpandAll ? <CollapseIcon fontSize='inherit' /> : <ExpandIcon fontSize='inherit' />}
            label={isExpandAll ? collapseButtonLabel : expandButtonLabel}
            onClick={isExpandAll ? this.onCollapseAll : this.onExpandAll}
          />
        </div>
        <div>
          {
            !isEmpty(invoiceItemsGroupedByPayee) &&
              <Virtuoso
                data={invoiceItems}
                style={{ height: '100vh' }}
                itemContent={(index, obj) => {
                  const communications = obj.communications
                  const recipients = compact(flatten(map(communications, comm => flatten(values(comm.recipients)))))
                  const mailStatus = sum(flatten(map(communications, comm => values(comm.mailStatus))))
                  const isPending = some(communications, comm => isEmpty(comm.mailStatus))
                  const success = recipients.length === mailStatus
                  const partialSuccess = mailStatus > 0;
                  const color = success ? 'success' : ((partialSuccess || isPending) ? 'info' : 'error')
                  const isExpanded = this.state.expanded.includes(obj.companyId)
                  return (
                    <div id={obj.payeeName} key={obj.payeeName}>
                      <Tooltip title={isExpanded ? 'Click to collapse' : 'Click to expand and view items'}>
                        <div style={{ background: '#F5F5F5', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }}  onClick={event => this.fetchItems(event, obj.companyId)}>
                          <span>
                          <span style={{ paddingLeft: '10px', fontSize: '20px', lineHeight: '50px', alignItems: 'center' }}>
                            {obj.payeeName}
                          </span>
                          {
                            !isEmpty(obj.communications) &&
                              <span style={{marginLeft: '10px', alignItems: 'center'}}>
                                <Button onClick={event => this.toggleCommunications(event, obj)} variant='text' startIcon={<MailIcon fontSize='small' />} size='small' color={color}>
                                  Email Status
                                </Button>
                              </span>
                          }
                          </span>
                          <span>
                            <Chip label={`Items: ${obj.totalItems}`} variant='outlined' color='secondary' size='small' />
                            </span>
                        </div>
                      </Tooltip>
                      <div style={isExpanded ? {} : { marginTop: '10px' }}>
                        {
                          (!obj?.items?.length || !isExpanded) ?
                            (
                              isExpanded ? <Skeleton width='100%' height={100} /> : <div />
                            ) :
                            <InvoicePayableTable
                              height={(((obj.items?.length || 0)) * 45) + 77 + 16 + 'px'}
                              items={obj.items}
                              columns={tableColumns}
                            />
                        }
                      </div>
                    </div>
                  )

                }}
              />
          }
        </div>
        {
          Boolean(selectedObj) &&
            <Communications
              open
              communications={selectedObj.communications}
              title={`${selectedObj.payeeName} Communications`}
              onClose={event => this.toggleCommunications(event)}
            />
        }
      </Paper>
  );
}
}

export default connect()(PaymentRunDetails);
