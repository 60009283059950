import React from 'react';
import alertifyjs from 'alertifyjs';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Paper, Table, TableHead, TableBody, TableRow, TableCell, Button, TextField } from '@mui/material';
import { map, startCase, isEmpty, includes, isObject, findIndex, merge } from 'lodash';
import { setHeaderText, setBreadcrumbs, isLoading, forceStopLoader } from '../../actions/main';
import { toDateTimeFormat, getCountryLabel } from '../../common/utils'
import APIService from '../../services/APIService';

class MobileMessages extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      count: null,
      allMessages: [],
      messages: [],
      isLoading: true,
      blocked: true,
      unsent: false,
      all: false,
      searchText: '',
    };
  }

  componentDidMount() {
    this.fetchMessages();
    this.props.dispatch(setHeaderText('Mobile Messages'));
    this.props.dispatch(setBreadcrumbs([{text: 'Mobile Messages'}]));
  }

  fetchMessages = params => this.setState(
    {isLoading: true},
    () => {
      this.props.dispatch(isLoading('mobileMessages'));
      APIService.mobile_messages()
                    .get(null, null, merge({blocked: this.state.blocked, unsent: this.state.unsent}, params || {}))
                    .then(response => this.setState({
                      isLoading: false, messages: response.results, count: response.count,
                      allMessages: response.results,
                    }, () => this.props.dispatch(forceStopLoader())));
    }
  )

  formatDate = date => toDateTimeFormat(date)

  onUnblockClick = message => {
    if(message?.id) {
      alertifyjs.confirm(
        'Unblock Recipient',
        `Are you sure you want to unblock recipient ${message.recipient} from sender number ${message.sender_number}, the movement ${message.movement_identifier} is ${message.movement_status}.`,
        () => {
          APIService.mobile_messages(message.id).appendToUrl('unblock/?force=true').put({id: message.id}).then(() => {
            this.fetchMessages();
            alertifyjs.notify('Unblocked. Reloading...', 'success');
          });
        },
        () => {}
      )
    }
  }

  onReminderClick = message => {
    alertifyjs.confirm(
      `Send Reminder to ${message.recipient}`,
      `Are you sure you want to remind ${message.recipient}?`,
      () => {
        APIService.mobile_messages(message.id).appendToUrl('remind/').post({}).then(response => {
          if(isObject(response)) {
            const newMessage = {...message, last_reminder_sent_at: response.lastReminderSentAt, status: response.status};
            const newState = {...this.state};
            let index = findIndex(newState.allMessages, {id: message.id});
            newState.allMessages.splice(index, 1, newMessage);
            index = findIndex(newState.messages, {id: message.id});
            newState.messages.splice(index, 1, newMessage);
            this.setState(newState, () => alertifyjs.success('Reminded!', 2));
          }
        });
      },
      () => {}
    );
  }

  onSendClick = message => {
    alertifyjs.confirm(
      `Send Message to ${message.recipient}`,
      `Are you sure you want to send ${message.recipient}? Have you unblocked this recipient?`,
      () => {
        APIService.mobile_messages(message.id).appendToUrl('send-unsent/').get().then(() => {
          this.fetchMessages();
          alertifyjs.success('Sent!');
        });
      },
      () => {}
    );
  }

  onDeleteClick = message => {
    alertifyjs.confirm(
      `Delete Message`,
      `Are you sure you want to delete this message?`,
      () => {
        APIService.mobile_messages(message.id).delete().then(() => {
          this.fetchMessages();
          alertifyjs.success('Deleted!');
        });
      },
      () => {}
    );
  }

  onSearch = () => {
    const { searchText } = this.state;
    if(searchText)
      this.fetchMessages({search: searchText});
    else
      this.fetchMessages();
  }

  handleSearchEnterKeyPress = event => {
    if (event.key === 'Enter') {
      this.onSearch();
    }
  }

  onViewClick = message => alertifyjs.alert(`Message to ${message.recipient} for movement ${message.movement_identifier}`, message.message.replaceAll('\n', '<br />'), () => {})

  onViewToggle = params => this.setState({...params}, this.fetchMessages)

  render() {
    const { count, messages, blocked, unsent, all, isLoading, searchText } = this.state;
    const currentCount = messages.length;
    const messageType = blocked ? 'Blocked Docket' : (unsent ? 'Unsent Docket' : '');
    const title = `Showing ${currentCount} out of ${count} ${messageType} Messages`;
    return (
      <Paper className='col-xs-12' style={{marginBottom: '5px'}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='left'>
                <span style={{display: 'flex', alignItems: 'center'}}>
                  <Button
                    style={{margin: '0 5px'}}
                    variant={blocked ? 'outlined' : 'text'}
                    color={blocked ? 'primary' : 'default'}
                    onClick={() => this.onViewToggle({blocked: true, all: false, unsent: false})}>
                    Blocked
                  </Button>
                  <Button
                    style={{margin: '0 5px'}}
                    variant={unsent ? 'outlined' : 'text'}
                    color={unsent ? 'primary' : 'default'}
                    onClick={() => this.onViewToggle({blocked: false, all: false, unsent: true})}>
                    Unsent
                  </Button>
                  <Button
                    style={{margin: '0 5px'}}
                    variant={all ? 'outlined' : 'text'}
                    color={all ? 'primary' : 'default'}
                    onClick={() => this.onViewToggle({blocked: false, all: true, unsent: false})}>
                    All
                  </Button>
                </span>
              </TableCell>
              <TableCell align='left' colSpan={2}>
                {isLoading ? 'Loading Messages...' : title}
              </TableCell>
              <TableCell align='left' colSpan={6}>
                <TextField
                  style={{minWidth: '300px', marginRight: '10px'}}
                  placeholder={`By Recipient/Movement/${getCountryLabel('rego')}...`}
                  value={searchText}
                  variant='outlined'
                  onChange={event => this.setState({searchText: event.target.value})}
                  InputProps={{
                    classes: {
                      input: 'input-dense',
                    }
                  }}
                  inputProps={{
                    onKeyPress: this.handleSearchEnterKeyPress
                  }}
                />
                <Button color='primary' variant='outlined' onClick={this.onSearch}>
                  Search
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell>Message Status</TableCell>
              {
                all &&
                <TableCell>Type</TableCell>
              }
              <TableCell>Recipient</TableCell>
              <TableCell>Sender</TableCell>
              <TableCell>Sent At</TableCell>
              <TableCell>Reminder Sent At</TableCell>
              <TableCell>{`Outload ${getCountryLabel('rego')}`}</TableCell>
              <TableCell>Movement</TableCell>
              <TableCell>Movement Status</TableCell>
              <TableCell align='center'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              !isLoading && isEmpty(messages) &&
              <TableRow>
                <TableCell align='center' colSpan={8}>0 messages found</TableCell>
              </TableRow>
            }
            {
              map(messages, message => {
                const isMessageClosed = includes(['manually_resolved', 'docket_received'], message.status);
                return (
                  <TableRow key={message.id}>
                    <TableCell>
                      <span>
                        <span style={{color: 'gray', fontSize: '8px', marginRight: '5px'}}>
                          {`(id=${message.id})`}
                        </span>
                        <span>
                          {startCase(message.status)}
                        </span>
                      </span>
                    </TableCell>
                    {
                      all && <TableCell>{startCase(message.type)}</TableCell>
                    }
                    <TableCell>{message.recipient}</TableCell>
                    <TableCell>{message.sender_number}</TableCell>
                    <TableCell>{this.formatDate(message.sent_at)}</TableCell>
                    <TableCell>{message.last_reminder_sent_at ? this.formatDate(message.last_reminder_sent_at) : '-'}</TableCell>
                    <TableCell>{message.outload_rego}</TableCell>
                    <TableCell>
                      <Link to={`/freights/movements/${message.movement_id}/details`}>
                        {message.movement_identifier}
                      </Link>
                    </TableCell>
                    <TableCell>{startCase(message.movement_status)}</TableCell>
                    <TableCell>
                      <Button size='small' variant='outlined' onClick={() => this.onViewClick(message)} style={{marginLeft: '5px'}}>View</Button>
                      {
                        blocked && !isMessageClosed &&
                          <Button size='small' color='secondary' variant='outlined' onClick={() => this.onReminderClick(message)} style={{marginLeft: '5px'}}>Remind</Button>
                      }
                      {
                        unsent &&
                          <Button style={{marginLeft: '5px'}} size='small' color='primary' variant='outlined' onClick={() => this.onSendClick(message)}>Send</Button>
                      }
                      {
                        unsent && message.movement_status != 'in_progress' &&
                          <Button style={{marginLeft: '5px'}} size='small' color='error' variant='outlined' onClick={() => this.onDeleteClick(message)}>Delete</Button>
                      }
                      {
                        blocked &&
                          <Button style={{marginLeft: '5px'}} size='small' color='error' variant='outlined' onClick={() => this.onUnblockClick(message)}>Unblock</Button>
                      }
                    </TableCell>
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

export default connect()(MobileMessages);
