import React from 'react';
import Chart from 'chart.js';
import moment from 'moment'
import { Card, CardContent, Typography, Zoom, Grow, CardActions, Button, Dialog, DialogTitle, DialogContent, DialogActions, Divider } from '@mui/material';
import { find, map, isEmpty, findLastIndex, get } from 'lodash';
import { BLACK } from '../../common/constants';
import './StatCard.scss'
import { getCountryCurrency } from '../../common/utils';

const STATS = [
  {field: 'count', label: 'Total', createdLabel: 'Created', delay: 1000},
  {field: 'completed', label: 'Completed', delay: 2000},
  {field: 'tonnage', label: 'Quantity', createdLabel: 'Created Quantity', suffix: ' MT', delay: 1500},
  {field: 'amount', label: 'Amount', createdLabel: 'Created Amount', prefix: `${getCountryCurrency()} `, delay: 2000},
  {field: 'subscriber', label: 'Subscriber', createdLabel: 'Subscriber', delay: 1500},
  {field: 'registered', label: 'Registered', createdLabel: 'Created Registered', delay: 1500},
  {field: 'unregistered', label: 'UnRegistered', createdLabel: 'Created Unregistered', delay: 2000},
  {field: 'smsSent', label: 'SMS Sent', delay: 1000},
  {field: 'docketsSmsSent', label: 'SMS Dockets Sent', delay: 1500},
  {field: 'docketsSmsReplied', label: 'SMS Dockets Replied', delay: 2000},
]


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom in ref={ref} {...props} />;
})

const ChartContainer = ({open, title, id, stats, delay, fields, toggleDistribution}) => {
  const contentId = `${id}-content`
  const makeChart = () => {
    let distribution = stats || []
    let labels = []
    let fieldValues = {}
    let createdLabels = []
    let createdFieldValues = {}
    distribution.all.forEach(dist => {
      const month = moment(dist.month).format('MMM YY')
      labels.push(month)
      createdLabels.push(month)
      fields.forEach(field => {
        fieldValues[field.id] = fieldValues[field.id] || []
        fieldValues[field.id].push(dist[field.id])
        createdFieldValues[field.id] = createdFieldValues[field.id] || []
        const createdDist = find(distribution.created, {month: dist.month})
        if(createdDist)
          createdFieldValues[field.id].push(createdDist[field.id])
        else
          createdFieldValues[field.id].push(0)
      })
    })
    let datasets = map(fieldValues, (data, fieldId) => {
      const field = find(fields, {id: fieldId})
      return {
        label: field.label,
        data: fieldValues[fieldId],
        fill: false,
        borderColor: field.borderColor || field.color,
        tension: 0.1,
        type: field.type || 'line',
        backgroundColor: field.bgColor,
        borderWidth: field.type === 'bar' ? 1 : 2,
      }
    })
    if(!isEmpty(distribution?.created))
      datasets = [...datasets, ...map(createdFieldValues, (data, fieldId) => {
        const field = find(fields, {id: fieldId})
        return {
          label: 'Created ' + field.label,
          data: createdFieldValues[fieldId],
          fill: false,
          borderColor: BLACK,
          tension: 0.1,
          type: field.type || 'line',
          backgroundColor: BLACK,
          borderWidth: field.type === 'bar' ? 1 : 2,
        }
      })]
    const setBackground = chart => {
      const ctx = chart.chart.ctx;
      const chartArea = chart.chartArea;
      const xScale = chart.scales['x-axis-0'];

      const years = ['16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26']; // Specify the years you have data for
      const yearColors = ['rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(0, 0, 0, 0.1)', 'rgba(0, 0, 0, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(0, 0, 0, 0.1)', 'rgba(0, 0, 0, 0.2)', 'rgba(255, 99, 132, 0.2)']; // Specify the colors for each year

      years.forEach((year, index) => {
        let startIndex = labels.findIndex(label => label.endsWith(year));
        if(startIndex != 0)
          startIndex -= 1
        const endIndex = findLastIndex(labels, label => label.endsWith(year));
        const startX = xScale.getPixelForValue(labels[startIndex]);
        const endX = xScale.getPixelForValue(labels[endIndex]);

        ctx.fillStyle = yearColors[index];
        ctx.fillRect(startX, chartArea.top, endX - startX, chartArea.bottom - chartArea.top);
      });

    }
    const config = {
      type: 'line',
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        scales: {
          xAxes: [{
            gridLines: {
              drawOnChartArea: false, // Disable grid lines
            },
          }],
        },
      },
      plugins: [{
        beforeDraw: setBackground,
      }]
    }
    new Chart(document.getElementById(contentId), config)
  }

  React.useEffect(() => {
    if(!isEmpty(stats) && open)
      setTimeout(makeChart, 1000)
  }, [open, stats])

  React.useEffect(() => {
    return () => {
      const el = document.getElementById(contentId)
      if(el) {
        el.remove()
      }
    }
  }, [])

  return (
    <Dialog TransitionComponent={Transition} open={open} onClose={toggleDistribution} id={id} fullWidth maxWidth='lg'>
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        <Zoom in style={{transitionDelay: delay ? delay : '0ms'}}>
          <canvas id={contentId} />
        </Zoom>
      </DialogContent>
      <DialogActions>
        <Button color='default' size='small' onClick={toggleDistribution}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const NumberCard = ({id, stats, secondaryText, icon, bgColor, txtColor, iconBgColor, iconGradient, delay, toggleDistribution, countField}) => {
  let _countField = countField || 'count'
  return (
    <Zoom in style={{transitionDelay: delay ? delay : '0ms'}}>
      <Card id={id} className='stat-card' style={{backgroundColor: bgColor, color: txtColor}}>
        <CardContent className='stat-content'>
          <div className='stat-icon' style={{color: iconBgColor, backgroundImage: iconGradient}}>
            {icon}
          </div>
          <div className='stat-primary'>
            {
              STATS.map(config => stats.all[config.field] ? (
                <Grow key={config.field} in timeout={config.delay}>
                  <div className='stat-param'>
                    <span className={get(stats.created, config.field) ? 'width-50' : ''}>
                      <Typography variant="h5" component="div" sx={{fontWeight: '700', textAlign: 'center'}}>
                        {config.prefix || ''}{stats.all[config.field].toLocaleString()}{config.suffix || ''}
                      </Typography>
                      <Typography sx={{ opacity: 0.72, fontSize: '12px' }}>
                        {config.label}
                      </Typography>
                    </span>
                    {
                      get(stats.created, config.field) ? (
                        <React.Fragment>
                          <Divider orientation='vertical' style={{borderColor: txtColor, margin: '0 2px', height: '125%'}} />
                          <span className='width-50'>
                            <Typography variant="h5" component="div" sx={{fontWeight: '700', textAlign: 'center'}}>
                              {config.prefix || ''}{stats.created[config.field].toLocaleString()}{config.suffix || ''}
                            </Typography>
                            <Typography sx={{ opacity: 0.72, fontSize: '12px' }}>
                              {config.createdLabel || config.label}
                            </Typography>
                          </span>
                        </React.Fragment>
                      ) : null
                    }
                  </div>
                </Grow>
              ) : null)
            }
          </div>
          <Typography sx={{ opacity: 0.72, fontSize: '18px', marginTop: '20px' }}>
            {secondaryText}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <Button color='default' size='small' style={{textTransform: 'none'}} onClick={toggleDistribution} disabled={!stats.all[_countField]}>
            Show Distribution
          </Button>
        </CardActions>
      </Card>
    </Zoom>
  )
}


const StatCard = props => (
  <React.Fragment>
    <NumberCard {...props} stats={props.stats.stats} />
    <ChartContainer {...props} stats={props.stats.distribution} open={Boolean(props.isDistribution)} />
  </React.Fragment>
);

export default StatCard;
