export const CARRY_ITEM_TYPE = 'carryitem';
export const CUSTOM_ITEM_TYPE = 'customitem';
export const FREIGHT_CONTRACT_ITEM_TYPE = 'freightcontract';
export const TITLE_TRANSFER_ITEM_TYPE = 'titletransfer';
export const EPR_ITEM_TYPE = 'epr';
export const EPR_ITEM_DB_TYPE = 'epritem';
export const GRAIN_LEVY_ITEM_TYPE = 'grainlevy';
export const LOAD_ITEM_TYPE = 'load';
export const STOCK_SWAP_ITEM_TYPE = 'warehousestockswapitem';
export const WAREHOUSE_TRANSFER_ITEM_TYPE = 'warehousetransferitem';
export const WAREHOUSE_STORAGE_ITEM_TYPE = 'warehousestorageitem';
export const WAREHOUSE_OUTLOAD_ITEM_TYPE = 'warehouseoutloaditem';
export const WAREHOUSE_INTLOAD_ITEM_TYPE = 'warehouseinloaditem';
export const WAREHOUSE_THROUGHPUT_OUTLOAD_ITEM_TYPE = 'warehousethroughputoutloaditem';
export const WAREHOUSE_THROUGHPUT_INLOAD_ITEM_TYPE = 'warehousethroughputinloaditem';
export const REGRADE_RESEASON_ITEM_TYPE = 'warehouseregradereseasonitem';
