import React, { useState } from 'react';
import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { KeyboardArrowDown, ArrowForwardIosSharp } from '@mui/icons-material';
import GenericTable from './GenericTable';
import { get, has, map } from 'lodash';
import OptionMenu from './OptionMenu';

let Row = props => {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell style={{width: '6%'}}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ?<KeyboardArrowDown /> : <ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} /> }
          </IconButton>
        </TableCell>
        {
          map(props.rowHeaders, rowHeader => {
            return (
              rowHeader.key === "actions" ?
                <TableCell align="right" style={{width: '6%', padding: '0px !important'}}>
                  <OptionMenu
                    optionsItems={props.rowActionOptions}
                    item={props.row}
                    handleOptionClick={props.handleRowOptionClick}
                    currentUser={props.user}
                    stopPropagation
                    iconSize="small"
                  />
                </TableCell>
              :
              <TableCell
                className={rowHeader.className}
                style={{ padding: '0 4px', whiteSpace: 'normal !important', width: '12%' }}
                sx={{fontSize: '0.8125rem'}}
              >
                {
                  has(rowHeader, 'toolTipKey') ?
                    <Tooltip title={get(props.row, rowHeader.toolTipKey)} placement='top'>
                      <span>{rowHeader.formatter ? rowHeader.formatter(props.row) : get(props.row, rowHeader.key)}</span>
                    </Tooltip> :
                  <span>{rowHeader.formatter ? rowHeader.formatter(props.row) : get(props.row, rowHeader.key)}</span>
                }
              </TableCell>
            )
          })
        }
      </TableRow>
      <TableRow style={{padding: '0px !important'}}>
        <TableCell className='padding-reset' style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'rgb(211, 211, 211, 0.3)' }} colSpan={props.rowHeaders.length+1}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className='expanded-row'>
              <GenericTable
                columns={[{cellStyle: {padding: '0 !important', width: '6%'}, default: ' '}, ...props.columns]}
                items={props.row.items}
                editColumnClass="xsmall"
                showHeader
                showHeaderValue={false}
                orderBy="siteName"
                optionsItems={props.optionItems}
                handleOptionClick={props.onOptionClick}
                handleDefaultCellClick={props.onDefaultClick}
                hasActions={props.hasActions}
                hideActionLabel
                actionStyles={{width: '6%', textAlign: 'center', paddingRight: '0px !important'}}
                hideColumnHeaders
                actionsClass='none'
                displayIDColumn={props.displayIDColumn}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

let CollapsibleTable = props => {

  return (
    <TableContainer>
      <Table aria-label="collapsible table" size='small'>
        <TableHead>
          <TableRow>
            <TableCell style={{width: '6%'}} />
            {
              map(props.rowHeaders, header => (
                <TableCell
                  className={header.className}
                  style={{ whiteSpace: 'normal !important' }}
                  sx={{fontSize: '0.8125rem'}}>
                  {header.key === "actions" ? "" : header.label}
                </TableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            map(props.rowData, (obj, index) => (
              <Row
                key={index}
                row={obj}
                onOptionClick={props.onOptionClick}
                onDefaultClick={props.onDefaultClick}
                hasActions={props.hasActions}
                columns={props.itemColumns}
                rowHeaders={props.rowHeaders}
                optionItems={props.optionItems}
                rowActionOptions={props.rowActionOptions}
                handleRowOptionClick={props.handleRowOptionClick}
                displayIDColumn={props.displayIDColumn}
              />
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CollapsibleTable;
