import {
  REQUEST_VERSION,
  RECEIVE_VERSION,
  RECEIVE_MARKET_ZONES,
  RECEIVE_BANKS,
  UPDATE_IS_LOADING,
  RECEIVE_ABN_RESPONSE,
  RECEIVE_COMPANY_RESPONSE,
  RECEIVE_LOGIN,
  LOGOUT,
  CHANGE_PASSWORD_ERRORS,
  SET_BREADCRUMBS,
  SET_HEADER_TEXT,
  SET_SUB_HEADER_TEXT,
  RECEIVE_VALIDATE_REGO_RESPONSE,
  CLEAR_ABN_ERRORS,
  MY_PROFILE_UPDATED,
  MY_PROFILE_UPDATE_ERRORS,
  CLEAR_MY_PROFILE_UPDATE_ERRORS,
  RECIEVE_NOTIFICATIONS,
  IS_LOADING,
  SERVER_ERROR,
  SET_PASSWORD_ERRORS, RECEIVE_ADMIN_EMAILS,
  RECEIVE_TOGGLES, SET_SETTING_BUTTON_TEXT,
  SET_LOADING_TEXT, SET_SECONDARY_SETTING_BUTTON_TEXT, SET_DOWNLOAD_BAR,
  RECEIVE_TRACKS, SET_LINK_INVALID, IS_SEARCH_APPLIED, RECEIVE_SEASONS
} from '../../actions/main';

const initialState = {
  toggles: {},
  version: '',
  serverEnv: 'dev',
  setEnvLabel: false,
  isLoading: false,
  waitForComponent: undefined,
  waitForMilliseconds: undefined,
  forceStopLoader: false,
  seasons: [],
  user: {
    token: localStorage.getItem('token'),
    user: JSON.parse(localStorage.getItem('user')),
    errors: {}
  },
  breadcrumbs: [],
  headerText: '',
  subHeaderText: '',
  company: {},
  notifications: [],
  serverErrorCode: null,
  setPasswordErrors: [],
  loadingText: null,
  downloadBarMessage: null,
  showDownloadBar: false,
  onDownloadBarClose: null,
  tracks: [],
  marketZones: [],
  isLinkValid: true,
  isSearchApplied: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case RECEIVE_TRACKS:
    return {
      ...state,
      tracks: action.tracks
    };
  case SET_DOWNLOAD_BAR:
    return {
      ...state,
      downloadBarMessage: action.message,
      showDownloadBar: action.isOpen,
      onDownloadBarClose: action.onClose
    };
  case SET_LOADING_TEXT:
    return {
      ...state,
      loadingText: action.loadingText
    };
    case IS_LOADING:
      return { ...state, isLoading: action.isLoading, waitForComponent: action.waitForComponent, forceStopLoader: action.forceStopLoader, waitForMilliseconds: action.waitForMilliseconds };
    case SERVER_ERROR:
      return { ...state, serverErrorCode: action.serverErrorCode};
    case RECIEVE_NOTIFICATIONS:
      return { ...state, notifications: action.notifications };
    case REQUEST_VERSION:
      return { ...state };
    case RECEIVE_VERSION:
    return {
      ...state,
      version: action.version,
      serverEnv: action.serverEnv,
      setEnvLabel: action.setEnvLabel
    };
    case RECEIVE_TOGGLES:
    return {...state, toggles: action.toggles};
    case RECEIVE_MARKET_ZONES:
      return { ...state, marketZones: action.marketZones };
    case RECEIVE_BANKS:
      return { ...state, banks: action.banks };
    case UPDATE_IS_LOADING:
    return { ...state, isLoading: action.isLoading };
    case RECEIVE_ABN_RESPONSE:
      return { ...state, abn: action.abn };
    case RECEIVE_VALIDATE_REGO_RESPONSE:
      return { ...state, isRegoAvailable: action.isRegoAvailable };
    case RECEIVE_COMPANY_RESPONSE:
      return { ...state, company: action.company };
    case RECEIVE_ADMIN_EMAILS:
      return { ...state, emails: action.emails};
    case RECEIVE_LOGIN:
      return { ...state, user: { ...state.user, ...action.user } };
    case LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.clear();
      return { ...state, user: { token: null, user: null, errors: {} } };
    case SET_LINK_INVALID:
      return { ...state, isLinkValid: action.isValid };
    case CHANGE_PASSWORD_ERRORS:
      return { ...state, password: action.password };
    case SET_BREADCRUMBS:
      return { ...state, breadcrumbs: action.breadcrumbs };
    case SET_HEADER_TEXT:
      return { ...state, headerText: action.text };
    case SET_SUB_HEADER_TEXT:
      return { ...state, subHeaderText: action.text };
    case SET_SETTING_BUTTON_TEXT:
      return { ...state, settingButton: action.text };
    case SET_SECONDARY_SETTING_BUTTON_TEXT:
      return { ...state, secondarySettingButton: action.text };
    case CLEAR_ABN_ERRORS:
      return { ...state, company: {} };
    case MY_PROFILE_UPDATED: {
      const user = { ...state.user.user, ...action.fields };
      localStorage.setItem('user', JSON.stringify(user));
      return {
        ...state,
        user: { ...state.user, user, errors: {} }
      };
    }
    case MY_PROFILE_UPDATE_ERRORS:
      return {
        ...state,
        user: { ...state.user, errors: action.errors }
      };
    case CLEAR_MY_PROFILE_UPDATE_ERRORS:
      return {
        ...state,
        user: { ...state.user, errors: {} }
      };
    case SET_PASSWORD_ERRORS:
      return {
        ...state,
        setPasswordErrors: action.errors
      };
    case IS_SEARCH_APPLIED:
      return {
        ...state,
        isSearchApplied: action.flag
      };
    case RECEIVE_SEASONS:
      return {
        ...state,
        seasons: action.seasons
      };
    default:
      return state;
  }
};

export default reducer;
