import React from 'react';
import { connect } from 'react-redux';
import Paper from '@mui/material/Paper';
import {setHeaderText, setBreadcrumbs} from '../../actions/main';
import { get, isEqual } from 'lodash';
import {getHeaderTitle} from "./invoiceDetails/InvoiceDetails";
import GenericTable from '../GenericTable';
import { getCountryLabel } from '../../common/utils';

class InvoiceCanolaLoadsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canolaLoads: null,
    };
  }

  getInvoiceId() {
    return this.props.invoiceId || this.props.match.params.invoice_id;
  }

  componentDidMount() {
    this.setHeaderAndBreadcrumbs();
  }

  componentDidUpdate() {
    this.setHeaderAndBreadcrumbs();
  }

  setHeaderAndBreadcrumbs() {
    const invoiceId = this.getInvoiceId();
    let breadcrumbs = [
      {text: 'Invoice Canola Loads'},
    ];
    let headerText = 'Invoice Canola Loads';
    if(invoiceId){
      breadcrumbs = [
        {text: 'Invoices', route: '/invoices'},
        {text: get(this.props.invoice, 'identifier', ''), route: '/invoices/' + invoiceId + '/details'},
        {text: 'Invoice Canola Loads'},
      ];
      headerText = getHeaderTitle(this.props.invoice);
    }
    this.props.dispatch(setHeaderText(headerText));

    if(!isEqual(this.props.breadcrumbs, breadcrumbs)) {
      this.props.dispatch(setBreadcrumbs(breadcrumbs));
    }

  }

  render() {
    const { invoice } = this.props;
    const items = get(invoice, 'canolaLoads', []).concat(get(invoice, 'loads', []));
    const COLUMNS = [
      {header: 'Ref No.', key: 'titleTransfer', className: 'medium'},
      {header: 'Load Type', key: 'loadType', className: 'small'},
      {header: 'Load Identifier', key: 'loadIdentifier', className: 'small'},
      {header: 'Commodity', key: 'commodity', className: 'small'},
      {header: 'Grade', key: 'grade', className: 'small'},
      {header: 'Season', key: 'season', className: 'xsmall'},
      {header: 'COIL', key: 'coil', className: 'small'},
      {header: 'IMPU', key: 'impu', className: 'small'},
      {header: 'Tonnage', key: 'tonnage', className: 'medium'},
      {header: `Price (Ex ${getCountryLabel('gst')})`, key: 'subtotal', className: 'medium'},
  ];
    return (
      <Paper className="paper-table">
        <GenericTable
          columns={COLUMNS}
          items={items}
          displayIDColumn='titleTransfer'
        />
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    breadcrumbs: state.main.breadcrumbs,
    invoice: state.companies.invoices.selectedInvoice,
  };
};

export default connect(mapStateToProps)(InvoiceCanolaLoadsSection);