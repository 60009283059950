import React from 'react';

import { connect } from 'react-redux';
import APIService from '../../services/APIService';
import CommonTabs from '../common/CommonTabs';
import { setSubHeaderText, isLoading, forceStopLoader } from '../../actions/main';
import { getInvoiceDetails, setClickedOption, invoiceDetails as setInvoiceDetails } from "../../actions/companies/invoices";
import InvoiceDetails from "./invoiceDetails/InvoiceDetails";
import { isEmpty, get, find, includes } from "lodash";
import { InvoiceDetailsBasicInfo } from "./invoiceDetails/InvoiceDetailsBasicInfo";
import InvoicePayments from "./InvoicePayments";
import InvoiceLoads from "./InvoiceLoads";
import { RejectionDetails } from '../rejections/RejectionDetails';
import Notes from '../notes/Notes';
import InvoicesActions from './InvoicesActions';
import InvoiceTransfers from './InvoiceTransfers';
import InvoiceCanolaLoadsSection from './InvoiceCanolaLoadsSection';

class InvoiceHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props.location.pathname,
      xeroMode: false,
      xeroAccounts: [],
      xeroItems: [],
      xeroMappings: [],
      newView: false,
    };
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  static defaultProps = {
    invoiceDetails: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return nextProps.location.pathname !== prevState.activeTab ?
      { ...prevState, activeTab: nextProps.location.pathname } :
      prevState;
  }

  componentDidMount() {
    this.props.dispatch(isLoading('invoiceDetails'))
    this.props.dispatch(getInvoiceDetails(this.props.match.params.invoice_id));
    this.clearEntity();
  }

  componentWillUnmount() {
    this.props.dispatch(setInvoiceDetails(null));
    this.props.dispatch(setSubHeaderText(' '));
  }

  clearEntity() {
    this.props.dispatch(setClickedOption(null));
  }

  handleTabChange(value) {
    this.setState(state => ({ ...state, activeTab: value }));
  }

  componentDidUpdate() {
    const invoiceId = parseInt(this.props.match.params.invoice_id);
    if (!isEmpty(this.props.invoiceDetails) && invoiceId && get(this.props.invoiceDetails, 'id') !== invoiceId) {
      this.props.dispatch(isLoading('invoiceDetails'))
      this.props.dispatch(getInvoiceDetails(invoiceId));
    }
  }

  onCreateXeroClick = () => {
    const { party, transactionType } = this.getCurrentUserParty()
    this.fetchXeroMappings(party?.companyId, transactionType)
    this.fetchXeroAccounts(party?.companyId)
  }

  getCurrentUserParty = () => {
    const { invoiceDetails, currentUser } = this.props
    const companyId = currentUser.companyId
    if(invoiceDetails?.payee?.companyId === companyId && invoiceDetails?.isWarehouseInvoice)
      return {party: invoiceDetails?.payee, transactionType: 'warehouse'} // warehouse company
    if(invoiceDetails?.payee?.companyId === companyId && invoiceDetails?.isFreightInvoice)
      return {party: invoiceDetails?.payee, transactionType: 'freight_invoice_receivable'} // freight provider company
    if(invoiceDetails?.payer?.companyId === companyId && invoiceDetails?.isFreightInvoice)
      return {party: invoiceDetails?.payer, transactionType: 'freight_invoice_payable'} // customer company
    if(invoiceDetails?.payer?.companyId === companyId)
      return {party: invoiceDetails?.payer, transactionType: 'purchase_contract'} // buyer
    if(invoiceDetails?.payee?.companyId === companyId)
      return {party: invoiceDetails?.payee, transactionType: 'sale_contract'} // seller

    return {party: null, transactionType: null}
  }

  fetchXeroAccounts = companyId => {
    if(isEmpty(this.state.xeroAccounts) && companyId) {
      this.props.dispatch(isLoading('getXeroAccounts'))
      APIService
        .companies(companyId)
        .appendToUrl('xero/connections/fixtures/')
        .get()
        .then(response => this.setState(
          {
            xeroMode: true,
            xeroAccounts: response.accounts,
            xeroItems: response.items
          },
          () => this.props.dispatch(forceStopLoader())
        ))
    } else
      this.setState({xeroMode: true})
  }

  fetchXeroMappings = (companyId, transactionType) => {
    if(isEmpty(this.state.xeroMappings) && companyId && transactionType) {
      let applicationCommodityId = []
      if (!isEmpty(get(this.props.invoiceDetails, 'chemicalApplicationItems')))
        applicationCommodityId = this.props.invoiceDetails.chemicalApplicationItems.map(obj => obj.commodityId);
      let queryCommodityIds = [...applicationCommodityId, this.props.invoiceDetails.commodityId].join(',')
      APIService
        .companies(companyId)
        .appendToUrl('xero/mappings/')
        .get(null, null, {transaction_type: `${transactionType},deductions`, commodity_id: queryCommodityIds})
        .then(response => this.setState({xeroMappings: response}))
    }
  }


  render() {
    const PARENT_URL = this.props.match.url;
    const { invoiceDetails } = this.props;
    const { activeTab, xeroMode } = this.state;
    const { party, transactionType } = this.getCurrentUserParty()

    let tabs = [
      {
        label: 'Invoice',
        key: 'invoiceDetails',
        url: `${PARENT_URL}`,
        component: () => <InvoiceDetails {...this.props} invoiceDetails={this.props.invoiceDetails} xeroMode={xeroMode} onCreateXeroClick={this.onCreateXeroClick} xeroAccounts={this.state.xeroAccounts} xeroItems={this.state.xeroItems}  />
      },
    ];

    if (get(invoiceDetails, 'type') == 'WarehouseFee') {
      const InvoiceLoadsTab = { label: 'Invoice Loads', key: 'invoiceLoads', url: `${PARENT_URL}/loads`, component: () => <InvoiceLoads invoiceId={get(invoiceDetails, 'id')} /> };
      const InvoiceTransferTab = { label: 'Invoice Transfers', key: 'invoiceTransfers', url: `${PARENT_URL}/transfers`, component: () => <InvoiceTransfers invoiceId={get(invoiceDetails, 'id')} /> };
      tabs = tabs.concat([InvoiceLoadsTab, InvoiceTransferTab]);
    }

    const canolaLoads = (get(invoiceDetails, 'canolaLoads') || []).concat(get(invoiceDetails, 'loads') || []);
    if (canolaLoads && !isEmpty(canolaLoads)) {
      const InvoiceCanolaLoadTab = { label: 'Invoice Canola Loads', key: 'invoiceCanolaLoads', url: `${PARENT_URL}/canola`, component: () => <InvoiceCanolaLoadsSection invoiceId={get(invoiceDetails, 'id')} /> };
      tabs = tabs.concat([InvoiceCanolaLoadTab]);
    }

    const InvoicePaymentTab = { label: 'Invoice Payments', key: 'invoicePayments', url: `${PARENT_URL}/payments`, component: () => <InvoicePayments invoiceId={get(invoiceDetails, 'id')} /> };
    tabs = tabs.concat([InvoicePaymentTab]);
    tabs.push({
      label: 'Audit History',
      key: 'notes',
      url: `${PARENT_URL}/notes`,
      component: () => <Notes {...this.props} objectId={get(invoiceDetails, 'id')} objectType='invoice' companyId={this.props.companyId} />
    });

    const isInvoiceDetails = activeTab === find(tabs, { key: 'invoiceDetails' })['url']
    const canSwitchToNewView = invoiceDetails?.isCommodityContractInvoice && isInvoiceDetails

    return (
      <div className="invoice-details-container">
        <div className="tab">
          <div className="tab-header">
            <CommonTabs value={activeTab} tabs={tabs} />
          </div>
          {
            !isEmpty(invoiceDetails) &&
              <InvoiceDetailsBasicInfo
                {...this.props}
                xeroMode={xeroMode}
                onCreateXeroClick={this.onCreateXeroClick}
                currentUserParty={party}
                transactionType={transactionType}
                canSwitchToNewView={canSwitchToNewView}
                newView={this.state.newView}
                onViewChange={newValue => this.setState({newView: newValue})}
              />
          }
          <div className="tab-content">
            {!isEmpty(invoiceDetails) && activeTab === find(tabs, { key: 'invoiceDetails' })['url'] &&
              <RejectionDetails rejectionReasonObject={invoiceDetails.requestReasonDisplay} className={"invoice-details-section-container"} />
            }
            {!isEmpty(invoiceDetails) && activeTab === find(tabs, { key: 'invoiceDetails' })['url'] &&
              <RejectionDetails rejectionReasonObject={invoiceDetails.rejectionReasonDisplay} className={"invoice-details-section-container"} />
            }
            {
              !isEmpty(invoiceDetails) &&
              isInvoiceDetails &&
                <InvoiceDetails {...this.props} xeroMode={xeroMode} onCreateXeroClick={this.onCreateXeroClick} xeroAccounts={this.state.xeroAccounts} xeroItems={this.state.xeroItems} xeroMappings={this.state.xeroMappings} newView={this.state.newView} />
            }
            {
              !isEmpty(invoiceDetails) &&
              includes(this.state.activeTab, 'loads') &&
              <InvoiceLoads idontRedirect={true} invoice={invoiceDetails} invoiceId={get(invoiceDetails, 'id')} {...this.props} />
            }
            {
              !isEmpty(invoiceDetails) &&
              includes(this.state.activeTab, 'transfers') &&
              <InvoiceTransfers invoice={invoiceDetails} invoiceId={get(invoiceDetails, 'id')} {...this.props} />
            }
            {
              !isEmpty(invoiceDetails) &&
              activeTab === find(tabs, { key: 'invoicePayments' })['url'] &&
              <InvoicePayments invoice={invoiceDetails} invoiceId={get(invoiceDetails, 'id')} />
            }
            {
              !isEmpty(invoiceDetails) &&
              activeTab === find(tabs, { key: 'notes' })['url'] &&
              <Notes {...this.props} objectId={get(invoiceDetails, 'id')} objectType='invoice' companyId={this.props.companyId} />
            }
            {
              !isEmpty(invoiceDetails) &&
              includes(this.state.activeTab, 'canola') &&
              <InvoiceCanolaLoadsSection {...this.props} objectId={get(invoiceDetails, 'id')} objectType='invoice' companyId={this.props.companyId} />
            }
          </div>
          <InvoicesActions  {...this.props} selectedInvoice={invoiceDetails}
             selectedInvoiceId={get(invoiceDetails, 'id')}/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const clickedOption = state.companies.invoices.clickedOption;
  return {
    currentUser: state.main.user.user,
    invoiceDetails: state.companies.invoices.selectedInvoice,
    status: state.companies.invoices.statusDisplayName,
    showVoidDialog: state.companies.invoices.flag,
    invoiceId: state.companies.invoices.invoiceId,
    isPdfDownloadable: state.companies.invoices.isPdfDownloadable,
    flag: state.companies.invoices.flag,
    userToken: state.main.user.token,
    canAddPayment: state.companies.invoices.canAddPayment,
    canMarkComplete: state.companies.invoices.canMarkComplete,
    clickedOption
  };
};

export default connect(mapStateToProps)(InvoiceHome);
