import React from 'react';
import { connect } from 'react-redux';
import EmployeesTable from '../../containers/EmployeesTable';
import EmployeesArchivedTable from '../../containers/EmployeesArchivedTable';
import CreateEmployee from '../../containers/CreateEmployee';
import UpdateEmployee from '../../containers/UpdateEmployee';
import { getCompanyEmployees } from '../../actions/api/employees';
import {
  receiveEmployees,
  clickAddEmployeeButton,
  cancelEditEmployee,
  receiveArchivedEmployees
} from '../../actions/company-settings/employees';
import {
  showSideDrawerForTransfer, showSideDrawerForMerge
} from '../../actions/companies/index';
import TransferToOtherCompany from '../../containers/TransferToOtherCompany';
import { getCompanyDetails } from '../../actions/companies/index';

import AddButton from '../common/AddButton';
import Paper from '@mui/material/Paper';
import { setHeaderText, setBreadcrumbs } from '../../actions/main';
import { isEqual, get, cloneDeep, find } from 'lodash';
import SideDrawer from '../common/SideDrawer';
import { isCompanyEditable, isSystemCompany, isTransactionParticipated } from '../../common/utils';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import '../common/subTab.scss';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import CommonButton from '../common/CommonButton';
import APIService from '../../services/APIService';
import alertifyjs from 'alertifyjs';

class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSideDrawer: false,
      openMergeSideDrawer: false,
      value: 'active',
      mergeToEmployee: {
        value: undefined,
        validators: [],
        errors: [],
      },
      selectedMergeToEmployee: undefined
    };

    this.openSideDraw = this.openSideDraw.bind(this);
    this.closeSideDraw = this.closeSideDraw.bind(this);
    this.handleEmployeeChange = this.handleEmployeeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeMergeDrawer = this.closeMergeDrawer.bind(this);
  }

  componentDidMount() {
    this.setBreadcrumbs();
    this.props.getEmployees(this.props.companyId, receiveEmployees, '');
    this.props.cancelTransferDrawer;
    this.props.cancelMergeDrawer;
  }

  shouldFetchCompany() {
    return !this.props.selectedCompany || (this.props.selectedCompany.id !== this.props.companyId);
  }

  componentDidUpdate() {
    if (this.props.selectedCompany) {
      if (this.props.headerText !== this.props.selectedCompany.name) {
        this.props.setHeaderText(this.props.selectedCompany.name);
      }
      this.setBreadcrumbs();
    }
    if (this.shouldFetchCompany()) {
      this.props.getCompany(this.props.companyId);
      this.props.getEmployees(this.props.companyId, receiveEmployees, '');
    }
  }

  componentWillUnmount() {
    this.props.cancelTransferDrawer(null, false);
    this.props.cancelMergeDrawer(null, false);
  };

  setBreadcrumbs() {
    if (this.props.selectedCompany) {
      const breadcrumbs = [
        { text: 'Companies', route: '/companies' },
        { text: this.props.selectedCompany.name, onClick: this.props.onDetailsClick, route: '/companies/' + this.props.selectedCompany.id + '/details' },
        { text: `Employees (${this.props.count})` }
      ];
      if (!isEqual(this.props.breadcrumbs, breadcrumbs)) {
        this.props.setBreadcrumbs(breadcrumbs);
      }
    }
  }

  openSideDraw() {
    this.props.handleAddEmployeeButtonClick();
    this.setState({ openSideDrawer: true, });
  }

  closeSideDraw() {
    this.setState({ openSideDrawer: false, });
  }

  selectedCompanyRegisteredButNotSubscriber() {
    return get(this.props, 'selectedCompany.isRegistered') && !isTransactionParticipated(this.props.selectedCompany);
  }

  canAccessAny() {
    return isSystemCompany() ? isCompanyEditable(this.props.selectedCompany) : (!isTransactionParticipated(this.props.selectedCompany) || isCompanyEditable(this.props.selectedCompany));
  }

  tabChanges = (event, value) => {
    if (value == 'active')
      this.props.getEmployees(this.props.companyId, receiveEmployees, '');
    else
      this.props.getEmployees(this.props.companyId, receiveArchivedEmployees, '?archived');

    this.setState({ value });
  };

  handleEmployeeChange(value, id, item) {
    const newState = {...this.state};
    newState.mergeToEmployee.value = value;
    newState.mergeToEmployee.errors = [];
    if (item) {
      newState.selectedMergeToEmployee = item;
    }
    this.setState(newState);
  }

  handleSubmit() {
    let data = {};
    data['mergeFrom'] = get(this.props.selectedAsset, 'id');
    if (data['mergeFrom']) {
      APIService.profiles()
        .appendToUrl(`${this.state.mergeToEmployee.value}/merge/`)
        .post(data)
        .then(res => {
          if (get(res, 'alert')) alertifyjs.error(get(res, 'alert'))
          else alertifyjs.success('Employee Merged!', 3, () => window.location.reload())
        });
    }
  }

  getEmployees = () => {
    let employees = cloneDeep(this.props.companyEmployees);
    employees.splice(employees.findIndex(item => item.id === get(this.props, 'selectedAsset.id')), 1);
    return employees;
  }

  getLabel = () => {
    let fromEmployee = get(this.props, 'selectedAsset.name');
    let toEmployee = "";
    if (this.state.mergeToEmployee.value) {
      toEmployee = get(find(this.props.companyEmployees, {id: this.state.mergeToEmployee.value}), 'name');
    }
    return "Merge " + fromEmployee + " into " + toEmployee;
  }

  closeMergeDrawer () {
    const newState = {...this.state};
    newState.mergeToEmployee.value = undefined;
    this.setState(newState);
    this.props.cancelMergeDrawer();
  }

  render() {
    const { value } = this.state;
    const canAccessAny = this.canAccessAny()
    const isSystem = isSystemCompany()
    const canEditEmployee = this.canAccessAny() && !(isTransactionParticipated(get(this.props.currentUser, 'company')) && this.selectedCompanyRegisteredButNotSubscriber());
    return (
      <Paper className="paper-table">
        <div>
          {(canAccessAny && (!isSystem || value==='active')) ?
            <AddButton label="Employee" onClick={this.openSideDraw} app="officeEmployee" /> : null
          }
          <div className="subTab">
            <Tabs className="subTab-header" value={value} indicatorColor="primary" onChange={this.tabChanges}>
              <Tab value='active' label="Active Employees" className={value !== 'active' ? 'unselected-subtab' : ''} />
              <Tab value='archived' label="Archived Employees" className={value !== 'archived' ? 'unselected-subtab' : ''} />
            </Tabs>
            <div className="subTab-container">
              {
                value === 'active' && <EmployeesTable />
              }
              {
                value === 'archived' && <EmployeesArchivedTable />
              }
            </div>
          </div>
          {this.props.isCreateFormDisplayed &&
            <SideDrawer
              isOpen={this.state.openSideDrawer}
              title="Add Employee"
              onClose={this.closeSideDraw}
              app="officeEmployee"
            >
              <CreateEmployee closeDrawer={this.closeSideDraw} canAccessAny={canAccessAny} companyId={this.props.companyId} />
            </SideDrawer>
          }
          {
            canEditEmployee &&
              <SideDrawer
                isOpen={this.props.isUpdateFormDisplayed}
                title="Edit Employee"
                onClose={this.props.cancelEditEmployee}
                app="officeEmployee"
              >
                <UpdateEmployee canAccessAny={this.canAccessAny()} companyId={this.props.companyId} />
              </SideDrawer>
          }
          {
            (canAccessAny && this.props.canEdit) &&
              <SideDrawer
                isOpen={this.props.openSideDrawer}
                title="Transfer"
                onClose={this.props.cancelTransferDrawer}
                app='office'
              >
                <TransferToOtherCompany canAccessAny={this.canAccessAny()} />
              </SideDrawer>
          }
          {this.props.openMergeSideDrawer &&
          <SideDrawer
            isOpen={this.props.openMergeSideDrawer}
            title="Merge"
            onClose={this.closeMergeDrawer}
          >
            <div style={{ paddingTop: "15px"}}>
              <CommonAutoSelect
                id='mergeToEmployee'
                items={this.getEmployees()}
                label={this.getLabel()}
                value={this.state.mergeToEmployee.value}
                onChange={this.handleEmployeeChange}
                selectedItem={this.state.selectedMergeToEmployee}
              />
            </div>
            {
            <div className="col-sm-12 cardForm-action top15 padding-reset" style={{ paddingTop: "7px", textAlign: "right" }}>
              <CommonButton
                type="button"
                label="Cancel"
                default
                onClick={this.closeMergeDrawer}
                variant="contained"
              />
              <CommonButton
                type="submit"
                label="Merge"
                primary
                onClick={this.handleSubmit}
                variant="contained"
              />
            </div>
            }
          </SideDrawer>
          }
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    openSideDrawer: state.companies.companies.showTransferSideDrawer,
    openMergeSideDrawer: state.companies.companies.showMergeSideDrawer,
    isCreateFormDisplayed: state.companySettings.employees.isCreateFormDisplayed,
    currentUserCompanyId: state.main.user.user.companyId,
    currentUser: state.main.user.user,
    selectedCompany: state.companies.companies.selectedCompany,
    isUpdateFormDisplayed: state.companySettings.employees.isUpdateFormDisplayed,
    headerText: state.main.headerText,
    breadcrumbs: state.main.breadcrumbs,
    count: get(state.companySettings.employees, 'items.length', 0),
    companyEmployees: state.companySettings.employees.items,
    selectedAsset: state.companies.companies.selectedAsset
  };
};

const mapDispatchToProps = dispatch => {
  return {
    cancelTransferDrawer: () => dispatch(showSideDrawerForTransfer(false)),
    cancelMergeDrawer: () => dispatch(showSideDrawerForMerge(false)),
    getEmployees: (companyId, actionCreator, queryString) => dispatch(getEmployees(companyId, actionCreator, queryString)),
    getCompany: (companyId) => dispatch(getCompanyDetails(companyId)),
    handleAddEmployeeButtonClick: () => dispatch(clickAddEmployeeButton()),
    setHeaderText: (text) => dispatch(setHeaderText(text)),
    setBreadcrumbs: (breadcrumbs) => dispatch(setBreadcrumbs(breadcrumbs)),
    cancelEditEmployee: () => dispatch(cancelEditEmployee())
  };
};

function getEmployees(companyId, actionCreator, queryString) {
  return (dispatch) => {
    dispatch(getCompanyEmployees(companyId, actionCreator, queryString));
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Employees);
