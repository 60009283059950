import { connect } from 'react-redux';
import SpecialConditionForm from '../components/conditions/SpecialConditionForm';
import { updateSpecialCondition, cancelEditGeneralCondition, deleteSpecialCondition } from '../actions/companies/conditions';
import find from 'lodash/find';

function submit(data) {
  return (dispatch, getState) => {
    const specialConditionId = getState().companies.conditions.selectedSpecialConditionId;
    dispatch(updateSpecialCondition(specialConditionId, data));
  };
}

function handleDelete() {
  return (dispatch, getState) => {
    const specialConditionId = getState().companies.conditions.selectedSpecialConditionId;
    dispatch(deleteSpecialCondition(specialConditionId));
  }
}

const mapStateToProps = (state) => {
  return {
    selectedSpecialCondition: find(
      state.companies.conditions.specialConditions,
      { id: state.companies.conditions.selectedSpecialConditionId }
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submit: (data) => dispatch(submit(data)),
    cancelEdit: () => dispatch(cancelEditGeneralCondition()),
    handleDelete: () => dispatch(handleDelete())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpecialConditionForm);
