import React from 'react';

import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import {canCreateFreightInvoiceForOrder, getInvoicesWeb} from '../../actions/companies/invoices';
import Paper from '@mui/material/Paper';
import {setHeaderText, setBreadcrumbs, setSubHeaderText} from '../../actions/main';
import { getOrderSubHeaderText } from '../../common/utils';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import InvoicesTable from '../../containers/InvoicesTable';
import { CALL_ON_GRAIN_TYPE_ID } from '../../common/constants';

class FreightInvoices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSideDrawerForFreightContractInvoice: false,
    };
    this.onHandleAddFreightInvoiceButtonClick = this.onHandleAddFreightInvoiceButtonClick.bind(this);
  }

  handleActionsClick = () => this.props.dispatch(canCreateFreightInvoiceForOrder(this.props.orderId));

  onHandleAddFreightInvoiceButtonClick() {
    const func = this.props.onHandleAddFreightInvoiceButtonClick;
    if(func) {
      func();
    }
    else{
      window.location = '/#/freights/invoices/new';
    }
  }

  componentDidMount() {
    const orderId = this.props.orderId || this.props.match.params.orderId;
    this.props.dispatch(getInvoicesWeb(null, null, null, true, null, null, orderId));
    this.setHeaderAndBreadcrumbs();
  }

  setHeaderAndBreadcrumbs() {
    const orderId = this.getOrderId();
    let breadcrumbs = [{text: 'Invoices'}];
    let headerText = 'Invoices';
    if(orderId){
      const isCallOnGrain = get(this.props, 'order.typeId') === CALL_ON_GRAIN_TYPE_ID;
      const orderType = isCallOnGrain ? 'grain' : 'freights';
      breadcrumbs = [
        {text: 'Orders', route: `/orders/${orderType}`},
        {text: get(this.props.order, 'identifier', ''), route: '/freights/orders/' + this.props.orderId + '/order'},
        {text: 'Invoices'}
      ];
      headerText = 'Freight Order ' + get(this.props.order, 'identifier', '');
    }
    this.props.dispatch(setHeaderText(headerText));
    this.props.dispatch(setSubHeaderText(getOrderSubHeaderText(this.props.order)))

    if(!isEqual(this.props.breadcrumbs, breadcrumbs)) {
      this.props.dispatch(setBreadcrumbs(breadcrumbs));
    }
  }

  getOrderId() {
    return this.props.orderId || this.props.match.params.orderId;
  }


  render() {
    return (
      <Paper className="paper-table">
        <div>
          <Button
            variant="contained"
            primary="true"
            className={'btn-green'}
            onClick={this.handleActionsClick}
            style={{float: 'right', marginTop: '0px', marginBottom: '0px'}}
          >
            Generate Invoice
          </Button>
          <InvoicesTable order={this.props.order}/>
        </div>
      </Paper>
    );
  }
}


const mapStateToProps = state => {
  return {
    invoices: [],
    breadcrumbs: state.main.breadcrumbs,
    currentUser: state.main.user.user,
  };
};

export default connect(mapStateToProps)(FreightInvoices);
