import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setHeaderText, setBreadcrumbs, forceStopLoader, isLoading } from '../../actions/main';
import APIService from '../../services/APIService';
import { isCompanyAdminOrObserver, isCurrentUserBelongsToCompany, isSuperuser } from '../../common/utils';
import { GROWER } from '../../common/constants';
import CommonTabs from '../common/CommonTabs';
import XeroMappingsContainer from './XeroMappingsContainer';
import ExternalPortals from './ExternalPortals';


class Integrations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      company: props.company,
    }
  }

  getCompanyId = props => (props || this.props).match.params.company_id

  componentDidMount() {
    this.fetchCompany()
    this.props.dispatch(setHeaderText('Integrations'))
  }

  componentDidUpdate(prevProps) {
    if(this.getCompanyId(prevProps) !== this.getCompanyId())
      this.fetchCompany()
  }

  fetchCompany = () => {
    const companyId = this.getCompanyId()
    if(companyId && companyId !== this.state.company?.id) {
      const { dispatch } = this.props
      dispatch(isLoading('IntegrationsCompany'))
      APIService
        .companies(companyId)
        .get()
        .then(response => this.setState(
          {company: response},
          () => {
            const breadcrumbs = [
              {text: 'Companies', route: '/companies'},
              {text: this.state.company.name, route: `/companies/${companyId}/details`},
              {text: 'Integrations'}
            ]
            dispatch(setBreadcrumbs(breadcrumbs))
            dispatch(forceStopLoader())
          }
        ))
    }
  }


  render() {
    const { company } = this.state
    const PARENT_URL = this.props.match.url;
    let _PARENT_URL = PARENT_URL
    if(!_PARENT_URL.endsWith('/'))
      _PARENT_URL += '/'
    const tabs = [
      {label: 'Xero', url: PARENT_URL},
    ]
    const BHCAccountTab = {label: 'Bulk Handler Accounts', url: _PARENT_URL + 'bulk-handlers'}
    const canViewBHCAccountTab = company?.typeId === GROWER && ((isCompanyAdminOrObserver() && isCurrentUserBelongsToCompany(company.id)) || isSuperuser())
    if(canViewBHCAccountTab)
      tabs.push(BHCAccountTab)


    const isHandlerTab = this.props.location.pathname.includes('/bulk-handlers')
    return (
      <div>
        <div className='tab'>
          <div className='tab-header-simple'>
            <CommonTabs
              variant='standard'
              value={this.props.location.pathname}
              tabs={tabs}
              tabStyle={{minWidth: '200px'}}
            />
            <div className='tab-content col-xs-12'>
              {
                company?.id && !isHandlerTab &&
                  <XeroMappingsContainer company={company} />
              }
            </div>
            <div className='tab-content col-xs-12'>
              {
                company?.id && isHandlerTab && canViewBHCAccountTab &&
                  <ExternalPortals company={company} />
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect()(Integrations);
