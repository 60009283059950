import React from "react";
import { connect } from 'react-redux';
import './orderPreview.scss';
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import { getGradeName, getCurrentCountry, toPhoneFormat, getCountryLabel, isVisibleInCountry } from '../../../common/utils';
import { map } from "lodash";
import { UNIT_ABBREVIATIONS } from "../../../common/constants";

class OrderPreview extends React.Component {
  constructor(props) {
    super(props);
    this.country = getCurrentCountry()
  }

  getApplicationSummary(application) {
    const { previewFields } = this.props
    const { priceUnit } = previewFields
    let summary = ''
    if (get(application, 'commodity.displayName'))
      summary += `${get(application, 'commodity.displayName')}`
    if (application.applicationRate && get(application, 'commodity.unit')) {
      let unitAbbr = get(UNIT_ABBREVIATIONS, application.commodity.unit)
      summary += ` ${application.applicationRate} ${unitAbbr}/${priceUnit}`
    }
    if (application.gradeName)
      summary += ` on ${application.gradeName}`
    return summary
  }

  render() {
    const { previewFields } = this.props;
    const { config } = this.country;
    const isNGRVisible = isVisibleInCountry('ngr')
    const isABNVisible = isVisibleInCountry('abn')
    const ABNLabel = config.abn.label
    const { customer, customerNgr, customerContact } = previewFields.customerDetails;
    const { provider, providerContact } = previewFields.providerDetails;
    const { freightContractDetails, pickupDetails, deliveryDetails } = previewFields;
    const tonnageLabel = getCountryLabel('tonnage')
    const spreadLabel = getCountryLabel('spreadDetails')
    return (
      <div className="order-preview">
        <div className="order-header">
          <div className="agri-logo">
            {previewFields.currentUserCompanyLogo ? <img src={previewFields.currentUserCompanyLogo} alt={previewFields.currentUser.company.name} crossOrigin="anonymous"/> : <h2>{previewFields.currentUser.company.name}</h2>}
          </div>
          <div className="order-header--content">
            <div className="col-xs-12 col-sm-6 col-md-6 padding-reset">
              <h4>Freight Order</h4>
              {previewFields.contractReferenceNumber && <p className="reference"><span>Contract Number:</span> {previewFields.contractReferenceNumber}</p>}
              {previewFields.externalReferenceNumber && <p className="reference"><span>Contract Number:</span> {previewFields.externalReferenceNumber}</p>}
              <p className="reference"><span>Order Number:</span> {previewFields.identifier.toUpperCase()}</p>
              <p className="reference"><span>Date Issued:</span> {previewFields.dateIssued}</p>
            </div>
            <div className="text-right col-xs-12 col-sm-6 col-md-6 padding-reset">
              <h4>{previewFields.currentUser.company.name}</h4>
              {previewFields.currentUser.company.address.address && <p>{previewFields.currentUser.company.address.address}</p>}
              {previewFields.currentUser.email && <p><span>EMAIL: </span>{previewFields.currentUser.email}</p>}
              {previewFields.currentUser.company.mobile && <p><span>PHONE: </span>{toPhoneFormat(previewFields.currentUser.company.mobile)}</p>}
              {previewFields.currentUser.company.abn && isABNVisible && <p><span>{`${ABNLabel}: `}</span> {previewFields.currentUser.company.abn}</p>}
            </div>
          </div>
        </div>
        <div className="order-body">
          <p className="description">
          {"This document is a record of the Freight Order agreed between the below Customer and Freight Provider following discussions between the Customer’s and Freight Provider’s representatives on "}
          {previewFields.dateIssued}{". "}
          <p>
            {"For further information please contact "}
            {previewFields.currentUserCompanyName}{" on "}{toPhoneFormat(previewFields.currentUserCompanyPhone)}
          </p>
          </p>
          <div className="order-details">
            <div className="order-details__left col-xs-12 col-sm-6 col-md-6">
              <h4>CUSTOMER</h4>
              <h5>{(get(customer,'entity') === 'farm' ? get(customer,'displayName') : get(customer,'name'))}</h5>
              { isABNVisible && <p><span className="pre-label">{ABNLabel}</span> <span className="pre-content">{get(customer,'abn')}</span></p> }
              <p><span className="pre-label">Entity</span> <span className="pre-content">{get(customer,'entityName')}</span></p>
              {isNGRVisible && <p><span className="pre-label">NGR</span> <span className="pre-content">{customerNgr}</span></p>}
              <p><span className="pre-label">Contact</span> <span className="pre-content">{customerContact && customerContact.name}</span></p>
              <div className="contacts">
                <p><span className="pre-label">Phone</span> <span className="pre-content">{toPhoneFormat(get(customer,'mobile') || get(customer,'company.mobile'))}</span></p>
                <p><span className="pre-label">Email</span>
                  <span className="pre-content">{customerContact && customerContact.email} </span>
                </p>
              </div>
            </div>
            {!isEmpty(previewFields.providerDetails) && <div className="order-details__right col-xs-12 col-sm-6 col-md-6">
              <h4>FREIGHT PROVIDER</h4>
              <h5>{get(provider,'name')}</h5>
                {isABNVisible && <p><span className="pre-label">{ABNLabel}</span> <span className="pre-content">{get(provider,'abn')}</span></p> }
              <p><span className="pre-label">Entity</span> <span className="pre-content">{get(provider,'entityName')}</span></p>
              {isNGRVisible && <p><span className="pre-label">NGR</span> <span className="pre-content"></span></p>}
              <p><span className="pre-label">Contact</span> <span className="pre-content">{get(providerContact,'name')}</span></p>
              <div className="contacts">
                <p><span className="pre-label">Phone</span> <span className="pre-content">{toPhoneFormat(get(providerContact,'mobile') || get(providerContact,'phone'))}</span></p>
                <p><span className="pre-label">Email</span> <span className="pre-content">{get(providerContact,'email')}</span></p>
              </div>
            </div>}
          </div>

          <div className="order-details margintop-20">
            <div className="order-details__left col-xs-12 col-sm-6 col-md-6">
              <h5>COMMODITY DETAILS</h5>
              <p><span className="pre-label">COMMODITY</span> <span className="pre-content">{get(previewFields,'commodity.displayName')}</span></p>
              <p><span className="pre-label">VARIETY</span> <span className="pre-content">{get(previewFields,'variety.name')}</span></p>
              <p><span className="pre-label">GRADE</span> <span className="pre-content">
                {previewFields.showSpreadGradesSection ? previewFields.gradeName : (previewFields.isPoolContract ? get(previewFields,'poolGrades') : getGradeName(previewFields)) }
              </span></p>
              {
                previewFields.showSpreadGradesSection &&
                <p>
                  <span className="pre-label">
                    {spreadLabel}
                  </span>
                  <span className="pre-content">
                    {
                      map(previewFields.spread, (grade, index) => <div key={index}><span className="spread-section" key={index} style={{fontWeight: 'normal'}}>{grade.gist}</span></div>)
                    }
                  </span>
                </p>
              }
              {
                previewFields.chemicalApplications?.length > 0 &&
                  <p>
                    <span className="pre-label">
                      Applications
                    </span>
                    <span className="pre-content">
                      {
                        map(previewFields.chemicalApplications, (application, index) => {
                          return <div key={index}><span className="spread-section" key={index} style={{fontWeight: 'normal'}}>{this.getApplicationSummary(application)}</span></div>
                        })
                      }
                    </span>
                  </p>
               }
              {previewFields.quantity && <p><span className="pre-label">{previewFields.quantityLabel}</span> <span className="pre-content">{get(previewFields, 'quantity')} {get(previewFields, 'quantityUnit')}</span></p>}
              <p><span className="pre-label">{tonnageLabel.toUpperCase()}</span> <span className="pre-content">{get(previewFields,'plannedTonnage')} {previewFields.quantity ? previewFields?.commodity?.tonnageUnit : previewFields.priceUnit}</span></p>
              <p><span className="pre-label">SEASON</span> <span className="pre-content">{get(previewFields,'season')}</span></p>
              {get(previewFields,'tolerance') &&
                <p><span className="pre-label">TOLERANCE</span> <span className="pre-content">{get(previewFields,'tolerance')}</span></p>
              }
            </div>
            {
              !isEmpty(freightContractDetails) &&
              <div className="order-details__right col-xs-12 col-sm-6 col-md-6">
                <h5>INVOICING DETAILS</h5>
                {freightContractDetails && Object.keys(freightContractDetails).map(function (key, index) {
                   return <p key={index}><span className="pre-label">{key}</span> <span
                                                                                    className="pre-content">{freightContractDetails[key]}</span></p>;
                 })}
              </div>
            }
          </div>
          <div className="order-details margintop-20">
            <div className="order-details__left col-xs-12 col-sm-6 col-md-6">
              <h5>PICKUP DETAILS</h5>
              {pickupDetails && Object.keys(pickupDetails).map(function(key, index) {
                 return <p key={index}><span className="pre-label" style={{ 'paddingRight': '5px' }}>{key}</span> <span className="pre-content">{pickupDetails[key]}</span></p>;
               })}
            </div>
            <div className="order-details__right col-xs-12 col-sm-6 col-md-6">
              <h5>DELIVERY DETAILS</h5>
              {deliveryDetails && Object.keys(deliveryDetails).map(function(key, index) {
                 return <p key={index}><span className="pre-label" style={{ 'paddingRight': '5px' }}>{key}</span> <span className="pre-content">{deliveryDetails[key]}</span></p>;
               })}
            </div>
          </div>
          {
            previewFields && previewFields.note && filter(Object.keys(previewFields.note), i => !isEmpty(previewFields.note[i])).length !== 0 &&
            <div className="order-details margintop-20">
              <div className="order-details__left col-xs-12 col-sm-6 col-md-6">
                <h5>Notes</h5>
                {
                  filter(Object.keys(previewFields.note), i => !isEmpty(previewFields.note[i])).map(function(key, index) {
                    if (key != 'attachments')
                      return(<p key={index}>
                          <span className="pre-label">Description: </span>
                          <span className="pre-content">{previewFields.note[key]}</span>
                        </p>);
                    return (<p key={index}>
                      <span className="pre-label">Attachment: </span>
                      <span className="pre-content">{previewFields.note[key][0]['name']}</span>
                  </p>);
                })
              }
              </div>
            </div>
          }
        </div>

        {previewFields.generalConditions && <div className="commodity-details order-conditions">
          <h4>Freight Order Conditions</h4>
          <p>{previewFields.generalConditions}</p>
        </div>}
        {previewFields.specialConditions && <div className="commodity-details order-conditions">
          <h4>Special Conditions</h4>
          <p>{previewFields.specialConditions}</p>
        </div>}
      </div>
    );
  }
}

export default connect()(OrderPreview);
