import React, { useState } from 'react';

import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse';

import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'

import isArray from 'lodash/isArray'
import APIService from '../../services/APIService'
import SideDrawer from './SideDrawer';
import LoaderInline from '../LoaderInline'
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Paper } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { isEmpty } from 'lodash';


const LoadLogDrawer = () => {
  const [open, setOpen] = React.useState(false)
  const [entityId, setEntityId] = React.useState('')
  const [events, setEvents] = React.useState(false)
  const [badResponse, setBadResponse] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const toggle = () => setOpen(!open)

  const listenKey = event => {
    const isCtrlO = event.keyCode === 76 && event.ctrlKey;

    if (!isCtrlO)
      return;
    toggle()
  };

  React.useEffect(() => {
    document.body.addEventListener("keydown", listenKey)
    return () => document.body.removeEventListener("keydown", listenKey)
  }, [])

  const fetchEvents = () => {
    if (entityId) {
      setIsLoading(true)
      setBadResponse(false)
      setEvents(false)
      APIService.loads().appendToUrl('identifier/' + entityId + '/logs/').get().then(response => {
        if (isArray(response) && !isEmpty(response)) {
          setEvents(response)
        } else {
          setBadResponse(response)
        }
        setIsLoading(false)
      })
    }
  }

  const onSubmit = event => {
    event.preventDefault()
    event.stopPropagation()
    fetchEvents()
  }

  const [expandedRows, setExpandedRows] = useState({});
  const [expandedSections, setExpandedSections] = useState({});

  const toggleRowExpand = (id) => {
    setExpandedRows((prev) => {
      const isExpanding = !prev[id];
      if (!isExpanding) {
        setExpandedSections((prevSections) => {
          const newSections = { ...prevSections };
          delete newSections[id];
          return newSections;
        });
      }
      return { ...prev, [id]: isExpanding };
    });
  };

  const toggleSectionExpand = (id, section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [id]: { ...prev[id], [section]: !prev[id]?.[section] },
    }));
  };

  return (
    <SideDrawer isOpen={open} title='Load Log Panel' size='xlarge' onClose={toggle}>
      <div className='col-xs-12 padding-reset' style={{ marginTop: '16px', display: 'flex', alignItems: 'enter' }} id='load-log-panel'>
        <TextField
          sx={{ width: '200px', marginLeft: '16px' }}
          size='small'
          label="Load Identifier"
          value={entityId}
          onChange={event => setEntityId(event.target.value || undefined)}
        />
        <Button sx={{ marginLeft: '16px' }} disabled={!entityId || isLoading} onClick={onSubmit}>
          Submit
        </Button>
      </div>
      <div className='col-xs-12 padding-reset' style={{ marginTop: '8px' }}>
        {isArray(events) ?
          (<TableContainer component={Paper}>
            <Table sx={{ tableLayout: "fixed", width: "100%" }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                  <TableCell />
                  <TableCell><strong>Load Log ID</strong></TableCell>
                  <TableCell><strong>Source</strong></TableCell>
                  <TableCell><strong>Created At</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events.map((log) => (
                  <React.Fragment key={log.id}>
                    <TableRow>
                      <TableCell sx={{ width: "50px" }}>
                        <IconButton onClick={() => toggleRowExpand(log.id)}>
                          {expandedRows[log.id] ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                      </TableCell>

                      <TableCell sx={{ whiteSpace: "nowrap", width: "150px" }}>{log.id}</TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap", width: "200px" }}>{log.source}</TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap", width: "250px" }}>
                        {new Date(log.createdAt).toLocaleString()}
                      </TableCell>
                    </TableRow>

                    {expandedRows[log.id] && (
                      <TableRow>
                        <TableCell colSpan={4} sx={{ padding: 0, borderBottom: "none" }}>
                          <Collapse in={true} timeout="auto">
                            <div style={{ padding: "10px", background: "#fafafa", borderRadius: "5px" }}>
                              {[
                                { title: "Request JSON", data: log.requestJson },
                                { title: "Response JSON", data: log.responseJson },
                                { title: "Request Headers", data: log.requestHeaders }
                              ].map((section, index) => (
                                <Accordion
                                  key={index}
                                  expanded={expandedSections[log.id]?.[section.title] || false}
                                  onChange={() => toggleSectionExpand(log.id, section.title)}
                                  sx={{
                                    marginBottom: "5px",
                                    boxShadow: "none",
                                    border: "1px solid #ddd",
                                    borderRadius: "5px"
                                  }}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    sx={{
                                      background: "#f5f5f5",
                                      fontWeight: "bold",
                                      borderBottom: "1px solid #ddd"
                                    }}
                                  >
                                    {section.title}
                                  </AccordionSummary>
                                  <AccordionDetails
                                    sx={{
                                      background: "#fff",
                                      borderRadius: "0 0 5px 5px",
                                      padding: "10px",
                                      fontSize: "14px",
                                      whiteSpace: "pre-wrap"
                                    }}
                                  >
                                    <pre>{JSON.stringify(section.data, null, 2)}</pre>
                                  </AccordionDetails>
                                </Accordion>
                              ))}

                            </div>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>) :
          (
            (isLoading && events === false) ?
              <LoaderInline containerClassName="inline-loader-container" /> :
              (
                badResponse !== false &&
                <p style={{ paddingLeft: '8px' }}>
                  Response: {JSON.stringify(badResponse, undefined, 2)}
                </p>
              )
          )
        }
      </div>
    </SideDrawer>
  )
}

export default LoadLogDrawer;
