import React, { useEffect } from 'react';
import { Tooltip, Dialog, DialogContent, Button, DialogActions, FormLabel, FormGroup, FormControl, FormControlLabel} from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import { useState } from 'react';
import CommonMultiSelect from '../common/autocomplete/CommonMultiSelect';
import {map, remove, uniqBy, isEqual, filter, includes, isEmpty, get} from 'lodash';
import APIService from '../../services/APIService';
import { useDispatch, useSelector } from 'react-redux';
import {setDownloadBar } from '../../actions/main';
import {currentUser, currentUserCompany, getSeasons, getCurrentCountry, isSystemCompany} from "../../common/utils";
import CommonDatePicker from '../common/CommonDatePicker';
import moment from 'moment';
import CommonListingButton from '../common/CommonListingButton';
import alertifyjs from 'alertifyjs';
import Checkbox from "@mui/material/Checkbox";
import CustomHeaderOptions from '../common/CustomHeaderOptions';
import SideDrawer from '../common/SideDrawer';
import { getCommodities } from '../../actions/api/commodities';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import CommonTextField from '../common/CommonTextField';
import AlertForm from '../alerts/AlertForm';
import { ACQUISITION_REPORT_ALERT_CODE } from '../alerts/constants';

const OwnershipStockCSV = props =>{
  const [allLocations, setAllLocations] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [allSeason, setSeason] = useState([]);
  const [allGrade, setGrade] = useState([]);
  const [loadsCSV, setLoadsCSV] = useState(false);
  const [stocksOverviewReport, setStocksOverviewReport] = useState(false);
  const [regradeReseasonReport, setRegradeReseasonReport] = useState(false);
  const [acquisitionFile, setAcquisitionFile] = useState(false);
  const [acquisitionRecipientsDialog, setAcquisitionRecipientsDialog] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState(null);
  const uploadForm = React.createRef();
  const uploadAcquisitionForm = React.createRef();
  const dispatch = useDispatch();

  const [combinedStates, setCombinedStates] = useState({
    'farm_id__in': [],
    'ngr__company_id__in': [],
    'grade__id__in': [],
    'season__in': [],
    'type__in': [],
    'date_time__lte': '',
    'date_time__gte': '',
    'commodity_id__in': '',
    'ngr_id__in': [],
  });
  const [regradeReseasonStates, setRegradeReseasonStates] = useState({
    'farm_id': '',
    'commodity_id__in': '',
    'ngr__company_id__in': [],
    'old_grade__id__in': [],
    'new_grade__id__in': [],
    'old_season__in': [],
    'new_season__in': [],
    'date_time__lte': '',
    'date_time__gte': '',
    'ngr_id__in': [],
  });
  let [customColumns, setCustomColumns] = useState(true);
  let [negativeOutloads, setNegativeOutloads] = useState(false);
  let [customHeaderOptions, setCustomHeaderOptions] = useState(false);
  let [customColumnNames, setCustomColumnNames] = useState({});
  let [csvType, setCsvType] = useState();
  let [allNgrs, setNgrs] = useState([]);
  let [groupCommodityDetailsTogether, setGroupCommodityDetailsTogether] = useState(false);
  let [groupGrades, setGroupGrades] = useState(false);
  let [groupSeasons, setGroupSeasons] = useState(false);
  let [emptyFarmError, setEmptyFarmError] = useState();
  let [customColumnTitle, setCustomColumnTitle] = useState(undefined);
  let [includeMyStocks, setIncludeMyStocks] = useState(false);

  let CUSTOM_HEADER_EDIT = {
    fontSize: '12px',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginLeft: '10px'
  };

  const getSeason = () => setSeason(map(getSeasons(), season => ({id: season, name: season})))

  const getAllCompanies = async () => {
    const user = currentUser();
    let companies = await APIService.companies().appendToUrl(`directory/names/?excludeGroups=true`).get(props.token);
    companies = uniqBy([...companies, { id: user.companyId, name: user.company.name }], 'id');
    setAllCompanies(companies);
  };

  const getAllGrade = async() => {
    const grades = await APIService.commodities().appendToUrl(`grades/all/`).get(props.token, null, {countryId: getCurrentCountry().id});
    setGrade(grades);
  };

  const handleStocksOverviewReport = () => {
    let combinedStatesCopy = Object.assign({}, combinedStates);
    if(combinedStatesCopy.date_time__lte){
      let lte_time = `${combinedStatesCopy.date_time__lte} 23:59:59`;
      lte_time = moment.tz(lte_time, moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm:ss');
      combinedStatesCopy.date_time__lte = lte_time;
    }
    if (combinedStatesCopy.date_time__gte) {
      let gte_time = `${combinedStatesCopy.date_time__gte} 00:00:00`;
      gte_time = moment.tz(gte_time, moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm:ss');
      combinedStatesCopy.date_time__gte = gte_time;
    }
    if (groupCommodityDetailsTogether)
      combinedStatesCopy['group_commodity_details_together'] = groupCommodityDetailsTogether;
    if (groupGrades)
      combinedStatesCopy['group_grade_details_together'] = groupGrades;
    if (groupSeasons)
      combinedStatesCopy['group_season_details_together'] = groupSeasons;

    dispatch(setDownloadBar('Your Stocks Overview Report is being prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true));

    combinedStates.include_my_stocks = includeMyStocks
    if (includeMyStocks && !isEmpty(combinedStatesCopy['ngr__company_id__in'])) {
      let user = currentUser();
      combinedStatesCopy['ngr__company_id__in'].push(get(user, 'companyId'));
    }

    APIService.farms().appendToUrl('web/stocks-overview-csv/').put(combinedStatesCopy);

    props.toggleDialog();
  }

  const handleStocksCSV = () => {
    let combinedStatesCopy = Object.assign({}, combinedStates);
    if(combinedStatesCopy.date_time__lte){
      let lte_time = `${combinedStatesCopy.date_time__lte} 23:59:59`;
      lte_time = moment.tz(lte_time, moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm:ss');
      combinedStatesCopy.date_time__lte = lte_time;
    }
    if (combinedStatesCopy.date_time__gte) {
      let gte_time = `${combinedStatesCopy.date_time__gte} 00:00:00`;
      gte_time = moment.tz(gte_time, moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm:ss');
      combinedStatesCopy.date_time__gte = gte_time;
    }
    if (groupCommodityDetailsTogether && !loadsCSV)
      combinedStatesCopy['group_commodity_details_together'] = groupCommodityDetailsTogether;
    combinedStatesCopy.custom_csv = customColumns;
    if(loadsCSV)
      combinedStatesCopy.negative_outloads = negativeOutloads;

    dispatch(setDownloadBar('Your Stocks CSV is being prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true));

    let queryParams = {};
    if(props.isThirdPartyStocks && !includeMyStocks) {
      queryParams = {my_stocks: false};
    }
    if(props.isMyStocks)
      queryParams = {my_stocks: true};
    if (includeMyStocks && !isEmpty(combinedStatesCopy['ngr__company_id__in'])) {
      let user = currentUser();
      combinedStatesCopy['ngr__company_id__in'].push(get(user, 'companyId'));
    }

    const service = APIService.farms().appendToUrl('web/');
    if(loadsCSV)
      service.appendToUrl('stocks-loads-csv/');
    else
      service.appendToUrl('stocks-csv/');

    service.put(combinedStatesCopy, props.token, null, queryParams);

    props.toggleDialog();
  };

  const handleRegradeReseasonReport = () => {
    if(regradeReseasonStates.farm_id) {
      setEmptyFarmError("");
      let regradeReseasonStatesCopy = Object.assign({}, regradeReseasonStates);
      if(regradeReseasonStatesCopy.date_time__gte){
        let gte_time = `${regradeReseasonStatesCopy.date_time__gte} 00:00:00`;
        gte_time = moment.tz(gte_time, moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm:ss');
        regradeReseasonStatesCopy.date_time__gte = gte_time;
      }
      if(regradeReseasonStatesCopy.date_time__lte){
        let lte_time = `${regradeReseasonStatesCopy.date_time__lte} 23:59:59`;
        lte_time = moment.tz(lte_time, moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm:ss');
        regradeReseasonStatesCopy.date_time__lte = lte_time;
      }
      dispatch(setDownloadBar('Your Regrade/Reseason Report CSV is being prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true));
      let queryParams = {};
      if(props.isThirdPartyStocks)
        queryParams = {my_farms: true, my_stocks: false};
      if(props.isMyStocks)
        queryParams = {my_stocks: true};
      const service = APIService.farms().appendToUrl('web/regrade-report/');
      service.put(regradeReseasonStatesCopy, props.token, null, queryParams);
      props.toggleDialog();
    }
    else {
      setEmptyFarmError("This field is required");
    }
  };

  useEffect(() => {
    if (!isEqual([props.selectedOwner], combinedStates.ngr__company_id__in) || !isEqual(combinedStates.farm_id__in, [props.farm_id])) {
      setCombinedStates({ ...combinedStates, 'farm_id__in': [props.farm_id], 'ngr__company_id__in': props.selectedOwner ? [props.selectedOwner] : [] });
      if (props.selectedOwner) {
        fetchNgrs([{id: props.selectedOwner}]);
      }
    }
  }, [props.farm_id, props.selectedOwner]);

  useEffect(() => {
    if (!isEqual([props.selectedOwner], regradeReseasonStates.ngr__company_id__in) || !isEqual(props.farm_id, regradeReseasonStates.farm_id)) {
      setRegradeReseasonStates({ ...regradeReseasonStates, 'ngr__company_id__in': props.selectedOwner ? [props.selectedOwner] : [], 'farm_id': props.farm_id != 'all' ? props.farm_id : ''});
    }
  }, [props.farm_id, props.selectedOwner]);

  useEffect(() => {
    if(props.isOpen) {
      getAllCompanies();
      getSeason();
      getAllGrade();
      getAllCommodities();
      if (!isEqual(props.filteredCommodities, combinedStates.commodity_id__in) || !isEqual(props.filteredGrades, combinedStates.grade__id__in) || !isEqual(props.filteredSeasons, combinedStates.season__in)) {
        setCombinedStates({ ...combinedStates, 'commodity_id__in': props.filteredCommodities, 'grade__id__in': props.filteredGrades, 'season__in': props.filteredSeasons });
      }
      if (props.isThirdPartyStocks && (!isEqual(props.filteredCommodities, regradeReseasonStates.commodity_id__in) || !isEqual(props.filteredGrades, regradeReseasonStates.old_grade__id__in) || !isEqual(props.filteredSeasons, regradeReseasonStates.old_season__in))) {
        setRegradeReseasonStates({ ...regradeReseasonStates, 'commodity_id__in': props.filteredCommodities, 'old_grade__id__in': props.filteredGrades, 'old_season__in': props.filteredSeasons });
      }
      else if (props.isMyStocks) {
        setRegradeReseasonStates({ ...regradeReseasonStates, 'commodity_id__in': '', 'old_grade__id__in': [], 'old_season__in': [] });
      }
    }
  }, [props.isOpen]);


  useEffect(() =>{
    if (props.locations) {
      let { locations } = {...props};
      let copyLocations = locations.slice();
      remove(copyLocations, val => val.id ==='all');
      copyLocations = filter(copyLocations, location => location?.isActive == true);
      setAllLocations(copyLocations);
    }
  }, [combinedStates.farm_id__in, allCompanies, props.locations]);

  const handleFileChosen = event => {
    const file = event.target.files[0];
    event.target.value = '';
    const formData = new FormData();
    formData.append('file', file);
    dispatch(setDownloadBar('Your Stocks CSV is being prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true));
    APIService.farms().appendToUrl('stocks-csv/').request(
      'POST', formData, null,
    ).catch(() => alertifyjs.error("Failed - Incorrect CSV format"));
  };

  const handleAcquisitionFileChosen = event => {
    const file = event.target.files[0];
    event.target.value = '';
    const formData = new FormData();
    formData.append('file', file);
    dispatch(setDownloadBar('Your Acquisition File CSV is being prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true));
    APIService.farms().appendToUrl('acquisition/csv/').post(
      formData, null,
    ).then(response => {
      let reasons = get(response, 'reasons')
      if (reasons != null && reasons.length > 0) {
        reasons = '<li>' + reasons.join('</li><li>');
        alertifyjs.alert(
          'Error While Uploading CSV',
          `<div id="complete-dialog-open" className=""><p>Acquisition File cannot be created because:</p><ul>${reasons}</ul><div>Please download the CSV template again and reupload it with the data under all the given headers.</div></div>`,
          () => {},
        );
      }
    })
    .catch(() => alertifyjs.error("Failed - Incorrect CSV format"));
  };

  const onLoadsCSVClick = () => {
    setLoadsCSV(true);
    setStocksOverviewReport(false)
    setRegradeReseasonReport(false);
    setAcquisitionFile(false);
    if(props.isThirdPartyStocks)
      setCsvType("third_party_loads_csv");
    if(props.isMyStocks)
      setCsvType("stocks_loads_csv");
    props.toggleDialog();
  };

  const regradeReseasonReportClick = () => {
    setLoadsCSV(false);
    setStocksOverviewReport(false)
    setAcquisitionFile(false);
    setRegradeReseasonReport(true);
    props.toggleDialog();
  };

  const onStocksCSVClick = () => {
    setLoadsCSV(false);
    setStocksOverviewReport(false)
    setRegradeReseasonReport(false);
    setAcquisitionFile(false);
    if(props.isThirdPartyStocks)
      setCsvType("third_party_stock_csv");
    if(props.isMyStocks)
      setCsvType("stock_csv");
    props.toggleDialog();
  };

  const onDownloadTemplateClick = () => location.href = 'https://agrichain-api-production.s3.ap-southeast-2.amazonaws.com/assets/Stocks+Upload+Template.csv';

  const onDownloadAcquisitionTemplateClick = () => {
    if(isSystemCompany())
      location.href = 'https://agrichain-api-production.s3.ap-southeast-2.amazonaws.com/assets/SuperAdminMovementsTransfersUploadTemplate.csv'
    else
      location.href = 'https://agrichain-api-production.s3.ap-southeast-2.amazonaws.com/assets/MovementsTransfersUploadTemplate.csv';
  }

  let onUploadAcquisitionFileCsvClick = () => {
    if (get(props.user.company, 'acquisitionFileUpload') || isSystemCompany())
      uploadAcquisitionForm.current.click()
    else {
      alertifyjs.alert(
        'Permission Denied',
        `<div><p>Uploading Acquisition is a premium feature. Please contact AgriChain support <a href="mailto:support@agrichain.com" target='_blank' rel="noopener noreferer">support@agrichain.com</a>  for more details.</p></div>`,
        () => {}
      );
    }
  }

  let editCustomHeaderOptions = async () => {
    if (csvType) {
      let columnNames = await APIService.profiles().appendToUrl(`${props.user.id}/report-preferences/${csvType}/`).get(props.token);
      setCustomColumnNames(columnNames);
      setCustomHeaderOptions(true);
    }
  };

  const enableCustomCsv = () => props.user.company.enableCustomCsv && !stocksOverviewReport;

  const getGrades = () => filter(allGrade, grade => includes(combinedStates.commodity_id__in, grade.commodityId))

  const getGradesForRegradedStocks = () => filter(allGrade, grade => includes(regradeReseasonStates.commodity_id__in, grade.commodityId))

  let fetchNgrs = async (selectedItems) => {
    if (!isEmpty(selectedItems)) {
      const companyQueryString = map(selectedItems, obj => `company_ids=${obj.id}`).join('&');
      const ngrs = await APIService.ngrs().appendToUrl(`?${companyQueryString}`).get();
      setNgrs(ngrs);
    }
    else setNgrs([]);
  }

  let handleCompanyChange = async (id, selectedItems) => {
    setCombinedStates({ ...combinedStates, 'ngr__company_id__in': map(selectedItems, 'id'), 'ngr_id__in': [], });
    await fetchNgrs(selectedItems);
  }

  let handleCompanyChangeForRegradeReseason = async (id, selectedItems) => {
    setRegradeReseasonStates({ ...regradeReseasonStates, 'ngr__company_id__in': map(selectedItems, 'id'), 'ngr_id__in': [] });
    if (!isEmpty(selectedItems)) {
      const companyQueryString = map(selectedItems, obj => `company_ids=${obj.id}`).join('&');
      const ngrs = await APIService.ngrs().appendToUrl(`?${companyQueryString}`).get();
      setNgrs(ngrs);
    }
    else setNgrs([]);
  };

  let allStocks = useSelector(state => state.master.commodities.items);

  let getAllCommodities = () => dispatch(getCommodities());

  const handleFarmSiteChange = id => {
    setEmptyFarmError("");
    setRegradeReseasonStates({ ...regradeReseasonStates, 'farm_id': id || '' })
  }

  let fetchAcquisitionFile = callback => {
    setCombinedStates({ ...combinedStates, 'type__in': ['inload'] })
    setLoadsCSV(false);
    setStocksOverviewReport(false)
    setRegradeReseasonReport(false);
    setAcquisitionFile(true);
    if(props.isThirdPartyStocks)
      setCsvType("third_party_acquisition_csv");
    if(props.isMyStocks)
      setCsvType("stock_acquisition_csv");
    props.toggleDialog();
    if (callback) {
      callback();
    }
  }

  let autoAcquisitionFile = () => {
    const user = currentUser();
    APIService.alerts().get(null, null, {company_id: user.companyId, alert_name:ACQUISITION_REPORT_ALERT_CODE}).then(response => {
      if (response?.id)
        setSelectedAlert(response)
      setAcquisitionRecipientsDialog(true);
    })
  }

  let dialogTitle = () => {
    if(loadsCSV)
      return 'Download Inload/Outload Data'
    if(regradeReseasonReport)
      return 'Regrade/Reseason Report'
    if(acquisitionFile)
      return 'Download Acquisition File'
    if(acquisitionRecipientsDialog)
      return 'Auto Acquisition File'
    if(stocksOverviewReport)
      return 'Stocks Overview Report'
    return 'Download Stocks Data'
  }

  let handleAcquisitionFileExport = () => {
    let combinedStatesCopy = Object.assign({}, combinedStates);
    if(combinedStatesCopy.date_time__lte){
      let lte_time = `${combinedStatesCopy.date_time__lte} 23:59:59`;
      lte_time = moment.tz(lte_time, moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm:ss');
      combinedStatesCopy.date_time__lte = lte_time;
    }
    if(combinedStatesCopy.date_time__gte){
      let gte_time = `${combinedStatesCopy.date_time__gte} 00:00:00`;
      gte_time = moment.tz(gte_time, moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm:ss');
      combinedStatesCopy.date_time__gte = gte_time;
    }
    if (groupCommodityDetailsTogether && !loadsCSV)
      combinedStatesCopy['group_commodity_details_together'] = groupCommodityDetailsTogether;
    combinedStatesCopy.custom_csv = customColumns;

    dispatch(setDownloadBar('Your Stocks CSV is being prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true));

    let queryParams = {};
    if(props.isThirdPartyStocks)
      queryParams = {my_farms: true, my_stocks: false};
    if(props.isMyStocks)
      queryParams = {my_stocks: true};

    const service = APIService.farms().appendToUrl('web/stocks-loads-csv/?acquisition_csv=true');
    service.put(combinedStatesCopy, props.token, null, queryParams);

    props.toggleDialog();
  }

  let onClickMethod = () => {
    if(regradeReseasonReport)
      return handleRegradeReseasonReport
    if(acquisitionFile)
      return handleAcquisitionFileExport
    if(stocksOverviewReport)
      return handleStocksOverviewReport
    return handleStocksCSV
  }

  let updateColumnCount = count => {
    setCustomColumnTitle(`Edit Columns (${count})`);
  }

  const onStocksOverviewReportClick = () => {
    setStocksOverviewReport(true)
    setLoadsCSV(false)
    setRegradeReseasonReport(false)
    setAcquisitionFile(false)
    props.toggleDialog();
  }

  let getOptionMapperListItems = () => {
    let items = [
      { name: 'Download Stock CSV', fx: onStocksCSVClick },
      { name: 'Inload/Outload Data', fx: onLoadsCSVClick },
      { name: 'Regrade/Reseason Report', fx: regradeReseasonReportClick },
    ]
    if (props.isThirdPartyStocks) {
      items = [...items, { name: 'Upload Stocks', fx: () => uploadForm.current.click()},
      { name: 'Download Stocks Template', fx: onDownloadTemplateClick }]
    }
    items = [...items, {name: 'Download Acquisition File', fx: callback => fetchAcquisitionFile(callback)}]
    if (!props.isThirdPartyStocks) {
      items= [...items, {name: 'Auto Acquisition File', fx: autoAcquisitionFile}]
    }
    if(props.isMyStocks && get(getCurrentCountry()?.config, 'acquisitionFile')) {
      items = [...items, {name: 'Download Movements/Transfers Template', fx: () => onDownloadAcquisitionTemplateClick()},
      {name: 'Upload Movements/Transfers', fx: onUploadAcquisitionFileCsvClick}]
    }
    if(!props.isMyStocks)
      items = [...items, {name: 'Stocks Overview Report', fx: () => onStocksOverviewReportClick()}]
    return items;
  }


  let closeAcquisitionDialog = () => {
    setAcquisitionRecipientsDialog(false);
    setSelectedAlert(null);
  }

  return (
    <span style={{display: 'inline-block'}}>
      <CommonListingButton
        defaultHandler={props.toggleDialog}
        showMenus
        optionMapper={getOptionMapperListItems()}
        title='Download Contents of the table in a CSV'
        name='CSV'
        style={{}}
        variant="outlined"
        size="small"
      />
      <input ref={uploadForm} name="upload" id="upload" type="file" accept=".csv" style={{ visibility: "hidden", display: 'none' }} onChange={handleFileChosen} />
      <input ref={uploadAcquisitionForm} name="uploadAcquisition" id="uploadAcquisition" type="file" accept=".csv" style={{ visibility: "hidden", display: 'none' }} onChange={handleAcquisitionFileChosen} />
      {
        acquisitionRecipientsDialog &&
        <AlertForm alert={selectedAlert} companyId={currentUserCompany()?.id} open={acquisitionRecipientsDialog} onClose={closeAcquisitionDialog}/>
      }
      {
        props.isOpen &&
      <Dialog open onClose={props.toggleDialog} aria-labelledby='form-dialog-title' fullWidth>
        <DialogTitleWithCloseIcon onClose={props.toggleDialog} style={{display: 'inline-flex', alignItems: 'center'}} closeButtonStyle={{marginTop: '0px'}} id='form-dialog-title'>
          {dialogTitle()}
          {
            stocksOverviewReport &&
              <Tooltip arrow title='Download an overview of non-grower stocks at your sites. This includes all Movements and Title Transfers from and to growers and stock swaps and Title Transferss for non growers as well as stock carried over from previous period, shrink, and regrades'>
                <InfoIcon style={{marginLeft: '10px'}} color='default' />
              </Tooltip>
          }
        </DialogTitleWithCloseIcon>
        <DialogContent>
          { !regradeReseasonReport &&
          <div className="col-sm-12 padding-reset">
            <CommonMultiSelect
              id="farm_id"
              items={allLocations}
              selectedItems={combinedStates.farm_id__in}
              displayField="name"
              onChange={(id, selectedItems) => setCombinedStates({...combinedStates, 'farm_id__in': map(selectedItems, 'id')})}
              placeholder="Select Location(s)..."
              label="Location(s)"
              selectAll
              clearAll
              containerStyle={{marginTop: '0px'}}
            />
          </div>
          }
          {regradeReseasonReport &&
          <div className="col-sm-12 padding-reset" style={{ marginTop: '20px' }}>
            <CommonAutoSelect
              id="farm_id"
              fullWidth
              onChange={handleFarmSiteChange}
              label="Farm/Site"
              items={allLocations}
              errorText={emptyFarmError}
              value={regradeReseasonStates.farm_id}
              getOptionLabel={option => get(option, 'displayName')}
            />
          </div>
          }
          { !regradeReseasonReport &&
          <div className="col-sm-12 padding-reset">
            <CommonMultiSelect
              id="ngr__company_id__in"
              items={allCompanies}
              selectedItems={combinedStates.ngr__company_id__in}
              displayField="name"
              onChange={handleCompanyChange}
              placeholder="Select Owner(s)..."
              label="Stock Owners"
              selectAll
              clearAll
            />
          </div>
          }
          {props.isThirdPartyStocks && !loadsCSV && !regradeReseasonReport && !acquisitionFile &&
            <div style={{paddingLeft: '0px'}} className="col-sm-12 padding-reset">
              <Checkbox checked={includeMyStocks} onChange={() => setIncludeMyStocks(!includeMyStocks)}/> Include My Stocks
            </div>
          }
          {!regradeReseasonReport && !stocksOverviewReport &&
          <div className="col-sm-12 padding-reset">
            <CommonMultiSelect
              id="ngr_id__in"
              items={allNgrs}
              selectedItems={combinedStates.ngr_id__in}
              displayField="ngrNumber"
              onChange={(id, selectedItems) =>
                setCombinedStates({ ...combinedStates, 'ngr_id__in': map(selectedItems, 'id') })}
              placeholder="Select NGR..."
              label="Ngrs"
              selectAll
              clearAll
            />
          </div>
          }
          {
            !regradeReseasonReport &&
            <div>
              <div className="col-sm-12 padding-reset">
                <CommonMultiSelect
                  id="commodity_id__in"
                  items={allStocks}
                  selectedItems={combinedStates.commodity_id__in}
                  displayField="displayName"
                  onChange={(id, selectedItems) =>
                    setCombinedStates({ ...combinedStates, 'commodity_id__in': map(selectedItems, 'id') })}
                  placeholder="Select Commodity..."
                  label="Commodity"
                  selectAll
                  clearAll
                />
              </div>
              <div className="col-sm-12 padding-reset">
                <CommonMultiSelect
                  id="grade__id__in"
                  items={getGrades()}
                  selectedItems={combinedStates.grade__id__in}
                  displayField="name"
                  onChange={(id, selectedItems) => setCombinedStates({ ...combinedStates, 'grade__id__in': map(selectedItems, 'id') })}
                  placeholder="Select Grade(s)..."
                  label="Grade(s)"
                  selectAll
                  clearAll
                />
              </div>
              <div className="col-sm-12 padding-reset">
                <CommonMultiSelect
                  id="season__in"
                  items={allSeason}
                  selectedItems={combinedStates.season__in}
                  displayField="name"
                  onChange={(id, selectedItems) => setCombinedStates({...combinedStates, 'season__in': map(selectedItems, 'id')})}
                  placeholder="Select Season..."
                  label="Season"
                  selectAll
                  clearAll
                />
              </div>
            </div>
          }
          {regradeReseasonReport &&
          <div>
            <div className="col-sm-12 padding-reset">
              <CommonMultiSelect
                id="ngr__company_id__in"
                items={allCompanies}
                selectedItems={regradeReseasonStates.ngr__company_id__in}
                displayField="name"
                onChange={handleCompanyChangeForRegradeReseason}
                placeholder="Select Owner(s)..."
                label="Stock Owners"
                selectAll
                clearAll
              />
            </div>
            <div className="col-sm-12 padding-reset">
              <CommonMultiSelect
                id="ngr_id__in"
                items={allNgrs}
                selectedItems={regradeReseasonStates.ngr_id__in}
                displayField="ngrNumber"
                onChange={(id, selectedItems) =>
                  setRegradeReseasonStates({ ...regradeReseasonStates, 'ngr_id__in': map(selectedItems, 'id') })}
                placeholder="Select NGR..."
                label="Ngrs"
                selectAll
                clearAll
              />
            </div>
          </div>
          }
          {regradeReseasonReport &&
          <div>
            <div className="col-sm-12 padding-reset">
              <CommonMultiSelect
                id="commodity_id__in"
                items={allStocks}
                selectedItems={regradeReseasonStates.commodity_id__in}
                displayField="displayName"
                onChange={(id, selectedItems) =>
                  setRegradeReseasonStates({ ...regradeReseasonStates, 'commodity_id__in': map(selectedItems, 'id') })}
                placeholder="Select Commodity..."
                label="Commodity"
                selectAll
                clearAll
              />
            </div>
            <div className="col-sm-12 padding-reset">
              <CommonMultiSelect
                id="old_grade__id__in"
                items={getGradesForRegradedStocks()}
                selectedItems={regradeReseasonStates.old_grade__id__in}
                displayField="name"
                onChange={(id, selectedItems) => setRegradeReseasonStates({ ...regradeReseasonStates, 'old_grade__id__in': map(selectedItems, 'id') })}
                placeholder="Select Old Grade(s)..."
                label="Old Grade(s)"
                selectAll
                clearAll
              />
            </div>
            <div className="col-sm-12 padding-reset">
              <CommonMultiSelect
                id="old_season__in"
                items={allSeason}
                selectedItems={regradeReseasonStates.old_season__in}
                displayField="name"
                onChange={(id, selectedItems) => setRegradeReseasonStates({...regradeReseasonStates, 'old_season__in': map(selectedItems, 'id')})}
                placeholder="Select Old Season..."
                label="Old Season"
                selectAll
                clearAll
              />
            </div>
            <div className="col-sm-12 padding-reset">
              <CommonMultiSelect
                id="new_grade__id__in"
                items={getGradesForRegradedStocks()}
                selectedItems={regradeReseasonStates.new_grade__id__in}
                displayField="name"
                onChange={(id, selectedItems) => setRegradeReseasonStates({ ...regradeReseasonStates, 'new_grade__id__in': map(selectedItems, 'id') })}
                placeholder="Select Regraded Grade(s)..."
                label="Regraded To"
                selectAll
                clearAll
              />
            </div>
            <div className="col-sm-12 padding-reset">
              <CommonMultiSelect
                id="new_season__in"
                items={allSeason}
                selectedItems={regradeReseasonStates.new_season__in}
                displayField="name"
                onChange={(id, selectedItems) => setRegradeReseasonStates({...regradeReseasonStates, 'new_season__in': map(selectedItems, 'id')})}
                placeholder="Select Reseasoned Season..."
                label="Reseasoned To"
                selectAll
                clearAll
              />
            </div>
            <div className='col-sm-6 no-left-padding'>
              <CommonDatePicker
                id='date_time__gte'
                floatingLabelText='Start Date'
                value={regradeReseasonStates.date_time__gte}
                onChange={(val, id) => setRegradeReseasonStates({ ...regradeReseasonStates, [id]: val })}
              />
            </div>
            <div className='col-sm-6 no-right-padding'>
              <CommonDatePicker
                id='date_time__lte'
                floatingLabelText='End Date'
                value={regradeReseasonStates.date_time__lte}
                onChange={(val, id) => setRegradeReseasonStates({ ...regradeReseasonStates, [id]: val })}
              />
            </div>
          </div>
          }
          {
            (loadsCSV || acquisitionFile || stocksOverviewReport) ?
            <React.Fragment>
              <div className='col-sm-6 no-left-padding' style={{marginTop: '15px'}}>
                <CommonDatePicker
                  id='date_time__gte'
                  floatingLabelText='Start Date'
                  value={combinedStates.date_time__gte}
                  onChange={(val, id) => setCombinedStates({ ...combinedStates, [id]: val })}
                />
              </div>

              <div className='col-sm-6 no-right-padding' style={{marginTop: '15px'}}>
                <CommonDatePicker
                  id='date_time__lte'
                  floatingLabelText='End Date'
                  value={combinedStates.date_time__lte}
                  onChange={(val, id) => setCombinedStates({ ...combinedStates, [id]: val })}
                />
              </div>

              {loadsCSV &&
              <div className="col-sm-12 padding-reset">
                <CommonMultiSelect
                  id="type__in"
                  items={[
                    { 'id': 'inload', 'name': 'Inload' },
                    { 'id': 'outload', 'name': 'Outload' },
                    { 'id': 'title_transfer_outload', 'name': 'Transferred Out' },
                    { 'id': 'title_transfer_inload', 'name': 'Transferred In' },
                  ]}
                  selectedItems={combinedStates.type__in}
                  displayField="name"
                  onChange={(id, selectedItems) => setCombinedStates({ ...combinedStates, 'type__in': map(selectedItems, 'id') })}
                  placeholder="Select Load Type..."
                  label="Load Type"
                  selectAll
                  clearAll
                />
              </div>
              }
              {acquisitionFile &&
                <div className="col-sm-12 padding-reset">
                  <CommonTextField
                    id="load_type"
                    label="Load Type"
                    value="Inload"
                    disabled
                  />
                </div>
              }
              {
                stocksOverviewReport &&
                  <FormControl className='col-sm-12 padding-reset' component="fieldset" variant="standard">
                    <FormLabel style={{ paddingTop: '15px' }}  component="legend">Group Together</FormLabel>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={groupCommodityDetailsTogether}
                            name="groupCommodityDetailsTogether"
                            onChange={() => setGroupCommodityDetailsTogether(!groupCommodityDetailsTogether)}
                          />
                        }
                        label="Commodities"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={groupGrades}
                            name="groupGrades"
                            onChange={() => setGroupGrades(!groupGrades)}
                          />
                        }
                        label="Grades"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={groupSeasons}
                            name="groupSeasons"
                            onChange={() => setGroupSeasons(!groupSeasons)}
                          />
                        }
                        label="Seasons"
                      />
                    </FormGroup>
                  </FormControl>
              }
            </React.Fragment> :
              <div>
                {!regradeReseasonReport && !stocksOverviewReport &&
                <div className='col-sm-6 padding-reset' style={{ paddingLeft: '0px', paddingTop: '2%' }}>
                  <CommonDatePicker
                    id='date_time__lte'
                    floatingLabelText='Stocks As On'
                    value={combinedStates.date_time__lte}
                    onChange={(val, id) => setCombinedStates({ ...combinedStates, [id]: val })}
                  />
                </div>
                }
                {!regradeReseasonReport &&
                <div className='col-sm-6 padding-reset' style={{ paddingLeft: '32px', paddingTop: '4%' }}>
                  <Checkbox
                    id={'club-commodity-details'}
                    checked={groupCommodityDetailsTogether}
                    style={{ height: '40px' }}
                    onChange={() => setGroupCommodityDetailsTogether(!groupCommodityDetailsTogether)}
                  />Club grades together
                </div>
                }
              </div>
          }
          {
            enableCustomCsv() &&
              <div className='col-sm-12 padding-reset' style={{marginTop: '15px'}}>
                <Checkbox
                  id='custom-headers'
                  checked={customColumns}
                  style={{ paddingLeft: '0px', height: '40px' }}
                  onChange={() => setCustomColumns(!customColumns)}
                />Custom Columns
                <a onClick={editCustomHeaderOptions} hidden={!customColumns} style={CUSTOM_HEADER_EDIT}>View & Update</a>
              </div>
          }
          {
            loadsCSV &&
              <div className='col-sm-12 padding-reset' style={{marginTop: '15px'}}>
                <Checkbox
                  id='negativeOutloads'
                  checked={negativeOutloads}
                  style={{ paddingLeft: '0px', height: '40px' }}
                  onChange={() => setNegativeOutloads(!negativeOutloads)}
                />Negative Outloads
              </div>
          }
          <SideDrawer
            isOpen={customHeaderOptions}
            title={customColumnTitle}
            onClose={() => setCustomHeaderOptions(false)}
            size="small"
          >
            <CustomHeaderOptions
              customColumns={customColumnNames}
              closeDrawer={() => setCustomHeaderOptions(false)}
              user={props.user}
              token={props.token}
              csv_type={csvType}
              updateColumnCount={(count) => updateColumnCount(count)}
            />
          </SideDrawer>
        </DialogContent>
        <DialogActions>
          <Button type='button' onClick={props.toggleDialog} variant='outlined'>
            Cancel
          </Button>
          <Button type='button' onClick={onClickMethod()} color='primary' variant='outlined'>
            Download
          </Button>
        </DialogActions>

      </Dialog>
      }
    </span>
  );
};

export default OwnershipStockCSV;
