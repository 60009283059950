/*eslint no-process-env: 0*/
import axios from 'axios';
import alertifyjs from 'alertifyjs';
import get from 'lodash/get';
import omit from 'lodash/omit';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
import defaults from 'lodash/defaults';
import { currentUserToken, formatQueryString } from '../common/utils';
import packageJson from '../../package.json';

const VERSION = packageJson?.version

/*eslint no-undef: 0*/
const APIURL = process.env.API_URL;
const APIServiceProvider = {};
const RESOURCES = [
  { name: 'location', relations: ['location'] },
  { name: 'cash_board', relations: ['constants'] },
  { name: 'vendor_decs', relations: ['constants'] },
  { name: 'timezones', relations: [] },
  { name: 'release_notes', relations: [] },
  { name: 'abn', relations: [] },
  { name: 'base64', relations: [] },
  { name: 'notes', relations: [] },
  { name: 'toggles', relations: [] },
  { name: 'distance', relations: [] },
  { name: 'states', relations: [] },
  {
    name: 'profiles',
    relations: ['notifications', 'admin-emails', 'downloads', 'filters', 'login']
  },
  { name: 'companies/minimal', relations: [] },
  {
    name: 'companies',
    relations: [
      'farms',
      'farms/web',
      'farms/web/search',
      'employees',
      'employees/minimal',
      'employees/new',
      'ngrs/new',
      'trucks',
      'trailers',
      'bhc_locations',
      'ngrs',
      'company_sites',
      'stocks',
      'companies',
      'companies/web',
      'companies/web/search',
      'directory/names',
      'companies/minimal',
      'types',
      'farm_ngrs',
      'setup',
      'trailers/unassigned',
      'admins',
      'claim',
      'names',
      'orders',
      'cash_price',
    ],
  },
  { name: 'trucks', relations: [] },
  { name: 'trailers', relations: [] },
  {
    name: 'farms',
    relations: [
      'farm_fields',
      'ngrs',
      'storages/home',
      'storages/system',
      'employees',
      'employees/new',
      'ngrs/new',
      'storages',
      'commodities',
      'stocks',
      'trucks',
      'setup',
      'freight-movements',
      'freight-movements/csv',
    ],
  },
  { name: 'farm_fields', relations: [] },
  { name: 'commodities', relations: ['varieties', 'grades', 'materials'] },
  { name: 'varieties', relations: [] },
  { name: 'grades', relations: [] },
  { name: 'bhc_locations', relations: [] },
  { name: 'storages', relations: ['inloads', 'outloads', 'storage_levels', 'deficit', 'loads', 'minimal'] },
  { name: 'ngrs', relations: [] },
  { name: 'invoice-payments', relations: ['confirm', 'reject'] },
  { name: 'invoices', relations: ['brokerages', 'confirm', 'reject', 'paid', 'invoice-payments', 'mark-paid'] },
  {
    name: 'contracts',
    relations: [
      'amend',
      'amend/confirm',
      'amend/reject',
      'confirm',
      'reject',
      'farms',
      'contacts',
      'companies',
      'orchestrate',
      'freight/contracts/new',
      'freight/orders/new',
      'title-transfers/new',
      'hierarchy',
      'price-points',
      'handlers'
    ],
  },
  {
    name: 'company_sites',
    relations: ['slots', 'slots/open-or-self', 'comments'],
  },
  {
    name: 'audit-history',
    relations: ['audit-history']
  },
  {
    name: 'freights',
    relations: [
      'contracts',
      'confirm',
      'reject',
      'void',
      'void/confirm',
      'void/reject',
      'amend',
      'amend/confirm',
      'amend/reject',
      'orders',
      'reject-load',
      'complete',
      'orchestrate',
      'assign',
      'assign-parent',
      'meta'
    ],
  },
  {
    name: 'freights/orders',
    relations: ['freight/contracts/new', 'freight/allocations/new'],
  },
  {
    name: 'loads', relations: [
      'movement'
    ]
  },
  {
    name: 'orders',
    relations: ['confirm', 'reject', 'void', 'void/confirm', 'void/reject', 'amend', 'amend/confirm', 'amend/reject'],
  },
  { name: 'mobile_messages', relations: [] },
  { name: 'reasons', relations: [] },
  { name: 'ngrs', relations: [] },
  { name: 's3', relations: [] },
  { name: 'stocks', relations: ['farms', 'storages', 'companies'] },
  { name: 'empty', relations: [] },
  { name: 'alerts', relations: [] },
  { name: 'countries', relations: [] },
  { name: 'contract_bids', relations: [] },
  { name: 'new', relations: [] },
];

class APIService {
  constructor(name, id, relations) {
    if (name === 'empty') this.URL = `${APIURL}/`;
    else this.URL = `${APIURL}/${name}/`;
    this.headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    if (id) {
      this.URL += `${id}/`;
    }
    if (relations) {
      relations.forEach(relation => {
        this[relation] = relationId => {
          this.URL += `${relation}/${relationId ? `${relationId}/` : ''}`;
          return this;
        };
      });
    }
  }

  appendToUrl(s) {
    this.URL += s;
    return this;
  }

  overrideURL(url) {
    this.URL = `${APIURL}${url}`;
    return this;
  }

  get(token, headers = {}, query) {
    return this.sendRequest('GET', null, token, headers, query);
  }

  post(data, token, headers = {}, query) {
    return this.sendRequest('POST', data, token, headers, query);
  }

  put(data, token, headers = {}, query) {
    return this.sendRequest('PUT', data, token, headers, query);
  }

  patch(data, token, headers = {}, query) {
    return this.sendRequest('PATCH', data, token, headers, query);
  }

  delete(token, headers = {}, query) {
    return this.sendRequest('DELETE', null, token, headers, query);
  }

  sendRequest(method, data, token, headers, query) {
    const request = this.getRequest(method, data, token, headers, query);
    return axios(request)
      .then(response => response.data || null)
      .catch(error => {
        if(error?.response?.data?.alert)
          alertifyjs.alert('Error!', error.response.data.alert, () => window.location.reload())
        return error.response ? error.response.data : error.message;
      });
  }

  getRequest(method, data, token, headers, query) {
    return {
      url: formatQueryString(this.URL),
      method,
      headers: this.getHeaders(token, headers),
      params: this.getQueryParams(query),
      data,
    };
  }

  request(method, data, token, config) {
    let headers = {};
    let query = {};
    if (isPlainObject(config)) {
      headers = get(config, 'headers', {});
      query = get(config, 'query', {});
    }
    let request = this.getRequest(method, data, token, headers, query);
    request = { ...request, ...omit(config, ['headers', 'query']) };
    return axios(request);
  };

  requestUsingFetch(method, headers, data) {
    return fetch(this.URL, {
      method,
      headers: this.getHeaders(headers),
      body: JSON.stringify(data)
    })
  }

  getHeaders(token, headers) {
    token = token || currentUserToken();
    const obj = defaults(headers, this.headers);
    if (token) obj['Authorization'] = `Token ${token}`;
    obj['REFERER-TZ'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    obj['WEB-VERSION'] = VERSION
    obj['REFERER-COUNTRY'] = localStorage.current_country || 'au';
    if(obj['original-unit']) {
      delete obj['original-unit']
      obj['REFERER-DO-NOT-CONVERT-UNIT'] = true
    }
    if(!obj['REFERER-UNIT'] || ['undefined', 'null'].includes(obj['REFERER-UNIT']))
       delete obj['REFERER-UNIT']
    obj['pragma'] = 'no-cache'
    obj['cache-control'] = 'no-cache'
    return obj;
  }

  getQueryParams(query) {
    if (isPlainObject(query)) {
      return query;
    }
    if (isString(query)) {
      const questionMarkSplit = query.split('?');
      if (questionMarkSplit.length === 2) {
        const params = {};
        const ampersandSplit = questionMarkSplit[1].split('&');
        ampersandSplit.forEach(param => {
          const paramSplit = param.split('=');
          params[paramSplit[0]] = get(paramSplit, '[1]', true);
        });
        return params;
      }
    }
    return {};
  }
}

RESOURCES.forEach(resource => {
  APIServiceProvider[resource.name] = (id, query) => new APIService(resource.name, id, resource.relations, query);
});

export default APIServiceProvider;
