import React from 'react';
import { connect } from 'react-redux';
import alertifyjs from 'alertifyjs';
import GenericTable from '../components/GenericTable';
import { clickOptionForFarmInTable } from '../actions/companies/farms';
import { getSelectedFarm, getCompanyFarmsWeb } from '../actions/api/farms';
import { receiveFarm, receivePaginatedFarms } from '../actions/companies/farms';
import {
  getCompanyIdFromCurrentRoute,
  isSystemCompany,
  currentUserCompany,
  isCompanyAdminOrObserver
} from '../common/utils';
import Link from '../components/common/icons/Link';
import Unlink from '../components/common/icons/Unlink';
import APIService from '../services/APIService';
import { includes } from 'lodash';
import { showSideDrawerForTransfer, showSideDrawerForMerge } from '../actions/companies';

const mapStateToProps = (state, ownProps) => {
  let statusIconFunc = (item) => {
    if(item.isManagedByUser){
      return <Link />;
    } else if (item.isInUserDirectory) {
      return <Unlink style={{marginLeft: '3px'}} />;
    } else {
      return <Unlink style={{marginLeft: '3px', fill: 'lightgray'}} />;
    }
  };
  const userCompanyTypeId = state.main.user.user.company.typeId;

  let columns = [
    { key: 'name', header: 'Farm Name', className: 'large'},
    { key: 'addressName', secondaryKey: 'address.address', header: 'Address', className: 'medium',
      map: {
        name: 'address.address',
        lat: 'address.latitude',
        lng: 'address.longitude'
      },
      inlineMap: true
    },
    { key: 'mobile', header: 'Phone/Mobile', className: 'xsmall' },
    { key: 'marketZoneName', secondaryKey: 'marketZone.name', header: 'Market Zone', className: 'small' },
    { key: 'regionName', secondaryKey: 'region.name', header: 'Region', className: 'small' },
  ];

  if(userCompanyTypeId === 2) {
    columns.splice(
      0,
      0,
      {
        func: statusIconFunc,
        header: 'Status',
        icon: true,
        className: 'no-click',
      }
    );
  }

  return {
    displayIDColumn: 'name',
    clearSearch: receivePaginatedFarms,
    paginationData: state.companies.farms.paginationData,
    globalSearch: true,
    columns: columns,
    items: state.companies.farms.items,
    scrollToTopOnUpdate: false,
    rowHighlightedMap: {'isHighlighted': 'delhi-blue'},
    optionsItems: (item) => {
      if (item.isHighlighted) {
        return [{ key: 'accept-reject', text: 'Accept/Reject' }];
      } else if (item.isPendingRequest || item.isPendingRequestForGrower) {
        return [{ key: 'pending-request', text: 'Pending Request' }];
      } else {

        let options = ownProps.isArchived ? [] : [
          { key: 'settings', text: 'Settings' },
        ];
        const stockOptions = ownProps.isArchived ? [] : [{ key: 'stocks', text: 'Stocks' }];
        let moreOptions = [];
        if (isSystemCompany() || (item.companyId === currentUserCompany().id && isCompanyAdminOrObserver())) {
          var option = ownProps.isArchived ? { key : 'unarchive', text: 'Unarchive' } : { key : 'archive', text: 'Archive' };
          moreOptions = [...moreOptions, option];
        }
        if(isSystemCompany() && !ownProps.isArchived){
          moreOptions = moreOptions.concat({ key: 'transfer', text: 'Transfer' });
        }
        if (isSystemCompany() && !ownProps.isArchived)
          moreOptions = moreOptions.concat({ key : 'merge', text: 'Merge' });

        if(item.isManagedByUser || isSystemCompany() || (item.companyId === currentUserCompany().id)) {
          options = [...options, ...stockOptions, ...moreOptions];
        } else if(item.isInUserDirectory) {
          options = options.concat(moreOptions)
        }
        return options;
      }
    },
    optionsColumnNumber: 7,
    orderBy: 'name',
    order: 'asc',
    hardClear: true
  };
};

function handleDefaultCellClick(farm) {
  return (dispatch) => {
    if (farm.isHighlighted) {
      dispatch(getSelectedFarm(farm.id, receiveFarm));
      document.location.hash = `#/farms/${farm.id}/accept-reject`;
    } else if (farm.isPendingRequest || farm.isPendingRequestForGrower) {
      document.location.hash = `#/farms/${farm.id}/pending-request`;
    } else
      document.location.hash = `#/farms/${farm.id}/settings/details`;
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleDefaultCellClick: (farm) => {
      if (ownProps.isArchived) {
        var message = (isCompanyAdminOrObserver() || isSystemCompany()) ? 'Please unarchive this farm to view details.' : 'To view details please contact the company admin to unarchive this farm.';
        alertifyjs.alert(
          'Warning',
          message,
          () => {}
          );
      } else
        dispatch(handleDefaultCellClick(farm))
    },
    handleOptionClick: (rowNum, key, farmId, farm) => {
      dispatch(clickOptionForFarmInTable(farm));
      switch (key) {
        case 'accept-reject':
          dispatch(receiveFarm(null));
          dispatch(getSelectedFarm(farmId, receiveFarm));
          document.location.hash = `#/farms/${farmId}/accept-reject`;
          break;
        case 'pending-request':
          document.location.hash = `#/farms/${farmId}/pending-request`;
          break;
        case 'movements':
          document.location.hash = `#/farms/${farmId}/home/movements`;
          break;
        case 'stocks':
          document.location.hash = `#/stocks/storages-view?farmId=${farmId}`;
          break;
        case 'stock-estimates':
          document.location.hash = `#/farms/${farmId}/home/${key}`;
          break;
        case 'transfer':
          farm.entity = 'farm';
          dispatch(showSideDrawerForTransfer(farm, true));
          break;
        case 'merge':
          farm.entity = 'farm';
          dispatch(showSideDrawerForMerge(farm, true));
          break;
        case 'archive':
        case 'unarchive':
          APIService.farms(farmId).put({'isActive': Boolean(ownProps.isArchived)}).then(response => {
            if(response?.errors)
              alertifyjs.alert('Warning',response.errors);
            else {
            let keyWord = ownProps.isArchived ? 'Unarchived' : 'Archived';
            alertifyjs.success(`Farm ${keyWord}`, 3, () => {
              window.location.reload()
            })
          }})
          break;
        case 'settings':
        default:
          dispatch(receiveFarm(null));
          dispatch(getSelectedFarm(farmId, receiveFarm));
          document.location.hash = `#/farms/${farmId}/settings/details`;
      }
    },
    navigateTo: (url) => {
      dispatch(getCompanyFarmsWeb(null, url, true, true, !ownProps.isArchived));
    },
    changePageSize: (url, pageSize) => {
      if (includes(url, '?')){
        url = `${url}&page_size=${pageSize}`;
      } else {
        url = `${url}?page_size=${pageSize}`;
      }
      dispatch(getCompanyFarmsWeb(null, url, true, true));
    },
    getSearchSortUrl: (pageSize, page, searchText, orderBy, order) => {
      const companyId = getCompanyIdFromCurrentRoute() || currentUserCompany().id;
      const companyService = APIService.companies(companyId);
      companyService.appendToUrl(`farms/web/search/?include_unaccepted=true&page_size=${pageSize}`);
      if (page) {
        companyService.appendToUrl(`&page=${page}`);
      }
      if(searchText) {
        companyService.appendToUrl(`&search=${searchText}`);
      }
      if(orderBy) {
        companyService.appendToUrl(`&order_by=${orderBy}&order=${order}`);
      }
      if(ownProps.isArchived)
        companyService.appendToUrl(`&is_active=${!ownProps.isArchived}`)

      return companyService.URL;
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericTable);
