import alertifyjs from 'alertifyjs';
import APIService from '../../services/APIService';
import{ get, forEach, isEmpty } from 'lodash';
import { receiveTrailers, receiveTrucks, receiveTrucksWeb } from '../companies/trucks';
import { forceStopLoader } from '../main';
import { isInFarmContext, getPageCache } from '../../common/utils';
import { receiveEmployees } from '../company-settings/employees';
import { getCompanyEmployees } from './employees';

const MESSAGES = {
  CREATE_SUCCESS: 'Truck was successfully added.',
  UPDATE_SUCCESS: 'Truck details were successfully updated.',
  TRAILER_CREATE_SUCCESS: 'Trailer was successfully added.',
  TRAILER_UPDATE_SUCCESS: 'Trailer details were successfully updated.',
};

export const getCompanyTrucks = (companyId, actionCreator, appendToUrl) => (dispatch, getState) => {
  const { token } = getState().main.user;
  if (companyId) {
    const companyTrucksService = APIService.companies(companyId).trucks();
    if (appendToUrl) companyTrucksService.appendToUrl(appendToUrl);
    companyTrucksService.get(token).then(items => {
      dispatch(actionCreator(items));
    });
  }
};

export const getCompanyTrucksWeb = (companyId, url, queryParams=null) => (dispatch) => {
  let service = APIService.companies(companyId).trucks().appendToUrl('web/')
  if(url)
    service.URL = url
  const cache = getPageCache();
  const cachedPageSize = get(cache, 'pageSize');
  const cachedPage = get(cache, 'page');
  if(!url && cachedPageSize) {
    if (service.URL.includes('?'))
      service.URL += '&'
    else
      service.URL += '?'
    service.URL += `page_size=${cachedPageSize}&page=${(cachedPage || 0) + 1}`
  }
  if (queryParams) {
    service.URL += service.URL.includes('?') ? '&' : '?'
    service.URL += queryParams;
  }
  service.get().then(result => {
    dispatch(receiveTrucksWeb(result))
    dispatch(forceStopLoader())
  })
};

export const getCompanyUnassignedTrailers = (companyId, actionCreator) => (dispatch, getState) => {
  const { token } = getState().main.user;
  if (companyId) {
    APIService.companies(companyId)
      ['trailers/unassigned']()
      .get(token)
      .then(items => {
        dispatch(actionCreator(items));
      });
  }
};

export const getCompanyTrailers = (companyId, actionCreator, queryParams=null) => (dispatch, getState) => {
  const { token } = getState().main.user;
  let appendToUrl = 'trailers/'
  if (queryParams)
    appendToUrl += queryParams
  if (companyId) {
    APIService.companies(companyId).appendToUrl(appendToUrl)
      .get(token)
      .then(items => {
        dispatch(actionCreator(items));
      });
  }
};

export const getFarmTrucks = (farmId, actionCreator) => (dispatch, getState) => {
  const { token } = getState().main.user;
  if (farmId) {
    APIService.farms(farmId)
      .trucks()
      .get(token)
      .then(items => {
        dispatch(actionCreator(items));
      });
  }
};

export const createTruck = (companyId, data, actionCreator, successCallback, id) => (dispatch, getState) => {
  const { token } = getState().main.user;
  if (!companyId){
    alertifyjs.error("Please select freight provider first")
    return
  }
  APIService.trucks()
    .post({ ...data, company_id: companyId }, token)
    .then(item => {
      if (!isEmpty(item.errors)) {
        forEach(item.errors, value => {
          alertifyjs.error(value[0]);
        });
      } else {
        if (isInFarmContext()) {
          dispatch(getFarmTrucks(data.farmId, receiveTrucks));
        } else {
          dispatch(getCompanyTrucksWeb(companyId));
        }
        dispatch(getCompanyEmployees(companyId, receiveEmployees))
        dispatch(getCompanyTrailers(companyId, receiveTrailers));
        dispatch(actionCreator(item, id));
        alertifyjs.success(MESSAGES.CREATE_SUCCESS);
        if (successCallback) {
          successCallback(item.id);
        }
      }
    });
};

export const updateTruck = (truckId, data, actionCreator, companyId) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.trucks(truckId)
    .put(data, token)
    .then(item => {
      if (!isEmpty(item.errors)) {
        forEach(item.errors, value => {
          alertifyjs.error(value[0]);
        });
      } else {
        if (isInFarmContext()) {
          dispatch(getFarmTrucks(data.farmId, receiveTrucks));
        } else {
          dispatch(getCompanyTrucksWeb(companyId));
        }
        dispatch(getCompanyEmployees(companyId, receiveEmployees))
        dispatch(getCompanyTrailers(companyId, receiveTrailers));
        dispatch(actionCreator(item));
        alertifyjs.success(MESSAGES.UPDATE_SUCCESS);
      }
    });
};

export const createTrailer = (companyId, data, actionCreator) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.trailers()
    .post({ ...data, company_id: companyId }, token)
    .then(item => {
      if (!isEmpty(item.errors)) {
        forEach(item.errors, value => {
          alertifyjs.error(value[0]);
        });
      } else {
        dispatch(actionCreator(item));
        alertifyjs.success(MESSAGES.TRAILER_CREATE_SUCCESS);
      }
    });
};

export const updateTrailer = (trailerId, data, actionCreator) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.trailers(trailerId)
    .put(data, token)
    .then(item => {
      if (!isEmpty(item.errors)) {
        forEach(item.errors, value => {
          alertifyjs.error(value[0]);
        });
      } else {
        dispatch(actionCreator(item));
        alertifyjs.success(MESSAGES.TRAILER_UPDATE_SUCCESS);
      }
    });
};
