import React from 'react';
import { connect } from 'react-redux';
import GenericTable from '../components/GenericTable';
import { isLoading } from '../actions/main';
import {
  getPaginatedInvoicesResponse, handlePaymentRunsOptionsMapper
} from '../actions/companies/invoices';
import { PAYMENT_RUN_TABLE_HEADERS } from '../common/constants';
import { getPaymentRunMenuOptions } from '../components/invoices/utils';
import { Paper } from '@mui/material';
import PaymentRunDetailsActions from '../components/invoices/PaymentRunDetailsActions';

class PaymentRunsTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      assignNGR: false,
      selectedPaymentRun: {},
    }
  }

  closeAddSideForm = () => this.setState({item: undefined});

  openAddSideForm = item => this.setState({item: item});

  handleOptionClick = (event, item, baseEntity) => {
    if(item?.key === 'assign_payer_ngr')
      this.setState({assignNGR: true, selectedPaymentRun: baseEntity})
    else
      this.setState({selectedPaymentRun: baseEntity}, () => {
        this.props.dispatch(handlePaymentRunsOptionsMapper(event, item, baseEntity));
      })
  }

  render() {
    return (
      <Paper className='paper-table'>
        <GenericTable
          {...this.props}
          handleOptionClick={this.handleOptionClick}
          openAddSideForm={this.openAddSideForm}
          closeAddSideForm={this.closeAddSideForm}
        />
        <PaymentRunDetailsActions {...this.props} noMenu assignNGR={this.state.assignNGR} paymentRunDetails={this.state.selectedPaymentRun} onNGRFormClose={() => this.setState({assignNGR: false, selectedPaymentRun: {}})} />
      </Paper>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleDefaultCellClick: paymentRun => {
      dispatch(isLoading('paymentRunDetail'));
      document.location.hash = `/invoices/payment-runs/${paymentRun.id}`;
    },
    navigateTo: null,
    changePageSize: null,
    getSearchSortUrl: null,
    dispatch
  };
};

const mapStateToProps = state => {
  return {
    clearSearch: getPaginatedInvoicesResponse,
    items: state.companies.invoices.paymentRunItems,
    columns: PAYMENT_RUN_TABLE_HEADERS,
    optionsItems: item => getPaymentRunMenuOptions(item),
    globalSearch: false,
    handleDefaultCellClick: true,
    rowHighlightedMap: {'isHighlighted': 'delhi-blue'},
    scrollToTopOnUpdate: false,
    useNestedOptionMenu: true,
    token: state.main.user.token,
    clickedOption: state.companies.invoices.clickedOption,
    paymentRunDetails: state.companies.invoices.selectedPaymentRun,
    canDownloadABA: state.companies.invoices.canDownloadABA,
    displayIDColumn: 'identifier'
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(PaymentRunsTable);
