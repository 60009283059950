export const contractStatusLegends = [
  'Private',
  'Delayed',
  'Error',
  'Payment Overdue',
  'Amended'
];

export const MOVEMENT_CONFIRMED = 'Movement is accepted';
export const ASSIGN_CONTRACT_FO_SUCCESS = 'Assigned contract/freight order successfully';
export const ASSIGN_CONTRACT_PO_SUCCESS = 'Assigned pack order successfully';
export const OUTLOAD = 'outload';
export const INLOAD = 'inload';
export const SPLIT_INLOAD = 'splitInload';
export const SPLIT_OUTLOAD = 'splitOutload';
export const CONTRACT_INVOICED_SUCCESS = "Contract has been successfully invoices";
export const PACKED_CONTAINER = 'packedContainer'
export const MOVEMENT_CANNOT_BE_CHECKED_ERROR = 'Since the order has either active movements or a linked commodity contract, the movement cannot be selected for updating the order.'
export const ORDER_CANNOT_BE_CHECKED_ERROR = 'As the movement has active slots attached, the order cannot be selected for updating the movement.'
export const SUBSCRIBER_PARTY_COULD_NOT_BE_UPDATED_ERROR = 'We would not be able to update the customer/pickup site/delivery site. Thus it cannot be assigned.'
export const BOTH_MOVEMENT_AND_ORDER_CANNOT_BE_CHECKED_ERROR = 'Both order and movement cannot be selected as one of the correct option and thus this movement cannot be assigned.'