import React from 'react';

import { connect } from 'react-redux';
import moment from 'moment';
import CommonTabs from '../common/CommonTabs';
import {setSubHeaderText} from '../../actions/main';
import OrderDetails from './order-details/OrderDetails';
import find from 'lodash/find';
import {
  getSelectedOrder,
  receiveOrder,
  canCreateMovementForOrder,
  canCreateAllocationForOrder,
} from "../../actions/companies/orders";
import get from "lodash/get";
import FreightOrders from "./FreightOrders";
import {FREIGHT_ORDER_MAX_LEVEL, CALL_ON_GRAIN_TYPE_ID, PICKUP_REQUEST_ORDER_TYPE_ID, DELIVERY_REQUEST_ORDER_TYPE_ID, PACK_ORDER_TYPE_ID, ORDER_TYPE_NAMES, ORDER_TYPE_ROUTE_MAPPING} from "../../common/constants";
import isEmpty from "lodash/isEmpty";
import {OrderDetailsBasicInfo} from "./order-details/OrderDetailsBasicInfo";
import OrderFreightMovements from './OrderFreightMovements';
import OrderAllocations from './OrderAllocations';
import FreightInvoices from "../invoices/FreightInvoices";
import Notes from '../notes/Notes';
import SideDrawer from '../common/SideDrawer';
import EditOrderReview from './order-details/EditOrderReview';
import EditMovementReview from './MovementDetails/EditMovementReview';
import { receiveFreight, getSelectedFreight } from '../../actions/companies/freights';
import {
  getOrderHeaderText,
  getOrderSubHeaderText,
  getQueryParams, isSystemCompany, vendorDecBlockPermissionPopup
} from "../../common/utils";
import VendorDecs from '../vendor-decs/VendorDecs';
import { AppContext } from '../main/LayoutContext';

class OrderHome extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props.location.pathname,
      amendDrawOpen: false,
      amendDetails: null,
      amendTimeStamp: null,
      showCurrentContract: true,
      entityId: null,
      entityType: null,
    };

    this.movementId = getQueryParams(document.location.hash, 'movementId');
    this.orderId = getQueryParams(document.location.hash, 'orderId');

    this.handleTabChange = this.handleTabChange.bind(this);
  }

  baseBreadcrumbs() {
    const { order } = this.props;

    const orderTypeId = get(order, 'typeId')
    const orderType = get(ORDER_TYPE_ROUTE_MAPPING, orderTypeId, 'freights')

    return [
      {text: 'Orders', route: `/orders/${orderType}`},
      {text: get(order, 'identifier', ''), route: `/freights/orders/${get(order, 'id')}/order`}
    ];
  }

  baseHeaderText() {
    const { order } = this.props;

    const headerText = getOrderHeaderText(order)
    return headerText
  }

  baseSubHeaderText() {
    const { order } = this.props;
    let subHeaderText = getOrderSubHeaderText(order);
    return subHeaderText;
  }

  componentDidMount(){
    this.fetchOrderIfNeeded();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return nextProps.location.pathname !== prevState.activeTab ?
           { ...prevState, activeTab: nextProps.location.pathname } :
           prevState;
  }

  shouldGetOrder(orderId) {
    orderId = orderId || parseInt(this.props.match.params.order_id);

    return (orderId && !this.props.order) ||
           (orderId && get(this.props.order, 'id') !== orderId);

  }

  componentDidUpdate(prevProps) {
    const orderId = parseInt(this.props.match.params.order_id);
    if (orderId !== parseInt(prevProps.match.params.order_id))
      window.location.reload();

    this.movementId = getQueryParams(document.location.hash, 'movementId');
    this.orderId = getQueryParams(document.location.hash, 'orderId');
    this.fetchOrderIfNeeded();
  }

  onAmendOpen = note => {
    let additionState = {
      showCurrentContract: true
    };
    if (note) {
      additionState = {
        amendDetails: get(note, 'amendedDetails'),
        amendTimeStamp: moment(note.createdAt).format('LLLL'),
        showCurrentContract: false,
        entityId: get(note, 'entity.entityId'),
        entityType: get(note, 'entity.entityType'),
      };
    }
    if (get(note, 'entity.entityType') === 'freightcontract'){
      this.props.dispatch(getSelectedFreight(get(note, 'entity.entityId'), receiveFreight, false, false, false, false));
    }
    this.setState({ ...additionState, amendDrawOpen: true });
  };

  onAmendClose = () => {
    const additionState = {
      amendDetails: null,
      amendTimeStamp: null,
      showCurrentContract: true
    };
    this.setState({ ...additionState, amendDrawOpen: false });
  };

  fetchOrderIfNeeded(orderId) {
    orderId = orderId || parseInt(this.props.match.params.order_id);

    if(this.shouldGetOrder(orderId))
      this.props.dispatch(
        getSelectedOrder(orderId, receiveOrder, false, false, false, false, true)
      );
  }

  onHandleAddFreightInvoiceButtonClick = () => {
    window.location = '/#/freights/invoices/new?orderId=' + this.props.order.id;
  };

  componentWillUnmount() {
    this.props.dispatch(setSubHeaderText(''));
  }

  handleTabChange(value) {
    this.setState({ activeTab: value });
  }

  onHandleAddMovementButtonClick = () => {
    this.props.dispatch(canCreateMovementForOrder(this.props.match.params.order_id));
  };

  onHandleAddOrderButtonClick = () => {
    this.props.dispatch(canCreateAllocationForOrder(this.props.match.params.order_id, this.isPickupOrder() || this.isDeliveryOrder()));
  };

  onAddVendorDecClick = () => {
    const { order } = this.props;
    if(isEmpty(order.cannotCreateVendorDecReasons))
      window.location.hash = `/vendor-decs/grain/new?entity=freightorder&entityId=${order.id}`;
    else
      vendorDecBlockPermissionPopup(order.cannotCreateVendorDecReasons, 'Order');
  };

  getOrderDetailsTabLabel = () => {
    if(this.isCallOnGrain())
      return 'Order';
    if(this.isPickupOrder())
      return 'Pickup Order';
    if(this.isDeliveryOrder())
      return 'Delivery Order';
    if(this.isPackOrder())
      return 'Pack Order';
    return 'Freight Order';
  };

  isCallOnGrain = () => get(this.props.order, 'typeId') === CALL_ON_GRAIN_TYPE_ID
  isPickupOrder = () => get(this.props.order, 'typeId') === PICKUP_REQUEST_ORDER_TYPE_ID
  isDeliveryOrder = () => get(this.props.order, 'typeId') === DELIVERY_REQUEST_ORDER_TYPE_ID
  isPackOrder = () => get(this.props.order, 'typeId') === PACK_ORDER_TYPE_ID

  render() {
    const { isMobileDevice } = this.context
    const PARENT_URL = this.props.match.url;
    const orderId = this.props.match.params.order_id;
    const isCallOnGrain = this.isCallOnGrain();
    const isRequestOrder = this.isPickupOrder() || this.isDeliveryOrder();
    const isPackOrder = this.isPackOrder();
    const orderType = ORDER_TYPE_NAMES.find(orderType => orderType.id == this.props.order?.typeId)?.name;
    const isSystem = isSystemCompany()
    const showAllocationTab = !isMobileDevice && ((isSystem || get(this.props.order,'isFreightProvider') || get(this.props.order,'parentOrder.providerId') == this.props.currentUser.companyId) && get(this.props.order,'level') < FREIGHT_ORDER_MAX_LEVEL);
    const showFreightInvoiceTab = !isMobileDevice && !isRequestOrder && !isCallOnGrain && !isPackOrder && (
      isSystem ||
      get(this.props.order,'isCustomer') ||
      get(this.props.order,'isFreightProvider')
    );
    const canViewPickup = this.props.order?.canViewPickup
    const canViewDelivery = this.props.order?.canViewDelivery
    let tabs = [
      {
        label: this.getOrderDetailsTabLabel(),
        key: 'orderDetails',
        url: `${PARENT_URL}/order`,
        component: () => <OrderDetails {...this.props} />
      }
    ];
    const allocationTab = {
      label: 'Freight Allocations',
      key: 'allocations',
      url: `${PARENT_URL}/allocations`,
      component: () => <FreightOrders {...this.props} parentOrderId={orderId} handleAddOrderButtonClick={this.onHandleAddOrderButtonClick} />
    };
    if(showAllocationTab && !isPackOrder)
      tabs.push(allocationTab);

    if(isCallOnGrain || isRequestOrder)
      tabs.push(
        {
          label: 'Freight Order',
          key: 'freightOrder',
          url: `${PARENT_URL}`,
          component: () => <FreightOrders dontRedirect={true} location={this.props.location} match={this.props.match} contract={this.props.contracts} isRequestOrder={isRequestOrder} order={this.props.order} />
        }
      );
    tabs.push(
      {
        label: isPackOrder ? 'Pack Movements' : 'Movements',
        key: 'movements',
        url: `${PARENT_URL}/movements`,
        component: () =>
          <OrderFreightMovements
            {...this.props}
            orderId={orderId}
            onHandleAddMovementButtonClick={this.onHandleAddMovementButtonClick}
            movementId={this.movementId}
          />
      }
    );
    if(showFreightInvoiceTab)
      tabs.push(
        {
          label: 'Invoices',
          key: 'invoices',
          url: `${PARENT_URL}/freights/invoices`,
          component:() => <FreightInvoices {...this.props} orderId={orderId} onHandleAddFreightInvoiceButtonClick={this.onHandleAddFreightInvoiceButtonClick}/>
        }
      );
    if (!isPackOrder && !isMobileDevice && canViewPickup && canViewDelivery)
      tabs.push(
        {
          label: 'Vendor Declarations',
          key: 'vendorDecs',
          url: `${PARENT_URL}/vendor-decs`,
          component: () => <VendorDecs {...this.props} orderId={orderId} />
        }
      );

    if(!isMobileDevice)
      tabs.push({ label: 'Audit History', key: 'notes', url: `${PARENT_URL}/notes`, component: () => <Notes {...this.props} objectId={orderId} objectType='freightorder' companyId={this.props.companyId} /> });

    return (
      <div className="order-details-container">
        <div className="tab">
          <div className="tab-header">
            <CommonTabs value={this.state.activeTab} tabs={tabs} />
          </div>
          {
            !isEmpty(this.props.order) &&
            !this.props.selectedFreight &&
            !this.props.selectedAllocation &&
            <OrderDetailsBasicInfo {...this.props} showAllocationTonnage={showAllocationTab} />
          }
          <div className="tab-content">
            {
              this.state.activeTab == find(tabs, {key: 'orderDetails'})?.url &&
              <OrderDetails {...this.props} />
            }
            {
              this.state.activeTab == find(tabs, {key: 'movements'})?.url &&
              <OrderFreightMovements
                {...this.props}
                orderId={orderId}
                order={this.props.order}
                onHandleAddMovementButtonClick={this.onHandleAddMovementButtonClick}
                movementId={this.movementId}
              />
            }
            {
              (isCallOnGrain || isRequestOrder) &&
              this.state.activeTab == find(tabs, {key: 'freightOrder'})?.url &&
              <OrderAllocations
                {...this.props}
                parentOrderId={orderId}
                onHandleAddOrderButtonClick={this.onHandleAddOrderButtonClick}
                orderId={this.orderId}
                isRequestOrder={isRequestOrder}
              />
            }
            {
              showFreightInvoiceTab &&
              this.state.activeTab == find(tabs, {key: 'invoices'})?.url &&
              <FreightInvoices {...this.props} orderId={orderId} onHandleAddFreightInvoiceButtonClick={this.onHandleAddFreightInvoiceButtonClick} />
            }
            {
              showAllocationTab && !isPackOrder &&
              this.state.activeTab == find(tabs, {key: 'allocations'})?.url &&
              <OrderAllocations
                {...this.props}
                parentOrderId={orderId}
                onHandleAddOrderButtonClick={this.onHandleAddOrderButtonClick}
                orderId={this.orderId}
              />
            }
            {
              this.state.activeTab == find(tabs, { key: 'notes' })?.url &&
              !isEmpty(this.props.order) &&
              <Notes
                {...this.props}
                objectId={orderId}
                objectType='freightorder'
                companyId={this.props.companyId}
                order={this.props.order}
                onAmendOpen={this.onAmendOpen}
              />
            }
            {
              this.state.activeTab == find(tabs, {key: 'vendorDecs'})?.url &&
              <VendorDecs
                {...this.props}
                queryString={`?order_id=${orderId}`}
                hideAddButton={!this.props.order}
                onAddClick={this.onAddVendorDecClick}
                orderId={orderId}
                baseBreadcrumbs={this.baseBreadcrumbs()}
                baseHeaderText={this.baseHeaderText()}
                baseSubHeaderText={this.baseSubHeaderText()}
              />
            }
          </div>
        </div>
        <SideDrawer
          isOpen={this.state.amendDrawOpen}
          title={`${this.state.entityType === 'freightorder' ? `${orderType} Order Amend Request (${get(this.props.order, 'identifier', '')})` : `Movement Amend Request (${this.props.selectedFreight?.identifier})`}`}
          onClose={this.onAmendClose}
          size="big"
        >
          {
            this.state.entityType === 'freightorder' &&
            <EditOrderReview
              closeSidebar={this.onAmendClose}
              order={this.props.order}
              showCurrentContract={this.state.showCurrentContract}
              amendDetails={this.state.amendDetails}
              amendTimeStamp={this.state.amendTimeStamp}
            />
          }
          {
            this.state.entityType === 'freightcontract' &&
            <EditMovementReview
              closeSidebar={this.onAmendClose}
              movementId={this.state.entityId}
              movement={this.props.selectedFreight}
              showCurrentContract={this.state.showCurrentContract}
              amendDetails={this.state.amendDetails}
              amendTimeStamp={this.state.amendTimeStamp}
            />
          }
        </SideDrawer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    order: state.companies.orders.selectedOrder,
    userToken: state.main.user.token,
    currentUser: state.main.user.user,
    selectedFreight: state.companies.freights.selectedFreight,
    selectedAllocation: state.companies.orders.selectedAllocation,
    selectedAllocationId: state.companies.orders.selectedAllocationId
  };
};

export default connect(mapStateToProps)(OrderHome);
