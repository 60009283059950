import React from 'react';

import { TextField, CircularProgress } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import {get, debounce, last, isArray, orderBy, uniqBy} from 'lodash';
import APIService from '../../../services/APIService';
import { currentUser, currentUserCompany } from '../../../common/utils';

const autocompleteFilters = createFilterOptions();
const SiteAsyncAutocomplete = ({ id, minLength, selected, onChange, size, variant, disabled, label, multiple, limitTags, errorText, farmId, ref, activeSitesOnly, includeSelectedSiteId, addSiteOption, popupIcon, classes, helperText, addLabel, override, options, isAddNewSiteOptionEnabled = true, fetchOnlySitesOfCompany = false, canClearSiteOptions = false, unsetClearSiteOption }) => {
  const [input, setInput] = React.useState('')
  const [items, setItems] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)
  const isSearchable = input.length >= minLength;

  const _onChange = (event, items) => {
    const item = isArray(items) ? last(items) : items
    const text = get(item, 'name', '');
    if(item && item.inputValue){
      onChange(item, id)
    }
    if(!item || item.id !== get(selected, 'id') || !selected?.companyId || override) {
      setInput(text)
      setIsLoading(item ? isLoading : false)
      onChange(multiple ? items : item, id)
    }
  }
  const _onInputChange = debounce((event, value, reason) => {
    if(reason == 'input' && event){
      setInput(value)
      setIsLoading(Boolean(value))
      if(value && value.length >= minLength)
        fetchItems(value)
    }
  }, 300);

  const fetchItems = (searchStr, isPrefetch=false) => {
    if(searchStr || isPrefetch) {
      let _str = searchStr
      if(_str && _str.includes('(') && _str.includes(')')) {
        _str = get(_str.match(/\(([^()]*)\)[^(]*$/), '1')
      }

      if(currentUser()?.id) {
        let queryParams = {
          search: _str,
          is_active: activeSitesOnly,
          include_site: includeSelectedSiteId,
          handler_ids: farmId,
          company_ids: fetchOnlySitesOfCompany ? currentUserCompany().id : null
        }

        APIService
          .contracts()
          .handlers()
          .get(null, null, queryParams)
          .then(res => {
            setItems(orderBy(res?.handlers || [], 'name'))
            setIsLoading(false)
            if (selected?.id && (!selected?.companyId || override) && res?.handlers)
              _onChange(null, res?.handlers)
          });
      }
      else {
        APIService
          .farms()
          .appendToUrl('all/')
          .get(null, null, {search_txt: _str})
          .then(res => {
            setItems(orderBy(res, 'name'))
            setIsLoading(false)
          });

      }
    }
  };

  const fetchById = () => {
    if(farmId) {
      setIsLoading(true)
      APIService.contracts().handlers().get(null, null, {handler_ids: farmId}).then(res => {
        let _items = res?.handlers || []
        setItems(_items)
        _onChange(null, _items)
        setIsLoading(false)
      })
    }
  }

  const clearItems = () => {
    setItems([])
    setInput('')
    unsetClearSiteOption()
    fetchItems('', true)
  }

  React.useEffect(() => {
    if(farmId && !selected?.id && !isLoading && !items?.length) {
      fetchById()
    }
  }, [farmId])

  React.useEffect(() => {
    if(canClearSiteOptions)
      clearItems()
  }, [canClearSiteOptions])

  return (
    <Autocomplete
      ref={ref}
      limitTags={limitTags || -1}
      multiple={Boolean(multiple)}
      id={id || "site"}
      size={size || 'medium'}
      blurOnSelect
      options={options ? uniqBy([...options, ...items], 'id') : items}
      getOptionLabel={option => {
        if (addSiteOption) {
          if (typeof option === 'string')
            return option;
          if (option.inputValue && option.length >= 3)
            return get(option, 'displayName', get(option, 'name'));
          return get(option, 'displayName', get(option, 'name'));
        }
        else
          return option.name
      }}
      isOptionEqualToValue={(option, value) => option?.id === value?.id && value?.id}
      popupIcon={popupIcon}
      loading={isLoading}
      loadingText={isSearchable ? 'Loading...' : `Type at least ${minLength} characters to search`}
      noOptionsText={input ? "No results" : 'Start typing...'}
      filterOptions={(options, params) => {
        if (addSiteOption && !isLoading && isSearchable) {
          let filtered = autocompleteFilters(options, params);
          if (isAddNewSiteOptionEnabled && params.inputValue && params.inputValue.length >= 3 ) {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}" as a new site`,
            });
          }
          return filtered;
        }
        else
          return options
      }}
      renderInput={params => (
        <TextField
          {...params}
          variant={variant || 'outlined'}
          label={addLabel ? label : null}
          placeholder={`${label} (Type at least ${minLength} characters to search)`}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading && !disabled ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
                {disabled && <i style={{top: '5px'}} className="icon-lock"></i>}
              </React.Fragment>
            ),
          }}
          inputProps={{
            ...params.inputProps,
            className: params.inputProps.className + ' black-text'
          }}
          fullWidth
          error={Boolean(errorText)}
          helperText={errorText || helperText}
        />
      )}
      classes={classes}
      value={selected}
      onChange={_onChange}
      onInputChange={(event, value, reason) => _onInputChange(event, value, reason)}
      disabled={Boolean(disabled)}
      disableCloseOnSelect
    />

  )
}

export default SiteAsyncAutocomplete;
