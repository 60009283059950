import React from 'react';


import './tooltip.scss';

class Tooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textExceededLimit: false,
    };
    this.myRef = React.createRef();
  }

  static defaultProps = {
    className: '',
  };

  componentDidMount() {
    this.checkTextExceededLimit();
  }

  componentDidUpdate() {
    this.checkTextExceededLimit();
  }

  isTextExceeds = element => {
    return element.clientWidth <= element.scrollWidth;
  };

  checkTextExceededLimit = () => {
    const element = this.myRef.current;
    const overflow = this.isTextExceeds(element);
    if (overflow !== this.state.textExceededLimit) {
      this.setState({ textExceededLimit: overflow });
    }
  };

  render() {
    const { textExceededLimit } = this.state;
    const { tooltipText, textContent, className, valueStyle, prefix, innerTextClassName } = this.props;
    const tooltipTextBasedOnLimit = textExceededLimit ? tooltipText : '';
    return (
      <span
        ref={this.myRef}
        className={className}
        title={tooltipTextBasedOnLimit}
        style={valueStyle}
      >
        <span
          className={innerTextClassName}
        >
          {textContent}
        </span>
        {prefix}
      </span>
    );
  }
}

export default Tooltip;
