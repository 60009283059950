import React from 'react';

import { connect } from 'react-redux';
import FarmFieldsTable from '../../containers/FarmFieldsTable';
import CreateFarmField from '../../containers/CreateFarmField';
import UpdateFarmField from '../../containers/UpdateFarmField';
import { getFarmFields } from '../../actions/api/farm-fields';
import {
  receiveFarmFields,
  clickAddFarmFieldButton,
  cancelEditFarmField,
} from '../../actions/companies/farm-fields';
import AddButton from '../common/AddButton';
import Paper from '@mui/material/Paper';
import {setBreadcrumbs, setDownloadBar} from '../../actions/main';
import {isEqual, get, cloneDeep, find} from 'lodash';
import SideDrawer from '../common/SideDrawer';
import {
  getCompanyIdFromCurrentRoute, isSystemCompany, farmStocksPath, attachCSVEventListener, isCompanyEditable,
} from '../../common/utils';
import { getCompanyDetails } from '../../actions/companies';
import { farmSideDrawerForTransfer } from '../../actions/companies/farms';
import FarmTransferAsset from '../../containers/FarmTransferAsset';
import FarmFieldsCSV from './FarmFieldsCSV';
import { showSideDrawerForMerge } from '../../actions/companies/index';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import CommonButton from '../common/CommonButton';
import APIService from "../../services/APIService";
import alertifyjs from 'alertifyjs';


class ActiveFarmFields extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openCreateSideDrawer: false,
      isOpen: false,
      openMergeSideDrawer: false,
      mergeToFarmField: {
        value: undefined,
        errors: [],
        validators: []
      }
    };
    this.farmId = this.props.farmId;
    this.handleAddFarmFieldButtonClick = this.handleAddFarmFieldButtonClick.bind(this);
    this.onCloseSideDraw = this.onCloseSideDraw.bind(this);
    this.handleFarmFieldChange = this.handleFarmFieldChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeMergeDrawer = this.closeMergeDrawer.bind(this);
  }

  componentDidMount() {
    this._attachCSVEventListener();
    this.getCompanyIfNeeded();
    this.setBreadcrumbs();
    this.props.getFarmFields(this.farmId);
    this.props.cancelMergeDrawer();
  }

  componentDidUpdate() {
    this.setBreadcrumbs();
  }

  getCompanyIfNeeded() {
    if(getCompanyIdFromCurrentRoute() && (!this.props.selectedCompany || this.props.selectedCompany.id !== parseInt(getCompanyIdFromCurrentRoute()))) {
      const companyId = getCompanyIdFromCurrentRoute();
      companyId && this.props.getCompany(companyId);
    }
  }

  onCloseDownloadResponse = () =>{
    this.props.setDownloadBar(null, false);
  };

  onDownloadResponse = message =>{
    this.props.setDownloadBar(message, true, this.onCloseDownloadResponse);
  };

  _attachCSVEventListener = () =>{
    attachCSVEventListener('farm-field-csv-ready', 'Farm-Fields', this.onDownloadResponse);
  };

  setBreadcrumbs() {
    const countLabel = ` (${this.props.count})`;
    if(this.props.farmId && this.props.farmName){
      const companyId = getCompanyIdFromCurrentRoute();
      const farmName = this.props.farmName;
      const farmId = this.props.farmId;
      let breadcrumbs = [];
      if(companyId && this.props.selectedCompany) {
        const parentRoute = '/companies/' + this.props.selectedCompany.id;
        breadcrumbs = [
          {text: 'Companies', route: '/companies'},
          {text: this.props.selectedCompany.name, route:  parentRoute + '/details?details'},
          {text: 'Farms', route: parentRoute + '/farms'},
          {text: 'Fields' + countLabel}
        ];
        const stocksOption = { text: farmName, route: parentRoute + farmStocksPath(farmId) };
        const detailsOption = { text: farmName, route: parentRoute + '/farms/' + farmId + '/settings/details?details' };
        if(this.props.canActOnFarm && this.props.selectedFarm.isAssociated) {
          breadcrumbs.splice(breadcrumbs.length-1, 0, stocksOption);
        } else {
          breadcrumbs.splice(breadcrumbs.length-1, 0, detailsOption);
        }
      } else {
        if(this.props.canActOnFarm && (this.props.selectedFarm.isAssociated || isSystemCompany())) {
          breadcrumbs = [
            {text: 'Farms', route: '/farms'},
            {text: farmName, route: farmStocksPath(farmId)},
            {text: 'Settings', route: '/farms/' + farmId + '/settings/details?details'},
            {text: 'Fields' + countLabel}
          ];
        } else {
          breadcrumbs = [
            {text: 'Farms', route: '/farms'},
          ];

          breadcrumbs = breadcrumbs.concat([
            {text: 'Settings', route: '/farms/' + farmId + '/settings/details?details'},
            {text: 'Fields' + countLabel}
          ]);
        }
      }
      if(!isEqual(this.props.breadcrumbs, breadcrumbs)) {
        this.props.setBreadcrumbs(breadcrumbs);
      }
    }

  }

  handleAddFarmFieldButtonClick() {
    this.props.handleAddFarmFieldButtonClick();
    this.setState({
      openCreateSideDrawer: true,
    });
  }

  onCloseSideDraw() {
    this.setState({
      openCreateSideDrawer: false,
    });
  }

  toggleDialog = () => this.setState({isOpen: !this.state.isOpen});

  componentWillUnmount() {
    this.props.cancelFarmTransfer(null, false);
    this.props.cancelMergeDrawer(null, false);
  };

  getFarmFields = () => {
    let farmFields = cloneDeep(this.props.farmFields);
    farmFields.splice(farmFields.findIndex(item => item.id === get(this.props, 'selectedAsset.id')), 1);
    return farmFields;
  };

  getLabel = () => {
    let fromFarmField = get(this.props, 'selectedAsset.name');
    let toFarmField = "";
    if (this.state.mergeToFarmField.value) {
      toFarmField = get(find(this.props.farmFields, {id: this.state.mergeToFarmField.value}), 'name');
    }
    return "Merge " + fromFarmField + " into " + toFarmField;
  }

  closeMergeDrawer() {
    const newState = {...this.state};
    newState.mergeToFarmField.value = undefined;
    this.setState(newState);
    this.props.cancelMergeDrawer();
  }

  handleFarmFieldChange(value) {
    const newState = {...this.state};
    newState.mergeToFarmField.value = value;
    newState.mergeToFarmField.errors = [];
    this.setState(newState);
  }

  handleSubmit() {
    let data = {};
    data['mergeFrom'] = get(this.props.selectedAsset, 'id');
    let farmId = get(this.props.selectedAsset, 'farmId');
    if (data['mergeFrom'] && farmId) {
    APIService.farms(farmId)
      .farm_fields()
      .appendToUrl(`${this.state.mergeToFarmField.value}/merge/`)
      .post(data)
      .then(res => {
      if (get(res, 'alert')) alertifyjs.error(get(res, 'alert'))
      else alertifyjs.success('Farm Field Merged!', 3, () => window.location.reload())
      });
    }
  }

  render() {
    const isEditable = isCompanyEditable(this.props.selectedCompany);
    const showPaddock = get(this.props, 'currentUser.companyId') === get(this.props, 'selectedFarm.companyId') || get(this.props, 'currentUser.isSuperuser');;
    return (
      <Paper className="paper-table">
        <div style={{position:"relative"}}>
          <FarmFieldsCSV
            isOpen={this.state.isOpen}
            toggleDialog={this.toggleDialog}
            farmId={this.props.farmId}
            showPaddock={showPaddock}
          />

          {
            this.props.canActOnFarm || isEditable ?
            <AddButton label="Field" onClick={this.handleAddFarmFieldButtonClick} app="farmField" style={{right: showPaddock ? "191px" : null}}/>
            : null
          }
        <FarmFieldsTable canActOnFarm={this.props.canActOnFarm}/>

        </div>
        {this.props.isCreateFormDisplayed &&
         <SideDrawer
           isOpen={this.state.openCreateSideDrawer}
           title="Add Field"
           size="small"
           onClose={this.onCloseSideDraw}
           app="farmField"
         >
           <CreateFarmField closeDrawer={this.onCloseSideDraw} farmId={this.farmId} />
         </SideDrawer>
        }
        {
          this.props.canActOnFarm || isEditable ?
            <SideDrawer
              size='big'
            isOpen={this.props.isUpdateFormDisplayed}
            title="Edit Field"
            onClose={this.props.cancelEditFarmField}
            app="farmField"
            >
            <UpdateFarmField farmId={this.farmId} />
          </SideDrawer>
          : null
        }
        {
          this.props.canActOnFarm || isEditable ?
          <SideDrawer
            isOpen={this.props.isopenSideDrawer}
            title="Transfer"
            size="small"
            onClose={this.props.cancelFarmTransfer}
            app="farmField"
            >
            <FarmTransferAsset farmId={this.farmId} />
          </SideDrawer>
          : null
        }
        {this.props.openMergeSideDrawer &&
          <SideDrawer
            isOpen={this.props.openMergeSideDrawer}
            title="Merge"
            onClose={this.closeMergeDrawer}
          >
            <div style={{ paddingTop: "15px"}}>
              <CommonAutoSelect
                id='mergeToFarmField'
                items={this.getFarmFields()}
                label={this.getLabel()}
                value={this.state.mergeToFarmField.value}
                onChange={this.handleFarmFieldChange}
              />
            </div>
            {
            <div className="col-sm-12 cardForm-action top15 padding-reset" style={{ paddingTop: "7px", textAlign: "right" }}>
              <CommonButton
                type="button"
                label="Cancel"
                default
                onClick={this.closeMergeDrawer}
                variant="contained"
              />
              <CommonButton
                type="submit"
                label="Merge"
                primary
                onClick={this.handleSubmit}
                variant="contained"
              />
            </div>
            }
          </SideDrawer>
        }
      </Paper>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    isopenSideDrawer: state.companies.farms.farmSideDrawer,
    openMergeSideDrawer: state.companies.companies.showMergeSideDrawer,
    isCreateFormDisplayed: state.companies.farmFields.isCreateFormDisplayed,
    isUpdateFormDisplayed: state.companies.farmFields.isUpdateFormDisplayed,
    breadcrumbs: state.main.breadcrumbs,
    selectedCompany: state.companies.companies.selectedCompany,
    selectedFarm: state.companies.farms.selectedFarm,
    farmFields: get(state.companies.farmFields, 'items'),
    count: get(state.companies.farmFields, 'items.length', 0),
    currentUser: state.main.user.user,
    selectedAsset: state.companies.companies.selectedAsset
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    cancelFarmTransfer: () => dispatch(farmSideDrawerForTransfer(false)),
    cancelMergeDrawer: () => dispatch(showSideDrawerForMerge(false)),
    getFarmFields: (farmId) => dispatch(getFarmFields(farmId, receiveFarmFields)),
    handleAddFarmFieldButtonClick: () => dispatch(clickAddFarmFieldButton()),
    setBreadcrumbs: (breadcrumbs) => dispatch(setBreadcrumbs(breadcrumbs)),
    cancelEditFarmField: () => dispatch(cancelEditFarmField()),
    getCompany: (companyId) => dispatch(getCompanyDetails(companyId)),
    setDownloadBar: (msg, isOpen, callback) => dispatch(setDownloadBar(msg, isOpen, callback))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveFarmFields);
