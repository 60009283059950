import { useEffect } from 'react';

const FreshDeskWidget = () => {
  useEffect(() => {
    window.fwSettings = {
      widget_id: 1060000001780,
    };

    const script = document.createElement('script');
    script.src = 'https://ind-widget.freshworks.com/widgets/1060000001780.js';
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return null;
};

export default FreshDeskWidget;