import React from 'react';
import { connect } from 'react-redux';
import GenericTable from '../components/GenericTable';
import {
  getContracts,
  getSelectedFreight,
  receiveFreight,
  getPaginatedContractsResponse,
  handleFreightMovementOptionsMapper,
} from '../actions/companies/freights';
import { isLoading } from '../actions/main';
import {isArray, get, includes, find, isEmpty } from 'lodash';
import APIService from '../services/APIService';
import { toDateFormat, getAbsoluteUrl, isDirectToBuyerAllocationEnabled } from "../common/utils";
import { getFreightMovementActionMenuOptions, regenerateMovementPDF } from '../components/freights/utils';
import { PACK_MOVEMENTS_TABLE_GLOBAL_LISTING_HEADERS, CONTRACT_ALLOCATION_MOVEMENT_HEADERS, PACK_MOVEMENTS_TABLE_COLUMN_LIMIT
} from "../common/constants";
import MovementActions from '../components/freights/MovementActions';
import { getCustomColumns } from '../actions/companies/index';

class PackMovementsTable extends React.Component {

  componentDidMount() {
    this.props.getCustomColumns('pack_movements_table');
  }

  closeAddSideForm = () =>{
    this.setState({item: undefined});
  };

  openAddSideForm = item =>{
    this.setState({item: item});
  };

  render() {
    return(
      <div>
        <GenericTable
          {...this.props}
          openAddSideForm={this.openAddSideForm}
          closeAddSideForm={this.closeAddSideForm}
        />
          <MovementActions {...this.props} isPackMovement/>
      </div>
    );
  }
}


const mapStateToProps = state => {
  var isPackMovement = true;
  (isArray(get(state.companies, 'freights.items')) ? state.companies.freights.items : []).forEach(item => {
    let pickupDate = includes(item.pickupDatetime, 'T') && toDateFormat(new Date(item.pickupDatetime));
    let deliveryDate = includes(item.deliveryDatetime, 'T') && toDateFormat(new Date(item.deliveryDatetime));
    let packDate = includes(item.packDate, 'T') && toDateFormat(new Date(item.packDate));
    if(pickupDate && pickupDate !== 'Invalid date')
      item['pickupDatetime'] = pickupDate;
    if(deliveryDate && deliveryDate !== 'Invalid date')
      item['deliveryDatetime'] = deliveryDate;
    if(packDate && packDate !== 'Invalid date')
    item['packDate'] = packDate;
    isPackMovement = true;
  });
  let customColumns = [...PACK_MOVEMENTS_TABLE_GLOBAL_LISTING_HEADERS];
  if (!isDirectToBuyerAllocationEnabled()) {
    customColumns = customColumns.filter(obj => !includes(CONTRACT_ALLOCATION_MOVEMENT_HEADERS, obj.key));
  }
  const tableColumnsOrder = get(state.companies, 'companies.tableColumns');
  if (tableColumnsOrder && !isEmpty(tableColumnsOrder)) {
    customColumns = [];
    tableColumnsOrder.forEach(val => {
      const obj = find(PACK_MOVEMENTS_TABLE_GLOBAL_LISTING_HEADERS, {key: val});
      if (obj) {
        customColumns.push(obj);
      }
    });
  }
  if (customColumns.length > PACK_MOVEMENTS_TABLE_COLUMN_LIMIT) {
    customColumns = customColumns.splice(0, PACK_MOVEMENTS_TABLE_COLUMN_LIMIT - 1);
  }
  const subItems = state.companies.freights.subItems;
  const clickedOption = state.companies.freights.clickedOption;
  let freightItems = state.companies.freights.items;
  if (freightItems && isArray(freightItems)) {
    freightItems.map(item => {
      if (!item.isCommodityContractInvoiced)
        item.isCommodityContractInvoiced = "False";
      if (!item.saleContractInvoicing)
        item.saleContractInvoicing = "False";
      if (!item.purchaseContractInvoicing)
        item.purchaseContractInvoicing = "False";
    });
  }
  return {
    canMarkCompleteForSelectedMovementId: state.companies.freights.canMarkCompleteForSelectedMovementId,
    clearSearch: getPaginatedContractsResponse,
    columns: customColumns,
    globalSearch: true,
    currentUser: state.main.user.user,
    items: freightItems,
    optionsItems: (item) => getFreightMovementActionMenuOptions(item, subItems, clickedOption, isPackMovement),
    paginationData: state.companies.freights.paginationData,
    rejectLoadForMovementId: state.companies.freights.rejectLoadForMovementId,
    rowHighlightedMap: { isHighlighted: 'delhi-blue' },
    scrollToTopOnUpdate: false,
    selectedFreightMovement: state.companies.freights.confirmedOrRejectedContract,
    selectedPackMovement: state.companies.freights.selectedFreightMovement,
    selectedFreightMovementId: state.companies.freights.selectedFreightMovementId,
    shouldFetchMovement: state.companies.freights.shouldFetchMovement,
    subItems: subItems,
    clickedOption: clickedOption,
    useNestedOptionMenu: true,
    voidableSelectedFreightMovementId: state.companies.freights.voidableSelectedFreightMovementId,
    assignToContractOrFreightOrderId: state.companies.freights.assignToContractOrFreightOrderId,
    isLoading: state.main.isLoading,
    userToken: state.main.user.token,
    voidFilter: true,
    displayIDColumn: 'identifier'
  };
};

const mapDispatchToProps = (dispatch, that) => {
  const { dontRedirect } = that;
  return {
    handleOptionClick: (event, item, baseEntity) => {
      if(get(item, 'key') === 'regenerate_pdf')
        regenerateMovementPDF(baseEntity);
      else
        dispatch(handleFreightMovementOptionsMapper(event, item, baseEntity));
    },
    handleDefaultCellClick: item => {
      dispatch(getSelectedFreight(item.id, receiveFreight, false, false, false, false));
      dispatch(isLoading('movementDetail'));
      if (dontRedirect) {
        document.location.hash = `${getAbsoluteUrl(document.location.hash)}?movementId=${item.id}`;
      } else {
        document.location.hash = '/freights/movements/' + item.id + '/details';
      }
    },
    navigateTo: (url, params) => {
      const order_id = get(params, 'order_id', '');
      const contract_id = get(params, 'contract_id', '');
      dispatch(getContracts(order_id, contract_id, url, true, true));
    },
    changePageSize: (url, pageSize) => {
      if (includes(url, '?')) {
        url = `${url}&page_size=${pageSize}`;
      } else {
        url = `${url}?page_size=${pageSize}`;
      }
      dispatch(getContracts('', '', url, true, true));
    },
    getSearchSortUrl: (pageSize, page, searchText, orderBy, order, pathname, includeVoid) => {
      let Api;
      if (pathname.startsWith('/farms/')) {
        const _path = pathname.replace('/farms/', '');
        Api = APIService.farms(_path.slice(0, _path.indexOf('/')));
        Api.appendToUrl('freight-movements/search/');
      } else {
        Api = APIService.freights().contracts();
        Api.appendToUrl('web/search/');
      }
      Api.appendToUrl(`?page_size=${pageSize}`);
      if (page) {
        Api.appendToUrl(`&page=${page}`);
      }
      if (searchText) {
        Api.appendToUrl(`&search=${searchText}`);
      }
      if (orderBy) {
        Api.appendToUrl(`&order_by=${orderBy}&order=${order}`);
      }
      if (includeVoid) {
        Api.appendToUrl(`&include_void=${includeVoid}`);
      }

      return Api.URL;
    },
    getCustomColumns: tableType => dispatch(getCustomColumns(tableType)),
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PackMovementsTable);
