import React from 'react';
import { connect } from 'react-redux';
import {
  Accordion, AccordionSummary, AccordionDetails
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material/';
import { get, map, isEmpty, without, includes, has, uniq, set, find, sumBy, isEqual, mapValues, orderBy, keys } from 'lodash';
import SideDrawer from '../common/SideDrawer';
import CreateSystemStorage from "../../containers/CreateSystemStorage";
import GenericTable from '../GenericTable';
import { currentUser } from '../../common/utils';
import {
  getCompanyOwnershipStocksColumnsByCommodity, getOptionItems, numberFormatterByValue
} from './utils';
import { MT_UNIT } from '../../common/constants';

class CompanyOwnershipStocks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: [],
      unit: currentUser()?.unit,
      stocks: [],
    };
  }

  getCacheContainerKey = () => this.props.isOtherStocksView ? 'otherStocksView' : 'ownershipView';

  getSortedStock = () => {
    const { stocks } = this.props
    delete stocks['requestedUnit'];
    const totalTonnage = mapValues(stocks, items => sumBy(items, 'tonnage'));
    const sortedKeys = orderBy(keys(totalTonnage), key => totalTonnage[key], ['desc']);
    return sortedKeys.map(key => [key, stocks[key]])
  }

  componentDidMount() {
    const cache = JSON.parse(get(localStorage, 'stocks', '{}'));
    const key = this.getCacheContainerKey();
    if(has(cache, key))
      this.setState({expanded: cache[key].expanded || []});
    else
      this.updateCache();
  }

  componentDidUpdate(prevProps) {
    const cacheValuesForUpdate = {}
    if(prevProps.locationType !== this.props.locationType)
      cacheValuesForUpdate["location"] = this.props.locationType
    if(prevProps.filteredNgrs !== this.props.filteredNgrs)
      cacheValuesForUpdate["ngrIds"] = this.props.filteredNgrs
    if(cacheValuesForUpdate)
      this.updateCache(cacheValuesForUpdate)
    if(
      !isEqual(this.props.stocks, prevProps.stocks) ||
      (Object.keys(this.props.stocks).length > 0 && this.state.stocks.length == 0)
    )
      this.setState({stocks: this.getSortedStock()})
  }

  updateCache = data => {
    const cache = JSON.parse(get(localStorage, 'stocks', '{}'));
    const key = this.getCacheContainerKey()
    set(cache, `${key}.expanded` ,this.state.expanded);
    if(data)
      set(cache, key, {...get(cache, key), ...data})
    localStorage.setItem('stocks', JSON.stringify(cache));
  };

  toggle = (event, expanded, commodityId) => {
    event.persist();
    const { locationType, owner } = this.props;
    const key = `${locationType}-${commodityId}-${owner}`;
    let newExpanded = expanded ? [...this.state.expanded, key] : without(this.state.expanded, key);
    this.setState({expanded: uniq(newExpanded)}, this.updateCache);
  };

  render() {
    const {
      isEmptyStoragesSelected, handleOptionClick, handleDefaultCellClick, locationType,
      currentLocation, isAddSystemStorageSideDrawerOpened, onSystemStorageCloseSideDraw,
      onSystemStorageAdd, owner, commodities, locations
    } = this.props;
    const { stocks } = this.state
    const unit = this.state.unit || MT_UNIT;
    const isOwnership = this.props.activeTab == '/stocks/ownership-view'
    return (
      <div style={{minHeight: '1000px'}}>
        {
          (isEmpty(stocks) && !this.props.isLoading && owner && locationType) ?
            <Accordion defaultExpanded expanded className="stocks-container" style={{margin: '0 0 10px 0', display: 'inline-block', width: '100%'}}>
              <AccordionSummary expandIcon={<span />} className="stock-panel">
                <div className='col-xs-12 stocks-summary' style={{margin: 'auto', padding: '0'}}>
                  <h5 className="header-title" style={{display: 'inline-block', margin: '0 10px 0 0'}}>
                    There are no current stocks for this owner.
                  </h5>
                </div>
              </AccordionSummary>
            </Accordion> :
          (
            !isEmptyStoragesSelected && map(stocks, stock => {
              const commodityId = stock[0]
              const details = stock[1]
              const key = `${locationType}-${commodityId}-${owner}`;
              const commodity = find(commodities, {id: parseInt(commodityId)})
              const commodityName = commodity?.displayName
              const commodityTonnage = sumBy(details, 'tonnage')
              const columns = getCompanyOwnershipStocksColumnsByCommodity(commodity, locations, commodities, unit)
              return (
                <Accordion
                  key={key}
                  expanded={includes(this.state.expanded, key)}
                  className="stocks-container"
                  style={{margin: '0 0 10px 0', display: 'inline-block', width: '100%'}}
                  onChange={
                    (event, expanded) => this.toggle(event, expanded, commodityId)
                  }>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} className="stock-panel">
                    <div className='col-xs-12 stocks-summary' style={{margin: 'auto', padding: '0'}}>
                      <h3 className="header-title" style={{display: 'inline-block', margin: '0 10px 0 0'}}>
                        {commodityName}
                      </h3>
                    </div>
                    <div className="card-header-content col-xs-2">
                      <div className="card-header-item">
                        <label>Total</label>
                        {numberFormatterByValue({tonnage: commodityTonnage}, 'tonnage', `${unit}`)}
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails style={{padding: '0 0 20px 0', maxHeight: '380px', overflow: 'scroll'}}>
                    <GenericTable
                      mainContainerStyle={{margin: '0 15px', width: '100%'}}
                      columns={columns}
                      items={details}
                      optionsItems={getOptionItems(isOwnership, false, this.props.isOtherStocksView)}
                      handleOptionClick={handleOptionClick}
                      handleDefaultCellClick={handleDefaultCellClick}
                      optionsColumnNumber={13}
                      orderBy="tonnage"
                      order="desc"
                    />
                  </AccordionDetails>
                </Accordion>
              );
            })
          )
        }
        {
          isAddSystemStorageSideDrawerOpened &&
            <SideDrawer
              isOpen={isAddSystemStorageSideDrawerOpened}
              title="Add Stock"
              size="medium"
              onClose={onSystemStorageCloseSideDraw}
              app="storage"
            >
              <CreateSystemStorage
                closeDrawer={onSystemStorageCloseSideDraw}
                farmId={locationType}
                companyId={get(currentLocation, 'companyId')}
                afterSubmit={onSystemStorageAdd}
              />
            </SideDrawer>
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.main.isLoading
  };
};

export default connect(mapStateToProps)(CompanyOwnershipStocks);
