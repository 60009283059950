import React from 'react';
import { connect } from 'react-redux';
import Tooltip from '@mui/material/Tooltip'
import GenericTable from '../components/GenericTable';
import { clickEditCompanyNgrIcon } from '../actions/companies/company-ngrs';
import { isCurrentUserGrower, isSystemCompany, toDateFormat } from '../common/utils';
import { showSideDrawerForTransfer, showSideDrawerForMerge } from '../actions/companies/index';
import { forEach, map, startCase, isEmpty, orderBy, isArray } from 'lodash';
import VerifiedIcon from '@mui/icons-material/VerifiedUser';

const ngrNumberIcon = ngr => {
  if(ngr?.isVerified)
    return (
      <Tooltip title={`Verified On: ${ngr.verifiedOnForDisplay}`}>
        <VerifiedIcon fontSize='small' color='primary'/>
      </Tooltip>
    )
  return null
}

const mapStateToProps = state => {
  const columns = [
    { header: '', formatter: ngrNumberIcon, className: 'xxxsmall', sortable: false, align: 'center'},
    { key: 'ngrNumber', header: 'NGR Number'},
    { key: 'ngrType', header: 'NGR Type' },
    { key: 'bankAccounts[0].accountDisplayName', header: 'Primary Shareholder' },
    { key: 'bankAccounts[0].shareholderPercent', header: '%' },
    { key: 'bankAccounts[1].accountDisplayName', header: 'Shareholder 2' },
    { key: 'bankAccounts[1].shareholderPercent', header: '%' },
    { key: 'verifiedOnForDisplay', header: 'Verified on' },
    { key: 'tags', header: 'Defaults', formatter: item => isEmpty(item.tags) ? '-'  : map(item.tags, startCase).join(', ') },
  ];

  if (isCurrentUserGrower()) {
    columns.push({ key: 'farm.name', header: 'Farm' });
  }
  let items = [];
  if (isArray(state.companies.companyNgrs.items)) {
    items = forEach(state.companies.companyNgrs.items, item => {
      if (typeof item === 'object') {
        item.bankAccounts = orderBy(item.bankAccounts, 'shareholderPercent', 'desc')
        if(item.isVerified) {
          item.verifiedText = 'Verified';
          item.verifiedOnForDisplay = toDateFormat(item.verifiedOn)
        } else {
          item.verifiedText = 'Not verified';
        }
        return item;
      }
    })
  }
  let config = {
    columns: columns,
    items: items,
    scrollToTopOnUpdate: false,
    orderBy: 'ngrNumber',
    order: 'asc',
    displayIDColumn: 'ngrNumber'
  };
  if (isSystemCompany())
    config.optionsItems = [{ key: 'transfer', text: 'Transfer' }];

  if (isSystemCompany())
    config.optionsItems.push({ key : 'merge', text: 'Merge' });

  return config;
};

const mapDispatchToProps = dispatch => {
  return {
    handleDefaultCellClick: item => dispatch(clickEditCompanyNgrIcon(item.id)),
    handleOptionClick: (index, key, id, item) => {
      if (key === 'transfer') {
        dispatch(showSideDrawerForTransfer(item, true));
      }
      if (key === 'merge') {
        dispatch(showSideDrawerForMerge(item, true));
      }
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GenericTable);
