import '@babel/polyfill';
import React from 'react';
import { connect } from 'react-redux';
import alertifyjs from 'alertifyjs';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Tooltip from '@mui/material/Tooltip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CollapseIcon from '@mui/icons-material/VerticalAlignCenter'
import ExpandIcon from '@mui/icons-material/Expand';
import ListItemText from '@mui/material/ListItemText';
import { Autocomplete, TextField, Button } from '@mui/material';
import {get, find, set, size, keys, values, every, map, isEmpty, filter, pickBy, sumBy, isNumber, without } from "lodash";
import APIService from '../../../services/APIService'
import {isLoading, forceStopLoader} from '../../../actions/main';
import { METER_CUBE_UNIT } from '../../../common/constants'
import { toFloatFromString , toDateFormat, getCountryLabel, currentUser } from '../../../common/utils';
import Number from '../../../services/Number'
import InvoiceTotalSection from './InvoiceTotalSection';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AdjustedPrices from '../AdjustedPrices';
import QuantityAdjustments from '../QuantityAdjustments';
import SideDrawer from '../../common/SideDrawer';

const EXPANDED_COL_BG_COLOR = 'rgb(255, 255, 226)'

class InvoiceItemsNewView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandLevy: false,
      user: currentUser(),
      xero: {},
      isSettingUpXeroMappings: false,
      selectedItem: undefined,
      showAdjustmentsSideBar: false,
      showQuantityAdjustments: false,
      collapsed: [],
      items: []
    };
  }

  toggleCollapsedItems = itemName => this.setState({collapsed: this.state.collapsed.includes(itemName) ? without(this.state.collapsed, itemName) : [...this.state.collapsed, itemName]})

  getTitle() {
    let header = null;
    const type = get(this.props, 'invoiceDetails.type');
    switch (type) {
    case 'Commodity Contract' :
      header = 'Commodity Contract: ';
      break;
    case 'Brokerage':
      header = 'Items To Be Invoiced';
      break;
    case 'Freight' :
      header = 'Freight Order : ';
      break;
    case 'WarehouseFee' :
      header = 'Invoiced Items:';
      break;
    }
    const number = this.props.invoiceDetails.raisedForNo || this.props.invoiceDetails.identifier;
    if(type !== 'Brokerage' && type !== 'WarehouseFee')
      header += number;
    return header;
  }

  isOthers(title) {
    return title === 'Others';
  }

  setXeroField = (item, value, xeroField, isChemicalApplicationItem) => {
    const newState = {...this.state}
    const xeroStateId = isChemicalApplicationItem ? item.itemId : item.invoiceItemId
    set(newState.xero, `${xeroStateId}.${xeroField}`, value)
    if (isChemicalApplicationItem) {
      set(newState.xero, `${xeroStateId}.isChemicalApplicationItem`, true);
      set(newState.xero, `${xeroStateId}.chemicalAppliedOnLoadId`, item.chemicalAppliedOnLoadId);
      set(newState.xero, `${xeroStateId}.freightMovementId`, item.freightMovementId);
    }
    this.setState(newState)
  }

  getItemTypeForMapping = name => {
    if(name.toLowerCase().includes('movement'))
      return 'movements'
    if(name.toLowerCase().includes('title'))
      return 'title_transfers'
    if(name.toLowerCase().includes('levies'))
      return 'levy'
    if(name.toLowerCase().includes('epr'))
      return 'epr'
    if(name.toLowerCase().includes('carry'))
      return 'carry'
    if(name.toLowerCase().includes('inloads'))
      return 'inload_fees'
    if(name.toLowerCase().includes('outloads'))
      return 'outload_fees'
    if(name.toLowerCase().includes('transfers'))
      return 'transfer_fees'
    if(name.toLowerCase().includes('storages'))
      return 'storage_fees'
    if(name.toLowerCase().includes('stock_swaps'))
      return 'stock_swap'
    if(name.toLowerCase().includes('regrade_reseasons'))
      return 'regrade_reseason'
    if(name.toLowerCase().includes('custom'))
      return 'custom'
    if(name.toLowerCase().includes('blending_fees'))
      return 'blending_fees'
    if(name.toLowerCase().includes('chemical_applications'))
      return 'chemical_applications'
  }

  getMappingsForItem = name => {
    const itemType = this.getItemTypeForMapping(name)
    return filter(this.props.xeroMappings, mapping => !mapping.itemType || mapping.itemType === itemType)
  }

  getBestMatchedMappingForItem = (itemName, item=null) => {
    const itemType = this.getItemTypeForMapping(itemName)
    const mappings = this.getMappingsForItem(itemName)
    let { commodityId, gradeId, season, siteId } = this.props.invoiceDetails;
    const parameters = {
      commodityId: commodityId || get(item, 'commodityId'),
      gradeId: gradeId || get(item, 'gradeId'),
      season: season || get(item, 'season'),
      siteId: siteId || get(item, 'siteId'),
      itemType: itemType,
    };
    const keys = Object.keys(parameters);
    const allCombinations = [];

    for (let i = 0; i < (1 << keys.length); i++) {
      const combinationDict = {};
      for (let j = 0; j < keys.length; j++) {
        combinationDict[keys[j]] = (i & (1 << j)) ? parameters[keys[j]] : null;
      }

      if (combinationDict.commodity_id === null && combinationDict.grade_id !== null) {
        continue;
      }

      allCombinations.push(combinationDict);
    }

    allCombinations.sort((a, b) => {
      const countNonNull = (obj) => {
        let count = 0;
        for (const value of Object.values(obj)) {
          if (value !== null) count++;
        }
        return count;
      };
      return countNonNull(b) - countNonNull(a);
    });

    for (let criteria of allCombinations) {
      const filterCriteria = {};
      for (let [key, value] of Object.entries(criteria)) {
        if (value !== null) {
          filterCriteria[key] = value;
        } else {
          filterCriteria[key] = null;
        }
      }

      const bestMatch = mappings.find(mapping => {
        return Object.entries(filterCriteria).every(([key, value]) => {
          if (value) {
            return (mapping[key] === value || mapping[key] === null)
          }
          else{
            return mapping[key] === null;
          }
        });
      });
      if (bestMatch) {
        return bestMatch;
      }
    }
    return null;
  }

  setUpXeroMapping = () => {
    const {
      freightContracts, titleTransfers, carry, grainLevies, eprs, others, isCommodityContractInvoice,
      warehouseInloads, warehouseOutloads, warehouseStockSwaps, warehouseRegradeReseasons,
      warehouseStorages, isWarehouseInvoice, warehouseThroughputInloads, warehouseThroughputOutloads, isFreightInvoice, loads,
      blendedGradeMovements, chemicalApplicationItems,
    } = this.props.invoiceDetails;
    if(isCommodityContractInvoice) {
      const newState = {...this.state}
      const _setup = (items, itemName) => {
        if(!isEmpty(items)) {
          items.forEach(item => {
            let bestMatch = this.getBestMatchedMappingForItem(itemName, item)
            const xeroStateId = item.chemicalAppliedOnLoadId ? item.itemId : item.invoiceItemId
            if(itemName === 'chemical_applications'){
              if (item.chemicalAppliedOnLoadId) {
                set(newState.xero, `${xeroStateId}.isChemicalApplicationItem`, true);
                set(newState.xero, `${xeroStateId}.chemicalAppliedOnLoadId`, item.chemicalAppliedOnLoadId);
                set(newState.xero, `${xeroStateId}.freightMovementId`, item.freightMovementId);
                bestMatch = this.getBestMatchedMappingForItem(itemName, item);
              }
            }
            if (bestMatch){
              set(newState.xero, `${xeroStateId}.accountCode`, bestMatch.xeroAccount)
              set(newState.xero, `${xeroStateId}.itemCode`, bestMatch.xeroItemCode)
            }
          });
        }
      }
      _setup(freightContracts, 'movement')
      _setup(titleTransfers, 'title_transfers')
      _setup(carry, 'carry')
      _setup(grainLevies, 'levies')
      _setup(eprs, 'epr')
      _setup(others, 'custom')
      _setup(loads, 'title_transfers')
      _setup(blendedGradeMovements, 'blending_fees')
      _setup(chemicalApplicationItems, 'chemical_applications')
      newState.isSettingUpXeroMappings = false
      this.setState(newState)
    }
    if (isFreightInvoice) {
      const newState = {...this.state}
      const _setup = (items, itemName) => {
        if(!isEmpty(items)) {
          const bestMatch = this.getBestMatchedMappingForItem(itemName)
          if(!isEmpty(bestMatch))
            items.forEach(item => {
              set(newState.xero, `${item.invoiceItemId}.accountCode`, bestMatch.xeroAccount)
              set(newState.xero, `${item.invoiceItemId}.itemCode`, bestMatch.xeroItemCode)
            });
        }
      }
      _setup(freightContracts, 'movement')
      _setup(others, 'custom')
      newState.isSettingUpXeroMappings = false
      this.setState(newState)
    }
    if (isWarehouseInvoice) {
      const newState = {...this.state};
      const _setup = (items, itemName) => {
        if (!isEmpty(items)) {
          items.forEach(item => {
            const bestMatch = this.getBestMatchedMappingForItem(itemName, item);
            if (!isEmpty(bestMatch)) {
              set(newState.xero, `${item.invoiceItemId}.accountCode`, bestMatch.xeroAccount);
              set(newState.xero, `${item.invoiceItemId}.itemCode`, bestMatch.xeroItemCode);
            }
          });
        }
      }
      _setup(warehouseInloads, 'inloads')
      _setup(warehouseOutloads, 'outloads')
      _setup(warehouseThroughputInloads, 'throughput_inloads')
      _setup(warehouseThroughputOutloads, 'throughput_outloads')
      _setup(warehouseStockSwaps, 'stock_swaps')
      _setup(warehouseRegradeReseasons, 'regrade_reseasons')
      _setup(warehouseStorages, 'storages')
      newState.isSettingUpXeroMappings = false
      this.setState(newState)
    }
  }

  componentDidMount() {
    const { invoiceDetails } = this.props
    if(invoiceDetails?.id) {
      const items = this.getItems()
      this.setState({items: items, collapsed: items?.length > 1 ? map(items, 'id') : []})
    }
  }

  componentDidUpdate(prevProps) {
    if(!isEmpty(this.props.xeroMappings) && !this.state.isSettingUpXeroMappings && isEmpty(prevProps.xeroMappings)) {
      this.setState({isSettingUpXeroMappings: true}, this.setUpXeroMapping)
    }
    const { invoiceDetails } = this.props
    if(invoiceDetails?.id && prevProps?.invoiceDetails?.id !== invoiceDetails?.id) {
      const items = this.getItems()
      this.setState({items: items, collapsed: items?.length > 1 ? map(items, 'id') : []})
    }
  }

  showAdjustments(item) {
    this.setState({selectedItem: item, showAdjustments: true, showQuantityAdjustments: false});
  }

  showQuantityAdjustments = item => this.setState({selectedItem: item, showQuantityAdjustments: true, showAdjustments: false})

  getQuantitySpecAdjustments = item => pickBy(item?.adjustments?.specs, val => val.quantity)

  getNumFromCurrencyValue = value => new Number(value).number

  getInvoiceItemDetails = obj => {
    const { items, title, id, position } = obj;
    const { invoiceDetails } = this.props
    const { grainLevies, eprs, carry } = invoiceDetails
    const isPeriodicBrokerageFees = title === 'Periodic Brokerage Fees';
    const isTitleTransfers = title === 'Title Transfers'
    const isMovements = title === 'Movements'
    const isChemicalApplications = title === 'Applications';
    const { isStrictQuantityBasedCommodity } = get(this.props, 'invoiceDetails');
    const isExpanded = !this.state.collapsed.includes(id)
    const sx={zIndex: position + 2,  backgroundColor: '#f7f7f7', top: '45px', position: 'sticky'}
    let itemsLevies = []
    let itemsEprs = []
    let itemsCarry = []
    if(isMovements) {
      itemsLevies = filter(grainLevies, {itemType: 'freightcontract'})
      itemsEprs = filter(eprs, {itemType: 'freightcontract'})
      itemsCarry = filter(carry, {itemType: 'freightcontract'})
    }
    else if(isTitleTransfers) {
      itemsLevies = filter(grainLevies, {itemType: 'titletransfer'})
      itemsEprs = filter(eprs, {itemType: 'titletransfer'})
      itemsCarry = filter(eprs, {itemType: 'titletransfer'})
    }
    const levyTotal = new Number(sumBy(itemsLevies, item => new Number(get(item, 'total')).number))
    const eprTotalExGst = new Number(sumBy(itemsEprs, item => new Number(get(item, 'totalExGst')).number))
    const eprGst = new Number(sumBy(itemsEprs, item => new Number(get(item, 'gst')).number))
    const eprTotal = new Number(sumBy(itemsEprs, item => new Number(get(item, 'total')).number))
    const carryTotalExGst = new Number(sumBy(itemsCarry, item => new Number(get(item, 'totalExGst')).number))
    const carryGst = new Number(sumBy(itemsCarry, item => new Number(get(item, 'gst')).number))
    const carryTotal = new Number(sumBy(itemsCarry, item => new Number(get(item, 'total')).number))
    return (
      <React.Fragment>
        <TableRow sx={{cursor: 'pointer', background: '#f7f7f7' }} onClick={() => this.toggleCollapsedItems(id)}>
          <TableCell align='right' sx={{...sx}}>
            {isExpanded ? <ExpandLessIcon fontSize='small' /> : <ExpandMoreIcon fontSize='small' />}
          </TableCell>
          {
            this.props.xeroMode &&
              <TableCell colSpan={2} sx={{...sx}} />
          }
          <TableCell sx={{...sx}}><b>{`${title} (${items.length.toLocaleString()})`}</b></TableCell>
          <TableCell align="right" sx={{fontWeight: 'bold', padding: '4px', fontSize: '12px', width: '90px', ...sx}}>
            {
              ['Title Transfers', 'Movements', 'Grain Levies', 'EPRs', 'Others'].includes(title) &&
                `${new Number(sumBy(items, item => new Number(item.tonnage).number)).formatted}`
            }
          </TableCell>
          <TableCell align='right' sx={{width: '90px', ...sx}}>-</TableCell>
          <TableCell align='right' sx={{fontWeight: 'bold', padding: '4px', fontSize: '12px', ...sx}}>
            {
              carryTotalExGst.formatted
            }
          </TableCell>
          <TableCell align='right' sx={{fontWeight: 'bold', padding: '4px', fontSize: '12px', ...sx}}>
            {
              eprTotalExGst.formatted
            }
          </TableCell>
          <TableCell align="right" sx={{fontWeight: 'bold', padding: '4px', fontSize: '12px', width: '130px', ...sx}}>
            {new Number(sumBy(items, item => this.getNumFromCurrencyValue(item.totalExGst)) + carryTotalExGst.number + eprTotalExGst.number).formatted}
          </TableCell>
          {
            this.state.expandLevy ?
              <>

                <TableCell align='right' sx={{fontWeight: 'bold', padding: '4px', fontSize: '12px', ...sx, backgroundColor: EXPANDED_COL_BG_COLOR}}>
                  {
                    new Number(sumBy(filter(itemsLevies, {type: 'daff'}), levy => new Number(levy.total).number), null, null, "*").formatted
                  }
                </TableCell>
                <TableCell align='right' sx={{fontWeight: 'bold', padding: '4px', fontSize: '12px', ...sx, backgroundColor: EXPANDED_COL_BG_COLOR}}>
                  {
                    new Number(sumBy(filter(itemsLevies, {type: 'fis'}), levy => new Number(levy.total).number), null, null, "*").formatted
                  }
                </TableCell>
                <TableCell align='right' sx={{fontWeight: 'bold', padding: '4px', fontSize: '12px', ...sx, backgroundColor: EXPANDED_COL_BG_COLOR}}>
                  {
                    new Number(sumBy(filter(itemsLevies, {type: 'gif'}), levy => new Number(levy.total).number), null, null, "*").formatted
                  }
                </TableCell>
                <TableCell align='right' sx={{fontWeight: 'bold', padding: '4px', fontSize: '12px', ...sx, backgroundColor: EXPANDED_COL_BG_COLOR}}>
                  {
                    new Number(sumBy(filter(itemsLevies, {type: 'girdf'}), levy => new Number(levy.total).number), null, null, "*").formatted
                  }
                </TableCell>
              </> :
            <TableCell align='right' sx={{fontWeight: 'bold', padding: '4px', fontSize: '12px', ...sx}}>
              {
                new Number(levyTotal.number, null, null, "*").formatted
              }
            </TableCell>
          }
          <TableCell align="right" sx={{fontWeight: 'bold', padding: '4px', fontSize: '12px', width: '100px', ...sx}}>
            {new Number(sumBy(items, item => this.getNumFromCurrencyValue(item.gst || 0)) + carryGst.number + eprGst.number).formatted}
          </TableCell>
          <TableCell align="right" width="8%" sx={{fontWeight: 'bold', padding: '4px', fontSize: '12px', width: '130px', ...sx}}>
            {new Number(sumBy(items, item => this.getNumFromCurrencyValue(item.total)) + carryTotal.number + eprTotal.number + levyTotal.number).formatted}
          </TableCell>
        </TableRow>
        {
          items.map((item, index) => {
            let isTitleTransferHasCanolaLoads =  false;
            if(isTitleTransfers)
              isTitleTransferHasCanolaLoads = Boolean(find(get(this.props.invoiceDetails, 'canolaLoads'), { id: get(item, 'itemId') }))
            const xeroStateId = isChemicalApplications ? item.itemId : item.invoiceItemId;
            const xeroState = get(this.state.xero, `${xeroStateId}`)
            let tonnage = !item.tonnage ? 'N/A' : item.tonnage === 'N/A' ? item.tonnage : isStrictQuantityBasedCommodity ? item.tonnage.slice(0, -3) ? (toFloatFromString(item.tonnage.slice(0, -3)) + ' ' + (item?.commodity?.unit || item.quantityUnit || METER_CUBE_UNIT)) : "0.00" : toFloatFromString(item.tonnage) || "0.00";
            let itemLevies = (isMovements || isTitleTransfers) ? filter(this.props.invoiceDetails.grainLevies, {itemForId: item.itemId, itemType: isMovements ? 'freightcontract' : 'titletransfer' }) : []
            const itemDaffLevy = find(itemLevies, {type: 'daff'})
            const itemFisLevy = find(itemLevies, {type: 'fis'})
            const itemGifLevy = find(itemLevies, {type: 'gif'})
            const itemGirdfLevy = find(itemLevies, {type: 'girdf'})
            const levyTotal = sumBy(itemLevies, levy => new Number(levy.total).number)
            const epr = find(this.props.invoiceDetails.eprs, {itemForId: item.itemId, itemType: isMovements ? 'freightcontract' : 'titletransfer' })
            const carry = find(this.props.invoiceDetails.carry, {itemForId: item.itemId, itemType: isMovements ? 'freightcontract' : 'titletransfer' })
            const hasQuantityAdjustments = !isEmpty(this.getQuantitySpecAdjustments(item))
            const parts = item.description.split(',')
            const primaryDesc = parts[0].trim()
            const secondaryDesc = parts.slice(1).join(',').trim()
            const total = levyTotal + new Number(item.total).number + new Number(epr?.total).number + new Number(carry?.total).number;
            const gst = new Number(item.gst).number + new Number(epr?.gst).number + new Number(carry?.gst).number
            const totalExGst = new Number(item.totalExGst).number + new Number(epr?.totalExGst).number  + new Number(carry?.totalExGst).number
            const rate = new Number(
              this.isOthers(title) || isPeriodicBrokerageFees || (isTitleTransfers && isTitleTransferHasCanolaLoads) ?
                "N/A" :
                isStrictQuantityBasedCommodity ?
                item.price.slice(0, -5) ?
                item.price.slice(0, -5) + ' / ' + (item?.commodity?.unit || item?.quantityUnit || METER_CUBE_UNIT) :
                0 :
              item.price
            )
            const isAdjustedRate = item.contractPrice != rate.number && isNumber(rate.number)
            if(isAdjustedRate) {
              rate.recompute()
            }
            return (
              <TableRow key={index} className={'invoice-item-row' + (items?.length === 1 ? ' single-row' : '')} sx={{display: isExpanded ? 'table-row' : 'none'}}>
                {
                  this.props.xeroMode &&
                    <React.Fragment>
                      <TableCell>
                        <Autocomplete
                          disableClearable
                          size='small'
                          disablePortal
                          id="xero-item-code"
                          options={this.props.xeroItems}
                          value={xeroState?.itemCode ? find(this.props.xeroItems, {code: xeroState.itemCode}) : undefined}
                          getOptionLabel={option => option.name}
                          renderInput={
                            params => <TextField size='small' {...params} label="Xero Item" />
                          }
                          onChange={(event, value) => this.setXeroField(item, value.code, 'itemCode', isChemicalApplications)}
                        />
                      </TableCell>
                      <TableCell>
                        <Autocomplete
                          disableClearable
                          size='small'
                          disablePortal
                          id="xero-item-account"
                          value={xeroState?.accountCode ? find(this.props.xeroAccounts, {code: xeroState.accountCode}) : undefined}
                          isOptionEqualToValue={(option, value) => option.code === value.code}
                          options={this.props.xeroAccounts}
                          getOptionLabel={option => option.name}
                          renderInput={
                            params => <TextField size='small' {...params} label="Xero Account" />
                          }
                          onChange={(event, value) => this.setXeroField(item, value.code, 'accountCode', isChemicalApplications)}
                        />
                      </TableCell>
                    </React.Fragment>
                }
                <TableCell>
                  {
                    toDateFormat(item.datetime || item.date)
                  }
                </TableCell>
                <TableCell align="left" sx={{width: '200px'}}>
                  <ListItemText
                    primary={isMovements ? <a href={`#/freights/movements/${item.itemId}/details`} target='_blank' rel="noreferrer noopener">{primaryDesc}</a> : primaryDesc}
                    secondary={secondaryDesc}
                    sx={{margin: 0, '.MuiListItemText-primary': {fontSize: '13px', fontWeight: '500'}, '.MuiListItemText-secondary': {fontSize: '11px'}}}
                  />
                </TableCell>
                <TableCell align="right" onClick={() => hasQuantityAdjustments ? this.showQuantityAdjustments(item) : {}} sx={hasQuantityAdjustments ? {cursor: 'pointer', width: '90px'} : {width: '90px'}}>
                  {
                    hasQuantityAdjustments &&
                      <InfoOutlinedIcon sx={{marginRight: '4px',  fontSize: '1.15rem', color: 'rgba(0, 0, 0, 0.54)'}} />
                  }
                  { new Number(tonnage).formatted }
                </TableCell>
                <TableCell onClick={() => this.showAdjustments(item)} align="right" sx={{width: '90px', cursor: 'pointer'}}>
                  <span style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                    <InfoOutlinedIcon sx={{marginRight: '4px', fontSize: '1.15rem', color: 'rgba(0, 0, 0, 0.54)'}} />
                    {
                      rate.formatted
                    }
                  </span>
                </TableCell>
                <TableCell align="right" onClick={() => this.showAdjustments(carry)} sx={{cursor: 'pointer', width: '100px'}}>
                  <span style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                    {
                      carry?.itemForId &&
                        <InfoOutlinedIcon sx={{marginRight: '4px', fontSize: '1.15rem', color: 'rgba(0, 0, 0, 0.54)'}} />
                    }
                    {new Number(get(carry, 'totalExGst')).formatted}
                  </span>
                </TableCell>
                <TableCell align="right" onClick={() => this.showAdjustments(epr)} sx={{cursor: 'pointer', width: '100px'}}>
                  <span style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                    {
                      epr?.itemForId &&
                        <InfoOutlinedIcon sx={{marginRight: '4px', fontSize: '1.15rem', color: 'rgba(0, 0, 0, 0.54)'}} />
                    }
                    {new Number(get(epr, 'totalExGst')).formatted}
                  </span>
                </TableCell>
                <TableCell align="right" sx={{width: '130px'}}>
                  {
                    new Number(totalExGst).formatted
                  }
                </TableCell>
                {
                  this.state.expandLevy ?
                    <>
                      <TableCell align="right" onClick={() => this.showAdjustments(itemDaffLevy)} sx={{cursor: 'pointer', width: '100px', backgroundColor: EXPANDED_COL_BG_COLOR}}>
                        <span style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                          {
                            itemDaffLevy?.itemForId &&
                              <InfoOutlinedIcon sx={{marginRight: '4px', fontSize: '1.15rem', color: 'rgba(0, 0, 0, 0.54)'}} />
                          }
                          {new Number(get(itemDaffLevy, 'total'), null, null, "*").formatted}
                        </span>
                      </TableCell>
                      <TableCell align="right" onClick={() => this.showAdjustments(itemFisLevy)} sx={{cursor: 'pointer', width: '100px', backgroundColor: EXPANDED_COL_BG_COLOR}}>
                        <span style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                          {
                            itemFisLevy?.itemForId &&
                              <InfoOutlinedIcon sx={{marginRight: '4px', fontSize: '1.15rem', color: 'rgba(0, 0, 0, 0.54)'}} />
                          }
                          {new Number(get(itemFisLevy, 'total'), null, null, "*").formatted}
                        </span>
                      </TableCell>
                      <TableCell align="right" onClick={() => this.showAdjustments(itemGifLevy)} sx={{cursor: 'pointer', width: '100px', backgroundColor: EXPANDED_COL_BG_COLOR}}>
                        <span style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                          {
                            itemGifLevy?.itemForId &&
                              <InfoOutlinedIcon sx={{marginRight: '4px', fontSize: '1.15rem', color: 'rgba(0, 0, 0, 0.54)'}} />
                          }
                          {new Number(get(itemGifLevy, 'total'), null, null, "*").formatted}
                        </span>
                      </TableCell>
                      <TableCell align="right" onClick={() => this.showAdjustments(itemGirdfLevy)} sx={{cursor: 'pointer', width: '100px', backgroundColor: EXPANDED_COL_BG_COLOR}}>
                        <span style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                          {
                            itemGirdfLevy?.itemForId &&
                              <InfoOutlinedIcon sx={{marginRight: '4px', fontSize: '1.15rem', color: 'rgba(0, 0, 0, 0.54)'}} />
                          }
                          {new Number(get(itemGirdfLevy, 'total'), null, null, "*").formatted}
                        </span>
                      </TableCell>
                    </> :
                  <TableCell align="right" onClick={() => this.showAdjustments(itemGirdfLevy)} sx={{cursor: 'pointer', width: '100px'}}>
                    {new Number(levyTotal, null, null, "*").formatted}
                  </TableCell>

                }
                <TableCell align="right" sx={{width: '100px'}}>
                  {
                    new Number(gst).formatted
                  }
                </TableCell>
                <TableCell align="right" sx={{width: '130px'}}>
                  {
                    new Number(total, null, null, this.shouldShowGSTMarkForOtherItemOrGrainLevies(item, title)).formatted
                  }
                </TableCell>
              </TableRow>
            );
          })
        }
      </React.Fragment>
    );
  }

  shouldShowGSTNotApplicableTextForOtherItems(items, title) {
    const { currency } = this.props.invoiceDetails;
    return title === 'Grain Levies' || (title === 'Others' && find(items, {gst: `${currency} 0.00`}));
  }

  shouldShowGSTMarkForOtherItemOrGrainLevies(item, title) {
    const { currency } = this.props.invoiceDetails;
    return title === 'Grain Levies' || ( title === 'Others' && get(item, 'gst') === `${currency} 0.00`);
  }


  onCreateClick = event => {
    event.preventDefault()
    event.stopPropagation()
    const {
      freightContracts, titleTransfers, carry, grainLevies, eprs, others, contractItems, warehouseTransfers,
      subscriptionItems, warehouseInloads, warehouseOutloads, warehouseThroughputInloads, warehouseThroughputOutloads,
      warehouseStorages, warehouseStockSwaps, warehouseRegradeReseasons, blendedGradeLoads, blendedGradeMovements, chemicalApplicationItems, loads
    } = this.props.invoiceDetails;
    const { xero } = this.state
    const totalItems = size(freightContracts) + size(titleTransfers) + size(carry) + size(grainLevies) + size(eprs) + size(others) + size(contractItems) + size(warehouseStorages)
          + size(subscriptionItems) + size(warehouseOutloads) + size(warehouseInloads) + size(warehouseThroughputInloads) + size(warehouseThroughputOutloads)
          + size(warehouseTransfers) + size(warehouseStockSwaps) + size(warehouseRegradeReseasons) + size(blendedGradeLoads) + size(blendedGradeMovements) + size(chemicalApplicationItems) + size(loads)
    if(keys(xero).length === totalItems && every(values(xero), val => Boolean(val.accountCode))) {
      this.props.dispatch(isLoading('createXeroInvoice'))
      const items = map(xero, (info, itemId) => {
        const property = get(info, 'isChemicalApplicationItem') ? 'itemId': 'invoiceItemId';
        return {[property]: itemId, ...info}
      });
      APIService.invoices(this.props.invoiceDetails.id).appendToUrl('xero/').post({invoiceItems: items}).then(response => {
        this.props.dispatch(forceStopLoader())
        if(get(response, 'result.invoices[0].invoiceID'))
          alertifyjs.success('Successfully created Invoice in Xero. Reload...', 1, () => window.location.reload())
        else if(get(response, 'result.creditNotes[0].creditNoteID'))
          alertifyjs.success('Successfully created Credit Note in Xero. Reload...', 1, () => window.location.reload())
        else {
          const errorMessage = get(response, 'result.elements[0].validationErrors[0].message')
          alertifyjs.error(errorMessage || 'An Error Occurred!', 5)
        }
      })
    } else {
      alertifyjs.error('Please select account for each Invoice Item.')
    }
  }

  getItems = () => {
    const {
      freightContracts, titleTransfers, others, loads, blendedGradeLoads,
      blendedGradeMovements, chemicalApplicationItems
    } = this.props.invoiceDetails;
    let items = [
      {items: freightContracts, title: 'Movements', id: 'movements'},
      {items: titleTransfers, title: 'Title Transfers', id: 'titleTransfers'},
      {items: blendedGradeLoads, title: 'Movements', id: 'blendedGradeLoads'},
      {items: blendedGradeMovements, title: 'Blending Fee', id: 'blendedGradeMovements'},
      {items: chemicalApplicationItems, title: 'Applications', id: 'applications'},
      {items: loads, title: 'Canola Loads', id: 'loads'},
      {items: others, title: 'Others', id: 'others'}
    ]
    let newItems = []
    let position = 0
    items.forEach(item => {
      if(item.items?.length > 0){
        position += 1
        newItems.push({...item, position: position})
      }
    })
    return newItems
  }

  render() {
    const { invoiceDetails } = this.props
    const {
      raisedForUnit, requestedUnit, currency, isStrictQuantityBasedCommodity, totalDetails
    } = invoiceDetails;
    const { items } = this.state
    const unit = requestedUnit || raisedForUnit
    const { expandLevy } = this.state
    const gstLabel = getCountryLabel('gst')
    const colIcon = expandLevy ? <CollapseIcon fontSize='small' sx={{transform: 'rotate(90deg)', color: 'rgba(0, 0, 0, 0.6)', marginLeft: '6px'}}/> : <ExpandIcon fontSize='small' sx={{transform: 'rotate(90deg)', color: 'rgba(0, 0, 0, 0.6)', marginLeft: '6px'}} />
    const bottomStyle = {zIndex: 10,  backgroundColor: 'rgb(210, 231, 194)	', bottom: 0, position: 'sticky', fontSize: '16px !important', fontWeight: 'bold'}

    return (
      <Accordion defaultExpanded sx={{boxShadow: 'none', margin: '16px 0'}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{fontWeight: 'bold', marginTop: '-16px !important'}}>
          {this.getTitle()}
          {
            this.props.xeroMode &&
              <Button variant='contained' size='small' style={{marginLeft: '25px'}} onClick={this.onCreateClick}>Create Xero Invoice</Button>
          }
        </AccordionSummary>

        <AccordionDetails style={{padding: '0px', marginTop: '-10px'}}>
          <div style={{overflow: 'auto', padding: '0 16px'}}>
            <TableContainer id='invoice-items-table-container' sx={{ maxHeight: 550 }}>
              <Table stickyHeader id='invoice-items-table' sx={{'.MuiTableCell-root': {padding: '6px 8px !important', fontSize: '13px'}, width: '100%'}}>
                <TableHead sx={{'.MuiTableCell-root.MuiTableCell-head': {fontSize: '12px'}}}>
                  <TableRow>
                    {
                      this.props.xeroMode &&
                        <React.Fragment>
                          <TableCell>Xero Item Code</TableCell>
                          <TableCell>Xero Account Code</TableCell>
                        </React.Fragment>
                    }
                    <TableCell width="100px">Date</TableCell>
                    <TableCell sx={{width: '200px'}}>Description</TableCell>
                    <TableCell align="right" sx={{width: '90px'}}>
                      <ListItemText
                        primary={isStrictQuantityBasedCommodity ? 'Quantity' : getCountryLabel('tonnage')}
                        secondary={unit}
                        sx={{margin: 0, '.MuiListItemText-primary': {fontSize: '12px', fontWeight: '500'}, '.MuiListItemText-secondary': {fontSize: '11px'}}}
                      />
                    </TableCell>
                    <TableCell align="right" width="100px" sx={{width: '90px'}}>
                      <ListItemText
                        primary='Rate'
                        secondary={`${currency}/${unit}`}
                        sx={{margin: 0, '.MuiListItemText-primary': {fontSize: '12px', fontWeight: '500'}, '.MuiListItemText-secondary': {fontSize: '11px'}}}
                      />
                    </TableCell>
                    <TableCell align="right" width="100px">
                      <ListItemText
                        primary='Carry'
                        secondary={currency}
                        sx={{margin: 0, '.MuiListItemText-primary': {fontSize: '12px', fontWeight: '500'}, '.MuiListItemText-secondary': {fontSize: '11px'}}}
                      />
                    </TableCell>
                    <TableCell align="right" width="100px">
                      <ListItemText
                        primary='EPR'
                        secondary={currency}
                        sx={{margin: 0, '.MuiListItemText-primary': {fontSize: '12px', fontWeight: '500'}, '.MuiListItemText-secondary': {fontSize: '11px'}}}
                      />
                    </TableCell>
                    <TableCell align="right" sx={{width: '130px'}}>
                      <Tooltip title={`Tonnage x Rate/${unit} + Carry - EPR`} placement='top'>
                        <ListItemText
                          primary={`Price (Ex ${gstLabel})`}
                          secondary={currency}
                          sx={{margin: 0, '.MuiListItemText-primary': {fontSize: '12px', fontWeight: '500'}, '.MuiListItemText-secondary': {fontSize: '11px'}}}
                        />
                      </Tooltip>
                    </TableCell>
                    {
                      expandLevy ?
                        <>

                          <TableCell align="right" width="100px" sx={{backgroundColor: EXPANDED_COL_BG_COLOR}}>
                            <ListItemText
                              primary='DAFF Levy'
                              secondary={currency}
                              sx={{margin: 0, '.MuiListItemText-primary': {fontSize: '12px', fontWeight: '500'}, '.MuiListItemText-secondary': {fontSize: '11px'}}}
                            />
                          </TableCell>
                          <TableCell align="right" width="100px" sx={{backgroundColor: EXPANDED_COL_BG_COLOR}}>
                            <ListItemText
                              primary='IFS Levy'
                              secondary={currency}
                              sx={{margin: 0, '.MuiListItemText-primary': {fontSize: '12px', fontWeight: '500'}, '.MuiListItemText-secondary': {fontSize: '11px'}}}
                            />
                          </TableCell>
                          <TableCell align="right" width="100px" sx={{backgroundColor: EXPANDED_COL_BG_COLOR}}>
                            <ListItemText
                              primary='GIF Levy'
                              secondary={currency}
                              sx={{margin: 0, '.MuiListItemText-primary': {fontSize: '12px', fontWeight: '500'}, '.MuiListItemText-secondary': {fontSize: '11px'}}}
                            />
                          </TableCell>
                          <TableCell align="right" width="100px" sx={{cursor: 'pointer', backgroundColor: EXPANDED_COL_BG_COLOR}} onClick={() => this.setState({expandLevy: false})}>
                            <ListItemText
                              primary={
                                <span style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                                  GIRDF Levy
                                  {colIcon}
                                </span>
                              }
                              secondary={currency}
                              sx={{margin: 0, '.MuiListItemText-primary': {fontSize: '12px', fontWeight: '500'}, '.MuiListItemText-secondary': {fontSize: '11px', paddingRight: '25px'}}}
                            />
                          </TableCell>
                        </> :
                      <TableCell align="right" width="100px" sx={{cursor: 'pointer'}} onClick={() => this.setState({expandLevy: true})}>
                        <ListItemText
                          primary={
                            <span style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                              Levy
                              {colIcon}
                            </span>
                          }
                          secondary={currency}
                          sx={{margin: 0, '.MuiListItemText-primary': {fontSize: '12px', fontWeight: '500'}, '.MuiListItemText-secondary': {fontSize: '11px', paddingRight: '25px'}}}
                        />
                      </TableCell>
                    }
                    <TableCell align="right" sx={{width: '100px'}}>
                      <ListItemText
                        primary={gstLabel}
                        secondary={currency}
                        sx={{margin: 0, '.MuiListItemText-primary': {fontSize: '12px', fontWeight: '500'}, '.MuiListItemText-secondary': {fontSize: '11px'}}}
                      />
                    </TableCell>
                    <TableCell align="right" sx={{width: '130px' }}>
                      <Tooltip title="Price (Ex GST) + Levy + GST" placement='top'>
                        <ListItemText
                          primary={`Total (Inc ${gstLabel})`}
                          secondary={currency}
                          sx={{margin: 0, '.MuiListItemText-primary': {fontSize: '12px', fontWeight: '500'}, '.MuiListItemText-secondary': {fontSize: '11px'}}}
                        />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{'.MuiTableCell-root': {textOverflow: 'initial !important'}}}>
                  {
                    map(items, (item, index) => (
                      <React.Fragment key={index}>{this.getInvoiceItemDetails(item)}</React.Fragment>
                    ))
                  }
                  <TableRow sx={{background: '#f7f7f7' }}>
                    <TableCell sx={{...bottomStyle}} />
                    {
                      this.props.xeroMode &&
                        <TableCell colSpan={2} sx={{...bottomStyle}} />
                    }
                    <TableCell sx={{...bottomStyle}}><b>Total</b></TableCell>
                    <TableCell align="right" sx={{width: '90px', ...bottomStyle}}>
                      {
                        new Number(invoiceDetails?.fmTtLoadTonnage).formatted
                      }
                    </TableCell>
                    <TableCell align='right' sx={{width: '90px', ...bottomStyle}}>-</TableCell>
                    <TableCell align='right' sx={{...bottomStyle}}>
                      {
                        new Number(totalDetails?.carryCharges).formatted
                      }
                    </TableCell>
                    <TableCell align='right' sx={{...bottomStyle}}>
                      {
                        new Number(totalDetails?.eprExGst).formatted
                      }
                    </TableCell>
                    <TableCell align="right" sx={{width: '130px', ...bottomStyle}}>
                      {
                        new Number(totalDetails?.totalCommodityPrice).formatted
                      }
                    </TableCell>
                    <TableCell colSpan={this.state.expandLevy ? 4 : undefined} align={this.state.expandLevy ? "center" : "right"} sx={{width: '130px', ...bottomStyle}}>
                      {
                        new Number(totalDetails?.grainLevies, null, null, '*').formatted
                      }
                    </TableCell>
                    <TableCell align="right" sx={{width: '100px', ...bottomStyle}}>
                      {
                        new Number(totalDetails?.gst).formatted
                      }
                    </TableCell>
                    <TableCell align="right" width="8%" sx={{width: '130px', ...bottomStyle}}>
                      {
                        new Number(totalDetails?.total).formatted
                      }
                    </TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </TableContainer>
            {
              this.state.showAdjustments &&
                <SideDrawer
                  isOpen
                  title='Rate Breakdown'
                  onClose={() => this.setState({showAdjustments: false, selectedItem: undefined})}
                  size="big"
                >
                  <AdjustedPrices
                    unit={raisedForUnit}
                    item={get(this.state.selectedItem, 'adjustments')}
                    closeDrawer={() => this.setState({showAdjustments: false, selectedItem: undefined})}
                    detailsView
                    currency={currency}
                  />
                </SideDrawer>
            }
            {
              this.state.showQuantityAdjustments &&
                <SideDrawer
                  isOpen
                  title='Quantity Adjustments'
                  onClose={() => this.setState({showQuantityAdjustments: false, selectedItem: undefined})}
                  size="big"
                >
                  <QuantityAdjustments
                    unit={raisedForUnit}
                    item={this.state.selectedItem}
                    closeDrawer={() => this.setState({showQuantityAdjustments: false, selectedItem: undefined})}
                    detailsView
                  />
                </SideDrawer>
            }
          </div>
          <InvoiceTotalSection {...this.props} invoiceDetails={this.props.invoiceDetails} expansionFalse={false} newView/>
        </AccordionDetails>
      </Accordion>
    );
  }
}
export default connect()(InvoiceItemsNewView);
