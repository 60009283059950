import React from 'react';
import jwt from 'jwt-simple';
import { currentUser } from '../../common/utils'

/* eslint-disable spellcheck/spell-checker */
/*eslint no-undef: 0*/
/*eslint no-process-env: 0*/

const DasMap = ({ field }) => {
  const claims = {
    iss: window.location.origin, // Issuer
    sub: currentUser().username,          // Subject
    aud: process.env.API_URL,  // Audience
    exp: Math.floor(Date.now() / 1000) + 3600, // Expiration Time (1 hour)
    iat: Math.floor(Date.now() / 1000),
  }

  // cat das_private_key.pem | base64 | tr -d '\n' --> on MAC
  // base64 -w 0 das_private_key.pem --> on linux
  // base64 is the value for DAS_PRIVATE_KEY

  const token = jwt.encode(claims, atob(process.env.DAS_PRIVATE_KEY), 'RS256')

  return (
    <das-map
    id="das-map"
    height="400px"
    width="100%"
    zoom="13"
    embedfarmid={field.id.toString()}
    farmname={field.name}
    clientid={field?.companyId?.toString()}
    address={field?.address?.address || ''}
    lat={field?.address?.latitude?.toString() || ''}
    lng={field?.address?.longitude?.toString() || ''}
    enablesearchbar="true"
    enablesavebtn="true"
    enablekml="true"
    enablepaddock="false"
    jwttoken={token}
    />
  )
}

export default DasMap;
