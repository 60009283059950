import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  required,
  maxLength,
  phoneMobileRegex,
  emailRegex, minLength
} from '../../common/validators';
import {forceStopLoader, isLoading, loaded} from "../../actions/main";
import {ERROR, PASSWORDS_DO_NOT_MATCH} from '../../common/constants';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import get from 'lodash/get';
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import CommonTextField from '../common/CommonTextField';
import '../../common/forms.scss';
import Checkbox from "@mui/material/Checkbox/Checkbox";
import Button from "@mui/material/Button/Button";
import CommonButton from "../common/CommonButton";
import alertifyjs from "alertifyjs";
import APIService from "../../services/APIService";
import {BLACK} from "../../common/constants";
import PasswordValidIndicator from './PasswordValidIndicator';
import PhoneField from '../common/PhoneField';
import { addEmployeeErrors } from '../../actions/company-settings/employees';
import { transferCompanyAsset } from '../../actions/api/companies';
import { addCompanyNgr } from '../../actions/companies/company-ngrs';
import { Tooltip } from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material'
import includes from 'lodash/includes';


const SIGN_UP_COMPLETE_TEXT = "You have successfully signed up to the AgriChain platform. " +
                              "Once the company admin has approved your request you will receive an email and be able to login";
const UNREG_GROWER_SIGN_UP_COMPLETE_TEXT = (
  <span>
    Sign up is now complete, welcome to <strong>AgriChain</strong>, the platform trusted by <strong>300+</strong> Growers, Traders, and BHCs.
  </span>
);

class EmployeeSignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
      typeId: 1,
      abn: undefined,
      existingEmployeeId: undefined,
      isPasswordValid: false,
      generatingUsername: false,
      fields: {
        title: {
          value: '',
          validators: [],
          errors: []
        },
        firstName: {
          value: '',
          validators: [required(), maxLength(100)],
          errors: []
        },
        lastName: {
          value: '',
          validators: [required(), maxLength(100)],
          errors: []
        },
        mobile: {
          value: '',
          validators: [phoneMobileRegex(null)],
          errors: []
        },
        email: {
          value: '',
          validators: [emailRegex(), maxLength(100)],
          errors: []
        },
        username: {
          value: '',
          validators: [required(), minLength(3)],
          errors: []
        },
        password: {
          value: '',
          validators: [required()],
          errors: []
        },
        confirmPassword: {
          value: '',
          validators: [required()],
          errors: []
        }
      }
    };
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.setServerErrors = this.setServerErrors.bind(this);
    this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
    this.isPasswordValid = this.isPasswordValid.bind(this);
  }

  componentDidMount() {
    const { selectedEmployee } = this.props;
    
    if(selectedEmployee) this.copyValuesFromSelectedEmployee(selectedEmployee);
    else this.copyValuesFromProps();
  }

  componentDidUpdate(prevProps) {
    if (this.props.serverErrors !== prevProps.serverErrors) {
      this.setServerErrors();
    }
  }

  copyValuesFromSelectedEmployee(selectedEmployee) {
    if(!selectedEmployee)
      return;
    const newState = {...this.state};
    newState.isChecked = get(this.props.values, 'isChecked', newState.isChecked);
    forEach(newState.fields, (field, key) => {
      field.value = get(selectedEmployee, key, field.value);
      field.validators = [];
    });
    this.setState(newState);
  }

  copyValuesFromProps = () => {
    const newState = this.state;
    newState.isChecked = get(this.props.values, 'isChecked', newState.isChecked);
    forEach(newState.fields, (field, key) => {
      field.value = get(this.props.values, key, field.value);
    });
    this.setState(newState);
  };

  onBlur(event) {
    this.setFieldErrors(event.target.id);
    if(includes(['lastName', 'mobile', 'email'], event.target.id) && !this.props.selectedEmployee)
      this.setState({generatingUsername: true}, () => this.setUsername())
  }

  setUsername = () => {
    let queryParams = '';

    if(this.state.fields.username.value && !this.props.selectedEmployee){
      const trimmedUsername = this.state.fields.username.value.split(' ').join('');
      if(trimmedUsername !== this.state.fields.username.value){
        this.setFieldValue('username', trimmedUsername);
        return
      }
    }

    if (this.state.fields.mobile.value)
      queryParams += `&mobile=${this.state.fields.mobile.value}`;
    if (this.state.fields.email.value)
      queryParams += `&email=${this.state.fields.email.value}`;
    APIService.profiles()
    .appendToUrl(`generate-username/?first_name=${this.state.fields.firstName.value}&last_name=${this.state.fields.lastName.value}${queryParams}`)
    .get()
    .then(response => {
      let username = response?.username
      if (username && !this.props.selectedEmployee)
        username = username.split(' ').join('');
      this.setFieldValue('username', username)
    });
  }

  isPasswordValid(valid) {
    if(valid !== this.state.isPasswordValid)
      this.setState({isPasswordValid: valid});
  }

  handleFieldChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
  }

  checkPasswordsMatch() {
    const newState = {...this.state};
    if (newState.fields.password.value && newState.fields.confirmPassword.value){
      if(newState.fields.password.value !== newState.fields.confirmPassword.value){
        newState.fields.confirmPassword.errors = [PASSWORDS_DO_NOT_MATCH];
      } else {
        newState.fields.confirmPassword.errors = [];
      }
      this.setState(newState);
    }
  }

  setFieldValue(key, value) {
    if(key == 'username')
      value = value.split(' ').join('');

    this.setState(state => ({
      ...state,
      fields: {
        ...state.fields,
        [key]: {
          ...state.fields[key],
          value: value,
        }
      }
    }), () => {
      this.setFieldErrors(key)
      if (this.state.generatingUsername)
        this.setState({generatingUsername: false})
    });
  }

  getFieldErrors(key) {
    const errors = [];
    const value = this.state.fields[key].value;
    const validators = this.state.fields[key].validators;
    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    return errors;
  }

  setFieldErrors(key) {
    this.setState(state => ({
      ...state,
      fields: {
        ...state.fields,
        [key]: {
          ...state.fields[key],
          errors: this.getFieldErrors(key)
        }
      }
    }), () => this.checkPasswordsMatch());
  }

  setServerErrors = async () => {
    return new Promise(resolve => {
      const newState = { ...this.state };
      forEach(this.props.serverErrors, (value, key) => {
        newState.fields[key].errors = value;
      });

      this.setState(newState, () => {
        this.checkPasswordsMatch();
        resolve();
      });
    });
  };

  setAllFieldsErrors = async () => {
    return new Promise(resolve => {
      const newState = { ...this.state };
      forEach(newState.fields, (value, key) => {
        newState.fields[key].errors = this.getFieldErrors(key);
      });

      this.setState(newState, () => {
        this.checkPasswordsMatch();
        resolve();
      });
    });
  };

  handleCheckboxChange = (event, isChecked) => {
    this.setState({isChecked});
  };

  handleContinue = async (event) => {
    event.preventDefault();
    await this.setAllFieldsErrors();
    this.props.isLiteSignUp ? await this.verifyLiteForm() : await this.verifyForm();
    this.handleSubmit();
  };

  shouldAddEmployee() {
    return (!this.props.isLiteSignUp && !this.props.isExistingCompanyRegistered && !this.props.selectedEmployee);
  }

  updateEmployee = async (data) => {
    const { selectedEmployee, dispatch } = this.props;
    if(!selectedEmployee)
      return;

    delete data['confirmPassword'];
    delete data['isChecked'];
    if(!this.props.isLiteSignUp && !this.props.companyResponse.typeId === 1)
      data['keepUnregistered'] = true;
    dispatch(isLoading('registerSuccess'));
    if(this.props.ngrNumber)
      data['companyFromNgr'] = true;
    APIService.companies().appendToUrl(`unregistered/${selectedEmployee.companyId}/employees/${selectedEmployee.id}/`).put(data).then(async response => {
      dispatch(loaded());
      if(isEmpty(response.errors))
        this.signupSuccess(response);
      else
        alertifyjs.error("Something went wrong!");
    });
  };

  addEmployee = async (data) => {
    const { dispatch, companyResponse } = this.props;
    const companyId = get(companyResponse, 'companyId') || get(companyResponse, 'id');
    if(companyId) {
      delete data['confirmPassword'];
      delete data['isChecked'];
      if(!this.props.isLiteSignUp && !this.props.companyResponse.typeId === 1)
        data['keepUnregistered'] = true;
      dispatch(isLoading('registerSuccess'));
      if(this.props.ngrNumber)
        data['companyFromNgr'] = true;
      APIService.companies().appendToUrl(`unregistered/${companyId}/employees/`).post(data).then(async response => {
        dispatch(loaded());
        if (response.id == null || response.errors){
          dispatch(forceStopLoader());
          dispatch(addEmployeeErrors(response.errors));
          this.setServerErrors();
        }
        else if (response.id)
          this.signupSuccess(response);
        else
          alertifyjs.error("Something went wrong!");
      });
    }
  };

  signupSuccess(response) {
    this.setState({registerSuccess: true}, () => {
      const { dispatch, companyResponse } = this.props;
      let companyId = get(companyResponse, 'companyId') || get(companyResponse, 'id');
      if (this.props.transferNgrId && companyId)
        dispatch(transferCompanyAsset(companyId, {asset_id: this.props.transferNgrId, asset: 'ngr', include_parties: false}, false));
      else if (this.props.shouldCreateNgr && companyId && this.props.ngrNumber) {
        let ngrData = {
          "bankAccounts": [{
            "accountName": "",
            "accountNumber": "",
            "bsbNumber": "",
            "companyId": "",
            "isPrimary": true,
            "shareholderPercent": 100
          }],
          "isVerified": false,
          "ngrType": "single",
          "ngrNumber": this.props.ngrNumber,
          "shareAuthorityFile": [],
          "shareAuthorityUrl": []
        }
        APIService.companies(companyId)
          .ngrs()
          .post(ngrData, this.props.token)
          .then(item => {
            if (item?.id == null)
              this.props.dispatch(forceStopLoader());
            else {
              this.props.dispatch(addCompanyNgr(item));
              alertifyjs.success("NGR was successfully added.");
              this.props.dispatch(loaded());
            }
          });
          dispatch(addCompanyNgr(companyId, {ngr_number: this.props.ngrNumber}));
      }
      if(this.props.isLiteSignUp)
        this.props.submit(this.getSubmitData(), this.props.nextStep);
      else if(this.props.companyResponse.typeId === 1 || response?.typeId == 1) {
        const formData = {
          username: this.state.fields.username.value,
          password: this.state.fields.password.value,
        }
        const tonnage = this.props.companyResponse.totalTonnage
        this.props.completeSignUp("Congratulations!", UNREG_GROWER_SIGN_UP_COMPLETE_TEXT, tonnage, true, formData);
      }
      else {
        this.props.nextStep();
        this.props.completeSignUp("Sign Up Complete", SIGN_UP_COMPLETE_TEXT);
      }
    });
  }

  handleSubmit = () => {
    if(this.isFormValid()) {
      if (this.state.isChecked) {
        if (this.props.getRegisteredDetails){
          this.props.getRegisteredDetails(this.state.abn, this.state.existingEmployeeId);
        }
        const data = this.getSubmitData();
        if(this.shouldAddEmployee())
          this.addEmployee(data);
        else if (this.props.selectedEmployee)
          this.updateEmployee(data);
        else {
          this.props.submit(data);
          this.props.nextStep();
        }
      } else if (!this.state.isChecked) {
        alertifyjs.alert("Terms & Conditions", "Please view and accept the Terms & Conditions and Privacy Policy", () => {});
      }
    }
  };

  getSubmitData = (isVerifying=false) => {
    let submitData = {isChecked: this.state.isChecked, typeId: this.state.typeId};
    forEach(this.state.fields, (field, key) => {
      submitData[key] = field.value;
    });
    if(isVerifying && this.props.selectedEmployee?.id)
      submitData["id"] = this.props.selectedEmployee.id
    return {...submitData};
  };

  isFormValid = () => {
    return !some(this.state.fields, (field) => !isEmpty(field.errors)) && this.state.isPasswordValid;
  };

  verifyForm = async () => {
    if(!this.state.isPasswordValid)
      return;
    return new Promise(resolve => {
      let dataToBeVerified = this.getSubmitData(true);
      delete dataToBeVerified['confirmPassword'];
      delete dataToBeVerified['isChecked'];

      let profiles = APIService.profiles();
      profiles.appendToUrl('verify').post(dataToBeVerified).then(response => {
        if (!isEmpty(response.errors)){
          const newState = {...this.state};
          const errors = response.errors;
          forEach(errors, (value, key) => newState.fields[key].errors = value);
          this.setState(newState,() => resolve());
        } else {
          resolve();
        }
      });
    });
  };

  verifyLiteForm = async () => {
    if(!this.state.isPasswordValid)
      return;
    return new Promise(resolve => {
      let dataToBeVerified = this.getSubmitData();
      delete dataToBeVerified['confirmPassword'];
      delete dataToBeVerified['isChecked'];

      let profiles = APIService.profiles();
      profiles.appendToUrl('verify-lite').post(dataToBeVerified).then(response => {
        if (!isEmpty(response.errors)){
          const newState = {...this.state};
          const errors = response.errors;
          forEach(errors, (value, key) => newState.fields[key].errors = value);
          if(get(response, 'isRegistered', false) === true){
            newState.abn = null;
            newState.existingEmployeeId = null;
          }
          else {
            newState.existingEmployeeId = get(response, 'id');
            newState.abn = get(response, 'abn');
          }
          this.setState(newState,() => resolve());
        } else {
          resolve();
        }
      });
    });
  };

  navigateToLogin = (event) => {
    event.preventDefault();
    document.location.hash = '/';
  };

  render() {
    return (
      <div ref={this.formRef}>
        <form onSubmit={this.handleSubmit} noValidate>
          <div className="sign-up-form-height">
            <div className="row" style={{height: '100%'}}>

              <div className='col-sm-6'>
                <div className="form-wrap-70 sign-up-field">
                  <CommonTextField
                    id="firstName"
                    label="First Name"
                    placeholder="Please enter"
                    value={this.state.fields.firstName.value}
                    onChange={this.handleFieldChange}
                    onBlur={this.onBlur}
                    helperText={this.state.fields.firstName.errors[0]}
                    maxLength="100"
                  />
                </div>

                <div className="form-wrap-70 sign-up-field">
                  <CommonTextField
                    id="lastName"
                    label="Last Name"
                    placeholder="Please enter"
                    value={this.state.fields.lastName.value}
                    onChange={this.handleFieldChange}
                    onBlur={this.onBlur}
                    helperText={this.state.fields.lastName.errors[0]}
                    maxLength="100"
                  />
                </div>

                <div className="form-wrap-70 sign-up-field">
                  <CommonTextField
                    id="title"
                    label="Job Title"
                    placeholder="Please enter Title (optional)"
                    value={this.state.fields.title.value}
                    onChange={this.handleFieldChange}
                    onBlur={this.onBlur}
                    helperText={this.state.fields.title.errors[0]}
                    fullWidth
                    maxLength="100"
                  />
                </div>

                <div className="form-wrap-70 sign-up-field">
                  <PhoneField
                    id="mobile"
                    label="Phone/Mobile"
                    value={this.state.fields.mobile.value}
                    onChange={this.handleFieldChange}
                    onBlur={this.onBlur}
                    helperText={this.state.fields.mobile.errors[0]}
                    countryCode={localStorage.current_country}
                  />
                </div>
              </div>

              <div className='col-sm-6'>
                <div className="form-wrap-70 sign-up-field sign-up-right-field">
                  <CommonTextField
                    id="email"
                    type="email"
                    label="Email"
                    placeholder="Please enter"
                    onBlur={this.onBlur}
                    value={this.state.fields.email.value}
                    onChange={this.handleFieldChange}
                    helperText={this.state.fields.email.errors[0]}
                  />
                </div>
                <div className="form-wrap-70 sign-up-field sign-up-right-field">
                  <CommonTextField
                    id="username"
                    type="username"
                    label={
                    <div>
                      Username <Tooltip title={"Please note that username is case sensitive"} arrow>
                        <InfoIcon fontSize='small' style={{fontSize: '1rem', color: 'rgba(0, 0, 0, 0.5)'}} />
                      </Tooltip>
                    </div>
                    }
                    placeholder="Please enter"
                    onBlur={this.onBlur}
                    value={this.state.fields.username.value}
                    onChange={this.handleFieldChange}
                    helperText={this.state.fields.username.errors[0]}
                  />
                </div>
                <div className="form-wrap-70 sign-up-field sign-up-right-field">
                  <CommonTextField
                    id="password"
                    label="Password"
                    placeholder="Please enter"
                    value={this.state.fields.password.value}
                    onChange={this.handleFieldChange}
                    onBlur={this.onBlur}
                    helperText={this.state.fields.password.errors[0]}
                    type='password'
                  />
                </div>
                <div className="form-wrap-70 sign-up-field sign-up-right-field">
                  <CommonTextField
                    id="confirmPassword"
                    label="Confirm Password"
                    placeholder="Please enter"
                    value={this.state.fields.confirmPassword.value}
                    onChange={this.handleFieldChange}
                    onBlur={this.onBlur}
                    helperText={this.state.fields.confirmPassword.errors[0]}
                    type='password'
                  />
                </div>

                <PasswordValidIndicator
                  mainContainerStyle={{fontSize: '12px', marginTop: '-10px'}}
                  indicatorContainerStyle={{marginTop: '2px'}}
                  password={this.state.fields.password.value}
                  onChange={this.isPasswordValid}
                />
                <div style={{marginTop: '40px'}}>
                  {!this.state.isPasswordValid && this.state.fields.confirmPassword.value && this.state.fields.password.value &&
                    <span style={{ color: ERROR }}>Please ensure that the password matches all the given criteria</span>
                  }
                </div>

                <div className="form-wrap-70 sign-up-field sign-up-right-field" style={this.state.isPasswordValid ? {marginTop: '55px'} : { marginTop: '10px' }}>
                  <div className="row">
                    <Checkbox
                        id="checkbox"
                        checked={this.state.isChecked}
                        onChange={this.handleCheckboxChange}
                    />
                    <span style={{fontSize: '0.8em'}}>
                      I agree to the AgriChain <a href="#/web/terms-and-conditions" target="_blank">Terms & Conditions</a> and <a href="https://www.iubenda.com/privacy-policy/38052771" title="Privacy Policy" target='_blank' rel="noopener noreferrer">Privacy Policy</a>
                    </span>
                  </div>
                </div>
              </div>

              <div className="signup-content-box--footer col-sm-12">
                <div className='col-sm-6 left-text-align padding-reset fixed-bottom' style={{zIndex: '100'}}>
                  {
                    this.props.showBack &&
                    <Button
                      variant="text"
                      type="button"
                      color="primary"
                      style={{color: BLACK}}
                      onClick={this.props.previousStep}
                      >
                      <KeyboardArrowLeft style={{padding: '1px 0px'}}/>{this.props.isLiteSignUp ? 'Go To Login' : 'Back'}
                    </Button>
                  }
                </div>
                <div className='col-sm-6 padding-reset fixed-bottom' style={{'textAlign': 'right', width: '97%'}}>
                  <CommonButton
                    type="submit"
                    label={this.props.isFetchedFromNgr ? "Finish" : "Continue"}
                    variant="contained"
                    primary={true}
                    className="login-button"
                    onClick={this.handleContinue}
                    style={{width: '30%'}}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.main.user.token,
    serverErrors: state.companySettings.employees.serverErrors,
  };
};

export default connect(mapStateToProps)(EmployeeSignUpForm);
