import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import InputAdornment from '@mui/material/InputAdornment';
import { required, minLength, emailRegex, maxLength } from '../../common/validators';
import CommonButton from '../common/CommonButton';
import WorkIcon from '@mui/icons-material/Work';
import { forceStopLoader, logout } from '../../actions/main';
import CommonTextField from '../common/CommonTextField';
import { apiURL } from '../../actions/main';
import { Button, List, ListItemButton, ListItemIcon, ListItemText} from '@mui/material';
import CommonSelect from '../common/select/CommonSelect';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import './forgotUsername.scss';
import { KeyboardArrowLeft } from '@mui/icons-material';
import APIService from  '../../services/APIService';
import some from 'lodash/some';

const ForgotUsername = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    identifier: {
      value: null,
      type: null,
      validators: [required('This field can not be blank'), minLength(3)],
      errors: []
    },
    email: {
      value: null,
      validators: [emailRegex(), maxLength(100)],
      errors: []
    },
  });
  const [isABNFieldVisible, setIsABNFieldVisible] = useState(true);
  const [isRetrieveButtonVisible, setIsRetrieveButtonVisible] = useState(true);
  const [showSubscribedCompanyMessage, setShowSubscribedCompanyMessage] = useState(false);
  const [showUnregisteredCompanyMessage, setShowUnregisteredCompanyMessage] = useState(false);
  const [showCompaniesDropdown, setShowCompaniesDropdown] = useState(false);
  const [foundCompanies, setFoundCompanies] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [foundEmployees, setFoundEmployees] = useState([]);
  const [showEmployeeList, setShowEmployeeList] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [fillEmail, setFillEmail] = useState(false)
  const [forgotUsernameSuccess, setForgotUsernameSuccess] = useState(false);
  const [showSupportText, setShowSupportText] = useState(false)

  useEffect(() => {
    dispatch(forceStopLoader());
  }, []);

  const retrieveCompany = (event) => {
    event.preventDefault();
    const identifier = state.identifier.value
    const pathParam = `lookup/${identifier}/`

    const isInvalid = some(state.identifier.validators, (validator) => validator.isInvalid(identifier));
    setFieldErrors("identifier")
    if(isInvalid)
      return

    APIService.companies().appendToUrl(pathParam).get(null, null, {})
      .then((companies) => {
        if (companies.length > 1) {
          setFoundCompanies(companies);
          setShowCompaniesDropdown(true);
          setIsRetrieveButtonVisible(false);
          let identifierType = 'NGR';
          setState({ identifier: { ...state.identifier, type: identifierType } })
        } else if (companies.length == 1) {
          const company = companies[0];
          if (company.transactionParticipation) {
            setShowSubscribedCompanyMessage(true);
            setIsABNFieldVisible(false);
            setIsRetrieveButtonVisible(false);
            setFoundCompanies(companies);
            setSelectedCompanyId(company.id);
          } else if (!company.isRegistered) {
            setShowUnregisteredCompanyMessage(true);
            setIsABNFieldVisible(false);
            setIsRetrieveButtonVisible(false);
          }
          else {
            setFoundCompanies(companies);
            setSelectedCompanyId(company.id);
            let identifierType = 'NGR';
            if (state.identifier.value === company.abn)
              identifierType = 'ABN';
            setState({ identifier: { ...state.identifier, type: identifierType } })
            setShowCompaniesDropdown(true);
            setIsRetrieveButtonVisible(false)
          }
        } else {
          setShowUnregisteredCompanyMessage(true);
          setIsABNFieldVisible(false);
          setIsRetrieveButtonVisible(false);
        }
        clearFieldErrors("identifier")
      });
  };

  const fetchUserList = () => {
    const data = {email: state.email.value}

    APIService.companies().appendToUrl(
      `${selectedCompanyId}/employees/names/`
      ).post(data)
      .then((employees) => {
        setFoundEmployees(employees);
        setSelectedEmployee(employees[0]);
        setShowEmployeeList(true);
      });
  }

  const handleChange = (event) => {
    const value = event.target.value;
    const field = event.target.id;
    setState(prevState => ({
      ...prevState,
      [field]: {
        ...prevState[field],
        value: value
      }
    }));
  };

  const redirectToLoginPage = () => {
    dispatch(logout());
    window.location.hash = `#/login`;
  };

  const changeSelectedCompany = (companyId) => {
    if (companyId && companyId !== selectedCompanyId)
      setSelectedCompanyId(companyId);
  }

  const handleListItemClick = (employee) => {
    setSelectedEmployee(employee)
  }

  const setFieldErrors = key => {
    setState({
      ...state,
      [key]: {
        ...state[key],
        errors: getFieldErrors(key)
      }
    });
  }

  const clearFieldErrors = key => {
    setState({
      ...state,
      [key]: {
        ...state[key],
        errors: []
      }
    });
  }

  const getFieldErrors = key => {
    let errors = [];
    const value = state[key].value;
    const validators = state[key].validators || [];
    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    return errors;
  }

  const sendEmail = () => {
    const data = {
      email: state.email.value,
      firstName: get(selectedEmployee, 'firstName'),
      lastName: get(selectedEmployee, 'lastName'),
      companyId: selectedCompanyId
    }

    APIService.profiles().appendToUrl('forgot_username/').post(data)
      .then(data => {
        if (data && data.errors && !selectedEmployee) {
          if (data.errors.toLowerCase().includes('not found')) {
            setState({
              email: {
                ...state.email, errors: [
                  "Incorrect email entered. Please contact support@agrichain.com if you are unable to recall the email associated with this account."
                ]
              }
            })
          }
          else if (data.errors.toLowerCase().includes('multiple accounts')) {
            fetchUserList()
            setFillEmail(false);
          }
        }
        else {
          setFillEmail(false);
          setShowEmployeeList(false);
          setForgotUsernameSuccess(true);
        }
      })
  };

  const confirmCompany = () => {
    const selectedCompany = foundCompanies.filter(company => company.id === selectedCompanyId)[0]
    if (selectedCompany.transactionParticipation) {
      setShowSubscribedCompanyMessage(true);
      setIsABNFieldVisible(false);
      setShowCompaniesDropdown(false);
      setIsRetrieveButtonVisible(false);
    } else if (!selectedCompany.isRegistered) {
      setShowUnregisteredCompanyMessage(true);
      setIsABNFieldVisible(false);
      setShowCompaniesDropdown(false);
      setIsRetrieveButtonVisible(false);
    }
    else {
      setShowCompaniesDropdown(false);
      setIsABNFieldVisible(false);
      setFillEmail(true)
    }
  }

  const displaySupportText = () => {
    setFillEmail(false);
    setShowSupportText(true)
  }

  const backToABNStep = () => {
    setShowUnregisteredCompanyMessage(false)
    setShowSubscribedCompanyMessage(false)
    setIsRetrieveButtonVisible(true);
    setIsABNFieldVisible(true)
  }

  const getCompanyAdmins = () => {
    const selectedCompany = foundCompanies.filter(company => company.id === selectedCompanyId)[0]
    return selectedCompany?.companyAdmins || []
  }

  return (
    <div className="forgot-username-wrapper">
      <div className="forgot-username-container">
        <div className="forgot-username-content-box">
          <div className="forgot-username-content-box--header">
            <img src="images/agrichain-logo.png" alt="Agri Chain Logo" />
          </div>

          <div className="forgot-username-content-box--content">
            {isABNFieldVisible && <h2>Retrieve Username</h2>}
            {showSubscribedCompanyMessage && <h2>Subscriber Company</h2>}
            {showUnregisteredCompanyMessage && <h2>No Company Found</h2>}
            {fillEmail && <h2>Confirm Email</h2>}
            {showEmployeeList && <h2>Select Associated User</h2>}
            {forgotUsernameSuccess && <h2>Success!</h2>}
            {showSupportText && <h2>Support</h2>}

            {fillEmail && <p>Please confirm the email associated with your username.</p>}
            {showEmployeeList && <p>There are multiple users linked with this email address. Please select the user for your username.</p>}
            {isABNFieldVisible && <p>Please enter your ABN/NGR, this will help identify the company that your user is associated with.</p>}

            <div className="cardForm cardForm--drawer">
              <div className="cardForm-content row">
                {showSubscribedCompanyMessage && (
                  <div className="col-sm-12">
                    <p >
                      The Company is an Agrichain Subscriber. Please contact your company admins for assistance.
                    </p>
                  </div>
                )}

                {showUnregisteredCompanyMessage && (
                  <div className="col-sm-12">
                    <p >
                      The Company is not registered on Agrichain. Please <a href="#/sign-up">Sign Up</a> to create your account and log in.
                    </p>
                  </div>
                )}

                {forgotUsernameSuccess && (
                  <div className="col-sm-12">
                    <p >
                      An email containing your username has been sent to your email address. Please follow the given instructions to reset your password and log in.
                    </p>
                  </div>
                )}

                {showSupportText && (
                  <div className="col-sm-12">
                    <p>
                      Please contact us at <a href="support@agrichain.com">support@agrichain.com</a> for further assistance.
                    </p>
                  </div>
                )}

                {isABNFieldVisible && (
                  <div className="col-sm-12">
                    <div style={{height: "65px"}}>
                      <CommonTextField
                        id="identifier"
                        label="ABN/NGR"
                        placeholder="Please enter ABN/NGR"
                        onChange={handleChange}
                        helperText={get(state, 'identifier.errors[0]', '')}
                        maxLength="20"
                        InputProps={{
                          startAdornment:
                            state.identifier.type ? (
                              <span style={{ paddingRight: "0.5em", fontWeight: "550", color: "#999999" }}>
                                {state.identifier.type}
                              </span>
                            ) : (
                              <InputAdornment position="start">
                                <WorkIcon sx={{color: "#999999"}} />
                              </InputAdornment>
                            ),
                        }}
                        FormHelperTextProps={{
                          style: {
                            marginTop: '10px'
                          },
                        }}
                      />
                    </div>
                  </div>
                )}

                {showCompaniesDropdown && (
                  <div className="col-sm-12">
                    <div style={{height: "65px"}}>
                      <CommonSelect
                        id="companies"
                        floatingLabelText="Company"
                        value={selectedCompanyId}
                        selectedItemId={selectedCompanyId}
                        selectConfig={{ text: 'displayName', value: 'id' }}
                        onChange={changeSelectedCompany}
                        items={
                          foundCompanies.map((company) => {
                            return {
                              id: company.id,
                              displayName: company.businessName
                            }
                          })
                        }
                        disabled={foundCompanies.length === 1}
                      />
                    </div>
                  </div>
                )}

                {(showEmployeeList || (showSubscribedCompanyMessage && getCompanyAdmins()?.length > 0 )) && (
                   <div className="col-sm-12 form-wrap">
                    <List
                      component="nav" aria-label="main mailbox folders"
                      className="form-wrap-65 sign-up-field"
                      sx={{
                        width: '100%',
                        bgcolor: 'background.paper',
                        position: 'relative',
                        overflow: 'auto',
                        maxHeight: 400,
                        '& ul': { padding: 0 },
                      }}
                    >
                      {
                        (showSubscribedCompanyMessage? getCompanyAdmins(): foundEmployees).map((employee) => (
                          <ListItemButton
                            style={{ backgroundColor: (!showSubscribedCompanyMessage && get(selectedEmployee, 'firstName') === employee.firstName && get(selectedEmployee, 'lastName') == employee.lastName) ? '#ccc' : '#f5f5f5' }}
                            selected={get(selectedEmployee, 'firstName') === employee.firstName && get(selectedEmployee, 'lastName') == employee.lastName}
                            onClick={event => handleListItemClick(employee)} /* eslint no-unused-vars: off */
                          >
                            <ListItemIcon>
                              <PersonIcon />
                            </ListItemIcon>
                            <ListItemText primary={employee.firstName + " " + employee.lastName} />
                          </ListItemButton>
                        ))
                      }
                    </List>
                  </div>
                )}

                {fillEmail && (
                   <div className="col-sm-12 form-wrap">
                    <div className="form-wrap-70 sign-up-field">
                      <CommonTextField
                        id="email"
                        type="email"
                        label="Confirm Email"
                        placeholder="Please enter"
                        value={state.email?.value}
                        onChange={handleChange}
                        helperText={state.email && state.email.errors && state.email.errors[0]}
                        InputProps={{
                          startAdornment:
                            (
                              <InputAdornment position="start">
                                <EmailIcon nativeColor="#999999" />
                              </InputAdornment>
                            )
                        }}
                        FormHelperTextProps={{
                          style: {
                            marginTop: '10px'
                          },
                        }}
                      />
                      {!state?.email?.errors && (
                        <div style={{ 'textAlign': 'right', 'marginBottom': '10px' }}>
                          <CommonButton
                            label="Don't remember email?"
                            primary
                            variant='text'
                            onClick={displaySupportText}
                            style={{ fontSize: '12px', fontWeight: '400', padding: '0', display: 'initial', textTransform: 'none', margin: '0' }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}

              </div>
            </div>

            <div className="col-12" style={{ 'textAlign': 'center' }}>
              {(showUnregisteredCompanyMessage || showSubscribedCompanyMessage) && (
                <Button
                  variant="contained"
                  type="button"
                  color="primary"
                  primary={true}
                  onClick={backToABNStep}
                  >
                  <KeyboardArrowLeft style={{padding: '1px 0px'}}/> Back
                </Button>
              )}
              <CommonButton
                label="Back to Login"
                secondary={true}
                variant="outlined"
                onClick={redirectToLoginPage}
              />
              {isRetrieveButtonVisible && (
                <CommonButton
                  onClick={retrieveCompany}
                  variant="contained"
                  label="Retrieve Company"
                  primary
                  className="login-button"
                />
              )}
              {fillEmail && (
                <CommonButton
                  onClick={sendEmail}
                  variant="contained"
                  label="Next"
                  primary
                  className="login-button"
                />
              )}
              {showEmployeeList && (
                <CommonButton
                  onClick={sendEmail}
                  variant="contained"
                  label="Next"
                  primary
                  className="login-button"
                />
              )}
              {showCompaniesDropdown && (
                <CommonButton
                  onClick={confirmCompany}
                  variant="contained"
                  label="Next"
                  primary
                  className="login-button"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotUsername;
