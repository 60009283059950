import React from 'react';
import Paper from '@mui/material/Paper';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { ERROR } from '../../../common/constants';

const renderBasedOnExpandedFlag = expanded => {
  if (expanded) {
    return (<KeyboardArrowUp
      style={{fill: '#112c42', height: '20px', width: '20px'}}
    />);
  }
  return (<KeyboardArrowDown style={{fill: '#112c42', height: '20px', width: '20px'}}/>);
};

export class ImpexDocsStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true
    };
  }

  toggleExpanded = () => {
    this.setState((prevState) => ({expanded: !prevState.expanded}));
  };

  render() {
    const {errors, heading, packMovementId } = this.props;
    const {expanded} = this.state;
    const containerErrors = errors?.containers
    const style = { display: "flex", alignItems: "center", gap: "5px"}
    const showErrors = !isEmpty(errors?.booking) || !isEmpty(errors?.shipment) || !isEmpty(errors?.salesOrder)
    return (
      <Paper className={this.props.className ? this.props.className : "contract-details-section-container"}>
        <h2 onClick={this.toggleExpanded}>
          { heading }
          <span className="expand-icon">
            {renderBasedOnExpandedFlag(expanded)}
          </span>
        </h2>
        {expanded && (
          <div style={{ whiteSpace: 'pre-wrap'}}>
            { !packMovementId && showErrors &&
            <div>
              {!isEmpty(errors?.booking) &&
              <p style={style}>
                Booking: <p style={{color: errors?.booking? ERROR : "inherit", margin: '0px'}}>{errors.booking}</p>
              </p>
              }
              {!isEmpty(errors?.shipment) &&
              <p style={style}>
                Shipment: <p style={{color: errors?.shipment? ERROR : "inherit", margin: '0px'}}>{errors?.shipment}</p>
              </p>
              }
              {!isEmpty(errors?.salesOrder) && 
              <p style={style}>
                Sales Order: <p style={{color: errors?.salesOrder? ERROR : "inherit", margin: '0px'}}>{errors?.salesOrder}</p>
              </p>
              }
            </div>
            }
            { get(containerErrors, packMovementId) && 
              <p style={{...style, margin: "0px"}}>
              Container: <p style={{color: get(containerErrors, packMovementId) ? ERROR : "inherit", margin: '0px'}}>{get(containerErrors, packMovementId)}</p>
              </p>
            }
          </div>
        )}
      </Paper>
    );
  }
}
