import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import alertifyjs from 'alertifyjs';
import { getSelectedOrderForMenu, receiveOrder, shouldFetchFreightOrder, closeOutFreightOrder, markMovementsDelivered, assignParentToOrder } from '../../actions/companies/orders';
import { setClickedOption } from '../../actions/companies/orders';
import { RejectionReasonDialog } from '../rejections/RejectionReasonDialog';
import SideDrawer from '../common/SideDrawer';
import EditOrderReview from './order-details/EditOrderReview';
import { raiseVoidOrderRequest, raiseVendorDecRequest } from '../../actions/companies/freights';
import { isLoading } from '../../actions/main';
import FreightCustomEmail from '../common/FreightCustomEmail';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { vendorDecBlockPermissionPopup, requestVendorDecBlockPermissionPopup } from '../../common/utils';
import { CALL_ON_GRAIN_TYPE_ID, ORDER_TYPE_NAMES } from '../../common/constants';
import { Dialog, DialogActions, DialogContent, Button } from '@mui/material';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import HierarchySideDrawer from '../common/HierarchySideDrawer';
import AllocateOrder from './order-details/AllocateOrder';
import { ASSIGN_CONTRACT_SUCCESS } from './order-details/Constants';


let FreightOrderActions = props =>{
  const [options, setOptions] = useState({
    showHierarchy: false,
    isVoidDialogOpen: false,
    isCloseOutDialogOpen: false,
    amendDialogOpen: false,
    showCustomEmailDialog: false,
    showVendorDecPopUp: false,
    disableOption:false,
    deliverMovementsDialogOpen: false,
    allocate: false
  });

  const [requestReason, setRequestReason] = useState("");
  const [closeOutReason, setCloseOutReason] = useState("");
  const [subject, setSubject] = useState("");
  const [vendorDecDetails, setVendorDecDetails] = useState(false);
  let [option, setOption] = useState(null)
  const [orderTypeName, setOrderTypeName] = useState("");

  let dispatch = useDispatch();

  let setOptionKey = (key, value) =>{
    setOptions({
      ...options,
      [key]: value
    });
    dispatch(setClickedOption(null));
  };

  let closeEmailDialog = (communicationData, justClose) => {
    setSubject("");
    setRequestReason(false);
    setCloseOutReason(false);
    setOptions({
      ...options,
      disableOption: false,
      showCustomEmailDialog: false,
      isVoidDialogOpen: false,
      isCloseOutDialogOpen: false,
      allocate: false
    });
    setVendorDecDetails(false);
    if (!justClose) {
      let data = {};
      if (communicationData) {
        data['communication'] = communicationData;
      }
      dispatch(isLoading('genericTableWithData'));
      if (vendorDecDetails) {
        const requestData = { entity: 'freightorder', ...data };
        dispatch(raiseVendorDecRequest(props.selectedOrderId, requestData));
      }
      else {
        const voidData = { requestReason: requestReason, ...data };
        const isDuplicated = get(props, 'canRaiseVoidAndDuplicateRequestForOrder')
        dispatch(raiseVoidOrderRequest(
          props.selectedOrderId,
          voidData,
          isDuplicated,
          get(props.selectedOrder, 'typeId')
        ));
      }
    }
  };

  let checkForOptions = async () => {
    const { selectedOrderId, clickedOption, canAssignParentForSelectedFreightOrder } = props;
    setOption(clickedOption)
    const clickedOptionKey = get(clickedOption, 'key');

    const isMatchingOrder = (selectedOrderId && !props.selectedAllocationId)
    const isMatchingAllocation = (!selectedOrderId && props.selectedAllocationId)

    if (
      get(props, 'canRaiseVoidRequestForOrder') &&
      get(clickedOption, 'key') === 'void' &&
      (isMatchingOrder || isMatchingAllocation)
    ) {
      setOptionKey("isVoidDialogOpen", true);
    }
    else if (
      get(props, 'canRaiseVoidAndDuplicateRequestForOrder') &&
      get(clickedOption, 'key') === 'void_and_duplicate' &&
      (isMatchingOrder || isMatchingAllocation)
    ) {
      setOptionKey("isVoidDialogOpen", true);
    }
    else if(selectedOrderId && clickedOption?.key === 'show_hierarchy'){
      setOptionKey("showHierarchy", true);
    }
    else if (get(clickedOption, 'key') === 'review_amendment'){
      setOptionKey("amendDialogOpen", true);
      setOrderTypeName(find(ORDER_TYPE_NAMES, {id: get(props, 'selectedOrder.typeId')}).name);
    }
    else if (
      get(clickedOption, 'key') === 'vendor_dec_add_fo' &&
      get(clickedOption, 'type') != 'allocation' &&
      selectedOrderId &&
      props.selectedOrder &&
      get(props.selectedOrder, 'typeId') != CALL_ON_GRAIN_TYPE_ID //this needs to be fixed better by separating out reducers for FO/GO
    ){
      dispatch(setClickedOption(null));
      if (isEmpty(props.selectedOrder.cannotCreateVendorDecReasons)) {
        document.location=`#/vendor-decs/grain/new?entity=freightorder&entityId=${selectedOrderId}`;
      }
      else{
        vendorDecBlockPermissionPopup(props.selectedOrder.cannotCreateVendorDecReasons, 'Order');
      }
    }
    else if (get(clickedOption, 'key') === 'vendor_dec_add_cog' && selectedOrderId && props.selectedOrder){
      dispatch(setClickedOption(null));
      if (isEmpty(props.selectedOrder.cannotCreateVendorDecReasons)) {
        document.location=`#/vendor-decs/grain/new?entity=freightorder&entityId=${selectedOrderId}`;
      }
      else{
        vendorDecBlockPermissionPopup(props.selectedOrder.cannotCreateVendorDecReasons, 'Order');
      }
    }
    else if (
      get(clickedOption, 'key') === 'vendor_dec_request_fo' &&
      get(clickedOption, 'type') != 'allocation' &&
      selectedOrderId && props.selectedOrder &&
      get(props.selectedOrder, 'typeId') != CALL_ON_GRAIN_TYPE_ID //this needs to be fixed better by separating out reducers for FO/GO
    ){
      dispatch(setClickedOption(null));
      if (isEmpty(props.selectedOrder.cannotRequestVendorDecReasons)) {
        const identifier = get(props.selectedOrder, 'identifier', '').toUpperCase();
        const entity = get(props.selectedOrder, 'typeId') === CALL_ON_GRAIN_TYPE_ID ? "Grain" : "Freight";
        setVendorDecDetails(true);
        setSubject(`[Request] Vendor Declaration for ${entity} Order #${identifier}`);
        setOptions({...options, disableOption: true, showCustomEmailDialog:true});
      }
      else{
        requestVendorDecBlockPermissionPopup(props.selectedOrder.cannotRequestVendorDecReasons, 'Order');
      }
    }
    else if (get(clickedOption, 'key') === 'vendor_dec_request_cog' && selectedOrderId && props.selectedOrder){
      dispatch(setClickedOption(null));
      if (isEmpty(props.selectedOrder.cannotRequestVendorDecReasons)) {
        const identifier = get(props.selectedOrder, 'identifier', '').toUpperCase();
        const entity = get(props.selectedOrder, 'typeId') === CALL_ON_GRAIN_TYPE_ID ? "Grain" : "Freight";
        setVendorDecDetails(true);
        setSubject(`[Request] Vendor Declaration for ${entity} Order #${identifier}`);
        setOptions({...options, disableOption: true, showCustomEmailDialog:true});
      }
      else{
        requestVendorDecBlockPermissionPopup(props.selectedOrder.cannotRequestVendorDecReasons, 'Order');
      }
    }
    else if (
      get(clickedOption, 'key') === 'vendor_dec_view_fo' &&
      get(clickedOption, 'type') != 'allocation' &&
      selectedOrderId &&
      get(props.selectedOrder, 'typeId') != CALL_ON_GRAIN_TYPE_ID // this needs to be fixed better by separating out reducers for FO/GO
    ){
      dispatch(setClickedOption(null));
      document.location=`#/freights/orders/${selectedOrderId}/vendor-decs`;
    }
    else if (get(clickedOption, 'key') === 'vendor_dec_view_cog' && selectedOrderId){
      dispatch(setClickedOption(null));
      document.location=`#/freights/orders/${selectedOrderId}/vendor-decs`;
    }
    else if (get(clickedOption, 'key') === 'close_out' && selectedOrderId && get(props, 'canCloseOutForOrder')){
      alertifyjs.confirm(
        'Warning',
        'To Close Out the Order, system would reduce the order tonnage to the tonnage that has been delivered. Do you wish to proceed?',
        () => {
          setOptionKey("isCloseOutDialogOpen", true);
        },
        () => { },);
    }
    else if (get(clickedOption, 'key') === 'mark_movements_delivered' && selectedOrderId)
      setOptions({...options, deliverMovementsDialogOpen:true});
    else if (clickedOptionKey === 'assign_to' && selectedOrderId){
      if (canAssignParentForSelectedFreightOrder && props.currentUser.isStaff) {
        setOptionKey("allocate", true);
      }
    }
  };

  let closeVoidDialog = () =>{
    setOptionKey("isVoidDialogOpen", false);
    setRequestReason("");
  };

  let closeCloseOutDialog = () =>{
    setOptionKey("isCloseOutDialogOpen", false);
    setCloseOutReason("");
  };

  let handleOrder = (data, id) => {
    if (data)
      data.feature = 'Order Details';
    dispatch(assignParentToOrder(id, data, ASSIGN_CONTRACT_SUCCESS));
  };

  let getIdentifier = () =>{
    return get(find(props.items, {id: props.selectedOrderId}), "identifier") || get(props.selectedOrder, 'identifier');
  };

  useEffect(()=> {
    if(props.clickedOption){
      checkForOptions();
    }
    if (props.shouldFetchOrder && props.selectedOrderId && props.selectedOrderId !== get(props, 'selectedOrder.id')) {
      dispatch(isLoading('orderDetail'));
      dispatch(
        getSelectedOrderForMenu(props.selectedOrderId, receiveOrder, false, false, false, false, true)
      );
      dispatch(shouldFetchFreightOrder(false));
      setOptionKey("isVoidDialogOpen", false);
    }
  }, [
    props.shouldFetchOrder, props.selectedOrderId,
    props.canRaiseVoidRequestForOrder, props.clickedOption,
    props.selectedOrder, props.canCloseOutForOrder,
    props.canRaiseVoidAndDuplicateRequestForOrder,
    props.canAssignParentForSelectedFreightOrder
  ]);

  useEffect(()=> {
    if ((get(option, 'key') || '').startsWith('vendor_dec_request') && props.selectedOrder && get(props.selectedOrder, 'typeId') != CALL_ON_GRAIN_TYPE_ID) {
      if (isEmpty(props.selectedOrder.cannotRequestVendorDecReasons)) {
        const identifier = get(props.selectedOrder, 'identifier', '').toUpperCase();
        const entity = get(props.selectedOrder, 'typeId') === CALL_ON_GRAIN_TYPE_ID ? "Grain" : "Freight";
        setVendorDecDetails(true);
        setSubject(`[Request] Vendor Declaration for ${entity} Order #${identifier}`);
        setOptions({...options, disableOption: true, showCustomEmailDialog:true});
      }
      else{
        requestVendorDecBlockPermissionPopup(props.selectedOrder.cannotRequestVendorDecReasons, 'Order');
      }
      setOption(null);
    }
  }, [props.selectedOrder])

  let handleSubmit = () => {
    setOptions({...options, deliverMovementsDialogOpen: false});
    dispatch(markMovementsDelivered(props.selectedOrderId));
  }

  let selectedOrderItem = find(props.items, {id: props.selectedOrderId})
  let selectedOrderTypeId = get(selectedOrderItem, 'typeId')
  let orderData = selectedOrderItem || props.selectedOrder

  if(selectedOrderTypeId == CALL_ON_GRAIN_TYPE_ID && props.selectedOrder)
    orderData = props.selectedOrder || selectedOrderItem

  return (
    <span>
      <FreightCustomEmail
        order={selectedOrderTypeId == CALL_ON_GRAIN_TYPE_ID ? props.selectedOrder: orderData}
        showCustomEmailDialog={options.showCustomEmailDialog}
        closeCustomEmailDialog={closeEmailDialog}
        disable={options.disableOption}
        subject={subject}
        vendorDecDetails={vendorDecDetails}

      />
      {
        options.isVoidDialogOpen && getIdentifier() &&
          <RejectionReasonDialog
            open={options.isVoidDialogOpen}
            onClose={closeVoidDialog}
            title={`Void Order: ${getIdentifier()}`}
            value={requestReason}
            onChange={(event) => setRequestReason(event.target.value)}
            onCancel={closeVoidDialog}
            onReject={() => {
              setVendorDecDetails(false);
              setOptionKey("showCustomEmailDialog", true);
              if(selectedOrderTypeId == CALL_ON_GRAIN_TYPE_ID)
                dispatch(shouldFetchFreightOrder(true));
            }}
            placeholder="Enter you reason for void request"
            submitText="Submit"
            required
          />
      }
      {
        options.allocate &&
        <SideDrawer
          isOpen={options.allocate}
          onClose={() => setOptionKey("allocate", false)}
          title={'Allocate Order: ' + getIdentifier()}
          classes={{ paper: 'left-text-align' }}
          size="big"
        >
         <AllocateOrder
            handleCancel={() => setOptionKey("allocate", false)}
            order={props.selectedOrder}
            allocateOrder={handleOrder}
          />
        </SideDrawer>
      }
      {
        options.isCloseOutDialogOpen && getIdentifier() &&
          <RejectionReasonDialog
            open={options.isCloseOutDialogOpen}
            onClose={closeCloseOutDialog}
            title={`Close Out Order: ${getIdentifier()}`}
            value={closeOutReason}
            onChange={(event) => setCloseOutReason(event.target.value)}
            onCancel={closeCloseOutDialog}
            onReject={() => {
              const data = { closeOutReason: closeOutReason, ...data }
              dispatch(closeOutFreightOrder(props.selectedOrderId, data));
              dispatch(setClickedOption(null));
            }}
            placeholder="Enter your reason for close out"
            submitText="Submit"
            required = {false}
          />
      }
      {options.deliverMovementsDialogOpen &&
       <Dialog open fullWidth onClose={() => setOptions({...options, deliverMovementsDialogOpen: false})}>
         <DialogTitleWithCloseIcon
           onClose={() => setOptions({...options, deliverMovementsDialogOpen: false})}
           closeButtonStyle={{ marginTop: '0px' }}
           id='form-dialog-title'>
           Mark Movements Delivered
         </DialogTitleWithCloseIcon>
         <DialogContent>
           <span>
             All In Progress movements will now be marked delivered.
           </span>
         </DialogContent>
         <DialogActions>
           <Button color='default' onClick={() => setOptions({...options, deliverMovementsDialogOpen: false})}>Cancel</Button>
           <Button onClick={handleSubmit}>Proceed</Button>
         </DialogActions>
       </Dialog>
      }

      {
        props.selectedOrder && options.amendDialogOpen &&
          <SideDrawer
            isOpen={options.amendDialogOpen}
            onClose={() => setOptionKey("amendDialogOpen", false)}
            title={`${orderTypeName} Order Amend Request (${get(props.selectedOrder, 'identifier')})`}
            classes={{'paper' : 'left-text-align'}}
            size="big"
          >
            <EditOrderReview order={props.selectedOrder} closeSidebar={() => setOptionKey("amendDialogOpen", false)}/>
          </SideDrawer>
      }
      {
        options.showHierarchy &&
          <HierarchySideDrawer
            isOpen={options.showHierarchy}
            onClose={() => setOptionKey("showHierarchy", false)}
            title="Order Hierarchy"
            orderId={props.selectedOrderId}
          />
      }

    </span>);
};

export default FreightOrderActions;
