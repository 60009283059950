import React from "react";
import './orderPreview.scss';
import { get, isEmpty } from 'lodash';
import { getCurrentCountry, toPhoneFormat, getCountryLabel, isVisibleInCountry } from '../../../common/utils';

class PackOrderPreview extends React.Component {
  render() {
    const { previewFields } = this.props;
    const { customer, customerContact } = previewFields.customerDetails;
    const packingProvider = previewFields.packingProvider;
    const packingProviderContact = previewFields.packingProviderContact;
    const invoicingDetails = previewFields.invoicingDetails;
    const freightShipping = previewFields.freightShipping;
    const freightContainer = previewFields.freightContainer;
    const { config } = getCurrentCountry();
    const ABNLabel = config.abn.label
    const tonnageLabel = getCountryLabel('tonnage')
    const isABNVisible = isVisibleInCountry('abn')
    return (
      <div className="order-preview">
        <div className="order-header">
          <div className="agri-logo">
            <h2>{packingProvider.name}</h2>
          </div>
          <div className="order-header--content">
            <div className="col-xs-12 col-sm-6 col-md-6 padding-reset">
              <h4>Pack Order</h4>
              {previewFields.externalReferenceNumber &&
                <p className="reference"><span>Contract Number:</span> {previewFields.externalReferenceNumber.toUpperCase()}</p>
              }
              <p className="reference"><span>Order Number:</span> {previewFields.identifier.toUpperCase()}</p>
              <p className="reference"><span>Date Issued:</span> {previewFields.dateIssued}</p>
            </div>
            <div className="text-right col-xs-12 col-sm-6 col-md-6 padding-reset">
              <h4>{previewFields.currentUser.name}</h4>
              {previewFields.currentUser.company.address.address && <p>{previewFields.currentUser.company.address.address}</p>}
              {previewFields.currentUser.email && <p><span>EMAIL: </span>{previewFields.currentUser.email}</p>}
              {previewFields.currentUser.company.mobile && <p><span>PHONE: </span>{toPhoneFormat(previewFields.currentUser.company.mobile)}</p>}
              {previewFields.currentUser.company.abn && isABNVisible && <p><span>{`${ABNLabel}: `}</span> {previewFields.currentUser.company.abn}</p>}
            </div>
          </div>
        </div>
        <div className="order-body">
          <p className="description">
            {`This document is a record of the Pack Order agreed between the below Customer and Pack Provider following discussions between the Customer’s and Packing Provider’s representatives on ${previewFields.dateIssued}.`}
            <p>
              {`For Further information pleases contact ${previewFields.currentUser.company.name} on ${previewFields.currentUser.company.mobile}`}
            </p>
          </p>
          <div className="order-details">
            <div className="order-details__left col-xs-12 col-sm-6 col-md-6">
              <h4>CUSTOMER</h4>
              <h5>{(get(customer,'entity') === 'farm' ? get(customer,'displayName') : get(customer,'name'))}</h5>
              {isABNVisible && <p><span className="pre-label">{ABNLabel}</span> <span className="pre-content">{get(customer,'abn')}</span></p>}
              <p><span className="pre-label">Contact</span> <span className="pre-content">{customerContact && customerContact.name}</span></p>
              <p><span className="pre-label">Phone</span> <span className="pre-content">{customerContact && toPhoneFormat(customerContact.mobile)}</span></p>
              <p><span className="pre-label">Email</span> <span className="pre-content">{customerContact && customerContact.email}</span></p>
            </div>
            {!isEmpty(packingProvider) && <div className="order-details__right col-xs-12 col-sm-6 col-md-6">
              <h4>PACK PROVIDER</h4>
              <h5>{get(packingProvider,'name')}</h5>
              {isABNVisible && <p><span className="pre-label">{ABNLabel}</span> <span className="pre-content">{get(packingProvider,'abn')}</span></p>}
              <p><span className="pre-label">Contact</span> <span className="pre-content">{packingProviderContact && packingProviderContact.name}</span></p>
              <p><span className="pre-label">Phone</span> <span className="pre-content">{packingProviderContact && toPhoneFormat(packingProviderContact.mobile)}</span></p>
              <p><span className="pre-label">Email</span> <span className="pre-content">{packingProviderContact && packingProviderContact.email}</span></p>
            </div>}
          </div>

          <div className="order-details margintop-20">
            <div className="order-details__left col-xs-12 col-sm-6 col-md-6">
              <h5>COMMODITY DETAILS</h5>
              <p><span className="pre-label">COMMODITY</span> <span className="pre-content">{get(previewFields,'commodity.displayName')}</span></p>
              <p><span className="pre-label">VARIETY</span> <span className="pre-content">{get(previewFields,'variety.name')}</span></p>
              <p><span className="pre-label">GRADE</span> <span className="pre-content">{get(previewFields,'plannedGrade.name')}</span></p>
              <p><span className="pre-label">{tonnageLabel.toUpperCase()}</span> <span className="pre-content">{get(previewFields,'plannedTonnage')} {previewFields.priceUnit}</span></p>
              <p><span className="pre-label">SEASON</span> <span className="pre-content">{get(previewFields,'season')}</span></p>
            </div>
            <div className="order-details__right col-xs-12 col-sm-6 col-md-6">
              <h5>INVOICING DETAILS</h5>
              <p><span className="pre-label">INVOICING</span> <span className="pre-content">{invoicingDetails.invoicing}</span></p>
              <p><span className="pre-label">PAYMENT TERM</span> <span className="pre-content">{invoicingDetails.paymentTerm}</span></p>
              <p><span className="pre-label">PACKING RATE</span> <span className="pre-content">{invoicingDetails.packingRate}</span></p>
              <p><span className="pre-label">FUMIGATION FEE</span> <span className="pre-content">{invoicingDetails.fumigationFee}</span></p>
              <p><span className="pre-label">WHARF DELIVERY FEE</span> <span className="pre-content">{invoicingDetails.wharfDeliveryFee}</span></p>
              <p><span className="pre-label">WHARF BOOKING FEE</span> <span className="pre-content">{invoicingDetails.wharfBookingFee}</span></p>
            </div>
          </div>
          <div className="order-details margintop-20">
            <div className="order-details__left col-xs-12 col-sm-6 col-md-6">
              <h5>SHIPPING DETAILS</h5>
              {freightShipping && Object.keys(freightShipping).map(key => (<p key={key}><span className="pre-label">{key}</span> <span className="pre-content">{freightShipping[key]}</span></p>))}
            </div>
            <div className="order-details__right col-xs-12 col-sm-6 col-md-6">
              <h5>CONTAINER DETAILS</h5>
              {freightContainer && Object.keys(freightContainer).map(key => (<p key={key}><span className="pre-label">{key}</span> <span className="pre-content">{freightContainer[key]}</span></p>))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PackOrderPreview;
