import React from 'react';
import { connect } from 'react-redux';
import GenericTable from '../components/GenericTable';
import {
  getPaginatedInvoicesResponse,
} from '../actions/companies/invoices';


class InvoicePayableTable extends React.Component {
  constructor(props) {
    super(props);
  }

  closeAddSideForm = () =>{
    this.setState({item: undefined});
  };

  openAddSideForm = item =>{
    this.setState({item: item});
  };

  render() {
    return (
        <GenericTable
          {...this.props}
          openAddSideForm={this.openAddSideForm}
          closeAddSideForm={this.closeAddSideForm}
          showHeader={true}
          showHeaderValue={false}
          nested
          showStarIfDisabled={this.props.showStarIfDisabled}
        />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleOptionClick: null,
    handleDefaultCellClick: null,
    navigateTo: null,
    changePageSize: null,
    getSearchSortUrl: null,
    dispatch
  };
};

const mapStateToProps = state => {
  return {
    clearSearch: getPaginatedInvoicesResponse,
    globalSearch: false,
    handleDefaultCellClick: false,
    order: 'desc',
    orderBy: 'createdAt',
    rowHighlightedMap: {'isHighlighted': 'delhi-blue'},
    scrollToTopOnUpdate: false,
    selectedInvoice: state.companies.invoices.selectedInvoice,
    canAddPayment: state.companies.invoices.canAddPayment,
    selectedInvoiceId: state.companies.invoices.selectedInvoiceId,
    useNestedOptionMenu: true,
    invoiceId: state.companies.invoices.invoiceId,
    isPdfDownloadable: state.companies.invoices.isPdfDownloadable,
    flag: state.companies.invoices.flag,
    token: state.main.user.token,
    canMarkComplete: state.companies.invoices.canMarkComplete,
    displayIDColumn: 'ref'
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePayableTable);
