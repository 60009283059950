import React from 'react';
import { connect } from 'react-redux';
import { get, isEmpty, find, capitalize, map } from 'lodash';
import GenericTable from '../GenericTable';
import InvoicesActions from './InvoicesActions';
import APIService from '../../services/APIService';
import { convertEpochToDateFormat } from '../../common/momentUtilities';
import {
  handleInvoicesOptionsMapper
} from '../../actions/companies/invoices';
import {
  regeneratePDF, toDateFormat
} from "../../common/utils";
import { getCustomColumns } from '../../actions/companies/index';
import { getActionMenuOptions } from './utils';
import { isLoading, setHeaderText, setBreadcrumbs, forceStopLoader } from '../../actions/main';
import {INVOICES_TABLE_COLUMN_LIMIT, INVOICE_TABLE_GLOBAL_LISTING_HEADERS} from '../../common/constants';

class WarehouseInvoices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: null,
      invoices: []
    }
  }

  componentDidMount() {
    this.fetchInvoices()
    this.setHeaderAndBreadcrumbs()
  }

  componentDidUpdate(prevProps) {
    if(prevProps.invoiceId !== this.props.invoiceId)
      this.setHeaderAndBreadcrumbs();
  }


  fetchInvoices() {
    APIService
      .invoices(this.props.invoiceId)
      .appendToUrl('warehouse-invoices/')
      .get()
      .then(response => {
        let invoices = map(response, invoice => {
          invoice.dueDate = convertEpochToDateFormat(invoice.paymentDueDate)
          invoice.paidOn = invoice.paymentDate ? toDateFormat(invoice.paymentDate) : '-'
          invoice.createdOn = toDateFormat(new Date(invoice.createdAt))
          invoice.status = capitalize(invoice.status)
          if(invoice.type === 'WarehouseFee' || invoice.type === 'Warehouse Fee Invoice'){
            invoice.type = 'Warehouse Fee Invoice';
          }
          return invoice
        })
        this.setState(
          {invoices: invoices},
          () => this.props.dispatch(forceStopLoader())
        )
      })
  }

  closeAddSideForm = () => this.setState({item: undefined});

  openAddSideForm = item => this.setState({item: item});

  handleOptionClick = (event, item, baseEntity) => {
    if(get(item, 'key') === 'regenerate_pdf' && get(baseEntity, 'id')) {
      const service = APIService.invoices(baseEntity.id).appendToUrl('regenerate-pdfs/');
      return regeneratePDF(service);
    } else {
      this.props.dispatch(handleInvoicesOptionsMapper(event, item, baseEntity, null,'invoiceTable'));
    }
  }

  handleDefaultCellClick = invoice => {
    this.props.dispatch(isLoading('invoiceDetail'));
    document.location.hash = `/invoices/${invoice.id}/details`;
  }

  getCustomColumns = tableType => this.props.dispatch(getCustomColumns(tableType))

  setHeaderAndBreadcrumbs() {
    const { headerText } = this.props;
    this.props.dispatch(setHeaderText(headerText));
    this.props.dispatch(setBreadcrumbs([{text: 'Warehouse Dashboard'}]));
  }

  render() {
    return (
      <div style={{background: '#FFF', padding: '10px'}}>
        <GenericTable
          {...this.props}
          items={this.state.invoices}
          openAddSideForm={this.openAddSideForm}
          closeAddSideForm={this.closeAddSideForm}
          handleDefaultCellClick={this.handleDefaultCellClick}
          handleOptionClick={this.handleOptionClick}
          getCustomColumns={this.getCustomColumns}
          optionsItems={getActionMenuOptions}
          order='desc'
          orderBy='createdAt'
          useNestedOptionMenu
          dispatch={this.props.dispatch}
          displayIDColumn='identifier'
        />
        <InvoicesActions {...this.props} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  let customColumns = [...INVOICE_TABLE_GLOBAL_LISTING_HEADERS];
  const tableColumnsOrder = get(state.companies, 'companies.tableColumns');
  if (tableColumnsOrder && !isEmpty(tableColumnsOrder)) {
    customColumns = [];
    tableColumnsOrder.forEach(val => {
      const obj = find(INVOICE_TABLE_GLOBAL_LISTING_HEADERS, {key: val});
      if (obj) {
        customColumns.push(obj);
      }
    });
  }
  if (customColumns.length > INVOICES_TABLE_COLUMN_LIMIT) {
    customColumns = customColumns.splice(0, INVOICES_TABLE_COLUMN_LIMIT);
  }
  return {
    columns: customColumns,
  }
}

export default connect(mapStateToProps)(WarehouseInvoices);
