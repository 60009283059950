/* eslint no-use-before-define: ["error", { "functions": false }] */
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import includes from 'lodash/includes';
import forEach from 'lodash/forEach';
import has from "lodash/has";
import isNil from "lodash/isNil";
import { EMAIL_REGEX } from './constants';
import { getPhoneMobileRegex, getCountryLabel } from './utils';

export const VALUE_NOT_SELECTED = 'VALUE_NOT_SELECTED';

export const MESSAGES = {
  REQUIRED: 'This field can not be blank',
  SELECTED: 'Please select from suggestions',
  MIN_LENGTH: 'Please enter at least $n characters',
  MAX_LENGTH: 'Please enter at most $n characters',
  FIX_LENGTH: 'Please enter $n characters',
  VALUE_ABOVE: 'Please enter a number greater than $n',
  VALUE_BELOW: 'Please enter a number lesser than $n',
  MIN_VALUE: 'Please enter a number greater than or equal to $n',
  MAX_VALUE: 'Please enter a number up to $n',
  VALUE_BETWEEN_LONG: 'Please enter a number between $min and $max',
  VALUE_BETWEEN_SHORT: '$min to $max',
  MOBILE_REGEX: 'Please enter a valid Mobile number',
  PHONE_MOBILE_REGEX: 'Please enter a valid Phone/Mobile number',
  ALPHA_REGEX: 'Please enter letters only',
  NUM_REGEX: 'Please enter numbers only',
  ALPHA_NUM_REGEX: 'Please enter letters and numbers only',
  UPPER_ALPHA_NUM_REGEX: 'Please enter capital letters and numbers only',
  WEBSITE_REGEX: 'Please enter a valid Website address',
  EMAIL_REGEX: 'Please enter a valid Email address',
  REGO_REGEX: 'Please enter a valid $regoLabel',
  DECIMAL_REGEX: 'Please enter up to $n decimal places',
  MIN_DATE: 'Please select a date above $min',
  MAX_DATE: 'Please select a date below $max',
  VALUE_EQUAL: 'Primary shareholder should have maximum percent share',
};

export const  required = (msg='') => getValidator(
  value => isEmpty(value) && !isNumber(value),
  (msg) ? msg : MESSAGES.REQUIRED,
);

export const  requiredBoolean = (msg='') => getValidator(
  value => isNil(value),
  (msg) ? msg : MESSAGES.REQUIRED,
);

export const selected = (msg='') => getValidator(
  value => ('' + value).length && value === VALUE_NOT_SELECTED,
  (msg) ? msg : MESSAGES.SELECTED,
);

export const minLength = (min, msg='') => getValidator(
  value => value && value.length > 0 && value.length < min,
  (msg) ? msg : MESSAGES.MIN_LENGTH.replace('$n', min),
);

export const maxLength = (length, msg='') => getValidator(
  value => value && value.length > length,
  (msg) ? msg : MESSAGES.MAX_LENGTH.replace('$n', length),
);

export const fixLength = (length, msg='') => getValidator(
  value => (value) ? value.length != length : false,
  (msg) ? msg : MESSAGES.FIX_LENGTH.replace('$n', length),
);

export const maxValue = (maxValue, msg='') => getValidator(
  value => parseFloat(value) > maxValue,
  (msg) ? msg : MESSAGES.MAX_VALUE.replace('$n', parseFloat(maxValue).toFixed(2)),
);

export const minValue = (minValue, msg='') => getValidator(
  value => parseFloat(value) < minValue,
  (msg) ? msg : MESSAGES.MIN_VALUE.replace('$n', parseFloat(minValue).toFixed(2)),
);

export const valueAbove = (valueAbove, msg='') => getValidator(
  value => parseFloat(value) <= valueAbove,
  (msg) ? msg : MESSAGES.VALUE_ABOVE.replace('$n', parseFloat(valueAbove).toFixed(2)),
);

export const valueBelow = (valueBelow, msg='') => getValidator(
  value => parseFloat(value) >= valueBelow,
  (msg) ? msg : MESSAGES.VALUE_BELOW.replace('$n', parseFloat(valueBelow).toFixed(2)),
);

export const valueAboveOrEqual = (valueAbove, msg='') => getValidator(
  value => parseFloat(value) < valueAbove,
  (msg) ? msg : MESSAGES.VALUE_ABOVE.replace('$n', parseFloat(valueAbove).toFixed(2)),
);

export const valueBelowOrEqual = (valueBelow, msg='') => getValidator(
  value => parseFloat(value) > valueBelow,
  (msg) ? msg : MESSAGES.VALUE_BELOW.replace('$n', parseFloat(valueBelow).toFixed(2)),
);

export const valueEqual = (valueEqual, msg = '') => getValidator(
  value => parseFloat(value) != parseFloat(valueEqual),
  (msg) ? msg : MESSAGES.VALUE_EQUAL,
);

export const mobileRegex = (msg='') => getRegexValidator(
  /^(04)[0-9]{8}$/,
  (msg) ? msg : MESSAGES.MOBILE_REGEX,
);

export const phoneMobileRegex = (msg='') => getRegexValidator(
  getPhoneMobileRegex(),
  (msg) ? msg : MESSAGES.PHONE_MOBILE_REGEX
);

export const alphaRegex = (msg='') => getRegexValidator(
  /^[a-zA-Z]*$/,
  (msg) ? msg : MESSAGES.ALPHA_REGEX,
);

export const numRegex = (msg='') => getRegexValidator(
  /^[0-9]*$/,
  (msg) ? msg : MESSAGES.NUM_REGEX,
);

export const alphaNumRegex = (msg='') => getRegexValidator(
  /^[a-zA-Z0-9]*$/,
  (msg) ? msg : MESSAGES.ALPHA_NUM_REGEX,
);

export const upperAlphaNumRegex = (msg='') => getRegexValidator(
  /^[A-Z0-9]*$/,
  (msg) ? msg : MESSAGES.UPPER_ALPHA_NUM_REGEX,
);

export const websiteRegex = (msg='') => getRegexValidator(
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, // eslint-disable-line
  (msg) ? msg : MESSAGES.WEBSITE_REGEX,
);

export const emailRegex = (msg='') => getRegexValidator(
  EMAIL_REGEX,
  (msg) ? msg : MESSAGES.EMAIL_REGEX,
);

export const decimalRegex = (decimalPlaces, msg='') => {
  var regExp = new RegExp("^\\s*-?(\\d+(\\.\\d{1," + decimalPlaces + "})?|\\.\\d{1," + decimalPlaces + "})\\s*$", "g");
  if (decimalPlaces == -1)
    regExp = new RegExp("^\\s*-?(\\d+(\\.\\d{1,25})?|\\.\\d{1,25})\\s*$", "g");

  return getRegexValidator(
    regExp,
    (msg) ? msg : MESSAGES.DECIMAL_REGEX.replace('$n', decimalPlaces),
  );
};

export const truckRegoRegex = (msg='') => getRegexValidator(
  /^.{3,10}$/,
  (msg) ? msg : MESSAGES.REGO_REGEX.replace('$regoLabel', getCountryLabel('rego')),
);

export const valueBetween = (minValue, maxValue, useShortMessage) => {
  const message = useShortMessage ? MESSAGES.VALUE_BETWEEN_SHORT : MESSAGES.VALUE_BETWEEN_LONG;

  return getValidator(
    value => !includes(['', null, NaN], value) && (value < minValue || value > maxValue),
    message.replace('$min', minValue).replace('$max', maxValue),
  );
};

export const minDate = (minValue, msg='', type='error') => getValidator(
  value => new Date(value).setHours(0,0,0,0) < new Date(minValue).setHours(0,0,0,0),
  getMessage(msg, MESSAGES.MIN_DATE, '$min', minValue, type),
  type
);

export const maxDate = (maxValue, msg='', type='error') => getValidator(
  value => new Date(value).setHours(0,0,0,0) > new Date(maxValue).setHours(0,0,0,0),
  getMessage(msg, MESSAGES.MAX_DATE, '$max', maxValue, type),
  type
);

export const applyValidatorsOn = (fields) => {
  forEach(fields, (field) => {
    if(has(field, 'value') && has(field, 'validators')){
    field.errors = [];
    field.validators.forEach((validator) => {
      if(validator.isInvalid(field.value)) {
      field.errors.push(validator.message);
    }
  });
  }
});
};

function getMessage(message, defaultMessage, replaceString, value, type) {
  let msg = (message) ? message : defaultMessage;
  msg = msg.replace(replaceString, value);
  return type=='warning' ? 'Warning: ' + msg : msg;
}

function getValidator(isInvalidFunction, message, type='error') {
  return {
    isInvalid: isInvalidFunction,
    message,
    type,
  };
}

function getRegexValidator(regex, message) {
  return getValidator(
    value => value && value.length > 0 && !regex.test(value),
    message,
  );
}
