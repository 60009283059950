import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';
import {
  get, set, compact, cloneDeep, omit, isEmpty, forEach, mapValues,
  some, has, find, includes, isNumber, map, merge, isEqual, uniq,
  isString, findIndex, orderBy, filter, defaultTo, isNull, upperFirst, min
} from 'lodash';
import { InputAdornment, FormControlLabel, Checkbox, TextField, createFilterOptions, Dialog, DialogContent, DialogActions, Button, Autocomplete } from '@mui/material/';
import CommonButton from '../common/CommonButton';
import { required, selected, valueBetween, valueBelow, valueAbove, truckRegoRegex } from '../../common/validators';
import {
  FIELD,
  REQUIRED_FIELD,
  INLOAD_EXCESS_NET_WEIGHT_WARNING_MESSAGE,
  DATE_DB_FORMAT,
  TIME_DB_FORMAT,
  WEIGHT_DESTINATION_ID,
  DEFAULT_WEIGHT_ID,
  COMMODITIES,
  NGR_REQUIRED_FOR_STOCKS_WARNING_MESSAGE,
  TRUCK_CODES, FREIGHT_CONTRACT_TYPE, PACK_ORDER_TYPE_ID, REGRADE_RESEASON_OPTION_TYPE,
  TRUCK_SEARCH_OPTIONS, OPTION_TYPE_WEB_SPLIT_LOADS,
  SEARCH_BY_FREIGHT_PROVIDER_REGOS,
  SEARCH_BY_ALL_REGOS,
  SEARCH_BY_TRUCK_OWNER_REGOS,
  SYSTEM_COMPANY_IDS,
  NOTICE_NUMBERS,
  MAX_UPLOAD_DOCKETS,
  STORAGE_STOCK_EMPTY_UPDATE_OPTION_TYPES,
  WEIGHT_ORIGIN_ID,
  UNIT_ABBREVIATIONS,
  LOAD_CREATE_OR_EDIT_ERROR_MESSAGE,
  SEASON_NA,
  FERTILISER_IDS
} from '../../common/constants';
import CommonDatePicker from '../common/CommonDatePicker';
import CommonTimePicker from '../common/CommonTimePicker';
import CommodityAutoComplete from '../common/autocomplete/CommodityAutoComplete';
import VarietyAutoComplete from '../common/autocomplete/VarietyAutoComplete';
import SeasonSelect from '../common/select/SeasonSelect';
import CommonSelect from '../common/select/CommonSelect';
import GradeAutoComplete from '../common/autocomplete/GradeAutoComplete';
import SpecParametersValue from '../common/SpecParametersValue';
import APIService from '../../services/APIService';
import { getCommodities } from '../../actions/api/commodities';
import { positiveDecimalFilter } from '../../common/input-filters';
import CommonTextField from '../common/CommonTextField';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import CommonSelectInput from '../common/CommonSelectInput';
import AdditionalMassLimitFields from '../common/AdditionalMassLimitFields';
import {
  getSiteName, getDateTimeInUTC, getDateTimeFromUTC, isSystemCompany, getLoadWarningTableData, generateIdentifier,
  getCountryLabel, isCompanyGrower, getCountryConfig, getCountrySystemCompanyId, isVisibleInCountry, getDefaultTruckTrailerUnit, isMoistureSpecMandatory, getCountryDisplayUnit
} from '../../common/utils';
import alertifyjs from 'alertifyjs';
import FileUpload from '../common/FileUpload';
import { validateRego, isLoading, forceStopLoader } from '../../actions/main';
import { createTruck } from '../../actions/api/trucks';
import { addTruck, emptyCreatedTruck } from '../../actions/companies/trucks';
import { getStoredCommodityDetails } from '../../actions/api/storages';
import CompanyAutocomplete from '../common/autocomplete/CompanyAutocomplete';
import SiteAsyncAutocomplete from '../common/autocomplete/SiteAsyncAutocomplete';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import IconButton from '@mui/material/IconButton';
import { Cancel as RemoveIcon } from '@mui/icons-material'
import AddButton from '../common/AddButton';
import { DELIVERED_STATUS } from '../freights/Constants';
import NumberField from '../common/NumberField';

const autocompleteFilters = createFilterOptions();

const validateInloadDateTime = (pickupDateTime, inloadDate, inloadTime) => {
  if (!pickupDateTime) return { dateError: null, timeError: null };

  const localPickupDateTime = getDateTimeFromUTC(pickupDateTime);
  let dateError = null;
  let timeError = null;

  if (inloadDate) {
    const inloadMoment = moment(inloadDate);
    const pickupMoment = moment(localPickupDateTime.date);

    if (inloadMoment.isBefore(pickupMoment))
      dateError = "Delivery Date can't be before the Pickup Date.";

    else if (inloadMoment.isSame(pickupMoment, 'day') && inloadTime) {
      const inloadDateTime = moment(`${inloadDate} ${inloadTime}`).startOf('minute');
      const pickupDateTime = moment(`${localPickupDateTime.date} ${localPickupDateTime.time}`).startOf('minute');

      if (inloadDateTime.isBefore(pickupDateTime))
        timeError = "Delivery Time can't be before the Pickup Time";
    }
  }

  return { dateError, timeError };
};

class InloadForm extends React.Component {
  constructor(props) {
    super(props);
    this.isFetchedCommodities = false;
    this.generatedIdentifier = generateIdentifier('load');
    const queryParams = queryString.parse(this.props.location.search);
    const load = this.props.inload;
    const storageType = get(props.movement, 'freightDelivery.consignee.sites[0].location.storageType');
    const isConsigneeSiteBHC = get(props.movement, 'freightDelivery.consignee.sites[0].isBhc');
    const isPackMovement = get(props.movement, 'typeId') === PACK_ORDER_TYPE_ID;
    this.farmId = get(this.props.match, 'params.farm_id');
    if (this.farmId)
      this.farmId = parseInt(this.farmId);
    this.storageId = get(this.props.match, 'params.storage_id');
    if (this.storageId)
      this.storageId = parseInt(this.storageId);
    const season = (queryString.season && queryString.season.toLowerCase() !== 'multiple') ? queryString.season : '';
    this.isRegradedLoad = false;
    this.countryTonnageLabel = getCountryLabel('tonnage');
    this.state = {
      unit: getDefaultTruckTrailerUnit(),
      submitting: false,
      existingTruckConfig: {},
      isWarehouseInvoiced: false,
      isFreightInvoiced: false,
      categories: [],
      permits: [],
      inload: load,
      selectedStockOwner: null,
      stockOwner: {
        value: '',
        errors: [],
        validators: [required()]
      },
      companyNgrs: {},
      selectedNgr: undefined,
      isDirectLoad: false,
      isStockUpdateLoad: false,
      isVarietyMandatoryLabel: false,
      providerTrucks: [],
      selectedTrucks:[],
      isCopyFromOutload: false,
      showAmendTonnagePopup: false,
      isSubmit: false,
      massLimitPermit: {
        value: '',
        validators: [required()],
        errors: [],
      },
      ghms: {
        value: false,
        validators: [],
        errors: [],
      },
      code: {
        value: '',
        validators: [],
        errors: [],
      },
      lockStockOwner: false,
      searchOption: (this.props.movement && this.props.inload) ? (get(this.props, 'movement.providerId') == get(this.props, 'inload.truck.companyId') ? SEARCH_BY_FREIGHT_PROVIDER_REGOS : SEARCH_BY_TRUCK_OWNER_REGOS) : this.props.movement ? (get(this.props, 'movement.providerId') == get(this.props.movement, 'plannedTruck.companyId') ? SEARCH_BY_FREIGHT_PROVIDER_REGOS : SEARCH_BY_TRUCK_OWNER_REGOS) : SEARCH_BY_FREIGHT_PROVIDER_REGOS,
      chemicalApplicationRates: [],
      models: {
        inload: {
          comment: cloneDeep(FIELD),
          date: set(cloneDeep(REQUIRED_FIELD), 'value', moment().format(DATE_DB_FORMAT)),
          time: set(cloneDeep(REQUIRED_FIELD), 'value', moment().format(TIME_DB_FORMAT)),
          freightProviderId: cloneDeep(isPackMovement ? FIELD : REQUIRED_FIELD),
          truckId: cloneDeep(isPackMovement ? FIELD : REQUIRED_FIELD),
          truckCompanyId: cloneDeep(FIELD),
          truckCompany: cloneDeep(FIELD),
          commodityId: set(cloneDeep(REQUIRED_FIELD), 'value', parseInt(queryParams.commodityId) || ''),
          varietyId: set(cloneDeep(FIELD), 'validators', [selected()]),
          season: set(cloneDeep(REQUIRED_FIELD), 'value', season),
          ngrId: {
            value: queryParams.ngrId || '',
            validators: [required()],
            errors: [],
          },
          tareWeight: cloneDeep(FIELD),
          grossWeight: cloneDeep(FIELD),
          docketNumber: set(cloneDeep(FIELD), 'validators', []),
          bookingNumber: set(cloneDeep(FIELD), 'validators', []),
          docketImage: {
            value: [],
            validators: [],
            errors: [],
          },
          farmFieldId: cloneDeep(FIELD),
          gradeId: { ...FIELD, value: parseInt(queryParams.gradeId) || '', validators: [selected(), required()] },
          storageId: (isPackMovement) ? cloneDeep(FIELD) : set(props.movement && !load && storageType !== 'system' && !isConsigneeSiteBHC ? cloneDeep(REQUIRED_FIELD) : cloneDeep(FIELD), 'value', undefined),
          bhcSite: cloneDeep(FIELD),
          farmId: cloneDeep(FIELD),
          estimatedNetWeight: {
            value: undefined,
            validators: [required()],
            errors: [],
          },
          orderNumber: set(cloneDeep(FIELD), 'validators', []),
          consignee: {
            handlerId: {
              value: undefined,
              validators: [required()],
              errors: [],
            },
          },
          quantity: {
            value: undefined,
            validators: [],
            errors: [],
          },
          sourceFieldId: {
            value: undefined,
            validators: [],
            errors: [],
          },
          categoryId: {
            value: undefined,
            validators: [],
            errors: [],
          },
          steer_point_5: {
            value: false,
            validators: [],
            errors: [],
          },
          steer_1_point_1: {
            value: false,
            validators: [],
            errors: [],
          },
          noticeNumber: {
            value: undefined,
            validators: [],
            errors: [],
          },
          accreditationNumber: {
            value: undefined,
            validators: [],
            errors: [],
          },
          restricted: {
            value: undefined,
            validators: [],
            errors: [],
          },
          declaredMassLimit: {
            value: undefined,
            validators: [],
            errors: [],
          },
          permitNumber: {
            value: undefined,
            validators: [],
            errors: [],
          },
          containerNumber: {
            value: undefined,
            validators: [],
            errors: [],
          },
          loadIdentifier: cloneDeep(FIELD),
          sealNumbers: {
            value: (isPackMovement) ? get(this.props, 'movement.order.freightContainer.dualSeal') || get(this.props, 'movement.sealNumbers[1]') ? [{
              id: '0',
              value: get(this.props, 'movement.sealNumbers[0]')
            }, {
              id: '1',
              value: get(this.props, 'movement.sealNumbers[1]')
            }] : [{
              id: '0',
              value: get(this.props, 'movement.sealNumbers[0]')
            }] : [],
            validators: [],
            errors: [],
          },
          containerTare: cloneDeep(FIELD),
        },
        specs: {},
      },
      trucks: [],
      ngrs: [],
      sites: [],
      bhcSites: [],
      farmFields: [],
      commoditySpecs: [],
      gradeSpecs: [],
      matchingPackOrders: [],
      matchingPackOrderId: {
        value: null,
        validators: [],
        errors: []
      },
      queryParams,
      storage: null,
      gradeFloatingLabelText: 'Grade',
      docketNumberFloatingLabelText: `${getCountryLabel('docket')} No (Optional)`,
      ngrFloatingLabelText: 'NGR',
      seasonFloatingLabelText: 'Season',
      isNetWeightGreaterThanRemainingSpace: false,
      isFetchingBHC: false,
      isBHCFetched: false,
      initialStorageId: '',
      siteName: props.movement && load ? getSiteName(get(props.movement, 'freightDelivery.consignee'), load) : null,
      showEstimatedNetWeightField: true,
      selectedConsignee: null,
      docketImageFileCount: get(props.movement, 'freightDelivery.docketImageUrl', []).length,
      docketImagePath: uniq(get(props.movement, 'freightDelivery.docketImagePath', [])),
      docketImageUrl: uniq(get(props.movement, 'freightDelivery.docketImageUrl', [])),
      initialEstimatedNetWeight: null,
      loadSharing: false,
      accreditationNumberChecked: false,
      permitChecked: false,
      restrictedChecked: false,
      truckErrors: {},
      showAdditionalMassLimitFields: false,
      amendParentTonnage: false,
      warehouseOverDelivered: false,
      chemicalApplications: [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleSealChange = this.handleSealChange.bind(this);
    this.handleIdentifierChange = this.handleIdentifierChange.bind(this);
    this.handleTareWeightChange = this.handleTareWeightChange.bind(this);
    this.handleGrossWeightChange = this.handleGrossWeightChange.bind(this);
    this.handleSelectFieldChange = this.handleSelectFieldChange.bind(this);
    this.handleCommodityChange = this.handleCommodityChange.bind(this);
    this.handleVarietyChange = this.handleVarietyChange.bind(this);
    this.handleGradeChange = this.handleGradeChange.bind(this);
    this.handleSpecsChange = this.handleSpecsChange.bind(this);
    this.handleSelectFieldBlur = this.handleSelectFieldBlur.bind(this);
    this.handleQuantityFieldChange = this.handleQuantityFieldChange.bind(this);
    this.handleSiteChange = this.handleSiteChange.bind(this);
    this.getSites = this.getSites.bind(this);
    this.checkNetWeightAgainstRemainingSpace = this.checkNetWeightAgainstRemainingSpace.bind(this);
    this.setFieldWarnings = this.setFieldWarnings.bind(this);
    this.getFieldWarnings = this.getFieldWarnings.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
    this.getIsFormValid = this.getIsFormValid.bind(this);
    this.getSubmitData = this.getSubmitData.bind(this);
    this.updateValidatorsBasedOnStorage = this.updateValidatorsBasedOnStorage.bind(this);
    this.getStorage = this.getStorage.bind(this);
    this.getFarmsFarmFields = this.getFarmsFarmFields.bind(this);
    this.getNgrs = this.getNgrs.bind(this);
    this.getTrucks = this.getTrucks.bind(this);
    this.getTonnageFieldsStates = this.getTonnageFieldsStates.bind(this);
    this.onDocketImageUpload = this.onDocketImageUpload.bind(this);
    this.onDocketImageRemove = this.onDocketImageRemove.bind(this);
    this.isNetWeightMoreThanAllowed = this.isNetWeightMoreThanAllowed.bind(this);
    this.handleTruckCodeChange = this.handleTruckCodeChange.bind(this);
    this.setOwnerAndNgrForCustomerOnlyMovements = this.setOwnerAndNgrForCustomerOnlyMovements.bind(this);
    this.getTonnageFieldsStates();
    this.voidLoad = this.voidLoad.bind(this);
    this.submitData = this.submitData.bind(this);
    this.setInvalidIdentifierError = this.setInvalidIdentifierError.bind(this);
    this.checkDirectLoad = this.checkDirectLoad.bind(this);
    this.checkStockUpdateLoad = this.checkStockUpdateLoad.bind(this)
    this.handleContainerTareChange = this.handleContainerTareChange.bind(this);
    this.handleTruckDetailsSelectValueChange = this.handleTruckDetailsSelectValueChange.bind(this);
    this.validateAndSetDateTime = this.validateAndSetDateTime.bind(this)
    this.fetchMatchingPackOrders = this.fetchMatchingPackOrders.bind(this);
    this.setMatchingPackOrder = this.setMatchingPackOrder.bind(this);
    this.validateMatchingPackOrderSelection = this.validateMatchingPackOrderSelection.bind(this);
    this.submitInload = this.submitInload.bind(this);
  }

  validateAndSetDateTime = (value, elementId) => {
    const outloadDateTime = get(this.props.movement, 'freightPickup.load[0].dateTime');
    if (!outloadDateTime) return true;

    const newState = {...this.state};
    const currentDate = get(this.state, 'models.inload.date.value');
    const currentTime = get(this.state, 'models.inload.time.value');

    const { dateError, timeError } = validateInloadDateTime(
      outloadDateTime,
      elementId === 'inload.date' ? value : currentDate,
      elementId === 'inload.time' ? value : currentTime
    );

    newState.models.inload.date.errors = [];
    newState.models.inload.time.errors = [];

    if (dateError)
      newState.models.inload.date.errors = [dateError];

    if (timeError)
      newState.models.inload.time.errors = [timeError];

    if (elementId === 'inload.date')
      newState.models.inload.date.value = value;
    else if (elementId === 'inload.time')
      newState.models.inload.time.value = value;

    this.setState(newState);
    return !dateError && !timeError;
  };

  fetchPermits() {
    APIService.trucks().appendToUrl(`categories/permits/?for_date=${this.state.models.inload.date.value}`).get().then(permits => this.setState({ permits: permits }));
  }

  fetchCategories() {
    APIService.trucks().appendToUrl(`categories/?for_date=${this.state.models.inload.date.value}`).get().then(categories => this.setState({ categories: categories }, () => this.setUpCategoryFromLoadOrTruck()));
  }

  checkShrinkageLoad() {
    return get(this.props, 'inload') && get(this.props.inload, 'shrinkageParent')
  }

  checkDirectLoad() {
    if(!this.props.movement && !(get(this.props.inload, 'checkpointId')) && !(get(this.props.inload, 'titleTransferNumber')) && !(get(this.props.inload, 'optionType')) && !get(this.props.inload, 'shrinkageParent')) {
      const newState = { ...this.state };
      newState.models.inload.loadIdentifier.value = get(this.props.inload, 'extras.identifier') || this.generatedIdentifier;
      newState.models.inload.loadIdentifier.validators = [required()];
      newState.isDirectLoad = true;
      this.setState(newState);
    }
  }

  checkStockUpdateLoad() {
    if (includes(STORAGE_STOCK_EMPTY_UPDATE_OPTION_TYPES, get(this.props.inload, 'optionType')))
      this.setState({ isStockUpdateLoad: true });
  }

  async fetchMatchingPackOrders(season=null, varietyId=null) {
    if (get(this.props.movement, 'id')) {
      let fetchURL =`minimal/pack-movement-creatable/?entity_id=${this.props.movement.id}`
      if(season)
        fetchURL += `&season=${season}`
      if(varietyId)
        fetchURL += `&variety_id=${varietyId}`

      let data = await APIService.freights().orders().appendToUrl(fetchURL).get(this.props.token, {}, null);
      const matchingPackOrderId = filter(data, order => order.id == this.state.matchingPackOrderId.value)[0]?.id
      this.setState({
        matchingPackOrders: data,
        matchingPackOrderId: {
          ...this.state.matchingPackOrderId,
          value: matchingPackOrderId
        }});
    }
  }

  setMatchingPackOrder = (orderId) => {
    const matchingPackOrder = find(this.state.matchingPackOrders, {id: orderId})
    const season = get(matchingPackOrder, 'season')
    const ngrId = get(matchingPackOrder, 'customer.ngrId')
    const customer = get(matchingPackOrder, 'customer')
    this.setState({
      matchingPackOrderId: {
        ...this.state.matchingPackOrderId,
        value: orderId,
        errors: []
      },
      stockOwner: {
        ...this.state.stockOwner,
        value: customer?.companyId
      },
      selectedStockOwner: {
        abn: customer?.abn,
        id: customer?.companyId,
        name: customer?.companyName,
        transactionParticipation: customer?.transactionParticipation,
        typeId: customer?.typeId
      },
      models: {
        ...this.state.models,
        inload: {
          ...this.state.models.inload,
          season: {
            ...this.state.models.inload.season,
            value: season
          },
          ngrId: {
            ...this.state.models.inload.ngrId,
            value: ngrId
          },
          commodityId: {
            ...this.state.models.inload.commodityId,
            value: matchingPackOrder?.commodityId
          },
          gradeId: {
            ...this.state.models.inload.gradeId,
            value: matchingPackOrder?.plannedGradeId
          }
        }
      }
    });
  }

  componentDidMount() {
    this.setStockOwnerFromLoadOrQueryParams();
    this.fetchTruckConfig();
    this.fetchInvoiced()

    if (this.props.movement && this.hasQuantityBasedCommodity(this.props.movement.commodityId)) {
      const newState = { ...this.state };
      newState.models.inload.quantity.validators = [required()];
      this.setState(newState);
    }
    if (isEmpty(this.props.commodities)) {
      this.props.getCommodities(true);
    }

    if (get(this.props.movement, 'status') === 'manual_contract_complete_balanced') {
      const newState = { ...this.state };
      newState.models.inload.truckId.validators = [];
      newState.models.inload.freightProviderId.validators = [];
      this.setState(newState);
    }

    if (!this.props.movement && !get(this.props.inload, 'truckId')) {
      const newState = { ...this.state };
      newState.models.inload.truckId.validators = [];
      newState.models.inload.freightProviderId.validators = [];
      this.setState(newState);
    }

    const commodityId = get(this.state, 'models.inload.commodityId.value');
    if (commodityId) {
      this.handleCommodityChange(commodityId, 'inload.commodityId');
    }
    const useConversions = getCountryConfig()?.showConversions
    if (this.props.inload) {
      const { inload } = this.props;
      const newState = { ...this.state };
      this.props.isLoading();
      newState.models.inload.truckId.value = get(inload, 'truckId');
      this.isRegradedLoad = isEqual(get(inload, 'optionType'), REGRADE_RESEASON_OPTION_TYPE);
      Object.keys(this.state.models.inload).forEach(key => {
        newState.models.inload[key].value = inload[key];
      });
      if(!inload?.freightProviderId && inload?.truck?.companyId)
        newState.models.inload.freightProviderId.value = inload.truck.companyId
      if (inload.dateTime) {
        const localDateTime = getDateTimeFromUTC(inload.dateTime);
        newState.models.inload.date.value = localDateTime.date;
        newState.models.inload.time.value = localDateTime.time;
      }
      if(inload.ngrId) {
        newState.models.inload.ngrId.value = get(inload, 'ngrId')
        newState.stockOwner.value = get(inload, 'ngr.companyId') || inload.ngrCompanyId
      }
      newState.models.inload.loadIdentifier.value = get(inload, 'identifier') || get(inload, 'extras.identifier') || get(inload, 'shrinkageParent');
      newState.models.inload.estimatedNetWeight.value = (useConversions ? inload.netWeightLb || inload?.weightsInLB?.netWeightLb : inload.tonnage || inload.estimatedNetWeight) || inload.tonnage;
      newState.searchOption = get(this.props.movement, 'providerId') == get(inload, 'truck.companyId') ? SEARCH_BY_FREIGHT_PROVIDER_REGOS : SEARCH_BY_TRUCK_OWNER_REGOS;
      newState.models.inload.truckCompanyId.value = get(inload, 'truck.companyId');
      if(get(inload, 'truck.companyId')) {
        let systemCompanyId = getCountrySystemCompanyId();
        if(get(inload, 'truck.companyId') == systemCompanyId)
          newState.models.inload.truckCompany.value = {id: systemCompanyId, name: 'Unknown', transactionParticipation: true}
        else
          APIService.companies(get(inload, 'truck.companyId')).get().then(data => newState.models.inload.truckCompany.value = data);
      }
      if (useConversions) {
        const tareWeight = inload.tareWeightLb || inload?.weightsInLB?.tareWeightLb
        const grossWeight = inload.grossWeightLb || inload?.weightsInLB?.grossWeightLb
        newState.models.inload.tareWeight.value = tareWeight ? parseFloat(tareWeight).toFixed(2) : null;
        newState.models.inload.grossWeight.value = grossWeight ? parseFloat(grossWeight).toFixed(2) : null;
      }
      if (!newState.models.inload.estimatedNetWeight.value)
        newState.models.inload.estimatedNetWeight.value = this.getNetWeight(newState);
      newState.initialEstimatedNetWeight = cloneDeep(newState.models.inload.estimatedNetWeight.value);
      this.setState(newState, () => {
        this.getTrucks(get(inload, 'truck.companyId'));
        this.checkNetWeightAgainstRemainingSpace();
        this.fetchChemicalApplications();
      });
    } else if (this.props.movement) {
      const { movement, flipLoad } = this.props;
      let truck = get(movement, 'plannedTruck');
      this.getTrucks(get(truck, 'companyId'));
      const newState = { ...this.state };
      if (this.isNGRFieldDisabled())
        newState.models.inload.ngrId.value = get(movement, 'commodityContract.buyer.ngrId');
      newState.models.inload.orderNumber.value = get(movement, 'freightDelivery.orderNumber');
      newState.models.inload.freightProviderId.value = movement.providerId;
      newState.models.inload.commodityId.value = movement.commodityId;
      newState.models.inload.varietyId.value = get(movement, 'varietyId');
      newState.models.inload.season.value = movement.season;
      newState.models.inload.gradeId.value = get(movement, 'plannedGradeId') || get(movement, 'commodityContract.gradeId');
      if (!has(flipLoad, 'truckId') && get(movement, 'plannedTruckId')) {
        let truckId = get(movement, 'plannedTruckId');
        newState.models.inload.truckId.value = truckId;
      }
      newState.models.inload.truckCompanyId.value = get(truck, 'companyId') || movement.providerId;
      if (get(truck, 'companyId') === movement.providerId)
        newState.models.inload.truckCompany.value = movement.provider;
      else
        newState.models.inload.truckCompany.value = {id: get(truck, 'companyId'), name: get(truck, 'company.name')}
      if (has(flipLoad, 'truckId')) newState.models.inload.truckId.value = get(flipLoad, 'truckId');
      if (has(flipLoad, 'tareWeight')) newState.models.inload.tareWeight.value = useConversions ? get(flipLoad, 'tareWeightLb') || flipLoad?.weightsInLB?.tareWeightLb : get(flipLoad, 'tareWeight');
      if (has(flipLoad, 'grossWeight')) newState.models.inload.grossWeight.value = useConversions ? get(flipLoad, 'grossWeightLb') || flipLoad?.weightsInLB?.grossWeightLb : get(flipLoad, 'grossWeight');
      if (has(flipLoad, 'netWeight')) newState.models.inload.estimatedNetWeight.value = useConversions ? get(flipLoad, 'netWeightLb')  || flipLoad?.weightsInLB?.netWeightLb : get(flipLoad, 'netWeight');
      if (this.isContainerMovement()) {
        let splitWeights = get(flipLoad, 'splitWeights');
        if (has(splitWeights, 'truckTare'))
          newState.models.inload.tareWeight.value = get(splitWeights, 'truckTare');
      }

      if (!newState.models.inload.estimatedNetWeight.value)
        newState.models.inload.estimatedNetWeight.value = this.getNetWeight(newState);
      this.setState(newState, () => {
        if (get(this.props.movement, 'isBlended'))
          this.onChemicalApplicationAdd();
          this.setGradeSpecs(this.state.models.inload.gradeId.value);
      });
    }

    if(!get(this.props.inload, 'dateTime') && this.props.movement){
      const newState = { ...this.state };
      const plannedFreightDeliveryDate = (
        get(this.props.inload, 'dateTime') ||
        get(this.props.movement, 'freightDelivery.dateTime') ||
        get(this.props.movement, 'freightPickup.load[0].dateTime') ||
        get(this.props.movement, 'freightPickup.dateTime')
      )
      if(plannedFreightDeliveryDate && new Date(plannedFreightDeliveryDate) <= new Date()){
        const localDateTime = getDateTimeFromUTC(plannedFreightDeliveryDate);
        newState.models.inload.date.value = localDateTime.date;
        newState.models.inload.time.value = localDateTime.time;
      }
      this.setState(newState);
    }

    if (this.props.inload && this.props.movement) {
      if (get(this.props.inload, 'dateTime')) {
        const newState = { ...this.state };
        const localDateTime = getDateTimeFromUTC(this.props.inload.dateTime);
        newState.models.inload.date.value = localDateTime.date;
        newState.models.inload.time.value = localDateTime.time;
        this.setState(newState, () => {
          if (get(this.props.inload, 'dateTime'))
            this.setFieldErrors('models.inload.date');
        });
      }
    }

    if(has(this.state.inload, 'netWeight')) {
      const newState = {...this.state};
      newState.models.inload.estimatedNetWeight.value = useConversions ? this.state.inload.netWeightLb : this.state.inload.netWeight;
      this.setState(newState);
    }

    const isContainerMovement = this.isContainerMovement();
    const isPackMovement = this.isPackMovement();
    if (isPackMovement || isContainerMovement)  {
      const newState = { ...this.state };
      newState.models.inload.containerNumber.value = get(this.props, 'movement.containerNumber');
      newState.models.inload.containerTare.value = get(this.props, 'inload') ? get(this.props, 'inload.splitWeights.containerTare') : get(this.props, 'movement.packMovement.containerTare') || get(this.props, 'movement.containerTare');
      if (get(this.props, 'inload'))
        newState.models.inload.tareWeight.value = get(this.props, 'inload.splitWeights.truckTare');
      if (isContainerMovement) {
        newState.models.inload.storageId.value = get(this.props, 'movement.freightPickup.load[0].storageId');
        let ngrId = get(this.props, 'movement.commodityContract.buyer.ngrId');
        if (ngrId)
          newState.models.inload.ngrId.value = ngrId;
        newState.models.inload.storageId.validators = [];
      }
      if (isPackMovement) {
        if (get(this.props, 'movement.sealNumbers[1]') || get(this.props, 'movement.order.freightContainer.dualSeal'))
          newState.models.inload.sealNumbers.value = [{
            id: '0',
            value: get(this.props, 'movement.sealNumbers[0]')
          }, {
            id: '1',
            value: get(this.props, 'movement.sealNumbers[1]')
          }]
        else
          newState.models.inload.sealNumbers.value = [{
            id: '0',
            value: get(this.props, 'movement.sealNumbers[0]')
          }]
      }
      this.setState(newState);
    }

    const inloadDate = get(this.state, 'models.inload.date.value');
    const inloadTime = get(this.state, 'models.inload.time.value');
    const outloadDateTime = get(this.props.movement, 'freightPickup.load[0].dateTime');

    if ((inloadDate || inloadTime) && outloadDateTime) {
      const { dateError, timeError } = validateInloadDateTime(
        outloadDateTime,
        inloadDate,
        inloadTime
      );

      if (dateError || timeError) {
        const currentInload = this.state.models.inload;
        const updatedInload = {
          ...currentInload,
          date: { ...currentInload.date, errors: dateError ? [dateError] : currentInload.date.errors },
          time: { ...currentInload.time, errors: timeError ? [timeError] : currentInload.time.errors }
        };

        this.setState({
          models: {
            ...this.state.models,
            inload: updatedInload
          }
        });
      }
    }

    this.setHandler();
    this.getStorage();
    this.getNgrs();
    this.getFarmsFarmFields();
    this.getSites(true);
    this.setCommoditySpecs(this.state.models.inload.commodityId.value);
    this.setGradeSpecs(this.state.models.inload.gradeId.value);
    this.checkDirectLoad();
    this.checkStockUpdateLoad();
    this.fetchAndSetStockOwner()
    if (this.state.models.inload.date.value) {
      this.fetchCategories();
      this.fetchPermits()
    }
    if (get(this.props.movement, 'isBlended'))
      this.fetchChemicalApplicationRates();

    if(this.props.movement?.id)
      this.fetchMatchingPackOrders();
  }

  fetchChemicalApplicationRates() {
    const companyId = get(this.props.movement, 'commodityContract.seller.companyId');
    if (companyId) {
      APIService.companies(companyId)
        .appendToUrl('application-rates/')
        .get()
        .then(resp => this.setState({chemicalApplicationRates: resp}))
    }
  }

  fetchChemicalApplications() {
    if (get(this.props.movement, 'isBlended')) {
      APIService.loads(this.props.inload.id)
        .appendToUrl('chemical-applications/')
        .get()
        .then(response => {
          if (response && !isEmpty(response)) {
            const newState = {...this.state};
            const movementChemicalApplications = (isEmpty(get(this.props.movement, 'chemicalApplications')) ? get(this.props.movement, 'orderChemicalApplications') : get(this.props.movement, 'chemicalApplications')) || [];
            forEach(response, (chemicalApplication, index) => {
              newState.chemicalApplications.push({
                id: index + 1,
                commodityId: chemicalApplication.commodityId,
                storageId: chemicalApplication.storageId,
                applicationRate: chemicalApplication.applicationFee,
                commodityIdOptions: movementChemicalApplications.map(_chemicalApplication => _chemicalApplication.commodityId),
                commodity: find(this.props.commodities, {id: chemicalApplication.commodityId}),
                chemicalLoadId: chemicalApplication.chemicalLoadId,
                errors: []
              })
            })
            this.setState(newState);
          }
        })
    }
  }

  fetchAndSetStockOwner() {
    if(this.props.movement) {
      APIService.freights().contracts().appendToUrl('inloads/stock-owners/').post({movement_id: this.props.movement.id}).then(response => {
        let lockStockOwner = Boolean(response?.inload && this.props.movement.commodityContractId)
        this.setState({
          ...this.state,
          stockOwner: {...this.state.stockOwner, value: response.inload},
          lockStockOwner: lockStockOwner
        });
      })
    }
  }

  fetchInvoiced() {
    const loadId = this.props?.inload?.id
    if(loadId)
      APIService.loads(loadId).appendToUrl('invoiced/').get().then(response => this.setState({isWarehouseInvoiced: Boolean(response?.warehouse), isFreightInvoiced: Boolean(response?.freight)}))
  }

  fetchTruckConfig = () => {
    const callback = response => {
      if(response.categoryId) {
        const newState = {...this.state}
        newState.existingTruckConfig = response
        newState.models.inload.categoryId.value = response.categoryId
        newState.models.inload.steer_1_point_1.value = response.steer1Point1
        newState.models.inload.steer_point_5.value = response.steerPoint5
        newState.models.inload.permitNumber.value = get(response, 'permitNumber')
        newState.models.inload.accreditationNumber.value = get(response, 'accreditationNumber')
        newState.models.inload.restricted.value = get(response, 'restricted')
        newState.models.inload.declaredMassLimit.value = get(response, 'declaredMassLimit')
        newState.models.inload.noticeNumber.value = get(response, 'noticeNumber')
        newState.permitChecked = Boolean(get(response, 'permitNumber'));
        newState.accreditationNumberChecked = Boolean(get(response, 'accreditationNumber'));
        newState.restrictedChecked = Boolean(get(response, 'restricted'));
        newState.loadSharing = get(response, 'loadSharing');
        this.setState(newState, this.setUpCategoryFromLoadOrTruck)
      }
    }
    if(this.props.inload?.id)
      APIService.loads(this.props.inload.id).appendToUrl('truck-config/').get().then(callback)
    else if(this.props.movement?.id)
      APIService.freights().contracts(this.props.movement.id).appendToUrl('truck-config/inload/').get().then(callback)
  }

  setOwnerAndNgrForCustomerOnlyMovements() {
    if (this.props.movement && includes([FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY, PACK_ORDER_TYPE_ID], this.props.movement.typeId) && this.props.isCreate
      && !get(this.state, 'models.inload.ngrId.value') && get(this.state, 'stockOwner.value') == get(this.props.movement, 'customer.companyId', get(this.props, 'flipLoad.ngr.companyId'))) {
      const { movement, flipLoad } = this.props;
      const newState = { ...this.state };
      set(newState.models, `inload.ngrId.value`, get(movement, 'customer.ngrId', get(flipLoad, 'ngrId')));
      set(newState, 'selectedNgr', get(movement, 'customer.ngr'));
      this.setState(newState);
    }
    else if (this.props.movement && this.props.movement.typeId === PACK_ORDER_TYPE_ID && this.props.isCreate && !get(this.state, 'models.inload.ngrId.value') && get(this.props.movement, 'externalReferenceNumber')) {
      const { movement } = this.props;
      const newState = { ...this.state };
      set(newState.models, `inload.ngrId.value`, get(movement, 'buyer.ngrId'));
      set(newState, 'selectedNgr', get(movement, 'buyer.ngr'));
      this.setState(newState);
    }
  }

  getInitialEstimatedNetWeight() {
    if (this.props.inload && this.state.initialStorageId == this.state.models.inload.storageId.value)
      return get(this.props.inload, 'netWeight', 0);

    if (this.props.movement && this.state.initialStorageId == this.state.models.inload.storageId.value)
      return get(this.props.movement, 'freight_delivery.loads.0.netWeight', 0);

    return 0;
  }

  setStockOwnerFromLoadOrQueryParams() {
    if (!this.isNGRFieldDisabled()) {
      const { inload } = this.props;
      const newState = { ...this.state };
      if (inload) {
        newState.models.inload.ngrId.value = inload.ngrId;
        const isSharedNgr = get(inload, 'ngr.ngrType') === 'shared';
        newState.stockOwner.value = get(this.state.inload, 'stockOwnerId') || (isSharedNgr ? newState.stockOwner.value : get(inload, 'ngr.companyId')) || get(inload, 'ownerId') || newState.stockOwner.value;
      } else if (this.state.queryParams.ngrId) {
        newState.models.inload.ngrId.value = get(this.state, 'selectedNgr.id');
        newState.stockOwner.value = get(this.state, 'selectedNgr.companyId');
      }
      this.setState(newState);
    }
  }

  onCompanyChange = item => {
    const value = item?.id
    const { inload } = this.props;
    const newState = { ...this.state };
    newState.stockOwner.value = value;
    newState.selectedStockOwner = item
    const isSharedNgr = get(inload, 'ngr.ngrType') === 'shared';
    const ownerId = get(inload, 'stockOwnerId') || get(inload, 'ownerId') || (isSharedNgr ? this.state.stockOwner.value : get(inload, 'ngr.companyId'));
    if (ownerId && ownerId === value)
      newState.models.inload.ngrId.value = inload.ngrId;
    else if (value !== get(this.state, 'selectedNgr.companyId'))
      newState.models.inload.ngrId.value = '';
    const cachedNgrs = get(this.state.companyNgrs, value);
    newState.ngrs = cachedNgrs?.length ? cachedNgrs : []
    this.setState(newState, () => {
      if (value) {
        if (isEmpty(cachedNgrs))
          APIService.companies(value).ngrs().appendToUrl('minimal/')
            .get()
            .then(ngrs => this.setState({
              companyNgrs: { ...this.state.companyNgrs, [value]: ngrs },
              ngrs: ngrs,
            }, () => {
              this.setVarietyMandatory()
              this.setOwnerAndNgrForCustomerOnlyMovements()
              this.setStockOwnerMandatoryError()
            }));
      } else {
        this.setStockOwnerMandatoryError()
        this.setVarietyMandatory()
      }
    });
  };


  isVarietyMandatory = () => {
    const { selectedConsignee } = this.state
    let result = false
    if(selectedConsignee?.isVarietyMandatory) {
      const isMandatoryForGrowers = selectedConsignee.userTypeForVarietyMandatory.includes('growers')
      const isMandatoryForNonGrowers = selectedConsignee.userTypeForVarietyMandatory.includes('non_growers')
      let isVarietyMandatoryForLoad = ['inload', 'inload_and_outload'].some(type => selectedConsignee.loadTypeForVarietyMandatory.includes(type))
      result = isMandatoryForGrowers && isMandatoryForNonGrowers && isVarietyMandatoryForLoad
      if(!result && isVarietyMandatoryForLoad && this.state.stockOwner.value) {
        const company = this.state.selectedStockOwner
        const isGrower = isCompanyGrower(company)
        result = isMandatoryForGrowers ? isGrower : !isGrower
      }
    }

    return result
  }

  setVarietyMandatory = () => {
    const isVarietyMandatory = this.isVarietyMandatory()
    if(isVarietyMandatory && !this.state.isVarietyMandatoryLabel) {
      const newState = {...this.state}
      newState.models.inload.varietyId.validators = [required(), selected()];
      newState.isVarietyMandatoryLabel = true;
      setTimeout( () => this.setState(newState), 100)
    } else if(!isVarietyMandatory && this.state.isVarietyMandatoryLabel) {
      const newState = {...this.state}
      newState.models.inload.varietyId.validators = [selected()];
      newState.models.inload.varietyId.errors = [];
      newState.isVarietyMandatoryLabel = false;
      setTimeout( () => this.setState(newState), 100)
    }
  }

  setHandler() {
    const self = this;
    const handler = get(self.props.inload, 'handler') || get(self.props.movement, 'freightDelivery.consignee.handler');
    if (handler) {
      const newState = { ...self.state };
      newState.selectedConsignee = { id: handler.id, name: handler.displayName, ...handler };
      newState.models.inload.consignee.handlerId.value = handler.id;
      newState.models.inload.consignee.handlerId.errors = this.isRestrictedToEditForSite(handler) ? [LOAD_CREATE_OR_EDIT_ERROR_MESSAGE.replace('$action', 'created')] : [];
      if (get(handler.deliveryMandatoryFields, 'docketNumber')){
        newState.docketNumberFloatingLabelText = `${getCountryLabel('docket')} No`
        newState.models.inload.docketNumber.validators = [required()]
      }
      else{
        newState.docketNumberFloatingLabelText = `${getCountryLabel('docket')} No (Optional)`
        newState.models.inload.docketNumber.validators = []
      }
      self.setState(newState, () => {
        if(this.state.selectedConsignee?.id)
          this.getConsigneeSites(this.state.selectedConsignee.id)
        this.setShowAdditionalMassLimitFields()
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEmpty(this.props.commodities) && !this.isFetchedCommodities) {
      this.setCommoditySpecs(this.state.models.inload.commodityId.value);
      this.isFetchedCommodities = true;
    }
    if(prevProps !== this.props) {
      this.checkDirectLoad();
      this.checkStockUpdateLoad();
      this.setGradeSpecs(this.state.models.inload.gradeId.value);
    }
    let outload = this.getCounterPartLoad()
    if(outload?.truckId && this.state.isCopyFromOutload && isNull(this.state.models.inload.truckId.value)) {
      const newState = {...this.state};
      newState.models.inload.truckId.value = outload?.truckId;
      this.setState(newState);
    }
    if(this.props.createdTruck && !find(this.state.trucks, {id: this.props.createdTruck?.id})) {
      const newState = {...this.state};
      newState.trucks = [...this.state.trucks, this.props.createdTruck]
      if(this.state.searchOption != SEARCH_BY_ALL_REGOS)
        this.handleSelectFieldChange(this.props.createdTruck?.id, 'inload.truckId', this.props.createdTruck);
      this.props.emptyCreatedTruck();
      this.setState(newState);
    }
    if(this.props.inload && prevState.searchOption != this.state.searchOption && !this.state.isCopyFromOutload) {
      if (this.state.searchOption == SEARCH_BY_FREIGHT_PROVIDER_REGOS)
        this.getTrucks(get(this.props, "movement.providerId"));
      else
        this.setState({trucks: []})
    }
    if(this.props.isLoading && this.props.inload && !isEmpty(this.state.trucks)) {
      if (!this.state.models.inload.truckCompany.value && get(this.props.inload, 'truck.companyId') == getCountrySystemCompanyId() && this.state.searchOption == SEARCH_BY_TRUCK_OWNER_REGOS) {
        const newState = {...this.state}
        newState.models.inload.truckCompany.value = {id: getCountrySystemCompanyId(), name: 'Unknown', transactionParticipation: true}
        newState.models.inload.truckId.value = get(this.props.inload, 'truckId');
        this.setState(newState)
      }
      if (!this.state.isSubmit)
      this.props.forceStopLoader();
    }
  }

  getTonnageFieldsStates() {
    const newState = { ...this.state };
    if (this.props.movement) {
      newState.showEstimatedNetWeightField = false;
      newState.models.inload.estimatedNetWeight.errors = [];
      newState.models.inload.tareWeight.errors = [];
      newState.models.inload.grossWeight.errors = [];
      if (this.props.movement.isAdHoc && !get(this.props.movement, 'freightDelivery.consignee')) {
        newState.models.inload.consignee.handlerId.validators = [required()];
      }
    } else {
      newState.showEstimatedNetWeightField = true;
      newState.models.inload.estimatedNetWeight.errors = [];
      newState.models.inload.tareWeight.errors = [];
      newState.models.inload.grossWeight.errors = [];
    }
    this.setState(newState);
  }

  setStockOwnerMandatoryError() {
    let hasErrors = false;
    if (!this.isNGRFieldDisabled() && !isSystemCompany()) {
      let errors = [];
      if (!this.state.stockOwner.value) {
        errors = ['This field can not be blank'];
        hasErrors = true;
      }
      const newState = { ...this.state };
      newState.stockOwner.errors = errors;
      this.setState(newState);
    }
    return hasErrors;
  }


  isRestrictedToEditForSite = site => get(site, 'externallySyncSource') || (get(site, 'stocksManagement') && this.props.userCompanyId != get(site, 'companyId') && !isSystemCompany());

  isMassLimitFieldsValid() {
    let isValid = true;
    const ERROR_MESSAGE = "This field is required";
    if (this.state.showAdditionalMassLimitFields && this.state.models.inload.truckId.value) {
      const newState = {...this.state};
      if (this.state.permitChecked) {
        if (!this.state.models.inload.permitNumber.value) {
          isValid = false;
          newState.truckErrors.permitNumber = ERROR_MESSAGE;
        }
        if (!this.state.models.inload.declaredMassLimit.value) {
          isValid = false;
          newState.truckErrors.declaredMassLimit = ERROR_MESSAGE;
        }
      }
      if (this.state.accreditationNumberChecked && !this.state.models.inload.accreditationNumber.value) {
        isValid = false;
        newState.truckErrors.accreditationNumber = ERROR_MESSAGE;
      }
      if (this.state.massLimitPermit.value === 'Notice' && !this.state.models.inload.noticeNumber.value) {
        isValid = false;
        newState.truckErrors.noticeNumber = ERROR_MESSAGE;
      }
      if (this.state.restrictedChecked) {
        if (!this.state.models.inload.restricted.value) {
          isValid = false;
          newState.truckErrors.restricted = ERROR_MESSAGE;
        }
        if (!this.state.models.inload.declaredMassLimit.value) {
          isValid = false;
          newState.truckErrors.declaredMassLimit = ERROR_MESSAGE;
        }
      }
      if (this.state.loadSharing && !this.state.restrictedChecked && !this.state.permitChecked && !this.state.models.inload.declaredMassLimit.value) {
        isValid = false;
        newState.truckErrors.declaredMassLimit = ERROR_MESSAGE;
      }
      this.setState(newState);
    }
    const validator = get(this.state.massLimitPermit.validators, '0')
    if (validator && validator.isInvalid(this.state.massLimitPermit.value) && this.state.code.value) {
      const newState = {...this.state};
      isValid = false;
      newState.massLimitPermit.errors = ['This field is required.']
      this.setState(newState);
    }
    return isValid;
  }

  unlockSubmit = (errorMsg, timeout) => {
    setTimeout(() => {
      this.setState({submitting: false}, () => {
        if(errorMsg)
          alertifyjs.error(errorMsg, 5);
      })
    }, timeout ? timeout : 10)
  }

  isWeighingAtOrigin = () => get(this.props, 'movement.commodityContract.weightId', DEFAULT_WEIGHT_ID) === WEIGHT_ORIGIN_ID

  getMaxAllowedTonnage(tonnageProperty) {
    const parentTonnage = min([get(this.props, 'movement.maxAllowedTonnageOnOrder'), get(this.props, 'movement.parentTotalTonnageWithTolerance')]);
    if (isNumber(parentTonnage) && isNumber(get(this.props, 'movement.parentTotalDeliveredTonnage'))) {
      let maxAllowedTonnage = parentTonnage - get(this.props, 'movement.parentTotalDeliveredTonnage');
      const weightId = get(this.props, 'movement.commodityContract.weightId', DEFAULT_WEIGHT_ID);
      //  for split load, only consider that particular load's weight instead of movement weight,
      //  for non split movement weight is equal to inload weight when contract's inspection weight is not origin
      //  split of outload when inspection weight is origin is not handled
      const useConversions = getCountryConfig()?.showConversions;
      if (!tonnageProperty)
        tonnageProperty = useConversions ? 'netWeightLb' : 'netWeight';
      const counterLoad = this.getCounterPartLoad()
      if (this.props.inload)
        if (weightId === WEIGHT_DESTINATION_ID)
            maxAllowedTonnage = maxAllowedTonnage + Math.max(get(this.props.inload, tonnageProperty), this.props.movement.inferredTonnage);
        else
            maxAllowedTonnage = maxAllowedTonnage + Math.max(this.props.movement.inferredTonnage, get(this.props.inload, tonnageProperty), get(counterLoad, tonnageProperty));
      else if ([DELIVERED_STATUS, 'completed'].includes(get(this.props, 'movement.status'))) {
        if (weightId === WEIGHT_ORIGIN_ID)
          maxAllowedTonnage = maxAllowedTonnage + this.props.movement.inferredTonnage;
      }
      return parseFloat(maxAllowedTonnage.toFixed(2));
    }
    return 0;
  }

  validateMatchingPackOrderSelection = () => {
    if(
      this.isPackMovement() &&
      !get(this.props, 'movement.order') &&
      !this.state.matchingPackOrderId.value
    ){
      this.setState({
        matchingPackOrderId: {
          ...this.state.matchingPackOrderId,
          errors: ['This field is required']
        }
      })
      return false
    }
    return true
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation()
    if (this.state.selectedConsignee && get(this.state.selectedConsignee, 'externallySyncSource')) {
      alertifyjs.error('Cannot perform this operation as site is externally managed', 5);
      return;
    }

    if(
      this.isPackMovement() &&
      !get(this.props, 'movement.order') &&
      this.state.matchingPackOrderId.value
    ){
      const matchingPackOrder = find(this.state.matchingPackOrders, {id: this.state.matchingPackOrderId.value})
      const remainingTonnage = get(matchingPackOrder, 'outstandingTonnage', 0)
      const netWeight = parseFloat(get(this.state.models, 'inload.estimatedNetWeight.value', 0))
      const currentOrderTonnage = get(matchingPackOrder, 'deliveredTonnage', 0)
      const updateToTonnage = currentOrderTonnage + netWeight
      const unit = get(matchingPackOrder, 'unit')

      if(netWeight > remainingTonnage) {
        alertifyjs.confirm(
          'Warning',
          `<div>
              This pack order can take up to only ${remainingTonnage} ${unit}.
              Saving this load will take the ${this.countryTonnageLabel.toLowerCase()} of pack order to ${updateToTonnage.toFixed(2)} ${unit}. Do you want to automatically
              update the pack order ${this.countryTonnageLabel.toLowerCase()} to ${updateToTonnage.toFixed(2)} ${unit} on saving this load?
          </div>`,
          () => {
            this.submitInload()
          },
          () => {
            this.unlockSubmit()
          }
        ).set('labels', { ok: 'Proceed', cancel: 'Cancel' });
        return;
      }
      else
        this.submitInload()
    }
    else
      this.submitInload()
  }

  submitInload = () => {
    if(!this.validateMatchingPackOrderSelection())
      return

    const inloadDateErrors = get(this.state, 'models.inload.date.errors', []);
    const inloadTimeErrors = get(this.state, 'models.inload.time.errors', []);

    if (inloadDateErrors.length > 0 || inloadTimeErrors.length > 0)
        return;

    this.setState({submitting: true}, () => {
      const isMassLimitFieldsValid = this.isMassLimitFieldsValid();
      if (!isMassLimitFieldsValid) {
        this.unlockSubmit()
        return false
      }
      else {
        let check = true;
        const farmId = this.props.inload?.farmId || this.props.inload?.farmField?.farmId
        if(farmId) {
          let existingDeliverySite = this.state.selectedConsignee
          if(existingDeliverySite) {
            if(this.isRestrictedToEditForSite(existingDeliverySite)) {
              this.unlockSubmit()
              return false;
            }
          }
        }

        if (
          this.props.optionType == OPTION_TYPE_WEB_SPLIT_LOADS &&
          this.state.models.inload.storageId.value &&
          get(this.props, 'movement.freightDelivery.load')
        ){
          const loads = this.props.movement.freightDelivery.load
          const isExistingStorage = some(loads, load => get(load, 'storageId') == this.state.models.inload.storageId.value)
          if(isExistingStorage) {
            this.unlockSubmit()
            return false
          }
        }

        let deliverySite = this.state.selectedConsignee
        const counterSlot = this.getCounterPartLoad()
        if (this.isRestrictedToEditForSite(deliverySite)){
          this.unlockSubmit()
          return false;
        }
        else if (this.isContainerMovement() && counterSlot && get(counterSlot, 'handler.id') == this.state.selectedConsignee?.id){
          this.unlockSubmit('Pickup and Delivery sites cannot be the same in container movement')
          return false
        }
        if ((get(this.props, 'movement.typeId') === PACK_ORDER_TYPE_ID || this.isContainerMovement()) && check)
          this.submitData(check);
        else {
          const submitCallback = () => {
            if (check) {
              if(!this.getIsFormValid()) {
                this.unlockSubmit()
                return false
              }
              let deliveryId = get(find(this.state.sites, {id: get(this.state.models.inload, 'storageId.value')}), 'entity') != "farm_field";
              if (deliveryId && this.state.models.inload.consignee.handlerId.value)
                getStoredCommodityDetails(this.state.models.inload.storageId.value, (data) => {
                  let space = this.props.isCreate ? get(find(data, ['id', get(this.state.models.inload, 'commodityId.value')]), 'space') : get(find(data, ['id', get(this.state.models.inload, 'commodityId.value')]), 'space') + this.getInitialEstimatedNetWeight();
                  if ((!some(data, ['id', this.state.models.inload.commodityId.value]) || some(data, ['id', this.state.models.inload.commodityId.value]) && space < get(this.state, 'models.inload.estimatedNetWeight.value', 0)) && !isEmpty(data)){
                    alertifyjs.confirm(
                      'Warning',
                      `<div className="">
                    <p>Please verify the storage information before inloading:</p>
                    <ul style="list-style: none;"><li>Storage Name: ${get(this.state, 'selectedSite.name')}</li>
                    <li>
                    <table>
                      <tr>
                        <th>Commodity</th>
                        <th>Grade</th>
                        <th>Season</th>
                        <th>Available ${getCountryLabel('tonnage')} (${this.priceUnit()})</th>
                        <th>Space Remaining</th>
                      </tr>
                      ${getLoadWarningTableData(data, null, space)}
                    </table>
                    </li>
                    </ul>
                  </div>`,
                      () => this.submitData(check),
                      this.unlockSubmit
                    );
                  }
                  else this.submitData(check);
                });
              else this.submitData(check);
            }
            else
              this.submitData(check);
          }
          this.setAllFieldsErrors(submitCallback)
        }
      }
    })
  }

  async confirmSubmit(check) {
    if(!get(this.state.models.inload.freightProviderId, 'value', false)) {
      let selectedTruckDetails = find(this.state.trucks, { id: this.state.models.inload.truckId.value });
      if(selectedTruckDetails) {
        const newState = { ...this.state };
        newState.models.inload.freightProviderId.value = get(selectedTruckDetails, 'companyId');
        this.setState(newState);
      }
    }
    if (isEmpty(this.state.stockOwner.errors) && this.getIsFormValid() && check) {
      const submitData = this.getSubmitData();
      const storageId = this.state.models.inload.storageId.value || this.props.storageId || this.storageId;
      const successCallback = () => {
        this.props.closeDrawer();
        if(!this.props.isCreate)
          localStorage.setItem('stocks', JSON.stringify({})); // stocks cache burst
        this.props.successCallback ? this.props.successCallback() : window.location.reload()
      };

      if(this.state.isDirectLoad || this.state.isStockUpdateLoad) {
        let isValidIdentifier = await this.checkIdentifier();
        if(!isValidIdentifier && !isEqual(get(this.props.inload, 'extras.identifier'), this.state.models.inload.loadIdentifier.value)) {
          this.setInvalidIdentifierError()
          this.unlockSubmit()
          return false
        } else {
          submitData.loadIdentifier = submitData.loadIdentifier.toUpperCase();
        }
      } else {
        delete submitData.loadIdentifier;
      }
      let datetimeString = this.props.inload ? this.props.inload.dateTime.replace("T", ' ').replace('Z', '') : '';
      datetimeString = datetimeString ? datetimeString.substring(0, 17) + '00' + datetimeString.substring(19) : '';
      if (this.props.inload && datetimeString != submitData.dateTime && get(this.props.inload, 'deviceSource.mobile') && get(this.props.inload, 'completedAt')) {
        setTimeout(() => {
          alertifyjs.confirm(
            'Warning',
            'The load date and time details will be replaced with the data entered here and the date and time data saved from the mobile app will be lost. Please confirm this change.',
            () => {
              submitData['completedAt'] = null;
              if (this.props.inload) {
                this.setState({ isSubmit: true})
                this.props.submit(this.props.inload.id, submitData, successCallback);
                this.unlockSubmit(null, 2000)
              } else if (storageId) {
                this.setState({ isSubmit: true})
                this.props.submit(storageId, submitData, successCallback);
                this.unlockSubmit(null, 2000)
              } else if (this.props.movement) {
                this.setState({ isSubmit: true})
                this.props.submit({ ...submitData, freightMovementId: this.props.movement.id }, successCallback);
                this.unlockSubmit(null, 2000)
              }
            },
            this.unlockSubmit
          );
        }, 500)
      }
      else {
        if (this.props.inload) {
          this.setState({ isSubmit: true})
          this.props.submit(this.props.inload.id, submitData, successCallback);
          this.unlockSubmit(null, 2000)
        } else if (storageId) {
          this.setState({ isSubmit: true})
          this.props.submit(storageId, submitData, successCallback);
          this.unlockSubmit(null, 2000)
        } else if (this.props.movement) {
          this.setState({ isSubmit: true})
          this.props.submit({ ...submitData, freightMovementId: this.props.movement.id }, successCallback);
          this.unlockSubmit(null, 2000)
        }
      }
    }
  }

  async submitData(check) {
    if (!this.getIsFormValid()) {
      this.unlockSubmit();
      return false;
    }
    const maxAllowedTonnage = this.getMaxAllowedTonnage()
    if (get(this.props, 'movement') && maxAllowedTonnage && maxAllowedTonnage < parseFloat(get(this.state.models, 'inload.estimatedNetWeight.value', 0)))
      this.setState({showAmendTonnagePopup: true});
    else
      this.confirmSubmit(check);
  }

  async checkIdentifier() {
    const identifierLoad = await APIService.loads().appendToUrl(`identifier/${this.state.models.inload.loadIdentifier.value.toUpperCase()}/`).get()
    return !identifierLoad;
  }

  setInvalidIdentifierError() {
    const newState = { ...this.state };
    newState.models.inload.loadIdentifier.errors.push('Identifier already exists');
    this.setState(newState);
  }

  handleFieldChange(event) {
    this.setFieldValue(`models.${event.target.id}`, event.target.value);
  }

  handleSealChange(event) {
    const newState = { ...this.state };
    newState.models.inload.sealNumbers.value.find(sealNumber => sealNumber.id === event.target.id).value = event.target.value;
    this.setState(newState);
  }

  handleIdentifierChange(event) {
    const regex = new RegExp('^[a-zA-Z0-9]*$');
    if (regex.test(event.target.value)) {
      this.handleFieldChange(event);
    }
  }

  handleContainerTareChange(event) {
    let value = event.target.value;
    if(isString(value))
      value = value.replaceAll(',', '').replaceAll(' ', '')
    this.setFieldValue('models.inload.containerTare', value, true, state => {
      const newState = {...state};
      newState.models.inload.estimatedNetWeight.value = this.getNetWeight(newState);
      this.setState(newState);
    })
  }

  handleTareWeightChange(event) {
    let value = event.target.value
    if(isString(value))
      value = value.replaceAll(',', '').replaceAll(' ', '')

    this.setFieldValue(`models.${event.target.id}`, value, true, state => {
      const newState = { ...state };
      const tareWeight = parseFloat(newState.models.inload.tareWeight.value);
      newState.models.inload.grossWeight.validators = [valueAbove(tareWeight)];
      newState.models.inload.estimatedNetWeight.value = this.getNetWeight(newState);
      this.setState(newState, () => {
        this.checkNetWeightAgainstRemainingSpace();
        if (get(this.state, 'models.inload.grossWeight.value')) {
          this.setFieldErrors('models.inload.grossWeight');
        }
      });
    });
  }

  handleQuantityFieldChange(event) {
    let value = event.target.value;
    if(isString(value))
      value = value.replaceAll(',', '').replaceAll(' ', '')
    if (value) value = parseFloat(value);

    this.setFieldValue(`models.${event.target.id}`, value, true);
  }

  handleGrossWeightChange(event) {
    let value = event.target.value
    if(isString(value))
      value = value.replaceAll(',', '').replaceAll(' ', '')
    this.setFieldValue(`models.${event.target.id}`, value, true, state => {
      const newState = { ...state };
      const grossWeight = parseFloat(newState.models.inload.grossWeight.value);
      newState.models.inload.tareWeight.validators = [valueBelow(grossWeight)];
      newState.models.inload.containerTare.validators = [valueBelow(grossWeight)];
      newState.models.inload.estimatedNetWeight.value = this.getNetWeight(newState);
      this.setState(newState, () => {
        this.checkNetWeightAgainstRemainingSpace();
        if (get(this.state, 'models.inload.tareWeight.value'))
          this.setFieldErrors('models.inload.tareWeight');
        if (get(this.state, 'models.inload.containerTare.value'))
          this.setFieldErrors('models.inload.containerTare');
      });
    });
  }

  getNetWeight = (newState) => {
    let estimatedNetWeight = parseFloat(newState.models.inload.grossWeight.value) - parseFloat(newState.models.inload.tareWeight.value);
    if (this.isContainerMovement() || this.isPackMovement())
      estimatedNetWeight = parseFloat(newState.models.inload.grossWeight.value) - parseFloat(newState.models.inload.containerTare.value || 0) - parseFloat(newState.models.inload.tareWeight.value || 0);
    if (isNaN(estimatedNetWeight))
      estimatedNetWeight = parseFloat(newState.models.inload.estimatedNetWeight.value);
    return isNaN(estimatedNetWeight) ? 0.0 : parseFloat(estimatedNetWeight).toFixed(2);
  };

  checkAnyWeightExists = site =>
    some([
      get(this.state, `models.${site}.tareWeight.value`),
      get(this.state, `models.${site}.grossWeight.value`),
      get(this.state, `models.${site}.estimatedNetWeight.value`),
    ]);

  validateWeightFields = site =>
    parseFloat(get(this.state, `models.${site}.estimatedNetWeight.value`)).toFixed(2) ===
    (parseFloat(get(this.state, `models.${site}.grossWeight.value`)) - parseFloat(get(this.state, `models.${site}.tareWeight.value`))).toFixed(2);

  handleNetWeightChange = (event) => {
    let value = event.target.value
    if(isString(value))
      value = value.replaceAll(',', '').replaceAll(' ', '')

    this.setFieldValue(`models.${event.target.id}`, value, true, (newState) => {
      if (newState.models.inload.estimatedNetWeight.value > 0 && !this.validateWeightFields('inload')) {
        newState.models.inload.tareWeight.value = undefined;
        newState.models.inload.grossWeight.value = undefined;
        this.setState(newState);
      }
    });
  };

  handleEstimatedNetWeightFieldChange = event => {
    let value = event.target.value
    if(isString(value))
      value = value.replaceAll(',', '').replaceAll(' ', '')
    this.setFieldValue(`models.${event.target.id}`, value, true, () => {
      this.checkNetWeightAgainstRemainingSpace();
    });
  };

  getTonnage = () => {
    const inload = this.state.models.inload;
    const tonnage = inload.grossWeight.value && inload.tareWeight.value
      ? parseFloat(inload.grossWeight.value) - parseFloat(inload.tareWeight.value)
      : inload.estimatedNetWeight.value
        ? parseFloat(inload.estimatedNetWeight.value)
        : null;

    return tonnage ? parseFloat(parseFloat(tonnage).toFixed(2)) : tonnage;
  };

  getQuantity = () => {
    return this.state.models.inload.quantity.value;
  };

  checkNetWeightAgainstRemainingSpace() {
    const grossWeight = parseFloat(this.state.models.inload.grossWeight.value);
    const tareWeight = parseFloat(this.state.models.inload.tareWeight.value);
    let estimatedNetWeight = parseFloat(this.state.models.inload.estimatedNetWeight.value);
    const relevantStocks = find(get(this.state.storage, 'stocks'), stock => {
      return get(stock, 'commodity.id') === this.state.models.inload.commodityId.value;
    });
    let remainingSpace = get(relevantStocks, 'remainingSpace');
    if (!isNumber(remainingSpace) && isEmpty(get(this.state.storage, 'stocks'))) {
      remainingSpace = get(this.state.storage, 'homestorage.size');
    }

    if (grossWeight && tareWeight) {
      estimatedNetWeight = grossWeight - tareWeight;
    }

    const isNetWeightGreaterThanRemainingSpace = estimatedNetWeight && remainingSpace ? estimatedNetWeight > remainingSpace : false;
    this.setState(state => ({ ...state, isNetWeightGreaterThanRemainingSpace }));
  }

  handleSelectFieldBlur(event) {
    const selectValue = get(this.state, `models.${event.target.id}.value`);
    if (selectValue === 'VALUE_NOT_SELECTED' && isEmpty(event.target.value)) {
      this.setFieldValue(`models.${event.target.id}`, event.target.value,() => this.setGradeSpecs(event.target.value));
    }
  }

  regoCallback = data => {
    if(data?.isAvailable) {
      const newState = {...this.state};
      newState.models.inload.truckId.errors = [];
      this.setState(newState)
      let config = getCountryConfig();
      let payload = {
        rego: data?.rego.toUpperCase(),
        tareWeight: get(config, 'truck.tareWeight'),
        grossWeight: get(config, 'truck.grossWeight')
      }
      let companyId = this.state.searchOption == SEARCH_BY_ALL_REGOS ? config?.systemCompanyId : this.state.models.inload.truckCompany.value?.id || config?.systemCompanyId;
      this.props.createTruck(companyId, payload, addTruck);
    }
  }
  handleSelectFieldChange(value, elementId, item) {
    if(value === 'Invalid date')
      value = ''
    let element = elementId == 'massLimitPermit' ? 'massLimitPermit' : `models.${elementId}`;
    let validateAfterSet = elementId != 'inload.truckId'

    if (elementId === 'inload.date' || elementId === 'inload.time') {
      if (!this.validateAndSetDateTime(value, elementId))
        return;
    }

    this.setFieldValue(element, value, validateAfterSet, () => {
      if (includes(['inload.season', 'inload.ngrId'], elementId)) {
        this.checkNetWeightAgainstRemainingSpace();
        if(elementId === 'inload.season')
          this.fetchMatchingPackOrders(value, get(this.state, 'models.inload.varietyId.value'));
      } else if (elementId === 'inload.truckId') {
        if (item && item?.inputValue) {
          if (item?.inputValue.length < 3 || item?.inputValue.length > 10) {
            const newState = {...this.state};
            newState.models.inload.truckId.errors.push(truckRegoRegex().message);
            this.setState(newState);
            return
          } else
            this.props.validateRego(elementId, item?.inputValue, this.regoCallback);
        }
        this.setUpDefaultValueWeightAndOwnerCompany(value, item);
        this.setUpCategoryFromTruck(value);
      } else if (includes(['massLimitPermit', 'inload.code'], elementId)) {
        this.setCategory();
      }
      if (elementId === 'massLimitPermit') {
        if (value === 'Notice') {
          const newState = {...this.state};
          newState.restrictedChecked = false;
          newState.ghms.value = false;
          newState.permitChecked = false;
          this.setState(newState)
        }
        else if(value && value.includes('PBS - ')) {
          const newState = {...this.state};
          newState.ghms.value = false;
          newState.accreditationNumberChecked = false;
          this.setState(newState);
        }
      }
      if (elementId === 'inload.date' && value) {
        let shouldFetchCategories = true;
        if (!isEmpty(this.state.categories)) {
          let category = this.state.categories[0];
          let date = new Date(value);
          if (date >= new Date(get(category, 'fromDate').substring(0, 10)) && date <= new Date(get(category, 'endDate').substring(0, 10)))
            shouldFetchCategories = false;
        }
        if (shouldFetchCategories) {
          this.fetchCategories();
          this.fetchPermits();
        }
      }
    });
  }

  setCategory = () => {
    const category = this.findCategory();
    if (get(category, 'id') !== this.state.models.inload.categoryId.value)
      this.setFieldValue('models.inload.categoryId', get(category, 'id'));
  };

  findCategory = () => {
    const { massLimitPermit, ghms, code } = this.state;
    if (massLimitPermit.value && code.value && !isEmpty(this.state.categories)) {
      const truck_code = code.value.toLowerCase().replaceAll(' ', '').replaceAll('-', '');
      const categories = filter(this.state.categories, category => {
        let truckCode = category.truckCode;
        if (isString(category.truckCode)) {
          truckCode = category.truckCode.toLowerCase().replaceAll(' ', '').replaceAll('-', '');
          return truckCode === truck_code;
        }
      });
      return find(categories, {
        massLimitPermit: massLimitPermit.value, ghms: ghms.value
      });
    }
    return undefined;
  };

  setUpCategoryFromLoadOrTruck = () => {
    let categoryId = this.state.models.inload.categoryId.value;
    if (!categoryId && this.props.isCreate)
      categoryId = get(find(this.state.trucks, { id: this.state.models.inload.truckId.value }), 'categoryId');
    if (categoryId)
      this.setCategoryAndFields(categoryId);
  };

  setUpCategoryFromTruck = truckId => {
    let _truckId = truckId || this.state.models.inload.truckId.value;
    if (!this.props.isCreate && truckId == get(this.props.inload, 'truckId'))
      return;
    if (isEmpty(this.state.categories))
      return;

    const truck = find(this.state.trucks, { id: _truckId });
    if (get(truck, 'categoryId')) {
      const newState = {...this.state};
      newState.models.inload.permitNumber.value = get(truck, 'permitNumber')
      newState.models.inload.accreditationNumber.value = get(truck, 'accreditationNumber')
      newState.models.inload.restricted.value = get(truck, 'restricted')
      newState.models.inload.declaredMassLimit.value = get(truck, 'declaredMassLimit')
      newState.models.inload.noticeNumber.value = get(truck, 'noticeNumber')
      newState.loadSharing = Boolean(get(truck, 'loadSharing'));
      newState.permitChecked = Boolean(get(truck, 'permitNumber'));
      newState.accreditationNumberChecked = Boolean(get(truck, 'accreditationNumber'));
      newState.restrictedChecked = Boolean(get(truck, 'restricted'));
      this.setState(newState, () => this.setCategoryAndFields(truck.categoryId));
    }
    else {
      const newState = { ...this.state };
      newState.models.inload.categoryId.value = null;
      newState.massLimitPermit.value = null;
      newState.code.value = get(truck, 'code');
      newState.ghms.value = null;
      newState.models.inload.steer_1_point_1.value = null;
      newState.models.inload.steer_point_5.value = null;
      this.setState(newState);
    }
  };

  setCategoryAndFields(categoryId) {
    const category = find(this.state.categories, { id: categoryId });
    const truck = find(this.state.trucks, { id: this.state.models.inload.truckId.value });
    if (category) {
      const newState = { ...this.state };
      newState.models.inload.categoryId.value = categoryId;
      newState.massLimitPermit.value = category.massLimitPermit;
      newState.code.value = category.truckCode;
      newState.ghms.value = category.ghms;
      if(this.state.models.inload.truckId.value == get(this.props.inload, 'truckId') && !this.props.isCreate){
        //pass
      } else if (truck?.categoryId === categoryId) {
        newState.models.inload.steer_1_point_1.value = get(truck, 'steer1Point1');
        newState.models.inload.steer_point_5.value = get(truck, 'steerPoint5');
      }
      this.setState(newState);
    }
  }

  setUpDefaultValueWeightAndOwnerCompany = (truckId, item) => {
    const truck = find(this.state.trucks, { id: truckId }) || item;
    if (truck) {
      const newState = { ...this.state };
      if(!this.checkAnyWeightExists('inload')) {
        if (this.state.selectedConsignee?.populateDefaultWeights) {
          newState.models.inload.tareWeight.value = get(truck, 'totalWeights.tareWeight');
          newState.models.inload.grossWeight.value = get(truck, 'totalWeights.grossWeight');
          newState.models.inload.estimatedNetWeight.value = get(truck, 'totalWeights.netWeight');
        }
      }
      newState.models.inload.estimatedNetWeight.errors = [];
      newState.models.inload.truckCompanyId.value = get(truck, 'companyId');
      if(get(truck, 'companyId') && (this.state.searchOption == SEARCH_BY_ALL_REGOS || this.props.inload)) {
        let systemCompanyId = getCountrySystemCompanyId();
        if(get(truck, 'companyId') == systemCompanyId)
          newState.models.inload.truckCompany.value = {id: systemCompanyId, name: 'Unknown', transactionParticipation: true}
        else
          newState.models.inload.truckCompany.value = {id: get(item, 'companyId'), name: get(item, 'companyName') , transactionParticipation: get(item, 'transactionParticipation')};
      }
      if(!find(this.state.trucks, { id: item.id }))
        newState.trucks.push(item);
      newState.models.inload.truckId.value = truckId
      this.setState(newState);
    }
  };

  handleCommodityChange(value, elementId) {
    const pastCommodityId = this.state.models.inload.commodityId.value
    this.setCommoditySpecs(value);
    this.setFieldValue(`models.${elementId}`, value, true, () => this.setPostCommodityChange(pastCommodityId));
  }

  setCommoditySpecs = value => {
    let isCommodityCanola = false;
    if (value === COMMODITIES.CANOLA) {
      isCommodityCanola = true;
    }
    const commoditySpecs = get(find(this.props.commodities, { id: value }), 'specs', []);
    const modelSpecs = this.getModelSpecsByCommoditySpecs(commoditySpecs, isCommodityCanola);
    if (!isEqual(commoditySpecs, modelSpecs)) {
      this.setState(
        state => ({
          ...state,
          models: set(state.models, 'specs', modelSpecs),
          commoditySpecs
        }), this.syncCoilAndImpu);
    }
  };

  setGradeSpecs = gradeId => {
    const value = gradeId || this.state.models.inload.gradeId.value;
    const commodityGrades = get(
      find(this.props.commodities, { id: this.state.models.inload.commodityId.value }),
      'grades',
      []
    );
    const gradeSpecs = get(
      find(commodityGrades, { id: value }),
      'specs',
      []
    );
    const gtaCode = get(
      find(commodityGrades, { id: value }),
      'gtaCode',
      []
    );
    if (value) {
      const updatedSpecs = gtaCode ? gradeSpecs : [];
      
      this.setState(
        state => ({
          ...state,
          gradeSpecs: updatedSpecs
        }), 
        () => {
          forEach(gradeSpecs, (specCode) => 
            this.setFieldWarnings(`models.specs.${specCode['code']}`, specCode['code'])
          );
        }
      );
    }
  } 

  getCounterPartLoad() {
    const { movement, flipLoad } = this.props;
    return flipLoad || get(movement, 'freightPickup.load.0');
  }

  hasCOILOrIMPU(load) {
    if (load)
      return get(load, 'specs.coil') || get(load, 'specs.impu');
  }

  syncCoilAndImpu() {
    const { inload } = this.props;
    const counterPart = this.getCounterPartLoad();
    if (this.hasCOILOrIMPU(counterPart) && !this.hasCOILOrIMPU(inload) && !isEmpty(this.state.models.specs)) {
      const newState = { ...this.state };

      newState.models.specs['COIL'].value = counterPart.specs['coil'];
      newState.models.specs['IMPU'].value = counterPart.specs['impu'];
      this.setState(newState);
    }
  }

  setPostCommodityChange = (pastCommodityId = null) => {
    this.checkNetWeightAgainstRemainingSpace();
    const newState = { ...this.state };
    if (this.hasQuantityBasedCommodity())
      newState.models.inload.quantity.validators = [required()];
    else if(!isEmpty(this.props.commodities)) {
      newState.models.inload.quantity.validators = [];
      newState.models.inload.quantity.errors = [];
      newState.models.inload.quantity.value = undefined;
    }
    if(FERTILISER_IDS.includes(this.state.models.inload.commodityId.value) && !newState.models.inload.season.value)
      newState.models.inload.season.value = SEASON_NA
    else if(FERTILISER_IDS.includes(pastCommodityId) && !FERTILISER_IDS.includes(this.state.models.inload.commodityId.value) && newState.models.inload.season.value)
      newState.models.inload.season.value = null
    this.setState(newState);
  };

  handleVarietyChange(value) {
    if (this.state.models.inload.varietyId.value !== value) {
      this.setFieldValue(
        'models.inload.varietyId',
        value,
        false,
        () => this.fetchMatchingPackOrders(get(this.state, 'models.inload.season.value'), value, get(this.state, 'models.inload.ngrId.value'))
      );
    }
  }

  handleGradeChange(object) {
    if (object && this.state.models.inload.gradeId.value !== object?.id) {
      this.setFieldValue('models.inload.gradeId', isNumber(object.id) ? object.id : undefined, true, () => {
        this.checkNetWeightAgainstRemainingSpace();
        this.setGradeSpecs(object.id);
      }
      );
    }
    if (!isEmpty(this.state.chemicalApplications))
      this.setState({chemicalApplications: []}, () => this.onChemicalApplicationAdd())
  }

  handleSpecsChange(specCode, specValue) {
    this.setFieldValue(`models.specs.${specCode}`, specValue);
    this.setFieldWarnings(`models.specs.${specCode}`, specCode);
  }

  setFieldValue(path, value, validateAfterSet = true, callback) {
    this.setState(
      state => set(state, `${path}.value`, value),
      () => {
        if (callback) callback(this.state);
        if (validateAfterSet) this.setFieldErrors(path);
      },
    );
  }

  setFieldWarnings(path, specCode) {
    this.setState(state => set(state, `${path}.warnings`, this.getFieldWarnings(path, specCode)));
  }

  setFieldErrors(path) {
    this.setState(state => set(state, `${path}.errors`, this.getFieldErrors(path)));
  }

  canRaiseMovementParentAmendRequest() {
    return get(this.props, 'movement.canRaiseParentAmendRequest');
  }

  getFieldWarnings(path, specCode) {
    const { gradeSpecs } = this.state;
    const warnings = [];
    const value = get(this.state, `${path}.value`);
    
    gradeSpecs.forEach(spec => {
      if (spec['code'] === specCode) {
        const min = get(spec, 'min', null)
        const max = get(spec, 'max', null)
        if (!(isNull(min) || isNull(max) || isNull(value))){
          if (!(value >= min && value <= max))
            warnings.push(`GTA Suggested Range: ${min} - ${max}`);
        }
      }}
    )
    return warnings;
  }

  getFieldErrors(path) {
    const errors = [];
    const value = get(this.state, `${path}.value`);
    const validators = get(this.state, `${path}.validators`, []);

    validators.forEach(validator => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
      if (path == 'models.inload.date' && this.props.movement &&
        get(this.props.movement, 'freightPickup.load[0].dateTime') && value) {
        const localDateTime = getDateTimeFromUTC(get(this.props.movement, 'freightPickup.load[0].dateTime'));
        if (moment(value).isBefore(moment(localDateTime.date)))
          errors.push("Delivery Date can't be before the Pickup Date.");
      }
      if (path == 'models.inload.estimatedNetWeight' && this.props.movement) {
        const maxAllowedTonnage = this.getMaxAllowedTonnage();
        if (maxAllowedTonnage && parseFloat(value) > maxAllowedTonnage)
          errors.push(`${this.canRaiseMovementParentAmendRequest() ? 'Warning: ': ''}Maximum allowed tonnage is ${maxAllowedTonnage} ${this.state.unit}`);
      }
    });
    if (path == 'models.inload.consignee.handlerId' && this.isRestrictedToEditForSite(this.state.selectedConsignee)) {
      errors.push(LOAD_CREATE_OR_EDIT_ERROR_MESSAGE.replace('$action', 'created'))
    }
    if (
      path == 'models.inload.storageId' &&
      this.state.models.inload.storageId.value &&
      get(this.props, 'movement.freightDelivery.load')
    ){
      const loads = this.props.movement.freightDelivery.load
      const isExistingStorage = some(loads, load => get(load, 'storageId') == this.state.models.inload.storageId.value)
      if(isExistingStorage){
        if(this.props.isCreate)
          errors.push('Load exists for this storage. Please select another storage.')
        else if(get(this.props.inload, 'storageId') != this.state.models.inload.storageId.value)
          errors.push('Load exists for this storage. Please select another storage.')
      }
    }

    return errors;
  }

  setAllFieldsErrors(callback) {
    let newState = cloneDeep(this.state);

    forEach(newState.models, (model, modelKey) => {
      forEach(model, (field, fieldKey) => {
        const path = `models.${modelKey}.${fieldKey}`;
        newState = set(newState, `${path}.errors`, this.getFieldErrors(path));
      });
    });

    newState.chemicalApplications = this.state.chemicalApplications.map(application => {
      let errors = [...application.errors];
      if (!application.commodityId || !application.storageId || !application.applicationRate)
        errors.push('This field is required');
      return { ...application, errors };
    });

    this.setState(newState, () => {
      this.setStockOwnerMandatoryError();
      if (callback)
        callback()
    });
  }

  getIsFormValid() {
    if (!isEmpty(this.state.models.inload.estimatedNetWeight.errors) && (!isSystemCompany() || this.state.models.inload.estimatedNetWeight.value) && !this.canRaiseMovementParentAmendRequest())
      return false;
    if (!isEmpty(this.state.chemicalApplications) && this.state.chemicalApplications.some(chemicalApplication => !isEmpty(chemicalApplication.errors) || !isEmpty(chemicalApplication.commodityErrors)))
      return false;
    if (!isEmpty(this.state.models.inload.consignee.handlerId.errors) && !isSystemCompany())
      return false;
    return !some(this.state.models, (model, modelName) => {
      const fieldsToOmit = modelName === 'inload' && !this.props.movement ? ['freightProviderId', 'truckId', 'farmFieldId'] : [];
      if (isSystemCompany())
        fieldsToOmit.push('storageId', 'estimatedNetWeight', 'ngrId', 'consignee', 'netWeight', 'season', 'freightProviderId', 'truckId', 'farmFieldId', 'gradeId')
      return some(omit(model, fieldsToOmit), field => {
        return some(field.validators, validator => {
          return validator.isInvalid(field.value);
        });
      });
    });
  }

  getSubmitData() {
    const { movement } = this.props;
    let data = {};
    const isEditing = this.props.inload

    const avoidableFields = ['storageId', 'consignee', 'netWeight'];
    forEach(this.state.models, (model, modelKey) => {
      forEach(model, (field, fieldKey) => {
        const path = `models.${modelKey}.${fieldKey}`;
        if (!includes(avoidableFields, fieldKey)) {
          data = set(data, `${modelKey}.${fieldKey}`, get(this.state, `${path}.value`, null));
        }
      });
    });
    if(isSystemCompany() && !data.inload.estimatedNetWeight)
      data.inload.estimatedNetWeight = null;
    if (!this.isContainerMovement() && !this.isPackMovement()) {
      delete data.inload.containerNumber;
    }
    data.inload.storageId = null;
    const site = get(this.props.movement, 'freightDelivery.consignee.sites[0].location');
    if (get(site, 'entity') === 'storage' && !isSystemCompany()) {
      data.inload.storageId = site.id;
    }

    if (this.state.models.inload.consignee.handlerId.value && this.state.selectedSite && get(this.state.selectedSite, 'entity') === 'storage') {
      data.inload.storageId = this.state.selectedSite.id;
      data.inload.farmFieldId = null;
    }


    if (get(this.state.selectedSite, 'entity') === 'farm_field') {
      data.inload.storageId = null;
      data.inload.farmFieldId = this.state.selectedSite.id;
      data.inload.sourceFieldId = this.state.models.inload.sourceFieldId.value;
    }

    if (this.props.inload && this.props.storageId) {
      data.inload.storageId = this.props.storageId;
    }

    if (this.props.movement) {
      data.inload.consignee = {
        handlerId: this.state.models.inload.consignee.handlerId.value,
      };
    }

    if (this.isContainerMovement() || this.isPackMovement()) {
      let containerTare = parseFloat(data.inload.containerTare || 0);
      let truckTare = parseFloat(data.inload.tareWeight || 0);
      data.inload['splitWeights'] = {'container_tare': containerTare, 'truck_tare': truckTare};
      data.inload.tareWeight = truckTare + containerTare;
      if (data.inload.tareWeight === 0)
        data.inload.tareWeight = null;
    }
    delete data.inload.containerTare;

    if (isNaN(parseFloat(data.inload.estimatedNetWeight))) {
      data.inload.estimatedNetWeight = null;
    }

    if (isNaN(parseFloat(data.inload.tareWeight))) {
      data.inload.tareWeight = null;
    }

    if (isNaN(parseFloat(data.inload.grossWeight))) {
      data.inload.grossWeight = null;
    }

    const utcDateTime = getDateTimeInUTC(data.inload.date, data.inload.time);
    data.inload.dateTime = utcDateTime.dateTime;
    data = omit(data, ['inload.date', 'inload.time']);
    if (movement) {
      if (this.props.inload) {
        data.inload.docketImagePath = this.state.docketImagePath;
      }
      data.inload.freightMovementId = movement.id;
      if (!isEditing)
        data.inload.identifier = this.generatedIdentifier;
    }
    data.inload.quantity = this.state.models.inload.quantity.value;

    if (
      !includes(['homestorage', 'systemstorage', 'storage'], get(this.state.selectedSite, 'entity')) &&
      !includes(['homestorage', 'systemstorage', 'storage'], get(this.props.movement, 'freightDelivery.consignee.sites[0].locationContentType'))
    ) {
      delete data.inload.storageId;
    }
    data.inload['loadSharing'] = this.state.loadSharing;

    delete data.inload.bhcSite;
    delete data.inload.truckCompanyId;
    delete data.inload.searchOption;
    delete data.inload.truckCompany;
    if (this.isPackMovement())
      data.inload.seal_numbers = this.state.models.inload.sealNumbers.value.map(sealNumber => defaultTo(sealNumber.value, ''));
    if (this.state.amendParentTonnage && this.props.movement?.id) {
      const parentTonnage = min([get(this.props, 'movement.maxAllowedTonnageOnOrder'), get(this.props, 'movement.parentTotalTonnageWithTolerance')]);
      data.inload.amendParentToTonnage = (parentTonnage + parseFloat(get(this.state.models, 'inload.estimatedNetWeight.value', 0)) - this.getMaxAllowedTonnage());
      data.inload.warehouseOverDelivered = this.state.warehouseOverDelivered
    }
    if (this.state.chemicalApplications.length > 0) {
      data.inload.chemicalApplications = this.state.chemicalApplications.map(chemicalApplication => {
        let chemicalApplicationData = {
          commodityId: chemicalApplication.commodityId,
          storageId: chemicalApplication.storageId,
          applicationFee: chemicalApplication.applicationRate
        }
        if (get(chemicalApplication, 'chemicalLoadId'))
          chemicalApplicationData.chemicalLoadId = get(chemicalApplication, 'chemicalLoadId')
        return chemicalApplicationData;
      });
    }
    return {
      ...data.inload,
      specs: data.specs,
      optionType: get(this.props, 'optionType'),
      orderId: (this.isPackMovement() && !get(this.props, 'movement.order')) ? this.state.matchingPackOrderId.value: undefined
    };
  }

  getModelSpecsByCommoditySpecs(commoditySpecs, isCommodityCanola) {
    const modelSpecs = {};
    const isMoistureMandatory = isMoistureSpecMandatory();
    const mandatorySpecs = get(this.state.selectedConsignee, 'deliveryMandatoryFields.specs')
    const selectedCommodity = this.getSelectedCommodity(this.state.models.inload.commodityId.value)
    let mandatorySpecForSelectedCommodity = [];
    if (mandatorySpecs && selectedCommodity)
      mandatorySpecForSelectedCommodity = get(mandatorySpecs, selectedCommodity.name.toLowerCase(), [])
    if (!isEmpty(commoditySpecs)) {
      forEach(orderBy(commoditySpecs, 'order'), (spec) => {
        let validators = [valueBetween(spec.min, spec.max, true)];
        if (((isCommodityCanola && includes(['COIL', 'IMPU'], spec.code)) || (isMoistureMandatory && spec.code === 'M')))
          validators.push(required());
        if (includes(mandatorySpecForSelectedCommodity, spec.code.toLowerCase()))
          validators.push(required())
        let specValue = get(this.props.inload, `specs.${spec.code.toLowerCase().replace('-', '')}`, null) ?? get(this.props.inload, `specs.${spec.code.replace('-', '')}`, null);
        modelSpecs[spec.code] = {
          ...FIELD,
          value: specValue,
          validators,
        };
      });
    }
    return modelSpecs;
  }

  getTrucks(companyId, callback) {
    const providerId =  companyId || get(this.props, 'inload.truck.companyId') || get(this.props, 'inload.freightProviderId') || get(this.props, 'movement.freightPickup.load.0.freightProviderId') || get(this.props, 'movement.subFreightProviderId') || get(this.props, 'movement.providerId');
    if (this.props.movement && providerId) {
      let service = APIService.companies(providerId).trucks()
      let truckId = get(this.props, 'inload.truckId') || get(this.props, 'movement.plannedTruckId');
      if (truckId)
        service.appendToUrl(`?include_truck_id=${truckId}`)
      service.get(this.props.token, null, {minimal: true})
        .then(trucks => {
          const newState = {...this.state};
          newState.trucks = trucks;
          if (!this.state.models.inload.truckId.value)
            newState.models.inload.truckId.value = truckId;
          this.setState(newState);
          if (callback) callback();
        });
    }
  }

  getNgrs() {
    const { companyId, companyIds, farmId, farmIds, ngrId, movement, token } = this.props;
    let _companyIds = companyIds || (companyId ? [companyId] : []);
    let _farmIds = farmIds || (farmId ? [farmId] : [get(this.props, 'match.params.farm_id')]);
    if (isEmpty(_farmIds) && this.farmId)
      _farmIds = [this.farmId];
    if (this.props.inload) {
      let stockOwner = get(this.props.inload, 'ngr.companyId')
      _companyIds = stockOwner ? [stockOwner] : [];
      _farmIds = []
    }
    else {
    if (movement && !get(this.props, 'movement.commodityContract')) {
      _companyIds = uniq(compact([
        ..._companyIds,
        get(movement, 'customer.companyId'),
        get(movement, 'commodityContract.seller.companyId'),
        get(movement, 'commodityContract.buyer.companyId')
      ]));
      _farmIds = uniq(compact([
        ..._farmIds,
        get(movement, 'freightPickup.consignor.handlerId'),
        get(movement, 'freightDelivery.consignee.handlerId')
      ]));
    }
    else if (get(this.props, 'movement.commodityContract')) {
      _companyIds = [get(this.props, 'movement.commodityContract.buyer.companyId')];
      _farmIds = [];
    }
    }

    const companyQueryString = map(_companyIds, id => { return `company_ids=${id}`; }).join('&');
    const farmQueryString = map(_farmIds, id => { return `farm_ids=${id}`; }).join('&');

    if ((companyQueryString || farmQueryString) && !get(this.state, 'queryParams.ngrId')) {
      const apiPath = APIService.ngrs().appendToUrl(`?${companyQueryString}&${farmQueryString}`);
      apiPath.get(token).then(ngrs => {
        this.setState(
          { ngrs: ngrs },
          () => {
            if(!this.props.inload) {
            if (movement && ngrId) {
              const newState = { ...this.state };
              newState.models.inload.ngrId.value = ngrId;
              this.setState(newState);
            }
            else if (ngrs.length == 1) {
              const newState = { ...this.state };
              newState.models.inload.ngrId.value = ngrs[0].id;
              this.setState(newState);
            }
          }
          },
        );
      });
    } else this.getNgr();
  }

  getNgr() {
    const { inload, token } = this.props;
    const ngrId = get(this.state, 'queryParams.ngrId');
    if (ngrId && !inload) {
      APIService.ngrs(ngrId).get(token).then(ngr => {
        const newState = {...this.state}
        if(ngr) {
          newState.models.inload.ngrId.value = ngr.id
        }
        newState.selectedNgr = ngr
        this.setState(newState);
      });
    }
  }

  getFarmsFarmFields() {
    if (this.props.farmId || this.farmId) {
      APIService.farms(this.props.farmId || this.farmId)
        .farm_fields()
        .get(this.props.token)
        .then(farmFields => {
          this.setState({ farmFields: farmFields });
        });
    }
  }

  getStorage() {
    if (this.props.storageId) {
      APIService.storages(this.props.storageId)
        .appendToUrl('?no_stocks&amend_archived')
        .get(this.props.token)
        .then(storage => {
          this.setState(
            { storage: storage },
            this.updateValidatorsBasedOnStorage,
          );
        });
    } else if (this.props.movement) {
      const site = get(this.props.movement, 'freightDelivery.consignee.sites[0].location');
      if (get(site, 'entity') === 'storage') {
        this.setState(
          state => ({ ...state, storage: site }),
          () => this.updateValidatorsBasedOnStorage(),
        );
      }
    }
  }

  handleConsigneeChange = (item) => {
    const newState = { ...this.state };
    const value = item?.id
    newState.models.inload.consignee.handlerId.value = value
    newState.models.inload.consignee.handlerId.errors = this.isRestrictedToEditForSite(item) ? [LOAD_CREATE_OR_EDIT_ERROR_MESSAGE.replace('$action', 'created')] : [];
    if (item) {
      newState.selectedConsignee = item;
      if (!this.isContainerMovement()) {
        newState.models.inload.storageId.validators = [selected(), required()];
      }
    } else {
      newState.selectedConsignee = undefined;
      newState.sites = [];
      newState.models.inload.storageId.validators = [];
    }
    this.setState(newState, () => {
      if(this.state.selectedConsignee?.id)
        this.getConsigneeSites(this.state.selectedConsignee?.id);
      if(!isSystemCompany())
        this.setFieldErrors('models.inload.consignee.handlerId');
      this.setFieldErrors('models.inload.storageId');
      this.setVarietyMandatory()
      this.setShowAdditionalMassLimitFields()
    });
  };

  setShowAdditionalMassLimitFields() {
    if (this.state.selectedConsignee) {
      const companyId = this.state.selectedConsignee.companyId;
      if (includes(SYSTEM_COMPANY_IDS, companyId))
        this.setState({showAdditionalMassLimitFields: false})
      else if(companyId) {
        APIService.companies(companyId)
          .get()
          .then(response => this.setState({showAdditionalMassLimitFields: get(response, 'additionalMassLimitCodes')}))
      }
    }
  }

  getConsigneeSites = async (handlerId) => {
    let queryString = 'storages/home/?no_stocks&no_aggregations&no_relations';
    if (get(this.props, 'inload.storageId') || this.storageId)
      queryString = queryString + `&storageId=${get(this.props, 'inload.storageId', this.storageId)}`;
    let homeStorages = await APIService.farms(handlerId).appendToUrl(queryString).get(this.props.token);
    let sites = map(homeStorages, (storage) => {
      return merge(storage, {
        id: storage.id,
        entity: 'storage',
        storageType: 'home',
      });
    });
    if (this.props.movement) {
      let farmFields = await APIService
        .farms(handlerId)
        .farm_fields()
        .get(this.props.token);

      farmFields = map(farmFields, ff => {
        return merge(ff, { entity: 'farm_field' });
      });

      sites = [...farmFields, ...sites];
    }
    if (this.props.movement && get(this.props.inload, 'farmFieldId') && !get(this.props.inload, 'farmField.isActive')) {
      let farmField = find(sites, {id: get(this.props.inload, 'farmFieldId'), entity: 'farm_field'})
      if (!farmField) {
        farmField = get(this.props.inload, 'farmField')
        if(farmField) {
          farmField['entity'] = 'farm_field';
          sites.push(farmField);
        }
      }
    }
    let selectedStorage = find(sites, { id: get(this.props.inload, 'siteId') }) || this.state.selectedSite;
    if (this.props.storageId && !selectedStorage)
      selectedStorage = find(sites, { id: this.props.storageId });
    if (this.storageId && !selectedStorage)
      selectedStorage = find(sites, { id: this.storageId });
    if (!selectedStorage) {
      let storageId = get(this.state, 'models.inload.storageId.value');
      if (storageId) {
        selectedStorage = find(sites, { id: storageId });
      }
    }
    this.setState({
      sites: sites,
      selectedSite: selectedStorage,
      models: {
        ...this.state.models,
        inload: {
          ...this.state.models.inload,
          storageId: {
            ...this.state.models.inload.storageId,
            value: get(selectedStorage, 'id')
          }
        }
      }
    });
  };

  async getSites(isMounting = false) {
    let consignee = null;
    let storageId = this.props.inload?.storageId || this.storageId;
    let farmFieldId = this.props.inload?.farmFieldId;
    let handlerId = this.props.inload?.farmId
    let storage = this.props.inload?.storage
    if (this.props.movement) {
      consignee = this.props.movement?.freightDelivery?.consignee
      handlerId = handlerId || consignee?.handlerId
    }
    let sites = [];
    if (handlerId) {
      let queryString = 'storages/home/?no_stocks';
      if (storageId)
        queryString = queryString + `&storageId=${storageId}`;
      let homeStorages = await APIService
        .farms(handlerId)
        .appendToUrl(queryString)
        .get(this.props.token);
      let farmFields = await APIService
        .farms(handlerId)
        .farm_fields()
        .get(this.props.token);

      homeStorages = map(homeStorages, storage => {
        return merge(storage, {
          id: storage.id,
          entity: 'storage',
          storageType: 'home',
        });
      });
      farmFields = map(farmFields, ff => {
        return merge(ff, { entity: 'farm_field' });
      });
      sites = farmFields.concat(homeStorages);
    }
    if (!isEqual(this.state.sites, sites)) {
      this.setState({ sites: sites }, () => {
        const newState = { ...this.state };
        var site = null;
        if (storageId) {
          site = find(this.state.sites, {
            id: storageId,
            entity: storage?.entity || 'storage',
            storageType: storage?.storageType || 'home',
          });
          newState.models.inload.storageId.value = this.props.inload.bhcSite ? this.props.inload.bhcSite.id : storageId;
        } else if (farmFieldId) {
          newState.models.inload.farmFieldId.value = farmFieldId;
          newState.models.inload.storageId.value = farmFieldId;
          site = find(this.state.sites, {
            id: farmFieldId,
            entity: 'farm_field',
          });
        } else {
          if(this.props.optionType !== OPTION_TYPE_WEB_SPLIT_LOADS || !isMounting)
            newState.models.inload.storageId.value = storageId || storage?.id || get(consignee, 'sites[0].locationId');
          site = find(this.state.sites, {
            id: newState.models.inload.storageId.value,
            entity: get(consignee, 'sites[0].locationContentType'),
          });
        }
        newState.initialStorageId = newState.models.inload.storageId.value;
        newState.selectedSite = site;
        newState.storage = site;

        this.setState(newState, () => {
          this.updateValidatorsBasedOnStorage();
          if (storage)
            this.setSiteDelayed(storage);
        });
      });
    }
  }

  //hack
  setSiteDelayed(storage) {
    setTimeout(() => {
      const site = find(this.state.sites, {
        id: storage.id,
        entity: storage.entity,
        storageType: storage.storageType,
      });

      const newState = { ...this.state };
      newState.models.inload.storageId.value = storage.id;
      newState.selectedSite = site;
      this.setState(newState, this.updateValidatorsBasedOnStorage);
    }, 4000);
  }

  updateValidatorsBasedOnStorage() {
    const newState = { ...this.state };
    if (this.props.isAddingToEmptyStorage) {
      ['commodityId', 'varietyId', 'gradeId'].forEach(key => {
        if (get(this.state, `storage.${key}`)) {
          newState.models.inload[key].value = this.state.storage[key];
        }
      });
    }
  }

  handleSiteChange(value, id, item) {
    const newState = { ...this.state };
    if (item) {
      newState.selectedSite = item;
      newState.storage = item;
      newState.models.inload.storageId.value = item.id;
    } else {
      newState.selectedSite = undefined;
      newState.storage = undefined;
      newState.models.inload.storageId.value = value;
    }
    this.setState(newState, () => {
      this.updateValidatorsBasedOnStorage();
      this.setFieldErrors('models.inload.storageId');
    });
  }

  onDocketImageRemove = fileSrc => {
    if (fileSrc) {
      const newState = { ...this.state };
      newState.models.inload.docketImage.value = newState.models.inload.docketImage.value || [];
      var index = findIndex(newState.models.inload.docketImage.value, file => file.base64 === fileSrc);
      if (index === -1) {
        index = findIndex(newState.docketImageUrl, file => file === fileSrc);
      }
      newState.models.inload.docketImage.value.splice(index, 1);
      newState.docketImagePath.splice(index, 1);
      newState.docketImageUrl.splice(index, 1);
      newState.docketImageFileCount = get(newState, 'docketImageUrl') ? get(newState, 'docketImageUrl').length + newState.models.inload.docketImage.value.length : newState.models.inload.docketImage.value.length;
      this.setState(newState);
    }
  };

  onDocketImageUpload = fileState => {
    const newState = { ...this.state };
    if (isEmpty(fileState.errors)) {
      var docketImages = newState.models.inload.docketImage.value || [];
      docketImages.push(fileState.file);
      newState.models.inload.docketImage.value = docketImages;
      newState.docketImageFileCount = get(newState, 'docketImageUrl') ? get(newState, 'docketImageUrl').length + docketImages.length : docketImages.length;
    } else {
      newState.models.inload.docketImage.errors = fileState.errors;
    }
    this.setState(newState);
  };

  isNetWeightMoreThanAllowed() {
    if (!get(this.props, 'movement.commodityContract') || !get(this.props, 'movement.order')) {
      return false;
    }
    const weightId = get(this.props, 'movement.commodityContract.weightId', DEFAULT_WEIGHT_ID);
    let maxTonnageAllowed = this.getMaxAllowedTonnage('netWeight');
    if (weightId === WEIGHT_DESTINATION_ID) {
      return this.getTonnage() > maxTonnageAllowed;
    } else {
      return false;
    }
  }

  isQuantityMoreThanAllowed() {
    if (!get(this.props, 'movement.commodityContract') || !get(this.props, 'movement.order')) {
      return false;
    }
    const weightId = get(this.props, 'movement.commodityContract.weightId', DEFAULT_WEIGHT_ID);
    let maxQuantityAllowed = this.getMaxAllowedTonnage('quantity');
    if (weightId === WEIGHT_DESTINATION_ID) {
      return this.getQuantity() > parseFloat(maxQuantityAllowed.toFixed(2));
    } else {
      return false;
    }
  }

  getSelectedCommodity = commodityId => {
    const id = commodityId || this.state.models.inload.commodityId.value
    return id ? find(this.props.commodities, {id: id}) : null
  }

  hasQuantityBasedCommodity = commodityId => Boolean(this.getSelectedCommodity(commodityId || this.state.models.inload.commodityId.value)?.isQuantityBased)

  isMeterCubeCommodityCommodity() {
    return get(this.getSelectedCommodity(), 'isStrictQuantityBased')
  }

  quantityLabel() {
    return get(this.getSelectedCommodity(), 'quantityLabel')
  }

  quantityUnit() {
    return get(this.getSelectedCommodity(), 'unit')
  }

  isNGRFieldDisabled() {
    return get(this.props, 'movement.commodityContract.buyer.ngrId') && false;
  }

  getSelectedNGRNumber() {
    return get(this.props, 'inload.ngr.ngrNumber') ||
      get(this.state, 'selectedNgr.ngrNumber') ||
      get(this.props, 'movement.commodityContract.buyer.ngr.ngrNumber') ||
      (isString(get(this.props, 'inload.ngr')) ? this.props.inload.ngr : undefined);
  }

  getSelectedNGRId() {
    return get(this.props, 'inload.ngrId') ||
      get(this.state, 'selectedNgr.id') ||
      get(this.props, 'movement.commodityContract.buyer.ngrId');
  }

  handleSteerChange = event => {
    const newState = { ...this.state };
    const checked = event.target.checked;
    newState.models.inload[event.target.id].value = checked;

    if (checked) {
      newState.permitChecked = false;
      if (event.target.id === 'steer_point_5')
        newState.models.inload.steer_1_point_1.value = false;
      else
        newState.models.inload.steer_point_5.value = false;
    }

    this.setState(newState);
  };

  shouldEnableSteerPoint5 = () => get(find(this.state.categories, { id: this.state.models.inload.categoryId.value }), 'steerPoint5');

  shouldEnableSteer1Point1 = () => get(find(this.state.categories, { id: this.state.models.inload.categoryId.value }), 'steer1Point1');

  handleCheckboxChange = event => {
    this.setFieldValue(event.target.id, event.target.checked, false, () => {
      const newState = {...this.state}
      newState.permitChecked = false;
      newState.accreditationNumberChecked = false;
      newState.restrictedChecked = false;
      this.setState(newState, () => this.setCategory())
    });
  };

  voidLoad() {
    const { inload } = this.props;
    if(this.isRestrictedToEditForSite(this.state.selectedConsignee)){
      alertifyjs.error("Inturns into and Outturns from this site can only be created/edited by the company employees. Please contact the site manager for creating this load.", 5);
    } else {
      alertifyjs.confirm(
        'Warning',
        'Load will be marked Void. Do you want to proceed?',
        () => {
          APIService
            .loads(inload.id)
            .appendToUrl('void/')
            .put().then(() => {
              alertifyjs.success('Load Deleted');
              window.location.reload();
              this.props.closeDrawer;
            });
        },
        () => { },
      );
    }
  }

  handleTruckCodeChange = (value, id) => {
    const newState = { ...this.state };
    set(newState, id + '.value', value);
    this.setState(newState, () => this.setCategory());
  };

  async getModelSpecsFromOutloadSpecs(commoditySpecs, specValues) {
    if (specValues) {
      const modelSpecs = {};
      if (isEmpty(commoditySpecs))
        commoditySpecs = get(find(this.props.commodities, { id: this.state.models.inload.commodityId.value }), 'specs', []);
      if (!isEmpty(commoditySpecs)) {
        forEach(orderBy(commoditySpecs, 'order'), (spec) => {
          let specValue = get(specValues, `${spec.code.toLowerCase()}`, false) ? parseFloat(get(specValues, `${spec.code.toLowerCase()}`, 0)).toFixed(2) : null;
          const validators = [valueBetween(spec.min, spec.max, true)];
          modelSpecs[spec.code] = {
            ...FIELD,
            value: specValue,
            validators,
          };
        });
      }
      this.setState(
        state => ({
          ...state,
          models: set(state.models, 'specs', modelSpecs),
          commoditySpecs,
        })
      );
    }
  }

  handleCopyLoadDetails(copyToType) {
    let loadFields = ['truckId', 'truckCompanyId', 'truckCompany', 'searchOption','steer_point_5', 'steer_1_point_1', 'season', 'grossWeight', 'varietyId'];
    if (!this.isPackMovement())
      loadFields.push('tareWeight');
    const newState = { ...this.state };
    newState.isCopyFromOutload = true
    let counterPartLoad = this.getCounterPartLoad();
    this.getTrucks(get(counterPartLoad, 'truck.companyId'))
    let localDateTime = get(counterPartLoad, 'dateTime') ? getDateTimeFromUTC(moment(counterPartLoad.dateTime).add(2, 'hours')) : null
    let localDate = localDateTime?.date || ''
    let localTime = localDateTime?.time || ''
    set(newState.models, `${copyToType}.date.value`, localDate);
    set(newState.models, `${copyToType}.date.errors`, [])
    set(newState.models, `${copyToType}.time.value`, localTime)
    set(newState.models, `${copyToType}.time.errors`, [])

    if (this.props.movement.typeId == FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY) {
      set(newState.models, `${copyToType}.ngrId.value`, get(counterPartLoad, 'ngrId'));
      set(newState, 'stockOwner.value', get(counterPartLoad, 'ngr.companyId'));
    }

    forEach(loadFields, field => {
      if(field == 'searchOption')
        newState.searchOption = get(this.props, 'movement.providerId') == get(counterPartLoad, 'truck.companyId') ? SEARCH_BY_FREIGHT_PROVIDER_REGOS : SEARCH_BY_TRUCK_OWNER_REGOS;
      else if(field == 'truckCompanyId') {
        set(newState.models, `${copyToType}.${field}.value`, get(counterPartLoad, 'truck.companyId'))
        if(!newState.models.inload.freightProviderId.value && counterPartLoad?.truck?.companyId)
          set(newState.models, `${copyToType}.freightProviderId.value`, get(counterPartLoad, 'truck.companyId'));
      }
      else {
        if(field == 'truckCompany' && get(counterPartLoad, 'truck.companyId')) {
          let systemCompanyId = getCountrySystemCompanyId();
          if(get(counterPartLoad, 'truck.companyId') == systemCompanyId)
            newState.models.inload.truckCompany.value = {id: systemCompanyId, name: 'Unknown'}
          else
            APIService.companies(get(counterPartLoad, 'truck.companyId')).get().then(item => newState.models.inload.truckCompany.value = item)
        }
        let setField = field == 'steer_point_5' ? 'steerPoint5' : field;
        setField = field == 'steer_1_point_1' ? 'steer1Point1' : setField;
        if (includes(['steerPoint5', 'steer1Point1'], setField))
          set(newState.models, `${copyToType}.${field}.value`, get(counterPartLoad, setField, false));
        else if (includes(['tareWeight', 'grossWeight', 'estimatedNetWeight'], field))
          set(newState.models, `${copyToType}.${field}.value`, get(counterPartLoad, `_entered${upperFirst(field)}`) || get(counterPartLoad, field));
        else
          set(newState.models, `${copyToType}.${field}.value`, get(counterPartLoad, setField));
      }
    });
    let allLoads = get(this.props, 'movement.freightPickup.load');
    if (allLoads) {
      if (allLoads.length > 1) {
        newState.models.inload.tareWeight.value = undefined;
        newState.models.inload.grossWeight.value = undefined;
      }
      let totalNetWeight = allLoads.reduce((acc, curr) => acc + (curr._enteredEstimatedNetWeight || curr.estimatedNetWeight) || 0, 0);
      newState.models.inload.estimatedNetWeight.value = totalNetWeight;
    }
    else {
      if (has(counterPartLoad, 'netWeight')) newState.models.inload.estimatedNetWeight.value = get(counterPartLoad, 'netWeight');
    }
    if (!newState.models.inload.estimatedNetWeight.value)
      newState.models.inload.estimatedNetWeight.value = this.getNetWeight(newState);
    newState.initialEstimatedNetWeight = cloneDeep(newState.models.inload.estimatedNetWeight.value);
    this.setState(newState, () => {
      this.setCategoryAndFields(get(counterPartLoad, 'categoryId'));
      this.getModelSpecsFromOutloadSpecs({}, get(counterPartLoad, 'specs'));
      setTimeout(() => this.handleGradeChange({id: get(counterPartLoad, 'gradeId')}), 1000);
    });
  }

  isContainerMovement = () => {
    return (!this.isPackMovement() && get(this.props, 'movement.containerNumber') != null);
  }

  isPackMovement = () => {
    return get(this.props, 'movement.typeId') === PACK_ORDER_TYPE_ID;
  }

  priceUnit() {
    return this.props.movement ? this.getTruckUnit() : getCountryDisplayUnit()
  }

  applicationCommodityUnit(commodity) {
    return get(UNIT_ABBREVIATIONS, commodity?.unit);
  }

  getTruckUnit() {
    return get(find(this.state.trucks, {id: this.state.models.inload.truckId.value}), 'unit') || this.state.unit;
  }

  handleTruckSearchOptionChange = value => {
    const newState = { ...this.state };
    newState.searchOption = value;
    if (value == SEARCH_BY_FREIGHT_PROVIDER_REGOS) {
      newState.models.inload.truckCompanyId.value = get(this.props, 'movement.providerId');
      newState.models.inload.truckId.value = get(this.props, 'inload.truckId') || get(this.props, 'movement.plannedTruckId');
      newState.models.inload.truckCompany.value = get(this.props, 'movement.provider');
    }
    else {
      let plannedTruck = get(this.props.movement, 'plannedTruck');
      if (get(plannedTruck, 'companyId') === get(this.props.movement, 'providerId') || newState.models.inload.truckCompanyId.value !== get(plannedTruck, 'companyId')) {
        newState.models.inload.truckCompanyId.value = plannedTruck ? get(this.props, 'movement.providerId') : get(this.props, 'movement.freightPickup.load[0].truck.companyId');
        newState.models.inload.truckId.value = plannedTruck ? get(this.props, 'movement.plannedTruckId') : get(this.props, 'movement.freightPickup.load[0].truck.id')
        if(newState.models.inload.truckCompanyId.value)
          APIService.companies(newState.models.inload.truckCompanyId.value).get().then(data => newState.models.inload.truckCompany.value = data);
      }
    }
    this.setState(newState, () => {
      if (this.state.searchOption === SEARCH_BY_FREIGHT_PROVIDER_REGOS)
        this.getTrucks(get(this.props, 'movement.providerId'));
    });
  }

  setSelectedProviderTruck = truck => {
    const newState = { ...this.state };
    newState.selectedTrucks.push(truck);
    if (get(truck, 'id')) {
      let category = find(this.state.categories, { id: get(truck, 'categoryId') });
      newState.code.value = get(category, 'truckCode');
      newState.models.inload.categoryId.value = get(truck, 'categoryId');
      newState.massLimitPermit.value = get(category, 'massLimitPermit');
      newState.ghms.value = get(category, 'ghms');
      newState.models.inload.steer_1_point_1.value = get(truck, 'steer1Point1');
      newState.models.inload.steer_point_5.value = get(truck, 'steerPoint5');
      newState.models.inload.truckCompanyId.value = get(truck, 'companyId');
      newState.models.inload.truckCompany.value = {id:get(truck, 'companyId'), name: get(truck, 'companyName')};
      newState.models.inload.truckId.value = get(truck, 'id')
    } else {
      newState.code.value = '';
      newState.models.inload.categoryId.value = '';
      newState.massLimitPermit.value = '';
      newState.ghms.value = false;
      newState.models.inload.steer_1_point_1.value = false;
      newState.models.inload.steer_point_5.value = false;
      newState.models.inload.truckCompanyId.value = null;
      newState.models.inload.truckCompany.value = null;
    }
    this.setState(newState);
  };

  handleTruckOwnerChange = (value, id) => { // eslint-disable-line no-unused-vars
    const newState = { ...this.state };
    newState.models.inload.truckCompanyId.value = value?.id;
    newState.models.inload.truckCompany.value = value;
    if(isNull(value)) {
      newState.models.inload.truckId.value= null;
      newState.trucks = [];
    }
    this.setState(newState, () => {
      if(value && this.state.searchOption != SEARCH_BY_ALL_REGOS)
        this.getTrucks(value?.id);
    });
  }

  handlePermitClick = event => {
    const isChecked = event.target.checked;
    const newState = {...this.state};
    set(newState, 'permitChecked', isChecked);
    newState.massLimitPermit.validators = [required()];
    if (isChecked) {
      newState.accreditationNumberChecked = false;
      newState.ghms.value = false;
      newState.models.inload.steer_point_5.value = false;
      newState.models.inload.steer_1_point_1.value = false;
      newState.massLimitPermit.validators = [];
    }
    this.setState(newState);
  }

  handleAccreditationNumberClick = event => {
    const isChecked = event.target.checked;
    const newState = {...this.state};
    set(newState, 'accreditationNumberChecked', isChecked);
    if (isChecked) {
      newState.permitChecked = false;
      newState.ghms.value = false;
    }
    this.setState(newState);
  }

  handleLoadSharingClick = event => event.target.checked ? this.setState({loadSharing: true}) : this.setState({loadSharing: false});

  handleRestrictedClick = event => {
    const isChecked = event.target.checked;
    const id = event.target.id;
    const newState = {...this.state};
    set(newState, id, isChecked);
    if (isChecked)
      newState.ghms.value = false;
    this.setState(newState);
  }

  handleTruckDetailsChange = event => {
    const newState = {...this.state}
    let id = event.target.id;
    set(newState, `${id}.value`, id === 'models.inload.restricted' ? event.target.checked : event.target.value);
    if (id !== 'models.inload.restricted' && event.target.value) {
      const parts = id.split(".")
      const errorField = `truckErrors.${parts[1]}`;
      set(newState, errorField, '')
    }
    this.setState(newState);
  }

  handleTruckDetailsSelectValueChange(value, id) {
    const newState = { ...this.state };
    set(newState, `${id}.value`, value);
    const parts = id.split(".")
    const errorField = `truckErrors.${parts[1]}`;
    set(newState, errorField, '')
    this.setState(newState);
  }

  getEntityUrl() {
    const reason = get(this.props, 'movement.reason')
    const canAmendRelatedEntity = get(this.props, 'movement.canAmendRelatedEntity');
    if (reason) {
      let url = ''
      let identifier = ''
      if (reason === 'contract') {
        identifier = get(this.props, 'movement.order.commodityContract.contractNumber')
        url = canAmendRelatedEntity ? <a rel="noopener noreferrer" target='_blank' href={`#/contracts/${get(this.props, 'movement.order.commodityContract.id')}/edit`} onClick={event => window.open(event.target.href, '_blank')}>{identifier}</a> : identifier;
      }
      else if (reason === 'pickup order') {
        identifier = get(this.props, 'movement.order.freightPickup.orderNumber')
        url = canAmendRelatedEntity ? <a rel="noopener noreferrer" target='_blank' href={`#/freights/orders/${get(this.props, 'movement.order.pickupOrderId')}/edit`} onClick={event => window.open(event.target.href, '_blank')}>{identifier}</a> : identifier;
      }
      else if (reason === 'delivery order') {
        identifier = get(this.props, 'movement.order.freightDelivery.orderNumber')
        url = canAmendRelatedEntity ? <a rel="noopener noreferrer" target='_blank' href={`#/freights/orders/${get(this.props, 'movement.order.deliveryOrderId')}/edit`} onClick={event => window.open(event.target.href, '_blank')}>{identifier}</a> : identifier;
      }
      else if (reason === 'parent order') {
        identifier = get(this.props, 'movement.order.parentOrder.identifier')
        url = canAmendRelatedEntity ? <a rel="noopener noreferrer" target='_blank' href={`#/freights/orders/${get(this.props, 'movement.order.parentOrder.id')}/edit`} onClick={event => window.open(event.target.href, '_blank')}>{identifier}</a> : identifier;
      }
      return url
    }
  }

  onChemicalApplicationCommodityChange(obj, value) {
    let commodity = undefined
    if (value)
      commodity = find(this.props.commodities, {id: value});
    this.setState(prevState => ({
      chemicalApplications: prevState.chemicalApplications.map(chemicalApplication => {
        if (chemicalApplication.id == obj.id)
          return {...chemicalApplication, commodityId: value, commodity: commodity};
        return {...chemicalApplication}
      })
    }));
  }

  handleApplicationRateChange(obj, value) {
    this.setState(prevState => ({
      chemicalApplications: prevState.chemicalApplications.map(chemicalApplication => {
        if (chemicalApplication.id == obj.id)
          return {...chemicalApplication, applicationRate: value};
        return {...chemicalApplication}
      })
    }));
  }

  handleChemicalApplicationStorageChange(value, item, obj) {
    this.setState(prevState => ({
      chemicalApplications: prevState.chemicalApplications.map(chemicalApplication => {
        if (chemicalApplication.id == obj.id)
          return {...chemicalApplication, storageId: value};
        return {...chemicalApplication}
      })
    }));
  }

  onChemicalApplicationDelete(obj) {
    const newState = {...this.state};
    let chemicalApplications = [...newState.chemicalApplications.filter(chemicalApplication => chemicalApplication.id !== obj.id)];
    newState.chemicalApplications = chemicalApplications.map((chemicalApplication, index) => ({...chemicalApplication, id: index + 1}));
    this.setState(newState)
  }

  onChemicalApplicationAdd() {
    const newState = {...this.state};
    const chemicalApplications = (isEmpty(get(this.props.movement, 'chemicalApplications')) ? get(this.props.movement, 'orderChemicalApplications') : get(this.props.movement, 'chemicalApplications')) || [];
    const obj = find(chemicalApplications, {gradeId: this.state.models.inload.gradeId.value})
    let commodityErrors = [];
    const commodityId = get(obj, 'commodityId');
    if (commodityId && this.state.chemicalApplications.some(chemicalApplication => chemicalApplication.commodityId === commodityId))
      commodityErrors = ['This application already defined.']
    const newObj = {
      id: newState.chemicalApplications.length + 1,
      commodityIdOptions: chemicalApplications.map(chemicalApplication => chemicalApplication.commodityId),
      commodityId: commodityId,
      commodity: find(this.props.commodities, {id: get(obj, 'commodityId')}),
      applicationRate: get(obj, 'applicationFee'),
      storageId: undefined,
      errors: [],
      commodityErrors: commodityErrors
    }
    newState.chemicalApplications = [...newState.chemicalApplications, newObj];
    this.setState(newState)
  }

  getEligibleApplicationRates(commodityId) {
    let eligibleApplicationRates = [];
    if (commodityId && !isEmpty(this.state.chemicalApplicationRates)) {
      let _eligibleApplicationRates = this.state.chemicalApplicationRates.filter(chemicalApplicationRate => chemicalApplicationRate.commodityId === commodityId);
      if (!isEmpty(_eligibleApplicationRates)) {
        _eligibleApplicationRates.forEach(_eligibleApplicationRate => {
          eligibleApplicationRates.push({'name': _eligibleApplicationRate.rate, 'id': _eligibleApplicationRate.rate})
        })
      }
    }
    return eligibleApplicationRates;
  }


  isFieldDisabled = field => {
    // only for specific fields site/grade/variety/ngr/weight/canola-specs/season and in movement completed/invoiced
    const isCommodityContractInvoiced = this.props.movement?.isCommodityContractInvoiced
    const { isWarehouseInvoiced, isFreightInvoiced } = this.state
    const isWeighingAtOrigin = this.isWeighingAtOrigin()

    if(isFreightInvoiced && ['inload.tareWeight', 'inload.grossWeight', 'inload.netWeight'].includes(field))
      return true
    if(isWeighingAtOrigin)
      return false
    if(isCommodityContractInvoiced && ['inload.consignee.handlerId', 'inload.gradeId', 'inload.varietyId', 'inload.stockOwner', 'inload.ngrId', 'inload.specs.coil', 'inload.specs.impu', 'inload.tareWeight', 'inload.grossWeight', 'inload.netWeight'].includes(field))
      return true
    if(isWarehouseInvoiced && ['inload.consignee.handlerId', 'inload.gradeId', 'inload.season', 'inload.stockOwner', 'inload.ngrId', 'inload.tareWeight', 'inload.grossWeight', 'inload.netWeight'].includes(field))
      return true
    return false
  }

  render() {
    const { inload, specs } = this.state.models;
    const {
      isNetWeightGreaterThanRemainingSpace,
      selectedConsignee, sites, farmFields, trucks,
      seasonFloatingLabelText, storage, ngrFloatingLabelText, ngrs,
      docketNumberFloatingLabelText, commoditySpecs, gradeFloatingLabelText,
      docketImageFileCount, docketImageUrl, stockOwner, selectedNgr, lockStockOwner, isVarietyMandatoryLabel,
      selectedStockOwner
    } = this.state;
    const { movement, isAddingToEmptyStorage } = this.props;
    const isPackMovement = this.isPackMovement();
    const isShrinkageLoad = this.checkShrinkageLoad();
    const isContainerMovement = this.isContainerMovement();
    const estimatedNetWeightError = get(
      inload,
      'estimatedNetWeight.errors[0]',
      isNetWeightGreaterThanRemainingSpace ? INLOAD_EXCESS_NET_WEIGHT_WARNING_MESSAGE : '',
    );
    const ngrFieldError =
      get(inload, 'ngrId.errors[0]', '') ||
      (movement && !inload.ngrId.value ? NGR_REQUIRED_FOR_STOCKS_WARNING_MESSAGE : '');
    const completedMovement = movement?.status === 'completed' && !isSystemCompany();
    const completedAndInvoicedMovement = Boolean(movement?.status === 'completed' && movement.isAnyInvoiced);
    const archivedStorage = find(sites, { 'storageId': get(this.props, 'inload.storageId', this.storageId), 'isActive': false }) || false;
    const isInvoiced = this.state.isWarehouseInvoiced;
    const isDirectLoad = !(get(this.props.inload, 'checkpointId')) && !(get(this.props.inload, 'titleTransferNumber')) && !(get(this.props.inload, 'optionType')) && !get(this.props.inload, 'shrinkageParent');
    const isStockUpdateLoad = includes(STORAGE_STOCK_EMPTY_UPDATE_OPTION_TYPES, get(this.props.inload, 'optionType'));
    const styleAdornment = (completedAndInvoicedMovement || archivedStorage || isShrinkageLoad || isStockUpdateLoad ) ? { color: 'rgb(162,162,162)', paddingRight: '32px' } : { color: 'rgb(162,162,162)' };
    const unit = this.priceUnit();
    const isTruckMassLimitsVisible = isVisibleInCountry('truckMassLimits');
    const disableBasedOnTruckSearchOption = this.state.searchOption != SEARCH_BY_TRUCK_OWNER_REGOS;
    const isSplitLoadForm = get(this.props, 'optionType') === OPTION_TYPE_WEB_SPLIT_LOADS;
    const isDateTimeDisabled = completedAndInvoicedMovement || archivedStorage || isInvoiced || isShrinkageLoad || isStockUpdateLoad;
    let disableLoadSharing = false;
    const isNoticePermit = this.state.massLimitPermit.value === 'Notice';
    const parentTonnage = min([get(this.props, 'movement.maxAllowedTonnageOnOrder'), get(this.props, 'movement.parentTotalTonnageWithTolerance')]);
    const maxParentTonnage = get(this.props, 'movement.maxAllowedTonnageOnOrder') || get(this.props, 'movement.parentTotalTonnageWithTolerance');
    const updateToTonnage = (parentTonnage + parseFloat(get(this.state.models, 'inload.estimatedNetWeight.value', 0)) - this.getMaxAllowedTonnage());
    const entityName = get(this.props, 'movement.orderId') ? 'order' : 'contract';
    const isParentOrderIndependent = get(this.props, 'movement.isParentOrderIndependent');
    const isDirectContractMovement = !get(this.props, 'movement.orderId') && get(this.props, 'movement.commodityContractId');
    let showUpdateToTonnagePopup = this.state.showAmendTonnagePopup && !isParentOrderIndependent;
    if (showUpdateToTonnagePopup && isDirectContractMovement && get(this.props, 'movement.canAmendRelatedEntity'))
      showUpdateToTonnagePopup = true;
    else if (showUpdateToTonnagePopup && parseFloat(updateToTonnage) < get(this.props, 'movement.maxAllowedTonnageOnOrder'))
      showUpdateToTonnagePopup = true
    else
      showUpdateToTonnagePopup = false
    const parentEntityUrl = this.getEntityUrl();
    const amendMessage = get(this.props, 'movement.canAmendRelatedEntity') ? `Please amend ${get(this.props, 'movement.reason')} first.` : `Please contact the relevant party to amend ${get(this.props, 'movement.reason')}.`;
    const isSiteRestricted = this.props?.inload && this.isRestrictedToEditForSite(this.state.selectedConsignee)
    return (
      <div className='col-xs-12 padding-reset' style={{marginTop: '20px'}}>
        {
          isInvoiced &&
            <span style={{fontSize: '14px', color: 'rgba(0, 0, 0, 0.5)'}}>
              <i>(this load is already invoiced, editing is restricted.)</i>
            </span>
        }
        <form onSubmit={this.handleSubmit} noValidate>
          <div className='cardForm cardForm--drawer col-xs-12 padding-reset'>
            <div className='cardForm-content row trucks col-xs-12 padding-reset'>
              <div className='col-xs-12' style={{ display: 'inline-block' }}>
                {this.getCounterPartLoad() && !isInvoiced && !completedAndInvoicedMovement &&
                  <CommonButton
                    label='Copy from outload'
                    default={true}
                    variant='outlined'
                    style={{ height: '20px', minWidth: '61px', }}
                    onClick={() => this.handleCopyLoadDetails('inload')}
                  />
                }
              </div>
              {
                !isPackMovement &&
                  <div className='col-xs-8 form-wrap' style={{ display: 'inline-block', marginTop: '16px' }}>
                    <SiteAsyncAutocomplete
                      farmId={this.farmId || this.props.farmId}
                      variant='standard'
                      id='inload.consignee.handlerId'
                      label='Delivery Site'
                      onChange={this.handleConsigneeChange}
                      selected={selectedConsignee?.id ? {...selectedConsignee, name: selectedConsignee.displayName || selectedConsignee.name} : null}
                      minLength={3}
                      errorText={inload.consignee.handlerId.errors[0]}
                      style={{ float: 'right' }}
                      disabled={ isSiteRestricted || this.isFieldDisabled('inload.consignee.handlerId') || archivedStorage || this.isRegradedLoad || isInvoiced || isShrinkageLoad || isStockUpdateLoad || movement?.inloadSlotId}
                      activeSitesOnly
                      includeSelectedSiteId={get(movement, 'freightDelivery.consignee.handlerId')}
                    />
                </div>
              }
              {
                !isPackMovement && !isContainerMovement &&
                <div className='col-xs-4 form-wrap' style={{ display: 'inline-block' }}>
                  <CommonAutoSelect
                    items={selectedConsignee?.id ? sites : []}
                    id='inload.storageId'
                    label='Delivery Storage'
                    value={get(inload, 'storageId.value', '')}
                    errorText={get(inload, 'storageId.errors[0]', '')}
                    onChange={this.handleSiteChange}
                    selectedItem={this.state.selectedSite}
                    dontAutoselectSingleItem
                    disabled={archivedStorage || this.isRegradedLoad || isShrinkageLoad || isStockUpdateLoad}
                    storageFieldMix
                  />
                </div>
              }
              {
                (isPackMovement || isContainerMovement) &&
                <div className="col-xs-4 form-wrap">
                  <CommonTextField
                    id='inload.containerNumber'
                    label='Container Name'
                    placeholder='Container Name'
                    value={inload.containerNumber.value}
                    maxLength='255'
                    disabled
                  />
                </div>
              }
              { isPackMovement && !get(this.props, 'movement.order') && (
                <div className="col-sm-12 form-wrap-70">
                  <Autocomplete
                    fullWidth
                    options={this.state.matchingPackOrders}
                    value={this.state.matchingPackOrderId.value && find(this.state.matchingPackOrders, {id: this.state.matchingPackOrderId.value})}
                    id="matchingPackOrderId"
                    style={{float: 'left'}}
                    required={true}
                    getOptionLabel={option => get(option, 'displayName')}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label='Pack Order'
                        error={!isEmpty(get(this.state.matchingPackOrderId, 'errors', ''))}
                        helperText={get(this.state.matchingPackOrderId, 'errors', '')}
                        fullWidth
                        variant='standard'
                      />
                    )}
                    onChange={(event, value) => this.setMatchingPackOrder(value ? value.id : null)}
                 />
                </div>
              )
              }
              {
                (isDirectLoad || isStockUpdateLoad || isShrinkageLoad) && !movement &&
                <div className='col-xs-4 form-wrap'>
                {
                  (isStockUpdateLoad || isShrinkageLoad) ?
                  <CommonTextField
                    id='inload.loadIdentifier'
                    label='Identifier'
                    placeholder='Identifier'
                    maxLength='255'
                    disabled
                    value={inload.loadIdentifier.value}
                    helperText={get(inload, 'loadIdentifier.errors[0]', '')}
                  /> :
                  <TextField
                    id='inload.loadIdentifier'
                    label='Identifier'
                    placeholder='Identifier'
                    onChange={this.handleIdentifierChange}
                    value={inload.loadIdentifier.value}
                    error={!isEmpty(inload.loadIdentifier.errors)}
                    helperText={get(inload, 'loadIdentifier.errors[0]', '')}
                    inputProps={{
                      maxLength: 14,
                    }}
                    fullWidth
                    variant='standard'
                  />
                }
                </div>
              }
              {!isSplitLoadForm &&
                <React.Fragment>
                  <div className={movement ? 'col-xs-6 form-wrap' : 'col-xs-4 form-wrap'}>
                    <CommonDatePicker
                      id='inload.date'
                      onChange={this.handleSelectFieldChange}
                      value={inload.date.value}
                      errorText={get(inload, 'date.errors[0]', '')}
                      maxDate={new Date()}
                      disabled={isDateTimeDisabled}
                      minDate={
                        get(movement, 'freightPickup.outload') ?
                          new Date(movement.freightPickup.outload.date) :
                          null
                      }
                    />
                  </div>
                  <div className={movement ? 'col-xs-6 form-wrap' : 'col-xs-4 form-wrap'}>
                    <CommonTimePicker
                      id='inload.time'
                      onChange={this.handleSelectFieldChange}
                      value={inload.time.value}
                      errorText={get(inload, 'time.errors[0]', '')}
                      disabled={isDateTimeDisabled}
                    />
                  </div>
                </React.Fragment>
              }
              {
                !movement &&
                <div className="col-xs-4 form-wrap">
                  <CommonAutoSelect
                    items={farmFields}
                    id="inload.sourceFieldId"
                    label="Field (Optional)"
                    value={inload.sourceFieldId.value}
                    onChange={this.handleSelectFieldChange}
                    helperText=''
                    maxLength="255"
                    dontAutoselectSingleItem
                    disabled={this.isRegradedLoad || isInvoiced || isShrinkageLoad || isStockUpdateLoad}
                  />
                </div>
              }
              {
                movement && !isPackMovement &&
                <div className='col-xs-4 form-wrap' style={{ display: 'inline-block' }}>
                  <CommonAutoSelect
                    id='searchOptions'
                    label='Show Trucks Of'
                    items={TRUCK_SEARCH_OPTIONS}
                    dataSourceConfig={{ text: 'name', value: 'id' }}
                    value={this.state.searchOption}
                    onChange={this.handleTruckSearchOptionChange}
                    maxLength="255"
                  />
                </div>
              }
              {
                movement && !isPackMovement &&
                <div className='col-xs-4 form-wrap' style={{ display: 'inline-block' }}>
                  <CompanyAutocomplete
                    id='truckOwner'
                    selected={this.state.models.inload.truckCompany.value}
                    onChange={this.handleTruckOwnerChange}
                    minLength={3}
                    getRegisteredCompanies={false}
                    variant="standard"
                    disabled={disableBasedOnTruckSearchOption}
                    label='Truck Owner'
                  />
                </div>
              }
              {
                movement && !isPackMovement &&
                  (this.state.searchOption == SEARCH_BY_ALL_REGOS ?
                <div className='col-xs-4 form-wrap' style={{ display: 'inline-block' }}>
                  <CommonSelectInput
                    search
                    allowEmptyOptions
                    allowText={false}
                    endpoint="trucks/search/"
                    queryParams={{is_active: true}}
                    options={[]}
                    optionMap={{ id: 'id', name: 'rego', companyId: 'companyId', companyName: 'companyName', totalWeights: 'totalWeights', categoryId: 'categoryId', code: 'code', steerPoint5: 'steerPoint5', steer1Point1: 'steer1Point1' }}
                    inputText={get(find(this.state.trucks, { id: inload.truckId.value }), 'rego')}
                    id='inload.truckId'
                    label={getCountryLabel('rego')}
                    getSelectedOption={this.setSelectedProviderTruck}
                    value={inload.truckId.value}
                    disabled={this.disableForm}
                    create={this.props.createTruck}
                    actionCreator={addTruck}
                    validate={this.props.validateRego}
                    errorText={get(inload, 'truckId.errors[0]', '')}
                    onChange={this.handleSelectFieldChange}
                    onInput={(e) => {
                      e.target.value = e.target.value.toString().slice(0, 10).replace(/[^0-9a-z]/gi, '');
                      if(e.target.value == ''){
                        const newState = {...this.state};
                        inload.truckCompanyId.value = '';
                        this.setState(newState);
                      }
                    }}
                />
                </div> :
                <div className='col-xs-4 form-wrap' style={{ display: 'inline-block' }}>
                  <CommonAutoSelect
                    id='inload.truckId'
                    label={getCountryLabel('rego')}
                    items={orderBy(trucks, 'rego')}
                    disabled={completedAndInvoicedMovement || this.isRegradedLoad || (movement && !get(inload, 'truckId.errors[0]', '') && this.state.searchOption == SEARCH_BY_TRUCK_OWNER_REGOS && this.state.models.inload.truckCompanyId.value == null)}
                    dataSourceConfig={{ text: 'rego', value: 'id' }}
                    value={inload.truckId.value}
                    onChange={this.handleSelectFieldChange}
                    errorText={get(inload, 'truckId.errors[0]', '')}
                    maxLength="255"
                    dontAutoselectSingleItem
                    filterOptions={(items, params) => {
                      let filtered = autocompleteFilters(items, params);
                      filtered = filtered.filter(item => !get(item, 'inputValue'))
                      if(!get(this.state.models.inload.truckCompany.value, 'transactionParticipation')) {
                        if (params.inputValue !== '' && params.inputValue.length >= 4 && params.inputValue.length <= 10 && params.inputValue.toLowerCase() !== 'fleet') {
                          filtered.push({
                            inputValue: params.inputValue,
                            name: `Add "${params.inputValue}" as new ${getCountryLabel('rego')}`,
                          });
                        }
                        return filtered;
                      }
                      return filtered;
                    }}
                  />
                </div>)
              }
              <div className='col-xs-4 form-wrap'>
                <CommodityAutoComplete
                  id='inload.commodityId'
                  onChange={this.handleCommodityChange}
                  commodityId={inload.commodityId.value}
                  disabled={!!movement || completedMovement || archivedStorage || this.isRegradedLoad || isInvoiced || isShrinkageLoad || isStockUpdateLoad}
                  errorText={get(inload, 'commodityId.errors[0]', '')}
                  includeUnknown={true}
                />
              </div>
              <div className='col-xs-4 form-wrap'>
                <SeasonSelect
                  id='inload.season'
                  floatingLabelText={seasonFloatingLabelText}
                  onChange={this.handleSelectFieldChange}
                  season={inload.season.value}
                  disabled={this.isFieldDisabled('inload.season') || archivedStorage || this.isRegradedLoad || isInvoiced || isShrinkageLoad || isStockUpdateLoad || (isPackMovement && get(this.props, 'movement.season')) || isContainerMovement}
                  errorText={get(inload, 'season.errors[0]', '')}
                  includeEmptyOption={get(storage, 'isOwner')}
                />
              </div>
              <div className='col-xs-4 form-wrap'>
                <GradeAutoComplete
                  id='inload.gradeId'
                  floatingLabelText={gradeFloatingLabelText}
                  commodityId={inload.commodityId.value}
                  season={inload.season.value}
                  selectedVarietyId={inload.varietyId.value}
                  onChange={this.handleGradeChange}
                  onBlur={this.handleSelectFieldBlur}
                  errorText={get(inload, 'gradeId.errors[0]', '')}
                  disabled={!inload.commodityId.value || this.isFieldDisabled('inload.gradeId') || archivedStorage || this.isRegradedLoad || isInvoiced || isShrinkageLoad || isStockUpdateLoad || isPackMovement || isContainerMovement}
                  dependsOnCommodity
                  dependsOnSeason
                  specs={mapValues(specs, 'value')}
                  gradeId={inload.gradeId.value}
                  selectedGradeId={inload.gradeId.value}
                />
              </div>
              <div className='col-xs-4 form-wrap'>
                <VarietyAutoComplete
                  id='inload.varietyId'
                  label={isVarietyMandatoryLabel ? 'Variety' : 'Variety (Optional)'}
                  commodityId={inload.commodityId.value}
                  dependsOnCommodity
                  onChange={this.handleVarietyChange}
                  varietyId={inload.varietyId.value}
                  errorText={get(inload, 'varietyId.errors[0]', '')}
                  disabled={this.isFieldDisabled('inload.varietyId') || this.isRegradedLoad || isShrinkageLoad || isStockUpdateLoad}
                />
              </div>
              {
                this.isNGRFieldDisabled() ?
                  <div className='col-xs-4 form-wrap'>
                    <CommonTextField
                      id='inload.ngrId'
                      label='NGR'
                      value={this.getSelectedNGRNumber()}
                      disabled
                    />
                  </div> :
                  <React.Fragment>
                    <div className='col-xs-4 form-wrap'>
                      <CompanyAutocomplete
                        variant='standard'
                        id='company'
                        label='Stock Owner'
                        companyId={stockOwner.value || get(selectedNgr, 'companyId')}
                        selected={selectedStockOwner}
                        onChange={this.onCompanyChange}
                        urlPath='directory/names/'
                        queryParams={{include_self: true, excludeGroups: true}}
                        minLength={3}
                        style={{ float: 'right' }}
                        errorText={stockOwner.errors[0]}
                        disabled={this.isFieldDisabled('inload.stockOwner') || archivedStorage || this.isRegradedLoad || isInvoiced || isShrinkageLoad || isStockUpdateLoad || isPackMovement || lockStockOwner}
                        filterFunc={companies => filter(companies, company => {
                          if (get(this.props, 'inload.ngr.companyId') && get(this.props.movement, 'commodityContract.seller.companyId') == get(this.props, 'inload.ngr.companyId'))
                            return true
                          else
                            return company.id != get(this.props.movement, 'commodityContract.seller.companyId');
                        })}
                        />
                    </div>
                    <div className='col-xs-4 form-wrap'>
                      <CommonSelect
                        id='inload.ngrId'
                        floatingLabelText={stockOwner.value ? ngrFloatingLabelText : 'NGR (Select Stock Owner)'}
                        items={stockOwner.value ? ngrs : []}
                        selectConfig={{ value: 'id', text: 'ngrNumber' }}
                        onChange={this.handleSelectFieldChange}
                        errorText={ngrFieldError}
                        value={inload.ngrId.value || get(selectedNgr, 'id')}
                        disabled={this.isFieldDisabled('inload.ngrId') || this.isRegradedLoad || isInvoiced || isShrinkageLoad || isStockUpdateLoad || (isPackMovement &&  get(selectedNgr, 'id'))}
                      />
                    </div>
                  </React.Fragment>
              }
              {
                !movement &&
                <div className='col-xs-4 form-wrap'>
                  <NumberField
                    id='inload.estimatedNetWeight'
                    label={getCountryLabel('tonnage')}
                    placeholder={getCountryLabel('tonnage')}
                    value={inload.estimatedNetWeight.value}
                    disabled={this.isFieldDisabled('inload.netWeight') || archivedStorage || this.isRegradedLoad || isInvoiced || isShrinkageLoad || isStockUpdateLoad}
                    helperText={estimatedNetWeightError}
                    maxValue={999999999.99}
                    onChange={this.handleEstimatedNetWeightFieldChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end' style={styleAdornment}>
                          {unit}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              }
              {
                this.hasQuantityBasedCommodity() &&
                <div className='col-xs-4 form-wrap'>
                  <NumberField
                    id='inload.quantity'
                    label={this.quantityLabel()}
                    placeholder='Please enter'
                    value={inload.quantity.value}
                    disabled={this.isFieldDisabled('inload.netWeight') || archivedStorage || this.isRegradedLoad || isInvoiced}
                    onChange={this.handleQuantityFieldChange}
                    maxValue={999999999.99}
                    helperText={get(inload, 'quantity.errors[0]', '')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end' style={{ color: 'rgb(162,162,162)' }}>
                          {this.quantityUnit()}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              }
              {
                movement && (isContainerMovement || isPackMovement) &&
                <div className='col-xs-4 form-wrap'>
                  <CommonTextField
                    id='inload.containerTare'
                    label='Container Tare'
                    placeholder='Please enter'
                    value={inload.containerTare.value}
                    disabled={this.isFieldDisabled('inload.tareWeight') || archivedStorage || this.isRegradedLoad}
                    onChange={this.handleContainerTareChange}
                    onKeyDown={event => positiveDecimalFilter(event, 2, 999999999.99)}
                    helperText={get(inload, 'containerTare.errors[0]', '')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end' style={styleAdornment}>
                          {unit}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              }
              {
                movement && !isPackMovement &&
                <div className='col-xs-4 form-wrap'>
                  <NumberField
                    id='inload.tareWeight'
                    label='Truck Tare Weight'
                    placeholder='Please enter'
                    value={inload.tareWeight.value}
                    disabled={this.isFieldDisabled('inload.tareWeight') || archivedStorage || this.isRegradedLoad}
                    onChange={this.handleTareWeightChange}
                    maxValue={999999999.99}
                    helperText={get(inload, 'tareWeight.errors[0]', '')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end' style={styleAdornment}>
                          {unit}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              }
              {
                movement &&
                <div className='col-xs-4 form-wrap'>
                  <NumberField
                    id='inload.grossWeight'
                    label={isPackMovement ? 'Container Gross' : 'Truck Gross Weight'}
                    placeholder='Please enter'
                    value={inload.grossWeight.value}
                    onChange={this.handleGrossWeightChange}
                    disabled={this.isFieldDisabled('inload.grossWeight') || archivedStorage || this.isRegradedLoad || isInvoiced}
                    maxValue={999999999.99}
                    helperText={get(
                      inload,
                      'grossWeight.errors[0]',
                      isNetWeightGreaterThanRemainingSpace ?
                        INLOAD_EXCESS_NET_WEIGHT_WARNING_MESSAGE :
                        '',
                    )}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end' style={styleAdornment}>
                          {this.getTruckUnit()}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              }
              {
                movement &&
                <div className='col-xs-4 form-wrap'>
                  <NumberField
                    id='inload.estimatedNetWeight'
                    label='Net Weight'
                    placeholder='Please enter'
                    value={inload.estimatedNetWeight.value ? inload.estimatedNetWeight.value : ''}
                    onChange={() => { }}
                    onBlur={this.handleNetWeightChange}
                    maxValue={999999999.99}
                    disabled={this.isFieldDisabled('inload.netWeight') || archivedStorage || this.isRegradedLoad || isInvoiced}
                    helperText={estimatedNetWeightError}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end' style={styleAdornment}>
                          {this.getTruckUnit()}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              }
              {
                movement &&
                <div className='col-xs-4 form-wrap'>
                  <CommonTextField
                    id='inload.docketNumber'
                    placeholder='Please enter'
                    label={docketNumberFloatingLabelText}
                    value={inload.docketNumber.value}
                    onChange={this.handleFieldChange}
                    helperText={get(inload, 'docketNumber.errors[0]', '')}
                    disabled={completedAndInvoicedMovement || archivedStorage || this.isRegradedLoad}
                  />
                </div>
              }
              <div className="col-xs-4 form-wrap">
                <CommonTextField
                  id='inload.orderNumber'
                  label='Order No (Optional)'
                  placeholder='Please enter'
                  value={inload.orderNumber.value}
                  onChange={this.handleFieldChange}
                  helperText={get(inload, 'orderNumber.errors[0]', '')}
                  maxLength='255'
                  disabled={this.isRegradedLoad || isShrinkageLoad || isStockUpdateLoad}
                />
              </div>
              {
                movement &&
                  <div className='col-xs-4 form-wrap'>
                    <CommonTextField
                      id='inload.bookingNumber'
                      placeholder='Please enter'
                      label='Booking Number (Optional)'
                      value={inload.bookingNumber.value || ''}
                      onChange={this.handleFieldChange}
                      helperText={get(inload, 'bookingNumber.errors[0]', '')}
                    />
                  </div>
              }
              <SpecParametersValue
                commoditySpecs={commoditySpecs}
                fieldsSpecs={specs}
                onChange={this.handleSpecsChange}
                disabled={this.isFieldDisabled('inload.specs.coil') || archivedStorage || this.isRegradedLoad || isShrinkageLoad || isStockUpdateLoad}
                errorTexts={mapValues(specs, spec => get(spec, 'errors[0]', ''))}
                warningTexts={mapValues(specs, spec => get(spec, 'warnings[0]', ''))}
                clearIndex={isAddingToEmptyStorage ? [2] : get(storage, 'isOwner') ? [] : [1]}
                showSecondarySpecs
              />
              {movement && isPackMovement &&
                inload.sealNumbers.value.map((sealNumber, index) => (
                    <div key={index} className='col-xs-4 form-wrap'>
                      <CommonTextField
                        id={index}
                        label={`Seal ${index + 1} Number`}
                        value={sealNumber.value}
                        onChange={this.handleSealChange}
                      />
                    </div>
                  ))
              }
              {get(movement, 'isBlended') &&
              <React.Fragment>
                <div className="col-xs-12" style={{paddingRight: '0px', marginBottom: '30px'}}>
                  <h4 style={{marginBottom: '0px'}}>Applications</h4>
                {
                  map(this.state.chemicalApplications, (obj, index) => (
                    <div key={index}>
                      <div className="col-xs-12" style={{padding: '0px 0px 0px 0px', marginTop: '20px'}}>
                        <div className="col-xs-4" style={{paddingLeft: '0px'}}>
                          <CommodityAutoComplete
                            id='commodity'
                            commodityId={obj.commodityId}
                            selected={obj.commodity}
                            onChange={(value) => this.onChemicalApplicationCommodityChange(obj, value)}
                            label='Application Commodity'
                            errorText={get(obj, 'commodityErrors.0') || (obj.commodityId ? '' : get(obj, 'errors.0'))}
                            itemFilterFunc={commodities => filter(commodities, {type: 'chemical'})}
                            disabled={completedAndInvoicedMovement}
                          />
                        </div>
                        <div className="col-xs-3" style={{paddingLeft: '0px', paddingRight: '0px'}}>
                          <CommonAutoSelect
                            id='applicationRate'
                            disabled={!obj.commodityId || completedAndInvoicedMovement}
                            onChange={value => this.handleApplicationRateChange(obj, value)}
                            label={`Application Rate (${this.applicationCommodityUnit(obj.commodity)}/${this.priceUnit()})`}
                            dataSourceConfig={{ text: 'name', value: 'id' }}
                            value={obj.applicationRate}
                            items={this.getEligibleApplicationRates(obj.commodityId)}
                            errorText={obj.applicationRate ? '' : get(obj, 'errors.0')}
                          />
                        </div>
                        <div className="col-xs-4" style={{paddingRight: '0px'}}>
                          <CommonAutoSelect
                            items={sites}
                            id='storageId'
                            label='Storage'
                            value={obj.storageId}
                            errorText={obj.storageId ? '' : get(obj, 'errors.0', '')}
                            onChange={(value, id, item) => this.handleChemicalApplicationStorageChange(value, item, obj)}
                            dontAutoselectSingleItem
                            disabled={completedAndInvoicedMovement}
                          />
                        </div>
                        {
                          !completedAndInvoicedMovement &&
                            <div className='col-md-1' style={{paddingRight: '0px'}}>
                              <IconButton onClick={() => this.onChemicalApplicationDelete(obj)} color='error'>
                                <RemoveIcon fontSize='inherit' />
                              </IconButton>
                            </div>
                        }
                      </div>
                    </div>
                  ))
                }
                <div className='col-xs-12' style={{marginTop: '30px', paddingLeft: '0px'}}>
                  <AddButton label='Application' onClick={() => this.onChemicalApplicationAdd()} style={{float: 'left'}} />
                </div>
                </div>
              </React.Fragment>
              }
              {
                movement && !isPackMovement && isTruckMassLimitsVisible &&
                <React.Fragment>
                  <div className="col-xs-12 form-wrap">
                    <h4>Truck Mass Limits (optional)</h4>
                  </div>
                  <div className="col-xs-12 form-wrap">
                    <CommonAutoSelect
                      id="code"
                      label='Code'
                      placeholder="Select Code..."
                      items={map(TRUCK_CODES, code => ({ id: code, name: code }))}
                      value={this.state.code.value || ''}
                      onChange={this.handleTruckCodeChange}
                      disabled={this.isRegradedLoad}
                    />
                  </div>
                  <div className="col-xs-4 form-wrap">
                    <CommonAutoSelect
                      id="massLimitPermit"
                      label='Mass Limit Permit'
                      placeholder="Select Mass Limit Permit..."
                      items={map(this.state.permits, permit => ({ id: permit, name: permit }))}
                      value={this.state.massLimitPermit.value || ''}
                      onChange={this.handleSelectFieldChange}
                      dataSourceConfig={{ id: 'id', text: 'name' }}
                      disabled={this.isRegradedLoad}
                      errorText={get(this.state.massLimitPermit.errors, '0') || ''}
                    />
                  </div>
                  <div className="col-xs-3 form-wrap">
                    <FormControlLabel
                      control={
                        <Checkbox
                          id='steer_point_5'
                          color='primary'
                          checked={this.state.models.inload.steer_point_5.value}
                          onChange={this.handleSteerChange}
                          disabled={!this.shouldEnableSteerPoint5() || this.isRegradedLoad}
                        />
                      }
                      label='0.5 T Steer Axle Allowance'
                    />
                  </div>
                  <div className="col-xs-3 form-wrap">
                    <FormControlLabel
                      control={
                        <Checkbox
                          id='steer_1_point_1'
                          color='primary'
                          checked={this.state.models.inload.steer_1_point_1.value}
                          onChange={this.handleSteerChange}
                          disabled={!this.shouldEnableSteer1Point1() || this.isRegradedLoad}
                        />
                      }
                      label='1.1 T Allowance'
                    />
                  </div>
                  <div className="col-xs-2 form-wrap">
                    <FormControlLabel
                      control={
                        <Checkbox
                          id='ghms'
                          color='primary'
                          checked={this.state.ghms.value}
                          onChange={this.handleCheckboxChange}
                          disabled={this.isRegradedLoad || isNoticePermit || (this.state.massLimitPermit.value || '').includes('PBS - ')}
                        />
                      }
                      label='GHMS'
                    />
                  </div>
                  { isNoticePermit &&
                      <div className="col-sm-4" style={{marginBottom: '30px'}}>
                        <CommonAutoSelect
                          id="models.inload.noticeNumber"
                          label='Notice Number'
                          placeholder="Select Notice Number..."
                          items={NOTICE_NUMBERS}
                          value={this.state.models.inload.noticeNumber.value}
                          onChange={this.handleTruckDetailsSelectValueChange}
                          dataSourceConfig={{id: 'id', text: 'name'}}
                          errorText={this.state.truckErrors.noticeNumber}
                        />
                      </div>
                    }
                  {
                    this.state.showAdditionalMassLimitFields &&
                    <AdditionalMassLimitFields
                      permitChecked={Boolean(this.state.permitChecked)}
                      handlePermitClick={this.handlePermitClick}
                      accreditationNumberChecked={Boolean(this.state.accreditationNumberChecked)}
                      handleAccreditationNumberClick={this.handleAccreditationNumberClick}
                      loadSharing={Boolean(this.state.loadSharing)}
                      handleLoadSharingClick={this.handleLoadSharingClick}
                      disableLoadSharing={disableLoadSharing}
                      isPBSMassLimitPermit={(this.state.massLimitPermit.value || '').includes('PBS - ')}
                      notice={this.state.massLimitPermit.value === 'Notice'}
                      handleTruckDetailsChange={this.handleTruckDetailsChange}
                      restrictedChecked={this.state.restrictedChecked}
                      handleRestrictedClick={this.handleRestrictedClick}
                      handleTruckDetailsSelectValueChange={this.handleTruckDetailsSelectValueChange}
                      accreditationNumberFieldId="models.inload.accreditationNumber"
                      accreditationNumber={this.state.models.inload.accreditationNumber.value}
                      restrictedFieldId="models.inload.restricted"
                      restricted={this.state.models.inload.restricted.value}
                      declaredMassLimitFieldId="models.inload.declaredMassLimit"
                      declaredMassLimit={this.state.models.inload.declaredMassLimit.value}
                      permitNumberFieldId="models.inload.permitNumber"
                      permitNumber={this.state.models.inload.permitNumber.value}
                      categoryId={this.state.models.inload.categoryId.value}
                      truckErrors={this.state.truckErrors}
                      truckUnit={this.getTruckUnit()}
                    />
                  }
                </React.Fragment>
              }
              {
                movement && !isPackMovement &&
                <FileUpload
                  id='inload.docketImage'
                  buttonText={`Upload ${getCountryLabel('docket')} Image`}
                  fullWidth={true}
                  fileCount={docketImageFileCount}
                  errorText={inload.docketImage.errors[0]}
                  textFieldstyle={{ float: 'left', color: 'black' }}
                  onChange={this.onDocketImageUpload}
                  onRemove={this.onDocketImageRemove}
                  showImagePreviewFromURL={docketImageUrl}
                  disabled={completedAndInvoicedMovement || archivedStorage || this.isRegradedLoad}
                  maxUploadFileCount={MAX_UPLOAD_DOCKETS}
                />
              }
              <div className='col-xs-12 cardForm-action'>
                <CommonTextField
                  id='inload.comment'
                  label='Comment'
                  value={inload.comment.value}
                  onChange={this.handleFieldChange}
                  multiline
                  rows={3}
                  variant='outlined'
                  disabled={this.isRegradedLoad || isShrinkageLoad || isStockUpdateLoad}
                />
              </div>
              <div className='col-xs-12 cardForm-action padding-reset'>
                <CommonButton
                  label='Cancel'
                  default={true}
                  variant='outlined'
                  onClick={this.props.closeDrawer}
                />
                {!get(this.props, 'inload.titleTransferNumber') && !get(this.props, 'inload.checkpointId') &&
                  get(this.props, 'inload.id') && !isShrinkageLoad &&
                  <CommonButton
                    label='Delete'
                    default={true}
                    variant='outlined'
                    onClick={this.voidLoad}
                    disabled={isInvoiced}
                  />}
                {
                  !isShrinkageLoad && !isStockUpdateLoad &&
                    <CommonButton
                      type='submit'
                      label='Save'
                      primary={true}
                      variant="contained"
                      disabled={this.state.submitting}
                  />
                }
              </div>
            </div>
          </div>
          {(showUpdateToTonnagePopup || (this.state.showAmendTonnagePopup && isParentOrderIndependent)) &&
          <Dialog open onClose={() => this.setState({showAmendTonnagePopup: false}, () => this.unlockSubmit())}>
            <DialogTitleWithCloseIcon onClose={() => this.setState({showAmendTonnagePopup: false}, () => this.unlockSubmit())}>Warning</DialogTitleWithCloseIcon>
            <DialogContent>
              This {entityName} can take up to only {parentTonnage} {this.state.unit} (Inc tolerance).
              Saving this load will take the {entityName}&apos;s {this.countryTonnageLabel.toLowerCase()} to {updateToTonnage.toFixed(2)} {this.state.unit}. Do you want to automatically
              update the {entityName} {this.countryTonnageLabel.toLowerCase()} to {updateToTonnage.toFixed(2)} {this.state.unit} on saving this load?
            </DialogContent>
            <DialogActions>
            <Button
                color='default'
              type='button'
              onClick={() => this.setState({showAmendTonnagePopup: false, warehouseOverDelivered: false}, () => this.unlockSubmit())}
              variant='outlined'>
              Cancel
            </Button>
              <Button type='button' onClick={() => this.setState({amendParentTonnage: true, showAmendTonnagePopup: false, warehouseOverDelivered: false}, () => this.confirmSubmit(this.getSubmitData()))} color='primary' variant='outlined'>
              Yes, Proceed
              </Button>
              {
                window.WAREHOUSE_OVER_DELIVERED_TOGGLE && this.props.movement?.isParentNilTolerance &&
                  <Button color='secondary' type='button' onClick={() => this.setState({amendParentTonnage: true, showAmendTonnagePopup: false, warehouseOverDelivered: true}, () => this.confirmSubmit(this.getSubmitData()))} variant='outlined'>
                    Warehouse Extra Quantity
                  </Button>
              }
            </DialogActions>
          </Dialog>
          }
          {this.state.showAmendTonnagePopup && !isParentOrderIndependent && !showUpdateToTonnagePopup &&
          <Dialog open onClose={() => this.setState({showAmendTonnagePopup: false}, () => this.unlockSubmit())}>
            <DialogTitleWithCloseIcon onClose={() => this.setState({showAmendTonnagePopup: false}, () => this.unlockSubmit())}>Warning</DialogTitleWithCloseIcon>
            <DialogContent>
              This {entityName} can take up to only {parentTonnage} {this.state.unit} (Inc tolerance)
              {isDirectContractMovement ? '' : ` and can be amended up to ${maxParentTonnage} ${this.state.unit} due to ${get(this.props, 'movement.reason')}`} {parentEntityUrl}. {amendMessage}
            </DialogContent>
            <DialogActions>
              <Button
                color='default'
              type='button'
              onClick={() => this.setState({showAmendTonnagePopup: false}, () => this.unlockSubmit())}
              variant='outlined'>
              Cancel
            </Button>
              {
                window.WAREHOUSE_OVER_DELIVERED_TOGGLE && this.props.movement?.isParentNilTolerance &&
                  <Button color='secondary' type='button' onClick={() => this.setState({amendParentTonnage: true, showAmendTonnagePopup: false, warehouseOverDelivered: true}, () => this.confirmSubmit(this.getSubmitData()))} variant='outlined'>
                    Warehouse Extra Quantity
                  </Button>
              }
            </DialogActions>
          </Dialog>
          }
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.main.user.token,
    userCompanyId: state.main.user.user.companyId,
    user: state.main.user.user,
    commodities: state.master.commodities.items,
    isRegoAvailable: state.main.isRegoAvailable,
    createdTruck: state.companies.companies.company.trucks.createdTruck,
    isLoading: state.main.isLoading,
  };
};

const mapDispatchToProps = dispatch => ({
  getCommodities: includeUnknown => dispatch(getCommodities(includeUnknown)),
  validateRego: (key, value, callback) => dispatch(validateRego(key, value, callback)),
  createTruck: (companyId, data, addTruck) => dispatch(createTruck(companyId, data, addTruck)),
  emptyCreatedTruck: () => dispatch(emptyCreatedTruck()),
  isLoading: () => dispatch(isLoading()),
  forceStopLoader: () => dispatch(forceStopLoader())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InloadForm));
