import React from 'react';

import Paper from '@mui/material/Paper'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { isVisibleInCountry } from '../../../common/utils';
import CompanyTooltip from '../../companies/CompanyTooltip'

const InvoicePartiesNewView = ({ invoiceDetails }) => {
  const hideNGR = !isVisibleInCountry('ngr')
  return (
    <Paper sx={{display: 'inline-block', boxShadow: 'none', padding: '8px', width: '100%'}}>
      <div className='col-xs-6 padding-reset'>
        <div className='col-xs-12' style={{fontSize: '12px', color: 'rgba(0, 0, 0, 0.6)'}}>
          {invoiceDetails?.payeeSectionName}
        </div>
        <List
          className='col-xs-12'
          dense
          sx={{
            padding: 0,
            display: 'flex',
            '.MuiListItemText-primary': {fontSize: '14px', fontWeight: 'bold'},
            '.MuiListItemText-secondary': {fontSize: '12px'},
            '.MuiListItem-root': {padding: 0, maxWidth: '300px', width: 'auto', paddingRight: '24px'},
            '.MuiListItemText-root': {marginTop: '4px', marginBottom: '4px'}
          }}
        >
          <ListItem>
            <ListItemText
              primary={
                <CompanyTooltip company={{name: invoiceDetails?.payeeDisplayName, ...invoiceDetails?.payee}}>
                <a target='_blank' href={`#/companies/${invoiceDetails?.payee?.companyId}/details`} rel='noreferrer noopener'>
                  {invoiceDetails?.payeeDisplayName}
                </a>
                  </CompanyTooltip>
              }
              secondary={hideNGR ? '' : `NGR: ${invoiceDetails?.payee?.ngrNumber}`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={invoiceDetails?.payee?.contactName}
              secondary={invoiceDetails?.payeeEmail}
            />
          </ListItem>
        </List>
      </div>
      <div className='col-xs-6 padding-reset'>
        <div className='col-xs-12' style={{fontSize: '12px', color: 'rgba(0, 0, 0, 0.6)'}}>
          {invoiceDetails?.payerSectionName}
        </div>
        <List
          className='col-xs-12'
          dense
          sx={{
            padding: 0,
            display: 'flex',
            '.MuiListItemText-primary': {fontSize: '14px', fontWeight: 'bold'},
            '.MuiListItemText-secondary': {fontSize: '12px'},
            '.MuiListItem-root': {padding: 0, maxWidth: '300px', width: 'auto', paddingRight: '24px'},
            '.MuiListItemText-root': {marginTop: '4px', marginBottom: '4px'}
          }}
        >
          <ListItem>
            <ListItemText
              primary={
                <CompanyTooltip company={{name: invoiceDetails?.payerDisplayName, ...invoiceDetails?.payer}}>
                <a target='_blank' href={`#/companies/${invoiceDetails?.payer?.companyId}/details`} rel='noreferrer noopener'>
                  {invoiceDetails?.payerDisplayName}
                </a>
                  </CompanyTooltip>
              }
              secondary={hideNGR ? '' : `NGR: ${invoiceDetails?.payer?.ngrNumber}`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={invoiceDetails?.payer?.contactName}
              secondary={invoiceDetails?.payerEmail}
            />
          </ListItem>
        </List>
      </div>
    </Paper>
  );
}

export default InvoicePartiesNewView;
