import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import DocketIcon from '@mui/icons-material/LinkedCamera';
import { isEmpty } from 'lodash';
import { setHeaderText, setBreadcrumbs } from '../../actions/main';
import StatCard from './StatCard';
import MovementIcon from '../common/icons/Movement';
import OrderIcon from '../common/icons/Order';
import ContractIcon from '../common/icons/Contract';
import TitleTransferIcon from '../common/icons/TitleTransfer';
import SiteBookingIcon from '../common/icons/SiteBooking';
import InvoiceIcon from '../common/icons/Invoice';
import CompanyIcon from '../common/icons/Company';
import InloadIcon from '../common/icons/Inload';
import OutloadIcon from '../common/icons/Outload';
import TruckIcon from '../common/icons/Truck';
import FarmIcon from '../common/icons/Farm';
import APIService from '../../services/APIService';
import TimeButtonGroup from '../common/TimeButtonGroup';
import { PRIMARY_COLOR_GREEN as GREEN } from '../../common/constants';
import CompanyAutocomplete from '../common/autocomplete/CompanyAutocomplete';


const COLORS = {
  blue: {
    bgColor: 'rgb(209, 233, 252)',
    txtColor: 'rgb(6, 27, 100)',
    iconBgColor: 'rgb(16, 57, 150)',
    iconGradient: 'linear-gradient(135deg, rgba(16, 57, 150, 0) 0%, rgba(16, 57, 150, 0.24) 100%)'
  },
  yellow: {
    bgColor: 'rgb(255, 247, 205)',
    txtColor: 'rgb(122, 79, 1)',
    iconBgColor: 'rgb(183, 129, 3)',
    iconGradient: 'linear-gradient(135deg, rgba(183, 129, 3, 0) 0%, rgba(183, 129, 3, 0.24) 100%)'
  },
  green: {
    bgColor: 'rgb(208, 242, 255)',
    txtColor: 'rgb(4, 41, 122)',
    iconBgColor: 'rgb(12, 83, 183)',
    iconGradient: 'linear-gradient(135deg, rgba(12, 83, 183, 0) 0%, rgba(12, 83, 183, 0.24) 100%)'
  },
  red: {
    bgColor: 'rgb(255, 231, 217)',
    txtColor: 'rgb(122, 12, 46)',
    iconBgColor: 'rgb(183, 33, 54)',
    iconGradient: 'linear-gradient(135deg, rgba(183, 33, 54, 0) 0%, rgba(183, 33, 54, 0.24) 100%)'
  }
}


class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedCompany: null,
      dateFrom: null,
      dateTo: null,
      isCustomApplied: false,
      selectedTimeKey: null,
      anchorEl: null,
      distributions: {},
      ...this.getResetState(),
    }
  }
  componentDidMount() {
    this.fetchData()

    const { dispatch } = this.props;
    dispatch(setHeaderText('Dashboard'))
    dispatch(setBreadcrumbs([{text: 'Dashboard'}]))
  }

  fetchData = () => {
    this.fetchContracts()
    this.fetchOrders()
    this.fetchMovements()
    this.fetchTransfers()
    this.fetchOutloads()
    this.fetchInloads()
    this.fetchSlots()
    this.fetchInvoices()
    this.fetchCompanies()
    this.fetchTrucks()
    this.fetchFarms()
    this.fetchMobileMessages()
  }

  reset = () => this.setState({...this.getResetState()})

  getResetState = () => {
    const struct = {stats: {all: {}, created: {}}, distribution: {all: [], created: []}}
    return {
      contracts: {...struct},
      orders: {...struct},
      movements: {...struct},
      transfers: {...struct},
      outloads: {...struct},
      inloads: {...struct},
      companies: {...struct},
      employees: {...struct},
      vendorDecs: {...struct},
      invoices: {...struct},
      slots: {...struct},
      trucks: {...struct},
      farms: {...struct},
      mobileMessages: {...struct}
    }
  }

  onReload = () => {
    if(this.state.dateFrom || this.state.dateTo)
      this.setState({dateFrom: null, dateTo: null}, this.fetchData)
  }

  getQueryParams = key => ({date_from: this.state.dateFrom, date_to: this.state.dateTo, distribution: this.state.distributions[key], company_id: this.state.selectedCompany?.id})

  handleResponse = (field, response) => this.setState({[field]: this.state.distributions[field] ? {...this.state[field], distribution: response.distribution} : {...this.state[field], stats: response}})

  fetchContracts = () => APIService.contracts().appendToUrl('stats/').get(null, null, this.getQueryParams('contracts')).then(response => this.handleResponse('contracts', response))

  fetchOrders = () => APIService.freights().orders().appendToUrl('stats/').get(null, null, this.getQueryParams('orders')).then(response => this.handleResponse('orders', response))

  fetchMovements = () => APIService.freights().contracts().appendToUrl('stats/').get(null, null, this.getQueryParams('movements')).then(response => this.handleResponse('movements', response))

  fetchTransfers = () => APIService.contracts().appendToUrl('title-transfers/stats/').get(null, null, this.getQueryParams('transfers')).then(response => this.handleResponse('transfers', response))

  fetchOutloads = () => APIService.loads().appendToUrl('outload/stats/').get(null, null, this.getQueryParams('outloads')).then(response => this.handleResponse('outloads', response))

  fetchInloads = () => APIService.loads().appendToUrl('inload/stats/').get(null, null, this.getQueryParams('inloads')).then(response => this.handleResponse('inloads', response))

  fetchSlots = () => APIService.company_sites().appendToUrl('slots/stats/').get(null, null, this.getQueryParams('slots')).then(response => this.handleResponse('slots', response))

  fetchInvoices = () => APIService.invoices().appendToUrl('stats/').get(null, null, this.getQueryParams('invoices')).then(response => this.handleResponse('invoices', response))

  fetchCompanies = () => APIService.companies().appendToUrl('stats/').get(null, null, this.getQueryParams('companies')).then(response => this.handleResponse('companies', response))

  fetchTrucks = () => APIService.trucks().appendToUrl('stats/').get(null, null, this.getQueryParams('trucks')).then(response => this.handleResponse('trucks', response))

  fetchFarms = () => APIService.farms().appendToUrl('stats/').get(null, null, this.getQueryParams('farms')).then(response => this.handleResponse('farms', response))

  fetchMobileMessages = () => APIService.mobile_messages().appendToUrl('stats/').get(null, null, this.getQueryParams('mobileMessages')).then(response => this.handleResponse('mobileMessages', response))
  onCompanyChange = (item, id) => this.setState({selectedCompany: item, ...this.getResetState()}, this.fetchData) // eslint-disable-line no-unused-vars

  onTimeChange = (event, key) => {
    let hours = 1
    let anchorEl = null
    let isCustomApplied = false
    let reset = true
    if(key.endsWith('h'))
      hours = parseInt(key.replace('h'))
    else if(key.endsWith('d'))
      hours = 24 * parseInt(key.replace('d'))
    else if (key === 'custom') {
      isCustomApplied = this.state.isCustomApplied
      anchorEl = event.currentTarget
      hours = false
      reset = false
    }

    let newState = {
      selectedTimeKey: key,
      dateFrom: hours ? moment().subtract(hours, 'hours').toDate().toISOString() : this.state.dateFrom,
      dateTo: hours ? moment().toDate().toISOString() : this.state.dateTo,
      anchorEl: anchorEl,
      isCustomApplied: isCustomApplied
    }
    if(reset)
      newState = {...newState, ...this.getResetState()}

    this.setState(newState, () => !anchorEl && this.fetchData())
  }

  onTimeFieldChange = event => this.setState({[event.target.id]: moment(event.target.value).toDate().toISOString()})

  onClear = () => this.setState({selectedTimeKey: null, dateFrom: null, dateTo: null, anchorEl: null, isCustomApplied: false, ...this.getResetState()}, this.fetchData)

  onApply = () => this.setState({anchorEl: null, isCustomApplied: true, ...this.getResetState()}, this.fetchData)

  toggleDistribution = (resource, callback) => this.setState({distributions: {...this.state.distributions, [resource]: !this.state.distributions[resource]}}, () => {
    if(isEmpty(this.state[resource].stats?.all) || isEmpty(this.state[resource].distribution?.all))
      callback()
  })

  render() {
    return (
      <div style={{display: 'flex', flexDirection: 'column'}} className='dashboard-main'>
        <div className='col-xs-12 no-side-padding' style={{paddingBottom: '6px', display: 'flex', alignItems: 'center'}}>
          <div className='col-xs-4 no-side-padding company-search' style={{background: '#FFF'}}>
            <CompanyAutocomplete
              size='small'
              selected={this.state.selectedCompany}
              onChange={this.onCompanyChange}
              minLength={3}
              getRegisteredCompanies={true}
              variant='outlined'
              disabled={false}
              label='Company'
            />
          </div>
          <div className='col-xs-8 no-side-padding time-filter-container' style={{display: 'flex'}}>
            <div className='col-xs-12 no-right-padding'>
              <TimeButtonGroup
                selected={this.state.selectedTimeKey}
                dateFrom={this.state.dateFrom}
                dateTo={this.state.dateTo}
                onReload={this.onReload}
                onClick={this.onTimeChange}
                isCustomApplied={this.state.isCustomApplied}
                anchorEl={this.state.anchorEl}
                onTimeFieldChange={this.onTimeFieldChange}
                onClear={this.onClear}
                onApply={this.onApply}
                btnGroupStyle={{background: '#FFF'}}
              />
            </div>
          </div>
        </div>
        <div style={{display: 'flex', justifyContent: 'center'}} className='stats-row'>
          <StatCard
            icon={<ContractIcon noStyle fill={COLORS.green.txtColor} />}
            secondaryText='Commodity Contracts'
            stats={this.state.contracts}
            {...COLORS.green}
            id='contracts'
            fields={[
              {id: 'count', label: 'Count', color: GREEN},
            ]}
            isDistribution={this.state.distributions?.contracts}
            title='Contracts Monthly Distribution'
            toggleDistribution={() => this.toggleDistribution('contracts', this.fetchContracts)}
          />
          <StatCard
            icon={<OrderIcon fill={COLORS.yellow.txtColor} />}
            secondaryText='Orders'
            stats={this.state.orders}
            delay='300ms'
            {...COLORS.yellow}
            id='orders'
            fields={[
              {id: 'count', label: 'Count', color: GREEN},
            ]}
            isDistribution={this.state.distributions?.orders}
            title='Orders Monthly Distribution'
            toggleDistribution={() => this.toggleDistribution('orders', this.fetchOrders)}
          />
          <StatCard
            icon={<MovementIcon fill={COLORS.blue.txtColor} />}
            secondaryText='Movements'
            stats={this.state.movements}
            delay='600ms'
            {...COLORS.blue}
            id='movements'
            fields={[
              {id: 'count', label: 'Count', color: GREEN},
            ]}
            isDistribution={this.state.distributions?.movements}
            title='Movement Monthly Distribution'
            toggleDistribution={() => this.toggleDistribution('movements', this.fetchMovements)}
          />
          <StatCard
            icon={<InvoiceIcon noStyle fill={COLORS.red.txtColor} />}
            secondaryText='Invoices'
            stats={this.state.invoices}
            delay='900ms'
            {...COLORS.red}
            id='invoices'
            fields={[
              {id: 'count', label: 'Count', color: GREEN},
            ]}
            isDistribution={this.state.distributions?.invoices}
            title='Invoices Monthly Distribution'
            toggleDistribution={() => this.toggleDistribution('invoices', this.fetchInvoices)}
          />
        </div>
        <div style={{display: 'flex', justifyContent: 'center'}} className='stats-row'>
          <StatCard
            icon={<TitleTransferIcon fill={COLORS.red.txtColor} />}
            secondaryText='Title Transfers'
            stats={this.state.transfers}
            delay='1200ms'
            {...COLORS.red}
            id='title-transfers'
            fields={[
              {id: 'count', label: 'Count', color: GREEN},
            ]}
            isDistribution={this.state.distributions?.transfers}
            title='Title Transfers Monthly Distribution'
            toggleDistribution={() => this.toggleDistribution('transfers', this.fetchTransfers)}
          />
          <StatCard
            icon={<OutloadIcon noStyle fill={COLORS.blue.txtColor} />}
            secondaryText='Outloads'
            stats={this.state.outloads}
            delay='1500ms'
            {...COLORS.blue}
            id='outloads'
            fields={[
              {id: 'count', label: 'Count', color: GREEN},
            ]}
            isDistribution={this.state.distributions?.outloads}
            title='Outloads Monthly Distribution'
            toggleDistribution={() => this.toggleDistribution('outloads', this.fetchOutloads)}
          />
          <StatCard
            icon={<InloadIcon fill={COLORS.yellow.txtColor} />}
            secondaryText='Inloads'
            stats={this.state.inloads}
            delay='1800ms'
            {...COLORS.yellow}
            id='inloads'
            fields={[
              {id: 'count', label: 'Count', color: GREEN},
            ]}
            isDistribution={this.state.distributions?.inloads}
            title='Inloads Monthly Distribution'
            toggleDistribution={() => this.toggleDistribution('inloads', this.fetchInloads)}
          />
          <StatCard
            icon={<SiteBookingIcon fill={COLORS.green.txtColor} />}
            secondaryText='Slots'
            stats={this.state.slots}
            delay='2100ms'
            {...COLORS.green}
            id='slots'
            fields={[
              {id: 'count', label: 'Count', color: GREEN},
              {id: 'completed', label: 'Delivered/Completed', bgColor: COLORS.blue.bgColor, borderColor: COLORS.blue.txtColor, type: 'line'},
            ]}
            isDistribution={this.state.distributions?.slots}
            title='Slots Monthly Distribution'
            toggleDistribution={() => this.toggleDistribution('slots', this.fetchSlots)}
          />
        </div>
        <div style={{display: 'flex', justifyContent: 'center'}} className='stats-row'>
          {
            !this.state.selectedCompany &&
              <StatCard
                icon={<CompanyIcon fill={COLORS.green.txtColor} />}
                secondaryText='Companies'
                stats={this.state.companies}
                delay='2400ms'
                {...COLORS.green}
                id='companies'
                isDistribution={this.state.distributions?.companies}
                title='Companies Monthly Distribution'
                toggleDistribution={() => this.toggleDistribution('companies', this.fetchCompanies)}
                fields={[
                  {id: 'count', label: 'Count', color: GREEN},
                  {id: 'subscriber', label: 'Subscriber', bgColor: GREEN, borderColor: GREEN, type: 'bar'},
                  {id: 'registered', label: 'Registered', bgColor: COLORS.blue.bgColor, borderColor: COLORS.blue.txtColor, type: 'bar'},
                  {id: 'unregistered', label: 'UnRegistered', bgColor: COLORS.red.bgColor, borderColor: COLORS.red.txtColor, type: 'bar'},
                ]}
              />
          }
          <StatCard
            icon={<FarmIcon fill={COLORS.yellow.txtColor} />}
            secondaryText='Farms'
            stats={this.state.farms}
            delay='2700ms'
            {...COLORS.yellow}
            id='farms'
            fields={[
              {id: 'count', label: 'Count', color: GREEN},
            ]}
            isDistribution={this.state.distributions?.farms}
            title='Farms Monthly Distribution'
            toggleDistribution={() => this.toggleDistribution('farms', this.fetchFarms)}
          />
          <StatCard
            icon={<TruckIcon fill={COLORS.blue.txtColor} />}
            secondaryText='Trucks'
            stats={this.state.trucks}
            delay='2700ms'
            {...COLORS.blue}
            id='trucks'
            fields={[
              {id: 'count', label: 'Count', color: GREEN},
            ]}
            isDistribution={this.state.distributions?.trucks}
            title='Trucks Monthly Distribution'
            toggleDistribution={() => this.toggleDistribution('trucks', this.fetchTrucks)}
          />
          <StatCard
            icon={<DocketIcon style={{color: COLORS.red.txtColor}} />}
            secondaryText='SMS'
            stats={this.state.mobileMessages}
            delay='3000ms'
            {...COLORS.red}
            id='dockets'
            fields={[
              {id: 'sms_sent', label: 'SMS Sent', color: GREEN},
              {id: 'dockets_sms_sent', label: 'Dockets SMS Sent', bgColor: COLORS.blue.bgColor, borderColor: COLORS.blue.txtColor, type: 'bar'},
              {id: 'dockets_sms_replied', label: 'Dockets SMS Replied', bgColor: COLORS.red.bgColor, borderColor: COLORS.red.txtColor, type: 'bar'},
            ]}
            isDistribution={this.state.distributions?.mobileMessages}
            title='SMS Monthly Distribution'
            toggleDistribution={() => this.toggleDistribution('mobileMessages', this.fetchMobileMessages)}
            countField='smsSent'
          />
        </div>
      </div>
    );
  }
}

export default connect()(Dashboard);
