import { connect } from 'react-redux';
import GenericTable from '../components/GenericTable';
import { clickEditTrailerIcon } from '../actions/companies/trucks';
import { isSystemCompany, getCountryLabel, currentUserCompany, isCompanyAdminOrObserver } from '../common/utils';
import { showSideDrawerForTransfer } from '../actions/companies';
import APIService from '../services/APIService';;
import alertifyjs from 'alertifyjs';
import { includes } from 'lodash';

const mapStateToProps = (state, ownProps) => {
  const columns = [
    { key: 'rego', header: `Trailer ${getCountryLabel('rego')}`, },
    { key: 'tareWeightDisplayValue', header: 'Trailer Tare Weight' },
    { key: 'grossWeightDisplayValue', header: 'Trailer Gross Weight' },
    { key: 'netWeightDisplayValue', header: 'Trailer Net Weight' },
  ];
  let config = {
    columns: columns,
    items: state.companies.companies.company.trucks.trailers,
    scrollToTopOnUpdate: false,
    orderBy: 'rego',
    tableHeader: 'Trailers',
    displayIDColumn: 'rego'
  };
  config.optionsItems = [];
  if(isSystemCompany() && !ownProps?.isArchived)
  config.optionsItems = [{ key: 'transfer', text: 'Transfer' }];

  if (isSystemCompany() || (ownProps?.companyId == currentUserCompany()?.id && isCompanyAdminOrObserver())) {
    var option = ownProps.isArchived ? { key : 'unarchive', text: 'Unarchive' } : { key : 'archive', text: 'Archive' };
    config.optionsItems = [...config.optionsItems, option]
  }

return config;
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleDefaultCellClick: (rowNum) => {
      if (!ownProps.isArchived)
        dispatch(clickEditTrailerIcon(rowNum));
    },
    handleOptionClick: (index, key, id, item) => {
      if (key === 'transfer') {
        dispatch(showSideDrawerForTransfer(item, true));
      }
      if (includes(['archive', 'unarchive'], key)) {
        APIService.trailers(item.id).put({'isActive': ownProps.isArchived}).then(() => {
          let keyWord = ownProps.isArchived ? 'Unarchived' : 'Archived';
          alertifyjs.success(`Trailer ${keyWord}`, 3, () => window.location.reload())
        })
      }
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericTable);
