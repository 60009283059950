import { Paper } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { get, includes } from 'lodash';
import alertifyjs from 'alertifyjs';
import APIService from '../../services/APIService';
import AddButton from '../common/AddButton';
import { getPaginatedOrdersResponse } from '../../actions/companies/orders';
import PackOrdersTable from '../../containers/PackOrdersTable';
import { setHeaderText, setBreadcrumbs, forceStopLoader, setDownloadBar } from '../../actions/main';
import { isAtGlobalOrders, defaultViewAction, attachCSVEventListener } from '../../common/utils';
import CommonListingButton from '../common/CommonListingButton';
import SideDrawer from '../common/SideDrawer';
import CustomHeaderOptions from '../common/CustomHeaderOptions';
import { COMPANY_ADMIN, OBSERVER_TYPE_ID, OFFICE_ADMIN, SYSTEM, DEFAULT_ORDERS_TABLE_COLUMN_LIMIT, ORDERS_TABLE_COLUMN_LIMIT, PACK_ORDERS_HEADERS } from '../../common/constants';
import DownloadDataDialog from '../common/DownloadDataDialog';


class PackOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      orders: [],
      customTableColumnOptions: false,
      customTableColumnNames: {},
      csvPopup: false,
      customColumns: true,
      customColumnNames: {},
      customHeaderOptions: false,
      csvData: [],
      customColumnTitle: undefined,
    };
  }

  componentDidMount() {
    this.fetchOrders();
    this.setHeaderAndBreadcrumbs();
    this._attachCSVEventListener()
  }

  onCloseDownloadResponse() {
    this.props.dispatch(setDownloadBar(false));
  }

  onDownloadResponse(message) {
    this.props.dispatch(setDownloadBar(message, true, this.onCloseDownloadResponse));
  }

  _attachCSVEventListener() {
    attachCSVEventListener('pack-orders-csv-ready', 'Orders', this.onDownloadResponse);
  }

  componentDidUpdate(prevProps) {
    if (get(prevProps, 'count') !== this.props.count) this.setHeaderAndBreadcrumbs();
    this.props.dispatch(forceStopLoader());
  }
  componentWillUnmount() {
    if(window.location.hash.includes('?') && isAtGlobalOrders())
      window.location.hash = window.location.hash.split('?')[0]
  }

  setHeaderAndBreadcrumbs() {
    const { count, dispatch } = this.props;
    const countLabel = ` (${count})`;
    let headerText = 'Pack Orders';
    let breadcrumbs = [{ text: headerText + countLabel }];
    dispatch(setHeaderText(headerText));
    dispatch(setBreadcrumbs(breadcrumbs));
  }

  fetchOrders() {
    this.setState({isLoading: true}, () => {
      APIService.freights().orders().appendToUrl('web/?type_id=6').get().then(orders => this.setState({orders: orders, isLoading: false}, () => {
        this.props.dispatch(getPaginatedOrdersResponse(orders));
      }));
    });
  }

  getActionsOptionMapperListItems() {
    return [
      { name: 'Custom Table Columns', fx: () => this.updateCustomTableColumns() },
      defaultViewAction
    ];
  }

  async updateCustomTableColumns() {
    if (this.props.currentUser.company.enableCustomCsv) {
      const tableColumnNames = await APIService.profiles().appendToUrl(`${this.props.currentUser.id}/table-preferences/pack_order_table/`).get();
      this.setState({customTableColumnNames: tableColumnNames, customTableColumnOptions: true});
    }
    else {
      alertifyjs.alert(
        'Permission Denied',
        'This feature is not enabled for your company. Please contact AgriChain support',
        () => { },
      );
    }
  }

  getColumnsMapping() {
    const packOrderColumns = [...PACK_ORDERS_HEADERS];
    return packOrderColumns.reduce((obj, objectKey) => ({ ...obj, [objectKey.key]: objectKey.header }), {});
  }

  updateColumnCount(count) {
    this.setState({customColumnTitle: `Edit Columns (${count})`});
  }

  canExportCSV() {
    return includes([COMPANY_ADMIN, OFFICE_ADMIN, SYSTEM, OBSERVER_TYPE_ID], get(this.props.currentUser, 'typeId'));
  }

  fetchCSVData = () => {
    var param = '';
    if (this.state.customColumns)
      param += '&custom_csv';
    this.setState({csvPopup: false})
    this.props.dispatch(setDownloadBar(`Your Pack Orders CSV is getting prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.`, true));
    APIService.freights().appendToUrl(`pack/orders/csv/?${param}`)
      .get(this.props.token, {
        'Content-Type': 'text/csv',
        Accept: 'text/csv',
      })
      .then(csvData => {
        this.setState({ csvData: csvData || [] });
      });
  };

  customCsvEnabled() {
    const newState = {...this.state};
    if (this.props.currentUser.company.enableCustomCsv) {
      newState.csvPopup = true;
      this.setState(newState);
    }
    else {
      newState.customColumns = false;
      this.setState(newState, this.fetchCSVData);
    }
  }

  render() {
    return (
      <Paper className='paper-table'>
        <div style={{ position: 'relative' }}>
          <div>
            <AddButton
              label='Pack Order'
              href='/#/orders/pack/new'
              app='order'
              tooltipTitle='Create Pack Order'
              tooltipPlacement='top'
            />
          </div>
          <div style={{float: 'right', marginRight: '10px'}}>
          <CommonListingButton
                showMenus
                showDownLoadIcon={false}
                optionMapper={this.getActionsOptionMapperListItems()}
                title='Actions'
                name='Actions'
              />
          </div>
          <SideDrawer
              isOpen={this.state.customTableColumnOptions}
              title={this.state.customColumnTitle}
              onClose={() => this.setState({customTableColumnOptions: false})}
              size="small"
            >
              <CustomHeaderOptions
                customColumns={this.state.customTableColumnNames}
                closeDrawer={() => this.setState({customTableColumnOptions: false})}
                user={this.props.currentUser}
                token={this.props.token}
                table_type="pack_order_table"
                columnsMapping={this.getColumnsMapping()}
                maxColumnLimit={ORDERS_TABLE_COLUMN_LIMIT}
                updateColumnCount={(count) => this.updateColumnCount(count)}
                defaultColumnLimit={DEFAULT_ORDERS_TABLE_COLUMN_LIMIT}
              />
           </SideDrawer>
          {this.canExportCSV() && (
            <CommonListingButton
              defaultHandler={() => this.customCsvEnabled()}
              optionMapper={[
                { name: 'Complete List', fx: () => this.customCsvEnabled() },
              ]}
              title='Download Contents of the table in a CSV'
              name='Export'
            />
          )}
          <DownloadDataDialog
            open={this.state.csvPopup}
            onClose={() => this.setState({csvPopup: false})}
            title='Download Pack Orders Data'
            onDownload={this.fetchCSVData}
            csvType="pack_orders_csv"
            enableCustomCsv={this.props.currentUser.company.enableCustomCsv}
            customColumnTitle={this.state.customColumnTitle}
            updateColumnCount={(count) => this.updateColumnCount(count)}
            user={this.props.currentUser}
            token={this.props.token}
          />
          <PackOrdersTable dontRedirect={this.props.dontRedirect} />
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.main.user.token,
    currentUser: state.main.user.user,
    count: get(state.companies.orders, 'paginationData.count') || 0,
  };
};

export default connect(mapStateToProps)(PackOrders);
