import React from 'react';
import { connect } from 'react-redux';
import { Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Dialog, DialogActions, DialogContent, Tooltip, DialogTitle, Checkbox, Badge, FormControlLabel, Chip, Skeleton } from '@mui/material';
import {
  get, isEmpty, find, reject, set, map,
  filter, includes, compact, sum, forEach, orderBy,
  uniq, intersection, without, uniqBy
} from 'lodash';
import APIService from '../../services/APIService';
import { currentUserCompany, getCountryFormats, attachCSVEventListener, defaultViewAction, getCountryCurrency } from '../../common/utils';
import { forceStopLoader, isLoading, setDownloadBar } from '../../actions/main';
import InvoicePayableTable from '../../containers/InvoicePayableTable';
import { FILTER_KEYS_TO_EXCLUDE, FREIGHT_RECEIVABLE_ITEM_COLUMNS, INVOICE_FILTER_KEYS_MAPPING, PREDEFINED_DATE_RANGE_FILTER_KEYS } from '../../common/constants';
import IconButton from '@mui/material/IconButton';
import ExpandIcon from '@mui/icons-material/Expand';
import CollapseIcon from '@mui/icons-material/UnfoldLess';
import Create from '@mui/icons-material/Create';
import moment from 'moment';
import {
  Event as DueDateIcon,
  AssignmentTurnedIn as CreateIcon,
  MarkEmailRead as SendIcon,
} from '@mui/icons-material';
import CommonDatePicker from '../common/CommonDatePicker';
import alertifyjs from 'alertifyjs';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import EmailAutocomplete from '../common/autocomplete/EmailAutocomplete';
import CommonListingButton from '../common/CommonListingButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import SideDrawer from '../common/SideDrawer';
import CustomHeaderOptions from '../common/CustomHeaderOptions';
import InvoiceFilters from '../common/InvoiceFilters';
import { Virtuoso } from 'react-virtuoso';

const CUSTOM_HEADER_EDIT = {
  fontSize: '12px',
  textDecoration: 'underline',
  cursor: 'pointer',
  marginLeft: '10px'
};

const OPTIONS = {
  'create': {
    title: 'Create Invoices',
  },
  'createSend': {
    title: 'Create and Send Invoices',
  },
  'dueDate': {
    title: 'Update Payment Due Date',
  }
}

const DEFAULT_FILTERS = {
  'payer__company_id__in': [],
  'freight_order__commodity__id__in': '',
  'freight_order__invoicing__in': '',
  'payment_due_date_range': '',
  'payment_due_date__gte': '',
  'payment_due_date__lte': ''
}

class FreightInvoiceReceivable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: [],
      loadingItemsFor: [],
      selectedPayerCompanyIds: [],
      invoiceItems: [],
      isFetchingInvoices: false,
      companyDirectory: [],
      invoiceItemsGroupedByPayer: [],
      isFetchingCompanyDirectory: false,
      selectedInvoiceItems: [],
      openBulkActions: false,
      selectedBulkOption: undefined,
      openDialog: false,
      dueDate: undefined,
      selectedRecipients: undefined,
      recipientsDialogOpen: false,
      sellerEmployeeData: undefined,
      showRecipientsError: false,
      csvPopup: false,
      isFilteredCsv: false,
      customColumns: true,
      customColumnNames: {},
      customHeaderOptions: false,
      customColumnTitle: undefined,
      applyFilters: false,
      openSideDrawer: false,
      filters: {},
    }
  }

  componentDidMount() {
    this._attachCSVEventListener();
    this.fetchInvoices();
    APIService.profiles()
    .filters('freight_invoice_receivable')
    .get()
    .then(res => {
      this.setState({
        filters: get(res, 'freight_invoice_receivable') || DEFAULT_FILTERS,
      });
    });
  }

  onDownloadResponse(message) {
    this.props.dispatch(setDownloadBar(message, true, this.onCloseDownloadResponse));
  }

  _attachCSVEventListener() {
    attachCSVEventListener('freight_receivables-csv-ready', 'Freight Invoice Receivables', this.onDownloadResponse);
  }

  fetchInvoices() {
    this.props.dispatch(isLoading('LoadingFreightReceivables'))
    if(!this.state.isFetchingInvoices) {
      this.setState({isFetchingInvoices: true}, async () => {
        const company = currentUserCompany();
        this.props.dispatch(isLoading('LoadingFreightReceivables'))
        APIService.invoices().appendToUrl(`freight-receivable-items/?company_id=${company.id}&items=false`).get().then(response =>
          this.setState({
            invoiceItems: response,
            isFetchingInvoices: false,
            expanded: response?.length == 1 ? [response[0].companyId]: []
          }, () => {
            if(isEmpty(this.state.companyDirectory))
              this.getCompanyDirectory()
            else
              this.setReceivableItems();
          }));
      })
    }
  }

  toFormattedDate = date => {
    if(date) {
      const mDate = moment(date)
      return mDate.isValid() ? mDate.format(getCountryFormats().date) : date
    }
    return date
  }

  setReceivableItems() {
    const {invoiceItems} = this.state
    if (!isEmpty(this.state.companyDirectory)) {
      map(invoiceItems, obj => {
        let payee = find(this.state.companyDirectory, {id: obj.companyId});
        obj.payerName = get(payee, 'name');
        obj.allSelected = false;
        if (obj.recipients && !isEmpty(obj.recipients)) {
          let recipientDisplayStr = "";
          map(obj.recipients, recipient => {
            if (isEmpty(recipientDisplayStr))
              recipientDisplayStr = recipientDisplayStr + recipient.email;
            else
              recipientDisplayStr = recipientDisplayStr + ", " + recipient.email;
          });
          obj['recipientDisplay'] = 'Recipients: (' + recipientDisplayStr + ')';
        }
        map(obj.items, invoiceItem => {
          invoiceItem.paymentDue = this.toFormattedDate(invoiceItem.paymentDue)
        })
      })
      this.setState({ invoiceItemsGroupedByPayer: invoiceItems });
    }
    this.props.dispatch(forceStopLoader())
  }

  getCompanyDirectory() {
    if(!this.state.isFetchingCompanyDirectory)
      this.setState({isFetchingCompanyDirectory: true}, async () => {
        APIService.companies().appendToUrl('directory/names/?excludeGroups=true&include_self=True').get().then(companies =>
          this.setState({companyDirectory: companies, isFetchingCompanyDirectory: false}, this.setReceivableItems));
      })
  }

  isSelected = (invoiceItem, payer) => Boolean(this.state.selectedPayerCompanyIds?.includes(payer?.companyId) || find(this.state.selectedInvoiceItems, {id: invoiceItem.id}) || invoiceItem.isCheckedItem);

  isSomeSelectedForPayer = payer => {
    const itemIds = map(payer?.items, 'id') || []
    if(itemIds.length) {
      const selected = filter(this.state.selectedInvoiceItems, item => itemIds.includes(item.id))
      return [Boolean(selected.length && selected.length !== itemIds.length), Boolean(selected.length && selected.length === itemIds.length)]
    }
    return [false, false] // some not all, all
  }

  updateSelectedPayerCompanyIdsFromSelectedItem = payer => {
    const [isSome, isAll] = this.isSomeSelectedForPayer(payer)
    const isAlreadySelectedPayer = this.state.selectedPayerCompanyIds.includes(payer.companyId)
    if(isAll && !isAlreadySelectedPayer)
      this.setState({selectedPayerCompanyIds: [...this.state.selectedPayerCompanyIds, payer.companyId] })
    else if(isSome && isAlreadySelectedPayer)
      this.setState({selectedPayerCompanyIds: without(this.state.selectedPayerCompanyIds, payer.companyId) })
    else if (!isAll && !isSome && isAlreadySelectedPayer)
      this.setState({selectedPayerCompanyIds: without(this.state.selectedPayerCompanyIds, payer.companyId) })
  }

  updateSelectedInvoiceItem = (selected, isChecked, obj) => {
    const newState = {...this.state};
    newState.selectedInvoiceItems = isChecked ? [...this.state.selectedInvoiceItems, selected] : reject(this.state.selectedInvoiceItems, {id: selected.id});
    this.setState(newState, () => this.updateSelectedPayerCompanyIdsFromSelectedItem(obj));
  }

  onSelectAllToggle = (event, value, obj) => {
    const newState = {...this.state};
    var index = this.state.invoiceItemsGroupedByPayer.findIndex(item => item.companyId === obj.companyId);
    set(newState, `invoiceItemsGroupedByPayer.${index}.allSelected`, value);
    if (value)
      newState.selectedInvoiceItems = [...this.state.selectedInvoiceItems, ...obj.items];
    else {
      let itemIds = map(obj.items, 'id');
      newState.selectedInvoiceItems = this.state.selectedInvoiceItems.filter(item => !itemIds.includes(item.id));
    }
    this.setState(newState);
  }

  handleEmailRecipients(companyId) {
    var index = this.state.invoiceItemsGroupedByPayer.findIndex(item => item.companyId === companyId);
    const selectedRecipients = get(this.state.invoiceItemsGroupedByPayer[index], 'recipients');
    this.setState({selectedRecipients: selectedRecipients})
    APIService
      .profiles()
      .appendToUrl('employees-signature/')
      .get(null, null, {company_ids: companyId})
      .then(employees => {
        const contactsWithEmail = map(filter(employees, employee => employee.email), employee => ({...employee, title: `${employee.name} (${employee.email})`, value: employee.email}))
        this.setState({sellerEmployeeData: contactsWithEmail, recipientsDialogOpen: true})
      })
  }

  toggleBulkActions = event => this.setState(
    {openBulkActions: this.state.openBulkActions ? false : event.currentTarget}
  )

  onBulkActionClick = event => this.setState({openDialog: true, openBulkActions: false, selectedBulkOption: event.currentTarget.id})

  onDialogClose = () => this.setState({recipientsDialogOpen: false});

  onPaymentDueDateChange = dueDate => this.setState({dueDate: dueDate});

  onBulkInvoiceClose = () => this.setState({openDialog: false, selectedBulkOption: false});

  onRecipientsChange = (event, selected) => {
    if (selected && selected.length <= 3)
      this.setState({showRecipientsError: false});
    this.setState({selectedRecipients: selected});
  }

  generateInvoices = noMail => {
    this.props.dispatch(isLoading('bulkGenerateReceivableInvoices'))
    const xeroEnabled = get(this.props, 'currentUser.company.xeroEnabled');
    let data = {
      'invoiceItems': [...new Set(this.state.selectedInvoiceItems.map(item => item.id))],
      'noMail': noMail,
    };
    if (xeroEnabled)
      data['communication'] = {'createXeroInvoice': true};
    APIService.invoices()
    .appendToUrl('freight-receivable-generate/')
    .post(data)
    .then(() => {
      alertifyjs.success("Receivables Invoice created successfully");
      this.onBulkInvoiceClose()
      this.fetchInvoices()
      window.location.reload()
    });
  }

  onSubmit = () => {
    const { dueDate, selectedBulkOption, selectedInvoiceItems } = this.state
    if(selectedBulkOption === 'create')
      this.generateInvoices(true)
    else if(selectedBulkOption === 'createSend')
      this.generateInvoices(false)
    else if(selectedBulkOption === 'dueDate')
      APIService.invoices().appendToUrl('web/').put({invoice_ids: map(selectedInvoiceItems, 'invoiceId'), payment_due_date: dueDate}).then(() => {
        this.fetchInvoices()
        this.onBulkInvoiceClose()
      })
  }

  submitEmailRecipients = () => {
    if (this.state.selectedRecipients.length > 3) {
      this.setState({showRecipientsError: true});
    }
    else {
      this.setState({recipientsDialogOpen: false, showRecipientsError: false});
      const companyId = get(this.state.selectedRecipients, '0.companyId');
      var index = this.state.invoiceItemsGroupedByPayer.findIndex(item => item.companyId === companyId);
      var invoiceIds = map(this.state.invoiceItemsGroupedByPayer[index].items, item => item.invoiceId);
      const data = {
        'selectedRecipients': this.state.selectedRecipients,
        'invoiceIds': invoiceIds,
        'isReceivable': true,
      }
      APIService
        .invoices()
        .appendToUrl('email-recipients/')
        .put(data)
        .then(() => window.location.reload());
    }
  }

  fetchCSVData = (callback) => {
    const company = currentUserCompany();
    var param = this.state.isFilteredCsv ? 'show_filters': '';
    if (this.state.customColumns) {
      param = param.length == 0 ? param + 'custom_csv' : param + '&custom_csv';
    }
    this.props.dispatch(setDownloadBar('Your Freight Receivables CSV is getting prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true))
    const service = APIService.invoices();
    service.appendToUrl(`freight-receivables/csv/?company_id=${company.id}&${param}`);
    service
      .get(this.props.token, {
        'Content-Type': 'text/csv',
        Accept: 'text/csv',
      })
      .then(() => this.setState({csvPopup: false}));
    if (callback) {
      callback();
    }
  };

  customCsvEnabled(isFilteredCsv) {
    const newState = {...this.state};
    newState.isFilteredCsv = isFilteredCsv;
    if (this.props.currentUser.company.enableCustomCsv) {
      newState.csvPopup = true;
      this.setState(newState);
    }
    else {
      newState.customColumns = false;
      this.setState(newState, this.fetchCSVData);
    }
  }

  async editCustomHeaderOptions() {
    const columnNames = await APIService.profiles().appendToUrl(`${this.props.currentUser.id}/report-preferences/freight_invoice_receivables_csv/`).get();
    this.setState({customColumnNames: columnNames, customHeaderOptions: true});
  }

  updateColumnCount(count) {
    this.setState({customColumnTitle: `Edit Columns (${count})`});
  }

  handleFilters = bool => {
    this.setState({
      applyFilters: bool,
      openSideDrawer: bool,
    });
  };

  handleFilterState = (key, value) => {
    this.setState({[key]: value}, () => {
      if(key === 'applyFilters' && value) {
        const { filters } = this.state;
        delete filters['commodity__id__in'];
        APIService
          .profiles()
          .filters()
          .post({ freight_invoice_receivable: filters }, this.props.token)
          .then(res => {
            this.setState({filters: get(res, 'filters.freight_invoice_receivable', {})}, () => {
              this.fetchInvoices();
            })
          });
      }
    });
  };

  customFilterValueExist = filterKeys => filterKeys.some(key => Boolean(get(this.state.filters, key)))

  filterCriteria = (key, value) => includes(FILTER_KEYS_TO_EXCLUDE, key) ? false : includes(PREDEFINED_DATE_RANGE_FILTER_KEYS, key) && value === 'custom' ? this.customFilterValueExist(get(INVOICE_FILTER_KEYS_MAPPING, key)) : value.length !== 0;

  getActionsOptionMapperListItems() {
    return [defaultViewAction];
  }

  getSelectedSummary = () => {
    const { invoiceItemsGroupedByPayer, selectedPayerCompanyIds, selectedInvoiceItems } = this.state
    let count = selectedInvoiceItems?.length || 0
    let total = sum(selectedInvoiceItems?.map(i => parseFloat(i?.total || 0))) || 0

    forEach(selectedPayerCompanyIds, payerCompanyId => {
      const payer = find(invoiceItemsGroupedByPayer, {companyId: payerCompanyId})
      if(!payer.items?.length || !intersection(selectedInvoiceItems?.map(item => item.id), payer?.items?.map(item => item.id))?.length) {
      count += payer.totalItems
      total += parseFloat(payer.total) || 0
      }
    })

    return {count, total}
  }

  fetchItems = (event, payerCompanyId) => {
    event.preventDefault()
    event.stopPropagation()
    if(payerCompanyId && this.state.expanded.includes(payerCompanyId)) {
      this.setState({expanded: without(this.state.expanded, payerCompanyId)})
    }
    if(payerCompanyId && !this.state.expanded.includes(payerCompanyId)) {
      this._fetchItems(payerCompanyId)
    }
  }

  _fetchItems = (payerCompanyId, onComplete=null) => {
    if(payerCompanyId && !this.state.loadingItemsFor.includes(payerCompanyId)) {
      this.setState({expanded: uniq([...this.state.expanded, payerCompanyId]), loadingItemsFor: uniq([...this.state.loadingItemsFor, payerCompanyId])}, () => {
        const company = currentUserCompany();
        let payer = find(this.state.invoiceItems, {companyId: payerCompanyId})
        if(!isEmpty(payer?.items)) {
          const newState = {...this.state}
          newState.loadingItemsFor = without(newState.loadingItemsFor, payerCompanyId)
          this.setState(newState, () => {
            if (typeof onComplete === 'function') {
              onComplete();
            }
          })
        } else {
          APIService.invoices()
            .appendToUrl(`freight-receivable-items/?company_id=${company.id}&payer_company_id=${payerCompanyId}&only_items=true`)
            .get()
            .then(response => {
              const newState = {...this.state}
              payer.items = response[0]?.items || []
              payer.chemicalApplicationItems = response[0]?.chemicalApplicationItems || []
              payer = {...payer, ...response[0]}
              newState.loadingItemsFor = without(newState.loadingItemsFor, payerCompanyId)
              this.setState(newState, () => {
                if (typeof onComplete === 'function') {
                  onComplete();
                }
              })
            })
            .catch(() => {
              const newState = {...this.state}
              newState.loadingItemsFor = without(newState.loadingItemsFor, payerCompanyId)
              this.setState(newState, () => {
                if (typeof onComplete === 'function') {
                  onComplete();
                }
              })
            });
        }
      })
    } else if (typeof onComplete === 'function') {
      onComplete();
    }
  }

  getReceivableInvoiceItems(payerCompanyId) {
    const { invoicesItems } = this.state;
    let allInvoiceItems = [];
    const newState = {...this.state};

    newState.allInvoiceItems = [...allInvoiceItems];
    newState.count = allInvoiceItems.length;
    newState.invoiceItemsGroupedByPayer = invoicesItems;
    this.setState(newState, () => {
      this.setHeaderAndBreadcrumbs();
      this.props.dispatch(forceStopLoader());
      if(this.state.selectedPayerCompanyIds.includes(payerCompanyId)) {
        const payer = find(this.state.invoiceItemsGroupedByPayer, {companyId: payerCompanyId})
        if(payer && payer?.items?.length)
          this.onSelectAllToggle(null, true, payer)
      }
    })
  }

  allPayerCompanyIds = () => compact(map(this.state.invoiceItemsGroupedByPayer, 'companyId'))

  onExpandAll = event => {
    event.stopPropagation()
    event.preventDefault()
    const payerCompanyIds = compact(map(orderBy(this.state.invoiceItemsGroupedByPayer, 'companyName'), 'companyId'))
    if(payerCompanyIds?.length) {
      this.props.dispatch(isLoading('LoadingExpandAll'))
      let remainingRequests = payerCompanyIds.length
      this.setState({expanded: [...payerCompanyIds]}, () => {
        if(remainingRequests === 0) {
          this.props.dispatch(forceStopLoader())
          return
        }

        const onItemComplete = () => {
          remainingRequests--
          if(remainingRequests <= 0) {
            this.props.dispatch(forceStopLoader())
          }
        }

        payerCompanyIds.forEach(id => {
          this._fetchItems(id, onItemComplete)
        })
      })
    }
  }

  onCollapseAll = () => this.setState({expanded: []})

  onSelectAllItems = event => {
    event.persist();
    const isChecked = event.target.checked;
    const newState = {...this.state};

    if (isChecked) {
        newState.selectedPayerCompanyIds = this.allPayerCompanyIds();
        const expandedItems = this.state.invoiceItemsGroupedByPayer
            .filter(payer => this.state.expanded.includes(payer.companyId))
            .reduce((allItems, payer) => [...allItems, ...(payer.items || [])], []);
        const allItems = [...this.state.selectedInvoiceItems, ...expandedItems];
        newState.selectedInvoiceItems = uniqBy(allItems, 'id');
    } else {
        newState.selectedPayerCompanyIds = [];
        newState.selectedInvoiceItems = [];
    }
    this.setState({
      selectedPayerCompanyIds: newState.selectedPayerCompanyIds,
      selectedInvoiceItems: newState.selectedInvoiceItems
    });
  }

  onSelectPayer = (event, companyId) => {
    event.persist()
    const isChecked = event.target.checked
    this.setState({selectedPayerCompanyIds: isChecked ? [...this.state.selectedPayerCompanyIds, companyId] : without(this.state.selectedPayerCompanyIds, companyId)}, () => {
      const payer = find(this.state.invoiceItemsGroupedByPayer, {companyId: companyId})
      if(payer && payer?.items?.length)
        this.onSelectAllToggle(event, isChecked, payer)
    })
  }

  render() {
    const {
      invoiceItemsGroupedByPayer, selectedInvoiceItems, openBulkActions, openDialog, selectedBulkOption,
      dueDate, recipientsDialogOpen, selectedRecipients, sellerEmployeeData, selectedPayerCompanyIds
    } = this.state
    const disableActionSelectedInvoices = (
      selectedPayerCompanyIds.length === 0 &&
      !get(selectedInvoiceItems, 'length', 0) > 0
    );
    const isDueDateOption = selectedBulkOption === 'dueDate'
    const isCreateOption = selectedBulkOption === 'create'
    const isCreateAndSendOption = selectedBulkOption === 'createSend'
    const today = moment()
    const allPayerCompanyIds = this.allPayerCompanyIds()
    const isExpandAll = Boolean(this.state.expanded?.length === this.state.invoiceItemsGroupedByPayer?.length && this.state.expanded?.length)
    const isEverythingSelected = Boolean(selectedPayerCompanyIds?.length && selectedPayerCompanyIds.length == allPayerCompanyIds.length)
    const isSomeOfEverythingSelected = Boolean(selectedPayerCompanyIds?.length && selectedPayerCompanyIds.length != allPayerCompanyIds.length)
    const { count, total} = this.getSelectedSummary()
    const invoiceItems = orderBy(invoiceItemsGroupedByPayer, obj => obj?.payerName?.toLowerCase())
    const collapseButtonLabel =  invoiceItemsGroupedByPayer?.length > 1 ? "Collapse All": "Collapse"
    const expandButtonLabel = invoiceItemsGroupedByPayer?.length > 1 ? "Expand All": "Expand"

    return (
      <Paper className="paper-table">
        <div>
          <span style={{ paddingLeft: '10px', fontWeight: 'bold', fontSize: '20px', marginTop: '8px' }}>
            Freight Receivable
            <FormControlLabel
              onClick={this.onSelectAllItems}
              sx={{marginLeft: '16px'}}
              control={<Checkbox indeterminate={isSomeOfEverythingSelected} checked={isEverythingSelected} />}
              label="Select All"
            />
            <Chip
              icon={isExpandAll ? <CollapseIcon fontSize='inherit' /> : <ExpandIcon fontSize='inherit' />}
              label={isExpandAll ? collapseButtonLabel : expandButtonLabel}
              onClick={isExpandAll ? this.onCollapseAll : this.onExpandAll}
            />
            {
              count > 0 &&
                <span style={{fontSize: '1rem', marginLeft: '20px', fontWeight: 'normal'}}>
                  Selected Items: <b style={{marginRight: '10px'}}>{count.toLocaleString()}</b> Total: <b>{`${getCountryCurrency()} ${Number(total.toFixed(2)).toLocaleString()}`}</b>
              </span>
            }
          </span>

          <CommonListingButton
            defaultHandler={() => this.customCsvEnabled(false)}
            showMenus={!isEmpty(Object.entries(this.state.filters).filter(val => get(val, '1.length', 0) !== 0))}
            optionMapper={[
              { name: 'Complete List', fx: () => this.customCsvEnabled(false) },
              { name: 'Filtered List', fx: () => this.customCsvEnabled(true) },
            ]}
            title='Download Contents of the table in a CSV'
            name='Export'
          />
          <Tooltip title='Apply filters' placement='top'>
            <Button
              value={this.state.applyFilters}
              variant="contained"
              type='button'
              onClick={() => this.handleFilters(true)}
              color='primary'
              className='add-button'
              style={{ float: 'right', marginLeft: '10px' }}
            >
              <FilterListIcon style={{ paddingRight: '5px' }} />
              FILTERS{' '}
              {+!isEmpty(Object.entries(this.state.filters).filter(val => this.filterCriteria(val[0], val[1])))
                ? `(${Object.entries(this.state.filters).filter(val => this.filterCriteria(val[0], val[1])).length})`
                : ''}
            </Button>
          </Tooltip>
          <Button
            variant="contained"
            type='button'
            color='primary'
            disabled={disableActionSelectedInvoices}
            onClick={this.toggleBulkActions}
            style={{ float: 'right', marginLeft: '10px' }}
          >
            Action Selected Invoices
          </Button>
          <CommonListingButton
            showMenus
            showDownLoadIcon={false}
            optionMapper={this.getActionsOptionMapperListItems()}
            title='Actions'
            name='Actions'
          />
          <Dialog open={this.state.csvPopup} onClose={() => this.setState({csvPopup: false})} aria-labelledby='form-dialog-title' fullWidth>
            <DialogTitleWithCloseIcon
              onClose={() => this.setState({csvPopup: false})}
              closeButtonStyle={{ marginTop: '0px' }}
              id='form-dialog-title'>
              Download Freight Invoice Receivable Data
            </DialogTitleWithCloseIcon>
            <DialogContent style={{ marginTop: '15px' }}>
              <span style={{float: 'left'}}>Select checkbox for custom csv download</span>
              <div className='col-sm-6 padding-reset' style={{marginTop: '10px'}}>
                <Checkbox
                  id={'custom-headers'}
                  checked={this.state.customColumns}
                  style={{ height: '40px' }}
                  onChange={() => this.setState({customColumns: !this.state.customColumns})}
                />
                Custom Columns
                <a onClick={() => this.editCustomHeaderOptions()} hidden={!this.state.customColumns} style={CUSTOM_HEADER_EDIT}>View & Update</a>
              </div>
              <SideDrawer
                isOpen={this.state.customHeaderOptions}
                title={this.state.customColumnTitle}
                onClose={() => this.setState({customHeaderOptions: false})}
                size="small"
              >
                <CustomHeaderOptions
                  customColumns={this.state.customColumnNames}
                  closeDrawer={() => this.setState({customHeaderOptions: false})}
                  user={this.props.currentUser}
                  csv_type="freight_invoice_receivables_csv"
                  updateColumnCount={(count) => this.updateColumnCount(count)}
                />
              </SideDrawer>
            </DialogContent>
            <DialogActions>
              <Button
                type='button'
                onClick={() => this.setState({csvPopup: false})}
                variant='outlined'>
                Cancel
              </Button>
              <Button type='button' onClick={() => this.fetchCSVData()} color='primary' variant='outlined'>
                Download
              </Button>
            </DialogActions>
          </Dialog>
          {this.state.applyFilters && (
            <SideDrawer isOpen={this.state.openSideDrawer} title='Filters' size='big' onClose={() => this.handleFilters(false)} app='filters'>
              <InvoiceFilters
                handleFilterState={this.handleFilterState}
                filters={this.state.filters}
                freightReceivableInvoice
              />
            </SideDrawer>
          )}
          <Divider light style={{ marginLeft: '-10px', marginRight: '-10px', marginTop: '20px' }} />
          {!isEmpty(invoiceItemsGroupedByPayer) &&
            <Virtuoso
              data={invoiceItems}
              style={{ height: '100vh' }}
              itemContent={(index, obj) => {
              const isExpanded = this.state.expanded.includes(obj.companyId)
              const [isSomeSelected,] = this.isSomeSelectedForPayer(obj)
                return (
                <div id={index} key={index}>
                  <div style={{ marginLeft: '-10px', marginRight: '-10px', background: '#F5F5F5', height: '50px' }}>
                    <Tooltip title={isExpanded ? 'Click to collapse' : 'Click to expand and view items'}>
                      <span style={{display: 'inline-flex', alignItems: 'center', width: 'calc(100% - 400px)', cursor: 'pointer'}} onClick={event => this.fetchItems(event, obj.companyId)}>
                        <span style={{ paddingLeft: '15px', fontSize: '20px', lineHeight: '50px' }}>{obj.payerName}</span>
                        <span style={{ paddingLeft: '15px', fontSize: '15px', lineHeight: '50px', marginTop: '1px' }}>
                        {
                          <>
                            {
                              get(obj, 'recipientDisplay') &&
                              <span style={{ paddingLeft: '15px', fontSize: '15px' }}>{get(obj, 'recipientDisplay')}</span>
                            }
                            <Tooltip title='Edit Recipients'>
                              <IconButton
                                onClick={() => this.handleEmailRecipients(obj.companyId)}
                                size="large">
                                <Create fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </>
                        }
                        </span>
                      </span>
                    </Tooltip>

                    <div style={{ float: "right", marginRight: '20px', lineHeight: '50px', maxWidth: '400px' }}>
                      <FormControlLabel
                        sx={{marginRight: '20px'}}
                        control={
                          <Badge badgeContent={obj.totalItems} color='secondary' sx={{'.MuiBadge-badge': {left: '-20px', top: '16px'}}} anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          >
                            <Checkbox
                              sx={{padding: '6px'}}
                              indeterminate={isSomeSelected}
                              checked={selectedPayerCompanyIds.includes(obj.companyId)}
                              onChange={event => this.onSelectPayer(event, obj.companyId)}
                              size='small'
                            />
                            </Badge>
                        }
                        label="Select All"
                      />
                    </div>
                  </div>

                  <div style={isExpanded ? {} : { marginTop: '10px' }}>
                  {
                    (isEmpty(obj.items) || !isExpanded) ?
                      (
                        isExpanded ? <Skeleton width='100%' height={100} /> : <div />
                      ) :
                    <InvoicePayableTable
                      items={obj.items}
                      columns={[
                        {
                          size: 'small',
                          sx:{padding: 0},
                          align: 'left',
                          header: 'All',
                          checkbox: true,
                          className: 'xxsmall',
                          onChange:(selected, isChecked) => this.updateSelectedInvoiceItem(selected, isChecked, obj),
                          func: item => this.isSelected(item, obj),
                          selectAll: true,
                          checked: selectedPayerCompanyIds?.includes(obj.companyId),
                          indeterminate: isSomeSelected,
                          onSelectAll: event => this.onSelectPayer(event, obj.companyId)
                        },
                        ...FREIGHT_RECEIVABLE_ITEM_COLUMNS
                      ]}
                      isSubscriber={obj.isSubscriber}
                    />
              }
                  </div>
                  {
                    recipientsDialogOpen &&
                    <Dialog open keepMounted fullWidth onClose={this.onDialogClose}>
                      <DialogTitleWithCloseIcon
                        onClose={this.onDialogClose}
                        closeButtonStyle={{ marginTop: '0px' }}
                        id='form-dialog-title'>
                        Update Recipients
                      </DialogTitleWithCloseIcon>
                      <DialogContent>
                        <React.Fragment>
                          <div>
                            {'Update Recipients'}
                          </div>
                          <div>
                            <EmailAutocomplete
                              options={sellerEmployeeData}
                              onChange={this.onRecipientsChange}
                              selected={selectedRecipients}
                            />
                          </div>
                          {
                            this.state.showRecipientsError &&
                            <div style={{ marginTop: '3px', color: '#FF0000', fontSize: '15px'}}>
                              You can select only 3 emails.
                            </div>
                          }
                        </React.Fragment>
                      </DialogContent>
                      <DialogActions>
                        <Button color='default' onClick={this.onDialogClose}>Cancel</Button>
                        <Button onClick={this.submitEmailRecipients}>Proceed</Button>
                      </DialogActions>
                    </Dialog>
                  }
                </div>
                )
              }}
            />
          }
        </div>
        {
          Boolean(openBulkActions) &&
          <Menu
            id="actions-menu"
            anchorEl={openBulkActions}
            open
            onClose={this.toggleBulkActions}>
            <MenuItem onClick={this.onBulkActionClick} id='create'>
              <ListItemIcon style={{minWidth: 'unset', marginRight: '5px'}}>
                <CreateIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Create</ListItemText>
            </MenuItem>
            <MenuItem onClick={this.onBulkActionClick} id='createSend'>
              <ListItemIcon style={{minWidth: 'unset', marginRight: '5px'}}>
                <SendIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Create & Send</ListItemText>
            </MenuItem>
            <MenuItem onClick={this.onBulkActionClick} id='dueDate'>
              <ListItemIcon style={{minWidth: 'unset', marginRight: '5px'}}>
                <DueDateIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Update Payment Due Date</ListItemText>
            </MenuItem>
          </Menu>
        }
        {
          openDialog &&
            <Dialog open keepMounted onClose={() => this.setState({openDialog: false})}>
              <DialogTitle>
                {OPTIONS[selectedBulkOption].title}
              </DialogTitle>
              <DialogContent>
                {
                  isDueDateOption &&
                  <div style={{marginTop: '10px'}}>
                    <CommonDatePicker
                      minDate={today.format('YYYY-MM-DD')}
                      label='Payment Due Date'
                      onChange={this.onPaymentDueDateChange}
                      value={dueDate}
                      variant='outlined'
                    />
                  </div>
                }
                {
                  isCreateOption &&
                  "Selected Invoice(s) will be created but emails will not be sent. Are you sure you want to proceed?"
                }
                {
                  isCreateAndSendOption &&
                  <React.Fragment>
                    <div>
                      {
                        `Selected Invoice(s) will be created and emails will be sent to corresponding recipients. Are you sure you want to proceed?`
                      }
                    </div>
                  </React.Fragment>
                }
              </DialogContent>
              <DialogActions>
                <Button color='default' onClick={this.onBulkInvoiceClose}>Cancel</Button>
                <Button disabled={isDueDateOption && !dueDate} onClick={this.onSubmit}>Proceed</Button>
              </DialogActions>
            </Dialog>
        }
      </Paper>
    )
  }
}

const mapStateToProps = state => {
  return {
    breadcrumbs: state.main.breadcrumbs,
    currentUser: state.main.user.user,
  };
};

export default connect(mapStateToProps)(FreightInvoiceReceivable);
