import React from 'react'
import moment from 'moment'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import SubscriberIcon from '@mui/icons-material/VerifiedUser';
import NotSubscriberIcon from '@mui/icons-material/RemoveModerator';
import RegisteredIcon from '@mui/icons-material/PrivacyTip';
import NotRegisteredIcon from '@mui/icons-material/GppBad';
import CheckIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { getCurrentCountry } from '../../common/utils'

const CompanyOverview = ({ overview, sx }) => {
  const getHighlight = id => overview?.highlights?.find(highlight => highlight.id === id)
  const abn = getHighlight('abn')
  const registered = getHighlight('registered')
  const subscriber = getHighlight('subscriber')
  const ngr = getHighlight('ngr')
  const xero = getHighlight('xero')
  const octopusbot = getHighlight('octopusbot')
  const showRegistered = Boolean(subscriber?.id && !subscriber?.value)
  const hasPrivate = Boolean(overview?.highlights?.find(highlight => highlight.public === false))
  const country = getCurrentCountry()

  return (
    <Card sx={{borderRadius: 0, boxShadow: 'none', ...sx}}>
    <CardContent sx={{padding: '0 16px', '&:last-child': {paddingBottom: '0'}}}>
      <List sx={{ width: '100%', p: 0, display: 'flex', flexDirection: 'row', '.MuiListItemText-secondary': {fontSize: '10px'}, '.MuiListItem-root': {minHeight: '55px'} }} dense>
          <ListItem sx={{p: 0}}>
            {
              abn?.id ?
                <ListItemIcon sx={{minWidth: 'auto', marginRight: '12px'}}>
                  {
                    abn?.value === 'active' ? <CheckIcon color='primary' />: <CancelIcon color='error' />
                  }
                </ListItemIcon> :
              <Skeleton variant="circular" width={20} height={20} sx={{marginRight: '12px'}} />
            }
            <ListItemText primary={country?.config?.abn?.label} secondary={(abn?.extras?.lastUpdatedAt && abn.value && overview?.abn != '-') ? moment(abn?.extras?.lastUpdatedAt).format('ll') : abn?.value} />
          </ListItem>
          <ListItem sx={{p: 0}}>
            {
              subscriber?.id ?
                <ListItemIcon sx={{minWidth: 'auto', marginRight: '12px'}}>
                  {
                    subscriber?.value ?
                      <SubscriberIcon color='primary' /> :
                      <NotSubscriberIcon color='error' />
                  }
                </ListItemIcon> :
              <Skeleton variant="circular" width={20} height={20} sx={{marginRight: '12px'}} />
            }
            <ListItemText primary="Subscriber" secondary={subscriber?.extras?.lastUpdatedAt ? moment(subscriber?.extras?.lastUpdatedAt).format('ll') : undefined} />
          </ListItem>
          {
            showRegistered &&
              <ListItem sx={{padding: '4px 0'}}>
                {
                  registered?.id ?
                    <ListItemIcon sx={{minWidth: 'auto', marginRight: '12px'}}>
                      {
                        registered?.value ?
                          <RegisteredIcon color='warning' /> :
                          <NotRegisteredIcon color='error' />
                      }
                    </ListItemIcon> :
                  <Skeleton variant="circular" width={20} height={20} sx={{marginRight: '12px'}} />
                }
                <ListItemText primary="Registered" secondary={registered?.extras?.lastUpdatedAt ? moment(registered?.extras?.lastUpdatedAt).format('ll') : undefined} />
              </ListItem>
          }
          {
            hasPrivate &&
              <React.Fragment>
                <ListItem sx={{p: 0}}>
                  {
                    ngr?.id ?
                      <ListItemIcon sx={{minWidth: 'auto', marginRight: '12px'}}>
                        {
                          ngr?.value ?
                            <LinkIcon color='primary' /> :
                            <LinkOffIcon color='error' />
                        }
                      </ListItemIcon> :
                    <Skeleton variant="circular" width={20} height={20} sx={{marginRight: '12px'}} />
                  }
                  <ListItemText primary="NGR Portal" secondary={ngr?.extras?.lastUpdatedAt ? moment(ngr?.extras?.lastUpdatedAt).format('ll') : undefined} />
                </ListItem>
                <ListItem sx={{p: 0}}>
                  {
                    xero?.id ?
                      <ListItemIcon sx={{minWidth: 'auto', marginRight: '12px'}}>
                        {
                          xero?.value ?
                            <LinkIcon color='primary' /> :
                            <LinkOffIcon color='error' />
                        }
                      </ListItemIcon> :
                    <Skeleton variant="circular" width={20} height={20} sx={{marginRight: '12px'}} />
                  }
                  <ListItemText primary="Xero" secondary={xero?.extras?.lastUpdatedAt ? moment(xero?.extras?.lastUpdatedAt).format('ll') : undefined} />
                </ListItem>
                <ListItem sx={{p: 0}}>
                  {
                    octopusbot?.id ?
                      <ListItemIcon sx={{minWidth: 'auto', marginRight: '12px'}}>
                        {
                          octopusbot?.value ?
                            <LinkIcon color='primary' /> :
                            <LinkOffIcon color='error' />
                        }
                      </ListItemIcon> :
                    <Skeleton variant="circular" width={20} height={20} sx={{marginRight: '12px'}} />
                  }
                  <ListItemText primary="OctopusBot" secondary={octopusbot?.extras?.lastUpdatedAt ? moment(octopusbot?.extras?.lastUpdatedAt).format('ll') : undefined} />
                </ListItem>
              </React.Fragment>
          }
        </List>
      </CardContent>
    </Card>
  )
}

export default CompanyOverview
