import React, { Component} from 'react';
import { connect } from 'react-redux';
import alertifyjs from 'alertifyjs';
import '@babel/polyfill';
import moment from 'moment';
import CommonTextField from '../common/CommonTextField';
import CommonDatePicker from '../common/CommonDatePicker';
import CommonButton from '../common/CommonButton';
import AddButton from '../common/AddButton';
import SideDrawer from '../common/SideDrawer';
import CustomEmailDialog from '../common/CustomEmailDialog';
import ArrowForward from '@mui/icons-material/ArrowForward';
import ContentRemove from '@mui/icons-material/Remove';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Create from '@mui/icons-material/Create';
import {
  mapValues, forEach, uniqBy, map, isNaN, partialRight, pick, find, cloneDeep, set, some, isEmpty,
  isNumber, filter, reject, camelCase, upperFirst, get, sortBy, includes, has, without, isEqual,
  every, round, times, isArray, startCase, keys, isObject, size, values, compact, uniq, sumBy
} from 'lodash';
import { currentUserCompany, getCountryFormats, getCountryConfig, getCountryLabel, isVisibleInCountry, toPhoneFormat, generateUpdatedIdentifier, getEstimatedPaymentDueDateForDatePaymentTerm } from '../../common/utils';
import CommodityAutoComplete from '../common/autocomplete/CommodityAutoComplete';
import VarietyAutoComplete from '../common/autocomplete/VarietyAutoComplete';
import GradeAutoComplete from '../common/autocomplete/GradeAutoComplete';
import CommonSelect from '../common/select/CommonSelect';
import SeasonSelect from '../common/select/SeasonSelect';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import SiteAsyncAutocomplete from '../common/autocomplete/SiteAsyncAutocomplete';
import { updateCompany } from '../../actions/companies/index';
import NumberField from '../common/NumberField';
import {
  canCreateOrUpdate,
  getPoolGrades,
  isShallowEqual,
  canNotEdit,
  getFreshdeskURL,
  getLabelForEmployeeForm,
  getAutoSelectFocusField,
  extractDigits,
  generateIdentifier,
  isCurrentUserBelongsToCompany,
  replaceUnit,
  getCountrySystemCompanyId,
  getCountryCurrency
} from '../../common/utils';
import {
  BROKER_NOTE_PREFIX,
  SALES_CONFIRMATION_PREFIX,
  CONTRACT_PREFIX,
  DATE_DB_FORMAT,
  COMMODITIES,
  GRADES,
  CONTRACT_TYPES,
  PAYMENT_SCALES,
  PRICE_POINTS,
  BHC_TYPE_ID,
  REASONS_TO_REPRESENT_FOR_SELLER,
  REASONS_TO_REPRESENT,
  TOLERANCE_5_OR_20_PERCENT,
  TOLERANCE_NIL,
  MT_UNIT,
  DELIVERY_MANDATORY_PRICE_POINTS, PICKUP_MANDATORY_PRICE_POINTS,
  PRICE_POINTS_TRACK_AND_DELIVERED_MARKET_ZONE,
  OTHERS_CANNOT_CREATE,
  OTHERS_CAN_CREATE_BUT_REQUIRES_ACCEPTANCE,
  PRIMARY_COLOR_GREEN,
  SEASON_NA,
  FERTILISER_IDS
} from '../../common/constants';
import CurrencyField from '../common/CurrencyField'
import { create, getFixtures, isValidUserDocumentType, raiseUpdateContractRequest } from '../../actions/companies/contracts';
import { fetchMarketZones, isLoading, setHeaderText, setBreadcrumbs, forceStopLoader } from '../../actions/main';
import { getTracks } from '../../actions/main/index';
import {
  clickAddGeneralConditionButton,
  clickAddSpecialConditionButton,
  getGeneralConditions,
  getSpecialConditions,
  receiveGeneralConditions,
  receiveSpecialConditions,
} from '../../actions/companies/conditions';
import {
  DELIVERY_ONUS,
  FREQUENCIES,
  INVOICINGS,
  PAYABLE_BY,
  BROKERAGE_FEE_TYPES,
  CHARGED_AT,
  VOLUME_BROKERAGE_FEE_TYPES,
  NEW_INVOICINGS,
} from './fixtures';
import { required, minValue, selected, VALUE_NOT_SELECTED, valueAbove } from '../../common/validators';
import { canCreateEmployee, getCompanyEmployees } from '../../actions/api/employees';
import { clickAddEmployeeButton, receiveEmployees } from '../../actions/company-settings/employees';
import SpreadPopup from './SpreadPopup';
import BlendedGrades from './BlendedGrades';
import SpecPriceAdjustments from './SpecPriceAdjustments';
import ContractPreview from './ContractPreview';
import { nextEndDayFrom, getWeekDays, getDaysToAdd, isCurrentUserBroker, getBrokerageDisplayValue } from '../../common/utils';
import '../../common/forms.scss';
import APIService from '../../services/APIService';
import CreateEmployee from '../../containers/CreateEmployee';
import { getCompanyCompaniesMinimal } from '../../actions/api/companies';
import CreateGeneralCondition from '../../containers/CreateGeneralCondition';
import CreateSpecialCondition from '../../containers/CreateSpecialCondition';
import CreateCompanyNgr from '../../containers/CreateCompanyNgr';
import { clickAddCompanyNgrButton, isCurrentUserRepresentingAnyParty } from '../../actions/companies/company-ngrs';
import { clickAddNgrButton } from '../../actions/companies/ngrs';
import { AddCircleIconWithTooltip } from '../common/icons/AddCircleIconWithTooltip';
import { canCreateNgr } from '../../actions/api/ngrs';
import Notes from '../common/Notes';
import withStyles from '@mui/styles/withStyles';
import QuickContract from '../common/TemplateSearch';
import CreateCompanySite from '../../containers/CreateCompanySite';
import CreateCompany from '../../containers/CreateCompany';
import UpdateLocation from '../locations/UpdateLocation';
import PinDropIcon from '@mui/icons-material/PinDrop';
import ChemicalApplications from './ChemicalApplications'

const SITE_REMOVAL_TITLE = 'Site is not empty!';
const SITE_REMOVAL_MESSAGE = 'Are you sure you want to remove this Site?';

const DEFAULT_SIDE_DRAWERS_STATE = {
  sellerSideDrawerIsOpen: false,
  buyerSideDrawerIsOpen: false,
  brokerSideDrawerIsOpen: false,
  ngrSideDrawerIsOpen: false,
  generalConditionSideDrawerIsOpen: false,
  specialConditionSideDrawerIsOpen: false,
  consignorSideDrawer: false,
  consigneeSideDrawer: false
};
const styles = () => ({
  inputRoot: {
    color: 'black !important'
  },
});

class ContractForm extends Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.hash.split('?')[1]);
    this.isDuplicatingContract = Boolean(queryParams.get('copyFrom'))
    this.contractId = this.props.match.params.contract_id;
    this.isEditingContract = Boolean(this.contractId);
    this.generatedIdentifier = generateIdentifier('broker_note');
    this.countryConfig = getCountryConfig()
    this.tonnageLabel = getCountryLabel('tonnage')
    const isSpotContract = get(window.location.hash.split('?'), '1') === 'contractType=spot'
    this.isDefaultDocumentTypeSelected = false
    this.brokerageFields = {
      type: {
        value: null,
        validators: [required()],
        errors: [],
      },
      chargedOn: {
        value: 'Pre-Delivery',
        validators: [required()],
        errors: [],
      },
      feeType: {
        value: null,
        validators: [required()],
        errors: [],
      },
      rate: {
        value: '',
        validators: [required()],
        errors: [],
      },
      totalFee: {
        value: '',
        validators: [required()],
        errors: [],
      },
    };
    this.sellerFields = {
      companyId: {
        value: null,
        validators: [required(), selected()],
        errors: [],
      },
      contactId: {
        value: null,
        validators: [required(), selected()],
        errors: [],
      },
      ngrId: {
        value: null,
        validators: [selected()],
        errors: [],
      },
    };
    this.areaFields = {
      area: {
        value: null,
        validators: [],
        errors: [],
      },
      estimatedYield: {
        value: null,
        validators: [],
        errors: [],
      },
      priceBy: {
        value: null,
        validators: [],
        errors: [],
      },
    };
    this.buyerFields = {
      companyId: {
        value: null,
        validators: [required(), selected()],
        errors: [],
      },
      contactId: {
        value: null,
        validators: [required(), selected()],
        errors: [],
      },
      ngrId: {
        value: null,
        validators: [selected()],
        errors: [],
      },
    };
    this.consignorFields = {
      handlerId: {
        value: null,
        validators: [selected()],
        errors: [],
      },
      ld: {
        value: null,
        validators: [],
        errors: [],
      },
      isBHC: false,
      item: undefined,
      position: 1,
      ld_db: 0
    };

    this.consigneeFields = {
      handlerId: {
        value: null,
        validators: isSpotContract ? [required(), selected()] : [selected()],
        errors: [],
      },
      ld: {
        value: null,
        validators: [],
        errors: [],
      },
      isBHC: false,
      item: undefined,
      position: 1,
      ld_db: 0
    };
    this.fields = {
      payableBy: {
        value: 'Seller',
        validators: [required()],
        errors: [],
      },
      brokerages: [set(cloneDeep(this.brokerageFields), 'type.value', 'Seller')],
      track: {
        value: null,
        validators: [selected()],
        errors: [],
      },
      generalConditions: {
        value: '',
        validators: [],
        errors: [],
      },
      specialConditions: {
        value: '',
        validators: [],
        errors: [],
      },
      identifier: {
        value: '',
        label: 'Identifier',
        errors: [],
        validators: [required()],
      },
      documentTypeId: {
        value: null,
        validators: [required()],
        errors: [],
      },
      lotNumber: {
        value: null,
        validators: [],
        errors: [],
      },
      commodityId: {
        value: null,
        validators: [required()],
        errors: [],
      },
      gradeId: {
        value: null,
        validators: [required(), selected()],
        errors: [],
      },
      varietyId: {
        value: null,
        validators: [],
        errors: [],
      },
      typeId: {
        value: isSpotContract ? CONTRACT_TYPES.SPOT : null,
        validators: [required()],
        errors: [],
      },
      season: {
        value: null,
        validators: [required()],
        errors: [],
      },
      pricePointId: {
        value: isSpotContract ? PRICE_POINTS.DELIVERED_SITE : null,
        validators: [required()],
        errors: [],
      },
      blendingFee: {
        value: null,
        validators: [],
        errors: [],
      },
      chemicalApplications: {
        value: [],
        validators: [],
        errors: [],
      },
      tonnage: {
        value: null,
        validators: [required(), minValue(0.001)],
        errors: [],
      },
      quantity: {
        value: null,
        validators: [],
        errors: [],
      },
      price: {
        value: null,
        validators: [required(), minValue(0.001)],
        errors: [],
      },
      deliveryOnus: {
        value: isSpotContract ? find(DELIVERY_ONUS, {id: 'Seller'}).id : '',
        validators: [required()],
        errors: [],
      },
      paymentScaleId: {
        value: isSpotContract ? PAYMENT_SCALES.FLAT : null,
        validators: [required()],
        errors: [],
      },
      paymentTermId: {
        value: null,
        validators: [required(), selected()],
        errors: [],
      },
      toleranceId: {
        value: isSpotContract ? TOLERANCE_NIL : TOLERANCE_5_OR_20_PERCENT,
        validators: [required()],
        errors: [],
      },
      conveyanceId: {
        value: 1,
        validators: [],
        errors: [],
      },
      inspectionId: {
        value: 1,
        validators: [required()],
        errors: [],
      },
      weightId: {
        value: 1,
        validators: [required()],
        errors: [],
      },
      packagingId: {
        value: 1,
        validators: [required()],
        errors: [],
      },
      marketZoneId: {
        value: null,
        validators: [],
        errors: [],
        label: 'Market Zone (Optional)',
      },
      regionId: {
        value: null,
        validators: [selected()],
        errors: [],
      },
      deliveryStartDate: {
        value: moment().format(DATE_DB_FORMAT),
        validators: [required()],
        errors: [],
      },
      deliveryEndDate: {
        value: isSpotContract ? moment().format(DATE_DB_FORMAT) : null,
        validators: [required()],
        errors: [],
      },
      carryRate: {
        value: null,
        validators: [valueAbove(0)],
        errors: [],
      },
      carryFrequency: {
        value: 'Monthly',
        validators: [],
        errors: [],
      },
      carryStartDate: {
        value: null,
        validators: [],
        errors: [],
      },
      carryEndDate: {
        value: null,
        validators: [],
        errors: [],
      },
      carryMax: {
        value: null,
        validators: [],
        errors: [],
      },
      carryCurrent: {
        value: null,
        validators: [],
        errors: [],
      },
      administration: {
        invoicing: {
          value: isSpotContract ? 'Buyer RCTI' : 'Seller to Invoice Buyer',
          validators: [required()],
          errors: [],
        },
        brokeredById: {
          value: undefined,
          validators: [required(), selected()],
          errors: [],
        },
        brokerContactId: {
          value: undefined,
          validators: [required(), selected()],
          errors: [],
        },
        transferOwnershipToId: {
          value: null,
          validators: [selected()],
          errors: [],
        },
      },
      crmNotes: {
        attachments: [],
        description: '',
        companyId: this.props.companyId,
        errors: []
      },
      consignors: [cloneDeep(this.consignorFields)],
      consignees: [cloneDeep(this.consigneeFields)],
      seller: cloneDeep(this.sellerFields),
      buyer: cloneDeep(this.buyerFields),
      area: cloneDeep(this.areaFields),
      spread: {
        value: null,
        validators: [],
        errors: [],
      },
      contractIdentifier: {
        value: null,
        validators: [],
        errors: [],
      },
      contractDate: {
        value: moment().format(DATE_DB_FORMAT),
        validators: [required()],
        errors: [],
      }
    };
    this.state = {
      isTrackVisible: isVisibleInCountry('track'),
      isSpotLocked: isSpotContract,
      countryFormats: getCountryFormats(),
      currency: getCountryCurrency(),
      consignee1InputText: '',
      consignee2InputText: '',
      consignee3InputText: '',
      consignor1InputText: '',
      consignor2InputText: '',
      consignor3InputText: '',
      isFetchingSelectedContract: false,
      isDefaultGeneralConditionApplied: false,
      isDefaultSpecialConditionApplied: false,
      showCustomEmailDialog: false,
      representingBuyer: false,
      representingSeller: false,
      base64Logo: null,
      canSetDefaultBrokeragesForBuyer: true,
      canSetDefaultBrokeragesForSeller: true,
      applyRule: true,
      preview: false,
      sellerSideDrawerIsOpen: false,
      buyerSideDrawerIsOpen: false,
      brokerSideDrawerIsOpen: false,
      ngrSideDrawerIsOpen: false,
      generalConditionSideDrawerIsOpen: false,
      specialConditionSideDrawerIsOpen: false,
      sustainableCommodity: false,
      isCashPriced: false,
      openConsignorFormForFarm: undefined,
      openConsigneeFormForFarm: undefined,
      templateNameSelector: {
        value: null,
        validators: [],
        errors: [],
      },
      sellerEstablishmentDetails: {
        company: undefined,
        value: 'seller'
      },
      buyerEstablishmentDetails: {
        company: undefined,
        value: 'buyer'
      },
      templateDialog: false,
      templateName: {
        value: '',
        validators: [required()],
        errors: [],
      },
      currentUser: this.props.currentUser,
      selectedCompanyId: this.props.match.params.company_id,
      disableSeller: false,
      sellerParties: [],
      buyerParties: [],
      consignors: [],
      consignees: [],
      sellerContacts: [],
      buyerContacts: [],
      sellerNgrs: [],
      buyerNgrs: [],
      fetchSellerParties: false,
      fetchBuyerParties: false,
      documentTypes: [],
      types: [],
      paymentTerms: [],
      paymentScales: [],
      tolerances: [],
      checkpoints: [],
      packagings: [],
      conveyances: [],
      pricePoints: [],
      regions: [],
      marketZones: [],
      marketZonesWithRegions: [],
      tracks: [],
      consignorsSites: [],
      consigneesSites: [[]],
      FarmFields: [],
      homeStorages: [],
      systemStorages: [],
      companySites: [],
      brokers: [],
      brokerCompanies: [],
      brokerEmployees: [],
      assigners: [],
      generalConditionsAll: [],
      specialConditionsAll: [],
      selectedGeneralConditionText: '',
      selectedSpecialConditionText: '',
      generalConditionsSelector: null,
      specialConditionsSelector: null,
      fields: cloneDeep(this.fields),
      selectedConsignorSite: undefined,
      selectedConsignee: undefined,
      selectedConsignor: undefined,
      selectedSeller: undefined,
      selectedBuyer: undefined,
      resetAllFieldValues: false,
      clearBuyerFeeType: false,
      clearSellerFeeType: false,
      ngrEntity: undefined,
      openCompanySideDrawer: false,
      contractParty: '',
      useSinglePrice: true,
      isUniqueIdentifierErrorExists: false,
    };
    this.selectedContract = undefined;
    this.isAllocatedContract = false;
    this.setDefaultValues = true;
    this.applyingTemplate = false;
    this.prefillBrokerById = true;
    this.showTypeIdFieldError = true;
    this.fetchBrokerEmployees = true;
    this.shouldFetchDistance = true;
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTemplateDialogClose = this.handleTemplateDialogClose.bind(this);
    this.saveTemplate = this.saveTemplate.bind(this);
    this.handleTemplateNameChange = this.handleTemplateNameChange.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleSelectFieldChange = this.handleSelectFieldChange.bind(this);
    this.handleSellerFieldChange = this.handleSellerFieldChange.bind(this);
    this.handleSellerCompanyIdChange = this.handleSellerCompanyIdChange.bind(this);
    this.handleBuyerCompanyIdChange = this.handleBuyerCompanyIdChange.bind(this);
    this.handleBuyerFieldChange = this.handleBuyerFieldChange.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
    this.focusOnFirstErrorField = this.focusOnFirstErrorField.bind(this);
    this.resetAllFieldsValues = this.resetAllFieldsValues.bind(this);
    this.handleVarietyChange = this.handleVarietyChange.bind(this);
    this.handleGradeChange = this.handleGradeChange.bind(this);
    this.handleMarketZoneChange = this.handleMarketZoneChange.bind(this);
    this.handleTrackChange = this.handleTrackChange.bind(this);
    this.handleInvoiceChange = this.handleInvoiceChange.bind(this);
    this.handleAdministratorFieldChange = this.handleAdministratorFieldChange.bind(this);
    this.handleDeliveryDateChange = this.handleDeliveryDateChange.bind(this);
    this.handlePricePointChange = this.handlePricePointChange.bind(this);
    this.handleSpreadGradesChange = this.handleSpreadGradesChange.bind(this);
    this.handleConditionSelector = this.handleConditionSelector.bind(this);
    this.appendGeneralConditions = this.appendGeneralConditions.bind(this);
    this.appendSpecialConditions = this.appendSpecialConditions.bind(this);
    this.handlePreviewClick = this.handlePreviewClick.bind(this);
    this.handlePreviewClose = this.handlePreviewClose.bind(this);
    this.handlePayableBy = this.handlePayableBy.bind(this);
    this.handleSellerChargedOn = this.handleSellerChargedOn.bind(this);
    this.handleBuyerChargedOn = this.handleBuyerChargedOn.bind(this);
    this.handleSellerBrokerageFeeType = this.handleSellerBrokerageFeeType.bind(this);
    this.handleBuyerBrokerageFeeType = this.handleBuyerBrokerageFeeType.bind(this);
    this.handleBuyerBrokerageField = this.handleBuyerBrokerageField.bind(this);
    this.handleSellerBrokerageField = this.handleSellerBrokerageField.bind(this);
    this.handleDocumentTypeChange = this.handleDocumentTypeChange.bind(this);
    this.commodityFieldChange = this.commodityFieldChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleTonnageFieldChange = this.handleTonnageFieldChange.bind(this);
    this.handlePriceFieldChange = this.handlePriceFieldChange.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.getPreviewFields = this.getPreviewFields.bind(this);
    this.getCompanyById = this.getCompanyById.bind(this);
    this.getCommodityDetails = this.getCommodityDetails.bind(this);
    this.handleDeliveryEndDateChange = this.handleDeliveryEndDateChange.bind(this);
    this.checkDeliveryEndDate = this.checkDeliveryEndDate.bind(this);
    this.handleDeliveryStartDateChange = this.handleDeliveryStartDateChange.bind(this);
    this.handleCarryRateFieldChange = this.handleCarryRateFieldChange.bind(this);
    this.handleTemplateNameBlur = this.handleTemplateNameBlur.bind(this);
    this.handleIdentifierChange = this.handleIdentifierChange.bind(this);
    this.openSellerSideDrawer = this.openSellerSideDrawer.bind(this);
    this.closeSellerSideDrawer = this.closeSellerSideDrawer.bind(this);
    this.openBuyerSideDrawer = this.openBuyerSideDrawer.bind(this);
    this.openBrokerSideDrawer = this.openBrokerSideDrawer.bind(this);
    this.closeBuyerSideDrawer = this.closeBuyerSideDrawer.bind(this);
    this.closeBrokerSideDrawer = this.closeBrokerSideDrawer.bind(this);
    this.getContacts = this.getContacts.bind(this);
    this.setBuyerDefaultBrokerage = this.setBuyerDefaultBrokerage.bind(this);
    this.setSellerDefaultBrokerage = this.setSellerDefaultBrokerage.bind(this);
    this.getBrokerCompanyEmployees = this.getBrokerCompanyEmployees.bind(this);
    this.openGeneralConditionSideDrawer = this.openGeneralConditionSideDrawer.bind(this);
    this.closeSpecialConditionSideDrawer = this.closeSpecialConditionSideDrawer.bind(this);
    this.closeGeneralConditionSideDrawer = this.closeGeneralConditionSideDrawer.bind(this);
    this.openSpecialConditionSideDrawer = this.openSpecialConditionSideDrawer.bind(this);
    this.addConsigneesConsignorsFields = this.addConsigneesConsignorsFields.bind(this);
    this.setDistance = this.setDistance.bind(this);
    this.setDistanceAndTimeState = this.setDistanceAndTimeState.bind(this);
    this.resetDistanceAndTimeState = this.resetDistanceAndTimeState.bind(this);
    this.fetchDistance = this.fetchDistance.bind(this);
    this.closeCustomEmailDialog = this.closeCustomEmailDialog.bind(this);
    this.openNgrSideDrawer = this.openNgrSideDrawer.bind(this);
    this.closeNgrSideDrawer = this.closeNgrSideDrawer.bind(this);
    this.openSideDrawer = this.openSideDrawer.bind(this);
    this.setDefaultGeneralCondition = this.setDefaultGeneralCondition.bind(this);
    this.setDefaultSpecialCondition = this.setDefaultSpecialCondition.bind(this);
    this.sellerSideDrawer = this.sellerSideDrawer.bind(this);
    this.buyerSideDrawer = this.buyerSideDrawer.bind(this);
    this.isBrokerFieldRequired = this.isBrokerFieldRequired.bind(this);
    this.updateBrokerContactValidators = this.updateBrokerContactValidators.bind(this);
    this.updateAdminValidators = this.updateAdminValidators.bind(this);
    this.handleQuantityFieldChange = this.handleQuantityFieldChange.bind(this);
    this.getTemplatesService = this.getTemplatesService.bind(this);
    this.getSiteLDByTrack = this.getSiteLDByTrack.bind(this);
    this.handleLdBlur = this.handleLdBlur.bind(this);
    this.handleContractDateChange = this.handleContractDateChange.bind(this);
    this.closeCompanySideDraw = this.closeCompanySideDraw.bind(this);
    this.openCompanySideDrawer = this.openCompanySideDrawer.bind(this);
    this.addNewCompany = this.addNewCompany.bind(this);
    this.handleNgrCreation = this.handleNgrCreation.bind(this);
    this.handleSustainableCommodity = this.handleSustainableCommodity.bind(this);
    this.getDisabledPartiesForEmail = this.getDisabledPartiesForEmail.bind(this);

    this.fieldsOrder = [
      "templateNameSelector", "identifier", "contractIdentifier", "contractDate","documentTypeId", "commodityId",
      "seller.companyId", "seller.contactId", "seller.ngrId", "buyer.companyId",
      "buyer.contactId", "buyer.ngrId", "typeId", 'quantity', "tonnage", "varietyId", "season",
      "area", "estimatedYield", "priceBy", "gradeId", "currency", "price", "pricePointId", "track",
      "spread", "consignors[0].handlerId", "consignees[0].handlerId",
      "deliveryOnus", "paymentScaleId", "paymentTermId", "toleranceId", "conveyanceId",
      "inspectionId", "weightId", "packagingId", "marketZoneId", "regionId", "deliveryStartDate",
      "deliveryEndDate", "carryFrequency", "carryStartDate", "carryEndDate",
      "generalConditionsSelector", "specialConditionsSelector", "payableBy", "chargedOn",
      "brokerages.Seller.feeType", "brokerages.Buyer.feeType", "brokerages.Seller.rate", "brokerages.Buyer.rate",
      "brokerages.Seller.totalFee", "brokerages.Buyer.totalFee", "invoicing", "attachment", "administration.brokeredById",
      "administration.brokerContactId",
    ];
    this.fieldRef = {};

    this.fieldsOrder.forEach(e => (this.fieldRef[e] = React.createRef()));
  }

  canRepresent() {
    const { currentUser } = this.props;
    return get(currentUser, 'company.canRepresent', false);
  }

  isCommoditySustainable() {
    if(this.state.fields.commodityId.value) {
      const selectedCommodity = find(this.props.commodities, { id: this.state.fields.commodityId.value });
      if(selectedCommodity)
          return get(selectedCommodity, 'sustainable');
    }
  }

  closeCustomEmailDialog(communicationData, justClose) {
    if(justClose)
      this.setState({showCustomEmailDialog: false});
    else if (this.state.showCustomEmailDialog) {
      const data = this.submittableData || this.editData;
      if (communicationData) {
        data['communication'] = communicationData;
      }
      this.setState({ showCustomEmailDialog: false }, () => {
        const { dispatch } = this.props;
        this.handlePreviewClose();
        if (has(data, 'identifier')) data.identifier = data.identifier.toUpperCase();
        if (this.isEditingContract) {
          if (
            (!this.isRepresentingBothParties() &&
             get(this.selectedContract, 'status') === 'planned' &&
             (this.isConveyanceChanged() || this.isDeliveryOnusChanged())) ||
            this.isTitleTransferPermissionChanged()
          ) {
            alertifyjs.confirm(
              'Warning',
              'All the movements and orders created from this contract will be marked Void. Do you want to proceed?',
              () => {
                this.setPrice(data);
                dispatch(raiseUpdateContractRequest(get(this.selectedContract, 'id'), data));
              },
              () => {},
            );
          } else {
            this.setPrice(data);
            dispatch(isLoading('alertify'));
            dispatch(raiseUpdateContractRequest(get(this.selectedContract, 'id'), data));
          }
        } else {
          this.setPrice(data);
          this.setRepresentedByCurrentUser(data);
          this.isSubmittingNewContract = true;
          this.props.dispatch(isLoading('unknownComponent'));
          dispatch(create(data));
        }
      });
    }
  }

  setPrice(data) {
    if (has(data, 'price') && isNaN(parseFloat(data.price))) {
      data.price = null;
    }
  }

  isDeliveryOnusChanged() {
    return get(this.selectedContract, 'deliveryOnus') !== this.state.fields.deliveryOnus.value;
  }

  isConveyanceChanged() {
    let conveyance = find(this.state.conveyances, { id: this.state.fields.conveyanceId.value });
    let prevConveyance = find(this.state.conveyances, { id: get(this.selectedContract, 'conveyanceId') });
    return (
      ((prevConveyance && this.state.representingBuyer && get(prevConveyance, 'name') === "Buyer's Call") ||
       (this.state.representingSeller && get(prevConveyance, 'name') === "Seller's Option")) &&
      get(this.selectedContract, 'conveyanceId') !== this.state.fields.conveyanceId.value &&
      !includes(['even spread', 'refer to special conditions'], conveyance.name.toLowerCase())
    );
  }

  isTitleTransferPermissionChanged() {
    let consignor = find(this.state.consignors, { id: this.state.fields.consignors[0].handlerId.value });
    return (
      get(this.selectedContract, 'status') === 'planned' &&
      (this.isTrackChanged() ||
       (!this.state.fields.consignees[0].isBHC &&
        includes(
          DELIVERY_MANDATORY_PRICE_POINTS,
          this.state.fields.pricePointId.value,
        )) ||
       (get(consignor, 'typeId') !== BHC_TYPE_ID && this.state.fields.pricePointId.value === PRICE_POINTS.EX_FARM_STORE))
    );
  }

  isTrackChanged() {
    return this.state.fields.pricePointId === PRICE_POINTS.TRACK && this.selectedContract.track !== this.state.fields.track.value;
  }

  openCustomEmailDialog() {
    this.setState({ showCustomEmailDialog: true });
  }

  getEmailSubject() {
    let representingCompanyText = '';
    const SPACE = ' ';
    const AC = SPACE + 'a/c' + SPACE;
    const AND = '&';
    const HASHTAG = '#';
    const identifier = get(this.state.fields, 'identifier.value', '').toUpperCase();
    const amendingText = this.isEditingContract ? '[Amendment] ' : '';
    const selectedDocumentType = this.isEditingContract
                               ? get(this.selectedContract, 'documentType')
                               : find(this.props.documentTypes, { id: this.state.fields.documentTypeId.value });
    if (this.state.representingSeller) {
      representingCompanyText = AC + get(this.state, 'selectedSeller.name');
    }
    if (this.state.representingBuyer) {
      if (isEmpty(representingCompanyText)) {
        representingCompanyText = AC + get(this.state, 'selectedBuyer.name');
      } else {
        representingCompanyText += SPACE + AND + SPACE + get(this.state, 'selectedBuyer.name');
      }
    }

    if (this.canRepresent() || isCurrentUserBroker()) {
      return (
        amendingText +
        this.props.currentUser.company.name +
        SPACE +
        get(selectedDocumentType, 'displayName') +
        representingCompanyText +
        SPACE +
        HASHTAG +
        identifier
      );
    } else {
      return amendingText + this.props.currentUser.company.name + SPACE + get(selectedDocumentType, 'displayName') + SPACE + HASHTAG + identifier;
    }
  }

  isCurrentUserSellerOrBuyer() {
    const selectedSellerCompanyId = get(this.state.selectedSeller, 'id');
    const selectedBuyerCompanyId = get(this.state.selectedBuyer, 'id');

    return isCurrentUserBroker() && includes([selectedSellerCompanyId, selectedBuyerCompanyId], this.props.currentUser.companyId);
  }

  getFooterNote() {
    return this.isEditingContract
         ? 'Amendments and updated Contract PDF will be automatically sent as part of the email'
         : 'Contract PDF will be automatically sent as part of the email';
  }

  componentDidMount() {
    this.getBase64Logo();
    const { dispatch, currentUser } = this.props;
    dispatch(isLoading('notExistingComponent'));
    if (!this.isEditingContract) {
      const breadcrumbs = [{ text: 'Contracts', route: '/contracts' }, { text: 'New' }];
      dispatch(setBreadcrumbs(breadcrumbs));
      dispatch(setHeaderText('Create Contract'));
      const newState = { ...this.state };
      newState.fields.identifier.value = this.generatedIdentifier;

      if (!isCurrentUserBroker()) {
        newState.fields.payableBy.value = 'Nil';
        newState.fields.administration.invoicing.value = this.isSpotContract() ? 'Buyer RCTI': 'Seller to Invoice Buyer';
      }

      this.setState(newState);
    }
    Promise.all([
      dispatch(fetchMarketZones()),
      dispatch(getFixtures()),
      dispatch(getTracks()),
      dispatch(getGeneralConditions('contract')),
      dispatch(getSpecialConditions('contract')),
    ]);
    if (currentUser.companyId) {
      dispatch(getCompanyEmployees(currentUser.companyId, receiveEmployees));
      dispatch(getCompanyCompaniesMinimal(currentUser.companyId, { include_parent_company: true, user_properties: 'brokerages_for_user' }));
    }
    if(this.state.isSpotLocked)
      setTimeout(() => dispatch(forceStopLoader()), 5000)

    if(this.isDuplicatingContract){
      const queryParams = new URLSearchParams(window.location.hash.split('?')[1]);
      const contractId = queryParams.get('copyFrom');
      if(contractId){
        this.applyingTemplate = true
        this.prefillBrokerById = false;
        this.getSelectedContractDetails(contractId);
      }
    }
  }



  getBase64Logo() {
    if (get(this.props, 'currentUser.myCompanyLogoUrl') && !this.state.base64Logo) {
      APIService.base64()
                .post({ url: this.props.currentUser.myCompanyLogoUrl })
                .then(data => {
                  this.setState({ base64Logo: data.base64 });
                });
    }
  }

  __setCommonData = (prevState) => {
    this.setState((state) => {
      let isUpdated = false;
      const newState = { ...state };
  
      if (!isEmpty(this.props.tolerances) && isEmpty(newState.tolerances)) {
        newState.tolerances = this.props.tolerances;
        isUpdated = true;
      }
  
      forEach(
        [
          'documentTypes',
          'tracks',
          'marketZones',
          'marketZonesWithRegions',
          'farmFields',
          'homeStorages',
          'systemStorages',
          'companySites',
          'pricePoints',
          'checkpoints',
          'conveyances',
          'packagings',
          'paymentTerms',
          'currentUser',
          'generalConditionsAll',
          'specialConditionsAll',
          'brokers',
        ],
        (item) => {
          if (!isEqual(prevState[item], this.props[item])) {
            isUpdated = true;
            newState[item] = this.props[item];
          }
        }
      );
  
      if (
        !this.isEditingContract &&
        !this.applyingTemplate &&
        !isCurrentUserBroker() &&
        !this.state.fields.documentTypeId.value &&
        this.props.isCompanyPartyDataSet &&
        !this.isDefaultDocumentTypeSelected
      ) {
        const contractDocumentTypeId = get(
          find(this.props.documentTypes, { name: 'contract', displayName: 'Contract' }),
          'id'
        );
        if (contractDocumentTypeId) {
          newState.fields.documentTypeId.value = contractDocumentTypeId;
          isUpdated = true;
          this.isDefaultDocumentTypeSelected = true;
        }
      }
  
      return isUpdated ? newState : null;
    });
  };

  __setBrokeredBy(prevProps) {
    if (
      !this.isEditingContract &&
      isCurrentUserBroker() &&
      this.state.fields.administration.brokeredById.value !== this.props.companyId &&
      prevProps.companyId !== this.props.companyId &&
      !this.isCurrentUserSellerOrBuyer()
    ) {
      const newState = { ...this.state };
      newState.fields.administration.brokeredById.value = this.props.companyId;
      this.setState(newState);
    }
  }

  __setParties() {
    const { allCompanyParties } = this.props;
    if (
      !this.state.fields.documentTypeId.value &&
      (isEmpty(this.state.sellerParties) || isEmpty(this.state.buyerParties)) &&
      !isEmpty(allCompanyParties)
    )
      this.setState({ sellerParties: allCompanyParties, buyerParties: allCompanyParties });
  }

  __setBuyerFromSelectedContract() {
    if(
      this.isEditingContract &&
      get(this.selectedContract, 'buyer.companyId') &&
      !get(this.state.fields, 'buyer.companyId.value')
    )
      this.setState({
        fields: {
          ...this.state.fields,
          buyer: {
            ...this.state.fields.buyer,
            companyId: {
              ...this.state.fields.buyer.companyId,
              value: this.selectedContract.buyer.companyId
            },
          },
        },
      });
  }

  __setFixtures() {
    if (
      (isEmpty(this.state.assigners) && !isEmpty(this.props.brokers)) ||
      (isEmpty(this.state.types) && !isEmpty(this.props.types)) ||
      (isEmpty(this.state.paymentScales) && !isEmpty(this.props.paymentScales))
    ) {
      this.setState({
        assigners: isEmpty(this.state.assigners) ? this.props.brokers : this.state.assigners,
        types: isEmpty(this.state.types) ? this.props.types : this.state.types,
        paymentScales: compact(isEmpty(this.state.paymentScales) ? this.props.paymentScales : this.state.paymentScales),
      });
    }
  }

  __setConditions() {
    let isUpdated = false;
    const newState = { ...this.state };
    if (!this.selectedContract && !this.isEditingContract) {
      if (!isEmpty(this.props.specialConditionsAll) && !this.state.isDefaultSpecialConditionApplied) {
        isUpdated = true;
        newState.isDefaultSpecialConditionApplied = true;
        newState.fields.specialConditions.value = map(filter(this.props.specialConditionsAll, { default: true }), 'details').join('\n');
      }
      if (!isEmpty(this.props.generalConditionsAll) && !this.state.isDefaultGeneralConditionApplied) {
        isUpdated = true;
        newState.isDefaultGeneralConditionApplied = true;
        newState.fields.generalConditions.value = map(filter(this.props.generalConditionsAll, { default: true }), 'details').join('\n');
      }
    }
    if (isUpdated) this.setState(newState);
  }

  __setBrokerCompanies() {
    const { brokerCompanies } = this.props;
    if (brokerCompanies && !isEqual(this.state.brokerCompanies, brokerCompanies) && isEmpty(this.state.brokerCompanies) && !isEmpty(brokerCompanies)) {
      this.setState(prevState => {
        const newState = { ...prevState };
        newState.brokerCompanies = brokerCompanies;
        if (isCurrentUserBroker()) {
          if (!find(brokerCompanies, { id: this.props.currentUser.companyId }))
            newState.brokerCompanies = [...newState.brokerCompanies, this.props.currentUser.company];

          if (this.prefillBrokerById && (this.isEditingContract || !this.isCurrentUserSellerOrBuyer() ))
            newState.fields.administration.brokeredById.value = this.props.currentUser.companyId;
        }
        return newState;
      });
    }
  }

  __setServerErrors(prevProps) {
    if (!isEqual(this.props, prevProps)) {
      const { serverErrors } = this.props;
      this.setState(prevState => {
        const newState = { ...prevState };
        forEach(serverErrors, (value, key) => {
          key = key === 'contractNumber' ? 'contractIdentifier' : key;
          if (isArray(get(newState.fields, `${key}.errors`))) {
            newState.fields[key] = {
              ...newState.fields[key],
              errors: value
            };
          }
        });
        return newState;
      });
    }
  }

  __getSelectedContract() {
    if (this.isEditingContract && !this.state.isFetchingSelectedContract) {
      this.setState({isFetchingSelectedContract: true}, () => {
        this.prefillBrokerById = false;
        this.getSelectedContractDetails(this.contractId);
      });
    }
  }

  isSpotContract = () => this.state.isSpotLocked || this.state.fields.typeId.value === CONTRACT_TYPES.SPOT
  isBlended = () => this.state.fields.typeId.value === CONTRACT_TYPES.BLENDED

  componentDidUpdate(prevProps, prevState) {
    if(this.isSpotContract()) {
      if(!isEmpty(this.props.documentTypes) && !isEmpty(this.props.allCompanyParties) && !this.state.fields.documentTypeId.value)
        this.handleDocumentTypeChange(find(this.props.documentTypes, {name: 'contract'})?.id, 'documentTypeId')
    }
    if(!this.isSubmittingNewContract && !this.isEditingContract && this.props.isLoading && !isEmpty(this.props.allCompanyParties) && !this.applyingTemplate)
      this.props.dispatch(forceStopLoader());
    else if(!this.isSubmittingNewContract && this.isEditingContract && this.props.isLoading && !isEmpty(this.props.allCompanyParties) && this.selectedContract)
      setTimeout(() => {
        this.props.dispatch(forceStopLoader());
      }, 2000);
    if (prevProps.allCompanyParties != this.props.allCompanyParties && this.isDuplicatingContract) {
      const newState = { ...this.state };
      newState.sellerParties = this.props.allCompanyParties;
      newState.buyerParties = this.props.allCompanyParties;
      newState.fields.seller.companyId.value = this.selectedContract?.seller?.companyId;
      newState.fields.buyer.companyId.value = this.selectedContract?.buyer?.companyId;
      this.setState(newState);
    }
    if (this.state.isUniqueIdentifierErrorExists && isEmpty(this.state.fields.identifier.errors)) {
      const newState = { ...this.state };
      newState.fields.identifier.errors = ['Contract with this Contract number already exists'];
      this.setState(newState);
    }
    this.__setCommonData(prevState);
    this.__setBrokeredBy(prevProps);
    this.__setParties();
    this.__setBuyerFromSelectedContract();
    this.__setFixtures();
    this.__setConditions();
    this.__setBrokerCompanies();
    this.__setServerErrors(prevProps);
    this.__getSelectedContract();
  }

  componentWillUnmount() {
    this.props.dispatch(receiveGeneralConditions([]));
    this.props.dispatch(receiveSpecialConditions([]));
  }

  commodityFieldChange(value, id) {
    const newState = { ...this.state };
    const pastCommodityId = newState.fields[id].value
    newState.sustainableCommodity = false;
    const unit = this.priceUnit(value)
    newState.tolerances = map(newState.tolerances, tolerance => {
      tolerance.name = replaceUnit(tolerance.name, unit);
      return tolerance;
    });
    if (this.hasQuantityBasedCommodity(value)) {
      newState.fields.quantity.validators = [required(), minValue(0)];
    } else {
      newState.fields.quantity.validators = [];
      newState.fields.quantity.value = undefined;
      newState.fields.quantity.errors = [];
    }

    this.toggleSpreadValidation(newState);
    if (newState.fields[id].value !== value) {
      this.setFieldValue('varietyId', null);
      if (
        value === COMMODITIES.WHEAT &&
        includes([CONTRACT_TYPES.MULTI_GRADE, CONTRACT_TYPES.FLOATING_MULTI_GRADE, CONTRACT_TYPES.AREA], newState.fields.typeId.value)
      ) {
        setTimeout(() => {
          const newState = { ...this.state };
          newState.fields.gradeId.errors = [];
          this.setState(newState);
          this.setFieldValue('gradeId', GRADES.APH1);
        }, 500);
      } else {
        this.setFieldValue('gradeId', null);
      }
      this.setFieldValue('spread', '');
    }
    newState.fields[id].value = value;
    this.getContractTypes(newState);
    if (value === COMMODITIES.CANOLA) {
      newState.paymentScales = compact(this.props.paymentScales);
      newState.fields.paymentScaleId.value = PAYMENT_SCALES.AOF_BASIC;
      newState.fields.paymentScaleId.errors = [];
    } else {
      newState.paymentScales = compact([find(newState.paymentScales, { id: PAYMENT_SCALES.FLAT })]);
    }
    if(FERTILISER_IDS.includes(value))
      this.setFieldValue('season', SEASON_NA)
    else if(FERTILISER_IDS.includes(pastCommodityId) && this.state.fields.season.value)
      this.setFieldValue('season', undefined)
    this.setState(newState, () => this.setFieldErrors(id, value));
  }

  getContractTypes(newState) {
    var contractTypes = this.props.types;
    if (get(newState.selectedBuyer, 'isAllowedAsBuyerForPoolContract') || this.isPoolContractType(newState)) contractTypes = contractTypes.concat(this.props.poolContractTypes);

    newState.types = contractTypes;
  }

  shouldDisplayOptionalContractField() {
    let selectedDocumentType = find(this.props.documentTypes, { id: this.state.fields.documentTypeId.value });
    if (this.isEditingContract && this.selectedContract && !selectedDocumentType) selectedDocumentType = get(this.selectedContract, 'documentType');

    return includes(['sales_confirmation', 'broker_note'], get(selectedDocumentType, 'name'));
  }

  filterContacts = (contacts, includeCurrentCompanyContacts) => {
    if (includeCurrentCompanyContacts) {
      contacts = contacts.concat(this.props.brokers);
    } else {
      const brokerIds = map(this.props.brokers, 'id');
      contacts = filter(contacts, contact => {
        return !includes(brokerIds, contact.id);
      });
    }
    return uniqBy(contacts, 'id');
  };

  handleDocumentTypeChange(value, id) {
    if (isNumber(value)) {
      const newState = { ...this.state };
      newState.fields[id].value = value;
      newState.fetchSellerParties = false;
      newState.fetchBuyerParties = false;
      newState.sellerContacts = [];
      newState.buyerContacts = [];
      newState.sellerNgrs = [];
      newState.buyerNgrs = [];
      newState.disableSeller = false;
      newState.fields.seller = cloneDeep(this.sellerFields);
      newState.fields.buyer = cloneDeep(this.buyerFields);
      newState.fields.consignors = [cloneDeep(this.consignorFields)];
      newState.fields.consignees = [cloneDeep(this.consigneeFields)];
      newState.selectedSeller = undefined;
      newState.selectedBuyer = undefined;
      newState.selectedConsignor = undefined;
      newState.selectedConsignorSite = undefined;
      if (newState.applyRule) {
        let startDate = newState.fields.deliveryStartDate.value;
        let startDateErrors = newState.fields.deliveryStartDate.errors;
        const allowPastDates = true;
        let maxDate = this.getMaxDeliveryStartDate();
        maxDate = maxDate ? maxDate.format('YYYY-MM-DD') : maxDate;
        const minDate = allowPastDates ? moment(0).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
        if (startDate < minDate || startDate > maxDate) {
          startDate = undefined;
          startDateErrors = [];
        }
        newState.fields.deliveryStartDate.value = startDate;
        newState.fields.deliveryStartDate.errors = startDateErrors;
        newState.fields.deliveryEndDate.value = this.isSpotContract() ? newState.fields.deliveryEndDate.value : undefined;
        newState.fields.deliveryEndDate.errors = [];
        newState.fields.carryStartDate.value = undefined;
        newState.fields.carryStartDate.errors = [];
        newState.fields.carryEndDate.value = undefined;
        newState.fields.carryEndDate.errors = [];
      }
      const selectedDocumentType = find(this.props.documentTypes, { id: newState.fields.documentTypeId.value });
      let identifierPrefix = '';
      let sellerParties = compact([...this.props.allCompanyParties, this.state.sellerEstablishmentDetails.company]);
      if (selectedDocumentType && selectedDocumentType.name === 'sales_confirmation') {
        newState.sellerParties = sellerParties
        newState.buyerParties = compact([...this.props.allCompanyParties, this.state.buyerEstablishmentDetails.company]);
        newState.fields.identifier.label = 'Sales Confirmation No';
        identifierPrefix = SALES_CONFIRMATION_PREFIX;
        if (isCurrentUserBroker()) {
          newState.representingBuyer = true;
          newState.representingSeller = false;
        }
      } else {
        newState.sellerParties = sellerParties
        newState.buyerParties = compact([...this.props.allCompanyParties, this.state.buyerEstablishmentDetails.company]);
        if (selectedDocumentType && selectedDocumentType.name === 'broker_note') {
          newState.sellerContacts = this.filterContacts(newState.sellerContacts, true);
          newState.fields.seller.contactId.value = newState.currentUser.id;
          newState.fields.identifier.label = 'Broker Note No';
          identifierPrefix = BROKER_NOTE_PREFIX;
          newState.representingSeller = true;
          newState.representingBuyer = false;
        } else if (selectedDocumentType) {
          newState.sellerContacts = this.filterContacts(newState.sellerContacts, false);
          newState.buyerContacts = this.filterContacts(newState.buyerContacts, true);
          newState.representingSeller = false;
          if (isCurrentUserBroker()) {
            newState.representingBuyer = true;
          }
          newState.fields.identifier.label = 'Contract No';
          identifierPrefix = CONTRACT_PREFIX;
        }
      }

      let identifierWithPrefix = newState.fields.identifier.value;
      const identifier = newState.fields.identifier.value;

      if (selectedDocumentType && identifier) {
        const existingPrefix = identifier[0];
        if(existingPrefix !== identifierPrefix && includes(without([SALES_CONFIRMATION_PREFIX, BROKER_NOTE_PREFIX, CONTRACT_PREFIX], identifierPrefix), existingPrefix))
          identifierWithPrefix = identifierPrefix + identifier.substring(1, identifier.length);
        if (identifierWithPrefix.substring(1, identifierWithPrefix.length) === this.generatedIdentifier.substring(1, this.generatedIdentifier.length))
          newState.fields.identifier.value = identifierWithPrefix;
      }

      newState.fields.contractIdentifier.value = null;

      this.setState(newState, () => {
        this.setFieldErrors(id, value);
        this.checkDeliveryEndDate();
        this.openSideDrawer();
        if(this.isSpotContract() && (!this.state.fields.seller.companyId.value || !this.state.selectedSeller?.id)) {
          const company = find(sellerParties, {id: this.props.currentUser.companyId})
          if(company)
            this.handleSellerCompanyIdChange(company.id, 'seller.companyId', company)
        }
      });
    }
  }

  handleSellerBrokerageField(event) {
    const newState = { ...this.state };
    const brokerage = this.getPartyBrokerage('seller');
    this.updatePartyBrokerageAttributes(brokerage, event.target.id, event.target.value);
    this.setState(newState);
  }

  updatePartyBrokerageAttributes(brokerage, id, value) {
    brokerage[id].value = value;
    if (!isEmpty(value)) brokerage[id].errors = [];

    this.updateTotalFee(brokerage);
  }

  handleBuyerBrokerageField(event) {
    const newState = { ...this.state };
    const brokerage = this.getPartyBrokerage('buyer');
    this.updatePartyBrokerageAttributes(brokerage, event.target.id, event.target.value);
    this.setState(newState);
  }

  handleBuyerBrokerageFeeType(value, id) {
    const brokerage = this.getPartyBrokerage('buyer');
    const prevValue = brokerage.feeType.value;
    const prevRate = brokerage.rate.value;
    const party = this.getParty('buyer');
    if (isCurrentUserBroker() && value && party && party.brokeragesForUser[0] && party.brokeragesForUser[0].feeType === 'Flat Fee') {
      if (value !== prevValue) {
        const displayValue = getBrokerageDisplayValue(party.brokeragesForUser[0], this.tonnageUnit());
        alertifyjs
          .confirm(
            'Warning',
            'You have set a periodic brokerage fees for this party as ' +
            displayValue +
            '. The fee selected here will be charged to the party over and above the periodic brokerage fees. Are you sure you want to add this brokerage amount for this party?',
            () => {
              const newState = { ...this.state };
              this.updatePartyBrokerageFeeType(brokerage, value);
              this.setState(newState, () => this.setFieldErrors(id, value));
            },
            () => {
              const newState = { ...this.state };
              newState.clearBuyerFeeType = prevValue === null || prevValue === '';
              this.updatePartyBrokerageFeeType(brokerage, prevValue, prevRate);
              this.setState(newState);
            },
          )
          .set('labels', { ok: 'Yes', cancel: 'No' });
      }
    } else {
      const newState = { ...this.state };
      const brokerage = this.getPartyBrokerage('buyer');
      this.updatePartyBrokerageFeeType(brokerage, value);
      this.setState(newState, () => {
        this.setFieldErrors(id, value);
      });
    }
    const newState = { ...this.state };
    newState.clearBuyerFeeType = false;
    if (value) brokerage.feeType.errors = [];
    this.setState(newState);
  }

  updatePartyBrokerageFeeType(brokerage, value, prevRate) {
    brokerage.feeType.value = value;
    brokerage.feeType.errors = [];
    if (prevRate) {
      brokerage.rate.value = prevRate;
    }
    if (brokerage.feeType.value === 'Flat Fee') {
      brokerage.rate.value = '';
      brokerage.rate.errors = [];
      brokerage.rate.validators = [];
    } else {
      brokerage.rate.validators = [required()];
      brokerage.feeType.errors = ["This field can not be blank"];
    }
    this.updateTotalFee(brokerage);
  }

  handleSellerBrokerageFeeType(value, id) {
    const brokerage = this.getPartyBrokerage('seller');
    const prevValue = brokerage.feeType.value;
    const prevRate = brokerage.rate.value;
    const party = this.getParty('seller');
    if (isCurrentUserBroker() && value && party && party.brokeragesForUser[0] && party.brokeragesForUser[0].feeType === 'Flat Fee') {
      if (value !== prevValue) {
        const displayValue = getBrokerageDisplayValue(party.brokeragesForUser[0], this.tonnageUnit());
        alertifyjs
          .confirm(
            'Warning',
            'You have set a periodic brokerage fees for this party as ' +
            displayValue +
            '. The fee selected here will be charged to the party over and above the periodic brokerage fees. Are you sure you want to add this brokerage amount for this party?',
            () => {
              const newState = { ...this.state };
              this.updatePartyBrokerageFeeType(brokerage, value);
              this.setState(newState, () => this.setFieldErrors(id, value));
            },
            () => {
              const newState = { ...this.state };
              newState.clearSellerFeeType = prevValue === null || prevValue === '';
              this.updatePartyBrokerageFeeType(brokerage, prevValue, prevRate);
              this.setState(newState);
            },
          )
          .set('labels', { ok: 'Yes', cancel: 'No' });
      }
    } else {
      const newState = { ...this.state };
      const brokerage = this.getPartyBrokerage('seller');
      this.updatePartyBrokerageFeeType(brokerage, value);
      this.setState(newState, () => {
        this.setFieldErrors(id, value);
      });
    }
    const newState = { ...this.state };
    newState.clearSellerFeeType = false;
    if (value) brokerage.feeType.errors = [];
    this.setState(newState);
  }

  getQuantity() {
    if (this.isMeterCubeCommodityCommodity() && this.state.fields.quantity.value) return this.state.fields.quantity.value;
    return this.state.fields.tonnage.value;
  }

  updateTotalFee(brokerage) {
    if (brokerage.feeType.value === 'Fee Per MT') {
      brokerage.totalFee.value = round((parseFloat(this.getQuantity()) || 0) * (brokerage.rate.value || 0), 2);
    } else if (brokerage.feeType.value === '% of Sale') {
      brokerage.totalFee.value = round((this.contractGrainValue() || 0) * (brokerage.rate.value / 100 || 0), 2);
    }
    brokerage.totalFee.errors = [];
  }

  updateAdminValidators(value, field) {
    const newState = { ...this.state };
    let isRequiredValidator = this.isBrokerFieldRequired(field, value);
    if (isRequiredValidator) {
      newState.fields.administration.brokeredById.validators = [required(), selected()];
      newState.fields.administration.brokerContactId.validators = [required(), selected()];
    } else {
      newState.fields.administration.brokeredById.validators = [selected()];
      newState.fields.administration.brokerContactId.validators = [selected()];
    }
    this.updateBrokerContactValidators(newState);
    this.setState(newState);
  }

  isBrokerFieldRequired(field, value) {
    let isRequired = false;
    if (field === 'payableBy') {
      isRequired =
        includes(['Buyer', 'Seller', 'Seller And Buyer'], value) ||
        includes(['Broker to Invoice Buyer'], this.state.fields.administration.invoicing.value);
    } else if (field === 'invoicing') {
      isRequired =
        includes(['Buyer', 'Seller', 'Seller And Buyer'], this.state.fields.payableBy.value) || includes(['Broker to Invoice Buyer'], value);
    } else {
      isRequired =
        includes(['Buyer', 'Seller', 'Seller And Buyer'], this.state.fields.payableBy.value) ||
        includes(['Broker to Invoice Buyer'], this.state.fields.administration.invoicing.value);
    }
    return isRequired;
  }

  updateBrokerContactValidators(newState) {
    newState.fields.administration.brokerContactId.validators =
      newState.fields.administration.brokeredById.value || this.isBrokerFieldRequired() ? [required()] : [];
  }

  handlePayableBy(value) {
    const newState = { ...this.state };
    if (this.state.fields.payableBy.value !== value) {
      if (includes(value.toLowerCase(), 'buyer')) {
        newState.canSetDefaultBrokeragesForBuyer = true;
      }
      if (includes(value.toLowerCase(), 'seller')) {
        newState.canSetDefaultBrokeragesForSeller = true;
      }
    }
    if (value === 'Buyer') {
      newState.fields.brokerages = [set(cloneDeep(this.brokerageFields), 'type.value', 'Buyer')];
    } else if (value === 'Seller') {
      newState.fields.brokerages = [set(cloneDeep(this.brokerageFields), 'type.value', 'Seller')];
    } else if (value === 'Seller And Buyer') {
      newState.fields.brokerages = [
        set(cloneDeep(this.brokerageFields), 'type.value', 'Seller'),
        set(cloneDeep(this.brokerageFields), 'type.value', 'Buyer'),
      ];
    } else {
      newState.fields.brokerages = [];
    }

    this.updateAdminValidators(value, 'payableBy');
    newState.fields.payableBy.value = value;
    this.setState(newState, () => {
      if (includes(value.toLowerCase(), 'buyer') && newState.canSetDefaultBrokeragesForBuyer) {
        const party = this.getParty('buyer');
        if (party) {
          this.setState({ canSetDefaultBrokeragesForBuyer: false }, () => this.handleDefaultBrokerageForParty('buyer', party));
        }
      }
      if (includes(value.toLowerCase(), 'seller') && newState.canSetDefaultBrokeragesForSeller) {
        const party = this.getParty('seller');
        if (party) {
          this.setState({ canSetDefaultBrokeragesForSeller: false }, () => this.handleDefaultBrokerageForParty('seller', party));
        }
      }
    });
    this.setFieldErrors('administration.brokeredById', this.state.fields.administration.brokeredById.value);
    this.setFieldErrors('administration.brokerContactId', this.state.fields.administration.brokerContactId.value);
  }

  handleSellerChargedOn(value) {
    const newState = { ...this.state };
    const brokerage = this.getPartyBrokerage('seller');
    brokerage.chargedOn.value = value;
    if (!isEmpty(value)) brokerage.chargedOn.errors = [];
    this.setState(newState);
  }

  handleBuyerChargedOn(value) {
    const newState = { ...this.state };
    const brokerage = this.getPartyBrokerage('buyer');
    brokerage.chargedOn.value = value;
    if (!isEmpty(value)) brokerage.chargedOn.errors = [];
    this.setState(newState);
  }

  handlePreviewClick() {
    this.setState({
      ...this.state,
      preview: true,
    });
  }

  handlePreviewClose() {
    this.setState({
      ...this.state,
      preview: false,
    });
  }

  appendGeneralConditions() {
    const newState = { ...this.state };
    if (isEmpty(newState.fields.generalConditions.value))
      newState.fields.generalConditions.value = newState.selectedGeneralConditionText;
    else if (!includes(newState.fields.generalConditions.value, newState.selectedGeneralConditionText))
      newState.fields.generalConditions.value = newState.fields.generalConditions.value + '\n\n' + newState.selectedGeneralConditionText;
    this.setState(newState);
  }

  appendSpecialConditions() {
    const newState = { ...this.state };
    if (isEmpty(newState.fields.specialConditions.value))
      newState.fields.specialConditions.value = newState.selectedSpecialConditionText;
    else if (!includes(newState.fields.specialConditions.value, newState.selectedSpecialConditionText))
      newState.fields.specialConditions.value = newState.fields.specialConditions.value + '\n\n' + newState.selectedSpecialConditionText;
    this.setState(newState);
  }

  handleConditionSelector(value, id, chosenItem) {
    var key = null;
    if (id === 'generalConditionsSelector') {
      key = 'selectedGeneralConditionText';
    } else if (id === 'specialConditionsSelector') {
      key = 'selectedSpecialConditionText';
    }
    const newState = { ...this.state };
    if (chosenItem) {
      newState[key] = chosenItem.details;
      newState[id] = value;
    } else {
      newState[key] = undefined;
      newState[id] = undefined;
    }
    this.setState(newState);
  }

  handlePricePointChange(value, id) {
    var newState = { ...this.state };
    var pricePointChanged = newState.fields[id].value !== value;
    newState.fields[id].value = value;
    if (this.setDefaultValues && (newState.buyerChanged || pricePointChanged)) {
      newState.fields.consignees = [cloneDeep(this.consigneeFields)];
      newState.fields.consignors = [cloneDeep(this.consignorFields)];
    }
    if(this.state.isTrackVisible) {
      newState.fields.track.validators = [selected()];
      newState.fields.track.errors = [];
    }
    newState.fields.marketZoneId.label = 'Market Zone (Optional)';
    newState.fields.marketZoneId.validators = [selected()];
    newState.fields.marketZoneId.errors = [];
    if ([PRICE_POINTS.DELIVERED_SITE, PRICE_POINTS.DPU].includes(value)) {
      if (!this.isEditingContract) newState.fields.toleranceId.value = 1;
      newState = this.toggleConsignorValidation(newState, false, true);
      newState = this.toggleConsigneeValidation(newState, true, false);
    } else if (includes(PICKUP_MANDATORY_PRICE_POINTS, value)) {
      newState = this.toggleConsignorValidation(newState, true, false);
      newState = this.toggleConsigneeValidation(newState, false, true);
    } else if (value === PRICE_POINTS.DELIVERED_BUYER) {
      newState = this.toggleConsignorValidation(newState, false, true);
      newState = this.toggleConsigneeValidation(newState, true, false);
    } else if (value === PRICE_POINTS.DELIVERED_PORT) {
      newState = this.toggleConsignorValidation(newState, false, false);
      newState = this.toggleConsigneeValidation(newState, true, true);
    } else if (value === PRICE_POINTS.TRACK) {
      newState.fields.track.validators = [required(), selected()];
      newState = this.toggleConsignorValidation(newState, false, false);
      newState = this.toggleConsigneeValidation(newState, false, false);
    } else if (value === PRICE_POINTS.FREE_IN_STORE) {
      newState = this.toggleConsignorValidation(newState, false, false);
      newState = this.toggleConsigneeValidation(newState, true, false);
    } else if (value === PRICE_POINTS.DELIVERED_DCT) {
      newState = this.toggleConsignorValidation(newState, false, true);
      newState = this.toggleConsigneeValidation(newState, true, false);
    } else if (value === PRICE_POINTS.DELIVERED_MARKET_ZONE) {
      newState.fields.marketZoneId.label = 'Market Zone';
      newState.fields.marketZoneId.validators = [required(), selected()];
      newState = this.toggleConsignorValidation(newState, false, false);
      newState = this.toggleConsigneeValidation(newState, false, false);
    } else if (includes([PRICE_POINTS.COST_AND_FREIGHT, PRICE_POINTS.COST_INSURANCE_FREIGHT, PRICE_POINTS.FREE_ON_BOARD], value)) {
      newState = this.toggleConsignorValidation(newState, false, true);
      newState = this.toggleConsigneeValidation(newState, true, false);
    }
    if (this.setDefaultValues) {
      if (
        includes(PRICE_POINTS_TRACK_AND_DELIVERED_MARKET_ZONE, value) || includes(DELIVERY_MANDATORY_PRICE_POINTS, value)
      ) {
        newState.fields.deliveryOnus.value = 'Seller';
      } else if (includes(PICKUP_MANDATORY_PRICE_POINTS, value)) {
        newState.fields.deliveryOnus.value = 'Buyer';
      } else {
        newState.fields.deliveryOnus.value = null;
      }
    }
    if (this.setDefaultValues) {
      if (includes([PRICE_POINTS.EX_FARM_STORE, PRICE_POINTS.DELIVERED_BUYER, PRICE_POINTS.DELIVERED_DCT, PRICE_POINTS.FREE_ON_TRUCK], value)) {
        this.setFieldErrors('track', newState.fields.track.value);
      }
      newState.selectedConsignor = undefined;
      newState.selectedConsignorSite = undefined;
    }
    this.setState(newState, () => {
      this.setFieldErrors(id, value);
    });
  }

  toggleConsignorValidation(newState, mandatory, toggleTrack) {
    const validators = mandatory ? [required(), selected()] : [selected()];
    const consignor = get(newState, 'fields.consignors.0');
    if (consignor) {
      newState.fields.consignors[0].handlerId.validators = validators;
    }
    if (toggleTrack && this.state.isTrackVisible) {
      newState.fields.track.validators = validators;
    }
    this.consignorFields.handlerId.validators = validators;
    return newState;
  }

  toggleConsigneeValidation(newState, mandatory, toggleTrack) {
    const validators = mandatory ? [required(), selected()] : [selected()];
    const consignee = get(newState, 'fields.consignees.0');
    if (consignee) {
      newState.fields.consignees[0].handlerId.validators = validators;
    }
    if (toggleTrack && this.state.isTrackVisible) {
      newState.fields.track.validators = validators;
    }
    this.consigneeFields.handlerId.validators = validators;
    return newState;
  }

  isDateBefore(value) {
    return moment(value).isBefore(moment().format('YYYY-MM-DD'));
  }

  handleDeliveryDateChange(value, id) {
    this.setFieldValue(id, value);
    this.setFieldErrors(id, value);
  }

  handleDeliveryStartDateChange(value, id) {
    this.setFieldValue(id, value);
    this.setFieldErrors(id, value);
  }

  checkDeliveryEndDate() {
    const newState = { ...this.state };
    const startDate = newState.fields.deliveryStartDate.value;
    const endDate = newState.fields.deliveryEndDate.value;
    if (startDate && moment(startDate).isValid()) {
      if (moment(endDate).isValid() && !moment(startDate).isSameOrBefore(moment(endDate))) {
        this.setFieldValue('deliveryEndDate', undefined);
        this.setFieldValue('carryEndDate', undefined);
        this.setFieldErrors('deliveryEndDate', undefined);
      }
    }
    if (!this.isEditingContract && moment(startDate).isValid() && moment(endDate).isValid() && moment(startDate).isBefore(moment(endDate))) {
      const selectedDocumentType = find(this.props.documentTypes, { id: newState.fields.documentTypeId.value });
      const allowPastDates = selectedDocumentType && selectedDocumentType.name === 'contract';
      const pastDate = this.isDateBefore(endDate);
      newState.fields.deliveryEndDate.errors = [];
      if (allowPastDates && this.isDateBefore(startDate) && !pastDate) {
        newState.fields.deliveryEndDate.errors = ['Warning: This will create an Open contract'];
      }
      this.setState(newState);
    }
  }

  handleDeliveryEndDateChange(value, id) {
    this.setFieldValue(id, value);
    this.setFieldErrors(id, value);
    this.setFieldValue('carryEndDate', value, () => this.clearCarryStartDateIfNeeded());
  }

  clearCarryStartDateIfNeeded() {
    const carryStartDate = this.state.fields.carryStartDate.value;
    const carryEndDate = this.state.fields.carryEndDate.value;
    if (carryStartDate && carryEndDate && moment(carryStartDate).isValid() && moment(carryEndDate).isValid()) {
      if (moment(carryEndDate).isBefore(moment(carryStartDate))) {
        this.setFieldValue('carryStartDate', undefined);
      }
    }
  }

  handleAdministratorFieldChange(value, id) {
    if (value !== VALUE_NOT_SELECTED) {
      const newState = { ...this.state };
      newState.fields.administration[id].value = value;
      this.setState(newState);
    }
  }

  handleInvoiceChange(value) {
    const newState = { ...this.state };
    newState.fields.administration.invoicing.value = value;
    if (includes(value, 'Seller')) {
      newState.assigners = this.getInvoicingContacts(value, 'seller');
    } else if (includes(value, 'Broker')) {
      newState.assigners = newState.brokers;
    } else {
      newState.assigners = this.getInvoicingContacts(value, 'buyer');
    }
    this.updateAdminValidators(value, 'invoicing');
    this.setState(newState);
    this.setFieldErrors('administration.brokeredById', this.state.fields.administration.brokeredById.value);
    this.setFieldErrors('administration.brokerContactId', this.state.fields.administration.brokerContactId.value);
  }

  getInvoicingContacts(value, key) {
    const contactsKey = key + 'Contacts';
    const companyId = this.state.fields[key].companyId.value;

    return filter(this.state[contactsKey], { companyId: companyId });
  }

  handleTrackChange(value, targetId) {
    const newState = { ...this.state };
    newState.fields.track.value = value;

    forEach(newState.fields.consignees, (consignee, index) => {
      const site = find(this.state.consignees, {id: consignee.handlerId.value});
      if(site)
        consignee.ld.value = this.getSiteLDByTrack(site, value, index, newState, 'consignees', consignee.ld.value) || 0;
    });

    forEach(newState.fields.consignors, (consignor, index) => {
      const site = find(this.state.consignors, {id: consignor.handlerId.value});
      if(site)
        consignor.ld.value = this.getSiteLDByTrack(site, value, index, newState, 'consignors', consignor.ld.value) || 0;
    });

    this.setState(newState, () => {
      this.setFieldErrors(targetId, value);
    });
  }

  getSiteLDByTrack(site, track, index=-1, newState={}, siteType, ld=undefined, checkChange=false) {
    let defaultTrack = get(site, 'defaultTrack', '')
    let tracks = {};
    const siteTracks = get(site, 'tracks', []);
    if(siteTracks) {
      forEach(Object.keys(siteTracks), key => {
        tracks[key.toLowerCase()] = siteTracks[key];
      })
    }
    let result = (defaultTrack && tracks && tracks[defaultTrack.toLowerCase()]) || values(siteTracks)[0] || 0

    result = this.formatLDValue(result);
    let baseLD = this.state.fields[siteType][0].ld_db;
    let trackResult = undefined;
    if (site && track)
      trackResult = this.formatLDValue(find(site.tracks, (_, t) => t.toLowerCase() === track.toLowerCase()));
    if (newState) {
      newState.fields[siteType][index].ld_db = (site && track) ? trackResult || result : result;
      this.setState(newState);
    }
    if ((this.isEditingContract || this.applyingTemplate) && checkChange)
      result = ld;
    else {
      let condition1 = includes(PICKUP_MANDATORY_PRICE_POINTS, this.state.fields.pricePointId.value);
      let condition2 = includes(DELIVERY_MANDATORY_PRICE_POINTS, this.state.fields.pricePointId.value);
      let isConsignor = siteType == 'consignors';
      let condition3 = isConsignor && (condition2 || includes(PRICE_POINTS_TRACK_AND_DELIVERED_MARKET_ZONE, this.state.fields.pricePointId.value)) ;
      let isLDDisabled = false;
      if (siteType == 'consignees'){
        const consigneeCount = get(this.selectedContract, 'consigneesWithSites', []).length - 1;
        const newSite = !this.selectedContract || get(this.selectedContract, 'status') === 'template' || index > consigneeCount
        isLDDisabled = !newSite && (this.isFieldDisabled('consigneeLD') || this.isConsigneeAndSiteFieldDisabled(index) || this.isSiteMandatoryBasedOnAllocation(index, 'delivery'))
      } else {
        isLDDisabled = this.isFieldDisabled('consignorLD') || this.isConsignorAndSiteFieldDisabled(index) || this.isSiteMandatoryBasedOnAllocation(index, 'pickup')
      }

      if (!isLDDisabled && condition1 && !isConsignor)
        result = ld || 0;

      if (!isLDDisabled && ((condition2 && !isConsignor) || (condition1 && isConsignor)) && get(site, 'isBhc')) {
        if (index == 0) {
          result = 0;
        }
        else if (index > 0) {
          result = (trackResult || result) - baseLD;
        }
      }

      if(!isLDDisabled && condition3){
        result = 0;
      }

      if (!isLDDisabled && site && track && !condition1 && !condition2 && !condition3)
        result = trackResult || result;
    }

    return parseFloat(result || 0).toFixed(2);
  }

  estimatedPaymentDueDate() {
    const selectedPaymentTerm = find(this.state.paymentTerms, { id: this.state.fields.paymentTermId.value });
    var val = null;
    if (selectedPaymentTerm && this.state.selectedBuyer) {
      let startOfWeek = 1;
      let endOfWeek = 5;
      if (this.state.selectedBuyer.partyType) {
        startOfWeek = this.state.selectedBuyer.startOfWeek;
        endOfWeek = this.state.selectedBuyer.endOfWeek;
      }
      if (selectedPaymentTerm.periodType == 'weeks') {
        endOfWeek = 0
        const nextFridayFromDate = nextEndDayFrom(this.state.fields[camelCase(selectedPaymentTerm.startFrom)].value, endOfWeek);
        val = nextFridayFromDate.add(selectedPaymentTerm.period, 'days').format(this.state.countryFormats.date);
      } else if (selectedPaymentTerm.periodType == 'months') {
        val = moment(this.state.fields[camelCase(selectedPaymentTerm.startFrom)].value)
          .endOf('month')
          .add(selectedPaymentTerm.period, 'days')
          .format(this.state.countryFormats.date);
      } else if (selectedPaymentTerm.periodType == 'days') {
        if (selectedPaymentTerm.name.includes('Business Days')) {
          const startDay = moment(this.state.fields[camelCase(selectedPaymentTerm.startFrom)].value).weekday();
          const weekdays = getWeekDays(startOfWeek, endOfWeek);
          if (startDay || startDay == 0) {
            const daysToAdd = getDaysToAdd(startDay, selectedPaymentTerm.period, weekdays);
            val = moment(this.state.fields[camelCase(selectedPaymentTerm.startFrom)].value)
              .add(daysToAdd, 'days')
              .format(this.state.countryFormats.date);
          }
        } else {
          val = moment(this.state.fields[camelCase(selectedPaymentTerm.startFrom)].value)
            .add(selectedPaymentTerm.period, 'days')
            .format(this.state.countryFormats.date);
        }
      } else if(selectedPaymentTerm.periodType == 'date'){
        const referenceDate = this.state.fields[camelCase(selectedPaymentTerm.startFrom)].value
        val = getEstimatedPaymentDueDateForDatePaymentTerm( referenceDate, selectedPaymentTerm, this.state.countryFormats.date);
      }
      else {
        val = selectedPaymentTerm.name;
      }
      return val === 'Invalid date' ? null : val;
    }
  }

  contractValue() {
    var _val = this.contractGrainValue();
    const currentCarry = this.state.fields.carryCurrent.value;
    if (currentCarry) {
      _val += parseFloat(currentCarry);
    }
    return isNaN(_val) || !_val ? null : parseFloat(_val).toFixed(2);
  }

  contractGrainValue() {
    var val = parseFloat(this.getQuantity()) * parseFloat(this.state.fields.price.value);
    return isNaN(val) || !val ? null : parseFloat(val);
  }

  levy() {
    const _contractValue = this.state.fields.tonnage.value * this.state.fields.price.value;
    const selectedCommodity = find(this.props.commodities, { id: this.state.fields.commodityId.value });
    const levy = selectedCommodity ? selectedCommodity.levy : { value: 0 };
    const pricePointId = this.state.fields.pricePointId.value;
    if (levy.multiplier === 'tonnage') {
      return Math.max(parseFloat(levy.value * this.state.fields.tonnage.value).toFixed(2), 0.0);
    }

    if (
      (includes([PRICE_POINTS.DELIVERED_SITE, PRICE_POINTS.DELIVERED_BUYER, PRICE_POINTS.DPU], pricePointId) && get(this.state, 'fields.consignees.0.isBHC')) ||
      includes([PRICE_POINTS.TRACK, PRICE_POINTS.DELIVERED_MARKET_ZONE, PRICE_POINTS.FREE_IN_STORE], pricePointId)
    ) {
      return Math.max(parseFloat(levy.value * (_contractValue - 20 * this.state.fields.tonnage.value)).toFixed(2), 0.0);
    }

    if (includes([PRICE_POINTS.DELIVERED_SITE, PRICE_POINTS.DELIVERED_BUYER, PRICE_POINTS.DPU], pricePointId) && !get(this.state, 'fields.consignees.0.isBHC')) {
      if (this.state.totalDistance) {
        return Math.max(
          parseFloat(levy.value * (_contractValue - 0.11 * this.state.totalDistance * this.state.fields.tonnage.value)).toFixed(2),
          0.0,
        );
      } else {
        return 0.0;
      }
    }

    if (includes([PRICE_POINTS.EX_FARM_STORE, PRICE_POINTS.FREE_ON_TRUCK, PRICE_POINTS.DELIVERED_DCT], pricePointId)) {
      return Math.max(parseFloat(levy.value * _contractValue).toFixed(2), 0.0);
    }
  }

  maxContractValue() {
    var _val = this.contractGrainValue();
    const maxCarry = this.state.fields.carryMax.value;
    if (maxCarry) {
      _val += parseFloat(maxCarry);
    }
    return isNaN(_val) || !_val ? null : parseFloat(_val).toFixed(2);
  }

  eprValue() {
    if (this.state.fields.varietyId.value) {
      const selectedVariety = filter(this.props.varieties, { id: this.state.fields.varietyId.value })[0];
      if (selectedVariety && selectedVariety.epr && this.state.fields.tonnage.value) {
        return parseFloat(this.state.fields.tonnage.value * selectedVariety.epr.rate).toFixed(2);
      }
    }
  }

  maxCarry() {
    const newState = { ...this.state };
    var periodMultiplier = null;

    if (this.state.fields.carryEndDate.value) {
      if (this.state.fields.carryFrequency.value === 'Monthly') {
        periodMultiplier = Math.ceil(
          moment(this.state.fields.carryEndDate.value).diff(moment(this.state.fields.carryStartDate.value), 'months', true),
        );
      } else if (this.state.fields.carryFrequency.value === 'Fortnightly') {
        periodMultiplier = Math.ceil(
          moment(this.state.fields.carryEndDate.value).diff(moment(this.state.fields.carryStartDate.value), 'weeks', true) / 2,
        );
      } else if (this.state.fields.carryFrequency.value === 'Weekly') {
        periodMultiplier = Math.ceil(
          moment(this.state.fields.carryEndDate.value).diff(moment(this.state.fields.carryStartDate.value), 'weeks', true),
        );
      }
    }
    let maxCarryValue = parseFloat(this.getQuantity()) * parseFloat(this.state.fields.carryRate.value) * periodMultiplier;
    maxCarryValue = isNaN(maxCarryValue) ? null : parseFloat(maxCarryValue).toFixed(2);
    newState.fields.carryMax.value = maxCarryValue;
    this.setState(newState);
  }

  currentCarry() {
    const newState = { ...this.state };
    var periodMultiplier = null;
    const today = moment().format('YYYY-MM-DD');
    const carryEndDate = this.state.fields.carryEndDate.value;
    const endDate = carryEndDate && carryEndDate < today ? carryEndDate : today;

    if (endDate) {
      if (this.state.fields.carryFrequency.value === 'Monthly') {
        periodMultiplier = Math.ceil(moment(endDate).diff(moment(this.state.fields.carryStartDate.value), 'months', true));
      } else if (this.state.fields.carryFrequency.value === 'Fortnightly') {
        periodMultiplier = Math.ceil(moment(endDate).diff(moment(this.state.fields.carryStartDate.value), 'weeks', true) / 2);
      } else if (this.state.fields.carryFrequency.value === 'Weekly') {
        periodMultiplier = Math.ceil(moment(endDate).diff(moment(this.state.fields.carryStartDate.value), 'weeks', true));
      }
    }
    let currentCarryValue = parseFloat(this.getQuantity()) * parseFloat(this.state.fields.carryRate.value) * periodMultiplier;
    currentCarryValue = isNaN(currentCarryValue) || currentCarryValue < 0 ? 0 : parseFloat(currentCarryValue).toFixed(2);
    newState.fields.carryCurrent.value = currentCarryValue;
    this.setState(newState);
  }

  calculateCarryValues = () => {
    this.currentCarry();
    this.maxCarry();
  };

  handleMarketZoneChange(value, targetId) {
    const newState = { ...this.state };
    const marketZone = find(this.props.marketZonesWithRegions, { id: value });
    if (isEmpty(marketZone) || isEmpty(marketZone['regions'])) {
      newState.regions = [];
      newState.fields.regionId.validators = [];
      newState.fields.regionId.errors = [];
    } else {
      newState.regions = marketZone['regions'];
      newState.fields.regionId.validators = [required()];
    }
    newState.fields.regionId.value = null;
    newState.fields.marketZoneId.value = value;
    this.setState(newState, () => this.setFieldErrors(targetId, value));
  }

  handleVarietyChange(value, id) {
    value = value || null;
    if (this.state.fields.varietyId.value !== value) {
      this.setFieldValue(id, value);
      this.setFieldErrors(id, value);
      if(!this.isEditingContract && !this.countryConfig?.contracts?.priceAdjustment)
        this.setFieldValue('spread', '');
    }
  }

  handleGradeChange(valueObject, id) {
    if (this.state.fields.gradeId.value != valueObject.id) {
      this.setFieldValue(id, valueObject.id);
      this.setFieldErrors(id, valueObject.id);
      if(!this.countryConfig?.contracts?.priceAdjustment)
        this.setFieldValue('spread', '');
    }
  }

  toggleSpreadValidation(newState) {
    if (newState.fields.typeId.value === CONTRACT_TYPES.MULTI_GRADE) {
      newState.fields.spread.validators = [required()];
    } else {
      newState.fields.spread.validators = [];
    }
  }

  async addConsigneesConsignorsFields(siteType, partyWithSites) {
    return new Promise(resolve => {
      const newState = { ...this.state };
      times(partyWithSites.length - 1, () => {
        let party = siteType == 'consignees' ? cloneDeep(this.consigneeFields) : cloneDeep(this.consignorFields);
        party.handlerId.validators = [];
        newState.fields[siteType].push(party);
        siteType == 'consignors' ? newState.consignorsSites.push([]) : newState.consigneesSites.push([]);
      });
      this.setState(newState, () => {
        resolve();
      });
    });
  }

  isSelectedContractSellerRepresentedByCurrentUser() {
    if(
      get(
        this.selectedContract, 'seller.representedById'
      ) === this.props.currentUser.companyId ||
      get(
        this.selectedContract, 'documentType.name'
      ) === 'broker_note'
    ) {
      return true;
    } else {
      const seller = find(
        this.props.allCompanyParties, {id: get(this.selectedContract, 'seller.companyId')}
      );

      return get(seller, 'isManagedByUser');
    }
  }

  isSelectedContractBuyerRepresentedByCurrentUser() {
    if(
      get(
        this.selectedContract, 'buyer.representedById'
      ) === this.props.currentUser.companyId ||
      get(
        this.selectedContract, 'documentType.name'
      ) === 'contract'
    ) {
      return true;
    } else {
      const buyer = find(
        this.props.allCompanyParties, {id: get(this.selectedContract, 'buyer.companyId')}
      );

      return get(buyer, 'isManagedByUser');
    }
  }

  async fetchDistance() {
    if (get(this.state, 'fields.consignees.0.item') && get(this.state, 'fields.consignors.0.item')) {
      let consignorAddressId = get(this.state, 'fields.consignors.0.item.addressId');
      let consigneeAddressId = get(this.state, 'fields.consignees.0.item.addressId');
      if (consignorAddressId && consigneeAddressId) {
        await APIService.location(consignorAddressId).get().then(
          async (consignorAddress) => {
            await APIService.location(consigneeAddressId).get().then(
              async (consigneeAddress) => {
                const consignorLat = consignorAddress.latitude;
                const consigneeLat = consigneeAddress.latitude;
                const consignorLng = consignorAddress.longitude;
                const consigneeLng = consigneeAddress.longitude;
                if (this.shouldFetchDistance && consignorLat && consigneeLat && consignorLng && consigneeLng) {
                  this.shouldFetchDistance = false;
                  let data = {
                    'origin_latitude': consignorLat,
                    'origin_longitude': consignorLng,
                    'destination_latitude': consigneeLat,
                    'destination_longitude': consigneeLng
                  }
                  let response = await APIService.farms().appendToUrl('geo-distance/').post(data);
                  this.setDistance(response);
                }
              });
          }
        );
      }
    }
  }

  setDistance(response) {
    if (get(response, 'status') == 'OK') {
      const distanceInKms = parseFloat(response['distance'] ? response['distance']['value'] / 1000 : 0).toFixed(2);
      this.setDistanceAndTimeState(distanceInKms);
    } else {
      this.resetDistanceAndTimeState();
    }
  }

  setDistanceAndTimeState(distance) {
    this.setState({
      totalDistance: distance,
    });
  }

  resetDistanceAndTimeState() {
    this.setState({
      totalDistance: '',
    });
  }

  isCurrentUserParty(contract, party) {
    const { companyId } = this.props;

    if(contract)
      return get(contract, `${party}.companyId`) === companyId;

    return get(this.state.fields, `${party}.companyId.value`) === companyId;
  }

  isCurrentUserAnyParty() {
    const { companyId } = this.props;
    return (
      this.state.fields.seller.companyId.value === companyId ||
      this.state.fields.buyer.companyId.value === companyId
    );
  }

  isCurrentUserRepresentingAnyParty() {
    return this.state.representingBuyer || this.state.representingSeller;
  }

  isCurrentUserInvolvedInAnyParty() {
    return this.isCurrentUserAnyParty() || this.isCurrentUserRepresentingAnyParty();
  }

  async setAllFieldsValuesBySelectedContract(contract) {
    if (!isEmpty(contract)) {
      const newState = { ...this.state };
      this.selectedContract = contract;
      newState.isCashPriced = get(contract, 'isCashPriced');
      newState.currency = get(contract, 'currency')
      if(contract.sustainableCommodity)
        newState.sustainableCommodity = get(contract, 'sustainableCommodity');
      if(this.isEditingContract)
        this.isAllocatedContract = get(contract, 'isPurchaseContract') || get(contract, 'isSaleContract');
      if (!isCurrentUserBroker()) {
        newState.representingSeller = this.isCurrentUserParty(contract, 'seller');
        newState.representingBuyer = this.isCurrentUserParty(contract, 'buyer');
      } else {
        newState.representingSeller = get(this.selectedContract, 'seller.representedById') === this.props.currentUser.companyId;
        newState.representingBuyer = get(this.selectedContract, 'buyer.representedById') === this.props.currentUser.companyId;
      }
      if (contract.marketZoneId) {
        if (!isEmpty(this.props.marketZonesWithRegions)) {
          const marketZone = find(this.props.marketZonesWithRegions, { id: contract.marketZoneId });
          if (isEmpty(marketZone) || isEmpty(marketZone['regions'])) {
            newState.regions = [];
            newState.fields.regionId.validators = [];
            newState.fields.regionId.errors = [];
          } else {
            newState.regions = marketZone['regions'];
            newState.fields.regionId.validators = [required()];
          }
        }
      }

      if (this.isEditingContract) {
        const breadcrumbs = [
          { text: 'Contracts', route: '/contracts' },
          { text: contract.referenceNumber, route: `/contracts/${contract.id}/contract` },
          { text: 'Edit' },
        ];
        this.props.dispatch(setBreadcrumbs(breadcrumbs));
        this.props.dispatch(setHeaderText('Edit Contract'));
      }
      forEach(newState.fields, (field, key) => {
        if (
          !includes(
            [
              'seller',
              'buyer',
              'consignees_with_sites',
              'consignors_with_sites',
              'administration',
              'brokerages',
              'spread',
              'identifier',
              'deliveryEndDate',
              'deliveryStartDate',
              'carryStartDate',
              'carryEndDate',
              'area',
            ],
            key,
          )
        ) {
          if (contract[key]) {
            field.value = contract[key];
            field.errors = [];
          }
        }
      });

      this.resetGradeAndSpreadFieldValue(newState);
      this.toggleGradeValidation(newState);
      this.toggleSpreadValidation(newState);
      newState.sellerParties = this.props.allCompanyParties;
      newState.buyerParties = this.props.allCompanyParties;
      ['seller','buyer'].forEach(party => {
        if(get(contract, `${party}.companyId`))
          newState.fields[party].companyId.value = contract[party].companyId;
        const el = document.getElementById('identifier');
        if (el) el.select(); // Temp fix for quick contract focus out
      });
      newState.sellerParties = this.props.allCompanyParties;
      newState.buyerParties = this.props.allCompanyParties;
      if (!isEmpty(contract.buyer)) {
        this.getContractTypes(newState);
      }

      const brokerages = contract.brokeragesForUser || contract.brokerages;
      if (brokerages && newState.fields.brokerages.length == 1 && brokerages.length > newState.fields.brokerages.length) {
        times(brokerages.length - 1, () => {
          var brokerageFields = cloneDeep(this.brokerageFields);
          newState.fields.brokerages.push(brokerageFields);
        });
      }
      newState.fields.crmNotes = {
        description: get(contract, 'note.description', ''),
        attachments: get(contract, 'note.attachments', []),
        companyId: this.props.companyId,
      };
      if (!isEmpty(brokerages)) {
        forEach(brokerages, brokerage => {
          if (brokerage.type) {
            if (brokerages.length == 2) {
              newState.fields.payableBy.value = 'Seller And Buyer';
            } else {
              newState.fields.payableBy.value = brokerage.type;
            }
          }
        });
      }
      setTimeout(() => {
        const newState = { ...this.state };
        if (!isEmpty(brokerages)) {
          forEach(brokerages, (brokerage, index) => {
            if (brokerage.type) {
              if (newState.fields.brokerages.length >= 1) {
                var brokerageFields = cloneDeep(this.brokerageFields);
                map(brokerageFields, (field, key) => {
                  if (brokerage[key]) {
                    if (key == 'feeType' && brokerage[key] === 'Flat Fee') {
                      newState.fields.brokerages[index].rate.validators = [];
                    }
                    newState.fields.brokerages[index][key].value = brokerage[key];
                  }
                });
              }
            }
          });
        } else {
          newState.fields.payableBy.value = 'Nil';
          newState.fields.brokerages = [];
        }
        this.setState(newState);
      }, 1000);
      this.setState(newState, () => this.toggleCarryStartEndDateValidation());

      if (!isEmpty(contract.administration)) {
        this.fetchBrokerEmployees = true;
        await this.getBrokerCompanyEmployees(contract.administration.brokeredById);
        newState.fields.administration.invoicing.value = contract.administration.invoicing;
        newState.fields.administration.brokeredById.errors = [];
        newState.fields.administration.brokeredById.value = contract.administration.brokeredById;
        newState.fields.administration.transferOwnershipToId.value = contract.administration.transferOwnershipToId;
        newState.fields.administration.brokerContactId.value = contract.administration.brokerContactId;
      }

      if (!isEmpty(contract.area)) {
        newState.fields.area.area.value = contract.area.area;
        newState.fields.area.estimatedYield.value = contract.area.estimatedYield;
        if (this.isEditingContract) newState.fields.area.priceBy.value = contract.area.priceBy;
        if (moment(newState.fields.area.priceBy) > moment()) {
          newState.fields.price.validators = [];
        } else {
          newState.fields.price.validators = [required(), minValue(0.01)];
        }
        newState.fields.area.area.validators = [required()];
        newState.fields.area.estimatedYield.validators = [required()];
        newState.fields.area.priceBy.validators = [required()];
      }

      if (!isEmpty(contract.seller)) {
        const selectedSeller = contract.seller;
        await this.getSellerNgrs(selectedSeller.companyId);
        await this.getContacts(selectedSeller.company, 'seller', null, selectedSeller.representedById);
        const newState = { ...this.state };
        if (selectedSeller) {
          newState.selectedSeller = get(selectedSeller, 'company');
          newState.sellerEstablishmentDetails.company = get(selectedSeller, 'company');
          if(this.isEditingContract){
            newState.fields.seller.companyId.value = selectedSeller.companyId;
          }
        }
        if (selectedSeller.contactId && !isEmpty(this.state.sellerContacts)) newState.fields.seller.contactId.value = selectedSeller.contactId;
        if (selectedSeller.ngrId && !isEmpty(this.state.sellerNgrs)) newState.fields.seller.ngrId.value = selectedSeller.ngrId;
        this.setState(newState);
      }

      if (!isEmpty(contract.buyer)) {
        const selectedBuyer = contract.buyer;
        await this.getBuyerNgrs(selectedBuyer.companyId);
        await this.getContacts(selectedBuyer.company, 'buyer', null, selectedBuyer.representedById);
        const newState = { ...this.state };
        if (selectedBuyer) {
          newState.selectedBuyer = get(selectedBuyer, 'company');
          newState.buyerEstablishmentDetails.company = get(selectedBuyer, 'company');
          if(this.isEditingContract)
            newState.fields.buyer.companyId.value = selectedBuyer.companyId;
        }
        if (selectedBuyer.contactId && !isEmpty(this.state.buyerContacts)) newState.fields.buyer.contactId.value = selectedBuyer.contactId;
        if (selectedBuyer.ngrId && !isEmpty(this.state.buyerNgrs)) newState.fields.buyer.ngrId.value = selectedBuyer.ngrId;

        this.getContractTypes(newState);
        this.setState(newState, () => {
          this.handleTypeIdFieldChange(contract.typeId, 'typeId', false);
        });
      }

      if (!isEmpty(contract.consignorsWithSites)) {
        await this.addConsigneesConsignorsFields('consignors', contract.consignorsWithSites);
        for (var index in contract.consignorsWithSites) {
          const consignor = contract.consignorsWithSites[index];
          const selectedConsignor = consignor.handler;
          if (selectedConsignor) {
            this.handleConsigneesConsignorsChange(selectedConsignor, index, false, 'consignors');
            let ld = isNumber(get(consignor, 'ld')) ? get(consignor, 'ld') : selectedConsignor.ld;
            if (ld === 0) {
              ld = '0'; // to fix label overlapping in TextField
            }
            this.handleConsigneesConsignorsSiteLDChange(ld, `consignors[${index}].ld`, index, 'consignors');
          }
        }
      }

      if (!isEmpty(contract.consigneesWithSites)) {
        await this.addConsigneesConsignorsFields('consignees', contract.consigneesWithSites);
        for (var i in contract.consigneesWithSites) {
          const consignee = contract.consigneesWithSites[i];
          const selectedConsignee = consignee.handler;
          if (selectedConsignee) {
            this.handleConsigneesConsignorsChange(selectedConsignee, i, false, 'consignees');
            let ld = isNumber(get(consignee, 'ld')) ? get(consignee, 'ld') : selectedConsignee.ld;
            if (ld === 0) {
              ld = '0'; // to fix label overlapping in TextField
            }
            this.handleConsigneesConsignorsSiteLDChange(ld, `consignees[${i}].ld`, i, 'consignees');
          }
        }
      }
      if (contract.spreadId) {
        this.handleSpreadGradesChange(contract.spread.details);
      }
      if (contract.regionId) {
        newState.fields.regionId.value = contract.regionId;
      }
    }
    this.applyingTemplate = false;
    this.setDefaultValues = true;
    const newState = { ...this.state };
    newState.resetAllFieldValues = false;
    newState.applyRule = true;
    if (this.isEditingContract || this.isDuplicatingContract) {
      newState.fields.deliveryEndDate.value = contract.deliveryEndDate;
      newState.fields.deliveryStartDate.value = contract.deliveryStartDate;
      newState.fields.carryStartDate.value = contract.carryStartDate;
      newState.fields.carryEndDate.value = contract.carryEndDate;
      const existingContractStatus = get(contract, 'statusDisplayName');
      if(!(existingContractStatus == 'Void') && this.isDuplicatingContract){
        newState.fields.identifier.value = generateUpdatedIdentifier(contract.identifier);
        newState.fields.contractIdentifier.value = generateUpdatedIdentifier(contract.contractNumber);
      }
      else {
        newState.fields.identifier.value = contract.identifier;
        newState.fields.contractIdentifier.value = contract.contractNumber;
      }

      newState.fields.contractDate.value = contract.contractDate.substring(0, 10)
    }
    else {
      newState.fields.contractDate.value = moment().format(DATE_DB_FORMAT);
    }

    if (this.isEditingContract || this.isDuplicatingContract) {
      if (get(newState.fields, 'commodityId.value') === COMMODITIES.CANOLA) {
        newState.paymentScales = this.props.paymentScales;
      } else {
        newState.paymentScales = compact([find(this.props.paymentScales, { id: PAYMENT_SCALES.FLAT })]);
      }
      newState.fields.paymentScaleId.value = contract.paymentScaleId
    }
    // if(contract.consignor)
    //   newState.selectedConsignor = {id: contract.consignor.handlerId, name: contract.consignor.handler.displayName};
    this.setState(newState, () => {
      this.props.dispatch(forceStopLoader());
      this.fetchBrokerEmployees = true;
      this.shouldFetchDistance = true;
      this.isDuplicatingContract = false;
      this.calculateCarryValues();
    });
  }

  resetAllFieldsValues(newState) {
    const identifier = newState.fields.identifier.value;
    newState.fields = cloneDeep(this.fields);
    newState.selectedBuyer = undefined;
    newState.selectedSeller = undefined;
    newState.selectedConsignor = undefined;
    newState.fields.identifier.value = identifier;
  }

  handleSaveButtonClick() {
    const newState = { ...this.state };
    newState.templateDialog = true;
    newState.templateName.value = null;
    newState.templateName.errors = [];
    this.setState(newState);
  }

  handleTemplateDialogClose() {
    this.setState({
      ...this.state,
      templateDialog: false,
    });
  }

  handleTemplateNameChange(event) {
    const newState = { ...this.state };
    newState.templateName.value = event.target.value;
    const targetId = event.target.id;
    this.setState(newState, () => this.setTemplateNameFieldErrors(targetId, this.state.templateName.value));
  }

  saveTemplate() {
    const newState = { ...this.state };
    const templateNameValidator = newState.templateName.validators[0];
    if (templateNameValidator.isInvalid(newState.templateName.value)) {
      newState.templateName.errors.push(templateNameValidator.message);
      this.setState(newState);
    } else {
      const data = mapValues(newState.fields, field => {
        return field.value;
      });

      [
        'commodityId',
        'conveyanceId',
        'documentTypeId',
        'gradeId',
        'inspectionId',
        'marketZoneId',
        'packagingId',
        'paymentScaleId',
        'paymentTermId',
        'pricePointId',
        'regionId',
        'toleranceId',
        'track',
        'typeId',
        'varietyId',
        'weightId',
      ].forEach(field => {
        if (data[field] === VALUE_NOT_SELECTED) {
          delete data[field];
        }
      });
      data.brokerages = [];
      newState.fields.brokerages.forEach(brokerage => {
        data.brokerages.push(
          mapValues(brokerage, field => {
            return field.value ? field.value : null;
          }),
        );
      });

      data.brokerages.forEach(b => {
        if (isEmpty(b.feeType)) data.brokerages = reject(data.brokerages, { type: b.type });
      });

      if (isEmpty(data.brokerages)) delete data.brokerages;

      ['seller', 'buyer', 'administration'].forEach(party => {
        data[party] = mapValues(newState.fields[party], field => {
          return field.value === VALUE_NOT_SELECTED ? null : field.value;
        });
      });

      const consigneeData = this.getConsigneesData();
      if (!isEmpty(consigneeData)) {
        data.consignees = consigneeData;
      }

      const consignorData = this.getConsignorsData();
      if (!isEmpty(consigneeData)) {
        data.consignors = consignorData;
      }

      delete data.payableBy;
      if (!isEmpty(data.spread)) {
        data.spread = this.getSpreadDataToSubmit(data.spread);
      } else {
        delete data.spread;
      }
      if (has(data, 'carryCurrent')) delete data.carryCurrent;
      if (has(data, 'carryMax')) delete data.carryMax;
      if (isNaN(parseFloat(data.carryRate)) || data.carryRate == 0) {
        delete data.carryRate;
        delete data.carryFrequency;
        delete data.carryStartDate;
        delete data.carryEndDate;
      }
      data['status'] = 'template';
      data['templateName'] = newState.templateName.value;
      data['sustainableCommodity'] = newState.sustainableCommodity;
      newState.templateDialog = false;
      newState.templateNameSelector.value = null;
      delete data.identifier;
      if (data['contractIdentifier'] !== null) {
        data['contractNumber'] = data['contractIdentifier'];
      }
      delete data['contractIdentifier'];
      this.setState(newState);
      this.setRepresentedByCurrentUser(data);
      delete data.contractNumber;
      const { dispatch } = this.props;
      dispatch(create(data));
    }
  }

  setRepresentedByCurrentUser(data) {
    if (this.state.representingBuyer) {
      set(data, 'buyer.representedById', this.props.currentUser.companyId);
    } else {
      if (get(this.state.selectedBuyer, 'brokerCompanyId')) {
        set(data, 'buyer.representedById', get(this.state.selectedBuyer, 'brokerCompanyId'));
      } else if (has(data, 'buyer.representedById')) {
        delete data.buyer.representedById;
      }
    }
    if(!isNumber(get(data, 'buyer.companyId'))) {
      delete data.buyer;
    }
    if (this.state.representingSeller) {
      set(data, 'seller.representedById', this.props.currentUser.companyId);
    } else {
      if (get(this.state.selectedSeller, 'brokerCompanyId')) {
        set(data, 'seller.representedById', get(this.state.selectedSeller, 'brokerCompanyId'));
      } else if (has(data, 'seller.representedById')) {
        delete data.seller.representedById;
      }
    }
    if(!isNumber(get(data, 'seller.companyId'))) {
      delete data.seller;
    }

    if (!isCurrentUserBroker()) {
      const isSellerCreator = this.props.companyId === (get(this.state.selectedSeller, 'companyId') || get(this.state.selectedSeller, 'id'));
      const isBuyerCreator = this.props.companyId === (get(this.state.selectedBuyer, 'companyId') || get(this.state.selectedBuyer, 'id'));

      if (isSellerCreator) {
        data.seller['representedById'] = this.state.fields.administration.brokeredById.value;
      }
      if (isBuyerCreator) {
        data.buyer['representedById'] = this.state.fields.administration.brokeredById.value;
      }
    }
  }

  valid(fields) {
    return every(fields, field => {
      if (has(field, 'errors')) return field.errors.length === 0;
      else return true;
    });
  }

  getConsignorsData = () => {
    var consignors = [];
    forEach(this.state.fields.consignors, item => {
      const handlerId = get(item, 'handlerId.value');
      var consignor = undefined;
      if (handlerId) {
        consignor = {
          handlerId: handlerId,
          position: get(item, 'position', 1),
          ld: get(item, 'ld.value'),
        };
        consignors.push(consignor);
      }
    });
    return consignors;
  };

  getConsigneesData = () => {
    var consignees = [];
    forEach(this.state.fields.consignees, item => {
      const handlerId = get(item, 'handlerId.value');
      var consignee = undefined;
      if (handlerId) {
        consignee = {
          handlerId: handlerId,
          position: get(item, 'position', 1),
          ld: get(item, 'ld.value'),
        };
        consignees.push(consignee);
      }
    });
    return consignees;
  };

  isPastContract() {
    return this.isDateBefore(this.state.fields.deliveryEndDate.value);
  }

  isRepresentingBothParties() {
    return this.state.representingSeller && this.state.representingBuyer;
  }

  canOthersCreateContractWithAcceptanceRequired = contractRestriction => contractRestriction === OTHERS_CAN_CREATE_BUT_REQUIRES_ACCEPTANCE

  currentUserNotBuyerAndCanCreateContractWithAcceptanceRequired = () => this.canOthersCreateContractWithAcceptanceRequired(get(this.buyerOfContract(), 'purchaseContractCreationRestriction')) && !isEqual(get(currentUserCompany(), 'id'), get(this.buyerOfContract(), 'id'))

  currentUserNotSellerAndCanCreateContractWithAcceptanceRequired = () => this.canOthersCreateContractWithAcceptanceRequired(get(this.sellerOfContract(), 'saleContractCreationRestriction')) && !isEqual(get(currentUserCompany(), 'id'), get(this.sellerOfContract(), 'id'))

  acceptanceRequiredForCreation = () => !this.isPastContract() && !this.isRepresentingBothParties() && !this.currentUserNotBuyerAndCanCreateContractWithAcceptanceRequired() && !this.currentUserNotSellerAndCanCreateContractWithAcceptanceRequired();

  acceptanceRequiredForAmend() {
    if(get(this.selectedContract, 'status') === 'planned')
      return false;
    if(this.isRepresentingBothParties())
      return false;
    if(this.isOnlyRepresentingPartyBrokerageChanged())
      return false;
    if(this.amIRepresented() && this.isOnlyMyBrokerageChanged())
      return false;
    if(this.currentUserNotBuyerAndCanCreateContractWithAcceptanceRequired() || this.currentUserNotSellerAndCanCreateContractWithAcceptanceRequired())
      return false;
    return true;
  }

  isRepresentingAnyParty() {
    return this.state.representingBuyer || this.state.representingSeller;
  }

  isOnlyRepresentingPartyBrokerageChanged() {
    if(this.isRepresentingAnyParty() && this.editData) {
      const representingParty = this.state.representingSeller ? 'Seller' : 'Buyer';
      return this.isOnlyPartyBrokerageChangedInAmend(representingParty);
    }
  }

  isOnlyPartyBrokerageChangedInAmend(party) {
    if(party && isEqual(without(keys(this.editData), 'levy', 'carryCurrent'), ['brokerages'])) {
      if(size(this.editData.brokerages) === 1)
        return get(this.editData.brokerages, '0.type') === party;
      else if (size(this.editData.brokerages) === 2) {
        const existingCounterPartyBrokerage = pick(
          get(
            reject(
              this.selectedContract.brokeragesForUser, {type: party}
            ), '0'
          ), ['type', 'chargedOn', 'feeType', 'rate', 'totalFee']
        );
        return isEqual(
          existingCounterPartyBrokerage,
          find(this.editData.brokerages, {type: get(existingCounterPartyBrokerage, 'type')})
        );
      }
      return true;
    }
  }

  amIRepresented() {
    const contract = this.selectedContract;
    return contract && (
      (contract.isBuyer && get(contract, 'buyer.representedById')) ||
      (contract.isSeller && get(contract, 'seller.representedById'))
    );
  }

  isOnlyMyBrokerageChanged() {
    const contract = this.selectedContract;
    if(!contract || !this.editData)
      return;

    let party;

    if(contract.isBuyer)
      party = 'Buyer';
    else if(contract.isSeller)
      party = 'Seller';

    return this.isOnlyPartyBrokerageChangedInAmend(party);
  }

  isAcceptanceDisabled() {
    if(this.isEditingContract)
      return !this.acceptanceRequiredForAmend();

    return !this.acceptanceRequiredForCreation();
  }

  getDisabledPartiesForEmail() {
    let disabledParties = [];
    if(this.currentUserNotBuyerAndCanCreateContractWithAcceptanceRequired())
      disabledParties.push('buyer');
    if(this.currentUserNotSellerAndCanCreateContractWithAcceptanceRequired())
      disabledParties.push('seller');
    return disabledParties;
  }

  alertContractTypeChangeIfConnectedEntitiesExist() {
    let message =`The following entities have different grade than the base grade in the linked contract. Please amend/void them first to make this change or change the base grade.\n`;

    get(this.selectedContract, 'freightOrdersGradeOtherThanContractBaseGrade').forEach((order) => {
      message += `${order.identifier}\t\t-\t\t${order.grade}\n`;
    })

    get(this.selectedContract, 'freightContractsGradeOtherThanContractBaseGrade').forEach((movement) => {
      message += `${movement.identifier}\t\t-\t\t${movement.grade}\n`;
    });

    get(this.selectedContract, 'titleTransfersGradeOtherThanContractBaseGrade').forEach((tt) => {
      message+=`${tt.identifier}\t\t-\t\t${tt.grade}\n`
    })
    alertifyjs.error(message)
  }

  buyerOfContract() {
    return this.isEditingContract ? get(this.selectedContract,'buyer.company') : this.getCompanyById(this.state.fields.buyer.companyId.value)
  }

  sellerOfContract() {
    return this.isEditingContract ? get(this.selectedContract,'seller.company') : this.getCompanyById(this.state.fields.seller.companyId.value)
  }

  othersCannotCreateContract = contractRestriction => contractRestriction === OTHERS_CANNOT_CREATE

  isCurrentUserNotBuyerAndIsPurchaseContract = () => this.othersCannotCreateContract(get(this.buyerOfContract(), 'purchaseContractCreationRestriction')) && !isEqual(get(currentUserCompany(), 'id'), get(this.buyerOfContract(), 'id'))

  isCurrentUserNotSellerAndIsSaleContract = () => this.othersCannotCreateContract(get(this.sellerOfContract(), 'saleContractCreationRestriction')) && !isEqual(get(currentUserCompany(), 'id'), get(this.sellerOfContract(), 'id'))

  isCurrentUserNotSellerOrBuyerAndIsSalePurchaseContract() {
    return ((this.othersCannotCreateContract(get(this.buyerOfContract(), 'purchaseContractCreationRestriction')) && !isEqual(get(currentUserCompany(), 'id'), get(this.buyerOfContract(), 'id'))) ||
    (this.othersCannotCreateContract(get(this.sellerOfContract(), 'saleContractCreationRestriction')) && !isEqual(get(currentUserCompany(), 'id'), get(this.sellerOfContract(), 'id'))))
  }

  async handleSubmit(event) {
    if (this.state.openConsigneeFormForFarm || this.state.openConsignorFormForFarm)
      return;
    event.stopPropagation();
    event.preventDefault();
    this.setAllFieldsErrors();
    if (this.isBlended() && !this.state.useSinglePrice && this.state.fields.spread.value.some(item => !item.price)) {
      alertifyjs.error('Invalid grades!', 5);
      return
    }
    if (!this.state.preview) {
      let queryParams = ''
      if (this.isEditingContract && this.selectedContract.id)
        queryParams += `?contract_id=${this.selectedContract.id}`;
      await APIService.contracts().appendToUrl(`${this.state.fields.identifier.value}/identifier-unique/${queryParams}`)
      .get()
      .then(response => {
        this.setState((prevState) => ({
          isUniqueIdentifierErrorExists: Boolean(response?.errors),
          fields: {
            ...prevState.fields,
            identifier: {
              ...prevState.fields.identifier,
              errors: response?.errors ? [response?.errors] : [],
            },
          },
        }));
      });
    }
    this.focusOnFirstErrorField();
    var invalidObjectFields = some(['seller', 'buyer', 'administration', 'area', 'crmNotes'], key => {
      return some(this.state.fields[key], (field, childKey) => {
        if (childKey == 'sites') {
          return some(this.state.fields[key][childKey], (site, index) => {
            return some(this.state.fields[key][childKey][index], cField => {
              return cField.errors.length != 0;
            });
          });
        } else {
          this.handlePreviewClose();
          if(key == 'crmNotes')
            return get(this.state.fields.crmNotes,'errors') && get(this.state.fields.crmNotes,'errors').length != 0
          else
            return has(field, 'errors') && field.errors.length != 0;
        }
      });
    });

    var invalidBasicFields = some(this.state.fields, (field, key) => {
      if (!includes(['seller', 'buyer', 'consignors', 'consignees', 'administration', 'brokerages', 'spread', 'crmNotes', 'area'], key)) {
        return has(field, 'errors') && field.errors.length != 0;
      } else {
        this.handlePreviewClose();
        return false;
      }
    });
    var hasValidConsignees = every(this.state.fields.consignees, field => this.valid(field))
    var hasValidConsignors = every(this.state.fields.consignors, field => this.valid(field))

    var invalidBrokerages = some(this.state.fields.brokerages, brokerage => {
      this.handlePreviewClose();
      return some(brokerage, field => {
        return field.errors.length != 0;
      });
    });
    if (!(invalidObjectFields || invalidBasicFields || invalidBrokerages || this.state.fields.spread.errors.length != 0) && hasValidConsignees && hasValidConsignors) {
      let data = []
      forEach(this.state.fields, (field, key) => {
        data[key] = field.value
      });
      data.sustainableCommodity = this.state.sustainableCommodity
      data.crmNotes = this.state.fields.crmNotes;
      data.brokerages = [];
      data.currency = this.state.currency;
      if (!data.blendingFee)
        delete data.blendingFee;
      this.state.fields.brokerages.forEach(brokerage => {
        data.brokerages.push(
          mapValues(brokerage, field => {
            return field.value || field.value === 0 ? field.value : null;
          }),
        );
      });
      ['seller', 'buyer', 'administration', 'area'].forEach(party => {
        data[party] = mapValues(this.state.fields[party], field => {
          return field.value;
        });
      });
      const consigneeData = this.getConsigneesData();
      if (!isEmpty(consigneeData)) {
        data.consignees = consigneeData;
      }
      const consignorData = this.getConsignorsData();
      if (!isEmpty(consignorData)) {
        data.consignors = consignorData;
      }
      data.levy = this.levy();
      delete data.payableBy;
      delete data.chargedOn;
      if (!isEmpty(data.spread)) {
        if (this.isBlended() && this.state.useSinglePrice) {
          data.spread = data.spread.map(spreadGrade => {
            spreadGrade.gist = `${spreadGrade.gradeName}: ${spreadGrade.quantity}${this.priceUnit()}`
            return spreadGrade;
          });
        }
        data.spread = this.getSpreadDataToSubmit(data.spread);
      }
      data.useContractPriceForInvoicing = this.state.useSinglePrice;

      if (has(data, 'carryCurrent')) delete data.carryCurrent;
      if (has(data, 'carryMax')) delete data.carryMax;
      const selectedDocumentType = find(this.props.documentTypes, { id: data.documentTypeId });
      let deliveryEndDateErrors = undefined;
      if (selectedDocumentType && !this.isEditingContract) {
        const startDate = this.isDateBefore(data.deliveryStartDate);
        const pastDate = this.isDateBefore(data.deliveryEndDate);
        if (selectedDocumentType.name !== 'contract') {
          if (startDate && pastDate) {
            deliveryEndDateErrors = "Delivery Start Date and End Date can't be in past";
          } else if (pastDate) {
            deliveryEndDateErrors = "Delivery End Date can't be in past";
          }
        }
      }
      let isEntitiesConnectedToContract = !isEmpty(get(this.selectedContract, 'freightOrdersGradeOtherThanContractBaseGrade')) || !isEmpty(get(this.selectedContract, 'freightContractsGradeOtherThanContractBaseGrade')) ||
      !isEmpty(get(this.selectedContract, 'titleTransfersGradeOtherThanContractBaseGrade'));
      let isSelectedTypeMultiOrFloating = includes([CONTRACT_TYPES.MULTI_GRADE, CONTRACT_TYPES.FLOATING_MULTI_GRADE], get(this.selectedContract, 'typeId'))

      if (deliveryEndDateErrors) {
        alertifyjs.error(deliveryEndDateErrors);
      } else if (this.isCurrentUserNotBuyerAndIsPurchaseContract()) {
          alertifyjs.alert(
            'Warning',
            `Contract Creation/Amending with ${get(this.buyerOfContract(), 'name')} as Buyer is disabled. Please Contact ${get(this.buyerOfContract(), 'name')} for Contract Creation/Amending`)
      } else if (this.isCurrentUserNotSellerAndIsSaleContract()) {
          alertifyjs.alert(
            'Warning',
            `Contract Creation/Amending with ${get(this.sellerOfContract(), 'name')} as Seller is disabled. Please Contact ${get(this.sellerOfContract(), 'name')} for Contract Creation/Amending`)
      } else if (this.isCurrentUserNotSellerOrBuyerAndIsSalePurchaseContract()) {
        if (this.othersCannotCreateContract(get(this.buyerOfContract(), 'purchaseContractCreationRestriction')))
          alertifyjs.alert(
            'Warning',
            `Contract Creation with ${get(this.buyerOfContract(), 'name')} is disabled. Please Contact ${get(this.buyerOfContract(), 'name')} for Contract Creation/Amending`)
        else
          alertifyjs.alert(
            'Warning',
            `Contract Creation with ${get(this.sellerOfContract(), 'name')} is disabled. Please Contact ${get(this.sellerOfContract(), 'name')} for Contract Creation/Amending`)
      }
      else if (!this.isEditingContract && !this.isCurrentUserInvolvedInAnyParty()) {
        alertifyjs.error('You should be one of the parties (Seller or Buyer) or represent one of the parties for creating this contract.', 10);
      } else if (this.isEditingContract && isSelectedTypeMultiOrFloating && includes([CONTRACT_TYPES.FIXED_GRADE], this.state.fields.typeId.value) && isEntitiesConnectedToContract) {
        this.alertContractTypeChangeIfConnectedEntitiesExist();
      }
      else if(this.isEditingContract && get(this.selectedContract, 'hasValidCommodityContractInvoice') && (get(data, 'carryRate') || get(data, 'carryStartDate') || get(data, 'carryEndDate')) && !this.state.preview && !this.state.showCustomEmailDialog) {
        alertifyjs.confirm(
          'Warning',
          "This contract has been partially/fully invoiced. Changes in carry details will not affect items already invoiced.",
          () => {this.handlePreviewClick()},
          () => {}).set('reverseButtons', true);
      }
      else {
        if (this.state.preview) {
          if (data['contractIdentifier'] !== null) {
            data['contractNumber'] = data['contractIdentifier'].toUpperCase();
          }
          delete data['contractIdentifier'];
          if (this.isEditingContract) {
            const editData = this.getContractEditedData(data);
            if (isEmpty(editData) || isEqual(keys(editData), 'communication')) {
              alertifyjs.error('Please update the contract before re-submitting.');
            } else {
              this.editData = editData;
              this.openCustomEmailDialog();
            }
          } else {
            this.submittableData = {...data};
            this.openCustomEmailDialog();
          }
        } else {
          this.handlePreviewClick();
        }
      }
    }
  }

  getSpreadDataToSubmit(spread) {
    return {
      details: map(spread, partialRight(pick, ['id', 'name', 'order', 'value', 'priceVariation', 'gist', 'operator', 'spec', 'adjustmentType', 'adjustmentValue', 'adjustmentPerValue', 'gradeId', 'gradeName', 'price', 'quantity'])).map(item => {
        item.value = item.value ? extractDigits(item.value) : "";
        return item;
      }),
    };
  }

  getContractEditedData = data => {
    const isSpreadDeleted = Boolean(data.typeId != this.selectedContract?.typeId && this.selectedContract.typeId === CONTRACT_TYPES.MULTI_GRADE && data?.spread?.details)
    if(isSpreadDeleted)
      data.spread = null
    forEach(['documentTypeId'], key => {
      if (get(data, key)) {
        delete data[key];
      }
    });

    if(isShallowEqual(get(this.selectedContract, 'typeId'), data.typeId))
      delete data.typeId;

    ['gradeId', 'pricePointId', 'track', 'deliveryOnus', 'conveyanceId'].forEach(key => {
      const fieldValue = get(data, key);
      if (fieldValue) {
        if (get(this.selectedContract, 'accountedTonnage', 0) > 0) {
          delete data[key];
        }
      }
    });

    if(isShallowEqual(get(this.selectedContract, 'season'), data.season))
      delete data.season;


    ['marketZoneId', 'regionId'].forEach(key => {
      if(get(this.selectedContract, key) == get(data, key))
        delete data[key]
    })

     forEach(data, (value, key) => {
      if (
        !includes(['seller', 'buyer', 'consignors', 'consignees', 'administration', 'brokerages', 'spread', 'crmNotes', 'levy', 'marketZoneId', 'regionId'], key) &&
        (get(this.selectedContract, key) == value || this.isFieldDisabled(key))
      ) {
        delete data[key];
      }
    });

    if (has(data, 'seller') && get(this.selectedContract, 'seller')) {
      const seller = {
        companyId: get(this.selectedContract,'seller.companyId'),
        contactId: get(this.selectedContract,'seller.contactId'),
        ngrId: get(this.selectedContract,'seller.ngrId'),
      };

      if(isShallowEqual(seller, data.seller))
        delete data.seller;

      if(has(data, 'seller'))
        delete data.seller.companyId;
    }

    if(has(data, 'consignors')) {
      map(data.consignors, item => {
        item.ld = parseFloat(item.ld).toFixed(2)
      })
    }

    if(has(data, 'consignees')) {
      map(data.consignees, item => {
        item.ld = parseFloat(item.ld).toFixed(2)
      })
    }

    if (has(data, 'seller')) {
      if (this.isFieldDisabled('seller.ngrId')) {
        delete data.seller.ngrId;
      }
      if (this.isFieldDisabled('seller.contactId')) {
        delete data.seller.contactId;
      }
    }

    if (has(data, 'buyer') && get(this.selectedContract, 'buyer')) {
      const buyer = {
        companyId: get(this.selectedContract,'buyer.companyId'),
        contactId: get(this.selectedContract,'buyer.contactId'),
        ngrId: get(this.selectedContract,'buyer.ngrId'),
      };
      if(isShallowEqual(buyer, data.buyer))
        delete data.buyer;

      if(has(data,'buyer'))
        delete data.buyer.companyId;
    }

    if (has(data, 'buyer')) {
      if (this.isFieldDisabled('buyer.ngrId')) {
        delete data.buyer.ngrId;
      }
      if (this.isFieldDisabled('buyer.contactId')) {
        delete data.buyer.contactId;
      }
    }

    if (get(this.selectedContract, 'consigneesWithSites')) {
      const consigneesWithSites = this.selectedContract.consigneesWithSites.map(consignee => ({
        handlerId: consignee.handlerId,
        position: consignee.position,
        ld: parseFloat(consignee.ld).toFixed(2),
      }));
      if (has(data, 'consignees') && isShallowEqual(consigneesWithSites, get(data, 'consignees', []))) {
        delete data.consignees;
        delete data.consignee;
      }

      if (has(data, 'consignee') && consigneesWithSites.length > 0 && isShallowEqual(consigneesWithSites[0], get(data, 'consignee', []))) {
        delete data.consignee;
      }
    }

    if (has(data, 'consignees') && !this.showAddConsigneeConsignorButton('consignees')) {
      delete data.consignees;
    }

    if (get(this.selectedContract, 'consignorsWithSites')) {
      const consignorsWithSites = this.selectedContract.consignorsWithSites.map(consignor => ({
        handlerId: consignor.handlerId,
        position: consignor.position,
        ld: parseFloat(consignor.ld).toFixed(2),
      }));
      if (has(data, 'consignors') && isShallowEqual(consignorsWithSites, get(data, 'consignors', []))) {
        delete data.consignors;
        delete data.consignor;
      }
    }

    if (has(data, 'administration')) {
      const administration = {
        invoicing: get(this.selectedContract, 'administration.invoicing'),
        brokeredById: get(this.selectedContract, 'administration.brokeredById'),
        brokerContactId: get(this.selectedContract, 'administration.brokerContactId'),
        transferOwnershipToId: get(this.selectedContract, 'administration.transferOwnershipToId'),
      };

      if (isShallowEqual(administration, data.administration)) {
        delete data.administration;
      }
    }

    if (has(data, 'administration')) {
      if (this.isFieldDisabled('administration.invoicing')) {
        delete data.administration.invoicing;
      }

      if (data.administration.brokeredById == get(this.selectedContract, 'administration.brokeredById')) {
        delete data.administration.brokeredById;
      }

      if (data.administration.brokerContactId == get(this.selectedContract, 'administration.brokerContactId')) {
        delete data.administration.brokerContactId;
      }
    }

    if (has(data, 'brokerages')) {
      const brokerages = get(this.selectedContract, 'brokeragesForUser', []).map(brokerage => {
        return {
          type: brokerage.type,
          feeType: brokerage.feeType,
          rate: brokerage.rate ? parseFloat(brokerage.rate) : brokerage.rate,
          totalFee: brokerage.totalFee ? parseFloat(brokerage.totalFee) : brokerage.totalFee,
          chargedOn: brokerage.chargedOn,
        };
      });
      if (isShallowEqual(data.brokerages, brokerages) || this.isFieldDisabled('brokerages')) {
        delete data.brokerages;
      }
    }

    if (has(data, 'spread') && !isSpreadDeleted) {
      const spread = this.getSpreadDataToSubmit(get(this.selectedContract, 'spread.details'));
      if (isShallowEqual(data.spread, spread)) {
        delete data.spread;
      }
    }

    if (has(data, 'crmNotes')) {
      if (isEmpty(get(data, 'crmNotes.description')) && isEmpty(get(data, 'crmNotes.attachments'))) {
        delete data.crmNotes;
      }
    }

    if (has(data, 'levy')) {
      const levy = get(this.selectedContract, 'levy');
      if (data.levy ==  levy) {
        delete data.levy;
      }
    }

    if (has(data, 'crmNotes') && this.isFieldDisabled('crmNotes')) {
      delete data.crmNotes;
    }

    if (has(data, 'spread') && this.isFieldDisabled('spread')) {
      delete data.spread;
    }

    if (has(data, 'consignees') && isEmpty(data.consignees)) {
      data.consignees = [];
    }

    if (has(data, 'consignors') && isEmpty(data.consignors)) {
      data.consignors = [];
    }

    if (this.selectedContract.isAreaContract) {
      if (
        has(data, 'area.area') &&
        (this.isFieldDisabled('area') || get(data, 'area.area') === get(this.selectedContract, 'area.area'))
      ) {
        delete data.area.area;
      }

      if (
        has(data, 'area.estimatedYield') &&
        (this.isFieldDisabled('estimatedYield') || get(data, 'area.estimatedYield') === get(this.selectedContract, 'area.estimatedYield'))
      ) {
        delete data.area.estimatedYield;
      }

      if (
        has(data, 'area.priceBy') &&
        (this.isFieldDisabled('priceBy') || get(data, 'area.priceBy') === get(this.selectedContract, 'area.priceBy'))
      ) {
        delete data.area.priceBy;
      }

      if (isEmpty(data.area)) {
        delete data.area;
      }
    } else {
      delete data.area;
    }

    if (data.quantity !== get(this.selectedContract, 'quantity') && this.hasQuantityBasedCommodity())
      data.quantity = this.state.fields.quantity.value;

    if (data.sustainableCommodity != get(this.selectedContract, 'sustainableCommodity'))
      data.sustainableCommodity = this.state.sustainableCommodity;

    let current = {};
    Object.keys(data).forEach(key => {
      let property = {
        'brokerages': 'brokeragesForUser',
        'consignees': 'consigneesWithSites',
        'consignors': 'consignorsWithSites'
      };
      if (has(this.selectedContract, key) || has(this.selectedContract, property[key])) {
        if (typeof data[key] == 'object') {
          if (!data[key] && !this.selectedContract[key])
            delete data[key];
          forEach(data[key], (v, k) => {
            if(get(this.selectedContract[key], k, get(this.selectedContract, `[${property[key]}][${k}]`, false)))
              {
                if (!current[key])
                  current[key] = get(property, key) ? [] : {};
                current[key][k] = get(this.selectedContract[key], k, get(this.selectedContract[property[key]], k, {}));
              }
          });
        }
        else {
          if (isEqual(get(this.selectedContract, key), data[key]))
            delete data[key];
          else
            current[key] = get(this.selectedContract, key);
        }
      }
    });

    let diff = { amended: {...data}, current: current };
    return diff;
  };

  handleConsigneeConsignorInputChange = (event, value, index, siteType) => {
    const field = `${siteType}${index}InputText`;
    this.setState({[field]: value});
  };

  canBrokerAccessAny() {
    let selectedEntity = this.getSelectedBrokerCompany();
    if (!canNotEdit(selectedEntity) && canCreateOrUpdate(this.props.currentUser, 'officeEmployee')) {
      return selectedEntity && (selectedEntity.id === this.props.currentUser.companyId || !selectedEntity.isRegistered);
    }
    return false;
  }

  getSelectedBrokerCompany() {
    return find(this.state.brokerCompanies, { id: this.state.fields.administration.brokeredById.value });
  }

  async getSellerNgrs(sellerId, partyType, newlyCreatedNgr) {
    await APIService.companies(sellerId)
                    .ngrs()
                    .appendToUrl('minimal/')
                    .get(this.props.userToken)
                    .then(items => {
                      const newState = { ...this.state };
                      newState.sellerNgrs = items;
                      if (newlyCreatedNgr) {
                        newState.fields.seller.ngrId.value = newlyCreatedNgr.id;
                      }
                      if (!find(newState.sellerNgrs, { id: newState.fields.seller.ngrId.value })) {
                        newState.fields.seller.ngrId.value = undefined;
                      }
                      this.setState(newState);
                    });
  };

  async getBuyerNgrs(buyerId, partyType, newlyCreatedNgr) {
    await APIService.companies(buyerId)
                    .ngrs()
                    .appendToUrl('minimal/')
                    .get(this.props.userToken)
                    .then(items => {
                      const newState = { ...this.state };
                      newState.buyerNgrs = items;
                      if (newlyCreatedNgr) {
                        newState.fields.buyer.ngrId.value = newlyCreatedNgr.id;
                      }
                      if (!find(newState.buyerNgrs, { id: newState.fields.buyer.ngrId.value })) {
                        newState.fields.buyer.ngrId.value = undefined;
                      }
                      this.setState(newState);
                    });
  };

  handleSellerCompanyIdChange(value, targetId, chosenItem) {
    const isBrokerNote = this.isBrokerNoteDocumentType();
    if(!this.isEditingContract && this.isCurrentUserBrokerAndSameAsParty(chosenItem))
      this.resetBrokeragesAndBrokeredBy();
    else
      this.handleDefaultBrokerageForParty('seller', chosenItem);
    if (isEmpty(this.dontResetSeller)) {
      const newState = { ...this.state };
      newState.sellerChanged = newState.fields.seller.companyId.value !== value;
      if(newState.sellerChanged && newState.fields.seller.companyId.value)
        newState.representingSeller = false
      if(this.setDefaultValues){
        newState.fields.consignor = cloneDeep(this.consignorFields);
        newState.selectedConsignor = undefined;
        newState.selectedConsignorSite = undefined;
      }
      if (newState.applyRule && newState.sellerChanged) {
        newState.fields.seller.contactId.value = undefined;
        newState.fields.seller.ngrId.value = undefined;
      }
      if (this.selectedContract && isCurrentUserBroker())
        newState.representingSeller = isBrokerNote || newState.representingSeller;
      else
        newState.representingSeller = isBrokerNote;
      if(isCurrentUserBroker() && get(chosenItem, 'isManagedByUser')) {
        newState.representingSeller = get(chosenItem, 'isManagedByUser');
      }
      newState.selectedSeller = chosenItem;
      if(chosenItem && value !== VALUE_NOT_SELECTED){
        if(newState.fields.seller.companyId.value !== value){
          newState.fetchSellerParties = true;
          newState.sellerEstablishmentDetails.company = chosenItem;
          if(!this.applyingTemplate && !isEqual(get(this.state.selectedSeller, 'id'), chosenItem.id)) {
            this.getSellerNgrs(value,chosenItem.partyType);
            this.getContacts(chosenItem, 'seller', null, newState.representingSeller);
          }
        }
        newState.fields.seller.companyId.errors = [];
        newState.buyerParties = compact(reject([...this.props.allCompanyParties, this.state.buyerEstablishmentDetails.company], {id: chosenItem.id}))
      } else {
        newState.fields.seller = cloneDeep(this.sellerFields);
        newState.fields.seller.companyId.value = value;
        newState.buyerParties = compact([...this.props.allCompanyParties, this.state.buyerEstablishmentDetails.company]);
        newState.sellerContacts = [];
        newState.sellerNgrs = [];
        newState.fetchSellerParties = false;
      }
      if(newState.sellerChanged)
        newState.fields.seller.companyId.value = value;
      this.setState(newState, this.openSideDrawer);
    }
  }

  async getContacts(company, getContactsFor, newContactId = '', representingParty) {
    if(!company)
      return;
    const representingCompanyId = isNumber(representingParty) ? representingParty : null;
    let isCurrentUserSameAsParty = get(company, 'id') === get(this.props, 'currentUser.companyId');
    let contacts = await APIService.contracts().companies(company.id).contacts().get(
      this.props.userToken,
      {},
      (representingCompanyId ? {representing_company_id: representingCompanyId} : {})
    );
    if(getContactsFor == 'seller'){
      const selectedSeller = get(this.state, 'selectedSeller') || get(this.selectedContract, 'seller.company') || find(this.state.sellerParties, {id: get(this.state.fields, 'seller.companyId.value')});
      if(selectedSeller){
        const selectedSellerCompanyId = get(selectedSeller, 'id');
        if(selectedSellerCompanyId === this.state.currentUser.company.id){
          contacts = uniqBy([{
            id: this.state.currentUser.id,
            name: this.state.currentUser.name,
            email: this.state.currentUser.email
          }].concat(contacts),'id');
        }
      }

      contacts = (!isCurrentUserSameAsParty && isCurrentUserBroker()) ?
                 this.filterContacts(contacts, representingParty || this.state.representingSeller) :
                 contacts;
      contacts = sortBy(contacts, contact => (contact.id === this.state.currentUser.id ? 0 : 1));
      let sellerDefaultSelectedContactId;
      if(newContactId)
        sellerDefaultSelectedContactId = newContactId;
      else if (isCurrentUserSameAsParty && !this.isEditingContract)
        sellerDefaultSelectedContactId = this.state.currentUser.id;
      else
        sellerDefaultSelectedContactId = this.getKeyContactId(contacts)
      this.setState({ sellerContacts: uniqBy(contacts, 'id') }, () => {
        if (sellerDefaultSelectedContactId && this.state.fields.seller.contactId.value !== sellerDefaultSelectedContactId && (newContactId || !this.state.fields.seller.contactId.value)) {
          this.handleSelectFieldChange(sellerDefaultSelectedContactId, 'seller.contactId');
        }
      });
    } else if(getContactsFor == 'buyer'){
      const selectedBuyer = get(this.state, 'selectedBuyer') || get(this.selectedContract, 'buyer.company') || find(this.state.buyerParties, {id: get(this.state.fields, 'buyer.companyId.value')});
      if(selectedBuyer){
        const selectedBuyerCompanyId = get(selectedBuyer,'id');
        if(selectedBuyerCompanyId === this.state.currentUser.company.id){
          contacts = uniqBy([{
            id: this.state.currentUser.id,
            name: this.state.currentUser.name,
            email: this.state.currentUser.email
          }].concat(contacts),'id');
        }
      }
      contacts = (!isCurrentUserSameAsParty && isCurrentUserBroker()) ?
                 this.filterContacts(contacts, representingParty || this.state.representingBuyer) :
                 contacts;
      contacts = sortBy(contacts, contact => (contact.id === this.state.currentUser.id ? 0 : 1));
      let buyerDefaultSelectedContactId;
      if (newContactId)
        buyerDefaultSelectedContactId = newContactId;
      else if (isCurrentUserSameAsParty && !this.isEditingContract)
        buyerDefaultSelectedContactId = this.state.currentUser.id;
      else
        buyerDefaultSelectedContactId = this.getKeyContactId(contacts);
      this.setState({ buyerContacts: uniqBy(contacts, 'id') }, () => {
        if (buyerDefaultSelectedContactId && this.state.fields.buyer.contactId.value !== buyerDefaultSelectedContactId && (newContactId || !this.state.fields.buyer.contactId.value)) {
          this.handleSelectFieldChange(buyerDefaultSelectedContactId, 'buyer.contactId');
        }
      });
    }
  }

  getKeyContactId(contacts) {
    const keyContacts = filter(contacts, { keyContact: true });
    let keyContactId = null;
    if (keyContacts && keyContacts.length === 1) {
      keyContactId = keyContacts[0].id;
    } else {
      const companyKeyContact = find(keyContacts, {companyId: this.state.fields.buyer.companyId.value});
      if(companyKeyContact) {
        keyContactId = companyKeyContact.id;
      }
    }
    return keyContactId;
  }

  handleDefaultBrokerageForParty(partyType, party) {
    const newState = { ...this.state };
    const brokerage = this.getPartyBrokerage(partyType);
    if (
      party &&
      !this.isBrokerageSameAsDefaultForParty(partyType, party) &&
        get(party, 'brokeragesForUser.0') &&
      includes(this.state.fields.payableBy.value.toLowerCase(), partyType) &&
      !this.isPartyDefaultBrokerageIsFlatFee(partyType, party)
    ) {
      if (brokerage && brokerage.feeType !== 'Flat Fee') {
        this.updatePartyBrokerageFeeType(brokerage, party.brokeragesForUser[0].feeType);
        this.updatePartyBrokerageAttributes(brokerage, 'rate', party.brokeragesForUser[0].rate);
        this.updatePartyBrokerageAttributes(brokerage, 'totalFee', party.brokeragesForUser[0].totalFee);
        this.setState(newState);
      }
    }
  }



  isCurrentUserBrokerAndSameAsParty(party) {
    return isCurrentUserBroker() && get(party, 'companyId') === get(this.props.currentUser, 'companyId');
  }

  resetBrokeragesAndBrokeredBy() {
    const newState = {...this.state};
    newState.fields.brokerages = [];
    newState.fields.payableBy.value = 'Nil';
    newState.fields.administration.brokeredById.value = '';
    newState.fields.administration.brokerContactId.value = '';
    this.setState(newState);
  }

  noteHandler = val => {
    this.setState({fields: {...this.state.fields, crmNotes: val}});
  };

  isPartyDefaultBrokerageIsFlatFee(partyType, party) {
    return party && party.brokeragesForUser[0] && party.brokeragesForUser[0].feeType == 'Flat Fee';
  }

  isBrokerageSameAsDefaultForParty(partyType, party) {
    let brokerage = this.getPartyBrokerage(partyType);
    return (
      get(party, 'brokeragesForUser.0') &&
      brokerage &&
      brokerage.feeType.value === party.brokeragesForUser[0].feeType &&
      brokerage.rate.value === party.brokeragesForUser[0].rate &&
      brokerage.totalFee.value === party.brokeragesForUser[0].totalFee
    );
  }

  handleBuyerCompanyIdChange(value, targetId, chosenItem) {
    if(!this.isEditingContract && this.isCurrentUserBrokerAndSameAsParty(chosenItem))
      this.resetBrokeragesAndBrokeredBy();
    else
      this.handleDefaultBrokerageForParty('buyer', chosenItem);

    this.closeBuyerSideDrawer();
    this.dontResetSeller = true;
    const newState = { ...this.state };
    newState.buyerChanged = newState.fields.buyer.companyId.value !== value;
    if(newState.buyerChanged && newState.fields.buyer.companyId.value)
      newState.representingBuyer = false
    if (!this.selectedContract && isCurrentUserBroker())
      newState.representingBuyer = this.isContractDocumentType();
    if(isCurrentUserBroker() && get(chosenItem, 'isManagedByUser')) {
      newState.representingBuyer = get(chosenItem, 'isManagedByUser');
    }
    if (isCurrentUserBroker() && (this.isSalesConfirmationDocumentType() || this.isContractDocumentType())) {
      newState.representingBuyer = true;
      newState.representingSeller = this.isSalesConfirmationDocumentType() ? false : newState.representingSeller;
    }
    if(this.setDefaultValues && value !== newState.fields.buyer.companyId.value){
      this.showTypeIdFieldError = false;
      newState.fields.consignees = [cloneDeep(this.consigneeFields)];
      if (newState.applyRule) {
        let startDate = newState.fields.deliveryStartDate.value;
        let startDateErrors = newState.fields.deliveryStartDate.errors;
        const allowPastDates = true;
        let maxDate = this.getMaxDeliveryStartDate();
        maxDate = maxDate ? maxDate.format('YYYY-MM-DD') : maxDate;
        const minDate = allowPastDates ? moment(0).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
        if (startDate < minDate || startDate > maxDate) {
          startDate = undefined;
          startDateErrors = [];
        }
        newState.fields.deliveryStartDate.value = startDate;
        newState.fields.deliveryStartDate.errors = startDateErrors;
        newState.fields.deliveryEndDate.value = this.isSpotContract() ? newState.fields.deliveryEndDate.value : undefined;
        newState.fields.deliveryEndDate.errors = [];
        newState.fields.carryStartDate.value = undefined;
        newState.fields.carryStartDate.errors = [];
        newState.fields.carryEndDate.value = undefined;
        newState.fields.carryEndDate.errors = [];
      }
      if (newState.applyRule && newState.buyerChanged) {
        newState.fields.buyer.contactId.value = undefined;
        newState.fields.buyer.ngrId.value = undefined;
      }
    }
    newState.selectedBuyer = chosenItem;
    if(chosenItem && value !== VALUE_NOT_SELECTED){
      if(this.state.fields.buyer.companyId.value !== value){
        newState.fetchBuyerParties = true;
        newState.buyerEstablishmentDetails.company = chosenItem;
        if(!this.applyingTemplate && !isEqual(get(this.state.selectedBuyer, 'id'), chosenItem.id)) {
          this.getBuyerNgrs(value,chosenItem.partyType);
          this.getContacts(chosenItem, 'buyer', null, newState.representingBuyer);
        }
      }
      newState.fields.paymentTermId.value = chosenItem.paymentTermId;
      newState.fields.buyer.companyId.errors = [];
      newState.fields.buyer.companyId.value = value;
      newState.sellerParties = compact(reject([...this.props.allCompanyParties, this.state.sellerEstablishmentDetails.company], {id: chosenItem.id}))
    } else {
      newState.fields.buyer = cloneDeep(this.buyerFields);
      newState.fields.buyer.companyId.value = value;
      newState.sellerParties = compact([...this.props.allCompanyParties, this.state.sellerEstablishmentDetails.company]);
      newState.buyerContacts = [];
      newState.buyerNgrs = [];
      newState.fetchBuyerParties = false;
    }
    if(newState.buyerChanged)
      newState.fields.buyer.companyId.value = value;
    this.getContractTypes(newState);
    this.setState(newState, () => {
      if (!this.applyingTemplate) {
        this.showTypeIdFieldError = false;
        const type = find(this.state.types, { id: this.state.fields.typeId.value });
        this.handleTypeIdFieldChange(get(type, 'id'), 'typeId');
      }
      setTimeout(() => {
        this.showTypeIdFieldError = true;
      }, 500);
      this.openSideDrawer();
    });
  }

  handleSellerFieldChange(value, id) {
    this.setSellerFieldValue(id, value);
  }

  handleBuyerFieldChange(value, id) {
    this.setBuyerFieldValue(id, value);
  }

  setSellerFieldValue(key, value) {
    this.setFieldValue(key, value);
    this.setFieldErrors(key, value);
  }

  setBuyerFieldValue(key, value) {
    this.setFieldValue(key, value);
    this.setFieldErrors(key, value);
  }

  handleQuickContractChange = item => {
    if(item) {
      const newState = {...this.state};
      this.selectedContract = undefined;
      alertifyjs
        .confirm(
          'Warning',
          'Already filled values in the Contract will be overridden. Do you wish to continue?',
          () => {
            newState.resetAllFieldValues = true;
            this.setDefaultValues = false;
            this.applyingTemplate = true;
            newState.sustainableCommodity = false;
            newState.isCashPriced = false;
            newState.applyRule = false;
            this.isEditingContract = false;
            this.prefillBrokerById = false;
            this.resetAllFieldsValues(newState);
            this.setState(newState, () => {
              this.getSelectedContractDetails(item.id);
              this.props.dispatch(isLoading());
            });
          },
          () => {},
        )
        .set('labels', { ok: 'Yes', cancel: 'No' });
    }
  };

  getSelectedContractDetails(contractId) {
    APIService.contracts(contractId)
      .get(this.props.userToken, {'original-unit': true})
              .then(item => {
                if(this.isDuplicatingContract){
                  if(!isValidUserDocumentType(item))
                    window.location.hash = '#/403';

                  this.setDefaultValues = false;
                  this.applyingTemplate = true;
                  this.props.dispatch(isLoading());
                  this.setAllFieldsValuesBySelectedContract(item);
                }
                else if (this.isEditingContract) {
                  this.setDefaultValues = false;
                  this.applyingTemplate = true;
                  if (item.canRaiseAmendRequest) {
                    this.setAllFieldsValuesBySelectedContract(item);
                  } else {
                    window.location.hash = '#/403';
                  }
                } else {
                  this.setAllFieldsValuesBySelectedContract(item);
                }
              });
  }

  async getBrokerCompanyEmployees(companyId, brokerContactId = '') {
    if (companyId) {
      let brokerKeyContactId = null;
      var employees = [];
      if (this.fetchBrokerEmployees || brokerContactId) {
        this.fetchBrokerEmployees = false;
        employees = await APIService.companies(companyId)
                                    .employees()
                                    .get(this.props.userToken);
      } else {
        employees = cloneDeep(this.state.brokerEmployees);
      }
      if (isCurrentUserBroker() && this.props.currentUser.companyId === companyId && !this.state.fields.administration.brokerContactId.value) {
        brokerKeyContactId = this.props.currentUser.id;
      }
      brokerKeyContactId = brokerContactId || brokerKeyContactId || this.getKeyContactId(employees);

      this.setState(
        {
          brokerEmployees: employees,
        },
        () => {
          if (brokerKeyContactId) {
            this.handleSelectFieldChange(brokerKeyContactId, 'administration.brokerContactId');
          }
        },
      );
    }
  }

  handleBrokeredByChange = (value, id) => {
    const newState = { ...this.state };
    if (!value) {
      newState.fields.administration.brokerContactId.value = undefined;
    }
    newState.fields.administration.brokeredById.value = value;
    if (!this.applyingTemplate) {
      this.fetchBrokerEmployees = true;
      this.getBrokerCompanyEmployees(value);
      this.setFieldErrors(id, value);
    }
    this.updateBrokerContactValidators(newState);
    this.setState(newState);
  };

  handleSelectFieldChange(value, id) {
    this.setFieldValue(id, value);
    this.setFieldErrors(id, value);
    this.calculateCarryValues();
  }

  isMeterCubeCommodityCommodity(commodityId) {
    const _id = commodityId || this.state.fields.commodityId.value;
    return get(this.getSelectedCommodity(_id), 'isStrictQuantityBased')
  }

  priceUnit(commodityId) {
    return get(this.getSelectedCommodity(commodityId), 'priceUnit', MT_UNIT)
  }

  tonnageUnit(commodityId) {
    return get(this.getSelectedCommodity(commodityId), 'tonnageUnit', MT_UNIT)
  }

  getPriceFieldLabel = () => {
    const unit = this.priceUnit()
    let label = `Price (per ${unit})`
    if (this.isAreaContract())
      label += ' (Optional)';

    return label;
  };

  toggleGradeValidation = newState => {
    if (this.isPoolContractType(newState)) {
      newState.fields.gradeId.validators = [];
    } else {
      newState.fields.gradeId.validators = [required()];
    }
  };

  handleTypeIdFieldChange = (value, id) => {
    const newState = { ...this.state };
    newState.fields.spread.value = undefined;
    newState.fields.spread.errors = [];
    newState.fields.typeId.value = value;
    this.resetGradeAndSpreadFieldValue(newState);
    if (
      newState.fields.commodityId.value === COMMODITIES.WHEAT &&
      includes([CONTRACT_TYPES.MULTI_GRADE, CONTRACT_TYPES.FLOATING_MULTI_GRADE, CONTRACT_TYPES.AREA], value) &&
      !this.applyingTemplate
    ) {
      newState.fields.gradeId.errors = [];
    }
    if (value === CONTRACT_TYPES.AREA) {
      newState.fields.price.validators = [];
      newState.fields.area.area.validators = [required()];
      newState.fields.area.estimatedYield.validators = [required()];
      newState.fields.area.priceBy.validators = [required()];
    } else if(value === CONTRACT_TYPES.SPOT) {
      newState.fields.deliveryStartDate.value = newState.fields.deliveryEndDate.value = moment().format(DATE_DB_FORMAT)
    } else {
      newState.fields.blendingFee.validators = []
      newState.fields.blendingFee.errors = []
      newState.fields.price.validators = [required(), minValue(0.01)];
      newState.fields.area.area.validators = [];
      newState.fields.area.estimatedYield.validators = [];
      newState.fields.area.priceBy.validators = [];
    }
    if(value !== CONTRACT_TYPES.BLENDED) {
      newState.fields.blendingFee.value = undefined;
      newState.fields.chemicalApplications.value = [];
    }
    if (value === CONTRACT_TYPES.BLENDED)
      newState.useSinglePrice = false;
    this.toggleGradeValidation(newState);
    this.toggleSpreadValidation(newState);
    this.setState(newState, () => {
      if (this.showTypeIdFieldError) {
        this.setFieldErrors(id, value);
      }
    });
    if ((this.isEditingContract || this.isDuplicatingContract) && this.selectedContract?.spreadId)
      this.handleSpreadGradesChange(this.selectedContract.spread.details);
  };

  isPoolContractType = newState => {
    return includes(
      [CONTRACT_TYPES.POOL_CASH_AND_CALL, CONTRACT_TYPES.POOL_STRATEGIC_6_MONTH, CONTRACT_TYPES.POOL_STRATEGIC_12_MONTH],
      newState.fields.typeId.value,
    );
  };

  resetGradeAndSpreadFieldValue = newState => {
    if (this.isPoolContractType(newState)) {
      newState.fields.gradeId.value = undefined;
      newState.fields.spread.value = undefined;
      newState.fields.gradeId.errors = [];
      newState.fields.spread.errors = [];
    }
  };

  handleBlur(event) {
    this.setFieldErrors(event.target.id, event.target.value);
  }

  handleTemplateNameBlur(event) {
    this.setTemplateNameFieldErrors(event.target.id, event.target.value);
  }

  handleFieldChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
  }

  handleIdentifierChange(event) {
    const regex = new RegExp('^[a-zA-Z0-9]*$');
    if (regex.test(event.target.value)) {
      const targetValue = event.target.value
      const canUpdateRelatedContractIdentifier = (
        this.isEditingContract &&
        event.target.id == 'identifier' &&
        get(this.selectedContract, 'identifier') == get(this.selectedContract, 'contractNumber')
      )
      const updateRelatedContractIdentifier = () => {
        if (canUpdateRelatedContractIdentifier)
          this.setFieldValue('contractIdentifier', targetValue.toUpperCase())
        if (this.state.isUniqueIdentifierErrorExists) {
          const newState = { ...this.state };
          newState.isUniqueIdentifierErrorExists = false;
          newState.fields.identifier.errors = [];
          this.setState(newState);
        }
      }
      this.setFieldValue(event.target.id, targetValue.toUpperCase(), updateRelatedContractIdentifier);
    }
  }

  handleContractDateChange(value, id) {
    this.setFieldValue(id, value);
    this.setFieldErrors(id, value);
  }

  toggleCarryStartEndDateValidation() {
    const newState = { ...this.state };
    if (newState.fields.carryRate.value) {
      newState.fields.carryStartDate.validators = [required()];
      newState.fields.carryEndDate.validators = [required()];
    } else {
      newState.fields.carryStartDate.validators = [];
      newState.fields.carryEndDate.validators = [];
    }
    this.setState(newState);
  }

  handleCarryRateFieldChange(event) {
    let value = event.target.value || null
    this.setFieldValue(event.target.id, value);
    this.toggleCarryStartEndDateValidation();
    this.calculateCarryValues();
  }

  accountedTonnage() {
    return parseFloat(get(this.selectedContract, 'accountedTonnage', 0)).toFixed(2);
  }

  minimumAllowedTonnage() {
    let minimumAllowedTonnage = parseFloat(get(this.selectedContract, 'minimumAllowedTonnage', 0)).toFixed(2);
    if(this.isAllocatedContract)
      minimumAllowedTonnage = parseFloat(get(this.selectedContract, 'allocatedTonnageForUser', 0)).toFixed(2);
    return minimumAllowedTonnage;
  }

  handleTonnageFieldChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
    this.setFieldErrors(event.target.id, event.target.value);
    if (this.isEditingContract) {
      const newState = { ...this.state };
      const accountedTonnage = this.accountedTonnage();
      const minimumAllowedTonnage = this.minimumAllowedTonnage();
      const unit = this.priceUnit()
      if (!this.isMeterCubeCommodityCommodity() && parseFloat(event.target.value) < minimumAllowedTonnage) {
        if(this.selectedContract && this.isAllocatedContract)
          newState.fields[event.target.id].errors = [`Please enter valid ${this.tonnageLabel} as ${minimumAllowedTonnage} ${unit} has already been allocated`];
        else
          newState.fields[event.target.id].errors = [`Please enter valid ${this.tonnageLabel} as ${accountedTonnage} ${unit} has already been planned`];
      } else {
        newState.fields[event.target.id].errors = [];
      }
      this.setState(newState);
    }

    const sellerBrokerage = this.getPartyBrokerage('seller');
    if (sellerBrokerage) {
      this.updateTotalFee(sellerBrokerage);
    }
    const buyerBrokerage = this.getPartyBrokerage('buyer');
    if (buyerBrokerage) {
      this.updateTotalFee(buyerBrokerage);
    }
    this.calculateCarryValues();
  }

  handleQuantityFieldChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
    this.setFieldErrors(event.target.id, event.target.value);
    if (this.isEditingContract) {
      const newState = { ...this.state };
      const accountedTonnage = this.accountedTonnage();
      const minimumAllowedTonnage = this.minimumAllowedTonnage();
      const unit = this.priceUnit()
      if (this.isMeterCubeCommodityCommodity() && parseFloat(event.target.value) < minimumAllowedTonnage) {
        if(this.selectedContract && this.isAllocatedContract)
          newState.fields[event.target.id].errors = [`Please enter valid ${this.tonnageLabel} as ${minimumAllowedTonnage} ${unit} has already been allocated`];
        else
          newState.fields[event.target.id].errors = [`Please enter valid ${this.tonnageLabel} as ${accountedTonnage} ${unit} has already been planned`];
      } else {
        newState.fields[event.target.id].errors = [];
      }
      this.setState(newState);
    }

    const sellerBrokerage = this.getPartyBrokerage('seller');
    if (sellerBrokerage) {
      this.updateTotalFee(sellerBrokerage);
    }
    const buyerBrokerage = this.getPartyBrokerage('buyer');
    if (buyerBrokerage) {
      this.updateTotalFee(buyerBrokerage);
    }
    this.calculateCarryValues();
  }

  handlePriceFieldChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
    const sellerBrokerage = this.getPartyBrokerage('seller');
    if (sellerBrokerage) {
      this.updateTotalFee(sellerBrokerage);
    }
    const buyerBrokerage = this.getPartyBrokerage('buyer');
    if (buyerBrokerage) {
      this.updateTotalFee(buyerBrokerage);
    }
  }

  handleCurrencyChange = value => {
    if (value)
      this.setState({currency: value})
  }

  setFieldValue(id, value, callback) {
    const newState = { ...this.state };
    set(newState.fields, id + '.value', value);
    this.setState(newState, () => {
      if (callback) {
        callback();
      }
    });
  }

  setFieldErrors(id, value) {
    const newState = { ...this.state };
    this.getFieldErrors(get(newState.fields, id), value);
    this.setState(newState);
  }

  setFieldCustomErrors = (id, msg) => {
    const newState = { ...this.state };
    const field = get(newState.fields, id);
    if (has(field, 'errors') && isArray(field.errors) && !isEmpty(msg)) {
      field.value = undefined;
      field.errors.push(msg);
    }
    this.setState(newState);
  };

  setTemplateNameFieldErrors(id, value) {
    const newState = { ...this.state };
    this.getFieldErrors(get(newState, id), value);
    this.setState(newState);
  }

  // eslint-disable-next-line no-unused-vars
  getFieldErrors(field, value) {
    // eslint-disable-line no-unused-vars
    if (has(field, 'value') && has(field, 'validators')) {
      field.errors = [];
      if (!this.state.resetAllFieldValues) {
        field.validators.forEach(validator => {
          if (validator.isInvalid(field.value)) {
            field.errors.push(validator.message);
          }
        });
      }
    }
    if (has(field, 'errors') && !isEmpty(field.errors)) {
      field.value = undefined;
    }
  }

  setAllFieldsErrors() {
    const newState = { ...this.state };
    this.applyValidatorsOn(newState.fields);
    this.applyValidatorsOn(newState.fields.buyer);
    this.applyValidatorsOn(newState.fields.seller);
    this.applyValidatorsOn(newState.fields.area);
    forEach(newState.fields.consignors, field => {
      this.applyValidatorsOn(field);
    });
    forEach(newState.fields.consignees, field => {
      this.applyValidatorsOn(field);
    });
    this.applyValidatorsOn(newState.fields.administration);
    this.applyValidatorsOn(newState.fields.spread);
    forEach(newState.fields.brokerages, field => {
      this.applyValidatorsOn(field);
    });
    const accountedTonnage = this.accountedTonnage();
    const minimumAllowedTonnage = this.minimumAllowedTonnage();
    const unit = this.priceUnit()
    const tonnageLabel = this.tonnageLabel
    if (
      !this.isMeterCubeCommodityCommodity() &&
      this.isEditingContract &&
      newState.fields.tonnage.value &&
      parseFloat(newState.fields.tonnage.value) < minimumAllowedTonnage
    ) {
      if(this.selectedContract && this.isAllocatedContract)
        newState.fields.tonnage.errors = [`Please enter valid ${tonnageLabel} as ${minimumAllowedTonnage} ${unit} has already been allocated`];
      else
        newState.fields.tonnage.errors = [`Please enter valid ${tonnageLabel} as ${accountedTonnage} ${unit} has already been planned`];
    }
    if (
      this.isMeterCubeCommodityCommodity() &&
      this.isEditingContract &&
      newState.fields.quantity.value &&
      parseFloat(newState.fields.quantity.value) < minimumAllowedTonnage
    ) {
      if(this.selectedContract && this.isAllocatedContract)
        newState.fields.tonnage.errors = [`Please enter valid ${tonnageLabel} as ${minimumAllowedTonnage} ${unit} has already been allocated`];
      else
        newState.fields.quantity.errors = [`Please enter valid ${tonnageLabel} as ${accountedTonnage} ${unit} has already been planned`];
    }
    if (this.isPoolContractType(newState)) {
      this.hidePriceField(newState);
    }
    this.setState(newState);
    window.scrollTo(0, 0);
  }

  getEstimatedTonnage() {
    return this.state.fields.area.estimatedYield.value && this.state.fields.area.area.value
         ? (this.state.fields.area.estimatedYield.value * this.state.fields.area.area.value).toFixed(2)
         : '';
  }

  focusOnFirstErrorField() {
    for (let i = 0; i < this.fieldsOrder.length; i++) {
      const formField = this.fieldRef[this.fieldsOrder[i]];
      const field = this.state.fields[this.fieldsOrder[i]];
      const autoCompleteFields = [
        "templateNameSelector", "commodityId", "varietyId", "gradeId", "track", "paymentTermId",
        "marketZoneId", "generalConditionsSelector", "specialConditionsSelector"
      ];
      const nestedFields = [
        "seller.companyId", "seller.contactId", "seller.ngrId", "buyer.companyId",
        "buyer.contactId", "buyer.ngrId", "consignors[0].handlerId",
        "consignees[0].handlerId",
        "administration.brokeredById", "administration.brokerContactId", "brokerages.Seller.feeType",
        "brokerages.Seller.rate", "brokerages.Buyer.rate", "brokerages.Seller.totalFee", "brokerages.Buyer.totalFee",
        "area", "estimatedYield", "priceBy", "brokerages.Buyer.feeType",
      ];

      if (
        (this.fieldsOrder[i] === 'spread' && this.state.fields.spread.errors.length > 0) ||
        (this.fieldsOrder[i] === 'consignees[0].handlerId' && this.state.fields.consignees[0].handlerId.errors.length > 0) ||
        (this.fieldsOrder[i] === 'consignors[0].handlerId' && this.state.fields.consignors[0].handlerId.errors.length > 0) ||
        (this.fieldsOrder[i] === 'typeId' && this.state.fields.typeId.errors.length > 0)
      ) {
        if (formField.current) {
          formField.current.focus();
          formField.current.scrollIntoView({ block: 'center' });
          break;
        }
      }
      if (nestedFields.indexOf(this.fieldsOrder[i]) !== -1) {
        if (
          (
            this.fieldsOrder[i] === "seller.companyId" &&
            this.state.fields.seller.companyId.errors.length
          ) ||
          (
            this.fieldsOrder[i] === "seller.ngrId" &&
            this.state.fields.seller.ngrId.errors.length
          ) ||
          (
            this.fieldsOrder[i] === "buyer.companyId" &&
            this.state.fields.buyer.companyId.errors.length
          ) ||
          (
            this.fieldsOrder[i] === "buyer.ngrId" &&
            this.state.fields.buyer.ngrId.errors.length
          ) ||
          (
            this.fieldsOrder[i] === "consignors[0].handlerId" &&
            this.state.fields.consignors[0].handlerId.errors.length
          ) ||
          (
            this.fieldsOrder[i] === "consignees[0].handlerId" &&
            this.state.fields.consignees[0].handlerId.errors.length
          )
        ) {
          getAutoSelectFocusField(this.fieldRef, this.fieldsOrder[i]);
          break;
        }
        if (
          (
            this.fieldsOrder[i] === "seller.contactId" &&
            this.state.fields.seller.contactId.errors.length
          ) ||
          (
            this.fieldsOrder[i] === "buyer.contactId" &&
            this.state.fields.buyer.contactId.errors.length
          ) ||
          (
            this.fieldsOrder[i] === "administration.brokeredById" &&
            this.state.fields.administration.brokeredById.errors.length
          ) ||
          (
            this.fieldsOrder[i] === "administration.brokerContactId" &&
            this.state.fields.administration.brokerContactId.errors.length
          ) ||
          (
            this.fieldsOrder[i] === "brokerages.Seller.feeType" &&
            this.state.fields.brokerages.length &&
            get(this.state.fields, 'brokerages[0].feeType.errors.length',0)
          ) ||
          (
            this.fieldsOrder[i] === "brokerages.Buyer.feeType" &&
            this.state.fields.brokerages.length &&
            (
              this.state.fields.brokerages.length > 1 ?
              get(this.state.fields, 'brokerages[1].feeType.errors.length', 0):
              get(this.state.fields, 'brokerages[0].feeType.errors.length', 0))
          )
        ) {
          if (formField?.current?.node) {
            formField.current.node.previousSibling.focus();
            break;
          }
        }
        if (
          (
            this.fieldsOrder[i] === "brokerages.Seller.rate" &&
            this.state.fields.brokerages.length &&
            get(this.state.fields, 'brokerages[0].rate.errors.length', 0)
          ) ||
          (
            this.fieldsOrder[i] === "brokerages.Buyer.rate" &&
            this.state.fields.brokerages.length &&
            (
              this.state.fields.brokerages.length > 1 ?
              get(this.state.fields, 'brokerages[1].rate.errors.length', 0):
              get(this.state.fields, 'brokerages[0].rate.errors.length', 0)
            )
          ) ||
          (
            this.fieldsOrder[i] === "brokerages.Seller.totalFee" &&
            this.state.fields.brokerages.length &&
            this.state.fields.brokerages[0].totalFee.errors.length
          ) ||
          (
            this.fieldsOrder[i] === "brokerages.Buyer.totalFee" &&
            this.state.fields.brokerages.length &&
            (
              this.state.fields.brokerages.length > 1 ?
              get(this.state.fields, 'brokerages[1].totalFee.errors.length', 0):
              get(this.state.fields, 'brokerages[0].totalFee.errors.length', 0)
            )
          ) ||
          (
            this.fieldsOrder[i] === "area" &&
            this.state.fields.area.area.errors.length
          ) ||
          (
            this.fieldsOrder[i] === "estimatedYield" &&
            this.state.fields.area.estimatedYield.errors.length
          ) ||
          (
            this.fieldsOrder[i] === "priceBy" &&
            this.state.fields.area.priceBy.errors.length
          )
        ) {
          if (formField.current) {
            formField.current.focus();
            break;
          }
        }
      } else if (
        autoCompleteFields.indexOf(this.fieldsOrder[i]) !== -1
      ) {
        if (field && field.errors.length > 0) {
          getAutoSelectFocusField(this.fieldRef, this.fieldsOrder[i]);
          break;
        }
      } else if (
        field && field.errors.length > 0
      ) {
        if (formField?.current?.node) {
          formField.current.node.previousSibling.focus();
          break;
        } else {
          formField?.current?.focus();
          break;
        }
      }
    }
  }

  applyValidatorsOn(fields) {
    forEach(fields, field => {
      this.getFieldErrors(field);
    });
  }

  handleSpreadGradesChange(data) {
    const newState = { ...this.state };
    newState.fields.spread.value = data;
    if(this.isBlended()) {
      newState.fields.tonnage.value = sumBy(data, 'quantity')
      newState.fields.tonnage.errors = []
      if (!this.state.useSinglePrice && !this.state.fields.price.value)
        newState.fields.price.value = parseFloat((sumBy(data, grade => grade.price * grade.quantity)/newState.fields.tonnage.value).toFixed(2))
      newState.fields.price.errors = []
      newState.fields.gradeId.value = get(data, '0.gradeId')
      newState.fields.gradeId.errors = []
    }
    this.setState(newState, () => this.setFieldErrors('spread', data));
  }

  handleChemicalApplicationsChange = applications => {
    const newState = {...this.state}
    newState.fields.chemicalApplications.value = applications || []
    this.setState(newState)
  }

  getNameById(id, list) {
    return get(find(list, {id: id}), 'name');
  }

  getDisplayNameById(id, list) {
    return get(find(list, {id: id}), 'displayName');
  }

  getCompanyById(id) {
    return find(this.props.allCompanyParties, {id: id});
  }

  getCommodityDetails() {
    const tonnage = this.state.fields.tonnage.value;
    const grade = this.getNameById(this.state.fields.gradeId.value, this.props.grades);
    const commodity = this.getDisplayNameById(this.state.fields.commodityId.value, this.props.commodities);
    const variety = this.getNameById(this.state.fields.varietyId.value, this.props.varieties);
    const season = this.state.fields.season.value;
    const unit = this.priceUnit()
    let details = '';
    if (commodity) {
      details += `${commodity}`;
    }
    if (variety) {
      details += ` | ${variety}`;
    }
    if (grade) {
      details += ` | ${grade}`;
    }
    if (tonnage) {
      details += ` | ${tonnage} ${unit}`;
    }
    if (season) {
      details += ` | ${season}`;
    }
    return details;
  }

  getNgrNumber(id, list) {
    const val = list.find(val => val.id === id);
    return val && val.ngrNumber;
  }

  getPreviewFields() {
    const fields = this.state.fields;
    const currentUser = this.state.currentUser;
    const seller = fields.seller;
    const buyer = fields.buyer;
    const spreads = fields.spread.value ? fields.spread.value : '';
    let brokerCompany = find(this.state.brokerCompanies, { id: fields.administration.brokeredById.value });
    let carryStartDate = moment(this.state.fields.carryStartDate.value, 'YYYY-MM-DD').format(this.state.countryFormats.dateDisplay);
    let carryEndDate = moment(this.state.fields.carryEndDate.value, 'YYYY-MM-DD').format(this.state.countryFormats.dateDisplay);
    let pickupSiteAddress = get(this.state.fields.consignors, '[0].item.address', '');
    let deliverySiteAddress = get(this.state.fields.consignees, '[0].item.address', '');
    if (typeof pickupSiteAddress === 'object')
      pickupSiteAddress = get(pickupSiteAddress, 'address');
    if (typeof deliverySiteAddress === 'object')
      deliverySiteAddress = get(deliverySiteAddress, 'address');
    return {
      priceUnit: this.priceUnit(),
      tonnageUnit: this.tonnageUnit(),
      brokersDocumentType: this.getDisplayNameById(fields.documentTypeId.value, this.state.documentTypes),
      contractDate: moment(this.state.fields.contractDate.value).format(this.state.countryFormats.dateDisplay),
      currentUser: currentUser,
      currentUserCompanyLogo: this.state.base64Logo,
      sellerContractNumber: '',
      buyerContractNumber: '',
      sellerDetails: this.getCompanyById(seller.companyId.value),
      buyerDetails: this.getCompanyById(buyer.companyId.value),
      buyerContact: find(this.state.buyerContacts, {id: fields.buyer.contactId.value}),
      sellerContact: find(this.state.sellerContacts, {id: fields.seller.contactId.value}),
      buyerNgr: find(this.state.buyerNgrs, {id: fields.buyer.ngrId.value}),
      sellerNgr: find(this.state.sellerNgrs, {id: fields.seller.ngrId.value}),
      paymentScale: this.getNameById(fields.paymentScaleId.value, this.state.paymentScales),
      deliveryEndDate: fields.deliveryEndDate.value && moment(fields.deliveryEndDate.value).format(this.state.countryFormats.dateDisplay),
      deliveryStartDate: fields.deliveryStartDate.value && moment(fields.deliveryStartDate.value).format(this.state.countryFormats.dateDisplay),
      specialConditions: fields.specialConditions.value,
      contractConditions: fields.generalConditions.value,
      conveyance: this.getNameById(fields.conveyanceId.value, this.state.conveyances),
      commodityDetails: this.getDisplayNameById(this.state.fields.commodityId.value, this.props.commodities),
      commodities: this.props.commodities,
      variety: this.getNameById(this.state.fields.varietyId.value, this.props.varieties),
      grade: this.getNameById(this.state.fields.gradeId.value, this.props.grades),
      tonnage: this.state.fields.tonnage.value,
      quantity: this.state.fields.quantity.value,
      quantityLabel: this.quantityLabel(),
      quantityUnit: this.quantityUnit(),
      isMeterCubeCommodityCommodity: this.isMeterCubeCommodityCommodity(),
      tonnageQuantity: this.getQuantity(),
      season: this.state.fields.season.value,
      pricePointId: fields.pricePointId.value,
      pricePoint: this.getDisplayNameById(fields.pricePointId.value, this.state.pricePoints),
      price: !this.isPoolContractType(this.state) ? fields.price.value && `${this.state.currency} ${fields.price.value}` : null,
      spread: spreads,
      paymentTerms: this.getNameById(fields.paymentTermId.value, this.state.paymentTerms),
      tolerance: this.getNameById(fields.toleranceId.value, this.state.tolerances),
      weight: this.getNameById(fields.weightId.value, this.state.checkpoints),
      inspection: this.getNameById(fields.inspectionId.value, this.state.checkpoints),
      packaging: this.getNameById(fields.packagingId.value, this.state.packagings),
      identifier: fields.identifier.value,
      contractIdentifier: fields.contractIdentifier.value,
      identifierLabel: this.state.fields.identifier.label,
      documentType: this.state.fields.documentTypeId.value,
      lotNumber: this.state.fields.lotNumber.value,
      contractTypeId: this.state.fields.typeId.value,
      contractType: this.getDisplayNameById(this.state.fields.typeId.value, this.state.types),
      track: fields.track.value,
      currency: this.state.currency,
      carryValue: this.state.fields.carryRate.value,
      carryDetails:
        this.state.currency + this.state.fields.carryRate.value + ' ' + this.state.fields.carryFrequency.value + ' from ' + carryStartDate + ' to ' + carryEndDate,
      invoicing: this.getNameById(fields.administration.invoicing.value, INVOICINGS),
      deliveryOnus: this.getNameById(this.state.fields.deliveryOnus.value, DELIVERY_ONUS),
      consignees: fields.consignees,
      consignors: fields.consignors,
      pickupSite: this.state.selectedConsignorSite != undefined ? this.state.selectedConsignorSite['name'] : '',
      pickupSiteAddress: pickupSiteAddress,
      deliverySiteAddress: deliverySiteAddress,
      brokerCompany: brokerCompany != undefined ? brokerCompany['displayName'] : '',
      brokerEmployee: this.getNameById(fields.administration.brokerContactId.value, this.state.brokerEmployees),
      brokerages: this.state.fields.brokerages,
      priceFieldLabel: this.getPriceFieldLabel(),
      poolGrades: getPoolGrades(
        filter(this.props.grades, grade => {
          return this.state.fields.commodityId.value ? this.state.fields.commodityId.value === grade.commodityId : true;
        }),
      ),
      isPoolContract: this.isPoolContractType(this.state),
      showGradeField: this.showGradeField(),
      showSpreadGradesSection: this.showSpreadGradesSection(),
      isRepresentingSeller: this.state.representingSeller,
      isRepresentingBuyer: this.state.representingBuyer,
      LDLabel: this.getLDLabel(),
      isAreaContract: this.isAreaContract(),
      estimatedYield: this.state.fields.area.estimatedYield.value,
      estimatedTonnage: this.getEstimatedTonnage(),
      priceBy: this.state.fields.area.priceBy.value && moment(this.state.fields.area.priceBy.value).format(this.state.countryFormats.dateDisplay),
      area: this.state.fields.area.area.value,
      marketZoneId: fields.marketZoneId.value,
      marketZone: this.getNameById(fields.marketZoneId.value, this.state.marketZones),
      regionId: fields.regionId.value,
      region: this.getNameById(fields.regionId.value, this.state.regions),
      isBrokerSalesConfirmation: this.isSalesConfirmationDocumentType() && isCurrentUserBroker(),
      sustainableCommodity: this.state.sustainableCommodity,
      isCashPriced: this.state.isCashPriced,
      blendingFee: this.state.fields.blendingFee.value,
      chemicalApplications: this.state.fields.chemicalApplications.value,
      isBlendedContract: this.isBlended()
    };
  }

  getMaxDeliveryStartDate = () => {
    let maxDeliveryStartDate = null;
    if (this.state.fields.deliveryEndDate.value) maxDeliveryStartDate = moment(this.state.fields.deliveryEndDate.value);

    return maxDeliveryStartDate;
  };

  openSellerSideDrawer(){
    const companyId = get(this.state.fields, 'seller.companyId.value');
    if(companyId){
      const { dispatch } = this.props;
      dispatch(canCreateEmployee(companyId, 'company', () => {
        this.props.handleAddEmployeeButtonClick();
        this.openSideDrawer('sellerSideDrawerIsOpen');
      }));
    }
  }

  openCompanySideDrawer(contractParty){
    this.setState({openCompanySideDrawer: true, contractParty: contractParty});
  }

  closeCompanySideDraw(){
    this.setState({
      openCompanySideDrawer: false
    });
  }

  alertPermissionError() {
    alertifyjs.alert(
      'Permission Denied',
      `<div className=""><p>You do not have permission to create employee for the party because:</p><ul><li>The selected party is registered on the system.</li></ul><div>Please follow <a href=${getFreshdeskURL()} target='_blank'>FAQs</a> for more details</div></div>`,
      () => {},
    );
  }

  alertNgrPermissionError() {
    alertifyjs.alert(
      'Permission Denied',
      `<div className=""><p>You do not have permission to create ngr for the party because:</p><ul><li>You are not authorized.Please contact Company Admin</li></ul><div>Please follow <a href=${getFreshdeskURL()} target='_blank'>FAQs</a> for more details</div></div>`,
      () => {},
    );
  }

  closeSellerSideDrawer() {
    this.setState({ sellerSideDrawerIsOpen: false });
  }

  openBuyerSideDrawer(){
    const companyId = get(this.state.fields, 'buyer.companyId.value');
    if(companyId){
      const { dispatch } = this.props;
      dispatch(canCreateEmployee(companyId, 'company', () => {
        this.props.handleAddEmployeeButtonClick();
        this.openSideDrawer('buyerSideDrawerIsOpen');
      }));
    }
  }

  closeBuyerSideDrawer() {
    this.setState({ buyerSideDrawerIsOpen: false });
  }

  openBrokerSideDrawer() {
    if (this.canBrokerAccessAny()) {
      this.props.handleAddEmployeeButtonClick();
      this.openSideDrawer('brokerSideDrawerIsOpen');
    } else {
      this.alertPermissionError();
    }
  }

  closeBrokerSideDrawer() {
    this.setState({ brokerSideDrawerIsOpen: false });
  }

  openGeneralConditionSideDrawer() {
    const { dispatch } = this.props;
    dispatch(clickAddGeneralConditionButton());
    this.openSideDrawer('generalConditionSideDrawerIsOpen');
  }

  closeGeneralConditionSideDrawer() {
    this.setState({ generalConditionSideDrawerIsOpen: false });
  }

  openSpecialConditionSideDrawer() {
    const { dispatch } = this.props;
    dispatch(clickAddSpecialConditionButton());
    this.openSideDrawer('specialConditionSideDrawerIsOpen');
  }

  closeSpecialConditionSideDrawer() {
    this.setState({ specialConditionSideDrawerIsOpen: false });
  }

  setBuyerDefaultBrokerage() {
    this.setPartyDefaultBrokerage('buyer');
  }

  setSellerDefaultBrokerage() {
    this.setPartyDefaultBrokerage('seller');
  }

  async addNewCompany(newCompany, usedNgr){
    let newState = { ...this.state };
    newCompany.brokeragesForUser=[];
    newCompany.companyId = newCompany.id;
    newCompany.isManagedByUser=false;
    newCompany.partyType="company";
    if(includes(this.state.contractParty, 'Seller Company')) {
      newState.sellerParties = [...this.state.sellerParties, newCompany];
      newState.selectedSeller = newCompany;
      newState.fields.seller.companyId = {
        ...newState.fields.seller.companyId,
        value: newCompany.id,
        errors: [],
      };

      let sellerNgrs = await APIService.companies(newCompany.id).ngrs().appendToUrl('minimal/').get(this.props.userToken)
      const ngr = find(sellerNgrs, {ngrNumber: usedNgr} )
      this.getContacts(newCompany, 'seller', null, newCompany.representedById);

      newState.fields.seller.contactId = {
        ...newState.fields.seller.contactId,
        value: '',
        errors: [],
      };
      newState.fields.seller.ngrId = {
        ...newState.fields.seller.ngrId,
        value: ngr?.id || '',
        errors: [],
      };
      newState.sellerNgrs = sellerNgrs;
      newState.sellerContacts = [];
      newState.sellerEstablishmentDetails.company = newCompany;
    }
    else if(includes(this.state.contractParty, 'Buyer Company')) {
      newState.buyerParties = [...this.state.buyerParties, newCompany];
      newState.selectedBuyer = newCompany;
      newState.fields.buyer.companyId = {
        ...newState.fields.buyer.companyId,
        value: newCompany.id,
        errors: [],
      };

      let buyerNgrs = await APIService.companies(newCompany.id).ngrs().appendToUrl('minimal/').get(this.props.userToken)
      const ngr = find(buyerNgrs, {ngrNumber: usedNgr})
      this.getContacts(newCompany, 'buyer', null, newCompany.representedById);

      newState.fields.buyer.contactId = {
        ...newState.fields.buyer.contactId,
        value: '',
        errors: [],
      };
      newState.fields.buyer.ngrId = {
        ...newState.fields.buyer.ngrId,
        value: ngr?.id || '',
        errors: [],
      };
      newState.buyerNgrs = buyerNgrs;
      newState.buyerContacts = [];
      newState.buyerEstablishmentDetails.company = newCompany;
    }
    this.setState(newState);
    if(this.state.currentUser.companyId)
      this.props.dispatch(getCompanyCompaniesMinimal(this.state.currentUser.companyId, { include_parent_company: true, user_properties: 'brokerages_for_user' }));
  }

  openNgrSideDrawer(ngrEntity, type, representing) {
    ngrEntity.type = type;
    const companyId = get(ngrEntity, 'id');
    if(companyId && (isCurrentUserBelongsToCompany(companyId) || representing)) {
      const { dispatch } = this.props;
      const selectedEntity = find(this.props.allCompanyParties, {id: companyId});
      dispatch(canCreateNgr(companyId, 'company', selectedEntity, () => {
        this.props.handleAddCompanyNgrButtonClick();
        this.props.handleAddNgrButtonClick();
        this.props.isCurrentUserRepresentingAnyParty(representing);
        this.setState({ngrEntity: ngrEntity}, () => this.openSideDrawer('ngrSideDrawerIsOpen'));
      }));
    } 
    else
      this.alertNgrPermissionError();
    }

  // eslint-disable-next-line no-unused-vars
  handleNgrCreation(companyId, submitData) {
    let newState = { ...this.state };
    if(isEqual(get(this.state.ngrEntity, 'type'), 'Seller')) {
      newState.sellerNgrs = [...this.state.sellerNgrs, submitData];
      newState.fields.seller.ngrId.value = get(submitData, 'id');
    }
    else if(isEqual(get(this.state.ngrEntity, 'type'), 'Buyer')) {
      newState.buyerNgrs = [...this.state.buyerNgrs, submitData];
      newState.fields.buyer.ngrId.value = get(submitData, 'id');
    }
    this.setState(newState);
  }

  closeNgrSideDrawer() {
    this.setState({ ngrSideDrawerIsOpen: false });
  }

  setPartyDefaultBrokerage(partyType) {
    const party = this.getParty(partyType);
    if (party && party.brokeragesForUser[0]) {
      alertifyjs
        .confirm(
          'Warning',
          'Default values for this ' +
          upperFirst(partyType) +
          ' is already setup as ' +
          getBrokerageDisplayValue(party.brokeragesForUser[0], this.tonnageUnit()) +
          '. Do you want to update the default value?',
          () => {
            this._setPartyDefaultBrokerage(partyType);
          },
          () => {},
        )
        .set('labels', { ok: 'Yes', cancel: 'No' });
    } else {
      this._setPartyDefaultBrokerage(partyType);
    }
  }

  getParty(type) {
    return find(this.props.allCompanyParties, {id: this.state.fields[type].companyId.value});
  }

  getPartyBrokerage(type) {
    return find(this.state.fields.brokerages, b => {
      return b.type.value && b.type.value.toLowerCase() === type;
    });
  }

  _setPartyDefaultBrokerage(partyType) {
    const brokerage = this.getPartyBrokerage(partyType);
    const brokerages = [
      {
        feeType: brokerage.feeType.value,
        rate: includes(['% of Sale', 'Fee Per MT'], brokerage.feeType.value) ? parseFloat(brokerage.rate.value) : null,
        totalFee: brokerage.feeType.value === 'Flat Fee' ? parseFloat(brokerage.totalFee.value) : null,
        brokerCompanyId: this.props.currentUser.companyId,
      },
    ];
    const party = this.getParty(partyType);
    if (party) {
      this.props.dispatch(updateCompany(party.id, { brokerages: brokerages }));
    }
  }

  shouldShowSetDefaultBrokerageActionForBuyer() {
    return this.shouldShowSetDefaultBrokerageActionForParty('buyer');
  }

  shouldShowSetDefaultBrokerageActionForSeller() {
    return this.shouldShowSetDefaultBrokerageActionForParty('seller');
  }

  shouldShowSetDefaultBrokerageActionForParty(partyType) {
    if(this.state.fields[partyType].companyId.value) {
      const brokerage = this.getPartyBrokerage(partyType);
      return (
        brokerage.feeType.value !== 'Flat Fee' &&
        Boolean(
          (includes(['% of Sale', 'Fee Per MT'], brokerage.feeType.value) && brokerage.rate.value) ||
          (brokerage.feeType.value === 'Flat Fee' && brokerage.totalFee.value),
        )
      );
    } else {
      return false;
    }
  }

  handleCancelButtonClick = event => {
    event.preventDefault();
    window.location.hash = '/contracts';
  };

  showGradeField = () => {
    return (
      !this.state.fields.typeId.value ||
      includes(
        [CONTRACT_TYPES.FIXED_GRADE, CONTRACT_TYPES.MULTI_GRADE, CONTRACT_TYPES.FLOATING_MULTI_GRADE, CONTRACT_TYPES.AREA, CONTRACT_TYPES.SPOT, CONTRACT_TYPES.BLENDED],
        this.state.fields.typeId.value,
      )
    );
  };

  showSpreadGradesSection = () => {
    return (
      this.state.fields.typeId.value &&
      this.state.fields.commodityId.value &&
      includes([CONTRACT_TYPES.MULTI_GRADE, CONTRACT_TYPES.FLOATING_MULTI_GRADE, CONTRACT_TYPES.AREA, CONTRACT_TYPES.BLENDED], this.state.fields.typeId.value)
    );
  };

  handleFarmSideDrawer = (key, bool = false) => {
    this.setState({
      [key]: bool
    });
  };

  handleFarmSubmit = (key, data) =>{
    this.handleFarmSideDrawer(key, false);
    data = pick(data, ['id', 'name', 'ld']);
    if (key === 'consignorSideDrawer'){
      this.handleConsigneesConsignorsChange(data, this.state.consignorsIndex, null, 'consignors');
    }
    else if (key === 'consigneeSideDrawer'){
      this.handleConsigneesConsignorsChange(data, this.state.consigneesIndex, null, 'consignees');
    }
    this.setState({inputTextFarm: "", index: ""});
  };

  formatLDValue(ld) {
    if(isNumber(ld))
      return -1 * ld;

    return 0;
  }

  handleConsigneesConsignorsChange(item, index=0, setErrors = true, siteType='consignees') {
    const newState = { ...this.state };
    let shouldFetchDistance = false;
    if (item && item.inputValue) {
      set(newState, 'inputTextFarm', item.inputValue);
      set(newState, `${siteType}Index`, index);
      this.setState(newState, () => this.handleFarmSideDrawer(siteType == 'consignees' ? "consigneeSideDrawer" : 'consignorSideDrawer', true));
      return;
    }
    let checkChange = isEqual(get(item, 'id'), get(this.selectedContract, `${siteType == 'consignees' ? 'consigneesWithSites' : 'consignorsWithSites'}[${index}].handlerId`));
    if (isObject(item)) {
      newState.fields[siteType][index].handlerId.value = item.id;
      newState.fields[siteType][index].item = cloneDeep(item);
      newState.fields[siteType][index].position = parseInt(index) + 1;
      newState.fields[siteType][index].isBHC = get(item, 'isBhc');
      shouldFetchDistance = true;
      newState[siteType] = uniqBy([...newState[siteType], item], 'id');
    } else {
      newState.fields[siteType][index].handlerId.value = undefined;
      newState.fields[siteType][index].item = undefined;
      newState.fields[siteType][index].position = 1;
      newState.fields[siteType][index].ld.value = null;
      newState.fields[siteType][index].ld_db = 0;
      newState.fields[siteType][index].ld.validators = [];
      newState.fields[siteType][index].ld.errors = [];
      newState.fields[siteType][index].isBHC = false;
    }

    if(!event){
      forEach(newState.fields[siteType], (party, index) => {
        const site = find(newState[siteType], {id: party.handlerId.value});
        if(site){
          let test= this.getSiteLDByTrack(site, newState.fields.track.value, index, newState, siteType, party.ld.value, checkChange) || party.ld.value || 0;
          party.ld.value = test
        }
        });
    }
    else {
      let siteParty = get(newState.fields[siteType], index)
      const site = find(newState[siteType], {id: siteParty.handlerId.value});
      if(site){
        siteParty.ld.value = this.getSiteLDByTrack(site, newState.fields.track.value, index, newState, siteType, siteParty.ld.value, checkChange) || siteParty.ld.value || 0;
      }
    }
     
    this.setState(newState, () => {
      if (setErrors) {
        this.setFieldErrors(`${siteType}[${index}].handlerId`, get(item, 'id'));
      }
      if (shouldFetchDistance) {
        this.shouldFetchDistance = true;
        this.fetchDistance();
      }
    });
  }

  handleAddConsigneesConsignors = event => {
    const newState = { ...this.state };
    let site = event.currentTarget.id == 'consignees' ? cloneDeep(this.consigneeFields) : cloneDeep(this.consignorFields);
    site.handlerId.validators = [];
    newState.fields[event.currentTarget.id].push(site);
    this.setState(newState);
  };

  handleRemoveConsigneesConsignors = (id, index)  => {
    const removeStorage = index => {
      const newState = { ...this.state };
      newState.fields[id].splice(index, 1);
      newState[`${id}Sites`].splice(index, 1);
      if(index === 0){
        forEach(newState.fields[id], (party, partyIndex) => {
          const site = find(newState[id], {id: party.handlerId.value});
          if (partyIndex === 0 && newState.fields.pricePointId.value !== PRICE_POINTS.TRACK)
            party.ld.value = '0';
          else if(site)
            party.ld.value = this.getSiteLDByTrack(site, newState.fields.track.value, partyIndex, newState, id, party.ld.value) || party.ld.value || 0;
          party.position = parseInt(partyIndex) + 1;
        });
      }
      this.setState(newState);
    };
    const party = get(this.state.fields, `${id}[${index}]`);
    if (
      party &&
      get(party, 'handlerId.value')
    ) {
      alertifyjs.confirm(
        SITE_REMOVAL_TITLE,
        SITE_REMOVAL_MESSAGE,
        () => removeStorage(index),
        () => {},
      );
    } else {
      removeStorage(index);
    }
  };

  showAddConsigneeConsignorButton = id => {
    return get(this.state.fields, `${id}.length`) < 20;
  };

  handleConsigneesConsignorsSiteLDChange = (value, targetId, index, siteType, setErrors = true) => {
    const newState = { ...this.state };
    if (newState.fields[siteType][index]) {
      newState.fields[siteType][index].ld.value = value;
      newState.fields[siteType][index].ld.errors = [];
      this.setState(newState, () => {
        if (setErrors) {
          this.setFieldErrors(targetId, value);
        }
      });
    }
  };

  handleLdBlur = (value, targetId, index, siteType, setErrors = true) => {
    const newState = { ...this.state };
    if (newState.fields[siteType][index] && isEmpty(value)) {
      newState.fields[siteType][index].ld.value = '0';
      newState.fields[siteType][index].ld.errors = [];
      this.setState(newState, () => {
        if (setErrors) {
          this.setFieldErrors(targetId, value);
        }
      });
    }
  };

  canEditContractNumber() {
    if (!this.selectedContract) return true;

    const { accountedTonnage } = this.selectedContract;
    return !this.isEditingContract || (accountedTonnage || 0) ===  0;
  }

  isFieldDisabled = field => {
    if(this.isBlended() && (['tonnage', 'gradeId'].includes(field) || (field === 'price' && !this.state.useSinglePrice)))
      return true
    const status = get(this.selectedContract, 'statusDisplayName');
    if(includes(['seller.ngrId', 'buyer.ngrId', 'seller.contactId', 'buyer.contactId'], field))
      return false;

    if (includes(['contractIdentifier', 'contractNumber'], field) && this.canEditContractNumber()) return false;

    if (field === 'contractDate') return false;

    if(this.isSpotContract() && this.state.isSpotLocked) {
      if(includes(['typeId', 'seller.companyId', 'pricePointId'], field))
        return true
    }

    if (!this.isEditingContract) return false;

    if(includes(['price', 'consigneeLD', 'consignorLD'], field))
      return get(this.selectedContract, 'hasValidCommodityContractInvoice') || get(this.selectedContract, 'isCashPriced');

    if(includes(['spread'], field))
      return get(this.selectedContract, 'isCashPriced')
    if(
      includes(
        ['documentTypeId', 'commodityId', 'seller.companyId'], field
      )
    ) return true;

    const hasAccountedTonnage = get(this.selectedContract, 'accountedTonnage', 0) > 0;
    const hasRaisedInvoice = get(this.selectedContract, 'hasRaisedInvoice');
    const hasRaisedBrokerageInvoice = get(this.selectedContract, 'hasRaisedBrokerageInvoice');
    const brokeredById = get(this.selectedContract, 'administration.brokeredById');
    const typeId = get(this.selectedContract, 'typeId');
    const hasPlannedTitleTransfers = get(this.selectedContract, 'hasPlannedTitleTransfers');
    const externalArgs = get(this.selectedContract, 'externalArgs')

    if (includes(['typeId'], field) && this.isEditingContract &&
        includes(['Open', 'In Progress', 'Confirmed', 'Awaiting Confirmation', 'Action Pending'], status) &&
        !includes([CONTRACT_TYPES.AREA], typeId))
        return false;

    if (hasAccountedTonnage && includes(
      PICKUP_MANDATORY_PRICE_POINTS,
      this.state.fields.pricePointId.value,
    ) && includes(['consignor'], field))
      return true;

    if (
      hasAccountedTonnage &&
      includes(
        [
          'gradeId',
          'pricePointId',
          'track',
          'deliveryOnus',
          'conveyanceId',
          'area.area',
          'area.estimatedYield',
          'area.priceBy',
        ],
        field,
      )
    )
      return true;

    if (hasRaisedInvoice && includes(['administration.invoicing'], field)) return true;

    if (status == 'Closed') return true;

    if (
      status === 'Delivered' &&
      !includes(
        [
          'price',
          'consigneeLD', 'consignorLD',
          'paymentScaleId',
          'paymentTermId',
          'toleranceId',
          'administration.invoicing',
          'tonnage',
          'specialConditions',
          'generalConditions',
          'payableBy',
          'chargedOn',
          'brokerages',
          'season',
          'identifier',
          'contractIdentifier',
          'contractNumber'
        ],
        field,
      )
    )
      return true;

    if (includes(['payableBy', 'chargedOn', 'brokerages'], field) && (!brokeredById || hasRaisedBrokerageInvoice)) return true;
    if (status === 'Invoiced' && !includes(['payableBy', 'chargedOn', 'tonnage', 'brokerages', 'identifier', 'contractIdentifier', 'contractNumber'], field)) return true;

    if (
      status === 'Completed' &&
      !includes(
        ['price', 'paymentScaleId', 'paymentTermId', 'toleranceId', 'administration.invoicing', 'tonnage', 'payableBy', 'chargedOn', 'brokerages', 'consigneeLD', 'consignorLD', 'identifier', 'contractIdentifier', 'contractNumber'],
        field,
      )
    )
      return true;

    if (includes(['Open', 'Confirmed', 'In Progress'], status) && includes(['season'], field)
        && (hasAccountedTonnage || hasPlannedTitleTransfers))
      return true;

    if (
      status == 'In Progress' &&
      !includes(
        [
          'price',
          'consigneeLD', 'consignorLD',
          'paymentScaleId',
          'paymentTermId',
          'toleranceId',
          'seller.ngrId',
          'buyer.ngrId',
          'tonnage',
          'administration.invoicing',
          'area.area',
          'consignees',
          'deliveryStartDate',
          'deliveryEndDate',
          'specialConditions',
          'generalConditions',
          'payableBy',
          'chargedOn',
          'brokerages',
          'varietyId',
          'carryRate',
          'carryFrequency',
          'carryStartDate',
          'carryEndDate',
          'spread',
          'identifier',
          'contractIdentifier',
          'contractNumber'
        ],
        field,
      )
    )
      return true;


    if (includes(['Open', 'Confirmed'], status) && includes(['gradeId'], field)
        && (hasAccountedTonnage || hasPlannedTitleTransfers))
      return true;

    if (externalArgs && includes(['identifier', 'contractIdentifier', 'contractNumber'], field))
      return true;

    return false;
  };

  handleRepresentationChange = party => event => {
    let allowedActions = REASONS_TO_REPRESENT;

    if(party === 'buyer') {
      if(this.state.selectedBuyer){
        const buyer = this.state.selectedBuyer;
        let buyerContacts = this.filterContacts(cloneDeep(this.state.buyerContacts), event.target.checked);
        if(event.target.checked){
          const buyerCompanyId = get(buyer, 'companyId') || get(buyer, 'id');
          buyerContacts = filter(buyerContacts, contact => includes([this.props.companyId, buyerCompanyId], contact.companyId));
        }
        this.setState({ representingBuyer: event.target.checked, buyerContacts: buyerContacts });
      }
    } else if (party === 'seller') {
      allowedActions = REASONS_TO_REPRESENT_FOR_SELLER;
      if(this.state.selectedSeller){
        const seller = this.state.selectedSeller;
        let sellerContacts = this.filterContacts(cloneDeep(this.state.sellerContacts), event.target.checked);
        if(event.target.checked){
          const sellerCompanyId = get(seller, 'companyId') || get(seller, 'id');
          sellerContacts = filter(sellerContacts, contact => includes([this.props.companyId, sellerCompanyId], contact.companyId));
        }
        this.setState({ representingSeller: event.target.checked, sellerContacts: sellerContacts });
      }
    }
    if (event.target.checked) {
      this.displayRepresentConfirmAlert(party, allowedActions);
    }
  };

  handleSustainableCommodity(event) {
    this.setState({ sustainableCommodity: event.target.checked });
  }

  isSustainableContractCheckDisabled(){
    if (this.state.isCashPriced)
      return ((this.isEditingContract && this.state.isCashPriced) || !this.isCommoditySustainable()) 
    return !this.isCommoditySustainable()

  }

  displayRepresentConfirmAlert(party, actions) {
    let partyName = startCase(party);
    actions = '<li>' + actions.join('</li><li>');
    alertifyjs.alert(
      'Warning',
      `<div><p>You should only check Representing checkbox for ${partyName} party if you have the authority to take following actions <b>on behalf of the ${partyName}</b></p><ul>${actions}</ul></div>`,
      () => {},
    );
  }

  shouldDisableRepresentationChecks(party) {
    return this.isEditingContract ||
           this.isFieldDisabled(`${party}.companyId`) ||
           this.isCurrentUserParty(null, party) ||
           (
             isCurrentUserBroker() && (this.isBrokerNoteDocumentType() || this.isSalesConfirmationDocumentType())
           );
  }

  isAreaContract() {
    return this.state.fields.typeId.value === CONTRACT_TYPES.AREA;
  }

  isBrokerNoteDocumentType() {
    return this.checkDocumentType('broker_note');
  }

  isSalesConfirmationDocumentType() {
    return this.checkDocumentType('sales_confirmation');
  }

  isContractDocumentType() {
    return this.checkDocumentType('contract');
  }

  checkDocumentType(name) {
    const selectedDocumentType = find(this.props.documentTypes, { id: this.state.fields.documentTypeId.value });
    return selectedDocumentType && selectedDocumentType.name === name;
  }

  getLDLabel = (index = -1, siteType) => {
    let ld_db = index < 0 ? '' : ' (' + this.state.fields[siteType][index].ld_db + ')';
    return (this.state.fields.pricePointId.value == PRICE_POINTS.DELIVERED_BUYER ? 'Price diff.' : 'LD') + ld_db ;
  };

  openSideDrawer(type) {
    const sideDrawer = cloneDeep(DEFAULT_SIDE_DRAWERS_STATE);
    if (type) {
      sideDrawer[type] = true;
    }
    this.setState(sideDrawer);
  }

  setDefaultGeneralCondition(condition) {
    this.openSideDrawer();
    if (condition) {
      this.handleConditionSelector(condition.id, 'generalConditionsSelector', condition);
    }
  }

  setDefaultSpecialCondition(condition) {
    this.openSideDrawer();
    if (condition) {
      this.handleConditionSelector(condition.id, 'specialConditionsSelector', condition);
    }
  }

  sellerSideDrawer() {
    const companyId = get(this.state.fields, 'seller.companyId.value');
    return this.props.isEmployeeSideDrawerOpened &&
           companyId &&
           <SideDrawer
             isOpen={ this.state.sellerSideDrawerIsOpen }
             title={ getLabelForEmployeeForm(this.state.selectedSeller) }
             onClose={this.closeSellerSideDrawer}
             app="officeEmployee"
             canCreate={true}
           >
             <CreateEmployee
               closeDrawer={this.closeSellerSideDrawer}
               canAccessAny={true}
               companyId={companyId}
               selectedCompany={this.state.selectedSeller}
               establishmentDetails={this.state.sellerEstablishmentDetails}
               getContacts={this.getContacts}
               fromContract={true}
             />
           </SideDrawer>;
  }

  buyerSideDrawer() {
    const companyId = get(this.state.fields, 'buyer.companyId.value');
    return this.props.isEmployeeSideDrawerOpened &&
           companyId &&
           <SideDrawer
             isOpen={this.state.buyerSideDrawerIsOpen}
             title={getLabelForEmployeeForm(this.state.selectedBuyer)}
             onClose={this.closeBuyerSideDrawer}
             app="officeEmployee"
             canCreate={true}
           >
             <CreateEmployee
               closeDrawer={this.closeBuyerSideDrawer}
               canAccessAny={true}
               companyId={companyId}
               selectedCompany={this.state.selectedBuyer}
               establishmentDetails={this.state.buyerEstablishmentDetails}
               getContacts={this.getContacts}
               fromContract={true}
             />
           </SideDrawer>;
  }

  getBrokers() {
    return isCurrentUserBroker() ? [this.props.currentUser.company] : this.state.brokerCompanies;
  }

  getPriceByMinDate() {
    let minimumDate = moment().add(0, 'days');
    if (this.isEditingContract) {
      const priceBy = get(this.selectedContract, 'area.priceBy');
      minimumDate = priceBy ? moment(priceBy) : null;
    }
    return minimumDate;
  }

  isConsigneeAndSiteFieldDisabled(index) {
    if (!this.isEditingContract || includes(
      PICKUP_MANDATORY_PRICE_POINTS,
      this.state.fields.pricePointId.value,
    )){
      return false;
    }
    const status = get(this.selectedContract, 'statusDisplayName');
    if (includes(['Closed', 'Invoiced'], status)) {
      return true;
    }

    const hasAccountedTonnage = get(this.selectedContract, 'accountedTonnage', 0) > 0;
    const consigneeCount = get(this.selectedContract, 'consigneesWithSites', []).length;
    if (hasAccountedTonnage || includes(['In Progress', 'Delivered', 'Completed'], status)) {
      return index < consigneeCount;
    }
    return false;
  }

  isConsignorAndSiteFieldDisabled(index) {
    if (!this.isEditingContract || !includes(
      PICKUP_MANDATORY_PRICE_POINTS,
      this.state.fields.pricePointId.value,
    )) {
      return false;
    }
    const status = get(this.selectedContract, 'statusDisplayName');
    if (includes(['Closed', 'Invoiced'], status)) {
      return true;
    }
    const hasAccountedTonnage = get(this.selectedContract, 'accountedTonnage', 0) > 0;
    const consignorCount = get(this.selectedContract, 'consignorsWithSites', []).length;
    if (hasAccountedTonnage || includes(['In Progress', 'Delivered', 'Completed'], status)) {
      return index < consignorCount;
    }
    return false;
  }

  isLdDisabled = siteType => {
    if(includes(
      PICKUP_MANDATORY_PRICE_POINTS,
      this.state.fields.pricePointId.value,
    ) && siteType == 'consignors')
      return true;
  }

  getSelectedParties = () => {
    if (this.isEditingContract) {
      if (get(this.selectedContract, 'isBuyer') && get(this.selectedContract, 'isSeller')) {
        if (get(this.selectedContract, 'seller.companyId') === this.props.currentUser.companyId) return ['buyer'];
        else if (get(this.selectedContract, 'buyer.companyId') === this.props.currentUser.companyId) return ['seller'];
        return ['buyer', 'seller'];
      }
      else if (get(this.selectedContract, 'isBuyer')) return ['seller'];
      else if (get(this.selectedContract, 'isSeller')) return ['buyer'];
      return ['buyer', 'seller'];
    } else {
      const sellerCompanyId = get(this.state.fields, 'seller.companyId.value');
      const buyerCompanyId = get(this.state.fields, 'buyer.companyId.value');
      if (this.props.currentUser.companyId !== sellerCompanyId && this.props.currentUser.companyId !== buyerCompanyId) return ['buyer', 'seller'];
      else if (this.props.currentUser.companyId === sellerCompanyId) return ['buyer'];
      else if (this.props.currentUser.companyId === buyerCompanyId) return ['seller'];
    }
    return ['buyer', 'seller'];
  };


  canAddCustomEmailToParties = () => {
    let parties = []
    const currentUserCompanyId = this.props.currentUser.companyId
    if(this.isEditingContract) {
      parties = [this.selectedContract?.party]
      if(this.selectedContract?.isBuyer || !this.selectedContract?.buyer?.isParticipatingInTransaction)
        parties.push('buyer')
      if(this.selectedContract?.isSeller || !this.selectedContract?.seller?.isParticipatingInTransaction)
        parties.push('seller')
    } else {
      if (currentUserCompanyId === this.state.fields.seller.companyId.value || this.state.representingSeller || !this.state.selectedSeller?.transactionParticipation)
        parties.push('seller');
      if (currentUserCompanyId === this.state.fields.buyer.companyId.value || this.state.representingBuyer || !this.state.selectedBuyer?.transactionParticipation)
        parties.push('buyer');
    }
    if(this.state.fields.administration.brokeredById.value === currentUserCompanyId) {
      parties.push('broker')
    } else if(this.state.fields.administration.brokeredById.value) {
      const broker = find(this.state.brokerCompanies, {id: this.state.fields.administration.brokeredById.value})
      if(broker?.transactionParticipation){
        parties = []
        if(currentUserCompanyId === this.state.fields.seller.companyId.value || this.state.representingSeller)
          parties.push('seller')
        if(currentUserCompanyId === this.state.fields.buyer.companyId.value || this.state.representingBuyer)
          parties.push('buyer')
      }
    }
    return uniq(compact(parties))
  }

  getPartyEmails() {
    return {
      seller: get(find(this.state.sellerContacts, { id: this.state.fields.seller.contactId.value }), 'email'),
      buyer: get(find(this.state.buyerContacts, { id: this.state.fields.buyer.contactId.value }), 'email'),
      broker: get(find(this.state.brokerEmployees, { id: this.state.fields.administration.brokerContactId.value }), 'email'),
    };
  }

  getPartyContacts() {
    return {
      seller: this.state.sellerContacts,
      buyer: this.state.buyerContacts,
      broker: this.state.brokerEmployees,
    };
  }

  hasQuantityBasedCommodity = commodityId => Boolean(this.getSelectedCommodity(commodityId || this.state.fields.commodityId.value)?.isQuantityBased)

  getSelectedCommodity = commodityId => {
    const id = commodityId || this.state.fields.commodityId.value
    return id ? find(this.props.commodities, {id: id}) : null
  }

  quantityLabel() {
    return get(this.getSelectedCommodity(), 'quantityLabel') || ''
  }

  quantityUnit() {
    if (this.hasQuantityBasedCommodity())
      return this.getSelectedCommodity().unit
  }

  hidePriceField = (newState) => {
    newState.fields.price.value = null;
    newState.fields.price.errors = [];
    newState.fields.price.validators = [];
  };

  getInvoicingList() {
    if (this.state.representingSeller || get(this.state.selectedSeller, 'companyId') === this.state.currentUser.company.id) return INVOICINGS;
    if (this.state.representingBuyer || get(this.state.selectedBuyer, 'companyId') === this.state.currentUser.company.id) return NEW_INVOICINGS;
    return INVOICINGS;
  }

  getTemplatesService(queryString, callback) {
    const { userToken } = this.props;
    APIService.contracts().appendToUrl('templates/').get(userToken, null, queryString).then(templates => {
      callback(templates);
    });
  }

  getContractTypeItems(){
    const status = get(this.selectedContract, 'statusDisplayName');
    const typeId = get(this.selectedContract, 'typeId');

    if (this.isEditingContract &&
        includes(['In Progress', 'Confirmed', 'Awaiting Confirmation'], status) &&
        includes([CONTRACT_TYPES.FIXED_GRADE], typeId))
      return filter(this.state.types, obj =>
        includes([CONTRACT_TYPES.MULTI_GRADE, CONTRACT_TYPES.FLOATING_MULTI_GRADE, CONTRACT_TYPES.FIXED_GRADE], obj.id)
      );
    return this.state.types;
  }

  isSiteMandatoryBasedOnAllocation(index, type) {
    var isSiteRequired = false;
    if(this.selectedContract && this.isAllocatedContract) {
      let selectedContractPricePoint = get(this.selectedContract, 'pricePointId');
      if(isEqual(type, 'pickup')) {
        let filteredContractSites = filter(this.selectedContract.consignorsWithSites, { handlerId: get(this.state.fields, `consignors.${index}.handlerId.value`) })
        if(!isEmpty(filteredContractSites))
          isSiteRequired = includes(PICKUP_MANDATORY_PRICE_POINTS, selectedContractPricePoint)
      }
      else if(isEqual(type, 'delivery')) {
        let filteredContractSites = filter(this.selectedContract.consigneesWithSites, { handlerId: get(this.state.fields, `consignees.${index}.handlerId.value`) })
        if(!isEmpty(filteredContractSites))
          isSiteRequired = includes(DELIVERY_MANDATORY_PRICE_POINTS, selectedContractPricePoint)
      }
    }
    return isSiteRequired;
  }

  ordersGradeDifferentFromBaseGrade() {
    return map(get(this.selectedContract, 'freightOrdersGradeOtherThanContractBaseGrade') || [], 'gradeId')
  }

  movementsGradeDifferentFromBaseGrade() {
    return map(get(this.selectedContract, 'freightContractsGradeOtherThanContractBaseGrade') || [], 'gradeId')
  }

  titleTransfersGradesDifferentFromBaseGrade() {
    return map(get(this.selectedContract, 'titleTransfersGradeOtherThanContractBaseGrade') || [], 'gradeId')
  }

  getFeeTypes = () => {
    const unit = this.priceUnit()
    if(this.isMeterCubeCommodityCommodity())
      return [...VOLUME_BROKERAGE_FEE_TYPES].map(fee => ({id: fee.id, name: fee.name.replace('unit', unit)}))

    return [...BROKERAGE_FEE_TYPES].map(fee => ({id: fee.id, name: fee.name.replace(' MT', ` ${unit}`)}))
  }

  handleConsignorLocationClick(item) {
    if (item)
      this.setState({openConsignorFormForFarm: item});
  }

  handleConsigneeLocationClick(item) {
    if (item)
      this.setState({openConsigneeFormForFarm: item});
  }

  closeFarmDrawer(id) {
    const newState = {...this.state};
    set(newState, id, undefined);
    this.setState(newState);
  }

  updateEntities = (farmId, address, mobile, checkpoint) => {
    let handlers = checkpoint == 'pickup' ? this.state.consignors : this.state.consignees
    let updateSelectedHandlers = checkpoint == 'pickup' ? this.state.fields.consignors : this.state.fields.consignees
    if (find(handlers, {id: farmId})) {
      const newState = {...this.state};
      newState[handlers] = map(handlers, item => {
        if(item?.id == farmId) {
          item.address=address
          item.mobile = mobile
        }
        return item
      })
      newState[updateSelectedHandlers] = map(updateSelectedHandlers, selectedHandler => {
        if(selectedHandler?.item && selectedHandler?.item?.id == farmId) {
          selectedHandler.item.address = address
          selectedHandler.item.mobile = mobile
        }
        return selectedHandler
      })
      this.setState(newState)
    }
  }

  handleSinglePriceChange = event => {
    let isChecked = event.target.checked;
    const newState = {...this.state};
    newState.useSinglePrice = isChecked;
    if (!isEmpty(this.state.fields.spread.value) && isChecked) {
      const updatedSpread = this.state.fields.spread.value.map(item => ({
        ...item,
        gist: `${item.gradeName}: ${item.quantity}${this.priceUnit()}`,
        price: null
      }));
      newState.fields.spread.value = [...updatedSpread];
    }
    this.setState(newState);
  }

  isAwaitingConfirmation() {
    return get(this.selectedContract, 'statusDisplayName') === 'Awaiting Confirmation';
  }

  isCurrentUserContractCreator() {
    return get(this.selectedContract, 'createdBy.companyId') === currentUserCompany().id;
  }

  isCreatorAmendingAwaitingConfirmationContract() {
    return this.isEditingContract && this.isAwaitingConfirmation() && this.isCurrentUserContractCreator();
  }

  render() {
    const allowPastDates = true;
    const canRepresent = this.canRepresent();
    const priceFieldLabel = this.getPriceFieldLabel();
    const isBrokerSalesConfirmation = this.isSalesConfirmationDocumentType() && isCurrentUserBroker()
    const commodityUnit = this.tonnageUnit()
    const priceUnit = this.priceUnit()
    const foreignCurrencyAllowed = currentUserCompany()?.foreignCurrencyAllowed
    const rateLabel = this.isMeterCubeCommodityCommodity() ? `Rate (per/${priceUnit})` : `Rate (per/${priceUnit}) (Optional)`
    const maxTonnageLabel = `Maximum ${this.tonnageLabel}`
    const currentUserNotBuyerAndCanCreateContractWithAcceptanceRequired = this.currentUserNotBuyerAndCanCreateContractWithAcceptanceRequired()
    const currentUserNotSellerAndCanCreateContractWithAcceptanceRequired = this.currentUserNotSellerAndCanCreateContractWithAcceptanceRequired()
    const emailDialogFieldsDisabled = currentUserNotBuyerAndCanCreateContractWithAcceptanceRequired || currentUserNotSellerAndCanCreateContractWithAcceptanceRequired
    const isBlended = this.isBlended();
    const isAcceptanceRequired = this.isCreatorAmendingAwaitingConfirmationContract() ? false : emailDialogFieldsDisabled;
    return (
      <div ref={this.formRef} id='contract-content'>
        <form onSubmit={this.handleSubmit} noValidate>
          <div className='cardForm'>
            <h4 className='cardForm-title'>General</h4>
            <div className='cardForm-content'>
              <div className='col-md-5'>
                {!this.isEditingContract && (
                   <div className='col-md-12 padding-reset form-wrap' style={{marginTop: '16px'}}>
                     <QuickContract
                       id="quickContract"
                       onChange={this.handleQuickContractChange}
                       service={this.getTemplatesService}
                       placeholder="Quick Contract (Type to search...)"
                     />
                   </div>
                )}
                <div className='col-md-12 padding-reset form-wrap'>
                  <TextField
                    inputRef={this.fieldRef['identifier']}
                    error={!isEmpty(this.state.fields.identifier.errors[0])}
                    id='identifier'
                    label={this.state.fields.identifier.label}
                    placeholder='Please enter 14 digit unique number'
                    style={{ float: 'left', marginTop: '-14px' }}
                    value={this.state.fields.identifier.value}
                    inputProps={{
                      maxLength: 14,
                    }}
                    fullWidth
                    helperText={this.state.fields.identifier.errors[0]}
                    onChange={this.handleIdentifierChange}
                    onBlur={this.handleBlur}
                    disabled={this.isFieldDisabled('identifier')}
                    variant='standard'
                  />
                </div>

                <div className='col-md-12 padding-reset form-wrap'>
                  <CommonDatePicker
                    id='contractDate'
                    setRef={this.fieldRef['contractDate']}
                    floatingLabelText='Contract Date'
                    onChange={this.handleContractDateChange}
                    errorText={this.state.fields.contractDate.errors[0]}
                    value={this.state.fields.contractDate.value}
                  />
                </div>

                {this.shouldDisplayOptionalContractField() && (
                   <div className='col-md-12 padding-reset form-wrap'>
                     {!this.canEditContractNumber() ? (
                        <CommonTextField
                          id='contractIdentifier'
                          label='Contract Number (Optional)'
                          onChange={this.handleIdentifierChange}
                          value={this.state.fields.contractIdentifier.value || ''}
                          disabled={this.isFieldDisabled('contractIdentifier')}
                        />
                     ) : (
                        <TextField
                          error={!isEmpty(this.state.fields.contractIdentifier.errors[0])}
                          inputRef={this.fieldRef['contractIdentifier']}
                          id='contractIdentifier'
                          onChange={this.handleIdentifierChange}
                          label='Contract Number (Optional)'
                          placeholder='Please enter 14 digit unique number'
                          style={{ float: 'left' }}
                          value={this.state.fields.contractIdentifier.value || ''}
                          inputProps={{
                            maxLength: 14,
                          }}
                          fullWidth
                          helperText={this.state.fields.contractIdentifier.errors[0]}
                          onBlur={this.handleBlur}
                          disabled={this.isFieldDisabled('contractIdentifier')}
                          variant='standard'
                        />
                     )}
                   </div>
                )}
              </div>
              <div className='col-md-5 col-md-offset-1'>
                <div className='col-md-12 form-wrap padding-reset' style={this.isEditingContract ? { display: 'none' } : { display: 'inline-block' }}>
                  <CommonSelect
                    id='documentTypeId'
                    setRef={this.fieldRef['documentTypeId']}
                    onChange={this.handleDocumentTypeChange}
                    floatingLabelText='Document Type'
                    errorText={get(this.state, 'fields.documentTypeId.errors[0]', '')}
                    selectConfig={{ text: 'displayName', value: 'id' }}
                    items={this.state.documentTypes}
                    value={this.state.fields.documentTypeId.value}
                    disabled={this.isFieldDisabled('documentTypeId')}
                  />
                </div>
                <div className='col-md-12 form-wrap padding-reset' style={this.isEditingContract ? { display: 'inline-block' } : { display: 'none' }}>
                  <CommonTextField
                    id='documentTypeIdDisplayName'
                    label='Document Type'
                    value={get(this.selectedContract, 'documentType.displayName')}
                    disabled={this.isFieldDisabled('documentTypeId')}
                  />
                </div>
                <div className='col-md-12 form-wrap padding-reset'>
                  <div>
                    {
                      this.isEditingContract ?
                      <CommonTextField
                        id="commodityId"
                        label="Commodity"
                        value={get(this.selectedContract,'commodity.displayName')}
                        disabled={this.isFieldDisabled('commodityId')}
                      />
                      : <CommodityAutoComplete
                          id="commodityId"
                          setRef={this.fieldRef["commodityId"]}
                          onChange={this.commodityFieldChange}
                          floatingLabelText="Commodity"
                          commodityId={this.state.fields.commodityId.value}
                          style={{float: 'right'}}
                          errorText={get(this.state, 'fields.commodityId.errors[0]', '')}
                          disabled={this.isFieldDisabled('commodityId')}
                      />
                    }
                    <FormControlLabel
                    id='sustainableCommodity'
                      control={<Checkbox size='small' id='sustainableCommodity' color="primary" value="sustainableCommodity" checked={this.state.sustainableCommodity}
                    onChange={this.handleSustainableCommodity} disabled={this.isSustainableContractCheckDisabled()} />}
                    label="Sustainable"
                  />
                  </div>
                </div>
                <div className='col-md-12 form-wrap padding-reset' style={{marginTop: '16px'}}>
                  <CommonTextField
                    id='lotNumber'
                    label='Lot No. (Optional)'
                    placeholder='Lot No. (Optional)'
                    onChange={this.handleFieldChange}
                    value={get(this.state.fields, 'lotNumber.value')}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='cardForm'>
            <h4 className='cardForm-title'>Counterparties</h4>
            {this.state.sellerParties ? (
               <div className="cardForm-content col-md-5">
                 <div className="col-md-12 form-wrap padding-reset">
                   {
                     (get(this, 'selectedContract.seller.company') && this.isEditingContract) || this.state.isSpotLocked ?
                     <CommonTextField
                       id="seller.companyId"
                       label="Seller Name"
                       value={this.state.isSpotLocked ? this.props.currentUser?.company?.name : get(this, 'selectedContract.seller.company.displayName')}
                       disabled
                       fullWidth
                     /> :
                     <React.Fragment>
                       <CommonAutoSelect
                         items={uniqBy(this.state.sellerParties, 'id')}
                         setRef={this.fieldRef["seller.companyId"]}
                         label="Seller Name"
                         disabled={this.state.disableSeller || this.isFieldDisabled('seller.companyId')}
                         id="seller.companyId"
                         selectedItem={this.state.selectedSeller}
                         value={this.state.fields.seller.companyId.value}
                         onChange={this.handleSellerCompanyIdChange}
                         onBlur={this.handleBlur}
                         errorText={this.state.fields.seller.companyId.errors[0]}
                       />
                       <AddCircleIconWithTooltip
                         id="addSellerCompany"
                         onClick={() => this.openCompanySideDrawer("Add Seller Company")}
                         title="Add Seller Company"
                       />
                     </React.Fragment>
                   }
                   {
                     canRepresent &&
                     <FormControlLabel
                       control={<Checkbox color="primary" value="seller" checked={this.state.representingSeller} onChange={this.handleRepresentationChange('seller')} disabled={this.shouldDisableRepresentationChecks('seller')} />}
                       label="Representing"
                     />
                   }
                 </div>
                 <div className="col-md-12 form-wrap padding-reset">
                   <CommonSelect
                     items={this.state.sellerContacts}
                     setRef={this.fieldRef["seller.contactId"]}
                     id="seller.contactId"
                     label="Seller Contact"
                     value={this.state.fields.seller.contactId.value}
                     floatingLabelText="Seller Contact"
                     errorText={this.state.fields.seller.contactId.errors[0]}
                     onChange={this.handleSelectFieldChange}
                     disabled={this.isFieldDisabled('seller.contactId')}
                   />
                   {this.state.fields.seller.companyId.value && <AddCircleIconWithTooltip
                                                                  id="addSellerContact"
                                                                  onClick={() => this.openSellerSideDrawer()}
                                                                  title="Add Seller Contact"
                                                                  disabled={this.isFieldDisabled('seller.contactId')}
                   />}
                 </div>
                 <div className="col-md-12 form-wrap padding-reset">
                   <CommonAutoSelect
                     items={this.state.sellerNgrs || []}
                     dataSourceConfig={{text: 'ngrNumber', value: 'id'}}
                     id="seller.ngrId"
                     setRef={this.fieldRef["seller.ngrId"]}
                     label="Seller NGR (Optional)"
                     value={this.state.fields.seller.ngrId.value}
                     errorText={this.state.fields.seller.ngrId.errors[0]}
                     onChange={this.handleSelectFieldChange}
                     onBlur={this.handleBlur}
                     disabled={this.isFieldDisabled('seller.ngrId')}
                   />
                   {this.state.fields.seller.companyId.value && <AddCircleIconWithTooltip
                                                                  id="addSellerNgr"
                                                                  onClick={() => this.openNgrSideDrawer(this.state.selectedSeller, "Seller", this.state.representingSeller)}
                                                                  title="Add Seller NGR"
                                                                  disabled={this.isFieldDisabled('seller.ngrId')}
                   />}
                 </div>
               </div>
            ) : null}
            {this.state.buyerParties ? (
               <div className="cardForm-content col-md-5 col-md-offset-1">
                 <div className="col-md-12 form-wrap padding-reset">
                   {
                     get(this, 'selectedContract.buyer.company') && this.isEditingContract ?
                     <CommonTextField
                       id="buyer.companyId"
                       label="Buyer Name"
                       value={get(this, 'selectedContract.buyer.company.displayName')}
                       disabled
                       fullWidth
                     /> :
                     <React.Fragment>
                       <CommonAutoSelect
                         items={uniqBy(this.state.buyerParties, 'id')}
                         setRef={this.fieldRef["buyer.companyId"]}
                         id="buyer.companyId"
                         label="Buyer Name"
                         value={this.state.fields.buyer.companyId.value}
                         errorText={this.state.fields.buyer.companyId.errors[0]}
                         onChange={this.handleBuyerCompanyIdChange}
                         selectedItem={this.state.selectedBuyer}
                         onBlur={this.handleBlur}
                         disabled={this.isFieldDisabled('buyer.companyId')}
                       />
                       <AddCircleIconWithTooltip
                         id="addBuyerCompany"
                         onClick={() => this.openCompanySideDrawer('Add Buyer Company')}
                         title="Add Buyer Company"
                       />
                     </React.Fragment>
                   }
                   {
                     canRepresent &&
                     <FormControlLabel
                       control={<Checkbox color="primary" value="buyer" checked={this.state.representingBuyer} onChange={this.handleRepresentationChange('buyer')} disabled={this.shouldDisableRepresentationChecks('buyer')} />}
                       label="Representing"
                     />
                   }
                 </div>
                 <div className="col-md-12 form-wrap padding-reset">
                   <CommonSelect
                     items={this.state.buyerContacts}
                     setRef={this.fieldRef["buyer.contactId"]}
                     style={{float: 'right'}}
                     id="buyer.contactId"
                     floatingLabelText="Buyer Contact"
                     label="Buyer Contact"
                     value={this.state.fields.buyer.contactId.value}
                     errorText={this.state.fields.buyer.contactId.errors[0]}
                     onChange={this.handleSelectFieldChange}
                     disabled={this.isFieldDisabled('buyer.contactId')}
                   />
                   {this.state.fields.buyer.companyId.value && <AddCircleIconWithTooltip
                                                                 id="addBuyerContact"
                                                                 onClick={() => this.openBuyerSideDrawer()}
                                                                 title="Add Buyer Contact"
                                                                 disabled={this.isFieldDisabled('buyer.contactId')}
                   />}
                 </div>
                 <div className="col-md-12 form-wrap padding-reset">
                   <CommonAutoSelect
                     items={this.state.buyerNgrs || []}
                     setRef={this.fieldRef["buyer.ngrId"]}
                     id="buyer.ngrId"
                     dataSourceConfig={{text: 'ngrNumber', value: 'id'}}
                     label="Buyer NGR (Optional)"
                     value={this.state.fields.buyer.ngrId.value}
                     errorText={this.state.fields.buyer.ngrId.errors[0]}
                     onChange={this.handleSelectFieldChange}
                     onBlur={this.handleBlur}
                     disabled={this.isFieldDisabled('buyer.ngrId')}
                   />
                   {this.state.fields.buyer.companyId.value && <AddCircleIconWithTooltip
                                                                 id="addBuyerNgr"
                                                                 onClick={() => this.openNgrSideDrawer(this.state.selectedBuyer, "Buyer", this.state.representingBuyer)}
                                                                 title="Add Buyer NGR"
                                                                 disabled={this.isFieldDisabled('buyer.ngrId')}
                   />}
                 </div>
               </div>
            ) : null}
          </div>

          <div className='cardForm'>
            <h4 className='cardForm-title'>Contract Details</h4>
            <div className='cardForm-content col-md-5'>
              <div className='col-md-12 form-wrap padding-reset'>
                <CommonSelect
                  id='typeId'
                  setRef={this.fieldRef['typeId']}
                  onChange={this.handleTypeIdFieldChange}
                  floatingLabelText='Contract Type'
                  style={{ float: 'left' }}
                  errorText={get(this.state, 'fields.typeId.errors[0]', '')}
                  selectConfig={{ text: 'displayName', value: 'id' }}
                  items={this.getContractTypeItems()}
                  value={this.state.fields.typeId.value}
                  disabled={this.isFieldDisabled('typeId')}
                />
              </div>
              {this.hasQuantityBasedCommodity() ? (
                 <div className='col-md-12 padding-reset form-wrap'>
                   <div className='col-md-6 no-left-padding'>
                     <NumberField
                       error={!isEmpty(this.state.fields.quantity.errors[0])}
                       inputRef={this.fieldRef['quantity']}
                       id='quantity'
                       label={this.quantityLabel()}
                       placeholder={this.quantityLabel()}
                       value={this.state.fields.quantity.value || ''}
                       fullWidth
                       helperText={this.state.fields.quantity.errors[0]}
                       onChange={this.handleQuantityFieldChange}
                       style={{ float: 'left' }}
                       onBlur={this.handleQuantityFieldChange}
                       maxValue={999999999.99}
                       InputProps={{
                         endAdornment: (
                           <InputAdornment position='end' style={{ color: 'rgb(162,162,162)' }}>
                             {this.quantityUnit()}
                           </InputAdornment>
                         ),
                       }}
                       disabled={this.isFieldDisabled('tonnage')}
                       variant="standard" />
                   </div>
                   <div className='col-md-6 no-right-padding'>
                     <NumberField
                       error={!isEmpty(this.state.fields.tonnage.errors[0])}
                       inputRef={this.fieldRef['tonnage']}
                       id='tonnage'
                       label={this.isAreaContract() ? maxTonnageLabel : this.tonnageLabel}
                       placeholder={this.isAreaContract() ? maxTonnageLabel : this.tonnageLabel}
                       value={this.state.fields.tonnage.value || ''}
                       fullWidth
                       helperText={this.state.fields.tonnage.errors[0]}
                       onChange={this.handleTonnageFieldChange}
                       style={{ float: 'left' }}
                       onBlur={this.handleTonnageFieldChange}
                       maxValue={999999999.99}
                       InputProps={{
                         endAdornment: (
                           <InputAdornment position='end' style={{ color: 'rgb(162,162,162)' }}>
                             {MT_UNIT}
                           </InputAdornment>
                         ),
                       }}
                       disabled={this.isFieldDisabled('tonnage')}
                       variant="standard" />
                   </div>
                 </div>
              ) : (
                 <div className='col-md-12 form-wrap padding-reset'>
                   <NumberField
                     error={!isEmpty(this.state.fields.tonnage.errors[0])}
                     inputRef={this.fieldRef['tonnage']}
                     id='tonnage'
                     label={this.isAreaContract() ? maxTonnageLabel : this.tonnageLabel}
                     placeholder={this.isAreaContract() ? maxTonnageLabel : this.tonnageLabel}
                     value={this.state.fields.tonnage.value || ''}
                     fullWidth
                     helperText={this.state.fields.tonnage.errors[0]}
                     onChange={this.handleTonnageFieldChange}
                     style={{ float: 'left' }}
                     onBlur={this.handleTonnageFieldChange}
                     maxValue={999999999.99}
                     InputProps={{
                       endAdornment: (
                         <InputAdornment position='end' style={{ color: 'rgb(162,162,162)' }}>
                           {commodityUnit}
                         </InputAdornment>
                       ),
                     }}
                     disabled={this.isFieldDisabled('tonnage')}
                     variant="standard" />
                 </div>
              )}
              {this.isAreaContract() && (
                 <div className='col-md-12 form-wrap padding-reset'>
                   <NumberField
                     error={!isEmpty(this.state.fields.area.area.errors[0])}
                     id='area.area'
                     inputRef={this.fieldRef['area']}
                     label='Area'
                     value={this.state.fields.area.area.value || ''}
                     fullWidth
                     helperText={this.state.fields.area.area.errors[0]}
                     onChange={this.handleFieldChange}
                     style={{ float: 'left' }}
                     onBlur={this.handleBlur}
                     maxValue={99999.99}
                     InputProps={{
                       endAdornment: (
                         <InputAdornment position='end' style={{ color: 'rgb(162,162,162)' }}>
                           {this.countryConfig?.farmField?.sizeUnit}
                         </InputAdornment>
                       ),
                     }}
                     disabled={this.isFieldDisabled('area')}
                     variant="standard" />
                 </div>
              )}
              <div className='col-md-12 form-wrap padding-reset'>
                <VarietyAutoComplete
                  id='varietyId'
                  setRef={this.fieldRef['varietyId']}
                  label='Variety (Optional)'
                  commodityId={this.state.fields.commodityId.value}
                  varietyId={this.state.fields.varietyId.value}
                  dependsOnCommodity
                  onChange={this.handleVarietyChange}
                  errorText={get(this.state, 'fields.varietyId.errors[0]', '')}
                  onBlur={this.handleBlur}
                  disabled={this.isFieldDisabled('varietyId')}
                />
              </div>
              <div className='col-md-12 form-wrap padding-reset'>
                <SeasonSelect
                  id='season'
                  setRef={this.fieldRef['season']}
                  onChange={this.handleSelectFieldChange}
                  season={this.state.fields.season.value}
                  style={{ float: 'left' }}
                  errorText={get(this.state, 'fields.season.errors[0]', '')}
                  disabled={this.isFieldDisabled('season') || this.isAllocatedContract}
                />
              </div>
            </div>

            <div className='cardForm-content col-md-5 col-md-offset-1'>
            {
              !isBlended &&
              <div className='col-md-12 form-wrap padding-reset'>
                <GradeAutoComplete
                  id='gradeId'
                  setRef={this.fieldRef['gradeId']}
                  style={{ float: 'right' }}
                  floatingLabelText={this.isAreaContract() ? 'Base Grade' : 'Grade'}
                  commodityId={this.state.fields.commodityId.value}
                  varietyId={this.state.fields.varietyId.value}
                  varietyWarning={msg => this.setFieldCustomErrors('varietyId', msg)}
                  season={this.state.fields.season.value}
                  gradeId={this.state.fields.gradeId.value}
                  selectedGradeId={this.state.fields.gradeId.value}
                  dependsOnCommodity
                  dependsOnSeason
                  onChange={this.handleGradeChange}
                  disabled={!isNumber(this.state.fields.commodityId.value) || this.isFieldDisabled('gradeId') || this.isAllocatedContract}
                  errorText={get(this.state, 'fields.gradeId.errors[0]', '')}
                  onBlur={this.handleBlur}
                  displayNameOnly={this.state.fields.commodityId.value && !this.showGradeField()}
                />
              </div>
            }
              {this.isAreaContract() && (
                 <div className='col-md-12 form-wrap padding-reset'>
                   <div className='col-md-6 form-wrap padding-reset'>
                     <NumberField
                       error={!isEmpty(this.state.fields.area.estimatedYield.errors[0])}
                       inputRef={this.fieldRef['estimatedYield']}
                       id='area.estimatedYield'
                       label={`Estimated Yield (${priceUnit} per ${this.countryConfig?.farmField?.sizeUnit})`}
                       placeholder={`Estimated Yield (${priceUnit} per ${this.countryConfig?.farmField?.sizeUnit})`}
                       style={{ float: 'right' }}
                       value={this.state.fields.area.estimatedYield.value || ''}
                       fullWidth
                       helperText={this.state.fields.area.estimatedYield.errors[0]}
                       onChange={this.handleFieldChange}
                       onBlur={this.handleBlur}
                       maxValue={9999.99}
                       disabled={this.isFieldDisabled('estimatedYield')}
                       variant="standard" />
                   </div>
                   <div className='col-md-1'></div>
                   <div className='col-md-5 form-wrap padding-reset'>
                     <TextField
                       label={`Estimated ${this.tonnageLabel} (${priceUnit})`}
                       placeholder={`Estimated ${this.tonnageLabel} (${priceUnit})`}
                       style={{ float: 'right' }}
                       value={this.getEstimatedTonnage()}
                       fullWidth
                       disabled
                       variant="standard" />
                   </div>
                 </div>
              )}
              {
              isBlended &&
              <div className='col-md-12 form-wrap padding-reset' style={{ display: 'flex' }}>
                <CurrencyField
                  error={!isEmpty(this.state.fields.blendingFee.errors[0])}
                  id='blendingFee'
                  label={`Blending Fee (per ${this.priceUnit()}) (Optional)`}
                  value={this.state.fields.blendingFee.value || ''}
                  helperText={this.state.fields.blendingFee.errors[0]}
                  inputRef={this.fieldRef['blendingFee']}
                  fullWidth
                  onChange={event => this.setFieldValue('blendingFee', event.target.value)}
                  maxValue={9999999.99}
                  onBlur={this.handleBlur}
                  disabled={this.isFieldDisabled('blendingFee')}
                  variant="standard"
                  onCurrencyChange={this.handleCurrencyChange}
                  selectedCurrency={this.state.currency}
                  disabledCurrency={!foreignCurrencyAllowed}
                />
              </div>
            }
              {
              !this.isPoolContractType(this.state) &&
              <>
                <div className='col-md-12 form-wrap padding-reset' style={{ display: 'flex' }}>
                  <div className={this.isBlended() ? 'col-md-6' : 'col-md-12'} style={{padding: '0px 0px 0px 0px'}}>
                    <CurrencyField
                      error={!isEmpty(this.state.fields.price.errors[0])}
                      id='price'
                      label={priceFieldLabel}
                      value={this.state.fields.price.value || ''}
                      helperText={this.state.fields.price.errors[0]}
                      inputRef={this.fieldRef['price']}
                      fullWidth
                      onChange={this.handlePriceFieldChange}
                      onBlur={this.handleBlur}
                      maxValue={9999999.99}
                      disabled={this.isFieldDisabled('price')}
                      variant="standard"
                      onCurrencyChange={this.handleCurrencyChange}
                      selectedCurrency={this.state.currency}
                      disabledCurrency={!foreignCurrencyAllowed}
                    />
                  </div>
                  {this.isBlended() &&
                  <div className='col-md-6' style={{paddingLeft: '50px', paddingTop: '10px'}} >
                    <Checkbox color="primary" checked={this.state.useSinglePrice} onChange={this.handleSinglePriceChange} /><span>Use Single Price</span>
                  </div>
                  }
                </div>
              </>
              }
              {this.isAreaContract() && (
                 <div className='col-md-12 form-wrap padding-reset datePicker'>
                   <CommonDatePicker
                     id='area.priceBy'
                     inputRef={this.fieldRef['priceBy']}
                     floatingLabelText='Price By'
                     onChange={this.handleDeliveryDateChange}
                     errorText={this.state.fields.area.priceBy.errors[0]}
                     value={this.state.fields.area.priceBy.value}
                     minDate={this.getPriceByMinDate()}
                     disabled={this.isFieldDisabled('priceBy')}
                   />
                 </div>
              )}
              <div className='col-md-12 form-wrap padding-reset'>
                {
                  (this.isFieldDisabled('pricePointId') || this.isAllocatedContract) ?
                    <CommonTextField
                      id='pricePointId'
                      label='Price Point'
                      value={get(find(this.state.pricePoints, {id: this.state.fields.pricePointId.value}), 'displayName')}
                      style={{ float: 'right' }}
                      disabled
                    /> :
                    <CommonSelect
                      id='pricePointId'
                      setRef={this.fieldRef['pricePointId']}
                      onChange={this.handlePricePointChange}
                      style={{ float: 'right' }}
                      floatingLabelText='Price Point'
                      errorText={get(this.state, 'fields.pricePointId.errors[0]', '')}
                      selectConfig={{ id: 'id', text: 'displayName' }}
                      items={this.state.pricePoints}
                      value={this.state.fields.pricePointId.value}
                    />
                }
              </div>
              {
                this.state.isTrackVisible &&
                  <div className='col-md-12 form-wrap padding-reset'>
                    <CommonAutoSelect
                      id='track'
                      setRef={this.fieldRef['track']}
                      label='Track'
                      errorText={this.state.fields.track.errors[0]}
                      value={this.state.fields.track.value}
                      errorStyle={{ textAlign: 'left' }}
                      onChange={this.handleTrackChange}
                      items={this.props.tracks}
                      onBlur={this.handleBlur}
                      disabled={this.isFieldDisabled('track')}
                    />
                  </div>
              }
            </div>
          </div>
          {
            <div className='cardForm' style={this.showSpreadGradesSection() ? { display: 'inline-block' } : { display: 'none' }}>
              <h4 className='cardForm-title'>{isBlended ? "Grades" : getCountryLabel('spreadDetails')}</h4>
              <div className='cardForm-content'>
                <div className='col-md-12 form-wrap' style={{padding: '0 10px'}}>
                  {
                    this.countryConfig?.contracts?.priceAdjustment ?
                      <SpecPriceAdjustments
                        commodity={find(this.props.commodities, { id: this.state.fields.commodityId.value })}
                        onSave={this.handleSpreadGradesChange}
                        setRef={this.fieldRef['spread']}
                        disabled={!this.showSpreadGradesSection() || this.isFieldDisabled('spread')}
                        errorText={this.state.fields.spread.errors[0]}
                        spread={this.state.fields.spread.value}
                        currency={this.state.currency}
                      /> :
                    (
                      isBlended ?
                        <BlendedGrades
                          commodity={find(this.props.commodities, { id: this.state.fields.commodityId.value })}
                          onSave={this.handleSpreadGradesChange}
                          useSinglePrice={this.state.useSinglePrice}
                          setRef={this.fieldRef['spread']}
                          disabled={!this.showSpreadGradesSection() || this.isFieldDisabled('spread')}
                          errorText={this.state.fields.spread.errors[0]}
                          spread={this.state.fields.spread.value}
                          currency={this.state.currency}
                          unit={this.priceUnit()}
                          foreignCurrencyAllowed={foreignCurrencyAllowed}
                        /> :
                      <SpreadPopup
                        onSave={this.handleSpreadGradesChange}
                        setRef={this.fieldRef['spread']}
                        commodityId={this.state.fields.commodityId.value}
                        baseGradeId={this.state.fields.gradeId.value}
                        disableAddButton={!this.showSpreadGradesSection() || this.isFieldDisabled('spread')}
                        errorText={this.state.fields.spread.errors[0]}
                        spread={this.state.fields.spread.value}
                        currency={this.state.currency}
                        season={this.state.fields.season.value}
                        gradesInvoiced={get(this.selectedContract, 'gradesInvoiced')}
                        ordersGradeDifferentFromBaseGrade={this.ordersGradeDifferentFromBaseGrade()}
                        movementsGradeDifferentFromBaseGrade={this.movementsGradeDifferentFromBaseGrade()}
                        titleTransfersGradesDifferentFromBaseGrade={this.titleTransfersGradesDifferentFromBaseGrade()}
                        contractType={this.state.fields.typeId.value}
                      />
                    )
                  }
                </div>
              </div>
            </div>
          }
          {
          isBlended &&
            <div className='cardForm' style={{ display: 'inline-block' }}>
              <h4 className='cardForm-title'>Applications</h4>
              <div className='cardForm-content'>
                <div className='col-md-12 form-wrap' style={{padding: '0 10px'}}>
                  <ChemicalApplications
                    commodities={this.props.commodities}
                    currency={this.state.currency}
                    unit={this.priceUnit()}
                    applications={this.state.fields.chemicalApplications.value || []}
                    onChange={this.handleChemicalApplicationsChange}
                  />
                </div>
              </div>
            </div>
          }
          <div className='cardForm'>
            <h4 className='cardForm-title'>Consignment Details</h4>
            {
              this.state.consignors &&
              <div className='cardForm-content col-md-5 col-md'>
                {
                  this.state.fields.consignors.map((consignor, index) => {
                    const removeButtonConsignor = this.state.fields.consignors.length !== 1;
                    const isConsignorAndSiteDisabled = this.isConsignorAndSiteFieldDisabled(index);
                    const field = `consignors[${index}].handlerId`;
                    const fieldDisabled = isConsignorAndSiteDisabled || this.isSiteMandatoryBasedOnAllocation(index, 'pickup')
                    let consignorAddressLink = '';
                    let consignorMobile = get(consignor, 'item.mobile') || get(consignor, 'item.mobile');
                    let consignorAddress = get(consignor, 'item.address.address') || get(consignor, 'item.address');
                    if (typeof consignorAddress === 'object')
                      consignorAddress = get(consignorAddress, 'address')
                    if (consignorAddress)
                      consignorAddressLink = <div style={{marginTop: "5px"}}>
                        <div style={{ display: "block" }}>
                        <span>{consignorAddress}</span>
                        <IconButton
                          style={{padding: "0px"}}
                          onClick={() => this.handleConsignorLocationClick(consignor?.item)}
                          size="100%">
                            <PinDropIcon style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem"}} />
                        </IconButton>
                        </div>
                        {
                          consignorMobile &&
                          <div style={{ display: "block" }}>
                            <span>{toPhoneFormat(consignorMobile)}</span>
                            <IconButton
                              style={{padding: "0px"}}
                              onClick={() => this.handleConsignorLocationClick(consignor?.item)}
                              size="100%">
                                <Create style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem"}} />
                            </IconButton>
                          </div>
                        }
                      </div>
                    return (
                      <div key={index} className='col-md-12 form-wrap padding-reset' style={{ marginBottom: '10px' }}>
                        <div className={removeButtonConsignor ? 'col-md-11 form-wrap padding-reset' : 'col-md-12 form-wrap padding-reset'}>
                          <div className='col-md-10 padding-reset' style={{ paddingRight: '12px' }} ref={this.fieldRef[field]}>
                          { fieldDisabled ?
                          <CommonTextField
                              id={field}
                              variant="standard"
                              label='Pickup Site'
                              fullWidth
                              value={get(consignor, 'item')?.displayName}
                              additionalHelperText={consignorAddressLink}
                              disabled
                            /> :
                            <SiteAsyncAutocomplete
                              limitTags={2}
                              label="Pickup Site"
                              id={field}
                              onChange={item => this.handleConsigneesConsignorsChange(item, index, true, 'consignors')}
                              selected={get(consignor, 'item') ? get(consignor, 'item') : null}
                              minLength={3}
                              variant="standard"
                              classes={{inputRoot: this.props.classes.inputRoot}}
                              addSiteOption
                              addLabel
                              disabled={fieldDisabled}
                              fullWidth
                              activeSitesOnly
                              popupIcon={isConsignorAndSiteDisabled ? <i className="icon-lock-inline"></i> : undefined}
                              errorText={get(this.state.fields, `${field}.errors[0]`)}
                              helperText={consignorAddressLink}
                            />
                            }
                          </div>
                          <div className='col-md-2 padding-reset'>
                            <TextField
                              error={!isEmpty(get(this.state.fields, 'consignors[' + index + '].ld.errors[0]'))}
                              id={'consignors[' + index + '].ld'}
                              label={this.getLDLabel(index, 'consignors')}
                              value={get(this.state.fields, 'consignors[' + index + '].ld.value') || ''}
                              fullWidth
                              helperText={get(this.state.fields, 'consignors[' + index + '].ld.errors[0]')}
                              onChange={event => this.handleConsigneesConsignorsSiteLDChange(event.target.value, event.target.id, index, 'consignors')}
                              style={{ float: 'left' }}
                              InputLabelProps={{style: {fontSize: '14px'}}}
                              onBlur={event => this.handleLdBlur(event.target.value, event.target.id, index, 'consignors')}
                              disabled={this.isFieldDisabled('consignorLD')}
                              type='number'
                              variant="standard" />
                          </div>
                        </div>
                        {
                          removeButtonConsignor &&
                          <div className='col-md-1 padding-reset' style={{ textAlign: 'right', lineHeight: '83px' }}>
                            <Button
                              className='btn-minus'
                              mini
                              variant='fab'
                              secondary={true}
                              disabled={isConsignorAndSiteDisabled || this.isSiteMandatoryBasedOnAllocation(index, 'pickup')}
                              onClick={() => this.handleRemoveConsigneesConsignors('consignors', index)}
                            >
                              <ContentRemove />
                            </Button>
                          </div>
                        }
                      </div>
                    );
                  })
                }
                {this.state.openConsignorFormForFarm &&
                  <UpdateLocation updateEntities={(address, mobile) =>this.updateEntities(get(this.state, 'openConsignorFormForFarm.id'), address, mobile, 'pickup')} entityId={get(this.state, 'openConsignorFormForFarm.id')} entity='farm' onCloseDrawer={() => this.closeFarmDrawer('openConsignorFormForFarm')}/>
                }
                {
                  this.showAddConsigneeConsignorButton('consignors') &&
                  <AddButton
                    label='PICKUP SITE'
                    id='consignors'
                    onClick={this.handleAddConsigneesConsignors}
                    className='top-15'
                    style={{ float: 'left', marginBottom: '10px' }}
                    disabled={this.isConsignorAndSiteFieldDisabled(get(this.state, 'fields.consignors.length', 0))}
                  />
                }
              </div>
            }
            {
              this.state.consignees &&
              <div className='cardForm-content col-md-5 col-md-offset-1'>
                {
                  this.state.fields.consignees.map((consignee, index) => {
                    const removeButtonConsignee = this.state.fields.consignees.length !== 1;
                    const isConsigneeAndSiteDisabled = this.isConsigneeAndSiteFieldDisabled(index);
                    const field = `consignees[${index}].handlerId`;
                    const consigneeCount = get(this.selectedContract, 'consigneesWithSites', []).length - 1;
                    const newSite = !this.selectedContract || get(this.selectedContract, 'status') === 'template' || index > consigneeCount
                    const isLDDisabled = !newSite && this.isFieldDisabled('consigneeLD')
                    const fieldDisabled = isConsigneeAndSiteDisabled || this.isSiteMandatoryBasedOnAllocation(index, 'delivery')
                    let consigneeAddressLink = '';
                    let consigneeMobile = get(consignee, 'item.mobile') || get(consignee, 'item.mobile');
                    let consigneeAddress = get(consignee, 'item.address.address') || get(consignee, 'item.address');
                    if (typeof consigneeAddress === 'object')
                      consigneeAddress = get(consigneeAddress, 'address')
                    if (consigneeAddress)
                      consigneeAddressLink = <div style={{marginTop: "5px"}}>
                        <div style={{ display: "block" }}>
                          <span>{consigneeAddress}</span>
                          <IconButton
                            style={{padding: "0px"}}
                            onClick={() => this.handleConsigneeLocationClick(consignee?.item)}
                            size="100%">
                              <PinDropIcon style={{ color: PRIMARY_COLOR_GREEN , fontSize: "0.95rem", marginBottom: "0.1rem" }} />
                          </IconButton>
                        </div>
                        {
                          consigneeMobile &&
                          <div style={{ display: "block" }}>
                            <span>{toPhoneFormat(consigneeMobile)}</span>
                            <IconButton
                              style={{padding: "0px"}}
                              onClick={() => this.handleConsigneeLocationClick(consignee?.item)}
                              size="100%">
                                <Create style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem"}} />
                            </IconButton>
                          </div>
                        }
                      </div>
                    return (
                      <div key={index} className='col-md-12 form-wrap padding-reset' style={{ marginBottom: '10px' }}>
                        <div className={removeButtonConsignee ? 'col-md-11 form-wrap padding-reset' : 'col-md-12 form-wrap padding-reset'}>
                          <div className='col-md-10 padding-reset' style={{ paddingRight: '12px' }} ref={this.fieldRef[field]}>
                            {fieldDisabled ?
                            <CommonTextField
                              id={field}
                              variant="standard"
                              label='Delivery Site'
                              fullWidth
                              value={get(consignee, 'item')?.displayName}
                              additionalHelperText={consigneeAddressLink}
                              disabled
                            />
                            : <SiteAsyncAutocomplete
                                limitTags={2}
                                label="Delivery Site"
                                id={field}
                                onChange={item => this.handleConsigneesConsignorsChange(item, index, true, 'consignees')}
                                selected={get(consignee, 'item') ? get(consignee, 'item') : null}
                                minLength={3}
                                variant="standard"
                                classes={{inputRoot: this.props.classes.inputRoot}}
                                addSiteOption
                                addLabel
                                disabled={fieldDisabled}
                                fullWidth
                                activeSitesOnly
                                popupIcon={isConsigneeAndSiteDisabled ? <i className="icon-lock-inline"></i> : undefined}
                                errorText={get(this.state.fields, `${field}.errors[0]`)}
                                helperText={consigneeAddressLink}
                            />
                            }
                          </div>
                          <div className='col-md-2 padding-reset'>
                            <TextField
                              error={!isEmpty(get(this.state.fields, 'consignees[' + index + '].ld.errors[0]'))}
                              id={'consignees[' + index + '].ld'}
                              label={this.getLDLabel(index, 'consignees')}
                              value={get(this.state.fields, 'consignees[' + index + '].ld.value') || ''}
                              fullWidth
                              helperText={get(this.state.fields, 'consignees[' + index + '].ld.errors[0]')}
                              onChange={event => this.handleConsigneesConsignorsSiteLDChange(event.target.value, event.target.id, index, 'consignees')}
                              style={{ float: 'left' }}
                              InputLabelProps={{style: {fontSize: '14px'}}}
                              onBlur={event => this.handleLdBlur(event.target.value, event.target.id, index, 'consignees')}
                              disabled={isLDDisabled}
                              type='number'
                              variant="standard" />
                          </div>
                        </div>
                        {
                          removeButtonConsignee &&
                          <div className='col-md-1 padding-reset' style={{ textAlign: 'right', lineHeight: '83px' }}>
                            <Button
                              className='btn-minus'
                              mini
                              id='consignees'
                              variant='fab'
                              secondary={true}
                              disabled={isConsigneeAndSiteDisabled || this.isSiteMandatoryBasedOnAllocation(index, 'delivery')}
                              onClick={() => this.handleRemoveConsigneesConsignors('consignees', index)}
                            >
                              <ContentRemove />
                            </Button>
                          </div>
                        }
                      </div>
                    );
                  })
                }
                {this.state.openConsigneeFormForFarm &&
                  <UpdateLocation updateEntities={(address, mobile) =>this.updateEntities(get(this.state, 'openConsigneeFormForFarm.id'), address, mobile, 'delivery')} entityId={get(this.state, 'openConsigneeFormForFarm.id')} entity='farm' onCloseDrawer={() => this.closeFarmDrawer('openConsigneeFormForFarm')}/>
                }
                {
                  this.showAddConsigneeConsignorButton('consignees') &&
                  <AddButton
                    label='DELIVERY SITE'
                    id='consignees'
                    onClick={this.handleAddConsigneesConsignors}
                    className='top-15'
                    style={{ float: 'left', marginBottom: '10px' }}
                    disabled={this.isConsigneeAndSiteFieldDisabled(get(this.state, 'fields.consignees.length', 0))}
                  />
                }
              </div>
            }
          </div>

          <div className='cardForm'>
            <h4 className='cardForm-title'>Terms and Responsibilities</h4>
            <div className='cardForm-content col-md-5'>
              <div className='col-md-12 form-wrap padding-reset'>
                <CommonSelect
                  id='deliveryOnus'
                  setRef={this.fieldRef['deliveryOnus']}
                  onChange={this.handleSelectFieldChange}
                  floatingLabelText='Delivery Onus'
                  value={this.state.fields.deliveryOnus.value}
                  errorText={get(this.state, 'fields.deliveryOnus.errors[0]', '')}
                  items={DELIVERY_ONUS}
                  style={{ float: 'left' }}
                  disabled={this.isFieldDisabled('deliveryOnus')}
                />
              </div>
              <div className='col-md-12 form-wrap padding-reset'>
                <CommonSelect
                  id='paymentScaleId'
                  setRef={this.fieldRef['paymentScaleId']}
                  onChange={this.handleSelectFieldChange}
                  value={this.state.fields.paymentScaleId.value}
                  floatingLabelText='Payment Scale'
                  errorText={get(this.state, 'fields.paymentScaleId.errors[0]', '')}
                  items={this.state.paymentScales}
                  style={{ float: 'left' }}
                  disabled={this.isFieldDisabled('paymentScaleId')}
                />
              </div>
              <div className='col-md-12 form-wrap padding-reset'>
                <CommonAutoSelect
                  id='paymentTermId'
                  setRef={this.fieldRef['paymentTermId']}
                  onChange={this.handleSelectFieldChange}
                  label='Payment Terms'
                  dataSourceConfig={{ text: 'name', value: 'id' }}
                  value={this.state.fields.paymentTermId.value}
                  errorText={get(this.state, 'fields.paymentTermId.errors[0]', '')}
                  items={this.state.paymentTerms}
                  onBlur={this.handleBlur}
                  disabled={this.isFieldDisabled('paymentTermId')}
                />
              </div>
              <div className='col-md-12 form-wrap padding-reset'>
                <CommonSelect
                  id='toleranceId'
                  setRef={this.fieldRef['toleranceId']}
                  onChange={this.handleSelectFieldChange}
                  value={this.state.fields.toleranceId.value}
                  floatingLabelText='Tolerance'
                  errorText={get(this.state, 'fields.toleranceId.errors[0]', '')}
                  items={this.state.tolerances}
                  style={{ float: 'left' }}
                  disabled={this.isFieldDisabled('toleranceId')}
                />
              </div>
              <div className='col-md-12 form-wrap padding-reset'>
                <CommonSelect
                  id='conveyanceId'
                  setRef={this.fieldRef['conveyanceId']}
                  onChange={this.handleSelectFieldChange}
                  value={this.state.fields.conveyanceId.value}
                  floatingLabelText='Conveyance'
                  errorText={get(this.state, 'fields.conveyanceId.errors[0]', '')}
                  items={this.state.conveyances}
                  style={{ float: 'left' }}
                  disabled={this.isFieldDisabled('conveyanceId')}
                />
              </div>
            </div>

            <div className='cardForm-content col-md-5 col-md-offset-1'>
              <div className='col-md-12 form-wrap padding-reset'>
                <CommonSelect
                  id='inspectionId'
                  setRef={this.fieldRef['inspectionId']}
                  onChange={this.handleSelectFieldChange}
                  value={this.state.fields.inspectionId.value}
                  floatingLabelText='Inspection'
                  style={{ float: 'right' }}
                  errorText={get(this.state, 'fields.inspectionId.errors[0]', '')}
                  items={this.state.checkpoints}
                  disabled={this.isFieldDisabled('inspectionId')}
                />
              </div>
              <div className='col-md-12 form-wrap padding-reset'>
                <CommonSelect
                  id='weightId'
                  setRef={this.fieldRef['weightId']}
                  onChange={this.handleSelectFieldChange}
                  value={this.state.fields.weightId.value}
                  floatingLabelText='Weight'
                  errorText={get(this.state, 'fields.weightId.errors[0]', '')}
                  style={{ float: 'right' }}
                  items={this.state.checkpoints}
                  disabled={this.isFieldDisabled('weightId')}
                />
              </div>
              <div className='col-md-12 form-wrap padding-reset'>
                <CommonSelect
                  id='packagingId'
                  setRef={this.fieldRef['packagingId']}
                  value={this.state.fields.packagingId.value}
                  onChange={this.handleSelectFieldChange}
                  floatingLabelText='Packaging'
                  errorText={get(this.state, 'fields.packagingId.errors[0]', '')}
                  items={this.state.packagings}
                  style={{ float: 'right' }}
                  disabled={this.isFieldDisabled('packagingId')}
                />
              </div>
              <div className='col-md-12 form-wrap padding-reset'>
                <CommonAutoSelect
                  id="marketZoneId"
                  setRef={this.fieldRef["marketZoneId"]}
                  label={this.state.fields.marketZoneId.label}
                  items={this.state.marketZones}
                  onChange={this.handleMarketZoneChange}
                  errorText={this.state.fields.marketZoneId.errors[0]}
                  value={this.state.fields.marketZoneId.value}
                />
              </div>
              <div className='col-md-12 form-wrap padding-reset'>
                <CommonSelect
                  id='regionId'
                  setRef={this.fieldRef['regionId']}
                  floatingLabelText='Region'
                  items={this.state.regions}
                  errorText={this.state.fields.regionId.errors[0]}
                  onChange={this.handleSelectFieldChange}
                  value={this.state.fields.regionId.value}
                />
              </div>
            </div>
          </div>
          <div className='cardForm'>
            <h4 className='cardForm-title'>Delivery Details</h4>
            <div className='cardForm-content'>
              <div className='col-md-5 form-wrap datePicker'>
                <CommonDatePicker
                  id='deliveryStartDate'
                  setRef={this.fieldRef['deliveryStartDate']}
                  floatingLabelText='Delivery Start Date'
                  onChange={this.handleDeliveryStartDateChange}
                  onBlur={this.checkDeliveryEndDate}
                  errorText={this.state.fields.deliveryStartDate.errors[0]}
                  value={this.state.fields.deliveryStartDate.value}
                  minDate={allowPastDates ? null : moment()}
                  maxDate={this.getMaxDeliveryStartDate()}
                  disabled={this.isFieldDisabled('deliveryStartDate')}
                />
              </div>
              <div className='col-md-5 form-wrap datePicker col-md-offset-1'>
                <CommonDatePicker
                  id='deliveryEndDate'
                  setRef={this.fieldRef['deliveryEndDate']}
                  floatingLabelText='Delivery End Date'
                  onChange={this.handleDeliveryEndDateChange}
                  onBlur={this.checkDeliveryEndDate}
                  value={this.state.fields.deliveryEndDate.value}
                  errorText={this.state.fields.deliveryEndDate.errors[0]}
                  minDate={
                    this.state.fields.deliveryStartDate.value ? moment(this.state.fields.deliveryStartDate.value) : allowPastDates ? null : moment()
                  }
                />
              </div>
            </div>
          </div>

          <div className='cardForm'>
            <h4 className='cardForm-title'>Carry Details</h4>
            <div className='cardForm-content col-md-5'>
              <div className='col-md-12 form-wrap padding-reset'>
                <NumberField
                  id='carryRate'
                  label={rateLabel}
                  placeholder={rateLabel}
                  value={this.state.fields.carryRate.value || ''}
                  fullWidth
                  maxLength='10'
                  helperText={this.state.fields.carryRate.errors[0]}
                  error={!isEmpty(this.state.fields.carryRate.errors[0])}
                  style={{ float: 'left' }}
                  onChange={this.handleCarryRateFieldChange}
                  onBlur={this.handleBlur}
                  maxValue={999.99}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start' style={{ color: 'rgb(162,162,162)' }}>
                        {this.state.currency}
                      </InputAdornment>
                    ),
                  }}
                  disabled={this.isFieldDisabled('carryRate')}
                  variant="standard" />
              </div>
              {parseFloat(this.state.fields.carryRate.value) > 0 ? (
                 <div className='col-md-12 form-wrap padding-reset'>
                   <CommonSelect
                     id='carryFrequency'
                     setRef={this.fieldRef['carryFrequency']}
                     onChange={this.handleSelectFieldChange}
                     value={this.state.fields.carryFrequency.value}
                     floatingLabelText='Frequency'
                     errorText={get(this.state, 'fields.frequency.errors[0]', '')}
                     style={{ float: 'left' }}
                     items={FREQUENCIES}
                     disabled={this.isFieldDisabled('carryFrequency')}
                   />
                   <Typography variant='subheading' gutterBottom>
                     Current Carry Value (in {this.state.currency}): {this.state.fields.carryCurrent.value}
                   </Typography>
                 </div>
              ) : (
                 ''
              )}
            </div>
            {parseFloat(this.state.fields.carryRate.value) > 0 ? (
               <div className='cardForm-content col-md-5 col-md-offset-1'>
                 <div className='col-md-12 form-wrap datePicker padding-reset'>
                   <CommonDatePicker
                     id='carryStartDate'
                     setRef={this.fieldRef['carryStartDate']}
                     floatingLabelText='Start Date'
                     onChange={this.handleSelectFieldChange}
                     errorText={this.state.fields.carryStartDate.errors[0]}
                     value={this.state.fields.carryStartDate.value}
                     minDate={this.state.fields.deliveryStartDate.value ? moment(this.state.fields.deliveryStartDate.value) : moment()}
                     disabled={this.state.fields.deliveryStartDate.value == null || this.isFieldDisabled('carryStartDate')}
                   />
                 </div>
                 <div className='col-md-12 form-wrap datePicker padding-reset'>
                   <CommonDatePicker
                     id='carryEndDate'
                     setRef={this.fieldRef['carryEndDate']}
                     floatingLabelText='End Date'
                     onChange={this.handleSelectFieldChange}
                     value={this.state.fields.carryEndDate.value}
                     errorText={this.state.fields.carryEndDate.errors[0]}
                     minDate={this.state.fields.carryStartDate.value ? moment(this.state.fields.carryStartDate.value) : moment()}
                     disabled={
                       this.state.fields.carryStartDate.value == null ||
                               this.state.fields.deliveryEndDate.value == null
                     }
                   />
                   <Typography variant='subheading' gutterBottom>
                     Maximum Carry Value (in {this.state.currency}): {this.state.fields.carryMax.value}
                   </Typography>
                 </div>
               </div>
            ) : (
               ''
            )}
          </div>

          <div className='cardForm'>
            <h4 className='cardForm-title'>Contract Conditions</h4>
            <div className='cardForm-content'>
              <div className='col-md-5 form-wrap'>
                <CommonAutoSelect
                  items={this.state.generalConditionsAll}
                  id='generalConditionsSelector'
                  setRef={this.fieldRef['generalConditionsSelector']}
                  value={this.state.generalConditionsSelector}
                  label='General'
                  onChange={this.handleConditionSelector}
                  dontAutoselectSingleItem
                  disabled={this.isFieldDisabled('generalConditions')}
                />
                {
                  <AddCircleIconWithTooltip
                    id='addGeneralCondition'
                    onClick={() => this.openGeneralConditionSideDrawer()}
                    title='Add General Condition'
                    disabled={this.isFieldDisabled('generalConditions')}
                  />
                }
                <CommonTextField
                  className='top15 text-black'
                  id='generalConditionViewer'
                  value={this.state.selectedGeneralConditionText}
                  fullWidth
                  multiline={true}
                  rows={1}
                  disabled={true}
                  rowsMax={1}
                  onBlur={this.handleBlur}
                />
              </div>
              <div className='cardForm-content col-md-1 arrow-button'>
                <IconButton
                  disabled={!isNumber(this.state.generalConditionsSelector)}
                  onClick={this.appendGeneralConditions}
                  size="large">
                  <ArrowForward color={isNumber(this.state.generalConditionsSelector) ? 'primary' : 'disabled'} />
                </IconButton>
              </div>
              <div className='cardForm-content col-md-5'>
                <div className='field-label'>Conditions (Optional)</div>
                <TextField
                  className='text-area'
                  id='generalConditions'
                  value={this.state.fields.generalConditions.value}
                  fullWidth
                  multiline={true}
                  onChange={this.handleFieldChange}
                  rows={6}
                  disabled={this.isFieldDisabled('generalConditions')}
                  variant="standard" />
              </div>
            </div>
            <div className='cardForm-content'>
              <div className='col-md-5 form-wrap'>
                <CommonAutoSelect
                  items={this.state.specialConditionsAll}
                  id='specialConditionsSelector'
                  setRef={this.fieldRef['specialConditionsSelector']}
                  value={this.state.specialConditionsSelector}
                  label='Special'
                  onChange={this.handleConditionSelector}
                  dontAutoselectSingleItem
                  disabled={this.isFieldDisabled('specialConditions')}
                />
                {
                  <AddCircleIconWithTooltip
                    id='addSpecialCondition'
                    onClick={() => this.openSpecialConditionSideDrawer()}
                    title='Add Special Condition'
                    disabled={this.isFieldDisabled('specialConditions')}
                  />
                }
                <CommonTextField
                  className='top15 text-black'
                  id='specialConditionViewer'
                  value={this.state.selectedSpecialConditionText}
                  fullWidth
                  multiline={true}
                  rows={1}
                  disabled={true}
                  rowsMax={1}
                  onBlur={this.handleBlur}
                />
              </div>
              <div className='cardForm-content col-md-1 arrow-button'>
                <IconButton
                  disabled={!isNumber(this.state.specialConditionsSelector)}
                  onClick={this.appendSpecialConditions}
                  size="large">
                  <ArrowForward color={isNumber(this.state.specialConditionsSelector) ? 'primary' : 'disabled'} />
                </IconButton>
              </div>
              <div className='col-md-5'>
                <div className='field-label'>Conditions (Optional)</div>
                <TextField
                  className='text-area'
                  id='specialConditions'
                  value={this.state.fields.specialConditions.value}
                  style={{ float: 'right' }}
                  fullWidth
                  multiline={true}
                  onChange={this.handleFieldChange}
                  rows={6}
                  onBlur={this.handleBlur}
                  disabled={this.isFieldDisabled('specialConditions')}
                  variant="standard" />
              </div>
            </div>
          </div>

          <div className='cardForm'>
            <h4 className='cardForm-title'>Valuations</h4>
            <div className='cardForm-content col-md-5'>
              <div className='col-md-12 form-wrap-70 text-only padding-reset'>
                <Typography variant='subheading' gutterBottom>
                  Contract Value (in {this.state.currency}): {this.contractValue()}
                </Typography>
              </div>
              <div className='col-md-12 form-wrap-70 text-only padding-reset'>
                <Typography variant='subheading' gutterBottom>
                  Max Contract Value (in {this.state.currency}): {this.maxContractValue()}
                </Typography>
              </div>
              <div className='col-md-12 form-wrap-70 text-only padding-reset'>
                <Typography variant='subheading' gutterBottom>
                  EPR Value (in {this.state.currency}): {this.eprValue()}
                </Typography>
              </div>
            </div>
            <div className='cardForm-content col-md-5 col-md-offset-1'>
              <div className='col-md-12 form-wrap-70 text-only padding-reset'>
                <Typography variant='subheading' gutterBottom>
                  Estimated Payment Due Date: {this.estimatedPaymentDueDate()}
                </Typography>
              </div>
              <div className='col-md-12 form-wrap-70 text-only padding-reset'>
                <Typography variant='subheading' gutterBottom>
                  Current Valuation (in {this.state.currency}): {this.contractValue()}
                </Typography>
              </div>
              <div className='col-md-12 form-wrap-70 text-only padding-reset'>
                <Typography variant='subheading' gutterBottom>
                  Central Levy (in {this.state.currency}): {this.levy()}
                </Typography>
              </div>
            </div>
          </div>

          <div className='cardForm'>
            <h4 className='cardForm-title'> {isBrokerSalesConfirmation ? 'Agent Fees' : 'Brokerage' }</h4>
            <div className='cardForm-content col-md-2 padding-reset'>
              <div className='col-md-12 form-wrap'>
                <CommonSelect
                  id='payableBy'
                  setRef={this.fieldRef['payableBy']}
                  onChange={this.handlePayableBy}
                  floatingLabelText='Payable By'
                  style={{ float: 'left' }}
                  errorText={this.state.fields.payableBy.errors[0]}
                  selectConfig={{ text: 'name', value: 'id' }}
                  items={PAYABLE_BY}
                  value={this.state.fields.payableBy.value}
                  disabled={this.isFieldDisabled('payableBy')}
                />
              </div>
            </div>
            <div className="cardForm-content col-md-10">
              {map(this.state.fields.brokerages, (brokerage) => {
                 return (
                   <div key={brokerage.type.value} style={{width: '100%'}}>
                     <div className="col-md-2 form-wrap">
                       <CommonSelect
                         id="chargeAt"
                         setRef={this.fieldRef["brokerages.chargedOn"]}
                         onChange={this['handle'+brokerage.type.value+'ChargedOn']}
                         floatingLabelText="Charged At"
                         style={{float: 'left'}}
                         errorText={ brokerage.chargedOn.errors[0] }
                         selectConfig={{text: 'name', value: 'id'}}
                         items={CHARGED_AT}
                         value={brokerage.chargedOn.value}
                         disabled={this.isFieldDisabled('chargedOn')}
                       />
                     </div>
                     <div className={isCurrentUserBroker() ? "col-md-2 form-wrap" : "col-md-3 form-wrap"}>
                       <CommonSelect
                         id="feeType"
                         setRef={this.fieldRef[`brokerages.${brokerage.type.value}.feeType`]}
                         floatingLabelText={"Fee Type" + " (" + brokerage.type.value + ")"}
                         errorText={ brokerage.feeType.errors[0] }
                         selectConfig={{text: 'name', value: 'id'}}
                         restorePrev={true}
                         items={this.getFeeTypes()}
                         value={brokerage.feeType.value}
                         clearSelectedItems={this.state['clear'+brokerage.type.value+'FeeType']}
                         onChange={this['handle'+brokerage.type.value+'BrokerageFeeType']}
                         disabled={this.isFieldDisabled('brokerages')}
                       />
                     </div>
                     { brokerage.feeType.value === 'Fee Per MT' &&
                       <div className={isCurrentUserBroker() ? "col-md-2 form-wrap" : "col-md-3 form-wrap"}>
                         <NumberField
                           id="rate"
                           inputRef={this.fieldRef[`brokerages.${brokerage.type.value}.rate`]}
                           error={!isEmpty(brokerage.rate.errors[0])}
                           style={{width: '100%'}}
                           label={"Rate" + " (" + brokerage.type.value + ")"}
                           placeholder={"Rate" + " (" + brokerage.type.value + ")"}
                           maxValue={9999}
                           helperText={ brokerage.rate.errors[0] }
                           value={brokerage.rate.value}
                           onChange={this['handle'+brokerage.type.value+'BrokerageField']}
                           InputProps={{
                             startAdornment: <InputAdornment position="start" style={{color: 'rgb(162,162,162)'}}>{this.state.currency}</InputAdornment>
                           }}
                           disabled={brokerage.feeType.value === 'Flat Fee' || this.isFieldDisabled('brokerages')}
                           variant="standard" />
                       </div> }
                     { brokerage.feeType.value === '% of Sale' &&
                       <div className={isCurrentUserBroker() ? "col-md-2 form-wrap" : "col-md-3 form-wrap"}>
                         <NumberField
                           id="rate"
                           inputRef={this.fieldRef[`brokerages.${brokerage.type.value}.rate`]}
                           error={!isEmpty(brokerage.rate.errors[0])}
                           style={{width: '100%'}}
                           label={"Rate" + " (" + brokerage.type.value + ")"}
                           placeholder={"Rate" + " (" + brokerage.type.value + ")"}
                           maxValue={100}
                           helperText={ brokerage.rate.errors[0] }
                           value={brokerage.rate.value}
                           onChange={this['handle'+brokerage.type.value+'BrokerageField']}
                           InputProps={{
                             endAdornment: <InputAdornment position="end" style={{color: 'rgb(162,162,162)'}}>%</InputAdornment>
                           }}
                           disabled={brokerage.feeType.value === 'Flat Fee' || this.isFieldDisabled('brokerages')}
                           variant="standard" />
                       </div> }
                     {  includes(['Flat Fee', '', undefined, null], brokerage.feeType.value) &&
                        <div className={isCurrentUserBroker() ? "col-md-2 form-wrap" : "col-md-3 form-wrap"}>
                          <NumberField
                            id="rate"
                            inputRef={this.fieldRef[`brokerages.${brokerage.type.value}.rate`]}
                            error={!isEmpty(brokerage.rate.errors[0])}
                            style={{width: '100%'}}
                            label={"Rate" + " (" + brokerage.type.value + ")"}
                            placeholder={"Rate" + " (" + brokerage.type.value + ")"}
                            maxValue={99999.99}
                            helperText={ brokerage.rate.errors[0] }
                            value={brokerage.rate.value}
                            onChange={this['handle'+brokerage.type.value+'BrokerageField']}
                            disabled={brokerage.feeType.value === 'Flat Fee' || this.isFieldDisabled('brokerages')}
                            variant="standard" />
                        </div>
                     }
                     <div className={isCurrentUserBroker() ? "col-md-3 form-wrap" : "col-md-4 form-wrap"}>
                       <NumberField
                         error={!isEmpty(brokerage.totalFee.errors[0])}
                         id="totalFee"
                         inputRef={this.fieldRef[`brokerages.${brokerage.type.value}.totalFee`]}
                         style={{width: '100%'}}
                         label={brokerage.chargedOn.value === 'Pre-Delivery' ? "Total Fee" + " (" + brokerage.type.value + ")" : "Estimated Total Fee" + " (" + brokerage.type.value + ")"}
                         placeholder={"Total Fee" + " (" + brokerage.type.value + ")"}
                         maxValue={99999.99}
                         helperText={ brokerage.totalFee.errors[0] }
                         value={brokerage.totalFee.value}
                         onChange={this['handle'+brokerage.type.value+'BrokerageField']}
                         InputProps={{
                           startAdornment: <InputAdornment position="start" style={{color: 'rgb(162,162,162)'}}>{this.state.currency}</InputAdornment>
                         }}
                         disabled={this.isFieldDisabled('brokerages')}
                         variant="standard" />
                     </div>
                     {isCurrentUserBroker() ?
                      <div className="col-md-3 form-wrap">
                        <CommonButton
                          label='Set Default'
                          primary={true}
                          onClick={this['set' + brokerage.type.value + 'DefaultBrokerage']}
                          variant="contained"
                          disabled={
                            (brokerage.type.value && !this['shouldShowSetDefaultBrokerageActionFor' + brokerage.type.value]()) ||
                                    this.isFieldDisabled('brokerages')
                          }
                        />
                      </div>
                      : null}
                   </div>
                 );
              })}
            </div>
          </div>

          <div className='cardForm'>
            <h4 className='cardForm-title'>Admin</h4>
            <div className='cardForm-content col-md-5'>
              <div className='col-md-12 form-wrap padding-reset'>
                <CommonSelect
                  id='invoicing'
                  setRef={this.fieldRef['invoicing']}
                  onChange={this.handleInvoiceChange}
                  floatingLabelText='Contract Invoicing'
                  errorText={this.state.fields.administration.invoicing.errors[0]}
                  selectConfig={{ text: 'name', value: 'id' }}
                  items={
                    this.isEditingContract && this.state.fields.administration.invoicing.value === 'Broker to Invoice Buyer'
                       ? INVOICINGS
                       : this.getInvoicingList()
                  }
                  style={{ float: 'left' }}
                  value={this.state.fields.administration.invoicing.value}
                  disabled={this.isFieldDisabled('administration.invoicing')}
                />
              </div>
              <div className='col-md-12 form-wrap padding-reset'>
                <Notes
                  id='crmNotes'
                  title='Internal CRM Notes (Optional)'
                  handler={this.noteHandler}
                  note={this.state.fields.crmNotes}
                  disabled={this.isFieldDisabled('crmNotes')}
                />

              </div>
            </div>
            <div className='cardForm-content col-md-5 col-md-offset-1'>
              <div className='col-md-12 form-wrap padding-reset'>
                <CommonSelect
                  items={this.getBrokers()}
                  id='administration.brokeredById'
                  setRef={this.fieldRef['administration.brokeredById']}
                  value={this.state.fields.administration.brokeredById.value}
                  floatingLabelText={isBrokerSalesConfirmation ? 'Agent' : 'Brokered By'}
                  errorText={this.state.fields.administration.brokeredById.errors[0]}
                  onChange={this.handleBrokeredByChange}
                  dontAutoselectSingleItem
                  includeEmptyOption={this.state.fields.payableBy.value === 'Nil' && (this.isCurrentUserSellerOrBuyer() || !isCurrentUserBroker()) && this.state.fields.administration.invoicing.value !== 'Broker to Invoice Buyer'}
                />
              </div>
              <div className='col-md-12 form-wrap padding-reset'>
                <CommonSelect
                  items={this.state.brokerEmployees}
                  id='administration.brokerContactId'
                  setRef={this.fieldRef['administration.brokerContactId']}
                  value={this.state.fields.administration.brokerContactId.value}
                  floatingLabelText={isBrokerSalesConfirmation ? 'Agent Contact' : 'Broker Contact'}
                  label={isBrokerSalesConfirmation ? 'Agent Contact' : 'Broker Contact'}
                  errorText={this.state.fields.administration.brokerContactId.errors[0]}
                  onChange={this.handleSelectFieldChange}
                />
                {this.state.fields.administration.brokeredById.value && (
                   <AddCircleIconWithTooltip
                     id='addBrokerContact'
                     onClick={() => this.openBrokerSideDrawer()}
                     title={isBrokerSalesConfirmation ? 'Add Agent Contact' : 'Add Broker Contact'}
                     disabled={this.isFieldDisabled('administration.brokerContactId')}
                   />
                )}
              </div>
            </div>
            <div className='col-md-12 cardForm-action top-15'>
              <CommonButton label='Cancel' default={true} variant='outlined' onClick={this.handleCancelButtonClick} />
              {!this.isEditingContract && (
                 <Tooltip title='Save template to quickly make contracts' placement='top'>
                   <span>
                     <CommonButton label='Save Template' secondary onClick={this.handleSaveButtonClick} variant="contained" />
                   </span>
                 </Tooltip>
              )}
              <CommonButton
                type='submit'
                label='Continue and Review'
                primary={true}
                variant="contained"
                disabled={this.isEditingContract && includes(['closed'], get(this.selectedContract, 'status'))}
              />
            </div>
          </div>
        </form>
        <Dialog open={this.state.preview} onClose={this.handlePreviewClose} scroll='paper' fullScreen>
          <DialogTitle>Contract Preview</DialogTitle>
          <DialogContent>
            <ContractPreview previewFields={this.getPreviewFields()} ref={el => (this.componentRef = el)} />
          </DialogContent>
          <DialogActions style={{paddingBottom: "40px"}}>
            <CommonButton label='Back' key='closeButton' default onClick={this.handlePreviewClose} variant='flat' />

            <CommonButton
              label={this.isEditingContract ? 'Re-Submit' : 'Submit'}
              key='submitButton'
              primary={true}
              onClick={this.handleSubmit}
              variant='flat'
            />
          </DialogActions>
        </Dialog>
        {this.state.consignorSideDrawer && <SideDrawer
                                             isOpen={ this.state.consignorSideDrawer }
                                             title="Add Site"
                                             onClose={() => this.handleFarmSideDrawer("consignorSideDrawer", false)}
                                             app="condition"
                                           >
          <CreateCompanySite closeDrawer={() => this.handleFarmSideDrawer("consignorSideDrawer", false)}
                             canAccessAny={true} companyId={getCountrySystemCompanyId()}
                             handleFarmSubmit={(data) => this.handleFarmSubmit("consignorSideDrawer" ,data)}
                             inputText={this.state.inputTextFarm}/>
        </SideDrawer>}

        {this.state.consigneeSideDrawer && <SideDrawer
                                             isOpen={ this.state.consigneeSideDrawer }
                                             title="Add Site"
                                             onClose={() => this.handleFarmSideDrawer("consigneeSideDrawer", false)}
                                             app="condition"
                                           >
          <CreateCompanySite closeDrawer={() => this.handleFarmSideDrawer("consigneeSideDrawer", false)}
                             canAccessAny={true} companyId={getCountrySystemCompanyId()}
                             handleFarmSubmit={(data) => this.handleFarmSubmit("consigneeSideDrawer", data)}
                             inputText={this.state.inputTextFarm}/>
        </SideDrawer>}


        <Dialog open={this.state.templateDialog} onClose={this.handleTemplateDialogClose} scroll='paper'>
          <DialogTitle>Contract Template</DialogTitle>
          <DialogContent>
            <TextField
              id='templateName'
              label='Template Name'
              placeholder='Please enter'
              value={this.state.templateName.value || undefined}
              fullWidth
              error={!isEmpty(this.state.templateName.errors[0])}
              helperText={this.state.templateName.errors[0]}
              onChange={this.handleTemplateNameChange}
              maxLength='200'
              onBlur={this.handleTemplateNameBlur}
              variant="standard" />
          </DialogContent>
          <DialogActions>
            <CommonButton variant='flat' label='Cancel' key='closeButton' default onClick={this.handleTemplateDialogClose} />
            <CommonButton variant='flat' label='Save' key='saveButton' primary={true} onClick={this.saveTemplate} />
          </DialogActions>
        </Dialog>
        {this.sellerSideDrawer()}
        {this.buyerSideDrawer()}

        {this.props.isEmployeeSideDrawerOpened && (
           <SideDrawer isOpen={this.state.brokerSideDrawerIsOpen} title='Add Employee' onClose={this.closeBrokerSideDrawer} app='officeEmployee'>
             <CreateEmployee
               closeDrawer={this.closeBrokerSideDrawer}
               canAccessAny={this.canBrokerAccessAny()}
               companyId={this.getSelectedBrokerCompany() ? this.getSelectedBrokerCompany().id : null}
               selectedCompany={this.getSelectedBrokerCompany()}
               establishmentDetails={this.state.fields.administration.brokeredById.value}
               getContacts={this.getBrokerCompanyEmployees}
             />
           </SideDrawer>
        )}

        {this.props.isConditionSideDrawerOpened && (
           <SideDrawer
             isOpen={this.state.generalConditionSideDrawerIsOpen}
             title='Add General Condition'
             onClose={this.closeGeneralConditionSideDrawer}
             app='condition'
             >
             <CreateGeneralCondition
               selectedConditionType='contract'
               closeDrawer={this.closeGeneralConditionSideDrawer}
               onSuccess={this.setDefaultGeneralCondition}
             />
           </SideDrawer>
        )}

        {this.props.isConditionSideDrawerOpened && (
           <SideDrawer
             isOpen={this.state.specialConditionSideDrawerIsOpen}
             title='Add Special Condition'
             onClose={this.closeSpecialConditionSideDrawer}
             app='condition'
             >
             <CreateSpecialCondition
               selectedConditionType='contract'
               closeDrawer={this.closeSpecialConditionSideDrawer}
               onSuccess={this.setDefaultSpecialCondition}
             />
           </SideDrawer>
        )}
        {
          this.state.showCustomEmailDialog &&
          <CustomEmailDialog
            parties={['broker', 'buyer', 'seller']}
            customEmailParties={this.canAddCustomEmailToParties()}
            selectedParties={this.getSelectedParties()}
            title='Email PDF copies to'
            partyEmails={this.getPartyEmails()}
            partyContacts={this.getPartyContacts()}
            subject={this.getEmailSubject()}
            noBody={true}
            footer={this.getFooterNote()}
            open={this.state.showCustomEmailDialog}
            onClose={this.closeCustomEmailDialog}
            disableAcceptanceRequired={this.isAcceptanceDisabled()}
            isAcceptanceRequired={isAcceptanceRequired}
            forceSetAcceptanceRequired={isAcceptanceRequired}
            disabledPartiesForEmail={this.getDisabledPartiesForEmail()}
            disableLater={emailDialogFieldsDisabled}
          />
        }
        {this.state.ngrEntity && this.props.isCreateNgrFormDisplayed &&
         <SideDrawer
           isOpen={this.state.ngrSideDrawerIsOpen}
           title="Add NGR"
           size="big"
           onClose={this.closeNgrSideDrawer}
           >
           <CreateCompanyNgr companyId={this.state.ngrEntity.id} company={this.state.ngrEntity} canAccessAny={true} closeDrawer={this.closeNgrSideDrawer} onSuccess={this.handleNgrCreation} bankAccountDetailsMandatory={false}/>
         </SideDrawer>
        }
        { this.state.openCompanySideDrawer &&
          <SideDrawer isOpen={this.state.openCompanySideDrawer} title={this.state.contractParty} onClose={this.closeCompanySideDraw}>
            <CreateCompany closeDrawer={this.closeCompanySideDraw} skipCompanyRedirection addNewCompany={this.addNewCompany}/>
          </SideDrawer>
        }
      </div>
);
}
}

const mapDispatchToProps = dispatch => {
  return {
    handleAddEmployeeButtonClick: () => {
      dispatch(clickAddEmployeeButton());
    },
    handleAddCompanyNgrButtonClick: () => dispatch(clickAddCompanyNgrButton()),
    handleAddNgrButtonClick: () => dispatch(clickAddNgrButton()),
    isCurrentUserRepresentingAnyParty: (isRepresenting) => dispatch(isCurrentUserRepresentingAnyParty(isRepresenting)),
    dispatch
  };
};

const mapStateToProps = state => {
  var documentTypes = [];
  var types = [];
  var paymentTerms = [];
  var paymentScales = [];
  var tolerances = [];
  var conveyances = [];
  var checkpoints = [];
  var packagings = [];
  var pricePoints = [];
  var poolContractTypes = [];
  var brokerCompanies = [];
  if (!isEmpty(state.companies.contracts.documentTypes)) {
    documentTypes = map(state.companies.contracts.documentTypes, partialRight(pick, ['id', 'name', 'displayName']));
  }
  if (!isEmpty(state.companies.contracts.types)) {
    const allContractTypes = map(state.companies.contracts.types, partialRight(pick, ['id', 'displayName']));
    const poolContractIDs = [CONTRACT_TYPES.POOL_CASH_AND_CALL, CONTRACT_TYPES.POOL_STRATEGIC_6_MONTH, CONTRACT_TYPES.POOL_STRATEGIC_12_MONTH];
    forEach(allContractTypes, type => {
      if (includes(poolContractIDs, type.id)) {
        poolContractTypes.push(type);
      } else {
        types.push(type);
      }
    });
  }
  if (!isEmpty(state.companies.contracts.pricePoints)) {
    pricePoints = map(state.companies.contracts.pricePoints, partialRight(pick, ['id', 'displayName']));
  }
  if (!isEmpty(state.companies.contracts.paymentTerms)) {
    paymentTerms = state.companies.contracts.paymentTerms;
  }
  if (!isEmpty(state.companies.contracts.paymentScales)) {
    paymentScales = map(state.companies.contracts.paymentScales, partialRight(pick, ['id', 'name']));
  }
  if (!isEmpty(state.companies.contracts.tolerances)) {
    tolerances = map(state.companies.contracts.tolerances, partialRight(pick, ['id', 'name']));
  }
  if (!isEmpty(state.companies.contracts.checkpoints)) {
    checkpoints = map(state.companies.contracts.checkpoints, partialRight(pick, ['id', 'name']));
  }
  if (!isEmpty(state.companies.contracts.conveyances)) {
    conveyances = map(state.companies.contracts.conveyances, partialRight(pick, ['id', 'name']));
  }
  if (!isEmpty(state.companies.contracts.packagings)) {
    packagings = map(state.companies.contracts.packagings, partialRight(pick, ['id', 'name']));
  }

  const companyId = state.main.user.user.companyId;
  const currentUser = state.main.user.user;
  const serverErrors = state.companies.contracts.serverErrors;
  const generalConditionsAll = state.companies.conditions.generalConditions;
  const specialConditionsAll = state.companies.conditions.specialConditions;
  const defaultGeneralConditionId = state.companies.conditions.setDefaultGeneralCondition;
  const defaultSpecialConditionId = state.companies.conditions.setDefaultSpecialCondition;
  const marketZonesWithRegions = state.main.marketZones || [];
  const marketZones = map(marketZonesWithRegions, function(mz) {
    return { name: mz.name, id: mz.id };
  });

  const tracks = state.main.tracks;
  const brokers = isCurrentUserBroker() ? filter(state.companySettings.employees.items, { companyId: companyId }) : [];
  if (currentUser)
    brokerCompanies = filter(state.companies.companies.companyParties, party => {
      return party.typeId === 2 && party.id !== currentUser.companyId;
    });
  if (isCurrentUserBroker() && !find(brokerCompanies, { id: currentUser.companyId }))
    brokerCompanies = [...brokerCompanies, currentUser.company];

  const grades = state.master.grades.items || [];
  const commodities = state.master.commodities.items || [];
  const varieties = state.master.varieties.items || [];
  const selectedContract = state.companies.contracts.selectedContract || null;
  const selectedCompany = state.companies.companies.selectedCompany;
  return {
    isCompanyPartyDataSet: state.companies.companies.isCompanyPartyDataSet,
    allCompanyParties: state.companies.companies.companyParties,
    selectedCompany,
    documentTypes,
    types,
    pricePoints,
    tolerances,
    checkpoints,
    paymentTerms,
    paymentScales,
    packagings,
    conveyances,
    marketZonesWithRegions,
    marketZones,
    tracks: tracks || [],
    companyId,
    serverErrors,
    currentUser,
    brokers,
    brokerCompanies,
    generalConditionsAll,
    specialConditionsAll,
    grades,
    commodities,
    varieties,
    selectedContract,
    userToken: state.main.user.token,
    isLoading: state.main.isLoading,
    defaultGeneralConditionId,
    defaultSpecialConditionId,
    poolContractTypes,
    isCreateNgrFormDisplayed: state.companies.companyNgrs.isCreateFormDisplayed,
    isCreateFormDisplayed: state.companies.ngrs.isCreateFormDisplayed,
    isConditionSideDrawerOpened: state.companies.conditions.isCreateFormDisplayed,
    isEmployeeSideDrawerOpened: state.companySettings.employees.isCreateFormDisplayed,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ContractForm));
