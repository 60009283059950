import React, { Component } from 'react';
import { connect } from 'react-redux';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { fetchMarketZones } from '../../actions/main';
import { getCountryConfig } from '../../common/utils';
import mapValues from 'lodash/mapValues';
import forEach from 'lodash/forEach';
import some from 'lodash/some';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import get from 'lodash/get';
import { required, maxLength } from '../../common/validators';
import GoogleMapPopup from '../common/GoogleMapPopup';
import APIService from '../../services/APIService';
import CommonButton from '../common/CommonButton';
import CommonTextField from '../common/CommonTextField';
import AutoComplete from '../common/autocomplete/AutoComplete';
import isEqual from "lodash/isEqual";
import DasMap from '../common/DasMap';

class FarmFieldForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      didSetFarmAttrs: false,
      regions: [],
      regionSearchText: '',
      marketzoneSearchText: '',
      isAddressSelected: false,
      defaultLocation: {},
      serverErrors : {},
      fields: {
        name: {
          value: '',
          validators: [required(), maxLength(100)],
          errors: []
        },
        address: {
          value: {},
          validators: [required()],
          errors: []
        },
        marketzoneId: {
          value: '',
          validators: [],
          errors: []
        },
        regionId: {
          value: '',
          validators: [],
          errors: []
        },
        size: {
          value: '',
          validators: [required()],
          errors: []
        },
        ownershipType: {
          value: '',
          validators: [required()],
          errors: []
        }
      }
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.setAllFieldsValuesBySelectedFarmField = this.setAllFieldsValuesBySelectedFarmField.bind(this);
    this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
    this.onNewRequestMarketZone = this.onNewRequestMarketZone.bind(this);
    this.onUpdateInputMarketZone = this.onUpdateInputMarketZone.bind(this);
    this.onNewRequestRegion = this.onNewRequestRegion.bind(this);
    this.onUpdateInputRegion = this.onUpdateInputRegion.bind(this);
    this.getSelectedFarm = this.getSelectedFarm.bind(this);

    const { dispatch } = this.props;
    dispatch(fetchMarketZones());
  }

  componentDidMount() {
    this.setAllFieldsValuesBySelectedFarmField();
  }

  setAttributesFromFarm() {
    const { selectedFarm, marketZonesWithRegions } = this.props;
    const newState = {...this.state};
    newState.didSetFarmAttrs = true;
    newState.defaultLocation = {
      formatted_address: get(selectedFarm, 'address.address'),
      lat: get(selectedFarm, 'address.latitude'),
      lng: get(selectedFarm, 'address.longitude'),
      suburb: get(selectedFarm, 'address.suburb'),
      state: get(selectedFarm, 'address.state'),
      postCode: get(selectedFarm, 'address.postCode'),
      streetName: get(selectedFarm, 'address.streetName'),
    };
    const marketZoneId = get(selectedFarm, 'marketZoneId');
    newState.fields.marketzoneId.value = marketZoneId;
    if(marketZoneId)
      newState.regions = get(find(marketZonesWithRegions, {id: marketZoneId}), 'regions', []);
    newState.fields.regionId.value = get(selectedFarm, 'regionId');
    this.setState(newState);
  }

  shouldSetAttributesFromFarm() {
    return !this.props.selectedFarmField &&
           this.props.selectedFarm &&
           !isEmpty(this.props.marketZonesWithRegions) &&
           !this.state.didSetFarmAttrs;
  }

  componentDidUpdate(prevProps) {
    if (this.shouldSetAttributesFromFarm())
      this.setAttributesFromFarm();

    if (this.props.selectedFarmField) {
      if (this.props.selectedFarmField.id !== prevProps.selectedFarmField.id) {
        this.setAllFieldsValuesBySelectedFarmField();
      }
    }

    if(!isEmpty(this.props.serverErrors) && !isEqual(this.state.serverErrors,this.props.serverErrors)){
        const newState = { ...this.state };
        newState.serverErrors = this.props.serverErrors;
        this.setState(newState);
    }
  }

  getSelectedFarm() {
    APIService.farms(this.props.farmId).get(this.props.token)
      .then((farm) => {
        const address = {
          formatted_address: farm.address.address,
          lat: farm.address.latitude,
          lng: farm.address.longitude,
          suburb: farm.address.suburb,
          state: farm.address.state,
          postCode: farm.address.postCode,
          streetName: farm.address.streetName,
        };
        const newState = {...this.state};
        newState.defaultLocation = address;
        this.setState(newState);
      });
  }

  onNewRequestMarketZone(selectedMarketZone) {
    const newState = {...this.state};
    newState.regions = get(find(this.props.marketZonesWithRegions, {id: selectedMarketZone.id}), 'regions');
    newState.regionSearchText = "";
    newState.marketzoneSearchText = selectedMarketZone.name;
    newState.fields.marketzoneId.value = selectedMarketZone.id;
    newState.fields.regionId.value = null;
    if(isEmpty(newState.regions)){
      newState.fields.regionId.validators = [];
      newState.fields.regionId.errors = [];
    }
    else{
      newState.fields.regionId.validators = [required()];
    }
    this.setState(newState);
    this.setFieldErrors('marketzoneId');
  }

  onUpdateInputMarketZone(searchText) {
    const newState = {...this.state};
    newState.marketzoneSearchText = searchText;
    if (isEmpty(searchText)) {
      newState.regions = [];
      newState.regionSearchText = "";
      newState.fields.marketzoneId.value = "";
    }
    this.setState(newState);
  }

  onNewRequestRegion(selectedRegion) {
    const newState = {...this.state};
    newState.regionSearchText = selectedRegion.name;
    newState.fields.regionId.value = selectedRegion.id;
    this.setState(newState);
    this.setFieldErrors('regionId');
  }

  onUpdateInputRegion(searchText) {
    this.setState({
      ...this.state,
      regionSearchText: searchText
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setAllFieldsErrors(()=>{
      const isFormInvalid = some(this.state.fields, (field) => {
        return field.errors.length > 0;
      });
      const submitData = mapValues(this.state.fields, (field) => {
        return field.value;
      });
      const data = {
        ...submitData,
        address: {
          address: submitData.address.formatted_address,
          latitude: submitData.address.lat,
          longitude: submitData.address.lng,
          suburb: submitData.address.suburb,
          state: submitData.address.state,
          postCode: submitData.address.postCode,
          streetName: submitData.address.streetName,
        }
      };
      if (!isFormInvalid){
        this.props.submit(this.props.farmId, data);
      }
    });
  }

  handleFieldChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
  }

  onFieldBlur(event) {
    this.setFieldErrors(event.target.id);
  }

  handleAddressChange(data) {
    const address = {
      formatted_address: data.formatted_address,
      lat: data.lat,
      lng: data.lng,
      suburb: data.suburb,
      state: data.state,
      postCode: data.postCode,
      streetName: data.streetName,
    };
    const newState = {...this.state};
    newState.fields.address.value = address;
    newState.fields.address.errors = [];
    newState.isAddressSelected = true;
    this.setState(newState);
  }

  handleSelectChange(key, event) {
    this.setFieldValue(key, event.target.value);
  }

  setFieldValue(key, value) {
    const newState = {...this.state};
    newState.fields[key].value = value;
    this.setState(newState, () => this.setFieldErrors(key));
  }

  getFieldErrors(key) {
    const errors = [];
    const value = (key=='address' && !this.state.isAddressSelected) ? '' : this.state.fields[key].value;
    const validators = this.state.fields[key].validators || [];

    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });

    return errors;
  }

  setFieldErrors(key) {
    this.setState(state => ({
      ...state,
      fields: {
        ...state.fields,
        [key]: {
          ...state.fields[key],
          errors: this.getFieldErrors(key)
        }
      }
    }));
  }

  setAllFieldsValuesBySelectedFarmField() {
    if (this.props.selectedFarmField) {
      const newState = { ...this.state };

      forEach(newState.fields, (value, key) => {
        newState.fields[key].value = this.props.selectedFarmField[key];
      });

      if(this.props.selectedFarmField['marketzone']) {
        newState.marketzoneSearchText = this.props.selectedFarmField['marketzone'].name;
      }

      if(this.props.selectedFarmField['region']) {
        newState.regionSearchText = this.props.selectedFarmField['region'].name;
      }
      const addressValue = {
        formatted_address: this.props.selectedFarmField['address'].address,
        lat: this.props.selectedFarmField['address'].latitude,
        lng: this.props.selectedFarmField['address'].longitude,
        suburb: this.props.selectedFarmField['address'].suburb,
        state: this.props.selectedFarmField['address'].state,
        postCode: this.props.selectedFarmField['address'].postCode,
        streetName: this.props.selectedFarmField['address'].streetName,
      };
      newState.fields.address.value = addressValue;
      newState.isAddressSelected = true;
      this.setState(newState);
      setTimeout( () => {
        const newState = {...this.state};
        newState.regions = find(
          this.props.marketZonesWithRegions,
          ['id', this.props.selectedFarmField['marketzone'].id]
        )['regions'];
        newState.fields.marketzoneId.value = this.props.selectedFarmField['marketzone'].id;
        this.setState(newState);
      }, 500);
    }
  }

  setAllFieldsErrors(callback) {
    const newState = { ...this.state };
    forEach(newState.fields, (value, key) => {
      newState.fields[key].errors = this.getFieldErrors(key);
    });
    this.setState(newState, callback);
  }

  handleAutoCompleteChange = name => value => {
    if(name === 'marketZone')
      this.onNewRequestMarketZone(value ? find(this.props.marketZones, {id: value}) : {id: '', name: ''});
    else if(name === 'region')
      this.onNewRequestRegion(value ? find(this.state.regions, {id: value}) : {id: '', name: ''});
  };

  render() {
    const marketItems = this.props.marketZones &&
      this.props.marketZones.map(e => ({label: e.name, value: e.id}));
    const regionItems = this.state.regions &&
          this.state.regions.map(e => ({label: e.name, value: e.id}));
    const sizeUnit = getCountryConfig()?.farmField?.sizeUnit

    return (
      <div>
        <form onSubmit={this.handleSubmit} noValidate>
          <div className="cardForm cardForm--drawer">
            <div className="cardForm-content row">
              <div className="col-sm-12 form-wrap-70">
                <CommonTextField
                  id="name"
                  label="Field Name"
                  helperText= {this.state.fields.name.errors[0] || get(this.state.serverErrors,'name.0') || get(this.state.serverErrors,'_All_.0')}
                  onChange={this.handleFieldChange}
                  onBlur={this.onFieldBlur}
                  value={this.state.fields.name.value}
                  maxLength="100"
                />
              </div>
              <div className="col-sm-12 form-wrap-70">
                <GoogleMapPopup
                  id="address"
                  location={this.state.fields.address.value}
                  defaultLocation={this.state.defaultLocation}
                  errors={this.state.fields.address.errors || get(this.state.serverErrors,'address.0')}
                  onDone={this.handleAddressChange}
                  showDefaultLocationOnLabel={!!this.props.selectedFarmField}
                  updateLocationFromSearchBox={this.handleAddressChange}
                />
                {
                  this.props.selectedFarmField?.id && window.DAS_INTEGRATION_TOGGLE &&
                    <DasMap field={this.props.selectedFarmField} />
                }
              </div>
              <div className="col-sm-12 form-wrap-70">
                <AutoComplete
                  id="marketzoneId"
                  label="Market Zone (Optional)"
                  placeholder="Market Zone (Optional)"
                  options={marketItems}
                  onChange={this.handleAutoCompleteChange('marketZone')}
                  fullWidth
                  errorText={this.state.fields.marketzoneId.errors[0] || get(this.state.serverErrors,'marketzoneId.0')}
                  onBlur={this.onFieldBlur}
                  value={this.state.fields.marketzoneId.value}
                />
              </div>
              <div className="col-sm-12 form-wrap-70">
                <AutoComplete
                  id="regionId"
                  label="Region"
                  placeholder="Region"
                  onChange={this.handleAutoCompleteChange('region')}
                  options={regionItems}
                  fullWidth
                  onBlur={this.onFieldBlur}
                  errorText={this.state.fields.regionId.errors[0] || get(this.state.serverErrors,'regionId.0')}
                  value={this.state.fields.regionId.value}
                />
              </div>
              <div className="col-sm-12 form-wrap-70">
                <CommonTextField
                  id="size"
                  type="number"
                  label="Size"
                  value={this.state.fields.size.value}
                  onChange={this.handleFieldChange}
                  onBlur={this.onFieldBlur}
                  helperText={this.state.fields.size.errors[0] || get(this.state.serverErrors,'size.0')}
                  maxLength="10"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{sizeUnit}</InputAdornment>,
                  }}
                />
              </div>
              <div className="col-sm-12 form-wrap-70">
                <FormControl
                  error={!!this.state.fields.ownershipType.errors[0] || !!get(this.state.serverErrors,'ownershipType.0')}
                  style={{width: '100%'}}
                  variant="standard">
                  <InputLabel htmlFor="ownershipType">Ownership Type</InputLabel>
                  <Select
                    id="ownershipType"
                    value={this.state.fields.ownershipType.value}
                    onChange={event => this.handleSelectChange('ownershipType', event)}
                    variant="standard">
                    <MenuItem value="company owned">Company Owned</MenuItem>
                    <MenuItem value="shared farm">Shared Farm</MenuItem>
                    <MenuItem value="leased">Leased</MenuItem>
                  </Select>
                  <FormHelperText>{this.state.fields.ownershipType.errors[0] || get(this.state.serverErrors,'ownershipType.0')}</FormHelperText>
                </FormControl>
              </div>
            </div>
            <div className="col-sm-12 cardForm-action top15 padding-reset">
              <CommonButton
                type="button"
                variant="outlined"
                label="Cancel"
                default
                onClick={this.props.selectedFarmField ? this.props.cancelEdit : this.props.closeDrawer}
              />
              <CommonButton
                primary={true}
                variant="contained"
                label="Save"
                type="submit"
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const marketZonesWithRegions = state.main.marketZones || [];
  const marketZones = map(
    marketZonesWithRegions, function(mz) {
      return {name: mz.name, id: mz.id};
    });
  return {
    marketZonesWithRegions,
    marketZones,
    serverErrors: state.companies.farmFields.serverErrors,
    token: state.main.user.token,
    selectedFarm: state.companies.farms.selectedFarm,
  };
};

export default connect(mapStateToProps)(FarmFieldForm);
