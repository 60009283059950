import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import APIService from '../../services/APIService';
import { debounce, get, map, find, isEmpty, startsWith, isNumber, merge, isArray } from 'lodash';
import { dataConfigMap, isEqualByProperty, getCountryConfig } from '../../common/utils';
import { truckRegoRegex } from '../../common/validators';

const fetchItems = debounce((endpoint, searchStr, token, queryParams, callback) => {
  queryParams = queryParams || {};
  const queryStr = searchStr ? merge(queryParams, { search: searchStr }) : queryParams;
  APIService.empty()
    .appendToUrl(startsWith(endpoint, '/') ? endpoint.slice(1) : endpoint)
    .get(token, null, queryStr)
    .then(results => {
      if (typeof callback === 'function') {
        callback(results);
      }
    });
}, 500);

const SelectAndInput = props => {
  const MIN_CHAR_FOR_SEARCH = 3;
  let selectedValue;
  const [state, setState] = useState({
    isDefaultNotChecked: true,
    isLoading: false,
    options: [],
    inputText: '',
    searchable: true,
    selectedOption: {},
    actualOptions: [],
  });

  useEffect(() => {
    if (isNumber(props.value) && isEmpty(selectedValue) && isEmpty(state.selectedOption) && !isEmpty(state.options)) {
      selectedValue = props.value;
      const selectedOption = find(state.options, { id: selectedValue });
      const inputText = get(selectedOption, 'name');
      if (selectedOption) {
        setState({ ...state, isLoading: false, inputText, selectedOption });
      }
    }
    if ((isEmpty(state.options) && !isEmpty(props.options)) || (!isEmpty(props.options) && !isEqualByProperty(props.options, state.options))) {
      setState({ ...state, options: map(props.options, item => dataConfigMap(item, props.optionMap)) });
    }
    if (props.endpoint && props.search && get(state.inputText, 'length') >= MIN_CHAR_FOR_SEARCH && state.searchable) {
      fetchItems(props.endpoint, state.inputText, props.token, props.queryParams, apiCallback);
    }
    if (!isEmpty(state.inputText) && state.isLoading && isEmpty(props.endpoint)) {
      setState({ ...state, isLoading: false });
    }
  });


  useEffect(() => {
    if(props.inputText && props.inputText != state.inputText && find(props.options, option => option[props.optionMap?.name] === props.inputText))
      setState({...state, inputText: props.inputText})
    else if(props.inputText && props.inputText != state.inputText && find(state.options, option => option?.name === props.inputText))
      setState({...state, inputText: props.inputText})
  }, [props.inputText, state.options])

  useEffect(() => {
    if(props.items && props.items.length > state.options.length) {
      setState({...state, options: map(props.items, item => dataConfigMap(item, props.optionMap))})
    }
  }, [props.items])

  const handlePropChange = (prop) => {
    if (prop && !find(state.options, { id: prop.id })) {
      const item = dataConfigMap(prop, props.optionMap);
      selectedValue = prop?.id;
      setState({ ...state, inputText: prop.rego, options: [item] });
    }
  };

  useEffect(() => {
    if(props.deliveryCreatedTruck)
      handlePropChange(props.deliveryCreatedTruck);
    else if (props.pickupCreatedTruck)
      handlePropChange(props.pickupCreatedTruck);
    else if (props.createdTruck) {
      handlePropChange(props.createdTruck);
      props.onChange(props.createdTruck?.id, props.id, props.createdTruck);
    }
  },[props.deliveryCreatedTruck, props.pickupCreatedTruck, props.createdTruck])

  const apiCallback = results => {
    let options = []
    if(results.length == 0 && state.inputText && state.inputText.length >= 4 && state.inputText.length <= 10 && state.inputText.toLowerCase() !== 'fleet')
      options.push({ inputValue: state.inputText, name: `Add "${state.inputText}" as new ${props.label}`});
    else
      options = map(results, item => dataConfigMap(item, props.optionMap));
    setState({ ...state, searchable: false, isLoading: false, options, actualOptions: results });
  };

  const callback = (data) => {
    if(data?.isAvailable) {
      let config = getCountryConfig();
      let payload = {
        rego: state.inputText.toUpperCase(),
        tareWeight: get(config, 'truck.tareWeight'),
        grossWeight: get(config, 'truck.grossWeight')
      }
      props.create(config?.systemCompanyId, payload, props.actionCreator, null, data?.key);
    }
  }

  const handleChange = (e, item) => {
    if (e) e.preventDefault();
    let inputText = '';
    if (item && item?.inputValue && !(item?.inputValue.length < 3 || item?.inputValue.length > 10)) {
      props.validate(props.id, item?.inputValue, callback);
    } else {
      inputText = get(item, 'name', '');
      selectedValue = get(item, 'id');
    }
    setState({ ...state, isLoading: false, inputText });
  };

  const handleInputChange = (e, value, reason) => {
    if (props.inputText && reason === 'reset')
      return;
    let inputText = (value || '').trim() || '';
    let searchable = true;
    let isLoading = Boolean(inputText);
    if (reason === 'reset') {
      isLoading = false;
      searchable = false;
      setSelectedValue(inputText);
    }
    setState({ ...state, isLoading, searchable, inputText });
  };

  const setSelectedValue = name => {
    const item = find(state.options, { name });
    if (!isEmpty(item)) selectedValue = get(item, 'id');
  };

  const handleBlur = e => {
    e.preventDefault();
    let inputText = state.inputText;
    if (!selectedValue) setSelectedValue(state.inputText);
    if ((props.allowText && !isEmpty(props.options)) || (!props.allowText && !isEmpty(props.options)) || (props.allowEmptyOptions && !props.allowText)) {
      let options = isEmpty(state.actualOptions) ? props.options : state.actualOptions;
      let item = find(options, { id: selectedValue });
      if (item)
        props.onChange(selectedValue, props.id, item);
      if (props.getSelectedOption)
        props.getSelectedOption(item);
    } else if (props.allowText) {
      let options = isEmpty(state.actualOptions) ? props.options : state.actualOptions;
      let item = find(options, { id: selectedValue });
      props.onChange(selectedValue || state.inputText, props.id, item);
      if (props.getSelectedOption)
        props.getSelectedOption(item);
    }
    if (isNumber(selectedValue) && !isEmpty(state.options))
      inputText = get(find(state.options, { id: selectedValue }), 'name');
    setState({ ...state, isLoading: false, inputText });
  };

  const getOptionLabel = option => get(option, 'name', '');
  const _overriddenProps = props.endpoint ? {filterOptions: x => x} : {}
  const checkValidLengthOfText = state.inputText && (state.inputText.length < 3 || state.inputText.length > 10)

  return (
    <div className='relative-pos' ref={props.setRef}>
      <Autocomplete
        id={props.id}
        freeSolo={props.allowText}
        blurOnSelect
        options={isArray(state.options) ? state.options : []}
        getOptionLabel={getOptionLabel}
        loading={state.isLoading}
        noOptionsText={state.inputText ? 'No results' : 'Start typing...'}
        inputValue={props.allowText ? state.inputText : state.inputText || ''}
        renderInput={params => (
          <React.Fragment>
            <TextField
              {...params}
              value={state.inputText || props.inputText}
              variant='standard'
              label={props.label || 'Input'}
              placeholder={props.search ? `${props.label || 'Input'} (Type to search...)` : props.label || 'Input'}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {state.isLoading && props.search ? <CircularProgress color='inherit' size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              fullWidth
              error={!isEmpty(props.errorText) || checkValidLengthOfText}
              helperText={props.errorText || (checkValidLengthOfText ? truckRegoRegex()?.message : null)}
              disabled={props.disabled}
            />
            {props.disabled && <i className='icon-lock' />}
          </React.Fragment>
        )}
        classes={props.classes}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onInput={props.onInput}
        onBlur={handleBlur}
        disabled={props.disabled}
        {..._overriddenProps}
      />
    </div>
  );
};

SelectAndInput.defaultProps = {
  allowText: true,
  optionMap: { id: 'id', name: 'name' },
};

const mapStateToProps = state => ({
  token: state.main.user.token,
  createdTruck: state.companies.companies.company.trucks.createdTruck,
  pickupCreatedTruck: state.companies.companies.company.trucks.pickupCreatedTruck,
  deliveryCreatedTruck: state.companies.companies.company.trucks.deliveryCreatedTruck,
 });

export default connect(mapStateToProps)(SelectAndInput);
