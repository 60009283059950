import React from 'react';
import { CircularProgress, Chip } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import {
  get, times, find, isString, orderBy, forEach, isNumber, map, capitalize, compact, includes,
} from 'lodash';
import { currentUser, toDateTimeFormat, getCountryLabel, getCountryConfig } from '../../common/utils';
import {PRIMARY_COLOR_GREEN, TOMATO_RED, BALES_UNIT, MT_UNIT, OPTION_TYPE_STORAGE_EMPTY_LOAD, OPTION_TYPE_STORAGE_UPDATE_LOAD, OPTION_TYPE_STOCK_UPDATE_LOAD, OPTION_TYPE_STOCK_EMPTY_LOAD} from '../../common/constants';

const __loadReferences = load => {
  const firstOptionType = -1;
  const secondOptionType = -2;
  const stockUpdateOptionType = -5;
  const falseChipValue = -10;
  const StorageToStorageTransfer = -3;
  const StockSwap = -4;

  return [
    {ref: load.externalSystem, value: load.externalReference},
    {ref: 'Contract', value: load.contractNumber},
    {ref: 'External Contract', value: get(load, 'extras.externalContractNumber')},
    {ref: 'Movement', value: load.movementNumber},
    {ref: 'Title Transfer', value: (load.titleTransferNumber && !load.isCashPriced ? load.titleTransferNumber : false)},
    {ref: 'Cashed Title Transfer', value: (load.titleTransferNumber && load.isCashPriced ? load.titleTransferNumber : false)},
    {ref: 'Shrinkage', value: load.shrinkageParent ? get(load, 'extras.identifier') ? get(load, 'extras.identifier') : load.shrinkageParent : load.shrinkageParent},
    {ref: 'Regraded', value: get(load, 'optionType') == secondOptionType ? get(load, 'regradeIdentifier') ? get(load, 'regradeIdentifier') : get(load, 'extras.identifier', '') : false},
    {ref: 'Stock Update', value: (includes([stockUpdateOptionType, OPTION_TYPE_STOCK_UPDATE_LOAD], get(load, 'optionType')) ? get(load, 'extras.identifier') ? get(load, 'extras.identifier') : falseChipValue  : false)},
    {ref: 'Storage Empty', value: (get(load, 'optionType') == OPTION_TYPE_STORAGE_EMPTY_LOAD ? get(load, 'extras.identifier') ? get(load, 'extras.identifier') : falseChipValue  : false)},
    {ref: 'Storage Update', value: (get(load, 'optionType') == OPTION_TYPE_STORAGE_UPDATE_LOAD ? get(load, 'extras.identifier') ? get(load, 'extras.identifier') : falseChipValue  : false)},
    {ref: 'Stock Empty', value: (get(load, 'optionType') == OPTION_TYPE_STOCK_EMPTY_LOAD ? get(load, 'extras.identifier') ? get(load, 'extras.identifier') : falseChipValue  : false)},
    {ref: 'Stock Match', value: (get(load, 'optionType') == firstOptionType && !get(load, 'extras.identifier')? falseChipValue : false)},
    {ref: 'Direct Load', value: (!(get(load, 'checkpointId')) && !(get(load, 'titleTransferNumber')) && !(get(load, 'optionType')) && !get(load, 'shrinkageParent')) ? get(load, 'extras.identifier') ? get(load, 'extras.identifier') : falseChipValue : false},
    {ref: 'Storage Transfer', value: (get(load, 'optionType') == StorageToStorageTransfer && get(load, 'extras.identifier') ? get(load, 'extras.identifier', '').toUpperCase() : false)},
    {ref: 'Stock Swap', value: (get(load, 'optionType') == StockSwap && get(load, 'extras.identifier') ? get(load, 'stockSwapIdentifier', '').toUpperCase() : false)},
    {ref: 'Transaction', value: load.status === 'void' ? 'Void' : false, "void": load.status === 'void', id: load.id},
    {ref: 'Load', value: get(load, 'identifier') ? get(load, 'identifier') : false},
  ];
};

export const getLoadReferences = (load, isSiteLoad) => {
  if(!load)
    return '';

  const refs = __loadReferences(load);
  if(isSiteLoad)
    return compact(map(refs, ref => { if(ref.value) return ref }))

  return compact(map(refs, ref => ref.value)).join(',');
};

export const loadReferencesDisplay = load => {
  if(!load)
    return;

  const refs = __loadReferences(load);
  const falseChipValue = -10;
  return (
    <div className='chip-items'>
      {
        map(refs, ref => {
          if(ref?.void) {
            return (
              <Chip label='Void' size='small' color='error' key={ref.id} sx={{marginLeft: '5px', marginBottom: '5px'}} />
            )
          } else if(ref.ref && ref.value)
            return (
              <div key={`${ref.ref}-${ref.value}`} className='chip-item'>
                <span className='chip-label'>{capitalize(ref.ref)}</span>
                {ref.value && ref.value != falseChipValue && (
                 <Tooltip title={ref.value}>
                    <span className='chip-value'>{ref.value}</span>
                  </Tooltip>
                )}
              </div>
            );
        })
      }
    </div>
  );
};

export const positiveStockDisplay = (value, unit) => {
  if(isNumber(value))
    return <span style={{color: PRIMARY_COLOR_GREEN}}>{`+${value.toFixed(2)} ${unit || ''}`}</span>;
};

export const negativeStockDisplay = (value, unit) => {
  if(isNumber(value))
    return <span style={{color: TOMATO_RED}}>{`-${Math.abs(value).toFixed(2)} ${unit || ''}`}</span>;
};

export const numberFormaterByLoadType = (load, attr, unit) => {
  const value = get(load, attr);
  if(load.type === 'inload')
    return positiveStockDisplay(value, unit);
  if(load.type === 'outload')
    return negativeStockDisplay(value, unit);
};

export const numberFormatterByValue = (item, attr, unit, showLoaderIfNotNumber) => {
  const value = get(item, attr);
  if(showLoaderIfNotNumber && !isNumber(value))
    return <CircularProgress />;
  if(value >= 0)
    return positiveStockDisplay(value, unit);

  return negativeStockDisplay(value, unit);
};

export const getLoadsWithBalance = loads => {
  if(loads) {
    forEach(loads, load => {
      load.date = toDateTimeFormat(load.dateTime);
      load["void"] = load.status === 'void'
    });
    return loads
  }
};

export const getLoadsWithBalanceWithoutUnshrunkTonnage = loads => {
  if(loads) {
    forEach(loads, load => {
      load.date = toDateTimeFormat(load.dateTime)
      load["void"] = load.status === 'void'
    });
    return loads
  }
};

export const getLoadsWithBalanceWithTargetMoistureTonnage = loads => {
  if(loads) {
    forEach(loads, load => {
      load.date = toDateTimeFormat(load.dateTime)
      load["void"] = load.status === 'void'
    });
    return loads
  }
}

export const getSpecColumns = (commodity, valueField) => {
  const specColumns = commodity && commodity.specs &&
        times(orderBy((commodity.specs || []), 'order').slice(0, 6).length, index => {
          const spec = find(commodity.specs, { order: index + 1 });
          const code = get(spec, 'code','');
          const name = get(spec, 'name');
          const unit = isString(name) ? name.substring(name.indexOf('(') + 1, name.indexOf(')')) : null;
          var header = 'SP' + index;
          if(unit && name){
            header = `${code} (${unit})`;
          } else if(name){
            header = name;
          }
          const key = isString(code) ? `${valueField}.${code.toLowerCase()}` : '';
          return { key: key, header: header, className: 'xsmall' };
        });

  return specColumns;
};

export const getStorageStocksColumnsByCommodity = (commodity, commodities, locations, displayUnit) => {

  const getRemainingSpace = item => item.isGate ? '-' : item.remainingSpace;

  const getLocationName = item => find(locations, {id: item.farmId})?.name;
  const unit = displayUnit || currentUser()?.unit || MT_UNIT
  const isStrictQuantity = commodity?.isStrictQuantityBased

  let columns = [
    { header: 'Location', className: 'small', default: getLocationName },
    { key: 'storageName', header: 'Storage', className: 'small' },
    {
      header: 'Grade',
      className: 'xsmall',
      key: 'grade',
      default: item => {
        const _unit = isStrictQuantity ? commodity?.unit : unit
        return item.gradeId || !getCountryConfig()?.stocks?.storages?.gradeSeasonDistribution ? item.grade : (
          <div style={{maxWidth: 'fit-content'}}>
            {
              map(orderBy(item.grades, grade => grade.tonnage, 'desc'), grade => {
                return (
                  <div key={grade.name} style={{display: 'flex', justifyContent: 'space-between'}}>
                    <span style={{textAlign: 'left'}}>{`${grade.name}: `}</span>
                    <span style={{textAlign: 'right', marginLeft: '8px'}}>{numberFormatterByValue(grade, 'tonnage', _unit)}</span>
                  </div>
                )
              })
            }
          </div>
        )
      }
    },
    {
      key: 'season',
      header: 'Season',
      className: 'xsmall',
      default: item => {
        const _unit = isStrictQuantity ? commodity?.unit : unit
        return item.season !== 'Multiple' || !getCountryConfig()?.stocks?.storages?.gradeSeasonDistribution ? item.season : (
          <span style={{display: 'flex', flexDirection: 'column'}}>
            {
              map(orderBy(item.seasons, season => season.tonnage, 'desc'), season => {
                return (
                  <span key={season.name}>
                    <span>{`${season.name}: `}</span>
                    <span>{numberFormatterByValue(season, 'tonnage', _unit)}</span>
                  </span>
                )
              })
            }
          </span>
        )
      }
    },
    { default: getRemainingSpace, header: 'Remaining Space', className: 'xsmall', key: 'remainingSpace'  },
    { default: item => numberFormatterByValue(item, 'tonnage'), header: `Total ${getCountryLabel('tonnage')} (${unit})`, className: 'xsmall', key: 'tonnage' },
  ];

  if(get(commodity, 'unit') === BALES_UNIT)
    columns.push({ key: 'quantity', header: 'Bales', className: 'xsmall' });
  if(isStrictQuantity)
    columns.push({ key: 'quantity', header: `Quantity (${get(commodity, 'unit')})`, className: 'xsmall' });

  return columns;
};

export const getCompanyOwnershipStocksColumnsByCommodity = (commodity, locations, commodities, displayUnit) => {
  const getLocationName = item => find(locations, {id: item.farmId})?.name
  const getGradeName = item => {
    let _commodity = find(commodities, {id: commodity?.id})
    return find(_commodity?.grades, {id: item.gradeId})?.name
  }
  const unit = displayUnit || currentUser()?.unit || MT_UNIT

  let columns = [
    { key: 'owner', header: 'Owner', className: 'large' },
    { key: 'ngrNumber', header: 'NGR', className: 'xsmall'  },
    { key: 'farmId', header: 'Location', className: 'large', default: getLocationName, sortable: false },
    { default: item => getGradeName(item), header: 'Grade', className: 'xsmall' },
    { key: 'season', header: 'Season', className: 'xsmall'  },
    { header: 'Variety', key: 'variety', className: 'xsmall' },
    { default: item => numberFormatterByValue({tonnage: item.tonnage}, 'tonnage', undefined), header: `${getCountryLabel('tonnage')} (${unit})`, className: 'xsmall', key: 'tonnage' },
  ];

  if(get(commodity, 'unit') === BALES_UNIT)
    columns.push({ key: 'quantity', header: 'Bales', className: 'xsmall' });
  if(get(commodity, 'isStrictQuantityBased'))
    columns.push({ key: 'quantity', header: `Quantity (${get(commodity, 'unit')})`, className: 'xsmall' });

  return columns;

};


export const getSwapStocksColumnsByCommodity = (commodities, displayUnit) => {
  const getGradeName = item => {
    let _commodity = find(commodities, {id: item.commodityId})
    return find(_commodity?.grades, {id: item.gradeId})?.name
  }
  const unit = displayUnit || currentUser()?.unit || MT_UNIT
  let columns = [
    { key: 'farmName', header: 'Location', className: 'large' },
    { default: getGradeName, header: 'Grade', className: 'xsmall', sortable: false },
    { key: 'season', header: 'Season', className: 'xsmall'  },
    { default: item => numberFormatterByValue(item, 'tonnage', undefined), header: `${getCountryLabel('tonnage')} (${unit})`, className: 'xsmall', key: 'tonnage' },
  ];

  return columns;
};

export const getOptionItems = (isOwnershipsView, isStorageView, isThirdParty=false, isContainer=false) => {
  var options = [];
  options = options.concat([
    { key: 'loads', text: 'Loads' },
  ]);
  if(isContainer)
    options = options.concat([
      { key: 'create_container_movement', text: 'Create Container Movement' },
    ]);

  if (!isContainer) {
    options = options.concat([
      { key: 'empty-storage', text: 'Empty Stock' },
      { key: 'update-stock', text: 'Update Stock' }
    ]);
    if (isStorageView)
      options = options.concat([
        { key: 'handle-storage-to-storage-transfer', text: 'Storage To Storage Transfer' },
      ]);
    else
      options = options.concat([
        { key: 'regrading-stock', text: 'Regrade/Reseason Stock' },
      ]);
    if (isThirdParty || isOwnershipsView)
      options = options.concat([
        { key: 'handle-stocks-swap', text: 'Stock Swap' },
        { key: 'handle-transfer', text: 'Transfer' }
      ]);
    if (isOwnershipsView)
      options = options.concat([
        { key: 'cash-out', text: 'Cash Out' },
      ]);
  }
  return options;
};

export const getStorageQueryStringByStocksStorageItem = item => {
  let queryString = '?';

  const stock = item?.stocks || item

  if (stock.commodityId) {
    queryString += `commodityId=${stock.commodityId}`;
    if (get(stock, 'ngrId')) {
      queryString += `&ngrId=${stock.ngrId}`;
    }
    if (stock.gradeId) {
      queryString += `&gradeId=${stock.gradeId}`;
    }
    if (stock.season) {
      queryString += `&season=${stock.season}`;
    }
  }
  return queryString;
};

export const getStorageQueryStringByStocksStorageItemforStorageView = item => {
  let queryString = '?';
  const commodityId = item.stocks?.commodityId || item.commodityId

  if (commodityId) {
    queryString += `commodityId=${commodityId}`;
  }
  return queryString;
};

export const emptyHomeStoragesColumns = unit => {
  return [
    { key: 'name', header: 'Name', className: 'large' },
    { key: 'typeName', header: 'Type', className: 'medium' },
    { key: 'size', header: `Size (${unit})`, className: 'medium' },
    {
      key: 'address',
      header: 'Location',
      className: 'large',
      map: {
        name: 'address.address',
        lat: 'address.latitude',
        lng: 'address.longitude'
      }
    },
  ]
};
