import { template } from "lodash";

export const FREIGHT_PROVIDER_REGISTERED_ERROR_MESSAGE = 'The selected Freight Provider is registered on the system';
export const VENDOR_DEC_NOT_ALLOWED_ERROR_MESSAGE_TEMPLATE = template(`Since this movement was created against a docket on mobile, it is missing <%= missingField %> details. Please enter this information to add a declaration against this movement.`);
export const NO_LINKED_FARMS_ERROR_MESSAGE = 'The selected Freight Provider has no linked farms';
export const TRUCKS_ENTITY = 'trucks';
export const SCENARIOS_FOR_SELF_ORDER = ['Customer is fully or partially handling the freight for the Freight Order using his/her own trucks.',
                                         'Freight Provider for the Freight Order has not been decided yet. In this case Customer can create allocations from the Freight Order later on when the Freight Provider has been decided'];
export const PLANNED_STATUS = 'planned';
export const DELIVERED_STATUS = 'delivered';
export const RECEIVER_PICKUP_SITE = 6;
export const RECEIVER_DELIVERY_SITE = 7;
export const SHOW_TONNAGE_ERROR_FOR_ORDER_TYPE_IDS = [1, 2, 4]

/* eslint-disable spellcheck/spell-checker */
export const LOADING_PORT_LIST = [
  {id: 'geelong', name: 'Geelong'},
  {id: 'melbourne', name: 'Melbourne'},
  {id: 'portland', name: 'Portland'},
  {id: 'outer_harbor', name: 'Outer Harbor'},
  {id: 'newcastle', name: 'Newcastle'},
  {id: 'port_kembla', name: 'Port Kembla'},
  {id: 'brisbane', name: 'Brisbane'},
  {id: 'gladstone', name: 'Gladstone'},
  {id: 'mackay', name: 'Mackay'},
  {id: 'port_giles', name: 'Port Giles'},
  {id: 'wallaroo', name: 'Wallaroo'},
  {id: 'port_lincoln', name: 'Port Lincoln'},
  {id: 'thevenard', name: 'Thevenard'},
  {id: 'kwinana_terminal', name: 'Kwinana Terminal'},
  {id: 'esperance_terminal', name: 'Esperance Terminal'},
  {id: 'albany_terminal', name: 'Albany Terminal'},
  {id: 'geraldton_terminal', name: 'Geraldton Terminal'},
  {id: 'bunbury', name: 'Bunbury'},
  {id: 'mgc', name: 'Mgc'},
  {id: 'port_botany', name: 'Port Botany'},
];
/* eslint-enable spellcheck/spell-checker */

export const CHECKPOINT_ORDER_CREATION_PERMISSIONS = {
  GRAIN_OWNER_AND_SITE_ACCEPTANCE_FROM_SITE: "grain_owner_and_site_acceptance_from_site",
  GRAIN_OWNER_AND_SITE_ACCEPTANCE_NOT_REQUIRED: "grain_owner_and_site",
  SITE_ONLY: "site_only",
}