import React from "react";
import { connect } from 'react-redux';
import { get, isArray, includes, find, isEmpty } from 'lodash';
import APIService from '../services/APIService';
import GenericTable from '../components/GenericTable';
import {
  getSelectedContract,
  receiveContract,
  getContracts,
  getPaginatedContractsResponse,
  handleContractOptionsMapper,
  setClickedOption
} from '../actions/companies/contracts';
import {isLoading} from '../actions/main';
import { toDateFormat } from '../common/utils';
import { getActionMenuOptions, regenerateContractPDF } from '../components/contracts/utils';
import { CONTRACT_TABLE_COLUMN_LIMIT, getContractsGlobalListingTableHeaders } from "../common/constants";
import ContractActions from "../components/contracts/ContractActions";
import { getCustomColumns } from '../actions/companies/index';

class ContractsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item:undefined,
    };
  }

  componentDidMount() {
    this.props.getCustomColumns('contract_table');
  }

  componentWillUnmount() {
    this.clearSelectedOption();
  }

  closeAddSideForm = () =>{
    this.setState({item: undefined});
  };

  openAddSideForm = item =>{
    this.setState({item: item});
  };

  clearSelectedOption = () => {
    this.fetchingForContractId = null;
    this.props.dispatch(setClickedOption(null));
  };

  render() {
    return(
      <React.Fragment>
        <GenericTable
          {...this.props}
          openAddSideForm={this.openAddSideForm}
          closeAddSideForm={this.closeAddSideForm}
          clearEntity={this.clearSelectedOption}
        />
        <ContractActions {...this.props}/>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  let contracts = get(state, 'companies.contracts.items', []);
  if (!isArray(contracts))
    contracts = [];

  contracts.forEach((item) => {
    item['contractDateFilter'] = toDateFormat(new Date(item.contractDate));
    item['pricePoint'] = get(item,'pricePoint.displayName') || get(item,'pricePoint');
  });

  const subItems = state.companies.contracts.subItems;
  const clickedOption = state.companies.contracts.clickedOption;
  const contractColumns = [...getContractsGlobalListingTableHeaders()];
  let customColumns = contractColumns;
  const tableColumnsOrder = get(state.companies, 'companies.tableColumns');
  if (tableColumnsOrder && !isEmpty(tableColumnsOrder)) {
    customColumns = [];
    tableColumnsOrder.forEach(val => {
      const obj = find(contractColumns, {key: val});
      if (obj) {
        customColumns.push(obj);
      }
    });
  }
  if (customColumns.length > CONTRACT_TABLE_COLUMN_LIMIT) {
    customColumns = customColumns.splice(0, CONTRACT_TABLE_COLUMN_LIMIT);
  }
  return {
    displayIDColumn: 'referenceNumber',
    clearSearch: getPaginatedContractsResponse,
    columns: customColumns,
    globalSearch: true,
    items: contracts,
    optionsItems: item => getActionMenuOptions(item, subItems, clickedOption, true),
    order: 'desc',
    orderBy: 'contractDate',
    paginationData: state.companies.contracts.paginationData,
    rowHighlightedMap: {'isHighlighted': 'delhi-blue'},
    scrollToTopOnUpdate: false,
    selectedContractId: state.companies.contracts.selectedContractId,
    selectedContract: state.companies.contracts.selectedContract,
    shouldFetchContract: state.companies.contracts.shouldFetchContract,
    useNestedOptionMenu: true,
    isFetchingVoidRequest: state.companies.contracts.isFetchingVoidRequest,
    canCreateCOGForContractId: state.companies.contracts.canCreateCOGForContractId,
    canCreateTitleTransferForContractId: state.companies.contracts.canCreateTitleTransferForContractId,
    canCloseOutForContract: state.companies.contracts.canCloseOutForContract,
    showHierarchyForOrderId: state.companies.contracts.showHierarchyForOrderId,
    openCloseOutDialog: state.companies.contracts.openCloseOutDialog,
    canRaiseVoidRequest: state.companies.contracts.canRaiseVoidRequest,
    canRaiseVoidAndDuplicateRequest: state.companies.contracts.canRaiseVoidAndDuplicateRequest,
    canMarkAsPaid: state.companies.contracts.canMarkAsPaid,
    subItems: subItems,
    clickedOption: clickedOption,
    voidFilter: true
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleOptionClick: (event, item, baseEntity) => {
      if(get(item, 'key') === 'regenerate_pdf') {
        return regenerateContractPDF(baseEntity);
      } else {
        event.persist()
        return dispatch(handleContractOptionsMapper(event, item, baseEntity));
      }
    },
    handleDefaultCellClick: (contract) => {
      dispatch(isLoading('contractDetail'));
      dispatch(getSelectedContract(contract.id, receiveContract, false, false));
      document.location.hash = '/contracts/' + contract.id + '/contract';
    },
    navigateTo: (url) => {
      dispatch(getContracts(url, true));
    },
    changePageSize: (url, pageSize) => {
      if (includes(url, '?')){
        url = `${url}&page_size=${pageSize}`;
      } else {
        url = `${url}?page_size=${pageSize}`;
      }
      dispatch(getContracts(url, true));
    },
    getSearchSortUrl: (pageSize, page, searchText, orderBy, order, _, includeVoid) => {
      const contractService = APIService.contracts();
      contractService.appendToUrl(`web/search/?page_size=${pageSize}`);
      if (page) {
        contractService.appendToUrl(`&page=${page}`);
      }
      if(searchText) {
        contractService.appendToUrl(`&search=${searchText}`);
      }
      if(orderBy) {
        contractService.appendToUrl(`&order_by=${orderBy}&order=${order}`);
      }
      if(includeVoid) {
        contractService.appendToUrl(`&include_void=${includeVoid}`)
      }

      return contractService.URL;
    },
    getCustomColumns: tableType => dispatch(getCustomColumns(tableType)),
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractsTable);
