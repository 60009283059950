import React from 'react';
import { connect } from 'react-redux';
import Paper from '@mui/material/Paper';
import {
  Event as DueDateIcon,
  AssignmentTurnedIn as CreateIcon,
  MarkEmailRead as SendIcon,
} from '@mui/icons-material';
import { isLoading, setBreadcrumbs, setHeaderText, forceStopLoader, setDownloadBar } from '../../actions/main';
import {
  Button, Checkbox, Dialog, DialogActions, DialogContent, Divider, Tooltip, FormControlLabel, Menu, MenuItem, ListItemIcon, ListItemText, DialogTitle, Skeleton, Chip
} from '@mui/material';
import { get, isEmpty, find, reject, isEqual, findIndex, set, has, map, filter, remove, includes, some, forEach, isString, compact, without, orderBy } from 'lodash';
import APIService from '../../services/APIService';
import { currentUserCompany, isCompanyGrower, getCountryConfig, getCountryCurrency } from '../../common/utils';
import moment from 'moment';
import { FILTER_KEYS_TO_EXCLUDE, INVOICE_FILTER_KEYS_MAPPING, PREDEFINED_DATE_RANGE_FILTER_KEYS, RECEIVABLE_INVOICE_ITEMS_COLUMNS, WARNING_ORANGE } from '../../common/constants';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import alertifyjs from 'alertifyjs';
import FilterListIcon from '@mui/icons-material/FilterList';
import SideDrawer from '../common/SideDrawer';
import InvoiceFilters from '../common/InvoiceFilters';
import CommonDatePicker from '../common/CommonDatePicker';
import CommonListingButton from '../common/CommonListingButton';
import { attachCSVEventListener, getCountryFormats } from '../../common/utils';
import IconButton from '@mui/material/IconButton';
import Create from '@mui/icons-material/Create';
import { FREIGHT_CONTRACT_ITEM_TYPE, LOAD_ITEM_TYPE } from './Constants';
import CustomHeaderOptions from '../common/CustomHeaderOptions';
import InvoicePayableTable from '../../containers/InvoicePayableTable';
import EmailAutocomplete from '../common/autocomplete/EmailAutocomplete';
import InvoiceGrainLevies from './InvoiceGrainLevies';
import WarningIcon from '@mui/icons-material/Warning';

const gradeMismatchInfoIcon = item => {
  if(!item?.isGradeMatchingWithContract && includes(["freightcontract", "titletransfer"], item?.itemType))
    return (
      <Tooltip arrow title={`The grade in this invoice item doesn't match with the Contract grade(s) - ${item?.contractGrades?.join(', ')}`}>
        <IconButton
          className='new-releases-button'
          onClick={() => alertifyjs.alert('Warning', `The grade in this invoice item doesn't match with the Contract grade(s) - ${item?.contractGrades?.join(', ')}`,
            () => { }
          )}
          size="small">
          <WarningIcon fontSize="small"
            style={{ cursor: 'pointer', color: WARNING_ORANGE }}
          >
          </WarningIcon>
        </IconButton>
      </Tooltip>
    )
  return null
}

const CUSTOM_HEADER_EDIT = {
  fontSize: '12px',
  textDecoration: 'underline',
  cursor: 'pointer',
  marginLeft: '10px'
};

const OPTIONS = {
  'create': {
    title: 'Create Invoices',
  },
  'createSend': {
    title: 'Create and Send Invoices',
  },
  'dueDate': {
    title: 'Update Payment Due Date',
  }
}

const DEFAULT_FILTERS = {
  'payer__company_id__in': [],
  'contract__commodity__id__in': '',
  'contract__administration__invoicing__in': '',
  'payment_due_date_range': '',
  'payment_due_date__gte': '',
  'payment_due_date__lte': ''
}

class InvoiceReceivable extends React.Component {
  constructor(props) {
    super(props);
    const config = getCountryConfig();
    this.state = {
      expanded: [],
      loadingItemsFor: [],
      companyDirectory: undefined,
      invoicesItems: undefined,
      selectedInvoiceItems: [],
      invoiceItemsGroupedByPayer: [],
      allInvoiceItems: [],
      count: 0,
      levyEprMapping: {},
      openDialog: false,
      applyFilters: false,
      openSideDrawer: false,
      filters: {},
      levyEprDefaults: undefined,
      levyEprPreference: undefined,
      bankProcessingDate: {
        value: undefined,
        error: ''
      },
      recipientsDialogOpen: false,
      sellerEmployeeData: undefined,
      selectedRecipients: undefined,
      showRecipientsError: false,
      isFetchingInvoices: false,
      isFetchingCompanyDirectory: false,
      csvPopup: false,
      isFilteredCsv: false,
      customColumns: true,
      customColumnNames: {},
      customHeaderOptions: false,
      customColumnTitle: undefined,
      unverifiedNgrData: [],
      openBulkActions: false,
      dueDate: '',
      gstRate: config?.invoicing?.gstRate,
    };
    this.handleBankProcessingDateChange = this.handleBankProcessingDateChange.bind(this);
    this.editCustomHeaderOptions = this.editCustomHeaderOptions.bind(this);
  }

  componentDidMount() {
    this._attachCSVEventListener();
    this.fetchInvoices();
    APIService.profiles()
      .filters('invoice_receivable')
      .get()
      .then(res => {
        this.setState({
          filters: get(res, 'invoice_receivable') || DEFAULT_FILTERS,
        });
      });
  }

  setHeaderAndBreadcrumbs() {
    const countLabel = ` (${this.state.count})`;
    const breadcrumbs = [{ text: 'Invoices Receivable' + countLabel }];
    const headerText = 'Invoices Receivable';
    this.props.setHeaderText(headerText);
    if (!isEqual(this.props.breadcrumbs, breadcrumbs)) {
      this.props.setBreadcrumbs(breadcrumbs);
    }
  }

  async getLevyEprPreferences(companyId) {
    const response = await APIService.companies()
          .appendToUrl(`${companyId}/payment-run-levy-epr-preferences/`)
          .get();
    return response;
  }

  async fetchLevyEprPreference(){
    const company = currentUserCompany();
    const response = await this.getLevyEprPreferences(company.id)
    this.setState({ levyEprPreference: response });
  };

  async fetchLevyEprDefaults() {
    const { invoicesItems } = this.state;
    const companyIds = map(invoicesItems, item => item.companyId);
    const responses = await Promise.all(
      map(companyIds, companyId => this.getLevyEprPreferences(companyId))
    );
    const levyEprDefaults = Object.fromEntries(
      map(responses, (response, index) => [companyIds[index], response])
    );
    this.setState({ levyEprDefaults });
  };

  async fetchInvoices() {
    this.props.dispatch(isLoading('LoadingReceivables'))
    if(!this.state.isFetchingInvoices) {
      this.setState({isFetchingInvoices: true}, async () => {
        const company = currentUserCompany();
        this.props.dispatch(isLoading('LoadingReceivables'))
        APIService.invoices().appendToUrl(`receivable-items/?company_id=${company.id}&items=false`).get().then(response =>
          this.setState({invoicesItems: response, isFetchingInvoices: false}, () => {
            this.fetchLevyEprPreference()
            this.fetchLevyEprDefaults()
            if(isEmpty(this.state.companyDirectory))
              this.getCompanyDirectory()
            else
              this.getReceivableInvoiceItems()
          }));
      })
    }
  }

  fetchItems = (event, payerCompanyId) => {
    event.preventDefault()
    event.stopPropagation()
    if(payerCompanyId && this.state.expanded.includes(payerCompanyId)) {
      this.setState({expanded: without(this.state.expanded, payerCompanyId)})
    }
    if(payerCompanyId && !this.state.expanded.includes(payerCompanyId) && !this.state.loadingItemsFor.includes(payerCompanyId)) {
      this.setState({expanded: [...this.state.expanded, payerCompanyId], loadingItemsFor: [...this.state.loadingItemsFor, payerCompanyId]}, () => {
        const company = currentUserCompany();
        let payer = find(this.state.invoicesItems, {companyId: payerCompanyId})
        if(!isEmpty(payer.items)) {
          const newState = {...this.state}
          newState.loadingItemsFor = without(newState.loadingItemsFor, payerCompanyId)
          this.setState(newState)
        } else {
          APIService.invoices().appendToUrl(`receivable-items/?company_id=${company.id}&payer_company_id=${payerCompanyId}&only_items=true`).get().then(response => {
            const newState = {...this.state}
            payer.items = response[0]?.items || []
            payer.chemicalApplicationItems = response[0]?.chemicalApplicationItems || []
            payer = {...payer, ...response[0]}
            newState.loadingItemsFor = without(newState.loadingItemsFor, payerCompanyId)
            this.setState(newState, () => {
              this.getReceivableInvoiceItems(payerCompanyId)
            })
          });
        }
      })
    }
  }

  getCompanyDirectory() {
    if(!this.state.isFetchingCompanyDirectory)
      this.setState({isFetchingCompanyDirectory: true}, async () => {
        APIService.companies().appendToUrl('directory/names/?excludeGroups=true').get().then(companies =>
          this.setState({companyDirectory: companies, isFetchingCompanyDirectory: false}, this.getReceivableInvoiceItems));
      })
  }

  handleValueChange(item, value, key) {
    let formattedValue = value ? parseFloat(value) : 0
    if (formattedValue > 0) {
      formattedValue = -1 * formattedValue;
    }
    const newState = {...this.state};
    let isEpr = key === 'epr';
    map(this.state.invoiceItemsGroupedByPayer, (obj, index) => {
      var itemIndex = findIndex(obj?.items, itemObj => itemObj.id === item.id);
      if (itemIndex !== -1) {
        let total = get(this.state.invoiceItemsGroupedByPayer, `${index}.items.${itemIndex}.total`);
        let prevValue = get(this.state.invoiceItemsGroupedByPayer, `${index}.items.${itemIndex}.${key}`);
        let prevTotalValue = prevValue;
        let formattedValueTotal = formattedValue;
        if (isEpr) {
          let gst = get(this.state.invoiceItemsGroupedByPayer, `${index}.items.${itemIndex}.gst`);
          let prevValueGst = prevValue * this.state.gstRate;
          prevTotalValue = prevValue + prevValueGst;
          gst -= prevValueGst;
          let formattedValueGst = formattedValue * this.state.gstRate;
          formattedValueTotal = formattedValue + formattedValueGst;
          gst += formattedValueGst;
          set(newState.invoiceItemsGroupedByPayer, `${index}.items.${itemIndex}.gst`, gst.toFixed(2));
        }
        total -= prevTotalValue;
        if(formattedValueTotal)
          total += formattedValueTotal;

        set(newState.invoiceItemsGroupedByPayer, `${index}.items.${itemIndex}.${key}`, formattedValue);
        set(newState.invoiceItemsGroupedByPayer, `${index}.items.${itemIndex}.total`, total);
        let itemId = get(obj.items, `${itemIndex}.id`);
        set(newState.levyEprMapping, `${obj.companyId}.${itemId}.${key}`, formattedValue);
        return;
      }
    });
    let selectedItemIndex = findIndex(this.state.selectedInvoiceItems, itemObj => itemObj.id === item.id);
    if (selectedItemIndex !== -1) {
      let total = get(this.state.selectedInvoiceItems, `${selectedItemIndex}.total`);
      let prevValue = get(this.state.selectedInvoiceItems, `${selectedItemIndex}.${key}`);
      let prevTotalValue = prevValue;
      let formattedValueTotal = formattedValue;
      if (isEpr) {
        let gst = get(this.state.selectedInvoiceItems, `${selectedItemIndex}.gst`);
        let prevValueGst = prevValue * this.state.gstRate;
        prevTotalValue = prevValue + prevValueGst;
        gst -= prevValueGst;
        let formattedValueGst = formattedValue * this.state.gstRate;
        formattedValueTotal = formattedValue + formattedValueGst;
        gst += formattedValueGst;
        set(newState.selectedInvoiceItems, `${selectedItemIndex}.gst`, gst.toFixed(2));
      }
      total = (total - prevTotalValue) + formattedValueTotal;
      set(newState.selectedInvoiceItems, `${selectedItemIndex}.${key}`, formattedValue);
      set(newState.selectedInvoiceItems, `${selectedItemIndex}.total`, total);
    }
    this.setState(newState);
  }


  toFormattedDate = date => {
    if(date) {
      const mDate = moment(date)
      return mDate.isValid() ? mDate.format(getCountryFormats().date) : date
    }
    return date
  }

  includeLevyAdjustments = () => {
    if (has(currentUserCompany(), 'includeLevyAdjustments'))
      return get(currentUserCompany(), 'includeLevyAdjustments')
    return true;
  };

  getReceivableInvoiceItems(payerCompanyId) {
    const { invoicesItems } = this.state;
    let allInvoiceItems = [];
    const newState = {...this.state};
    const company = currentUserCompany();
    if (!isEmpty(invoicesItems)) {
      let items = invoicesItems
      if(payerCompanyId)
        items = filter(invoicesItems, {companyId: payerCompanyId})
      map(items, obj => {
        let payee = find(this.state.companyDirectory, {id: obj.companyId});
        obj.payeeName = get(payee, 'name');
        let levyEprPreference = get(this.state.levyEprPreference, obj.companyId)
        let defaultValue = get(this.state.levyEprDefaults, `${obj.companyId}.defaultValue`, true) ? isCompanyGrower(company) && !obj.isGrower : false
        obj.levy = get(levyEprPreference, 'levy', defaultValue);
        obj.epr = get(levyEprPreference, 'epr', defaultValue);
        if (obj.recipients && !isEmpty(obj.recipients)) {
          let recipientDisplayStr = "";
          map(obj.recipients, recipient => {
            if (isEmpty(recipientDisplayStr))
              recipientDisplayStr = recipientDisplayStr + recipient.email;
            else
              recipientDisplayStr = recipientDisplayStr + ", " + recipient.email;
          });
          obj['recipientDisplay'] = 'Recipients: (' + recipientDisplayStr + ')';
        }
        obj.allSelected = false;
        allInvoiceItems = [...allInvoiceItems, ...(obj.items || [])];
        newState.levyEprMapping[obj.companyId] = {};
        map(obj.items, invoiceItem => {
          invoiceItem.paymentDue = this.toFormattedDate(invoiceItem.paymentDue)
          invoiceItem.levy = invoiceItem.levy || 0;
          invoiceItem.epr = invoiceItem.epr || 0;
          invoiceItem.carry = invoiceItem.carry || 0;
          let eprTotal = invoiceItem.epr + (invoiceItem.epr * this.state.gstRate);
          invoiceItem.isEditable = invoiceItem.invoiceStatus === 'draft' && !get(invoiceItem, 'isBlendingFees');
          if (!this.includeLevyAdjustments() && !isEmpty(invoiceItem.levyItems)) {
            let adjustmentsTotal = invoiceItem.levyItems.reduce((total, item) => total + item.adjustedPriceValue, 0);
            invoiceItem.levy = invoiceItem.levy - adjustmentsTotal;
            const updatedLevyItems = invoiceItem.levyItems.map(item => {
              return {
                ...item,
                total: item.total - item.adjustedPriceValue
              };
            });
            invoiceItem.levyItems = [...updatedLevyItems];
          }
          invoiceItem.total = invoiceItem.total + invoiceItem.levy + eprTotal + invoiceItem.carry;
          if (invoiceItem.isEditable) {
            if (!obj.levy && invoiceItem.levy)
              invoiceItem.total -= invoiceItem.levy;
            if (!obj.epr && eprTotal)
              invoiceItem.total -= eprTotal;
          }
          newState.levyEprMapping[obj.companyId][invoiceItem.id] = {};
          newState.levyEprMapping[obj.companyId][invoiceItem.id] = { levy: invoiceItem.levy, epr: invoiceItem.epr};
          if (!obj.levy && invoiceItem.isEditable) {
            delete invoiceItem.levy;
          }
          if (!obj.epr && invoiceItem.isEditable) {
            delete invoiceItem.epr;
          }
          if(invoiceItem.itemType === 'customitem') {
            delete invoiceItem.carry
            delete invoiceItem.epr
            delete invoiceItem.levy
          }
        });
        obj.chemicalApplicationItems = isEmpty(obj.chemicalApplicationItems) ? [] : obj.chemicalApplicationItems
        obj.chemicalApplicationItems.map(chemicalApplicationItem => {
          let index = findIndex(obj.items, item => item.itemId === chemicalApplicationItem.chemicalAppliedOnLoadId || item.itemId === chemicalApplicationItem.freightMovementId);
          if (index !== -1) {
            let blendLoadItem = get(obj.items, index);
            chemicalApplicationItem.ref = get(blendLoadItem, 'ref');
            chemicalApplicationItem.itemUrl = get(blendLoadItem, 'itemUrl');
            chemicalApplicationItem.loadRef = get(blendLoadItem, 'loadRef');
            chemicalApplicationItem.contract = get(blendLoadItem, 'contract');
            chemicalApplicationItem.contractUrl = get(blendLoadItem, 'contractUrl');
            chemicalApplicationItem.invoiceUrl = get(blendLoadItem, 'invoiceUrl');
            chemicalApplicationItem.confirmedInvoice = get(blendLoadItem, 'confirmedInvoice');
            chemicalApplicationItem.contractInvoicing = get(blendLoadItem, 'contractInvoicing');
            chemicalApplicationItem.paymentDue = get(blendLoadItem, 'paymentDue');
            chemicalApplicationItem.tonnageDisplayName = get(blendLoadItem, 'tonnageDisplayName');
            chemicalApplicationItem.rateDisplayName = `${getCountryCurrency()} ${chemicalApplicationItem.rate} / ${get(chemicalApplicationItem, 'adjustments.loadUnit')}`;
            chemicalApplicationItem.levy = 0;
            chemicalApplicationItem.epr = 0;
            chemicalApplicationItem.carry = 0;
            chemicalApplicationItem.sellerNgrDetails = get(blendLoadItem, 'sellerNgrDetails');
            obj.items.splice(index + 1, 0, chemicalApplicationItem);
            allInvoiceItems.push(chemicalApplicationItem);
          }
        });
      });
    }
    newState.allInvoiceItems = [...allInvoiceItems];
    newState.count = allInvoiceItems.length;
    newState.invoiceItemsGroupedByPayer = invoicesItems;
    this.setState(newState, () => {this.setHeaderAndBreadcrumbs(); this.props.dispatch(forceStopLoader());})
  }

  updateSelectedInvoiceItem = (selected, isChecked, obj) => {
    if (selected.isBlended) {
      if (isChecked) {
        let relatedItems = this.state.allInvoiceItems.filter(item => item.freightMovementId && item.freightMovementId === selected.freightMovementId && includes([LOAD_ITEM_TYPE, FREIGHT_CONTRACT_ITEM_TYPE], item.itemType) && item.id !== selected.id);
        let items = [...relatedItems, selected];
        var index = findIndex(this.state.invoiceItemsGroupedByPayer, item => item.companyId === obj.companyId);
        const newState = {...this.state};
        map(this.state.invoiceItemsGroupedByPayer[index].items, (item, itemIndex) => {
          if (item.isChemicalItem && item.freightMovementId === selected.freightMovementId) {
            set(newState.invoiceItemsGroupedByPayer, `${index}.items.${itemIndex}.isCheckedItem`, true);
          }
        })
        newState.selectedInvoiceItems = [...this.state.selectedInvoiceItems, ...items];
        this.setState(newState);
      }
      else {
        const newState = {...this.state};
        remove(newState.selectedInvoiceItems, selectedItem => selectedItem.freightMovementId && selectedItem.freightMovementId === selected.freightMovementId && includes([LOAD_ITEM_TYPE, FREIGHT_CONTRACT_ITEM_TYPE], selectedItem.itemType));
        var objIndex = findIndex(this.state.invoiceItemsGroupedByPayer, item => item.companyId === obj.companyId);
        map(this.state.invoiceItemsGroupedByPayer[objIndex].items, (item, itemIndex) => {
          if (item.isChemicalItem && item.freightMovementId === selected.freightMovementId) {
            set(newState.invoiceItemsGroupedByPayer, `${objIndex}.items.${itemIndex}.isCheckedItem`, false);
          }
        })
        this.setState(newState);
      }
    }
    else if (selected.itemType === LOAD_ITEM_TYPE) {
      if (isChecked) {
        let relatedItems = this.state.allInvoiceItems.filter(item => item.titleTransferId && item.titleTransferId === selected.titleTransferId && item.itemType === LOAD_ITEM_TYPE && item.id !== selected.id);
        let items = [...relatedItems, selected];
        this.setState({selectedInvoiceItems: [...this.state.selectedInvoiceItems, ...items]});
      }
      else {
        const newState = {...this.state};
        remove(newState.selectedInvoiceItems, selectedItem => selectedItem.titleTransferId && selectedItem.titleTransferId === selected.titleTransferId && selectedItem.itemType === LOAD_ITEM_TYPE);
        this.setState(newState);
      }
    }
    else {
      this.setState({selectedInvoiceItems: isChecked ? [...this.state.selectedInvoiceItems, selected] : reject(this.state.selectedInvoiceItems, {id: selected.id})});
    }
  }

  isSelected = invoiceItem => Boolean(find(this.state.selectedInvoiceItems, {id: invoiceItem.id}) || invoiceItem.isCheckedItem);

  isSelectAllCheckboxDisabled(obj){
    return (obj.isSubscriber && isEmpty(obj.items.filter(item => item.contractInvoicing !== "Buyer RCTI")))
  }

  onSelectAllToggle = (event, value, obj) => {
    const newState = {...this.state};
    var index = findIndex(this.state.invoiceItemsGroupedByPayer, item => item.companyId === obj.companyId);
    set(newState, `invoiceItemsGroupedByPayer.${index}.allSelected`, value);
    if (value) {
      const filteredItems = obj.isSubscriber ? obj.items.filter(item => item.contractInvoicing !== "Buyer RCTI") : obj.items;
      newState.selectedInvoiceItems = [...this.state.selectedInvoiceItems, ...filteredItems];
    }
    else {
      let itemIds = map(obj.items, 'id');
      newState.selectedInvoiceItems = this.state.selectedInvoiceItems.filter(item => !itemIds.includes(item.id));
    }
    this.setState(newState);
  }

  handleCheckboxChange(event, obj, key) {
    event.preventDefault()
    event.stopPropagation()

    const company = currentUserCompany();
    const data = {}
    var index = findIndex(this.state.invoiceItemsGroupedByPayer, item => item.companyId === obj.companyId);
    let value = !get(obj, key);
    data['payee_company_id'] = obj.companyId;
    data['settings'] = {};
    data['settings'][key] = value;
    APIService.companies()
      .appendToUrl(`${company.id}/payment-run-levy-epr-preferences/`)
      .put({paymentRunEprLevyPreference: data})
      .then(() => {
        const newState = {...this.state};
        if (value) {
          map(this.state.invoiceItemsGroupedByPayer[index].items, (item, itemIndex) => {
            if (item.isEditable) {
              let keyValue = get(this.state.levyEprMapping, `${obj.companyId}.${item.id}.${key}`);
              let currentTotal = item.total ? parseFloat(item.total) : 0;
              let newTotal = currentTotal + keyValue;
              if (key === 'epr') {
                let currentGst = item.gst ? parseFloat(item.gst) : 0;
                let eprGst = keyValue * this.state.gstRate;
                let newGst = currentGst + eprGst;
                newTotal += eprGst
                set(newState.invoiceItemsGroupedByPayer, `${index}.items.${itemIndex}.gst`, newGst.toFixed(2));
              }
              var selectedItemIndex = findIndex(this.state.selectedInvoiceItems, selectedItem => selectedItem.id === item.id);
              if (selectedItemIndex !== -1) {
                set(newState.selectedInvoiceItems, `${selectedItemIndex}.${key}`, keyValue);
              }
              set(newState.invoiceItemsGroupedByPayer, `${index}.items.${itemIndex}.${key}`, keyValue);
              set(newState.invoiceItemsGroupedByPayer, `${index}.items.${itemIndex}.total`, newTotal.toFixed(2));
            }
          });
        }
        else {
          map(this.state.invoiceItemsGroupedByPayer[index].items, (item, itemIndex) => {
            if (item.isEditable) {
              let currentTotal = item.total ? parseFloat(item.total) : 0;
              let keyValue = get(this.state.invoiceItemsGroupedByPayer, `${index}.items.${itemIndex}.${key}`);
              let newTotal = currentTotal - keyValue;
              if (key === 'epr') {
                let currentGst = item.gst;
                let eprGst = item.epr * this.state.gstRate;
                let newGst = currentGst - eprGst;
                newTotal -= eprGst
                set(newState.invoiceItemsGroupedByPayer, `${index}.items.${itemIndex}.gst`, newGst.toFixed(2));
              }
              var selectedItemIndex = findIndex(this.state.selectedInvoiceItems, selectedItem => selectedItem.id === item.id);
              if (selectedItemIndex !== -1) {
                set(newState.selectedInvoiceItems, `${selectedItemIndex}.${key}`, null);
              }
              set(newState.invoiceItemsGroupedByPayer, `${index}.items.${itemIndex}.total`, newTotal.toFixed(2));
              delete newState.invoiceItemsGroupedByPayer[index].items[itemIndex][key];
            }
          });
        }
        set(newState, `invoiceItemsGroupedByPayer.${index}.${key}`, value);
        this.setState(newState);
      })
    return false
  }

  handleFilters = bool => {
    this.setState({
      applyFilters: bool,
      openSideDrawer: bool,
      expanded: [],
      loadingItemsFor: []
    });
  };

  handleFilterState = (key, value) => {
    this.setState({[key]: value}, () => {
      if(key === 'applyFilters' && value) {
        const { filters } = this.state;
        delete filters['commodity__id__in'];
        APIService
          .profiles()
          .filters()
          .post({ invoice_receivable: filters }, this.props.token)
          .then(res => {
            this.setState({filters: get(res, 'filters.invoice_receivable', {})}, () => {
              this.props.isLoading();
              this.fetchInvoices();
            })
          });
      }
    });
  };

  handleBankProcessingDateChange(value) {
    const newState = {...this.state};
    newState.bankProcessingDate.value = value;
    newState.bankProcessingDate.error = '';
    this.setState(newState);
  }

  onCloseDownloadResponse() {
    this.props.setDownloadBar(false);
  }

  onDownloadResponse(message) {
    this.props.setDownloadBar(message, true, this.onCloseDownloadResponse);
  }
  _attachCSVEventListener() {
    attachCSVEventListener('payables-csv-ready', 'Invoice Receivables', this.onDownloadResponse);
  }

  fetchCSVData = (callback) => {
    const company = currentUserCompany();
    var param = this.state.isFilteredCsv ? 'show_filters': '';
    if (this.state.customColumns) {
      param = param.length == 0 ? param + 'custom_csv' : param + '&custom_csv';
    }
    const { setDownloadBar } = this.props;
    setDownloadBar('Your Payables CSV is getting prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true);
    const service = APIService.invoices();
    service.appendToUrl(`receivables/csv/?company_id=${company.id}&${param}`);
    service
      .get(this.props.token, {
        'Content-Type': 'text/csv',
        Accept: 'text/csv',
      })
      .then(() => this.setState({csvPopup: false}));
    if (callback) {
      callback();
    }
  };

  handleEmailRecipients(event, companyId) {
    event.stopPropagation()
    event.preventDefault()
    var index = findIndex(this.state.invoiceItemsGroupedByPayer, item => item.companyId === companyId);
    const selectedRecipients = get(this.state.invoiceItemsGroupedByPayer[index], 'recipients');
    this.setState({selectedRecipients: selectedRecipients})
    APIService
      .profiles()
      .appendToUrl('employees-signature/')
      .get(null, null, {company_ids: companyId})
      .then(employees => {
        const contactsWithEmail = map(filter(employees, employee => employee.email), employee => ({...employee, title: `${employee.name} (${employee.email})`, value: employee.email}))
        this.setState({sellerEmployeeData: contactsWithEmail, recipientsDialogOpen: true})
      })
    return false
  }

  onDialogClose = () => this.setState({recipientsDialogOpen: false});

  onBulkInvoiceClose = () => this.setState({openDialog: false, selectedBulkOption: false})

  customCsvEnabled(isFilteredCsv) {
    const newState = {...this.state};
    newState.isFilteredCsv = isFilteredCsv;
    if (this.props.currentUser.company.enableCustomCsv) {
      newState.csvPopup = true;
      this.setState(newState);
    }
    else {
      newState.customColumns = false;
      this.setState(newState, this.fetchCSVData);
    }
  }

  async editCustomHeaderOptions() {
    const columnNames = await APIService.profiles().appendToUrl(`${this.props.currentUser.id}/report-preferences/invoice_receivables_csv/`).get();
    this.setState({customColumnNames: columnNames, customHeaderOptions: true});
  }

  updateColumnCount(count) {
    this.setState({customColumnTitle: `Edit Columns (${count})`});
  }

  onBulkActionClick = event => {
    const option = event.currentTarget.id
    let invoiceItemsWithUnMatchedGradesWithContract = []
    if(includes(['create', 'createSend'], option)) {
      if(some(this.state.selectedInvoiceItems, invoice => (invoice.invoiceStatus === 'confirmed'))) {
        alertifyjs.alert('Operation Not Permitted', 'Some of the selected items are fully invoiced. You cannot re-create these. For this action, please select invoices that are not fully invoiced.')
        return
      }
      let selectedInvoiceItems = [...this.state.selectedInvoiceItems];
      map(selectedInvoiceItems, selectedItem => {
        if (!get(selectedItem, 'isGradeMatchingWithContract')){
          invoiceItemsWithUnMatchedGradesWithContract.push(selectedItem)
        }
      })
      let warningContent = '';
      if(!isEmpty(invoiceItemsWithUnMatchedGradesWithContract)){
        const text = '<li>' + invoiceItemsWithUnMatchedGradesWithContract.map(item => `${item.ref} - ${item.contractGrades}`).join('</li><li>')
        warningContent = `<div>One or more invoice items contain a grade that doesn't match with their Contract grade(s)</div><ul>${text}</ul>`
      }
      if (warningContent) {
        alertifyjs.confirm(
          'Warning',
          `<div>
              ${warningContent}
              <div>
                <b>Do you want to continue to create Invoice?<b>
              </div>
          </div>`,
          () => this.setState({openDialog: true, openBulkActions: false, selectedBulkOption: option}),
          () => {}
        );
      } else
      this.setState({openDialog: true, openBulkActions: false, selectedBulkOption: option})
    }
    else
      this.setState({openDialog: true, openBulkActions: false, selectedBulkOption: option})
  }

  toggleBulkActions = event => this.setState(
    {openBulkActions: this.state.openBulkActions ? false : event.currentTarget}
  )

  onPaymentDueDateChange = dueDate => {
    this.setState({dueDate: dueDate})
  }

  generateInvoices = noMail => {
    this.props.dispatch(isLoading('bulkGenerateReceivableInvoices'))
    const xeroEnabled = get(this.props, 'currentUser.company.xeroEnabled');
    let selectedInvoiceItems = [...this.state.selectedInvoiceItems];
    remove(selectedInvoiceItems, selectedInvoiceItem => selectedInvoiceItem.isChemicalItem);
    let invoiceItems = selectedInvoiceItems.reduce((obj, objectKey) => ({ ...obj, [objectKey.id]: {'levies': objectKey.levyItems.reduce((acc, levy) => (acc[levy.levyType] = {"total":objectKey.levy ? levy.total : undefined, "isCustomAdjustment": levy?.isCustomAdjustment }, acc), {}), 'epr': objectKey.epr}}), {});
    let data = {
      'invoiceItems': invoiceItems,
      'noMail': noMail,
      'includeLevyAdjustments': this.includeLevyAdjustments(),
    };
    if (xeroEnabled)
      data['communication'] = {'createXeroInvoice': true};
    APIService.invoices()
      .appendToUrl('receivable-generate/')
      .post(data)
      .then(() => {
        alertifyjs.success("Receivables Invoice created successfully");
        this.onBulkInvoiceClose()
        this.fetchInvoices()
        window.location.reload()
      });
  }

  onSubmit = () => {
    const { dueDate, selectedBulkOption, selectedInvoiceItems } = this.state
    if(selectedBulkOption === 'create')
      this.generateInvoices(true)
    else if(selectedBulkOption === 'createSend')
      this.generateInvoices(false)
    else if(selectedBulkOption === 'dueDate')
      APIService.invoices().appendToUrl('web/').put({invoice_ids: map(selectedInvoiceItems, 'invoiceId'), payment_due_date: dueDate, is_receivable: true}).then(() => {
        this.fetchInvoices()
        this.onBulkInvoiceClose()
      })
  }

  getRecipientEmailsToSubmit = () => {
    const { selectedRecipients } = this.state
    const result = []
    if(!isEmpty(selectedRecipients))
      forEach(selectedRecipients, recipient => result.push(isString(recipient) ? recipient : get(recipient, 'email')))
    return compact(result)
  }

  onRecipientsChange = (event, selected) => {
    if (selected && selected.length <= 3)
      this.setState({showRecipientsError: false});
    this.setState({selectedRecipients: selected});
  }

  submitEmailRecipients = () => {
    if (this.state.selectedRecipients.length > 3) {
      this.setState({showRecipientsError: true});
    }
    else {
      this.setState({recipientsDialogOpen: false, showRecipientsError: false});
      const companyId = get(this.state.selectedRecipients, '0.companyId');
      var index = findIndex(this.state.invoiceItemsGroupedByPayer, item => item.companyId === companyId);
      var invoiceIds = map(this.state.invoiceItemsGroupedByPayer[index].items, item => item.invoiceId);
      const data = {
        'selectedRecipients': this.state.selectedRecipients,
        'invoiceIds': invoiceIds,
        'isReceivable': true
      }
      APIService
        .invoices()
        .appendToUrl('email-recipients/')
        .put(data)
        .then(() => window.location.reload());
    }
  }

  customFilterValueExist = filterKeys => filterKeys.some(key => Boolean(get(this.state.filters, key)))

  filterCriteria = (key, value) => includes(FILTER_KEYS_TO_EXCLUDE, key) ? false : includes(PREDEFINED_DATE_RANGE_FILTER_KEYS, key) && value === 'custom' ? this.customFilterValueExist(get(INVOICE_FILTER_KEYS_MAPPING, key)) : value.length !== 0;

  updateLevies = (item, levies) => {
    const newState = {...this.state};
    map(this.state.invoiceItemsGroupedByPayer, (obj, index) => {
      var itemIndex = findIndex(obj?.items, itemObj => itemObj.id === item.id);
      if (itemIndex !== -1) {
        set(newState.invoiceItemsGroupedByPayer, `${index}.items.${itemIndex}.levyItems`, levies);
        return;
      }
    });
    let selectedItemIndex = findIndex(this.state.selectedInvoiceItems, itemObj => itemObj.id === item.id);
    if (selectedItemIndex !== -1)
      set(newState.selectedInvoiceItems, `${selectedItemIndex}.levyItems`, levies);
    this.setState(newState, () => {
      const levyTotal = levies.reduce((accumulator, levy) => accumulator + levy.total, 0);
      this.handleValueChange(item, levyTotal, 'levy');
    })
  }

  render() {
    const {
      invoiceItemsGroupedByPayer, openDialog, selectedInvoiceItems, dueDate, selectedBulkOption, openBulkActions,
      recipientsDialogOpen, selectedRecipients, sellerEmployeeData
    } = this.state;
    const selectedCount = selectedInvoiceItems?.length || 0;
    const isDueDateOption = selectedBulkOption === 'dueDate'
    const isCreateOption = selectedBulkOption === 'create'
    const isCreateAndSendOption = selectedBulkOption === 'createSend'
    const today = moment()
    let receivableColumns = [...RECEIVABLE_INVOICE_ITEMS_COLUMNS];
    receivableColumns.splice(10, 0, { key: 'levy', header: 'Levy', className: 'xsmall', editComponent: InvoiceGrainLevies, isColumnEditable: true, type: 'currency', editableFieldType: 'number', updateData: this.updateLevies},)
    receivableColumns.splice(1, 0, { header: '', className: 'xxsmall', sortable: false, formatter: gradeMismatchInfoIcon})
    return (
      <Paper className="paper-table">
        <div>
          <div style={{display: 'inline-flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
            <div style={{display: 'inline-flex', alignItems: 'center'}}>
              <span style={{ paddingLeft: '10px', fontWeight: 'bold', fontSize: '20px', marginTop: '8px' }}>
                Receivable
              </span>
            </div>
            <div style={{display: 'inline-flex', alignItems: 'center'}}>
              <Button
                variant="contained"
                type='button'
                color='primary'
                disabled={selectedCount === 0}
                onClick={this.toggleBulkActions}
                style={{ float: 'right', marginLeft: '10px' }}
              >
                Action Selected Invoices
              </Button>
              <Tooltip title='Apply filters' placement='top'>
                <Button
                  value={this.state.applyFilters}
                  variant="contained"
                  type='button'
                  onClick={() => this.handleFilters(true)}
                  color='primary'
                  className='add-button'
                  style={{ float: 'right', marginLeft: '10px' }}
                >
                  <FilterListIcon style={{ paddingRight: '5px' }} />
                  FILTERS{' '}
                  {+!isEmpty(Object.entries(this.state.filters).filter(val => this.filterCriteria(val[0], val[1])))
                   ? `(${Object.entries(this.state.filters).filter(val => this.filterCriteria(val[0], val[1])).length})`
                   : ''}
                </Button>
              </Tooltip>
              <CommonListingButton
                defaultHandler={() => this.customCsvEnabled(false)}
                showMenus={!isEmpty(Object.entries(this.state.filters).filter(val => get(val, '1.length', 0) !== 0))}
                optionMapper={[
                  { name: 'Complete List', fx: () => this.customCsvEnabled(false) },
                  { name: 'Filtered List', fx: () => this.customCsvEnabled(true) },
                ]}
                title='Download Contents of the table in a CSV'
                name='Export'
              />
            </div>
          </div>
          <Divider light style={{ marginLeft: '-10px', marginRight: '-10px', marginTop: '20px' }} />
          {
            !isEmpty(invoiceItemsGroupedByPayer) &&
              map(orderBy(invoiceItemsGroupedByPayer, obj => obj.payeeName.toLowerCase()), (obj, index) => {
                const isExpanded = this.state.expanded.includes(obj.companyId)
                return (
                  <div key={index}>
                    <div style={{ marginLeft: '-10px', marginRight: '-10px', background: '#F5F5F5', height: '50px' }}>
                      <Tooltip title='Click to expand and view items'>
                        <span style={{display: 'inline-flex', alignItems: 'center', width: 'calc(100% - 300px)', cursor: 'pointer'}} onClick={event => this.fetchItems(event, obj.companyId)}>
                          <span style={{ paddingLeft: '15px', fontSize: '20px', lineHeight: '50px' }}>{obj.payeeName}</span>
                          <span style={{ paddingLeft: '15px', fontSize: '15px', lineHeight: '50px', marginTop: '1px' }}>
                            {
                              <>
                                {
                                  get(obj, 'recipientDisplay') ? get(obj, 'recipientDisplay') : ''
                                }
                                <Tooltip title='Edit Recipients'>
                                  <IconButton disabled={!isExpanded} onClick={event => this.handleEmailRecipients(event, obj.companyId)} size="small" sx={get(obj, 'recipientDisplay') ? {marginLeft: '8px'} : {}}>
                                    <Create fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </>
                            }
                          </span>
                        </span>
                      </Tooltip>
                      <div style={{ float: "right", marginRight: '20px', lineHeight: '50px' }}>
                        <Chip label={`${obj.totalItems} Items`} color='secondary' variant='outlined' size='small' sx={{marginRight: '25px'}} />
                        <FormControlLabel
                          style={{marginRight: '25px'}}
                          control={
                            <Checkbox
                              checked={obj.levy}
                              onChange={event => this.handleCheckboxChange(event, obj, 'levy')}
                              disabled={!isExpanded}
                            />
                          }
                          label="Levies"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={obj.epr}
                              onChange={event => this.handleCheckboxChange(event, obj, 'epr')}
                              disabled={!isExpanded}
                            />
                          }
                          label="EPR"
                        />

                      </div>
                    </div>
                    <div style={isExpanded ? {} : { marginTop: '10px' }}>
                      {
                        (isEmpty(obj.items) || !isExpanded) ?
                          (
                            isExpanded ? <Skeleton width='100%' height={100} /> : <div />
                          ) :
                          <InvoicePayableTable
                            height={(((obj.items?.length || 0) + 1) * 65) + 16 + 'px'}
                            items={obj.items}
                            columns={[
                              { size: 'small', sx:{padding: 0}, align: 'left', header: 'All', checkbox: true, className: 'xxsmall', onChange:(selected, isChecked) => this.updateSelectedInvoiceItem(selected, isChecked, obj),
                                func: this.isSelected, selectAll: true, checked: obj.allSelected, isSelectAllCheckboxDisabled:this.isSelectAllCheckboxDisabled(obj), onSelectAll:(event, value) => this.onSelectAllToggle(event, value, obj) },
                              ...receivableColumns
                            ]}
                            handleValueChange={(item, value, key) => this.handleValueChange(item, value, key)}
                            isSubscriber={obj.isSubscriber}
                          />
                      }
                    </div>
                    {
                      recipientsDialogOpen &&
                        <Dialog open keepMounted fullWidth onClose={this.onDialogClose}>
                          <DialogTitleWithCloseIcon
                            onClose={this.onDialogClose}
                            closeButtonStyle={{ marginTop: '0px' }}
                            id='form-dialog-title'>
                            Update Recipients
                          </DialogTitleWithCloseIcon>
                          <DialogContent>
                            <React.Fragment>
                              <div>
                                {'Update Recipients'}
                              </div>
                              <div>
                                <EmailAutocomplete
                                  options={sellerEmployeeData}
                                  onChange={this.onRecipientsChange}
                                  selected={selectedRecipients}
                                />
                              </div>
                              {
                                this.state.showRecipientsError &&
                                  <div style={{ marginTop: '3px', color: '#FF0000', fontSize: '15px'}}>
                                    You can select only 3 emails.
                                  </div>
                              }
                            </React.Fragment>
                          </DialogContent>
                          <DialogActions>
                            <Button color='default' onClick={this.onDialogClose}>Cancel</Button>
                            <Button onClick={this.submitEmailRecipients}>Proceed</Button>
                          </DialogActions>
                        </Dialog>
                    }
                  </div>
                )
              })
          }
        </div>
        {
          Boolean(openBulkActions) &&
            <Menu
              id="actions-menu"
              anchorEl={openBulkActions}
              open
              onClose={this.toggleBulkActions}>
              <MenuItem onClick={this.onBulkActionClick} id='create'>
                <ListItemIcon style={{minWidth: 'unset', marginRight: '5px'}}>
                  <CreateIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Create</ListItemText>
              </MenuItem>
              <MenuItem onClick={this.onBulkActionClick} id='createSend'>
                <ListItemIcon style={{minWidth: 'unset', marginRight: '5px'}}>
                  <SendIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Create & Send</ListItemText>
              </MenuItem>
              <MenuItem onClick={this.onBulkActionClick} id='dueDate'>
                <ListItemIcon style={{minWidth: 'unset', marginRight: '5px'}}>
                  <DueDateIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Update Payment Due Date</ListItemText>
              </MenuItem>
            </Menu>
        }
        {
          openDialog &&
            <Dialog open keepMounted onClose={() => this.setState({openDialog: false})}>
              <DialogTitle>
                {OPTIONS[selectedBulkOption].title}
              </DialogTitle>
              <DialogContent>
                {
                  isDueDateOption &&
                    <CommonDatePicker
                      minDate={today.format('YYYY-MM-DD')}
                      label='Payment Due Date'
                      onChange={this.onPaymentDueDateChange}
                      value={dueDate}
                    />
                }
                {
                  isCreateOption &&
                    "Selected Invoice(s) will be created but emails will not be sent. Are you sure you want to proceed?"
                }
                {
                  isCreateAndSendOption &&
                    <React.Fragment>
                      <div>
                        {
                          `Selected Invoice(s) will be created and emails will be sent to corresponding recipients. Are you sure you want to proceed?`
                        }
                      </div>
                    </React.Fragment>
                }
              </DialogContent>
              <DialogActions>
                <Button color='default' onClick={this.onBulkInvoiceClose}>Cancel</Button>
                <Button disabled={isDueDateOption && !dueDate} onClick={this.onSubmit}>Proceed</Button>
              </DialogActions>
            </Dialog>
        }
        <Dialog open={this.state.csvPopup} onClose={() => this.setState({csvPopup: false})} aria-labelledby='form-dialog-title' fullWidth>
          <DialogTitleWithCloseIcon
            onClose={() => this.setState({csvPopup: false})}
            closeButtonStyle={{ marginTop: '0px' }}
            id='form-dialog-title'>
            Download Invoice Receivable Data
          </DialogTitleWithCloseIcon>
          <DialogContent style={{ marginTop: '15px' }}>
            <span style={{float: 'left'}}>Select checkbox for custom csv download</span>
            <div className='col-sm-6 padding-reset' style={{marginTop: '10px'}}>
              <Checkbox
                id={'custom-headers'}
                checked={this.state.customColumns}
                style={{ height: '40px' }}
                onChange={() => this.setState({customColumns: !this.state.customColumns})}
              />
              Custom Columns
              <a onClick={() => this.editCustomHeaderOptions()} hidden={!this.state.customColumns} style={CUSTOM_HEADER_EDIT}>View & Update</a>
            </div>
            <SideDrawer
              isOpen={this.state.customHeaderOptions}
              title={this.state.customColumnTitle}
              onClose={() => this.setState({customHeaderOptions: false})}
              size="small"
            >
              <CustomHeaderOptions
                customColumns={this.state.customColumnNames}
                closeDrawer={() => this.setState({customHeaderOptions: false})}
                user={this.props.currentUser}
                token={this.props.token}
                csv_type="invoice_receivable_csv"
                updateColumnCount={(count) => this.updateColumnCount(count)}
              />
            </SideDrawer>
          </DialogContent>
          <DialogActions>
            <Button
              type='button'
              onClick={() => this.setState({csvPopup: false})}
              variant='outlined'>
              Cancel
            </Button>
            <Button type='button' onClick={() => this.fetchCSVData()} color='primary' variant='outlined'>
              Download
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.applyFilters && (
          <SideDrawer isOpen={this.state.openSideDrawer} title='Filters' size='big' onClose={() => this.handleFilters(false)} app='filters'>
            <InvoiceFilters
              handleFilterState={this.handleFilterState}
              filters={this.state.filters}
              receivableInvoice={true}
            />
          </SideDrawer>
        )}
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    breadcrumbs: state.main.breadcrumbs,
    currentUser: state.main.user.user,
  };
};

const mapDispatchToProps = dispatch => ({
  setBreadcrumbs: breadcrumbs => dispatch(setBreadcrumbs(breadcrumbs)),
  setHeaderText: text => dispatch(setHeaderText(text)),
  isLoading: (waitForComponent) => dispatch(isLoading(waitForComponent)),
  forceStopLoader: () => dispatch(forceStopLoader()),
  setDownloadBar: (message, isOpen, onClose) => dispatch(setDownloadBar(message, isOpen, onClose)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceReceivable);
