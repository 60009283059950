import React, { Component } from 'react';
import alertifyjs from 'alertifyjs';
import { connect } from 'react-redux';
import { Select, MenuItem, FormControl, InputLabel, Autocomplete, TextField, Button } from '@mui/material';
import { forEach, isEmpty, some, startCase, map, flatten, filter, find, get, compact, includes } from 'lodash';
import APIService from '../../services/APIService';
import { getSeasons, cartesian } from '../../common/utils';
import { required } from '../../common/validators';
import CommodityMultiSelect from '../common/autocomplete/CommodityMultiSelect'
import CommonMultiSelect from '../common/autocomplete/CommonMultiSelect';
import AddButton from '../common/AddButton';

const TRANSACTION_TYPES = [
  'purchase_contract',
  'sale_contract',
  'warehouse',
  'deductions',
  'freight_invoice_payable',
  'freight_invoice_receivable'
]

const SITES_CRITERIA_TRANSACTION_TYPES = ['warehouse', 'purchase_contract', 'sale_contract']

const SEASONS = getSeasons()
const CONTRACT_ITEM_TYPES = [
  {id: 'movements', label: 'Freight Movements'},
  {id: 'title_transfers', label: 'Title Transfers'},
  {id: 'carry', label: 'Carry Charges'},
  {id: 'custom', label: 'Custom Items'},
  {id: 'blending_fees', label: 'Blending Fees'},
  {id: 'chemical_applications', label: 'Applications'},
]
const DEDUCTION_ITEM_TYPES = [
  {id: 'epr', label: 'EPR'},
  {id: 'levy', label: 'Grain Levy'},
]
const WAREHOUSE_ITEM_TYPES = [
  {id: 'inload_fees', label: 'Inload Fees'},
  {id: 'outload_fees', label: 'Outload Fees'},
  {id: 'storage_fees', label: 'Storage Fees'},
  {id: 'transfer_fees', label: 'Transfer Fees'},
  {id: 'stock_swap', label: 'Stock Swap'},
  {id: 'regrade_reseason', label: 'Regrade Reseason'},
  {id: 'custom', label: 'Custom Items'},
]
const FREIGHT_INVOICE_ITEM_TYPES = [
  {id: 'movements', label: 'Freight Movements'},
  {id: 'custom', label: 'Custom Items'}
]

const TRANSACTION_ITEM_TYPE_MAPPING = {
  'purchase_contract': CONTRACT_ITEM_TYPES,
  'sale_contract': CONTRACT_ITEM_TYPES,
  'warehouse': WAREHOUSE_ITEM_TYPES,
  'deductions': DEDUCTION_ITEM_TYPES,
  'freight_invoice_payable': FREIGHT_INVOICE_ITEM_TYPES,
  'freight_invoice_receivable': FREIGHT_INVOICE_ITEM_TYPES,
}


class XeroMappingForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSettingUp: true,
      selected: props.selected,
      commodityIds: [],
      gradeIds: [],
      seasons: [],
      itemTypes: [],
      companySites: [],
      siteIds: [],
      allTrackingCategories: [],
      trackingCategoryMapping: {},
      selectedTrackingCategories: [],
      fields: {
        transactionType: {
          value: props.transactionType || '',
          validators: [required()],
          errors: [],
        },
        commodityId: {
          value: '',
          validators: [],
          errors: [],
        },
        gradeId: {
          value: '',
          validators: [],
          errors: [],
        },
        season: {
          value: '',
          validators: [],
          errors: [],
        },
        itemType: {
          value: '',
          validators: [],
          errors: [],
        },
        xeroAccount: {
          value: '',
          validators: [required()],
          errors: [],
        },
        xeroItemCode: {
          value: '',
          validators: [],
          errors: [],
        },
      }
    }
  }

  componentDidMount() {
    this.setUp()
    if (includes(SITES_CRITERIA_TRANSACTION_TYPES, this.props.transactionType))
      this.fetchSites();
  }

  setUp() {
    if(this.state.selected) {
      this.setForEdit()
    } else {
      if (get(this.props, 'company.hasTrackingCategories')) {
        if (!isEmpty(get(this.props, 'allTrackingCategories')))
          this.setState({allTrackingCategories: this.props.allTrackingCategories, isSettingUp: false}, () => this.createTrackingCategoryMapping());
        else
          this.fetchTrackingCategories();
      } else
        this.setState({isSettingUp: false})
    }
  }

  fetchSites() {
    APIService.companies(this.props.company.id)
      .appendToUrl('company_sites/minimal')
      .get()
      .then(resp => this.setState({companySites: resp}))
  }

  setAllFieldErrors = () => {
    const newState = {...this.state}
    forEach(newState.fields, (state, field) => {
      newState.fields[field].errors = []
      state.validators.forEach(validator => {
        if(validator.isInvalid(state.value))
          newState.fields[field].errors.push(validator.message)
      })
    })
    this.setState(newState)
  }

  hasAtleastOneAttribute = () => some(
    ['commodityIds', 'gradeIds', 'seasons', 'itemTypes'].map(field => !isEmpty(this.state[field]))
  )

  isValid = () => Boolean(this.hasAtleastOneAttribute() && this.state.fields.transactionType.value && this.state.fields.xeroAccount.value)

  getPayload = () => {
    const NONE = '__NONE__'
    const { commodityIds, gradeIds, seasons, siteIds, itemTypes, fields } = this.state
    const formatList = list =>  isEmpty(list) ? [NONE] : list
    const combos = cartesian(formatList(commodityIds), formatList(gradeIds), formatList(seasons), formatList(itemTypes), formatList(siteIds))
    const toValue = value => value === NONE ? undefined : value
    return combos.map(combo => ({
      commodityId: toValue(combo[0]),
      gradeId: toValue(combo[1]),
      season: toValue(combo[2]),
      itemType: toValue(combo[3]),
      siteId: toValue(combo[4]),
      xeroAccount: fields.xeroAccount.value,
      xeroItemCode: fields.xeroItemCode.value,
      transactionType: fields.transactionType.value,
    }))
  }

  onSubmit = () => {
    this.setAllFieldErrors()

    if(!this.hasAtleastOneAttribute())
      alertifyjs.error('One of commodity, grade, season or item must be selected.', 5)
    else if(this.isValid()){
      const service = APIService.companies(this.props.company.id).appendToUrl('xero/mappings/')
      const payload = this.getPayload()
      if(this.state.selected)
        payload[0].id = this.state.selected.id
      let data = {xeroMappings: payload};
      if (!isEmpty(this.state.selectedTrackingCategories))
        data['trackingCategories'] = this.state.selectedTrackingCategories;
      service.post(data).then(response => {
        if(some(response, r => get(r, 'errors._All_')))
          alertifyjs.error('Some of the combination already exists. Create rest.', 3)

        this.props.onSuccess()
      })
    }
  }

  setForEdit = () => {
    const newState = {...this.state};
    ['commodityId', 'gradeId', 'season', 'itemType', 'transactionType', 'xeroAccount', 'xeroItemCode'].forEach(field => {
      newState.fields[field].value = newState.selected[field] || ''
    });
    newState.commodityIds = compact([newState.selected.commodityId])
    newState.gradeIds = compact([newState.selected.gradeId])
    newState.seasons = compact([newState.selected.season])
    newState.siteIds = compact([newState.selected.siteId])
    newState.itemTypes = compact([newState.selected.itemType])
    let selectedTrackingCategories = newState.selected.trackingCategories || [];
    let fetchingTrackingCategories = false;
    if (!isEmpty(selectedTrackingCategories)) {
      if (!get(this.props, 'allTrackingCategories')) {
        this.fetchTrackingCategories();
        fetchingTrackingCategories = true;
      }
      else
        this.createTrackingCategoryMapping(newState);
      newState.selectedTrackingCategories = selectedTrackingCategories;
    }
    if (!fetchingTrackingCategories) 
      newState.isSettingUp = false
    this.setState(newState)
  }

  onFieldChange = (id, value) => {
    const newState = {...this.state}
    newState.fields[id].value = value || ''
    this.setState(newState, () => {
      if (includes(SITES_CRITERIA_TRANSACTION_TYPES, this.state.fields.transactionType.value) && isEmpty(this.state.companySites))
        this.fetchSites();
    })
  }

  onCommodityChange = (id, selectedCommodities) => {
    const ids = map(selectedCommodities, 'id')
    this.setState({commodityIds: ids})
  }

  onGradeChange = (id, grades) => {
    const ids = map(grades, 'id')
    this.setState({gradeIds: ids})
  }

  onItemTypeChange = (id, items) => {
    const ids = map(items, 'id')
    this.setState({itemTypes: ids})
  }

  onSeasonChange = (event, items) => this.setState({seasons: items})

  getGrades = () => flatten(map(filter(this.props.commodities, commodity => this.state.commodityIds.includes(commodity.id)), 'grades'))

  fetchTrackingCategories() {
    APIService
      .companies(this.props.company.id)
      .appendToUrl(`xero/tracking-categories/`)
      .get()
      .then(data => {
        if (isEmpty(data)) {
          this.setState({isSettingUp: false});
          alertifyjs.error('No tracking categories found. Please create tracking categories in Xero.', 5);
        }
        else
          this.setState({allTrackingCategories: data, isSettingUp: false}, () => this.createTrackingCategoryMapping())
      })
  }

  handleTrackingCategoriesClick() {
    this.fetchTrackingCategories();
  }

  createTrackingCategoryMapping(newState=null) {
    const allTrackingCategories = isEmpty(this.state.allTrackingCategories) ? get(this.props, 'allTrackingCategories'): this.state.allTrackingCategories;
    let trackingCategoryMapping = {}
    allTrackingCategories.forEach(trackingCategory => {
      trackingCategoryMapping[trackingCategory.trackingCategoryId] = trackingCategory.name;
    })
    if (newState)
      newState.trackingCategoryMapping = trackingCategoryMapping;
    else
      this.setState({trackingCategoryMapping: trackingCategoryMapping});
  }

  onTrackingCategoryChange(item, currentTransactionTrackingCategoryId) {
    const newState = {...this.state};
    let selectedTrackingCategories = this.state.selectedTrackingCategories;
    selectedTrackingCategories = selectedTrackingCategories.filter(trackingCategory => trackingCategory.xeroTrackingCategoryId !== currentTransactionTrackingCategoryId);
    if (item) {
      selectedTrackingCategories.push({
        xeroTrackingCategoryId: currentTransactionTrackingCategoryId,
        name: this.state.trackingCategoryMapping[currentTransactionTrackingCategoryId],
        xeroOptionId: item.id,
        optionName: item.name
      });
    }
    newState.selectedTrackingCategories = selectedTrackingCategories;
    this.setState(newState);
  }

  onSiteChange = (id, selectedItems) => {
    const ids = map(selectedItems, 'id')
    this.setState({siteIds: ids});
  };

  render() {
    const { fields, isSettingUp, selected, companySites } = this.state
    const { commodities, xeroAccounts, onClose, xeroItemCodes } = this.props
    let allTrackingCategories = isEmpty(this.state.allTrackingCategories) ? get(this.props, 'allTrackingCategories'): this.state.allTrackingCategories;
    const allGrades = this.getGrades()
    const allItemTypes = get(TRANSACTION_ITEM_TYPE_MAPPING, fields.transactionType.value);
    const selectedXeroAccount = find(xeroAccounts, {code: this.state.fields.xeroAccount.value})
    const selectedXeroItemCode = find(xeroItemCodes, {code: this.state.fields.xeroItemCode.value});
    return (
      <div className='cardForm cardForm--drawer' style={{marginTop: '20px'}}>
        {
          !isSettingUp &&
            <div className="cardForm-content row">
              <div className='col-sm-12'>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Transaction Type</InputLabel>
                  <Select
                    label='Transaction Type'
                    required
                    id="transactionType"
                    value={fields.transactionType.value}
                    onChange={event => this.onFieldChange('transactionType', event.target.value)}
                  >
                    {
                      TRANSACTION_TYPES.map(transactionType => (
                        <MenuItem value={transactionType} key={transactionType}>
                          {startCase(transactionType)}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </div>
              <div className='col-sm-12'>
                <CommodityMultiSelect
                  onChange={this.onCommodityChange}
                  commodities={commodities}
                  selectedCommodities={this.state.commodityIds}
                  label="Commodities"
                  limitTags={2}
                />
              </div>
              <div className='col-sm-12'>
                <CommonMultiSelect
                  id='gradeIds'
                  items={allGrades}
                  onChange={this.onGradeChange}
                  selectedItems={this.state.gradeIds}
                  label="Grades"
                  limitTags={2}
                  displayField='name'
                />
              </div>
              <div className='col-sm-12' style={{marginTop: '15px'}}>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  id='seasons'
                  options={SEASONS}
                  getOptionLabel={option => option}
                  renderInput={params => <TextField label='Seasons' {...params} variant='outlined' fullWidth />}
                  value={this.state.seasons}
                  onChange={this.onSeasonChange}
                  limitTags={2}
                />
              </div>
              {includes(SITES_CRITERIA_TRANSACTION_TYPES, fields.transactionType.value) &&
              <div className="col-sm-12">
                <CommonMultiSelect
                  id="siteIds"
                  items={filter(companySites, site => site?.isActive)}
                  selectedItems={this.state.siteIds}
                  displayField='name'
                  onChange={this.onSiteChange}
                  placeholder="Select Site(s)..."
                  label='Site'
                />
              </div>
              }
              <div className='col-sm-12'>
                <CommonMultiSelect
                  id='itemTypes'
                  items={allItemTypes}
                  selectedItems={this.state.itemTypes}
                  onChange={this.onItemTypeChange}
                  label='Item Types'
                  displayField='label'
                  limitTags={2}
                />
              </div>
              <div className='col-sm-12' style={{marginTop: '15px'}}>
                <Autocomplete
                  id='xeroAccount'
                  options={xeroAccounts}
                  getOptionLabel={option => option?.name || ''}
                  renderInput={params => <TextField label='Xero Account' {...params} variant='outlined' fullWidth helperText={fields.xeroAccount.errors[0]} error={Boolean(fields.xeroAccount.errors[0])} />}
                  value={selectedXeroAccount || ''}
                  onChange={(event, item) => this.onFieldChange('xeroAccount', get(item, 'code') || '')}
                />
              </div>
              <div className='col-sm-12' style={{marginTop: '15px'}}>
                <Autocomplete
                  id='xeroItemCode'
                  options={xeroItemCodes}
                  getOptionLabel={option => option?.name || ''}
                  renderInput={params => <TextField label='Xero Item Code' {...params} variant='outlined' fullWidth />}
                  value={selectedXeroItemCode || ''}
                  onChange={(event, item) => this.onFieldChange('xeroItemCode', get(item, 'code') || '')}
                />
              </div>
              {
                isEmpty(allTrackingCategories) ?
                <div className='col-sm-12' style={{marginTop: '15px'}}>
                  <AddButton
                    label='Add Tracking Categories'
                    style={{float: "left"}}
                    onClick={() => this.handleTrackingCategoriesClick()}
                  />
                </div> :
                <div className='col-sm-12' style={{marginTop: '15px'}}>
                  {
                    map(allTrackingCategories, trackingCategory => {
                      const trackingCategoryName = get(trackingCategory, 'name');
                      const options = get(trackingCategory, 'options');
                      let selectedValue = this.state.selectedTrackingCategories.find(selectedTrackingCategory => selectedTrackingCategory.xeroTrackingCategoryId === trackingCategory.trackingCategoryId);
                      if (selectedValue)
                        selectedValue = {id: selectedValue.optionId, name: selectedValue.optionName, trackingCategoryId: selectedValue.trackingCategoryId}
                      return (
                        <div style={{marginBottom: '14px'}}>
                          <Autocomplete
                            id='xeroTrackingCategory'
                            options={options}
                            getOptionLabel={option => option?.name || ''}
                            renderInput={params => <TextField label={trackingCategoryName} {...params} variant='outlined' fullWidth />}
                            value={selectedValue || ''}
                            onChange={(event, item) => this.onTrackingCategoryChange(item, trackingCategory.trackingCategoryId)}
                          />
                        </div>
                      )
                    })
                  }
                </div>
              }
              <div className='col-sm-12' style={{marginTop: '15px'}}>
                <Button variant='contained' color='secondary' style={{marginRight: '20px'}} onClick={onClose}>
                  Cancel
                </Button>
                <Button variant='contained' color='primary' onClick={this.onSubmit}>
                  {
                    selected?.id ? 'Update' : 'Create'
                  }
                  </Button>
                </div>
            </div>
        }
      </div>
    )
  }
}

export default connect()(XeroMappingForm)
