import React, { useEffect } from 'react';
import CommonListingButton from '../common/CommonListingButton';
import CommonDatePicker from '../common/CommonDatePicker';
import { useState } from 'react';
import { Dialog, DialogContent, Button, DialogActions } from '@mui/material';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import CommonMultiSelect from '../common/autocomplete/CommonMultiSelect';
import { map, remove, uniqBy, filter, includes, isEmpty } from 'lodash';
import APIService from '../../services/APIService';
import { useDispatch, useSelector } from 'react-redux';
import { setDownloadBar } from '../../actions/main';
import { getCommodities } from '../../actions/api/commodities';
import { currentUser, getCurrentCountry } from "../../common/utils";
import moment from 'moment';

let StockSwapCSV = props => {
  const [allLocations, setAllLocations] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [allSeason, setSeason] = useState([]);
  const [allGrade, setGrade] = useState([]);
  const dispatch = useDispatch();

  const [combinedStates, setCombinedStates] = useState({
    'swapped_to_site_id__in': [],
    'swapped_from_site_id__in': [],
    'inload__ngr__company_id__in': [],
    'inload__grade__id__in': [],
    'inload__season__in': [],
    'inload__date_time__lte': '',
    'inload__date_time__gte': '',
    'inload__commodity_id__in': [],
    'inload__ngr_id__in': [],
  });
  let [allNgrs, setNgrs] = useState([]);

  useEffect(() => {
    if(props.isOpen) {
      getAllCompanies();
      APIService.empty().appendToUrl('common/seasons/').get().then(response => setSeason(map(response?.seasons, season => ({id: season, name: season}))));
      getAllGrade();
      getAllCommodities();
    }
  }, [props.isOpen]);

  useEffect(() =>{
    if (props.locations) {
      let { locations } = {...props};
      let copyLocations = locations.slice();
      remove(copyLocations, val => val.id ==='all');
      setAllLocations(copyLocations);
    }
  }, [combinedStates.swapped_to_site_id__in, allCompanies, props.locations]);

  const getAllCompanies = async () => {
    const user = currentUser();
    let companies = await APIService.companies().appendToUrl(`directory/names/?excludeGroups=true`).get(props.token);
    companies = uniqBy([...companies, { id: user.companyId, name: user.company.name }], 'id');
    setAllCompanies(companies);
  };

  const getAllGrade = async() => {
    const grades = await APIService.commodities().appendToUrl(`grades/all/`).get(props.token, null, {countryId: getCurrentCountry().id});
    setGrade(grades);
  };

  let getAllCommodities = () => dispatch(getCommodities());

  let fetchStockSwapReport = callback => {
    props.toggleDialog();
    if (callback) {
      callback();
    }
  };

  let handleCompanyChange = async (id, selectedItems) => {
    setCombinedStates({ ...combinedStates, 'inload__ngr__company_id__in': map(selectedItems, 'id'), 'inload__ngr_id__in': [] });
    if (!isEmpty(selectedItems)) {
      const companyQueryString = map(selectedItems, obj => `company_ids=${obj.id}`).join('&');
      const ngrs = await APIService.ngrs().appendToUrl(`?${companyQueryString}`).get();
      setNgrs(ngrs);
    }
    else setNgrs([]);
  };

  const getGrades = () => filter(allGrade, grade => includes(combinedStates.inload__commodity_id__in, grade.commodityId));

  let allStocks = useSelector(state => state.master.commodities.items);

  const handleStockSwapCSV = () => {
    let combinedStatesCopy = Object.assign({}, combinedStates);
    if(combinedStatesCopy.inload__date_time__lte){
      let lte_time = `${combinedStatesCopy.inload__date_time__lte} 23:59:59`;
      lte_time = moment.tz(lte_time, moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm:ss');
      combinedStatesCopy.inload__date_time__lte = lte_time;
    }
    if (combinedStatesCopy.inload__date_time__gte) {
      let gte_time = `${combinedStatesCopy.inload__date_time__gte} 00:00:00`;
      gte_time = moment.tz(gte_time, moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm:ss');
      combinedStatesCopy.inload__date_time__gte = gte_time;
    }

    dispatch(setDownloadBar('Your Stock Swap Report CSV is being prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true));

    const service = APIService.farms().appendToUrl(`web/stock-swap-csv/`);
    service
      .put(combinedStatesCopy, props.token);

    props.toggleDialog();
  };

  return (
    <span style={{display: 'inline-block'}}>
      <CommonListingButton
        showMenus={true}
        optionMapper={[{ name: 'Stock Swap CSV', fx: callback => fetchStockSwapReport(callback) }]}
        title='Download Contents of the table in a CSV'
        name='CSV'
        style={{}}
        variant="outlined"
        size="small"
      />
      <Dialog open={props.isOpen} onClose={props.toggleDialog} aria-labelledby='form-dialog-title' fullWidth>
        <DialogTitleWithCloseIcon
          onClose={props.toggleDialog}
          closeButtonStyle={{ marginTop: '0px' }}
          id='form-dialog-title'>
          Stock Swap Report
        </DialogTitleWithCloseIcon>
        <DialogContent style={{ marginTop: '15px' }}>
          <div className="col-sm-12 padding-reset">
            <CommonMultiSelect
              id="inload__ngr__company_id__in"
              items={allCompanies}
              selectedItems={combinedStates.inload__ngr__company_id__in}
              displayField="name"
              onChange={handleCompanyChange}
              placeholder="Select Owner(s)..."
              label="Stock Owners"
              selectAll
              clearAll
            />
          </div>
          <div className="col-sm-12 padding-reset">
            <CommonMultiSelect
              id="inload__ngr_id__in"
              items={allNgrs}
              selectedItems={combinedStates.inload__ngr_id__in}
              displayField="ngrNumber"
              onChange={(id, selectedItems) =>
                setCombinedStates({ ...combinedStates, 'inload__ngr_id__in': map(selectedItems, 'id') })}
              placeholder="Select NGR..."
              label="Ngrs"
              selectAll
              clearAll
            />
          </div>
          <div>
            <div className="col-sm-12 padding-reset">
              <CommonMultiSelect
                id="inload__commodity_id__in"
                items={allStocks}
                selectedItems={combinedStates.inload__commodity_id__in}
                displayField="displayName"
                onChange={(id, selectedItems) =>
                  setCombinedStates({ ...combinedStates, 'inload__commodity_id__in': map(selectedItems, 'id') })}
                placeholder="Select Commodity..."
                label="Commodity"
                selectAll
                clearAll
              />
            </div>
            <div className="col-sm-12 padding-reset">
              <CommonMultiSelect
                id="inload__grade__id__in"
                items={getGrades()}
                selectedItems={combinedStates.inload__grade__id__in}
                displayField="name"
                onChange={(id, selectedItems) => setCombinedStates({ ...combinedStates, 'inload__grade__id__in': map(selectedItems, 'id') })}
                placeholder="Select Grade(s)..."
                label="Grade(s)"
                selectAll
                clearAll
              />
            </div>
            <div className="col-sm-12 padding-reset">
              <CommonMultiSelect
                id="inload__season__in"
                items={allSeason}
                selectedItems={combinedStates.inload__season__in}
                displayField="name"
                onChange={(id, selectedItems) => setCombinedStates({...combinedStates, 'inload__season__in': map(selectedItems, 'id')})}
                placeholder="Select Season..."
                label="Season"
                selectAll
                clearAll
              />
            </div>
          </div>
          <div className="col-sm-12 padding-reset">
            <CommonMultiSelect
              id="farm_id"
              items={allLocations}
              selectedItems={combinedStates.swapped_from_site_id__in}
              displayField="name"
              onChange={(id, selectedItems) => setCombinedStates({...combinedStates, 'swapped_from_site_id__in': map(selectedItems, 'id')})}
              placeholder="Swapped From"
              label="Swapped From"
              selectAll
              clearAll
            />
          </div>
          <div className="col-sm-12 padding-reset">
            <CommonMultiSelect
              id="farm_id"
              items={allLocations}
              selectedItems={combinedStates.swapped_to_site_id__in}
              displayField="name"
              onChange={(id, selectedItems) => setCombinedStates({...combinedStates, 'swapped_to_site_id__in': map(selectedItems, 'id')})}
              placeholder="Swapped To"
              label="Swapped To"
              selectAll
              clearAll
            />
          </div>
          <div className='col-sm-6 no-left-padding'>
            <CommonDatePicker
              id='inload__date_time__gte'
              floatingLabelText='Start Date'
              value={combinedStates.inload__date_time__gte}
              onChange={(val, id) => setCombinedStates({ ...combinedStates, [id]: val })}
            />
          </div>
          <div className='col-sm-6 no-right-padding'>
            <CommonDatePicker
              id='inload__date_time__lte'
              floatingLabelText='End Date'
              value={combinedStates.inload__date_time__lte}
              onChange={(val, id) => setCombinedStates({ ...combinedStates, [id]: val })}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button type='button' onClick={props.toggleDialog} variant='outlined'>
            Cancel
          </Button>
          <Button type='button' onClick={handleStockSwapCSV} color='primary' variant='outlined'>
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
};

export default StockSwapCSV;
