import React from 'react';
import alertifyjs from 'alertifyjs';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import uniq from 'lodash/uniq'
import { copyToClipboard, isSuperuser, isCurrentUserBelongsToCompany, isCompanyAdminOrObserver } from '../../common/utils'
import APIService from '../../services/APIService';
import Xero from './Xero';


const XeroSetupInstructions = ({ company }) => {
  const [clientId, setClientId] = React.useState({value: company.xeroClientId || '', error: ''});
  const [clientSecret, setClientSecret] = React.useState({value: company.xeroClientSecret || '', error: ''});
  const [clientResponse, setClientResponse] = React.useState(false)
  const [isValidXeroConnection, setIsValidXeroConnection] = React.useState(undefined)
  const [isLoading, setIsLoading] = React.useState(true)
  const [activeSteps, setActiveSteps] = React.useState([])
  const MORPHED_SECRET = '*'.repeat(48)

  const setErrors = () => {
    if(clientId.value)
      setClientId({...clientId, error: ''})
    else
      setClientId({...clientId, error: 'This field cannot be blank'})

    if(clientSecret.value)
      setClientSecret({...clientSecret, error: ''})
    else
      setClientSecret({...clientSecret, error: 'This field cannot be blank'})
  }


  const anchorStyles = {fontSize: '1rem', color: '#337ab7', fontWeight: 'normal'}

  const onClientSecretUpdate = () => {
    setErrors()
    if(!clientSecret.error && !clientId.error) {
      if (company.xeroClientId === clientId.value && (company.xeroClientSecret === clientSecret.value || clientSecret.value === MORPHED_SECRET))
        alertifyjs.warning('Same value as before, nothing to update.', 3)
      else if(company.id) {
        if(company.xeroClientId && company.xeroClientSecret)
          alertifyjs.confirm('Update Xero Secrets', 'Are you sure you want to update the Xero Secrets?', updateSecrets, () => {})
        else
          updateSecrets()
      }
    }
  }

  const updateSecrets = () => {
    APIService.companies(company.id).put({xero_client_id: clientId.value, xero_client_secret: clientSecret.value}).then(res => {
      setClientResponse(res)
    })
  }

  const hadSecrets = Boolean(company?.xeroClientId && company?.xeroClientSecret)

  const canLinkAccount = () => {
    if(!clientId.value && !clientSecret.value && company.xeroClientId && company.xeroClientSecret)
      return true
    if(clientId.value === company.xeroClientId && clientSecret.value === company.xeroClientSecret)
      return true
    if(clientId.value && clientSecret.value && clientResponse?.xeroClientId === clientId.value)
      return true
    return false
  }

  const verifyXeroConnection = () => {
    if(company.id) {
      setIsLoading(true)
      APIService.companies(company.id).appendToUrl('xero/connections/').get().then(res => {
        setIsValidXeroConnection(res?.valid)
        setIsLoading(false)
      })
    }
  }

  const onUnlink = () => {
    alertifyjs.confirm('Unlink Xero Account', 'This action will remove Xero Account Connection with AgriChain.<br/><br/><b>Invoices will no longer sync to Xero unless the Xero Connection is re-linked.</b> <br/><br/>Are you sure you want to proceed?', () => {
      setIsLoading(true)
      APIService.companies(company.id).appendToUrl('xero/connections/').delete().then(res => {
        if(res?.detail) {
          setIsLoading(false)
          alertifyjs.alert('Permission Denied', res?.detail, () => {})
        }
        else {
          alertifyjs.success('Successfully unlinked Xero Account', 1, () => window.location.reload())
        }
      })
    }, () => {})
  }

  const markStepActive = step => setActiveSteps(uniq([...activeSteps, step]))

  React.useEffect(() => {
    verifyXeroConnection()
  }, [company.id])


  return (
    <Box sx={{ p: 2 }}>
      <Stepper orientation="vertical">
        <Step active={!hadSecrets || activeSteps.includes(1)} completed={hadSecrets} onClick={() => markStepActive(1)} sx={{cursor: 'pointer'}}>
          <StepLabel sx={{cursor: 'pointer !important'}}>
            Connect AgriChain with Xero
          </StepLabel>
          <StepContent>
            <Typography>
              <span>Connect AgriChain with Xero in three simple steps. Our </span>
              <a style={anchorStyles} href='https://www.youtube.com/watch?v=LCh45euvaKE' target='_blank' rel="noreferrer">How to Setup Xero Integration</a>
              <span> video explains the steps in details, please watch and follow.</span>
            </Typography>
          </StepContent>
        </Step>
        <Step active={!hadSecrets || activeSteps.includes(2)} completed={hadSecrets} onClick={() => markStepActive(2)} sx={{cursor: 'pointer'}}>
          <StepLabel sx={{cursor: 'pointer !important'}}>
            Register AgriChain App in Xero
          </StepLabel>
          <StepContent>
            <div className='col-xs-12 padding-reset'>
              Open <a style={anchorStyles} href='https://developer.xero.com/app/manage/' target='_blank' rel="noreferrer">{'Xero App Registration (https://developer.xero.com/app/manage/)'}</a>
            </div>
            <div className='col-xs-12 padding-reset' style={{marginTop: '16px'}}>
              Below are some URLs referenced in the video for you to use:
              <div style={{marginTop: '8px'}}>
                <span className='no-select 'style={{fontSize: '14px', color: 'gray', marginRight: '8px'}}>AgriChain Application URL:</span>
                <span>{'https://platform.agrichain.com'}</span>
                <Button size='small' variant='text' color='primary' sx={{textTransform: 'none'}} onClick={() => copyToClipboard('https://platform.agrichain.com', 'Copied in your clipboard', 2)}>
                  copy
                </Button>
              </div>
              <div>
                <span className='no-select 'style={{fontSize: '14px', color: 'gray', marginRight: '8px'}}>Redirect URI:</span>
                <span>{'https://platform.agrichain.com/xero/callback'}</span>
                <Button size='small' variant='text' color='primary' sx={{textTransform: 'none'}} onClick={() => copyToClipboard('https://platform.agrichain.com/xero/callback', 'Copied in your clipboard', 2)}>
                  copy
                </Button>
              </div>
            </div>
          </StepContent>
        </Step>
        <Step active={!hadSecrets || activeSteps.includes(3)} completed={canLinkAccount()} onClick={() => markStepActive(3)} sx={{cursor: 'pointer'}}>
          <StepLabel sx={{cursor: 'pointer !important'}}>
            Enter AgriChain App Secrets
          </StepLabel>
          <StepContent>
            <div className='col-xs-12 padding-reset'>
              Please enter Client credentials copied from Xero:
            </div>
            <div className='col-xs-12 padding-reset' style={{marginTop: '16px'}}>
              <TextField
                size='small'
                label='Client ID'
                value={clientId.value}
                onChange={event => setClientId({...clientId, value: event.target.value})}
                required
                style={{width: '50%'}}
                error={Boolean(clientId.error)}
                helperText={clientId.error}
                disabled={isValidXeroConnection}
              />
            </div>
            <div className='col-xs-12 padding-reset' style={{marginTop: '16px'}}>
              <TextField
                size='small'
                label='Client Secret'
                value={clientSecret.value === company.xeroClientSecret ? MORPHED_SECRET : clientSecret.value}
                onChange={event => setClientSecret({...clientSecret, value: event.target.value})}
                required
                style={{width: '50%'}}
                error={Boolean(clientSecret.error)}
                helperText={clientSecret.error}
                disabled={isValidXeroConnection}
              />
            </div>
            <div className='col-xs-12 padding-reset' style={{marginTop: '16px'}}>
              <Button variant="contained" onClick={onClientSecretUpdate} disabled={isValidXeroConnection || isLoading}>
                Update Secrets
              </Button>
            </div>
          </StepContent>
        </Step>
        <Step active={!isLoading && Boolean(clientId.value && clientSecret.value && (clientResponse?.xeroClientId === clientId.value || (!clientResponse?.id && hadSecrets && company.xeroClientId === clientId.value)))} completed={isValidXeroConnection}>
          <StepLabel
            optional={
                <Typography variant="caption">Final step</Typography>
            }
          >
            Authorize AgriChain
          </StepLabel>
          <StepContent>
            <div className='col-xs-12 padding-reset'>
              This will take you to your Xero Account and it will ask to authorize AgriChain.
            </div>
            <div className='col-xs-12 padding-reset' style={{marginTop: '16px'}}>
              {
                isValidXeroConnection ?
                  <React.Fragment>
                  <Button disabled variant='contained'>
                    Authorized and Connected
                  </Button>
                    {
                      (isSuperuser() || (isCompanyAdminOrObserver() && isCurrentUserBelongsToCompany(company.id))) &&
                        <Button sx={{marginLeft: '16px'}} variant='outlined' color='error' onClick={onUnlink}>
                          Unlink Account
                        </Button>
                    }
                    </React.Fragment> :
                  <Xero
                    label={company.isXeroAccountLinked && !isValidXeroConnection ? 'Reconnect Xero' : 'Connect Xero'}
                    disabled={isLoading}
                    clientId={clientId.value}
                    clientSecret={clientSecret.value}
                    companyId={company.id}
                  />
              }
            </div>
            {
              isValidXeroConnection && !company.xeroTenantId &&
                <div className='col-xs-12 padding-reset' style={{marginTop: '8px'}}>
                  <FormHelperText error>
                    Could not connect Xero Tenant with the company. Please reach out to AgriChain Support.
                  </FormHelperText>
                </div>
            }
          </StepContent>
        </Step>

      </Stepper>
    </Box>
  )
}

export default XeroSetupInstructions;
