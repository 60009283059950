import React from 'react';
import { connect } from 'react-redux';
import GenericTable from '../components/GenericTable';
import alertifyjs from 'alertifyjs';
import includes from 'lodash/includes';
import {
  canVoidTitleTransfer, titleTransfersResponse, getTitleTransfers, receiveTitleTransfer,
  getSelectedTitleTransfer,
  downloadTitleTransferPDF,
  setClickedTitleTransferOption,
  setSelectedTitleTransfer,
  showAddTitleTransferSideDrawer,
  updateDuplicateTitleTransferId,
  voidTitleTransferDialog,
} from '../actions/companies/contracts';
import APIService from '../services/APIService';
import { TITLE_TRANSFER_HEADERS, TITLE_TRANSFER_TABLE_COLUMN_LIMIT } from '../common/constants';
import { get, find, isEmpty, isArray } from 'lodash';
import { getCustomColumns } from '../actions/companies/index';
import TitleTransferActions from '../components/title-transfers/TitleTransferActions';
import { currentUserCompany, isCurrentUserBroker } from '../common/utils';

const TITLE_TRANSFER_OPTIONS = [
  { key: 'void', text: 'Void' },
  { key: 'duplicate', text: 'Duplicate'},
  { key: 'void_and_duplicate', text: 'Void and Duplicate'},
  { key: 'download_pdf', text: 'Download PDF' },
  { key: 'assign_to', text: 'Assign To' }
]

class TitleTransfersTable extends React.Component {
  componentDidMount() {
    this.props.getCustomColumns('title_transfer_table');
  }

  getSearchSortUrl = (pageSize, page, searchText, orderBy, order, _, includeVoid) => {
    const titleTransferService = APIService.contracts();
    titleTransferService.appendToUrl(`title-transfers/search/?page_size=${pageSize}`);
    if (page)
      titleTransferService.appendToUrl(`&page=${page}`);
    if (searchText)
      titleTransferService.appendToUrl(`&search=${searchText}`);
    if (orderBy)
      titleTransferService.appendToUrl(`&order_by=${orderBy}&order=${order}`);
    if (includeVoid)
      titleTransferService.appendToUrl(`&include_void=${includeVoid}`);
    if(this.props.contractId)
      titleTransferService.appendToUrl(`&contract_id=${this.props.contractId}`)
    return titleTransferService.URL;
  }

  render() {
    return (
      <div>
        <GenericTable {...this.props} getSearchSortUrl={this.getSearchSortUrl} />
        <TitleTransferActions {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const clickedOption = state.companies.contracts.clickedTitleTransferOption;
  let customColumns = [...TITLE_TRANSFER_HEADERS];
  const tableColumnsOrder = get(state.companies, 'companies.tableColumns');
  if (tableColumnsOrder && !isEmpty(tableColumnsOrder)) {
    customColumns = [];
    tableColumnsOrder.forEach(val => {
      const obj = find(TITLE_TRANSFER_HEADERS, {key: val});
      if (obj) {
        customColumns.push(obj);
      }
    });
  }
  if (customColumns.length > TITLE_TRANSFER_TABLE_COLUMN_LIMIT) {
    customColumns = customColumns.splice(0, TITLE_TRANSFER_TABLE_COLUMN_LIMIT);
  }
  let items = state.companies.contracts.titleTransfers;
  if (items && isArray(items)) {
    items.map(item => {
      item.sustainable = item.sustainable ? 'True': '';
    })
  }
  return {
    columns: customColumns,
    items: state.companies.contracts.titleTransfers,
    scrollToTopOnUpdate: false,
    handleDefaultCellClick: false,
    optionsItems: ownProps.isCashBoard ? null : TITLE_TRANSFER_OPTIONS,
    clearSearch: titleTransfersResponse,
    clickedOption: clickedOption,
    paginationData: state.companies.contracts.titleTransferPaginatedData,
    globalSearch: true,
    selectedTitleTransfer: state.companies.contracts.selectedTitleTransfer,
    voidFilter: true,
    displayIDColumn: 'identifier'
  };
};

const canTitleTransferBeDuplicated = (item) => {
  const userCompanyId = currentUserCompany()?.id;
  const isUserBroker = isCurrentUserBroker();

  const isSiteAndSellerNonSubscriber =
    !get(item, 'sellerCompanyTransactionParticipation') &&
    !get(item, 'buyerCompanyTransactionParticipation');

  if (!isUserBroker) {
    return Promise.resolve(
      includes([item.sellerCompanyId, item.transferSiteCompanyId], userCompanyId) ||
      (
        includes(item.sellerNgrPrimaryShareholderCompanyIds, userCompanyId) &&
        includes([item.buyerCompanyId], userCompanyId)
      )
    ).then(isDuplicationAllowed => {
      if (isDuplicationAllowed || isSiteAndSellerNonSubscriber)
        return { canDuplicate: true };

      let errorMessage = "Some error in duplicating this title transfer. Please try again later.";
      if(!isSiteAndSellerNonSubscriber)
        errorMessage = 'You cannot create this Title Transfer because you are not the seller or the site employee.'

      if(!isSiteAndSellerNonSubscriber)
        return {
          canDuplicate: false,
          message: errorMessage
        };
    });
  }

  return APIService.companies()
    .appendToUrl('managed/companies/')
    .get()
    .then(managedCompanies => !!(
      find(managedCompanies, { id: item.sellerCompanyId }) ||
      find(managedCompanies, { id: item.transferSiteCompanyId }) ||
      get(item, 'contractSellerRepresentedBy') == userCompanyId
    ))
    .catch(error => {
      console.error('Error fetching managed companies:', error);
      return false;
    })
    .then(isDuplicationAllowed => {
      if (isDuplicationAllowed || isSiteAndSellerNonSubscriber)
        return { canDuplicate: true };

      let errorMessage = "Some error in duplicating this title transfer. Please try again later.";
      if(!isSiteAndSellerNonSubscriber)
        errorMessage = 'You cannot create this Title Transfer because you are not the seller or the site employee.'

      if(!isSiteAndSellerNonSubscriber)
        return {
          canDuplicate: false,
          message: errorMessage
        };
    });
};

const duplicateTitleTransfer = (item, id) => dispatch => {
  canTitleTransferBeDuplicated(item)
    .then(result => {
      if (result.canDuplicate) {
        dispatch(updateDuplicateTitleTransferId(id))
        dispatch(showAddTitleTransferSideDrawer(true))
      } else if (result.message)
        alertifyjs.alert('Title Transfer', `<div><p>${result.message}</p></div>`);
    })
}

const voidAndDuplicateTitleTransfer = item => dispatch => {
  canTitleTransferBeDuplicated(item)
    .then(result => {
      if (result.canDuplicate) {
        APIService.contracts().appendToUrl(`title-transfers/${item.id}/void/`)
          .get().then(res => {
            let reasons = get(res, 'reasons');
            if (!isEmpty(reasons)) {
              reasons = '<li>' + reasons.join('</li><li>');
              alertifyjs.alert(
                'Permission Denied',
                `<div><p>You cannot Void this Title Transfer because:</p><ul>${reasons}</ul></div>`,
                () => { }
              );
            }
            else if (isArray(reasons) && isEmpty(reasons)) {
              alertifyjs
                .confirm(
                  'Warning',
                  `This will void the current title transfer and allow you to create a new title transfer with similar details.`,
                  () => {
                    dispatch(voidTitleTransferDialog(true, item));
                    dispatch(updateDuplicateTitleTransferId(item.id))
                  },
                  () => { },
                )
                .set('labels', { ok: 'Proceed', cancel: 'Cancel' });
            }
          })
      }
      else if (result.message)
        alertifyjs.alert('Title Transfer', `<div><p>${result.message}</p></div>`);
    });
}

const mapDispatchToProps = dispatch => {
  return {
    handleOptionClick: (index, key, id, item) => {
      dispatch(setClickedTitleTransferOption(key));
      if (key === 'void')
        dispatch(canVoidTitleTransfer(item));
      else if(key == 'duplicate')
        dispatch(duplicateTitleTransfer(item, id))
      else if(key == 'void_and_duplicate')
        dispatch(voidAndDuplicateTitleTransfer(item))
      else if(key == 'download_pdf')
        dispatch(downloadTitleTransferPDF(item));
      else if (key == 'assign_to')
        dispatch(getSelectedTitleTransfer(item.id, setSelectedTitleTransfer, false, item.priceUnit, false))
    },
    handleDefaultCellClick: (item) => {
      dispatch(getSelectedTitleTransfer(item.id, receiveTitleTransfer, false, item.priceUnit));
    },
    navigateTo: (url) => {
      dispatch(getTitleTransfers('', true, url, true));
    },
    changePageSize: (url, pageSize) => {
      if (includes(url, '?')) {
        url = `${url}&page_size=${pageSize}`;
      } else {
        url = `${url}?page_size=${pageSize}`;
      }
      dispatch(getTitleTransfers('', false, url, true));
    },
    getCustomColumns: tableType => dispatch(getCustomColumns(tableType)),
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TitleTransfersTable);
