import React from 'react';
import { connect } from 'react-redux';
import { Button, Divider, Paper, Dialog, DialogActions, DialogContent, Tooltip, Checkbox, Table, TableHead, TableRow, TableCell, TableBody, FormControlLabel, Badge, Chip, Skeleton } from '@mui/material';
import ExpandIcon from '@mui/icons-material/Expand';
import CollapseIcon from '@mui/icons-material/UnfoldLess';
import {
  get, isEmpty, find, reject, set, map, filter,
  includes, remove, uniq, uniqBy, flattenDeep, has,
  isEqual, isUndefined, sum, forEach, compact, orderBy,
  intersection, without
} from 'lodash';
import APIService from '../../services/APIService';
import { currentUserCompany, getCountryFormats, attachCSVEventListener, defaultViewAction, getCountryLabel, generateIdentifier, getCountryCurrency } from '../../common/utils';
import { forceStopLoader, isLoading, setDownloadBar } from '../../actions/main';
import InvoicePayableTable from '../../containers/InvoicePayableTable';
import { FILTER_KEYS_TO_EXCLUDE, FREIGHT_RECEIVABLE_ITEM_COLUMNS, INVOICE_FILTER_KEYS_MAPPING, PREDEFINED_DATE_RANGE_FILTER_KEYS } from '../../common/constants';
import IconButton from '@mui/material/IconButton';
import Create from '@mui/icons-material/Create';
import moment from 'moment';
import CommonDatePicker from '../common/CommonDatePicker';
import alertifyjs from 'alertifyjs';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import EmailAutocomplete from '../common/autocomplete/EmailAutocomplete';
import CommonListingButton from '../common/CommonListingButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import SideDrawer from '../common/SideDrawer';
import CustomHeaderOptions from '../common/CustomHeaderOptions';
import InvoiceFilters from '../common/InvoiceFilters';
import { Virtuoso } from 'react-virtuoso';

const CUSTOM_HEADER_EDIT = {
  fontSize: '12px',
  textDecoration: 'underline',
  cursor: 'pointer',
  marginLeft: '10px'
};

const DEFAULT_FILTERS = {
  'payee__company_id__in': [],
  'contract__commodity__id__in': '',
  'payment_due_date_range': '',
  'payment_due_date__gte': '',
  'payment_due_date__lte': ''
}

class FreightInvoicePayable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPayeeCompanyIds: [],
      expanded: [],
      loadingItemsFor: [],
      invoiceItems: [],
      isFetchingInvoices: false,
      companyDirectory: [],
      invoiceItemsGroupedByPayee: [],
      isFetchingCompanyDirectory: false,
      selectedInvoiceItems: [],
      openDialog: false,
      selectedRecipients: undefined,
      recipientsDialogOpen: false,
      sellerEmployeeData: undefined,
      showRecipientsError: false,
      csvPopup: false,
      isFilteredCsv: false,
      customColumns: true,
      customColumnNames: {},
      customHeaderOptions: false,
      customColumnTitle: undefined,
      applyFilters: false,
      openSideDrawer: false,
      expandAllLoading: false,
      filters: {},
      bankProcessingDate: {
        value: undefined,
        error: ''
      },
    }

    this.handleGeneratePaymentRunClick = this.handleGeneratePaymentRunClick.bind(this);
    this.generatePaymentRun = this.generatePaymentRun.bind(this);
    this.handleBankProcessingDateChange = this.handleBankProcessingDateChange.bind(this);
  }

  componentDidMount() {
    this._attachCSVEventListener();
    this.fetchInvoices();
    APIService.profiles()
      .filters('freight_invoice_payable')
      .get()
      .then(res => {
        this.setState({
          filters: get(res, 'freight_invoice_payable', {}) || DEFAULT_FILTERS,
        });
      });
  }

  onDownloadResponse(message) {
    this.props.dispatch(setDownloadBar(message, true, this.onCloseDownloadResponse));
  }

  _attachCSVEventListener() {
    attachCSVEventListener('freight_payables-csv-ready', 'Freight Invoice Payables', this.onDownloadResponse);
  }

  fetchInvoices() {
    this.props.dispatch(isLoading('LoadingPayables'))
    if (!this.state.isFetchingInvoices) {
      this.setState({ isFetchingInvoices: true }, async () => {
        const company = currentUserCompany();
        APIService.invoices().appendToUrl(`freight-payable-items/?company_id=${company.id}&items=false`).get().then(response =>
          this.setState({
            invoiceItems: response,
            isFetchingInvoices: false,
            expanded: response.length == 1  ? [response[0].companyId]: []
          }, () => {
            if (isEmpty(this.state.companyDirectory))
              this.getCompanyDirectory()
            else
              this.setPayableItems();
          }));
      })
    }
  }

  generatePaymentRun() {
    if (isUndefined(this.state.bankProcessingDate.value)) {
      const newState = {...this.state};
      newState.bankProcessingDate.error = 'This field is required';
      this.setState(newState);
    }
    else {
      this.setState({openDialog: false}, () => {
        this.props.isLoading();
        let selectedInvoiceItems = [...this.state.selectedInvoiceItems];
        remove(selectedInvoiceItems, selectedInvoiceItem => selectedInvoiceItem.isChemicalItem);
        let invoiceItems = selectedInvoiceItems.reduce((obj, objectKey) => ({ ...obj, [objectKey.id]: {}}), {});
        let minPaymentDueObj = selectedInvoiceItems.sort((a, b) => new Date(a.paymentDue) - new Date(b.paymentDue));
        let identifier = generateIdentifier('paymentRun');
        const company = currentUserCompany();
        let data = {
          'invoiceItems': invoiceItems,
          'identifier' : identifier,
          'payer': {
            'companyId': get(company, 'id'),
            'role': 'payer'
          },
          'paymentDue': moment(get(minPaymentDueObj, '0.paymentDue'), 'DD/MM/YYYY').format('YYYY-MM-DD hh:mm:ss'),
          'bankProcessingDate': this.state.bankProcessingDate.value,
        };
        APIService.invoices()
          .appendToUrl(`payment-runs/?company_id=${company.id}`)
          .post(data)
          .then((res) => {
            if (res.errors) {
              if(res.errors[0].includes('recreated'))
                alertifyjs.error(res.errors[0] + ', reloading...', 2, () => window.location.reload());
              else
                alertifyjs.error(res.errors[0]);

            }
            else {
              alertifyjs.success("Payment Run created successfully");
              this.props.forceStopLoader();
              window.location = '#/invoices/payment-runs';
            }
          });
      });
    }
  }

  handleBankProcessingDateChange(value) {
    const newState = {...this.state};
    newState.bankProcessingDate.value = value;
    newState.bankProcessingDate.error = '';
    this.setState(newState);
  }

  toFormattedDate = date => {
    if (date) {
      const mDate = moment(date)
      return mDate.isValid() ? mDate.format(getCountryFormats().date) : date
    }
    return date
  }

  setPayableItems() {
    const { invoiceItems } = this.state
    if (!isEmpty(this.state.companyDirectory)) {
      map(invoiceItems, obj => {
        let payer = find(this.state.companyDirectory, { id: obj.companyId });
        obj.payerName = get(payer, 'name');
        obj.allSelected = false;
        if (obj.recipients && !isEmpty(obj.recipients)) {
          let recipientDisplayStr = "";
          map(obj.recipients, recipient => {
            if (isEmpty(recipientDisplayStr))
              recipientDisplayStr = recipientDisplayStr + recipient.email;
            else
              recipientDisplayStr = recipientDisplayStr + ", " + recipient.email;
          });
          obj['recipientDisplay'] = 'Recipients: (' + recipientDisplayStr + ')';
        }
        map(obj.items, invoiceItem => {
          invoiceItem.paymentDue = this.toFormattedDate(invoiceItem.paymentDue)
        })
      })
      this.setState({ invoiceItemsGroupedByPayee: invoiceItems });
    }
    this.props.dispatch(forceStopLoader())
  }

  getCompanyDirectory() {
    if (!this.state.isFetchingCompanyDirectory)
      this.setState({ isFetchingCompanyDirectory: true }, async () => {
        APIService.companies().appendToUrl('directory/names/?excludeGroups=true&include_self=True').get().then(companies =>
          this.setState({ companyDirectory: companies, isFetchingCompanyDirectory: false }, this.setPayableItems));
      })
  }

  isSelected = (invoiceItem, payee) => Boolean(this.state.selectedPayeeCompanyIds?.includes(payee?.companyId) || find(this.state.selectedInvoiceItems, {id: invoiceItem.id}) || invoiceItem.isCheckedItem);

  updateSelectedPayeeCompanyIdsFromSelectedItem = payee => {
    const [isSome, isAll] = this.isSomeSelectedForPayee(payee)
    const isAlreadySelectedPayee = this.state.selectedPayeeCompanyIds.includes(payee.companyId)
    if(isAll && !isAlreadySelectedPayee)
      this.setState({selectedPayeeCompanyIds: [...this.state.selectedPayeeCompanyIds, payee.companyId] })
    else if(isSome && isAlreadySelectedPayee)
      this.setState({selectedPayeeCompanyIds: without(this.state.selectedPayeeCompanyIds, payee.companyId) })
    else if (!isAll && !isSome && isAlreadySelectedPayee)
      this.setState({selectedPayeeCompanyIds: without(this.state.selectedPayeeCompanyIds, payee.companyId) })
  }

  updateSelectedInvoiceItem = (selected, isChecked, obj) => {
    const newState = { ...this.state };
    newState.selectedInvoiceItems = isChecked ? [...this.state.selectedInvoiceItems, selected] : reject(this.state.selectedInvoiceItems, { id: selected.id });

    this.setState(newState, () => this.updateSelectedPayeeCompanyIdsFromSelectedItem(obj));
  }

  onSelectAllToggle = (event, value, obj) => {
    const newState = { ...this.state };
    var index = this.state.invoiceItemsGroupedByPayee.findIndex(item => item.companyId === obj.companyId);
    set(newState, `invoiceItemsGroupedByPayee.${index}.allSelected`, value);
    if (value)
      newState.selectedInvoiceItems = [...this.state.selectedInvoiceItems, ...obj.items];
    else {
      let itemIds = map(obj.items, 'id');
      newState.selectedInvoiceItems = this.state.selectedInvoiceItems.filter(item => !itemIds.includes(item.id));
    }
    this.setState(newState);
  }

  handleEmailRecipients(companyId) {
    var index = this.state.invoiceItemsGroupedByPayee.findIndex(item => item.companyId === companyId);
    const selectedRecipients = get(this.state.invoiceItemsGroupedByPayee[index], 'recipients');
    this.setState({ selectedRecipients: selectedRecipients })
    APIService
      .profiles()
      .appendToUrl('employees-signature/')
      .get(null, null, { company_ids: companyId })
      .then(employees => {
        const contactsWithEmail = map(filter(employees, employee => employee.email), employee => ({ ...employee, title: `${employee.name} (${employee.email})`, value: employee.email }))
        this.setState({ sellerEmployeeData: contactsWithEmail, recipientsDialogOpen: true })
      })
  }

  onDialogClose = () => this.setState({ recipientsDialogOpen: false });

  onRecipientsChange = (event, selected) => {
    if (selected && selected.length <= 3)
      this.setState({ showRecipientsError: false });
    this.setState({ selectedRecipients: selected });
  }

  submitEmailRecipients = () => {
    if (this.state.selectedRecipients.length > 3) {
      this.setState({ showRecipientsError: true });
    }
    else {
      this.setState({ recipientsDialogOpen: false, showRecipientsError: false });
      const companyId = get(this.state.selectedRecipients, '0.companyId');
      var index = this.state.invoiceItemsGroupedByPayee.findIndex(item => item.companyId === companyId);
      var invoiceIds = map(this.state.invoiceItemsGroupedByPayee[index].items, item => item.invoiceId);
      const data = {
        'selectedRecipients': this.state.selectedRecipients,
        'invoiceIds': invoiceIds,
      }
      APIService
        .invoices()
        .appendToUrl('email-recipients/')
        .put(data)
        .then(() => window.location.reload());
    }
  }

  fetchCSVData = (callback) => {
    const company = currentUserCompany();
    var param = this.state.isFilteredCsv ? 'show_filters' : '';
    if (this.state.customColumns) {
      param = param.length == 0 ? param + 'custom_csv' : param + '&custom_csv';
    }
    this.props.dispatch(setDownloadBar('Your Freight Payables CSV is getting prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true))
    const service = APIService.invoices();
    service.appendToUrl(`freight-payables/csv/?company_id=${company.id}&${param}`);
    service
      .get(this.props.token, {
        'Content-Type': 'text/csv',
        Accept: 'text/csv',
      })
      .then(() => this.setState({ csvPopup: false }));
    if (callback) {
      callback();
    }
  };

  customCsvEnabled(isFilteredCsv) {
    const newState = { ...this.state };
    newState.isFilteredCsv = isFilteredCsv;
    if (this.props.currentUser.company.enableCustomCsv) {
      newState.csvPopup = true;
      this.setState(newState);
    }
    else {
      newState.customColumns = false;
      this.setState(newState, this.fetchCSVData);
    }
  }

  async editCustomHeaderOptions() {
    const columnNames = await APIService.profiles().appendToUrl(`${this.props.currentUser.id}/report-preferences/freight_invoice_payables_csv/`).get();
    this.setState({ customColumnNames: columnNames, customHeaderOptions: true });
  }

  updateColumnCount(count) {
    this.setState({ customColumnTitle: `Edit Columns (${count})` });
  }

  handleFilters = bool => {
    this.setState({
      applyFilters: bool,
      openSideDrawer: bool,
    });
  };

  handleFilterState = (key, value) => {
    this.setState({ [key]: value }, () => {
      if (key === 'applyFilters' && value) {
        const { filters } = this.state;
        delete filters['commodity__id__in'];
        APIService
          .profiles()
          .filters()
          .post({ freight_invoice_payable: filters }, this.props.token)
          .then(res => {
            this.setState({ filters: get(res, 'filters.freight_invoice_payable', {}) }, () => {
              this.fetchInvoices();
            })
          });
      }
    });
  };

  customFilterValueExist = filterKeys => filterKeys.some(key => Boolean(get(this.state.filters, key)))

  filterCriteria = (key, value) => includes(FILTER_KEYS_TO_EXCLUDE, key) ? false : includes(PREDEFINED_DATE_RANGE_FILTER_KEYS, key) && value === 'custom' ? this.customFilterValueExist(get(INVOICE_FILTER_KEYS_MAPPING, key)) : value.length !== 0;

  getActionsOptionMapperListItems() {
    return [defaultViewAction];
  }

  handleGeneratePaymentRunClick() {
    this.setState(prevState => ({
      unverifiedNgrData: uniqBy(
        flattenDeep(map(prevState.selectedInvoiceItems, selectedItem => {
          const sellerNgrDetails = get(selectedItem, 'sellerNgrDetails');
          if (!get(sellerNgrDetails, 'isVerified')) {
            const { ngrType, ngrNumber, bankDetails } = sellerNgrDetails;

            if (isEmpty(bankDetails)) {
              return {
                companyName: '',
                ngrNumber,
                ngrType,
                shareholderPercent: '',
                bsbNumber: '',
                name: '',
                accountName: '',
                accountNumber: '',
              }
            }
            else if (isEqual(ngrType, 'shared')) {
              return map(bankDetails, item => {
                const { companyName, shareholderPercent, bsbNumber, name, accountName, accountNumber } = item;
                return {
                  companyName,
                  ngrNumber,
                  ngrType,
                  shareholderPercent,
                  bsbNumber,
                  name,
                  accountName,
                  accountNumber,
                };
              });
            } else {
              const [item] = bankDetails;
              const { companyName, shareholderPercent, bsbNumber, name, accountName, accountNumber } = item;
              return {
                companyName,
                ngrNumber,
                ngrType,
                shareholderPercent,
                bsbNumber,
                name,
                accountName,
                accountNumber,
              };
            }
          }
        })).filter(Boolean),
        JSON.stringify
      ),
    }));

    const company = currentUserCompany();
    let itemsWithIdenticalInvoices = {};
    let xeroMissingContacts = []
    const checkXeroContactStatus = company.xeroEnabled;
    let selectedInvoiceItems = [...this.state.selectedInvoiceItems];
    remove(selectedInvoiceItems, selectedInvoiceItem => selectedInvoiceItem.isChemicalItem);
    map(selectedInvoiceItems, selectedItem => {
      let invoice = get(selectedItem, 'invoice');
      if (checkXeroContactStatus) {
        const details = find(this.state.invoiceItems, invoiceItem => find(invoiceItem.items, item => item.ref === selectedItem.ref))
        if (details?.companyId && !details.xeroContactStatus) {
          const payee = find(this.state.companyDirectory, { id: details.companyId });
          xeroMissingContacts.push(payee.name)
        }
      }
      if (!has(itemsWithIdenticalInvoices, invoice)) {
        let invoiceItemsForSelectedItemInvoice = this.state.invoiceItems.filter(item => get(item, 'invoice') === invoice);
        if (invoiceItemsForSelectedItemInvoice.length > 1) {
          itemsWithIdenticalInvoices[invoice] = {};
          itemsWithIdenticalInvoices[invoice]['unselected'] = invoiceItemsForSelectedItemInvoice.filter(item => !this.state.selectedInvoiceItems.find(_item => item?.itemType === 'customitem' ? _item.description === item.description : _item.ref === item.ref)).map(item => item?.itemType === 'customitem' ? `${item.ref} - ${item.description}` : item.ref);
          itemsWithIdenticalInvoices[invoice]['selected'] = invoiceItemsForSelectedItemInvoice.filter(item => this.state.selectedInvoiceItems.find(_item => item?.itemType === 'customitem' ? _item.description === item.description : _item.ref === item.ref)).map(item => item?.itemType === 'customitem' ? `${item.ref} - ${item.description}` : item.ref);
        }
      }
    });

    xeroMissingContacts = uniq(xeroMissingContacts)

    let resultArray = []
    map(Object.entries(itemsWithIdenticalInvoices), item => {
      let invoice = get(item, '0');
      let selectedItems = get(item, '1.selected');
      let unselectedItems = get(item, '1.unselected');
      if (!isEmpty(unselectedItems)) {
        let unselectedVerb = unselectedItems.length > 1 ? 'have' : 'has';
        let selectedVerb = selectedItems.length > 1 ? 'have' : 'has';
        unselectedItems = `<ul><li>${unselectedItems.join('</li><li>')}</ul>`
        let itemRefs = `${invoice}: ${selectedItems.toString()} ${selectedVerb} been selected but ${unselectedItems} ${unselectedVerb} not been selected.`
        resultArray.push(itemRefs);
      }
    });

    let warningContent = '';
    if (!isEmpty(resultArray)) {
      resultArray = '<li>' + resultArray.join('</li><li>')
      warningContent = `<ul>${resultArray}</url>`
    }
    if (!isEmpty(xeroMissingContacts)) {
      const missingContactsText = '<li>' + xeroMissingContacts.join('</li><li>')
      warningContent += `<br/><br/><div>Following counter parties does not exist in Xero as Contacts:</div><ul>${missingContactsText}</ul>`
    }

    if (warningContent) {
      alertifyjs.confirm(
        'Warning',
        `<div>
            ${warningContent}
            <br/><br/>
            <div>
              Do you want to continue to create Payment Run ?
            </div>
        </div>`,
        () => this.setState({ openDialog: true }),
        () => { }
      );
    }
    else {
      this.setState({ openDialog: true });
    }
  }

  getSelectedSummary = () => {
    const { invoiceItemsGroupedByPayee, selectedPayeeCompanyIds, selectedInvoiceItems } = this.state
    let count = selectedInvoiceItems?.length || 0
    let total = sum(selectedInvoiceItems?.map(i => parseFloat(i?.total || 0))) || 0

    forEach(selectedPayeeCompanyIds, payeeCompanyId => {
      const payee = find(invoiceItemsGroupedByPayee, {companyId: payeeCompanyId})
      if(!payee.items?.length || !intersection(selectedInvoiceItems?.map(item => item.id), payee?.items?.map(item => item.id))?.length) {
      count += payee.totalItems
      total += parseFloat(payee.total) || 0
      }
    })
    return {count, total}
  }

  allPayeeCompanyIds = () => compact(map(this.state.invoiceItemsGroupedByPayee, 'companyId'))

  fetchItems = (event, payeeCompanyId) => {
    event.preventDefault()
    event.stopPropagation()
    if(payeeCompanyId && this.state.expanded.includes(payeeCompanyId)) {
      this.setState({expanded: without(this.state.expanded, payeeCompanyId)})
    }
    else if(payeeCompanyId && !this.state.expanded.includes(payeeCompanyId) && !this.state.loadingItemsFor.includes(payeeCompanyId)) {
      this._fetchItems(payeeCompanyId)
    }
  }

  _fetchItems = (payeeCompanyId, onComplete=null) => {
    if(payeeCompanyId && !this.state.loadingItemsFor.includes(payeeCompanyId)) {
      this.setState({expanded: uniq([...this.state.expanded, payeeCompanyId]), loadingItemsFor: uniq([...this.state.loadingItemsFor, payeeCompanyId])}, () => {
        const company = currentUserCompany();
        let payee = find(this.state.invoiceItems, {companyId: payeeCompanyId})
        if(!isEmpty(payee?.items)) {
          const newState = {...this.state}
          newState.loadingItemsFor = without(newState.loadingItemsFor, payeeCompanyId)
          this.setState(newState, () => {
            if (typeof onComplete === 'function') {
              onComplete();
            }
          });
        } else {
          APIService.invoices().appendToUrl(`freight-payable-items/?company_id=${company.id}&payee_company_id=${payeeCompanyId}&only_items=true`).get().then(response => {
            const newState = {...this.state}
            payee.items = response[0]?.items || []
            payee.chemicalApplicationItems = response[0]?.chemicalApplicationItems || []
            payee = {...payee, ...response[0]}
            newState.loadingItemsFor = without(newState.loadingItemsFor, payeeCompanyId)
            this.setState(newState, () => {
              if (typeof onComplete === 'function') {
                onComplete();
              }
            });
          }).catch(() => {
            const newState = {...this.state}
            newState.loadingItemsFor = without(newState.loadingItemsFor, payeeCompanyId)
            this.setState(newState, () => {
              if (typeof onComplete === 'function') {
                onComplete();
              }
            });
          });
        }
      });
    } else if (typeof onComplete === 'function') {
      onComplete();
    }
  }

  onExpandAll = event => {
    event.stopPropagation()
    event.preventDefault()
    const payeeCompanyIds = compact(map(orderBy(this.state.invoiceItemsGroupedByPayee, 'companyName'), 'companyId'))
    if(payeeCompanyIds?.length) {
      this.props.dispatch(isLoading('LoadingExpandAll'))

      let remainingRequests = 0
      payeeCompanyIds.forEach(id => {
        const payee = find(this.state.invoiceItems, {companyId: id})
        if(isEmpty(payee?.items)) {
          remainingRequests++
        }
      })

      this.setState({expanded: [...payeeCompanyIds]}, () => {
        if(remainingRequests === 0) {
          this.props.dispatch(forceStopLoader())
          return
        }

        const onItemComplete = () => {
          remainingRequests--
          if(remainingRequests <= 0) {
            this.props.dispatch(forceStopLoader())
          }
        }

        payeeCompanyIds.forEach(id =>
          this._fetchItems(id, onItemComplete)
        )
      })
    }
  }

  onCollapseAll = () => this.setState({expanded: []})

  onSelectAllItems = event => {
    event.persist();
    const isChecked = event.target.checked;
    const newState = {...this.state};

    if (isChecked) {
        newState.selectedPayeeCompanyIds = this.allPayeeCompanyIds();
        const expandedItems = this.state.invoiceItemsGroupedByPayee
            .filter(payee => this.state.expanded.includes(payee.companyId))
            .reduce((allItems, payee) => [...allItems, ...(payee.items || [])], []);
        const allItems = [...this.state.selectedInvoiceItems, ...expandedItems];
        newState.selectedInvoiceItems = uniqBy(allItems, 'id');
    } else {
        newState.selectedPayeeCompanyIds = [];
        newState.selectedInvoiceItems = [];
    }
    this.setState({
      selectedPayeeCompanyIds: newState.selectedPayeeCompanyIds,
      selectedInvoiceItems: newState.selectedInvoiceItems
    });
  }

  onSelectPayee = (event, companyId) => {
    event.persist()
    const isChecked = event.target.checked
    this.setState({selectedPayeeCompanyIds: isChecked ? [...this.state.selectedPayeeCompanyIds, companyId] : without(this.state.selectedPayeeCompanyIds, companyId)}, () => {
      const payee = find(this.state.invoiceItemsGroupedByPayee, {companyId: companyId})
      if(payee && payee?.items?.length)
        this.onSelectAllToggle(event, isChecked, payee)
    })
  }

  isSomeSelectedForPayee = payee => {
    const itemIds = map(payee?.items, 'id') || []
    if(itemIds.length) {
      const selected = filter(this.state.selectedInvoiceItems, item => itemIds.includes(item.id))
      return [Boolean(selected.length && selected.length !== itemIds.length), Boolean(selected.length && selected.length === itemIds.length)]
    }
    return [false, false] // some not all, all
  }

  render() {
    const {
      invoiceItemsGroupedByPayee, selectedInvoiceItems,
      recipientsDialogOpen, selectedRecipients, sellerEmployeeData,
      selectedPayeeCompanyIds
    } = this.state
    const disablePaymentRun = (
      selectedPayeeCompanyIds.length === 0 &&
      !get(selectedInvoiceItems, 'length', 0) > 0
    );

    const allPayeeCompanyIds = this.allPayeeCompanyIds()
    const isExpandAll = Boolean(this.state.expanded?.length === this.state.invoiceItemsGroupedByPayee?.length && this.state.expanded?.length)
    const isEverythingSelected = Boolean(selectedPayeeCompanyIds?.length && selectedPayeeCompanyIds.length == allPayeeCompanyIds.length)
    const isSomeOfEverythingSelected = Boolean(selectedPayeeCompanyIds?.length && selectedPayeeCompanyIds.length != allPayeeCompanyIds.length)
    const { count, total} = this.getSelectedSummary()
    const invoiceItems = orderBy(invoiceItemsGroupedByPayee, obj => obj?.payeeName?.toLowerCase())
    const collapseButtonLabel =  invoiceItemsGroupedByPayee?.length > 1 ? "Collapse All": "Collapse"
    const expandButtonLabel = invoiceItemsGroupedByPayee?.length > 1 ? "Expand All": "Expand"

    return (
      <Paper className="paper-table">
        <div>
          <span style={{ paddingLeft: '10px', fontWeight: 'bold', fontSize: '20px', marginTop: '8px' }}>
            Freight Payable
            <FormControlLabel
              onClick={this.onSelectAllItems}
              sx={{marginLeft: '16px'}}
              control={<Checkbox indeterminate={isSomeOfEverythingSelected} checked={isEverythingSelected} />}
              label="Select All"
            />
            <Chip
              icon={isExpandAll ? <CollapseIcon fontSize='inherit' /> : <ExpandIcon fontSize='inherit' />}
              label={isExpandAll ? collapseButtonLabel : expandButtonLabel}
              onClick={isExpandAll ? this.onCollapseAll : this.onExpandAll}
            />
            {
              count > 0 &&
                <span style={{fontSize: '1rem', marginLeft: '20px', fontWeight: 'normal'}}>
                  Selected Items: <b style={{marginRight: '10px'}}>{count.toLocaleString()}</b> Total: <b>{`${getCountryCurrency()} ${Number(total.toFixed(2)).toLocaleString()}`}</b>
              </span>
            }
          </span>

          <CommonListingButton
            defaultHandler={() => this.customCsvEnabled(false)}
            showMenus={!isEmpty(Object.entries(this.state.filters).filter(val => get(val, '1.length', 0) !== 0))}
            optionMapper={[
              { name: 'Complete List', fx: () => this.customCsvEnabled(false) },
              { name: 'Filtered List', fx: () => this.customCsvEnabled(true) },
            ]}
            title='Download Contents of the table in a CSV'
            name='Export'
          />
          <Tooltip title='Apply filters' placement='top'>
            <Button
              value={this.state.applyFilters}
              variant="contained"
              type='button'
              onClick={() => this.handleFilters(true)}
              color='primary'
              className='add-button'
              style={{ float: 'right', marginLeft: '10px' }}
            >
              <FilterListIcon style={{ paddingRight: '5px' }} />
              FILTERS{' '}
              {+!isEmpty(Object.entries(this.state.filters).filter(val => this.filterCriteria(val[0], val[1])))
                ? `(${Object.entries(this.state.filters).filter(val => this.filterCriteria(val[0], val[1])).length})`
                : ''}
            </Button>
          </Tooltip>
          <Button
            variant="contained"
            type='button'
            color='primary'
            onClick={this.handleGeneratePaymentRunClick}
            style={{ float: 'right', marginLeft: '10px' }}
            disabled={disablePaymentRun}
          >
            Generate Payment Run
          </Button>
          <CommonListingButton
            showMenus
            showDownLoadIcon={false}
            optionMapper={this.getActionsOptionMapperListItems()}
            title='Actions'
            name='Actions'
          />
          <Dialog open={this.state.csvPopup} onClose={() => this.setState({ csvPopup: false })} aria-labelledby='form-dialog-title' fullWidth>
            <DialogTitleWithCloseIcon
              onClose={() => this.setState({ csvPopup: false })}
              closeButtonStyle={{ marginTop: '0px' }}
              id='form-dialog-title'>
              Download Freight Invoice Payable Data
            </DialogTitleWithCloseIcon>
            <DialogContent style={{ marginTop: '15px' }}>
              <span style={{ float: 'left' }}>Select checkbox for custom csv download</span>
              <div className='col-sm-6 padding-reset' style={{ marginTop: '10px' }}>
                <Checkbox
                  id={'custom-headers'}
                  checked={this.state.customColumns}
                  style={{ height: '40px' }}
                  onChange={() => this.setState({ customColumns: !this.state.customColumns })}
                />
                Custom Columns
                <a onClick={() => this.editCustomHeaderOptions()} hidden={!this.state.customColumns} style={CUSTOM_HEADER_EDIT}>View & Update</a>
              </div>
              <SideDrawer
                isOpen={this.state.customHeaderOptions}
                title={this.state.customColumnTitle}
                onClose={() => this.setState({ customHeaderOptions: false })}
                size="small"
              >
                <CustomHeaderOptions
                  customColumns={this.state.customColumnNames}
                  closeDrawer={() => this.setState({ customHeaderOptions: false })}
                  user={this.props.currentUser}
                  csv_type="freight_invoice_payables_csv"
                  updateColumnCount={(count) => this.updateColumnCount(count)}
                />
              </SideDrawer>
            </DialogContent>
            <DialogActions>
              <Button
                type='button'
                onClick={() => this.setState({ csvPopup: false })}
                variant='outlined'>
                Cancel
              </Button>
              <Button type='button' onClick={() => this.fetchCSVData()} color='primary' variant='outlined'>
                Download
              </Button>
            </DialogActions>
          </Dialog>
          {this.state.applyFilters && (
            <SideDrawer isOpen={this.state.openSideDrawer} title='Filters' size='big' onClose={() => this.handleFilters(false)} app='filters'>
              <InvoiceFilters
                handleFilterState={this.handleFilterState}
                filters={this.state.filters}
                freightPayableInvoice
              />
            </SideDrawer>
          )}
          <Divider light style={{ marginLeft: '-10px', marginRight: '-10px', marginTop: '20px' }} />
          {!isEmpty(invoiceItemsGroupedByPayee) &&
            <Virtuoso
              data={invoiceItems}
              style={{ height: '100vh' }}
              itemContent={(index, obj) => {
                const isExpanded = this.state.expanded.includes(obj.companyId)
                const [isSomeSelected,] = this.isSomeSelectedForPayee(obj)
                return (
                <div id={index} key={index}>
                  <div style={{ marginLeft: '-10px', marginRight: '-10px', background: '#F5F5F5', height: '50px' }}>
                    <Tooltip title={isExpanded ? 'Click to collapse' : 'Click to expand and view items'}>
                      <span style={{ display: 'inline-flex', alignItems: 'center', width: 'calc(100% - 400px)', cursor: 'pointer'}} onClick={event => this.fetchItems(event, obj.companyId)}>
                        <span style={{ paddingLeft: '15px', fontSize: '20px', lineHeight: '50px' }}>{obj.payerName}</span>
                        <span style={{ paddingLeft: '15px', fontSize: '15px', lineHeight: '50px', marginTop: '1px' }}>
                          {
                            <>
                              {
                                get(obj, 'recipientDisplay') &&
                                <span style={{ paddingLeft: '15px', fontSize: '15px' }}>{get(obj, 'recipientDisplay')}</span>
                              }
                              <Tooltip title='Edit Recipients'>
                                <IconButton
                                  onClick={() => this.handleEmailRecipients(obj.companyId)}
                                  size="large">
                                  <Create fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </>
                          }
                        </span>
                      </span>
                    </Tooltip>

                    <div style={{ float: "right", marginRight: '20px', lineHeight: '50px', maxWidth: '400px' }}>
                      <FormControlLabel
                        sx={{marginRight: '20px'}}
                        control={
                          <Badge badgeContent={obj.totalItems} color='secondary' sx={{'.MuiBadge-badge': {left: '-20px', top: '16px'}}} anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          >
                            <Checkbox
                              sx={{padding: '6px'}}
                              indeterminate={isSomeSelected}
                              checked={selectedPayeeCompanyIds.includes(obj.companyId)}
                              onChange={event => this.onSelectPayee(event, obj.companyId)}
                              size='small'
                            />
                            </Badge>
                        }
                        label="Select All"
                      />
                    </div>
                  </div>
                  <div style={isExpanded ? {} : { marginTop: '10px' }}>
                  {
                    (isEmpty(obj.items) || !isExpanded) ?
                      (
                        isExpanded ? <Skeleton width='100%' height={100} /> : <div />
                      ) :
                    <InvoicePayableTable
                      items={obj.items}
                      columns={[
                        {
                            size: 'small',
                            sx:{padding: 0},
                            align: 'left',
                            header: 'All',
                            checkbox: true,
                            className: 'xxxsmall',
                            onChange:(selected, isChecked) => this.updateSelectedInvoiceItem(selected, isChecked, obj),
                            func: item => this.isSelected(item, obj),
                            selectAll: true,
                            checked: selectedPayeeCompanyIds?.includes(obj.companyId),
                            indeterminate: isSomeSelected,
                            onSelectAll: event => this.onSelectPayee(event, obj.companyId)
                        },
                        ...FREIGHT_RECEIVABLE_ITEM_COLUMNS
                      ]}
                      isSubscriber={obj.isSubscriber}
                    />
                  }
                  </div>
                  {
                    recipientsDialogOpen &&
                    <Dialog open keepMounted fullWidth onClose={this.onDialogClose}>
                      <DialogTitleWithCloseIcon
                        onClose={this.onDialogClose}
                        closeButtonStyle={{ marginTop: '0px' }}
                        id='form-dialog-title'>
                        Update Recipients
                      </DialogTitleWithCloseIcon>
                      <DialogContent>
                        <React.Fragment>
                          <div>
                            {'Update Recipients'}
                          </div>
                          <div>
                            <EmailAutocomplete
                              options={sellerEmployeeData}
                              onChange={this.onRecipientsChange}
                              selected={selectedRecipients}
                            />
                          </div>
                          {
                            this.state.showRecipientsError &&
                            <div style={{ marginTop: '3px', color: '#FF0000', fontSize: '15px' }}>
                              You can select only 3 emails.
                            </div>
                          }
                        </React.Fragment>
                      </DialogContent>
                      <DialogActions>
                        <Button color='default' onClick={this.onDialogClose}>Cancel</Button>
                        <Button onClick={this.submitEmailRecipients}>Proceed</Button>
                      </DialogActions>
                    </Dialog>
                  }
                </div>
              )}
              }
            />
          }
        </div>
        <Dialog
          open={this.state.openDialog}
          onClose={() => this.setState({ openDialog: false })}
          aria-labelledby="form-dialog-title"
          fullWidth
          PaperProps={{ style: { maxWidth: !isEmpty(this.state.unverifiedNgrData) ? '1400px' : '600px' } }}
        >
          <DialogTitleWithCloseIcon
            onClose={() => this.setState({ openDialog: false })}
            closeButtonStyle={{ marginTop: '0px' }}
            id='form-dialog-title'>
            Payment Run
          </DialogTitleWithCloseIcon>
          <DialogContent style={{ marginTop: '15px' }}>
            <span style={{ float: 'left' }}>
              This will generate invoices and a payment run for the selected items.
            </span>
            <div style={{ marginTop: '35px', marginBottom: '20px' }}>
              <CommonDatePicker
                id='bankProcessingDate'
                floatingLabelText='Bank Processing Date'
                onChange={this.handleBankProcessingDateChange}
                errorText={this.state.bankProcessingDate.error}
                value={this.state.bankProcessingDate.value}
                minDate={new Date()}
              />
            </div>
            {!isEmpty(this.state.unverifiedNgrData) && <div>
              <span style={{ marginTop: '35px' }}>
                The following NGR&apos;s bank details are not verified from NGR.com. Please verify the account details before making the payment.
              </span>
              <Table style={{ border: "none" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Company Name</TableCell>
                    <TableCell>NGR Number</TableCell>
                    <TableCell>NGR Type</TableCell>
                    <TableCell>Shareholder Percent</TableCell>
                    <TableCell>{`${getCountryLabel('bsb')} Number`}</TableCell>
                    <TableCell>Bank Name</TableCell>
                    <TableCell>Account Name</TableCell>
                    <TableCell>Account Number</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {map(this.state.unverifiedNgrData, row => (
                    <TableRow key={row.ngrNumber}>
                      <TableCell>{row.companyName}</TableCell>
                      <TableCell>{row.ngrNumber}</TableCell>
                      <TableCell>{row.ngrType}</TableCell>
                      <TableCell>{row.shareholderPercent}</TableCell>
                      <TableCell>{row.bsbNumber}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.accountName}</TableCell>
                      <TableCell>{row.accountNumber}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>}

          </DialogContent>
          <DialogActions>
            <Button
              type='button'
              onClick={() => this.setState({ openDialog: false })}
              variant='outlined'>
              Cancel
            </Button>
            <Button type='button' onClick={this.generatePaymentRun} color='primary' variant='contained'>
              OK
            </Button>
          </DialogActions>
        </Dialog>

      </Paper>
    )
  }
}

const mapStateToProps = state => {
  return {
    breadcrumbs: state.main.breadcrumbs,
    currentUser: state.main.user.user,
  };
};

const mapDispatchToProps = dispatch => ({
  isLoading: (waitForComponent) => dispatch(isLoading(waitForComponent)),
  forceStopLoader: () => dispatch(forceStopLoader()),
  setDownloadBar: (message, isOpen, onClose) => dispatch(setDownloadBar(message, isOpen, onClose)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FreightInvoicePayable);
