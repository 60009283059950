import React, {Component} from 'react';
import {connect} from 'react-redux';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import './movementDetails.scss';
import {MovementDetailsBasicInfo} from './MovementDetailsBasicInfo';
import MovementDetailsContacts from './MovementDetailsContacts';
import MovementDeliveredDetails from './MovementDeliveredDetails';
import MovementDetailsConditionSection from './MovementDetailsConditionSection';
import { receiveFreight,} from '../../../actions/companies/freights';
import { setHeaderText, setSubHeaderText, setBreadcrumbs, isLoading} from '../../../actions/main';
import { isStaff } from '../../../common/utils';
import {RejectionDetails} from "../../rejections/RejectionDetails";
import {RejectedLoadReasonDetails} from "../../rejections/RejectedLoadDetails";
import FreightContractDetails from "./FreightContractDetails";
import OrderNotes from "../order-details/OrderNotes";
import DocketClarificationTable from './DocketClarificationTable';
import { ORDER_TYPE_ROUTE_MAPPING, PACK_ORDER_TYPE_ID } from '../../../common/constants';
import {ImpexDocsStatus} from "../order-details/ImpexDocsStatus";


class MovementDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resolveDocket: false,
      clarificationLoad: '',
    }
  }
  static defaultProps = {
    movement: {}
  };

  componentDidUpdate() {
    if (this.shouldFetchMovement()) {
      this.props.getSelectedFreight(get(this.props,'match.params.movement_id'), receiveFreight, false, false, false, false, false);
    }
    const pathSegment = get(this.props.movement, 'typeId') == PACK_ORDER_TYPE_ID ? "pack": "freights"
    let breadcrumbs = [
      {text: 'Movements', route: `/movements/${pathSegment}`},
      {text: get(this.props.movement, 'identifier', '')},
    ];
    const movementOrder = this.props.movementOrder;
    if(movementOrder){
      const countLabel = ` (${this.props.count})`;
      const orderType = get(ORDER_TYPE_ROUTE_MAPPING, movementOrder.typeId, 'freights')
      breadcrumbs =  [
        {text: 'Orders', route: `/orders/${orderType}`},
        {text: get(movementOrder, 'identifier', ''), route: `/freights/orders/${get(movementOrder, 'id')}/order`},
        {text: 'Movements' + countLabel, route: `/freights/orders/${get(movementOrder, 'id')}/movements`},
        {text: get(this.props.movement, 'identifier', '')},
      ];
    }
    if(!isEqual(this.props.breadcrumbs, breadcrumbs)) {
      this.props.setBreadcrumbs(breadcrumbs);
    }
  }

  shouldFetchMovement() {
    return isEmpty(this.props.movement);
  }

  componentWillUnmount() {
    this.props.resetHeaderAndSubHeaderText();
  }

  markResolveDocket = clarificationLoad => this.setState({ resolveDocket: true, clarificationLoad: clarificationLoad});

  unmarkResolveDocket = () => this.setState({ resolveDocket: false, clarificationLoad: '' });

  render() {
    const {movement, currentUser} = this.props;
    const note  = get(this.props, 'movement.note');
    const rejectionReasonDisplay = get(movement, 'rejectionReasonDisplay');
    const requestReasonDisplay = get(movement, 'requestReasonDisplay');
    const {resolveDocket, clarificationLoad} = this.state;
    const customerCompany = get(movement, 'customer.company')
    const isCustomer = currentUser?.companyId == get(movement, 'customer.companyId')
    const showImpexdocsError = isEqual(this.props.movementOrder?.typeId, PACK_ORDER_TYPE_ID) && customerCompany?.impexDocsEnabled
    const impexDocsContainerError = showImpexdocsError && get(this.props.movementOrder?.impexDocsErrors, 'containers', {})
    return (
      <div className="contract-details-container" id="movement-details-container">
        {!isEmpty(movement) &&
        <div>
          <MovementDetailsBasicInfo {...this.props} />
          {
            get(movement,'status') === 'load_rejected' ? <RejectedLoadReasonDetails movement={movement}/> :
            <RejectionDetails rejectionReasonObject={rejectionReasonDisplay}/>
          }
          {
            get(impexDocsContainerError, movement?.id) &&
            <ImpexDocsStatus
              className="order-details-section-container"
              errors={this.props.movementOrder?.impexDocsErrors}
              heading="Impex Docs Status"
              packMovementId={movement?.id}
            />
           }

          {get(rejectionReasonDisplay, 'requestReason') && <RejectionDetails rejectionReasonObject={rejectionReasonDisplay}/>}
          {get(requestReasonDisplay, 'requestReason') && <RejectionDetails rejectionReasonObject={requestReasonDisplay}/>}
          {(isStaff() || isCustomer) && movement?.pausedDocketEntry && <DocketClarificationTable movement={movement} markResolveDocket={this.markResolveDocket}/>}
          <MovementDetailsContacts {...this.props}/>
          <FreightContractDetails  {...this.props} toggleBookingPopup={this.props.toggleBookingPopup} />
          <MovementDeliveredDetails {...this.props} resolveDocket={resolveDocket} unmarkResolveDocket={this.unmarkResolveDocket} clarificationLoad={clarificationLoad} />
          <MovementDetailsConditionSection {...this.props} />
           {note && <OrderNotes {...this.props} entity={get(this.props, 'movement')}/>}
        </div>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    movement: state.companies.freights.selectedFreight,
    currentUser: state.main.user.user,
    breadcrumbs: state.main.breadcrumbs,
    count: get(state.companies.freights, 'paginationData.count') || 0,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetHeaderAndSubHeaderText: () => {
      dispatch(setHeaderText(null));
      dispatch(setSubHeaderText(null));
    },
    setBreadcrumbs: breadcrumbs => dispatch(setBreadcrumbs(breadcrumbs)),
    loading: () => dispatch(isLoading('unknown')),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MovementDetails);
