import React from 'react';
import { Button, Paper, ButtonGroup, Tooltip, Divider } from '@mui/material';
import TransferIcon from '@mui/icons-material/ArrowCircleRight';
import MergeIcon from '@mui/icons-material/Merge';
import { get, set, forEach, map, cloneDeep, includes, filter, mapValues, compact, isEmpty, isNull } from 'lodash';
import { connect } from 'react-redux';
import { setBreadcrumbs, setHeaderText } from '../../actions/main';
import APIService from '../../services/APIService';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import CommonTextField from '../common/CommonTextField';
import alertifyjs from 'alertifyjs';
import CompanyAutocomplete from '../common/autocomplete/CompanyAutocomplete';

const COMMON_MODEL = {
  from: '',
  to: '',
  action: 'transfer',
};

const MergeButton = ({ selected, onChange }) => (
  <ButtonGroup>
    <Tooltip arrow title='Merge'>
      <Button variant={selected === 'merge' ? 'contained' : 'outlined'} onClick={() => onChange('merge')}><MergeIcon /></Button>
    </Tooltip>
    <Tooltip arrow title='Transfer'>
      <Button variant={selected === 'transfer' ? 'contained' : 'outlined'} onClick={() => onChange('transfer')}><TransferIcon /></Button>
    </Tooltip>
  </ButtonGroup>
);


const MergeAsset = ({title, fromItems, toItems, itemField, state, onChange, stateField }) => {
  return (
    <div className='col-xs-12 padding-reset'>
      <h4 style={{paddingLeft: '15px'}}>{title} {`(${(fromItems || []).length})`}</h4>
      {
        map(fromItems, (item, index) => {
          const id = `fields.${stateField}.[${index}]`
          return (
            <div className="col-xs-12 form-wrap-70" key={index} style={{display: 'inline-flex', alignItems: 'flex-end', margin: '5px 0'}}>
              <div className="col-xs-5 form-wrap-70">
                <CommonTextField label="Name" value={get(item, itemField)} disabled />
              </div>
              <div className="col-xs-2 form-wrap-70" style={{textAlign: 'center'}}>
                <MergeButton selected={get(state, `[${index}].action`)} onChange={val => onChange(val, `${id}.action`, index)}/>
              </div>
              {
                get(state, `[${index}].action`) != 'transfer' &&
                  <div className="col-xs-5 form-wrap-70">
                 <CommonAutoSelect
                   id={`${id}.to`}
                   label='Name'
                   items={toItems}
                   value={get(state, `[${index}].to`)}
                   onChange={(val, id, index) => onChange(val, id, index)}
                   dataSourceConfig={{ id: 'id', text: itemField }}
                 />
               </div>
              }
            </div>
          )
        })
      }
      <Divider style={{display: 'inline-block', width: '100%'}} />
    </div>

  )
}

class MergeCompaniesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mergeToCompany: undefined,
      mergeFrom: props.company || '',
      fields: {
        mergeTo: '',
        employees: [],
        ngrs: [],
        farms: [],
        trucks: [],
        storages: [],
        fields: [],
      },
      mergerEmployees: [],
      mergeToEmployees: [],
      mergeToNgrs: [],
      mergerNgrs: [],
      mergerFarms: [],
      mergeToFarms: [],
      storageList: [],
      mergerTrucks: [],
      mergeToTrucks: [],
    };
    this.fetchCompanyDetails = this.fetchCompanyDetails.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this)
    this.initialState = this.state
  }

  componentDidMount() {
    const { dispatch } = this.props
    this.fetchMergeFrom();
    dispatch(setBreadcrumbs([{ text: 'Companies', route: '/companies' }, { text: 'Merge/Transfer' }]));
    dispatch(setHeaderText('Merge/Transfer Companies'));
  }

  componentDidUpdate(prevProps) {
    if(prevProps.match.params.company_id !== this.props.match.params.company_id)
      this.setState({mergeToCompany: undefined, mergeFrom: '', fields: {...this.state.fields, mergeTo: ''}}, this.fetchMergeFrom)
  }

  fetchMergeFrom() {
    if(!this.state.mergeFrom?.id) {
      APIService.companies(this.props.match.params.company_id).get().then(response => this.setState({mergeFrom: response}))
    }
  }

  handleMergeToCompanyChange = val => this.setState({mergeToCompany: val, fields: { ...this.state.fields, mergeTo: val?.id || '' } }, () => {
    if (val) this.fetchCompanyDetails()
    else this.setState(this.initialState)
  })

  fetchCompanyDetails = async () => {
    const newState = { ...this.state };
    const mergerNgrs = await APIService.companies(this.props.match.params.company_id).ngrs().appendToUrl('minimal/').get()
    const mergeToNgrs = await APIService.companies(this.state.fields.mergeTo).ngrs().appendToUrl('minimal/').get()
    const mergerEmployees = await APIService.companies(this.props.match.params.company_id).employees().get()
    const mergeToEmployees = await APIService.companies(this.state.fields.mergeTo).employees().get()
    const mergerFarms = await APIService.companies(this.props.match.params.company_id).farms().get()
    const mergeToFarms = await APIService.companies(this.state.fields.mergeTo).farms().get()
    const mergerTrucks = await APIService.companies(this.props.match.params.company_id).trucks().get()
    const mergeToTrucks = await APIService.companies(this.state.fields.mergeTo).trucks().get()
    forEach(mergerEmployees, (val, index) => {
      set(newState, `fields.employees[${index}]`, cloneDeep(COMMON_MODEL))
      set(newState, `fields.employees[${index}].from`, val.id)
    })
    forEach(mergerNgrs, (val, index) => {
      set(newState, `fields.ngrs[${index}]`, cloneDeep(COMMON_MODEL))
      set(newState, `fields.ngrs[${index}].from`, val.id)
    })
    forEach(mergerFarms, (val, index) => {
      set(newState, `fields.farms[${index}]`, cloneDeep(COMMON_MODEL))
      set(newState, `fields.farms[${index}].from`, val.id)
    })
    forEach(mergerTrucks, (val, index) => {
      set(newState, `fields.trucks[${index}]`, cloneDeep(COMMON_MODEL))
      set(newState, `fields.trucks[${index}].from`, val.id)
    })
    newState.mergerNgrs = mergerNgrs;
    newState.mergeToNgrs = mergeToNgrs;
    newState.mergerEmployees = mergerEmployees;
    newState.mergeToEmployees = mergeToEmployees;
    newState.mergerFarms = mergerFarms;
    newState.mergeToFarms = mergeToFarms;
    newState.mergerTrucks = mergerTrucks;
    newState.mergeToTrucks = mergeToTrucks;
    this.setState(newState)
  }

  handleEntityChange = (val, id) => {
    const newState = { ...this.state };
    set(newState, id, val)
    this.setState(newState, () => includes(id, 'fields.farms') && this.fetchStorages())
  }

  fetchStorages = async () => {
    const newState = { ...this.state };
    let fromQueryString = '';
    let toQueryString = '';
    let mergeToCheck = false;
    let mergeFromCheck = false;
    forEach(this.state.fields.farms, (obj, index) => {
      if (get(this.state.fields, `farms[${index}].action`) === 'merge' && !isNull(obj.from) && !isEmpty(obj.from)) {
        if(obj?.from) {
          fromQueryString = fromQueryString + `farm_ids=${obj.from}&`;
          mergeFromCheck = true
        }
      }
    });
    forEach(this.state.fields.farms, (obj, index) => {
      if (get(this.state.fields, `farms[${index}].action`) === 'merge' && !isNull(obj.to) && !isEmpty(obj.to)) {
        if(obj?.to) {
          toQueryString = toQueryString + `farm_ids=${obj.to}&`;
          mergeToCheck = true;
        }
      }
    });
    if (!isEmpty(this.state.fields.farms) && mergeFromCheck && mergeToCheck) {
      let fromStorageList = await APIService.farms().appendToUrl(`linked-storages-fields/?${fromQueryString}`).get();
      let toStorageList = await APIService.farms().appendToUrl(`linked-storages-fields/?${toQueryString}`).get();
      forEach(fromStorageList['storages'], (val, index) => {
        set(newState, `fields.storages[${index}]`, cloneDeep(COMMON_MODEL))
        set(newState, `fields.storages[${index}].from`, val.id)
        val['farmStorageDisplayName'] = get(val, 'storageDisplayName', get(val, 'fieldDisplayName'))
        delete val['storageDisplayName']
        delete val['farmFieldDisplayName']
      })
      forEach(toStorageList['storages'], val => {
        val['farmStorageDisplayName'] = get(val, 'storageDisplayName', get(val, 'fieldDisplayName'))
        delete val['storageDisplayName']
        delete val['farmFieldDisplayName']
      })
      forEach(fromStorageList['fields'], (val, index) => {
        set(newState, `fields.fields[${index}]`, cloneDeep(COMMON_MODEL))
        set(newState, `fields.fields[${index}].from`, val.id)
        val['farmStorageDisplayName'] = get(val, 'fieldDisplayName')
        delete val['storageDisplayName']
        delete val['farmFieldDisplayName']
      })
      forEach(toStorageList['fields'], val => {
        val['farmStorageDisplayName'] = get(val, 'fieldDisplayName')
        delete val['storageDisplayName']
        delete val['farmFieldDisplayName']
      })
      newState.fromStorageList = fromStorageList['storages']
      newState.toStorageList = toStorageList['storages']
      newState.fromFieldList = fromStorageList['fields']
      newState.toFieldList = toStorageList['fields']
      this.setState(newState);
    }
  }

  handleSubmit() {
    const fields = cloneDeep(this.state.fields);
    delete fields.mergeTo
    let transfer_values = {
      employees_transfer: Object.values(mapValues(filter(fields.employees, ['action', 'transfer']), 'from')),
      ngrs_transfer: Object.values(mapValues(filter(fields.ngrs, ['action', 'transfer']), 'from')),
      farms_transfer: Object.values(mapValues(filter(fields.farms, ['action', 'transfer']), 'from')),
      fields_transfer: Object.values(mapValues(filter(fields.fields, ['action', 'transfer']), 'from')),
      storages_transfer: Object.values(mapValues(filter(fields.storages, ['action', 'transfer']), 'from')),
      trucks_transfer: Object.values(mapValues(filter(fields.trucks, ['action', 'transfer']), 'from')),
    }
    let data = {};
    forEach(fields, (value, key) => data[key] = { transfer: compact(get(transfer_values, key + '_transfer')), merge: filter(fields[key], ['action', 'merge']) })
    APIService.companies(this.state.fields.mergeTo).appendToUrl(`merge/?purge_company_id=${this.props.match.params.company_id}`).post(data).then(
      res => {
        if (get(res, 'alert')) alertifyjs.error(get(res, 'alert'))
        else alertifyjs.success('Company purged!', 3, () => window.history.back())
      }
    )
    return data;
  }

  render() {
    return (
      <Paper style={{padding: '15px', display: 'flex', flexDirection: 'column'}}>
        <form noValidate>
          <div className='col-xs-12 padding-reset'>
            <div className='col-xs-6 no-left-padding'>
              <CommonTextField label='Merge/Transfer From' value={get(this.state.mergeFrom, 'name')} disabled />
            </div>
            <div className='col-xs-6 no-right-padding'>
              <CompanyAutocomplete
                variant='standard'
                selected={this.state.mergeToCompany}
                onChange={this.handleMergeToCompanyChange}
                minLength={3}
                disabled={false}
                label='Merge/Transfer To'
              />
            </div>
          </div>
          {
            this.state.fields.mergeTo &&
              <div className='col-xs-12 padding-reset'>
                <MergeAsset
                  title='Employees'
                  fromItems={this.state.mergerEmployees}
                  toItems={this.state.mergeToEmployees}
                  itemField='name'
                  state={this.state.fields.employees}
                  onChange={this.handleEntityChange}
                  stateField='employees'
                />
                <MergeAsset
                  title='NGRs'
                  fromItems={this.state.mergerNgrs}
                  toItems={this.state.mergeToNgrs}
                  itemField='ngrNumber'
                  state={this.state.fields.ngrs}
                  onChange={this.handleEntityChange}
                  stateField='ngrs'
                />
                <MergeAsset
                  title='Farms/Sites'
                  fromItems={this.state.mergerFarms}
                  toItems={this.state.mergeToFarms}
                  itemField='displayName'
                  state={this.state.fields.farms}
                  onChange={this.handleEntityChange}
                  stateField='farms'
                />
                <MergeAsset
                  title='Fields'
                  fromItems={this.state.fromFieldList}
                  toItems={this.state.toFieldList}
                  itemField='farmStorageDisplayName'
                  state={this.state.fields.fields}
                  onChange={this.handleEntityChange}
                  stateField='fields'
                />
                <MergeAsset
                  title='Storages'
                  fromItems={this.state.fromStorageList}
                  toItems={this.state.toStorageList}
                  itemField='rego'
                  state={this.state.fields.storages}
                  onChange={this.handleEntityChange}
                  stateField='storages'
                />
                <MergeAsset
                  title='Trucks'
                  fromItems={this.state.mergerTrucks}
                  toItems={this.state.mergeToTrucks}
                  itemField='rego'
                  state={this.state.fields.trucks}
                  onChange={this.handleEntityChange}
                  stateField='trucks'
                />
                <div className='col-md-12' style={{ marginTop: '20px', textAlign: 'right'}}>
                  <Button type='button' onClick={this.handleSubmit} color='primary' variant='outlined' style={{ marginRight: '16px'}}>
                    Merge
                  </Button>
                  <Button type='button' onClick={() => window.history.back()} color='default' variant='outlined'>
                    Cancel
                  </Button>
                </div>
              </div>
          }
        </form>
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.main.user.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MergeCompaniesForm);
