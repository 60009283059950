import React from 'react';

import { connect } from 'react-redux';
import { getStorages } from '../../actions/api/storages';
import {
  receiveArchivedStorages, activateStorage,
} from '../../actions/companies/storages';
import { getCompanyDetails } from '../../actions/companies';
import GenericTable from '../GenericTable';
import { getGradeName, currentUser } from '../../common/utils';

class ArchivedStorages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSideDrawer: false,
    };
  }

  componentDidMount() {
    this.props.getStorages(this.props.farmId);
  }

  render() {
    let columns = [
      { key: 'name', header: 'Storage Name', },
      { key: 'typeName', header: 'Type' },
      { key: 'size', header: `Size (${currentUser()?.unit})` },
      { key: 'commodity.displayName', header: 'Commodity' },
      { key: 'variety.name', header: 'Variety' },
      { default: getGradeName, header: 'Grade' },
      {
        key: 'address.address', header: 'Address', map: {
          name: 'address.address',
          lat: 'address.latitude',
          lng: 'address.longitude'
        }
      }
    ];
    return (
      <GenericTable
        columns={columns}
        items={this.props.items}
        editColumnClass="xsmall"
        orderBy="siteName"
        optionsItems={[{ key: 'activate', text: 'Activate' }]}
        handleOptionClick={(index, key, id, item) => this.props.handleOptionClick(key, item)}
        displayIDColumn='name'
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.companies.storages.archivedItems
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStorages: (farmId) => dispatch(getStorages(farmId, receiveArchivedStorages, '?no_stocks&archived')),
    getCompany: (companyId) => dispatch(getCompanyDetails(companyId)),
    handleOptionClick: (key, item) => {
      if (key === 'activate')
        dispatch(activateStorage(item))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedStorages);
