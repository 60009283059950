import React from 'react';

import { connect } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import '../common/subTab.scss';
import { getCompanyDetails, showSideDrawerForTransfer } from '../../actions/companies/index';
import {
  receiveCompanySites,
  clickAddCompanySiteButton,
  cancelEditCompanySite
} from '../../actions/companies/company-sites';
import { setHeaderText, setBreadcrumbs } from '../../actions/main';
import { isSystemCompany } from '../../common/utils';
import { isEqual, get } from 'lodash';
import CompanySitesListing from './CompanySitesListing';
import ApprovedBuyers from '../farms/ApprovedBuyers';
import { getCompanyFarmsWeb } from '../../actions/api/farms';

class CompanySites extends React.Component {
  constructor(props) {
    super(props);
    this.companyId = this.props.companyId;
    this.COMPANY_SITES_LISTING = 'site';
    this.ARCHIVED_COMPANY_SITES_LISTING = 'archived-site';
    this.APPROVED_BUYER = 'approved-buyers';
    this.state = {
      value: 'site',
    };
  }

  componentDidMount() {
    this.setBreadcrumbs();
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    if (dispatch) {
      dispatch(receiveCompanySites([]));
    }
  }

  componentDidUpdate() {
    if (this.props.selectedCompany) {
      if (this.props.headerText !== this.props.selectedCompany.name) {
        this.props.setHeaderText(this.props.selectedCompany.name);
      }
      this.setBreadcrumbs();
    }
  }

  setBreadcrumbs() {
    if (this.props.selectedCompany) {
      const breadcrumbs = [
        { text: 'Companies', route: '/companies' },
        { text: this.props.selectedCompany.name, onClick: this.props.onDetailsClick, route: '/companies/' + this.props.selectedCompany.id + '/details' },
        { text: `Sites (${this.props.count})` }
      ];
      if (!isEqual(this.props.breadcrumbs, breadcrumbs)) {
        this.props.setBreadcrumbs(breadcrumbs);
      }
    }
  }
  onTabChanges = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { value } = this.state;
    return (
      <div>
        {this.props.isFromCompanySetting && (this.props.companyId == this.props.user.companyId  || isSystemCompany()) ?
          <div className="subTab">
            <Tabs className="subTab-header" value={value} onChange={this.onTabChanges}>
              <Tab label="Sites" value={this.COMPANY_SITES_LISTING} className={value !== this.COMPANY_SITES_LISTING ? 'unselected-subtab' : ''} />
              <Tab label="Archived Sites" value={this.ARCHIVED_COMPANY_SITES_LISTING} className={value !== this.ARCHIVED_COMPANY_SITES_LISTING ? 'unselected-subtab' : ''} />
              <Tab label="Approved Buyers" value={this.APPROVED_BUYER} className={value !== this.APPROVED_BUYER ? 'unselected-subtab' : ''} />
            </Tabs>
            {
              value === this.COMPANY_SITES_LISTING && <CompanySitesListing {...this.props} isArchived={false} />
            }
            {
              value === this.ARCHIVED_COMPANY_SITES_LISTING && <CompanySitesListing {...this.props} isArchived={true} />
            }
            {
              value === this.APPROVED_BUYER && <ApprovedBuyers {...this.props} />
            }
          </div> :
          <CompanySitesListing {...this.props} />}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.main.user.user,
    openSideDrawer: state.companies.companies.showTransferSideDrawer,
    isCreateFormDisplayed: state.companies.companySites.isCreateFormDisplayed,
    currentUserCompanyId: state.main.user.user.companyId,
    selectedCompany: state.companies.companies.selectedCompany,
    isUpdateFormDisplayed: state.companies.companySites.isUpdateFormDisplayed,
    headerText: state.main.headerText,
    breadcrumbs: state.main.breadcrumbs,
    count: get(state.companies.companySites, 'items.length', 0),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    cancelTransferDrawer: () => dispatch(showSideDrawerForTransfer(false)),
    getCompanySites: (companyId) => dispatch(getCompanyFarmsWeb(companyId, null, null, true, true, true)),
    getCompany: (companyId) => dispatch(getCompanyDetails(companyId)),
    handleAddCompanySiteButtonClick: () => dispatch(clickAddCompanySiteButton()),
    setHeaderText: (text) => dispatch(setHeaderText(text)),
    setBreadcrumbs: (breadcrumbs) => dispatch(setBreadcrumbs(breadcrumbs)),
    cancelEditCompanySite: () => dispatch(cancelEditCompanySite()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanySites);
