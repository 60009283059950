import React from 'react';

import RightTick from "../common/icons/RightTick";
import CommonButton from "../common/CommonButton";
import {BLACK} from "../../common/constants";
import { AppContext } from '../main/LayoutContext';

class SignUpSuccess extends React.Component {
  static contextType = AppContext

  constructor(props) {
    super(props);

  }

  render() {
    const { isMobileDevice } = this.context

    return (
      <div className='sign-up-success-tick'>
        <div>
          <RightTick width="110px" height="110px" strokeWidth="8px"/>
        </div>
        <div className='sign-up-success-heading'>
          <span>{this.props.headerText}</span>
        </div>
        <div className='sign-up-success-text' style={ this.props.isMiniature ? { margin: '20px 55px'}: { margin: '19px 20%'}}>
          <span style={{ color: (this.props.contentColor || BLACK), fontSize: '20px'}}>
            {this.props.tonnage ? (
              <span>
                You have <strong>{this.props.tonnage} MT</strong> of stock recorded on AgriChain.
                <br />
                <br />
              </span>
            ) : null}
            {this.props.contentText}
          </span>
        </div>
        <div>
          {this.props.isUnRegGrowerSignup ?
          <React.Fragment>
            {
              this.props.tonnage && !isMobileDevice ?
                <CommonButton
                  type="submit"
                  label="SEE MY STOCKS"
                  variant="outlined"
                  primary={true}
                  className="login-button"
                  onClick={this.props.loginToStocks}
                  style={{
                    height: '36px',
                    borderRadius: '4px',
                    marginRight: '10px',
                    marginTop: '60px',
                    width: '220px'
                  }}
                /> : null
            }
            <CommonButton
              type="submit"
              label={isMobileDevice ? "Let's Get Started" : "TAKE A GUIDED TOUR"}
              variant="outlined"
              primary={true}
              className="login-button"
              onClick={this.props.login}
              style={{
                height: '36px',
                borderRadius: '4px',
                marginTop: this.props.tonnage ? '60px' : '0px',
                width: '220px'
              }}
            />
          </React.Fragment> :
          <CommonButton
            type="submit"
            label="Login"
            variant="contained"
            primary={true}
            className="login-button"
            onClick={this.props.onClick}
            style={{
              height: '36px',
              borderRadius: '4px',
              marginTop: '30px',
              width: '220px'
            }}
          />}
        </div>
      </div>
    );
  }
}

export default SignUpSuccess;
