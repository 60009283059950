import React from 'react';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import APIService from '../../services/APIService';
import TextField from '@mui/material/TextField';
import { Tooltip, Autocomplete, Checkbox } from '@mui/material';
import CommonDatePicker from './CommonDatePicker';
import CommonButton from './CommonButton';
import { has, get, isEmpty, isEqual, intersectionWith, intersection, uniqBy, cloneDeep, map, includes, find, filter, startCase, forEach, pick, compact } from 'lodash';
import { ListboxComponent } from './ListboxComponent';
import { currentUser, getDateTimeInUTC, getSeasons, getCurrentCountry, getCountryFormats } from '../../common/utils';
import moment from 'moment';
import { LOAD_TYPE_OPTIONS, PICKUP_REQUEST_ORDER_TYPE_ID, DELIVERY_REQUEST_ORDER_TYPE_ID } from '../../common/constants';
import { INLOAD, OUTLOAD } from '../freights/MovementDetails/Constants';
import TruckAsyncAutocomplete from './autocomplete/TruckAsyncAutocomplete';
import SiteAsyncAutocomplete from './autocomplete/SiteAsyncAutocomplete';
import CompanyAutocomplete from './autocomplete/CompanyAutocomplete';
import PreDefinedDateRangeSelector from './PreDefinedDateRangeSelector';

let Commodity = props => {
  const [commodity, setCommodity] = useState([]);
  const [listGrades, setGrades] = useState([]);

  let fetchData = (data = []) => {
    let commodities = data.map(val => {
      let listGrades = val.grades.map(val2 => {
        return { name: val2.name, parent: val.id, id: val2.id };
      });
      return { name: val.displayName, listGrades: listGrades, id: val.id };
    });
    setCommodity(commodities);
  };

  const handleGradeChange = (event, items) => {
    props.setSelectedGrade(items);
  };

  const handleChange = (event, item) => {
    props.setSelectedCommodity(item);
  };

  const selectedInputGrades = selectedCommodities => {
    let grades = [];
    selectedCommodities.forEach(val => {
      grades = [...val.listGrades, ...grades];
    });
    let selectedGrade = intersection(grades, props.selectedGrade);
    setGrades(grades);
    props.setSelectedGrade(selectedGrade);
  };

  useEffect(() => {
    selectedInputGrades(props.selectedCommodities);
  }, [props.selectedCommodities]);

  useEffect(() => {
    fetchData(props.data);
  }, [props.data]);

  return (
    <div className='cardForm-content row col-xs-12 padding-reset'>
      <div className='col-xs-6'>
        <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}> Commodities </h4>
        <Autocomplete
          multiple
          disableCloseOnSelect
          id='commodity.AutoComplete'
          options={commodity || []}
          getOptionLabel={option => option.name}
          renderInput={params => <TextField {...params} variant='outlined' fullWidth />}
          value={intersectionWith(commodity, props.selectedCommodities, isEqual)}
          onChange={handleChange}
        />
      </div>

      <div className='col-xs-6'>
        <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}>Grades</h4>
        <Autocomplete
          multiple
          disableCloseOnSelect
          id='listGrades.AutoComplete'
          options={listGrades || []}
          getOptionLabel={option => option.name}
          renderInput={params => <TextField {...params} variant='outlined' fullWidth placeholder={isEmpty(listGrades) ? 'Select Commodities' : ''} />}
          onChange={handleGradeChange}
          value={intersectionWith(listGrades, props.selectedGrade, isEqual)}
        />
      </div>
    </div>
  );
};

let PrimaryBusinessType = props => {
  const [companyTypes, setCompanyTypes] = useState([]);

  let fetchData = async () => {
    let companyTypes = await APIService.companies().types().get(props.token)
    const types = companyTypes.map(val => ({
      name: val.displayName,
      id: val.id
    }));
    setCompanyTypes(types)
    let existing_filter = map(props.filters?.primary_business__in, val => ({ name: find(types, {id: val})?.name, id: val }));
    props.setSelectedPrimaryBusiness([...props.selectedPrimaryBusiness, ...existing_filter])
  };

  const handleChange = (_, item) => props.setSelectedPrimaryBusiness(item);

  useEffect(() => {
    fetchData()
  }, []);

  return (
    <div className='cardForm-content row'>
      <div className='col-md-12'>
        <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}> Company Type </h4>
        <Autocomplete
          multiple
          disableCloseOnSelect
          id='companyType.AutoComplete'
          options={companyTypes || []}
          getOptionLabel={option => option.name}
          renderInput={params => <TextField {...params} variant='outlined' fullWidth />}
          value={intersectionWith(companyTypes, props.selectedPrimaryBusiness, isEqual)}
          onChange={handleChange}
        />
      </div>

    </div>)

}
let ApprovedBuyers = props => {

  let initialSetup = () => {
    props.setSelectedApprovedBuyers(get(props.filters, 'approved_buyers'))
  }

  const handleChange = (event, item) => {
    props.setSelectedApprovedBuyers(item);
  };

  useEffect(() => {
    initialSetup();
  }, []);

  return (
    <div className='cardForm-content row'>
      <div className='col-md-12' style={{display: 'flex', alignItems: 'center', marginTop: '20px'}}>
        <Checkbox
          checked={props.selectedApprovedBuyers}
          onChange={handleChange}
          size='medium'
          disabled={!props.enableTransferToApprovedBuyers}
        />
        <h4 style={{ margin: '10px 0px', fontWeight: 'normal', color: props.enableTransferToApprovedBuyers ? 'inherit' : 'grey' }}> Approved Buyers </h4>
      </div>

    </div>)

}

let SiteWithStorages = props => {
  const handleChange = (event, items) => {
    if (!isEqual(items, props.selectedSite))
      fetchStorages(items)

    props.setSelectedSite(items);
  };

  const fetchStorages = items => {
    if (isEmpty(items)) {
      props.setStorages([])
    } else {
      const farmQueryString = map(filter(compact(items), item => Boolean(item?.id)), obj => `farm_ids=${obj.id}`).join('&');
      if(farmQueryString)
        APIService.farms().appendToUrl(`linked-storages-fields/?${farmQueryString}`).get().then(
          res => props.setStorages([...res['storages'], ...res['fields']])
        );
    }
  }

  const handleStorageChange = (event, items) => props.setSelectedStorage(items);

  useEffect(() => {
    if (isEmpty(props.selectedSite))
      handleStorageChange(null, []);
    else
      fetchStorages(props.selectedSite)
  }, [props.selectedSite]);

  return (
    <div className='cardForm-content row col-xs-12 padding-reset'>
      <div className='col-xs-6'>
        <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}> {props.name} </h4>
        <SiteAsyncAutocomplete
          multiple
          limitTags={2}
          label={props.name}
          id={props.name}
          onChange={items => handleChange(null, items)}
          selected={props.selectedSite}
          minLength={3}
        />
      </div>
      <div className='col-xs-6'>
        <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}>Storages</h4>
        <Autocomplete
          multiple
          disableCloseOnSelect
          id={props.name + 'storage'}
          options={props.storages || []}
          getOptionLabel={option => option.name}
          renderInput={params => <TextField {...params} variant='outlined' fullWidth placeholder={isEmpty(props.storages) ? 'Select Site' : ''} />}
          onChange={handleStorageChange}
          value={intersectionWith(props.storages, props.selectedStorage, (a, b) => a.id === b.id && a.entity === b.entity)}
        />
      </div>
    </div>
  );
};

let CompanyWithNgr = props => {
  const handleChange = (event, items) => {
    if (!isEqual(items, props.selectedCompany))
      fetchNgrs(items)
    props.setSelectedStockOwner(items);
  };


  const fetchNgrs = items => {
    if (isEmpty(items))
      props.setNgrs([]);
    else {
      const companyQueryString = map(items, obj => `company_ids=${obj.id}`).join('&');
      APIService.ngrs().appendToUrl(`?${companyQueryString}`).get().then(
        res => props.setNgrs([...res])
      );
    }
  }

  const handleNgrChange = (event, items) => props.setSelectedNgrs(items);

  useEffect(() => {
    if (isEmpty(props.selectedStockOwner))
      handleNgrChange(null, []);
    else
      fetchNgrs(props.selectedStockOwner)
  }, [props.selectedStockOwner]);

  return (
    <div className='cardForm-content row col-xs-12 padding-reset'>
      <div className='col-xs-6'>
        <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}>
          {props.name}
        </h4>
        <CompanyAutocomplete
          multiple
          disableCloseOnSelect
          id={props.name}
          label={props.name}
          getOptionLabel={option => option.name}
          selected={props.selectedStockOwner}
          onChange={items => handleChange(null, items)}
          urlPath={currentUser()?.id ? 'directory/names/' : 'names/'}
          queryParams={currentUser()?.id ? {include_self: true, excludeGroups: true} : {include_self: true}}
          minLength={3}
          limitTags={3}
        />
      </div>
      <div className='col-xs-6'>
        <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}>NGR</h4>
        <Autocomplete
          multiple
          disableCloseOnSelect
          id={props.name + 'ngr'}
          options={props.ngrs || []}
          getOptionLabel={option => option.ngrNumber}
          renderInput={params => <TextField {...params} variant='outlined' fullWidth placeholder={isEmpty(props.ngrs) ? (props.ngrPlaceholder || 'Select Stock Owner') : ''} />}
          onChange={handleNgrChange}
          value={intersectionWith(props.ngrs, props.selectedNgrs, (a, b) => a.id === b.id)}
        />
      </div>
    </div>
  );
};

let Status = props => {
  const [status] = useState(props.statuses);
  const handleChange = (event, items) => {
    props.setSelectedStatus(items);
  };

  return (
    <div>
      <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}>{props.label}</h4>
      <Autocomplete
        multiple
        disableCloseOnSelect
        id={props.id}
        options={status || []}
        getOptionLabel={option => option[1]}
        renderInput={params => <TextField {...params} variant='outlined' fullWidth />}
        value={intersectionWith(status, props.selectedStatus, isEqual)}
        onChange={handleChange}
      />
    </div>
  );
};

let PricePoint = props => {
  const handleChange = (event, items) => {
    props.setSelectedPricePoint(items);
  };

  return (
    <div>
      <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}> Price Point </h4>
      <Autocomplete
        multiple
        disableCloseOnSelect
        id='PricePoints.AutoComplete'
        options={props.data || []}
        getOptionLabel={option => option.displayName}
        renderInput={params => <TextField {...params} variant='outlined' fullWidth />}
        value={intersectionWith(props.data, props.selectedPricePoint, isEqual)}
        onChange={handleChange}
      />
    </div>
  );
};

let Invoicing = props => {
  const [invoicing] = useState(props.invoicing);
  const handleChange = (event, items) => {
    props.setSelectedInvoicing(items);
  };

  return (
    <div>
      <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}> Invoicing </h4>
      <Autocomplete
        multiple
        disableCloseOnSelect
        id='invoicing.AutoComplete'
        options={invoicing || []}
        getOptionLabel={option => option[1]}
        renderInput={params => <TextField {...params} variant='outlined' fullWidth />}
        value={intersectionWith(invoicing, props.selectedInvoicing, isEqual)}
        onChange={handleChange}
      />
    </div>
  );
};

let Company = props => {
  return (
    <div>
      <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}> {props.name} </h4>
      <CompanyAutocomplete
        multiple
        disableCloseOnSelect
        id={props.name}
        label={props.name}
        getOptionLabel={option => option.name}
        selected={props.selectedCompany}
        onChange={props.setSelectedCompany}
        urlPath={props.allCompanies ? 'minimal/all/' : (currentUser()?.id ? 'directory/names/' : 'names/')}
        queryParams={(!props.allCompanies && currentUser()?.id) ? {include_self: true, excludeGroups: true} : {include_self: true}}
        minLength={3}
        limitTags={3}
      />
    </div>
  );
};

let Rego = props => {
  return (
    <div>
      <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}> {props.name} </h4>
      <TruckAsyncAutocomplete
        multiple
        limitTags={2}
        label={props.name}
        id={props.name}
        onChange={props.setSelectedRego}
        selected={props.selectedRego}
        minLength={3}
        excludeFleet
      />
    </div>
  );
};

let DeliveryDate = props => {
  return (
    <div style={props.style ? props.style : {}}>
      <CommonDatePicker
        id={props.name}
        floatingLabelText={props.name}
        onChange={val => props.setDeliveryDate(val)}
        value={props.deliveryDate}
        variant="outlined"
        minDate={moment().subtract(5, 'years').format(getCountryFormats().date)}
        maxDate={moment().add(5, 'years').format(getCountryFormats().date)}
      />
    </div>
  );
};

let LoadDate = props => {
  const minDate = props.minDate || moment().subtract(5, 'years').format(getCountryFormats().date);
  const maxDate = props.maxDate || moment().add(5, 'years').format(getCountryFormats().date);

  return (
    <div style={props.style ? props.style : {}}>
      <CommonDatePicker
        id={props.name}
        floatingLabelText={props.name}
        onChange={val => props.setLoadDate(val)}
        value={props.loadDate}
        variant="outlined"
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  );
};

let LoadType = props => {
  const handleTypeChange = (event, items) => props.setSelectedLoadTypes(items);

  return (
    <div>
      <Autocomplete
        multiple
        disableCloseOnSelect
        ListboxComponent={ListboxComponent}
        id="loadType.AutoComplete"
        options={props.data || []}
        getOptionLabel={option => option.name}
        renderInput={params => <TextField {...params} variant='outlined' fullWidth />}
        value={intersectionWith(props.data, props.selectedLoadTypes, isEqual)}
        onChange={handleTypeChange}
      />
    </div>
  )
}

let LoadSubtype = props => {
  const handleSubTypeChange = (event, items) => props.setSelectedLoadSubTypes(items);

  return (
    <div>
      <Autocomplete
        multiple
        disableCloseOnSelect
        ListboxComponent={ListboxComponent}
        id="loadSubType.AutoComplete"
        options={props.data || []}
        getOptionLabel={option => option}
        renderInput={params => <TextField {...params} variant='outlined' fullWidth />}
        value={intersectionWith(props.data, props.selectedLoadSubTypes, isEqual)}
        onChange={handleSubTypeChange}
      />
    </div>
  )
}

let TransferDate = props => {
  return (
    <div style={props.style ? props.style : {}}>
      <CommonDatePicker id={props.name} floatingLabelText={props.name} onChange={props.setTransferDateChange} value={props.value} variant="outlined" />
    </div>
  );
};

let Site = props => {
  return (
    <div>
      <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}> {props.name} </h4>
      <SiteAsyncAutocomplete
        multiple
        limitTags={2}
        label={props.name}
        id={props.name}
        onChange={props.setSelectedSite}
        selected={props.selectedSite}
        minLength={3}
      />
    </div>
  );
};

let Season = props => {
  const [seasons] = useState(getSeasons());
  const handleChange = (event, items) => {
    props.setSelectedSeason(items);
  };

  return (
    <div>
      <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}> Season </h4>
      <Autocomplete
        multiple
        disableCloseOnSelect
        id='season.AutoComplete'
        options={seasons || []}
        getOptionLabel={option => option}
        renderInput={params => <TextField {...params} variant='outlined' fullWidth />}
        value={props.selectedSeason}
        onChange={handleChange}
      />
    </div>
  );
};

let CommoditiesWithoutGrade = props => {
  const [commodity, setCommodity] = useState([]);

  const handleChange = (event, item) => {
    props.setSelectedCommodity(item);
  };

  let fetchData = (data = []) => {
    let commodities = data.map(val => {
      return { name: val.displayName, id: val.id };
    });
    setCommodity(commodities);
  };

  useEffect(() => {
    fetchData(props.data);
  }, [props.data]);

  return (
    <div>
      <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}> Commodity </h4>
      <Autocomplete
        multiple
        disableCloseOnSelect
        id='commodity.AutoComplete'
        options={commodity || []}
        getOptionLabel={option => option.name}
        renderInput={params => <TextField {...params} variant='outlined' fullWidth />}
        value={props.selectedCommodities}
        onChange={handleChange}
      />
    </div>);
};

let Type = props => {
  const handleChange = (event, item) => {
    props.setSelectedType(item);
  };

  return (
    <div>
      <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}> Type </h4>
      <Autocomplete
        multiple
        disableCloseOnSelect
        id='type.AutoComplete'
        options={props.data || []}
        getOptionLabel={option => option.name}
        renderInput={params => <TextField {...params} variant='outlined' fullWidth />}
        value={props.selectedType}
        onChange={handleChange}
      />
    </div>);
};

let Track = props => {
  const [tracks] = useState(props.tracks);
  const handleChange = (event, items) => {
    props.setSelectedTrack(items);
  };

  return (
    <div className='col-xs-6 no-left-padding'>
      <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}>Port</h4>
      <Autocomplete
        multiple
        disableCloseOnSelect
        id='track.AutoComplete'
        options={tracks || []}
        getOptionLabel={option => option.name}
        renderInput={params => <TextField {...params} variant='outlined' fullWidth />}
        value={intersectionWith(tracks, props.selectedTrack, isEqual)}
        onChange={handleChange}
      />
    </div>
  );
};

let RequestOrderTypes = props => {
  const orderTypes = [{id: PICKUP_REQUEST_ORDER_TYPE_ID, name: 'Pickup Order'}, {id: DELIVERY_REQUEST_ORDER_TYPE_ID, name: 'Delivery Order'}];
  const handleChange = (event, items) => props.setRequestOrderType(items);
  return (
    <div>
      <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}>Order Type</h4>
      <Autocomplete
        multiple
        disableCloseOnSelect
        ListboxComponent={ListboxComponent}
        id='orderType.AutoComplete'
        options={orderTypes || []}
        getOptionLabel={option => option.name}
        onChange={handleChange}
        renderInput={params => <TextField {...params} variant='outlined' fullWidth />}
        value={intersectionWith(orderTypes, props.requestOrderType, isEqual)}
      />
    </div>
  );
};

const Filters = props => {
  const fmDateTypeOptions = [
    {id: 'freight_pickup_date', name: 'Planned Pickup'},
    {id: 'freight_delivery_date', name: 'Planned Delivery'},
    {id: 'outload_date', name: 'Actual Pickup'},
    {id: 'inload_date', name: 'Actual Delivery'}
  ]
  let token = useSelector(state => {
    return state.main.user.token;
  });
  const [filtersStruct] = useState(cloneDeep(props.filterValues));
  const [selectedCommodities, setSelectedCommodity] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [invoicedForStatus, setInvoicedForStatus] = useState([]);
  const [selectedPricePoint, setSelectedPricePoint] = useState([]);
  const [selectedInvoicing, setSelectedInvoicing] = useState([]);
  const [selectedSeller, setSelectedSeller] = useState([]);
  const [selectedBuyer, setSelectedBuyer] = useState([]);
  const [sellerNgrs, setSellerNgrs] = useState([]);
  const [buyerNgrs, setBuyerNgrs] = useState([]);
  const [selectedSellerNgrs, setSelectedSellerNgrs] = useState([]);
  const [selectedBuyerNgrs, setSelectedBuyerNgrs] = useState([]);
  const [deliveryStartDate, setDeliveryStartDate] = useState('');
  const [deliveryEndDate, setDeliveryEndDate] = useState('');
  const [transferStartDate, setTransferStartDate] = useState('');
  const [transferEndDate, setTransferEndDate] = useState('');
  const [afterDate, setAfterDate] = useState('');
  const [beforeDate, setBeforeDate] = useState('');
  const [selectedSeason, setSelectedSeason] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [selectedFreightProvider, setSelectedFreightProvider] = useState([]);
  const [selectedPickupsite, setSelectedPickupsite] = useState([]);
  const [selectedDeliverysite, setSelectedDeliverysite] = useState([]);
  const [selectedPackSite, setSelectedPackSite] = useState([]);
  const [selectedPlannedRego, setselectedPlannedRego] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [selectedTrack, setSelectedTrack] = useState(false);
  const [apiData, setApiData] = useState({});
  const [minPlannedPickupDate, setMinPlannedPickupDate] = useState('');
  const [maxPlannedPickupDate, setMaxPlannedPickupDate] = useState('');
  const [minLoadCreationDate, setMinLoadCreationDate] = useState('');
  const [maxLoadCreationDate, setMaxLoadCreationDate] = useState('');
  const [minDeliverDate, setMinDeliverDate] = useState('');
  const [maxDeliverDate, setMaxDeliverDate] = useState('');
  const [minPackDate, setMinPackDate] = useState('');
  const [maxPackDate, setMaxPackDate] = useState('');
  const [minCustomDate, setMinCustomDate] = useState('');
  const [maxCustomDate, setMaxCustomDate] = useState('');
  const loadSubTypeData = [startCase(INLOAD), startCase(OUTLOAD)];
  const [selectedLoadSubTypes, setSelectedLoadSubTypes] = useState([]);
  const [selectedStockOwners, setSelectedStockOwners] = useState([]);
  const [selectedLoadTypes, setSelectedLoadTypes] = useState([]);
  const [selectedSiteLoadNgrs, setSelectedSiteLoadsNgr] = useState([]);
  const [customerNgrs, setCustomerNgrs] = useState([]);
  const [selectedShippingLine, setSelectedShippingLine] = useState([]);

  const loadTypeData = LOAD_TYPE_OPTIONS;
  const [minPlannedDeliveryDate, setMinPlannedDeliveryDate] = useState('');
  const [maxPlannedDeliveryDate, setMaxPlannedDeliveryDate] = useState('');
  const [minPickupLoadDate, setMinPickupLoadDate] = useState('');
  const [maxPickupLoadDate, setMaxPickupLoadDate] = useState('');
  const [minDeliveryLoadDate, setMinDeliveryLoadDate] = useState('');
  const [maxDeliveryLoadDate, setMaxDeliveryLoadDate] = useState('');
  const [fmDateTypeFilters, setFmDateTypeFilters] = useState([]);
  const [fmDateRangeFilters, setFmDateRangeFilters] = useState('');
  const [fmDateTypeFieldErrors, setFmDateTypeFieldErrors] = useState('');
  const [pickupStorages, setPickupStorages] = useState([]);
  const [deliveryStorages, setDeliveryStorages] = useState([]);
  const [selectedPickupStorage, setSelectedPickupStorage] = useState([]);
  const [selectedDeliveryStorage, setSelectedDeliveryStorage] = useState([]);
  const [selectedPickupStockOwner, setSelectedPickupStockOwner] = useState([]);
  const [selectedPickupNgrs, setSelectedPickupNgrs] = useState([]);
  const [selectedPackMovementNgrs, setSelectedPackMovementNgrs] = useState([]);
  const [pickupNgrs, setPickupNgrs] = useState([]);
  const [packNgrs, setPackNgrs] = useState([]);
  const [selectedDeliveryStockOwner, setSelectedDeliveryStockOwner] = useState([]);
  const [selectedDeliveryNgrs, setSelectedDeliveryNgrs] = useState([]);
  const [deliveryNgrs, setDeliveryNgrs] = useState([]);

  const [requestOrderType, setRequestOrderType] = useState([]);
  const [selectedPrimaryBusiness, setSelectedPrimaryBusiness] = useState([]);
  const [selectedApprovedBuyers, setSelectedApprovedBuyers] = useState(false);

  const [predefinedDateRangeFilters, setPredefinedDateRangeFilters] = useState({
    packByDateRange: '',
    deliverByDateRange: '',
    inloadDateRange: '',
    outloadDateRange: '',
    freightDeliveryDateRange: '',
    freightPickupDateRange: '',
    processOnDateRange: '',
    loadDateRange: '',
    updatedAtDateRange: '',
    deliveryEndDateRange: '',
    deliveryStartDateRange: '',
  });

  const combineFiltersAndSave = () => {
    if (props.isFMFilters && ((!isEmpty(fmDateTypeFilters) && isEmpty(fmDateRangeFilters)) || (isEmpty(fmDateTypeFilters) && !isEmpty(fmDateRangeFilters))))
      return;
    let filters = cloneDeep(props.filterValues);
    selectedCommodities.forEach(val => {
      filters.commodity__id__in.push(val.id);
    });

    if (get(props.filterValues, 'grade__id__in', false)) {
      selectedGrade.forEach(val => {
        filters.grade__id__in.push(val.id);
      });
    } else if (get(props.filterValues, 'freight_pickup__loads_set__grade__id__in', false)) {
      selectedGrade.forEach(val => {
        filters.planned_grade__id__in.push(val.id);
      });
    }
    else {
      selectedGrade.forEach(val => {
        filters.planned_grade__id__in.push(val.id);
      });
    }

    selectedStatus.forEach(val => {
      if (val[0] == 'completed') {
        filters.status__in.push(val[0]);
        filters.status__in.push('manual_contract_complete_balanced');
      }
      else { filters.status__in.push(val[0]); }
    });
    invoicedForStatus.forEach(val => {
        filters.invoiced_for.push(val[0]);
    });

    if (get(props.filterValues, 'price_point__id__in', false)) {
      selectedPricePoint.forEach(val => {
        filters.price_point__id__in.push(val.id);
      });
    }

    if (get(props.filterValues, 'invoicing__in', false)) {
      selectedInvoicing.forEach(val => {
        filters.invoicing__in.push(val[0]);
      });
    } else if (get(props.filterValues, 'administration__invoicing__in', false)) {
      selectedInvoicing.forEach(val => {
        filters.administration__invoicing__in.push(val[0]);
      });
    }

    {
      if (get(props.filterValues, 'seller__company__id__in', false)) {
        selectedSeller.forEach(val => {
          filters.seller__company__id__in.push(val.id);
        });
      }
      if (get(props.filterValues, 'buyer__company__id__in', false)) {
        if(props.loggedOut)
          filters.buyer__company__id__in = [...selectedBuyer]
        else
          selectedBuyer.forEach(val => {
            filters.buyer__company__id__in.push(val.id);
          });
      }

      if (selectedSeller && get(props.filterValues, 'seller__ngr__id__in', false))
        selectedSellerNgrs.forEach(val => filters.seller__ngr__id__in.push(val.id));

      if (selectedBuyer && get(props.filterValues, 'buyer__ngr__id__in', false))
        selectedBuyerNgrs.forEach(val => filters.buyer__ngr__id__in.push(val.id));

      if (get(props.filterValues, 'customer__company__id__in', false) && !props.isPMFilters) {
        selectedCustomer.forEach(val => {
          filters.customer__company__id__in.push(val.id);
        });
      }
      if (get(props.filterValues, 'provider__id__in', false)) {
          selectedFreightProvider.forEach(val => {
            filters.provider__id__in.push(val.id);
          });
        }
      if (get(props.filterValues, 'commodity_contract__seller__company__id__in', false)) {
        selectedSeller.forEach(val => {
          filters.commodity_contract__seller__company__id__in.push(val.id);
        });

        selectedBuyer.forEach(val => {
          filters.commodity_contract__buyer__company__id__in.push(val.id);
        });
      }
    }

    if (get(props.filterValues, 'for_company__id__in', false)) {
      selectedCustomer.forEach(val => {
        filters.for_company__id__in.push(val.id);
      });
    }

    if (has(props.filterValues, 'freight_shipping__shipping_line__id__in', false))
      selectedShippingLine.forEach(val => filters.freight_shipping__shipping_line__id__in.push(val.id));

    if (has(props.filterValues, 'freight_container__consignor__handler__id__in', false))
      selectedPackSite.forEach(val => filters.freight_container__consignor__handler__id__in.push(val.id));

    selectedSeason.forEach(val => {
      filters.season__in.push(val);
    });

    if (props.isPMFilters || get(props.filterValues, 'freight_delivery__consignee__handler__id__in', false)) {
      filters.freight_delivery__consignee__handler__id__in = filters.freight_delivery__consignee__handler__id__in || []
      selectedDeliverysite.forEach(val => {
        filters.freight_delivery__consignee__handler__id__in.push(val.id);
      });
    }

    if (props.isPMFilters || get(props.filterValues, 'freight_pickup__consignor__handler__id__in', false)) {
      filters.freight_pickup__consignor__handler__id__in = filters.freight_pickup__consignor__handler__id__in || []
      selectedPickupsite.forEach(val => {
        filters.freight_pickup__consignor__handler__id__in.push(val.id);
      });
    }

    if (get(props.filterValues, 'consignee__handler__id__in', false)) {
      selectedDeliverysite.forEach(val => {
        filters.consignee__handler__id__in.push(val.id);
      });
    }

    if (get(props.filterValues, 'consignor__handler__id__in', false)) {
      selectedPickupsite.forEach(val => {
        filters.consignor__handler__id__in.push(val.id);
      });
    }

    if (get(props.filterValues, 'site_id', false)) {
      selectedPickupsite.forEach(val => {
        if (get(filters, `site_id[${props.companyId}]`))
            get(filters, `site_id[${props.companyId}]`).push(val.id);
        else
          filters['site_id'][`${props.companyId}`] = [val.id];
      });
    }


    if (get(props.filterValues, 'planned_truck__id__in', false)) {
      selectedPlannedRego.forEach(val => {
        filters.planned_truck__id__in.push(val.id);
      });
    }

    if(props.isTitleTransferFilters) {
      filters.process_on__gte = get(predefinedDateRangeFilters, 'processOnDateRange') === 'custom' ? (transferStartDate || '') : '';
      filters.process_on__lte = get(predefinedDateRangeFilters, 'processOnDateRange') === 'custom' ? (transferEndDate || '') : '';
    }

    if (has(props.filterValues, 'delivery_start_date__gte')) {
      filters.delivery_start_date__gte = get(predefinedDateRangeFilters, 'deliveryStartDateRange') === 'custom' ? (deliveryStartDate || '') : '';
      filters.delivery_end_date__lte = get(predefinedDateRangeFilters, 'deliveryEndDateRange') === 'custom'  ? (deliveryEndDate || '') : '';
    }
    else if (has(props.filterValues, 'freight_delivery__date_time__lte') && has(props.filterValues, 'freight_pickup__date_time__gte') && !has(props.filterValues, 'outload__date_time__gte')) {
      filters.freight_delivery__date_time__lte = get(predefinedDateRangeFilters, 'deliveryEndDateRange') === 'custom' ? (deliveryEndDate ? get(getDateTimeInUTC(deliveryEndDate, '23:59:59'), 'dateTime') : deliveryEndDate) : '';
      filters.freight_pickup__date_time__gte = get(predefinedDateRangeFilters, 'deliveryStartDateRange') === 'custom' ? (deliveryStartDate ? get(getDateTimeInUTC(deliveryStartDate, '00:00:00'), 'dateTime') : deliveryStartDate) : '';
    }

    if (has(props.filterValues, 'freight_pickup__date_time__gte') && has(props.filterValues, 'freight_pickup__date_time__lte')) {
      let startDate = '';
      let endDate = '';
      if (get(predefinedDateRangeFilters, 'freightPickupDateRange') === 'custom') {
        startDate = minPlannedPickupDate ? get(getDateTimeInUTC(minPlannedPickupDate, '00:00:00'), 'dateTime') : minPlannedPickupDate;
        endDate = maxPlannedPickupDate ? get(getDateTimeInUTC(maxPlannedPickupDate, '23:59:59'), 'dateTime') : maxPlannedPickupDate;
      }
      filters.freight_pickup__date_time__gte = startDate || '';
      filters.freight_pickup__date_time__lte =  endDate || '';
    }

    if (has(props.filterValues, 'freight_delivery__date_time__gte')) {
      let startDate = '';
      let endDate = '';
      if (get(predefinedDateRangeFilters, 'freightDeliveryDateRange') === 'custom') {
        startDate = minPlannedDeliveryDate ? get(getDateTimeInUTC(minPlannedDeliveryDate, '00:00:00'), 'dateTime') : minPlannedDeliveryDate;
        endDate = maxPlannedDeliveryDate ? get(getDateTimeInUTC(maxPlannedDeliveryDate, '23:59:59'), 'dateTime') : maxPlannedDeliveryDate;
      }
      filters.freight_delivery__date_time__gte = startDate || '';
      filters.freight_delivery__date_time__lte = endDate || '';
    }

    if (has(props.filterValues, 'outload__date_time__gte')) {
      let startDate = '';
      let endDate = '';
      if (get(predefinedDateRangeFilters, 'outloadDateRange') === 'custom') {
        startDate = minPickupLoadDate ? get(getDateTimeInUTC(minPickupLoadDate, '00:00:00'), 'dateTime') : minPickupLoadDate;
        endDate = maxPickupLoadDate ? get(getDateTimeInUTC(maxPickupLoadDate, '23:59:59'), 'dateTime') : maxPickupLoadDate;
      }
      filters.outload__date_time__gte = startDate || '';
      filters.outload__date_time__lte =  endDate || '';
    }

    if (has(props.filterValues, 'inload__date_time__gte')) {
      let startDate = '';
      let endDate = '';
      if (get(predefinedDateRangeFilters, 'inloadDateRange') === 'custom') {
        startDate = minDeliveryLoadDate ? get(getDateTimeInUTC(minDeliveryLoadDate, '00:00:00'), 'dateTime') : minDeliveryLoadDate;
        endDate = maxDeliveryLoadDate ? get(getDateTimeInUTC(maxDeliveryLoadDate, '23:59:59'), 'dateTime') : maxDeliveryLoadDate;
      }
      filters.inload__date_time__gte = startDate || '';
      filters.inload__date_time__lte = endDate || '';
    }

    if (has(props.filterValues, 'updated_at__gte')) {
      let startDate = '';
      let endDate = '';
      if (get(predefinedDateRangeFilters, 'updatedAtDateRange') === 'custom') {
        startDate = afterDate ? get(getDateTimeInUTC(afterDate, '00:00:00'), 'dateTime') : '';
        endDate = beforeDate ? get(getDateTimeInUTC(beforeDate, '23:59:59'), 'dateTime') : '';
      }
      filters.updated_at__gte = startDate || '';
      filters.updated_at__lte = endDate || '';
    }
    if (has(props.filterValues, 'type__in')) {
      selectedType.forEach(val => {
        filters.type__in.push(val.id);
      });
    }
    if (has(props.filterValues, 'track__in')) {
      forEach(selectedTrack, val => {
        filters.track__in.push(val.name);
      });
    }

    if (get(props.filterValues, 'site__id__in', false)) {
      if(props.loggedOut)
        filters.site__id__in = [...selectedPickupsite]
      else
        selectedPickupsite.forEach(val => {
          filters.site__id__in.push(val.id);
        });
    }

    if (has(props.filterValues, 'freight_pickup__loads_set__ngr__company_id__in')) {
      selectedPickupStockOwner.forEach(val => {
        filters.freight_pickup__loads_set__ngr__company_id__in.push(val.id);
      });
    }
    if (has(props.filterValues, 'customer__ngr__in')) {
      selectedCustomer.forEach(val => {
        filters.customer__company__id__in.push(val.id);
      });
    }
    if (has(props.filterValues, 'freight_delivery__loads_set__ngr__company_id__in')) {
      selectedDeliveryStockOwner.forEach(val => {
        filters.freight_delivery__loads_set__ngr__company_id__in.push(val.id);
      });
    }

    if (has(props.filterValues, 'freight_delivery__loads_set__ngr_id__in')) {
      selectedDeliveryNgrs.forEach(val => {
        filters.freight_delivery__loads_set__ngr_id__in.push(val.id);
      });
    }

    if (has(props.filterValues, 'freight_pickup__loads_set__ngr_id__in')) {
      selectedPickupNgrs.forEach(val => {
        filters.freight_pickup__loads_set__ngr_id__in.push(val.id);
      });
    }
    if (has(props.filterValues, 'customer__ngr__in')) {
      selectedPackMovementNgrs.forEach(val => {
        filters.customer__ngr__in.push(val.id);
      });
    }
    if (has(props.filterValues, 'freight_delivery__loads_set__storage_id__in')) {
      selectedDeliveryStorage.forEach(val => {
        if(val.entity == 'storage')
          filters.freight_delivery__loads_set__storage_id__in.push(val.id);
      });
    }
    if (has(props.filterValues, 'freight_pickup__loads_set__storage_id__in')) {
      selectedPickupStorage.forEach(val => {
        if(val.entity == 'storage')
        filters.freight_pickup__loads_set__storage_id__in.push(val.id);
      });
    }
    if (has(props.filterValues, 'freight_delivery__loads_set__farm_field_id__in')) {
      selectedDeliveryStorage.forEach(val => {
        if(val.entity == 'farmfield')
        filters.freight_delivery__loads_set__farm_field_id__in.push(val.id);
      });
    }
    if (has(props.filterValues, 'freight_pickup__loads_set__farm_field_id__in')) {
      selectedPickupStorage.forEach(val => {
        if(val.entity == 'farmfield')
        filters.freight_pickup__loads_set__farm_field_id__in.push(val.id);
      });
    }
    if(has(props.filterValues, 'created_at__gte')) {
      filters.created_at__gte = minLoadCreationDate ? get(getDateTimeInUTC(minLoadCreationDate, '00:00:00'), 'dateTime') : '';
    }
    if(has(props.filterValues, 'created_at__lte')) {
      filters.created_at__lte = maxLoadCreationDate ? get(getDateTimeInUTC(maxLoadCreationDate, '23:59:59'), 'dateTime') : '';
    }
    if(has(props.filterValues, 'load_type__in')) {
      filters.type__in = selectedLoadSubTypes.map(element => element.toLowerCase());
    }
    if(has(props.filterValues, 'load_source_type__in')) {
      filters.load_source_type__in = map(selectedLoadTypes, 'id');
    }
    if(has(props.filterValues, 'stock_owner__in')) {
      filters.stock_owner__in = map(selectedStockOwners, 'id');
    }
    if(has(props.filterValues, 'ngr__ngr_number__in')) {
      filters.ngr__ngr_number__in = map(selectedSiteLoadNgrs, ngr => ngr?.ngrNumber || ngr?.name);
    }
    if(has(props.filterValues, 'type_id__in')) {
      filters.type_id__in = map(requestOrderType, 'id');
    }
    if(has(props.filterValues, 'pack__load__date_time__gte')) {
      filters.pack__load__date_time__gte = get(predefinedDateRangeFilters, 'packByDateRange') === 'custom' ? (minPackDate ? get(getDateTimeInUTC(minPackDate, '00:00:00'), 'dateTime') : '') : '';
    }
    if(has(props.filterValues, 'pack__load__date_time__lte')){
      filters.pack__load__date_time__lte = get(predefinedDateRangeFilters, 'packByDateRange') === 'custom' ? (maxPackDate ? get(getDateTimeInUTC(maxPackDate, '23:59:59'), 'dateTime') : '') : '';
    }
    if(has(props.filterValues, 'freight_container__pack_by_date__gte'))
      filters.freight_container__pack_by_date__gte = get(predefinedDateRangeFilters, 'packByDateRange') === 'custom' ? (minPackDate ? moment.utc(minPackDate).local().format('YYYY-MM-DD') : '') : '';
    if(has(props.filterValues, 'freight_container__pack_by_date__lte'))
      filters.freight_container__pack_by_date__lte = get(predefinedDateRangeFilters, 'packByDateRange') === 'custom' ? (maxPackDate ? moment.utc(maxPackDate).local().format('YYYY-MM-DD') : '') : '';

    if(has(props.filterValues, 'freight_container__deliver_by_date__gte'))
      filters.freight_container__deliver_by_date__gte = get(predefinedDateRangeFilters, 'deliverByDateRange') === 'custom' ? (minDeliverDate ? moment.utc(minDeliverDate).local().format('YYYY-MM-DD') : '') : '';
    if(has(props.filterValues, 'freight_container__deliver_by_date__lte'))
      filters.freight_container__deliver_by_date__lte = get(predefinedDateRangeFilters, 'deliverByDateRange') === 'custom' ? (maxDeliverDate ? moment.utc(maxDeliverDate).local().format('YYYY-MM-DD') : '') : '';

    if (has(props.filterValues, 'primary_business__in')) {
      selectedPrimaryBusiness.forEach(val => {
        filters.primary_business__in.push(val.id);
      });
    }
    if (has(props.filterValues, 'group__in'))
      filters.group__in=get(props.filterValues, 'group__in')

    if (has(props.filterValues, 'approved_buyers'))
      filters.approved_buyers = selectedApprovedBuyers

    if (has(props.filterValues, 'delivery_start_date_range'))
      filters.delivery_start_date_range = get(predefinedDateRangeFilters, 'deliveryStartDateRange') || '';
    if (has(props.filterValues, 'delivery_end_date_range'))
      filters.delivery_end_date_range = get(predefinedDateRangeFilters, 'deliveryEndDateRange') || '';
    if (has(props.filterValues, 'updated_at_date_range'))
      filters.updated_at_date_range = get(predefinedDateRangeFilters, 'updatedAtDateRange') || '';
    if (has(props.filterValues, 'load_date_range'))
      filters.load_date_range = get(predefinedDateRangeFilters, 'loadDateRange') || '';
    if (has(props.filterValues, 'process_on_date_range'))
      filters.process_on_date_range = get(predefinedDateRangeFilters, 'processOnDateRange') || '';
    if (has(props.filterValues, 'freight_pickup_date_range'))
      filters.freight_pickup_date_range = get(predefinedDateRangeFilters, 'freightPickupDateRange') || '';
    if (has(props.filterValues, 'freight_delivery_date_range'))
      filters.freight_delivery_date_range = get(predefinedDateRangeFilters, 'freightDeliveryDateRange') || '';
    if (has(props.filterValues, 'outload_date_range'))
      filters.outload_date_range = get(predefinedDateRangeFilters, 'outloadDateRange') || '';
    if (has(props.filterValues, 'inload_date_range'))
      filters.inload_date_range = get(predefinedDateRangeFilters, 'inloadDateRange') || '';
    if (has(props.filterValues, 'pack_by_date_range'))
      filters.pack_by_date_range = get(predefinedDateRangeFilters, 'packByDateRange') || '';
    if (has(props.filterValues, 'deliver_by_date_range'))
      filters.deliver_by_date_range = get(predefinedDateRangeFilters, 'deliverByDateRange') || '';

    if (has(props.filterValues, 'date_type_filters'))
      filters.date_type_filters = fmDateTypeFilters.map(val => val.id) ;
    if (has(props.filterValues, 'date_range_filters'))
      filters.date_range_filters = fmDateRangeFilters;

    if (has(props.filterValues, 'min_custom_date') && fmDateRangeFilters == 'custom' && minCustomDate)
      filters.min_custom_date = get(getDateTimeInUTC(minCustomDate, '00:00:00'), 'dateTime');
    if (has(props.filterValues, 'max_custom_date') && fmDateRangeFilters == 'custom' && maxCustomDate)
      filters.max_custom_date = get(getDateTimeInUTC(maxCustomDate, '23:59:59'), 'dateTime');
    props.handleFilterState('filters', filters);
    props.handleFilterState('applyFilters', false);
    props.handleFilterState('openSideDrawer', false);
  };

  const transformFromFilters = async filters => {
    filters = isEmpty(filters) ? filtersStruct : filters;

    const callback = async () => {
      setSelectedStockOwners(filters.stock_owner__in || []);
      setSelectedBuyer(filters.buyer__company__id__in || filters.commodity_contract__buyer__company__id__in || []);
      setSelectedSeller(filters.seller__company__id__in || filters.commodity_contract__seller__company__id__in || []);
      setSelectedCustomer(filters.customer__company__id__in || filters.for_company_id__in || []);
      setSelectedFreightProvider(filters.provider__id__in || []);
      setSelectedPickupsite(filters.freight_pickup__consignor__handler__id__in || filters?.consignor__handler__id__in || filters?.site__id__in || []);
      setSelectedDeliverysite(filters.freight_delivery__consignee__handler__id__in || filters?.consignee__handler__id__in || []);
      setSelectedPickupStorage([...(filters.freight_pickup__loads_set__storage_id__in || []), ...(filters.freight_pickup__loads_set__farm_field_id__in || [])]);
      setSelectedDeliveryStorage([...(filters.freight_delivery__loads_set__storage_id__in || []), ...(filters.freight_delivery__loads_set__farm_field_id__in || [])]);
      setSelectedPackMovementNgrs(filters.customer__ngr__in || []);
      setSelectedPickupStockOwner(filters.freight_pickup__loads_set__ngr__company_id__in || [])
      setSelectedPickupNgrs(filters.freight_pickup__loads_set__ngr_id__in || [])
      setSelectedDeliveryStockOwner(filters.freight_delivery__loads_set__ngr__company_id__in)
      setSelectedDeliveryNgrs(filters.freight_delivery__loads_set__ngr_id__in || [])
      setselectedPlannedRego(filters.planned_truck__id__in || [])

      if (get(filters, 'commodity__id__in', []) || get(filters, 'grade__id__in', []) || get(props.filterValues, 'planned_grade__id__in', [])) {
        let commodityData = await APIService.commodities().get(token, null, { includes: 'grade', simplify_names: true, countryId: getCurrentCountry().id });
        let transformedFilter = [];
        let grades = [];
        commodityData.map(val => {
          let listGrades = val.grades.map(val2 => {
            let gradeJson = { name: val2.name, parent: val.id, id: val2.id };
            if (get(filters, 'grade__id__in', false)) {
              if (filters.grade__id__in.includes(val2.id)) grades.push(gradeJson);
              return gradeJson;
            } else if (get(filters, 'planned_grade__id__in', false)) {
              if (filters.planned_grade__id__in.includes(val2.id)) grades.push(gradeJson);
              return gradeJson;
            }
          });
          if (get(filters, 'commodity__id__in', false)) {
            if (filters.commodity__id__in.includes(val.id))
              transformedFilter.push({ name: val.displayName, listGrades: listGrades, id: val.id });
          }
        });
        setSelectedGrade(grades);
        setSelectedCommodity(transformedFilter);
        apiData.commodity = commodityData;
        setApiData(apiData);
      }

      if (props.isContractFilters && filters.seller__company__id__in) {
        let companyIds = compact(map(filters.seller__company__id__in, val => val.id));
        setSellerCompanyNgrs(companyIds)
      }

      if (props.isContractFilters && filters.buyer__company__id__in) {
        let companyIds = compact(map(filters.buyer__company__id__in, val => val.id));
        setBuyerCompanyNgrs(companyIds)
      }
      if(props.isPackOrderFilter && filters?.freight_shipping__shipping_line__id__in)
        setSelectedShippingLine(filters.freight_shipping__shipping_line__id__in)

      if(props.isPackOrderFilter && filters?.freight_container__consignor__handler__id__in)
        setSelectedPackSite(filters.freight_container__consignor__handler__id__in)

      if(get(filters, 'created_at__gte', '')) {
        setMinLoadCreationDate( moment.utc(get(filters, 'created_at__gte')).local().format('YYYY-MM-DD') );
      }
      if(get(filters, 'created_at__lte', '')) {
        setMaxLoadCreationDate(moment.utc(get(filters, 'created_at__lte')).local().format('YYYY-MM-DD'));
      }
      if(get(filters, 'type__in', false)) {
        let loadSubTypes = get(filters, 'type__in').map(element => startCase(element));
        setSelectedLoadSubTypes(loadSubTypes);
      }
      if(get(filters, 'load_source_type__in', false)) {
        let sourceTypes =  get(filters, 'load_source_type__in');
        let filteredSourceTypes = filter(LOAD_TYPE_OPTIONS, item => includes(sourceTypes, item.id));
        setSelectedLoadTypes(filteredSourceTypes);
      }
      if(get(filters, 'ngr__ngr_number__in', false)) {
        setSelectedSiteLoadsNgr(filters.ngr__ngr_number__in || []);
      }
      if(get(filters, 'invoiced_for', false))
        setInvoicedForStatus(filters.invoiced_for || []);
      if (
        !isEmpty(get(filters, 'status__in', [])) ||
        !isEmpty(get(filters, 'administration__invoicing__in', [])) ||
        !isEmpty(get(filters, 'invoicing__in', [])) ||
        !isEmpty(get(filters, 'invoiced_for', []))
      ) {
        let transformedStatus = [];
        let transformedInvoicing = [];
        let transformedInvoicedForStatus = [];

        props.statusTemp.forEach(val => {
          filters.status__in.includes(val[0]) ? transformedStatus.push(val) : '';
        });
        forEach(props.invoicedStatuses, val => {
          filters.invoiced_for.includes(val[0]) ? transformedInvoicedForStatus.push(val) : '';
        });

        if (get(props.filterValues, 'invoicing__in', false)) {
          props.invoicingTemp.forEach(val => {
            filters.invoicing__in.includes(val[0]) ? transformedInvoicing.push(val) : '';
          });
        } else if (get(props.filterValues, 'administration__invoicing__in', false)) {
          props.invoicingTemp.forEach(val => {
            filters.administration__invoicing__in.includes(val[0]) ? transformedInvoicing.push(val) : '';
          });
        }

        setSelectedInvoicing(transformedInvoicing);
        setSelectedStatus(transformedStatus);
        setInvoicedForStatus(transformedInvoicedForStatus);
      }

      if (!isEmpty(get(filters, 'type__in', []))) {
        let transformedType = [];
        if (get(props.filterValues, 'type__in', false)) {
          props.type_options.forEach(val => {
            filters.type__in.includes(val.id) ? transformedType.push(val) : '';
          });
        }
        setSelectedType(transformedType);
      }

      if (get(props.filterValues, 'price_point__id__in', false)) {
        if (get(filters, 'price_point__id__in', [])) {
          let transformedPricePoint = [];
          let pricePointData = await APIService.contracts()
          ['price-points']()
            .get(token);
          pricePointData.forEach(val => {
            filters.price_point__id__in.includes(val.id) ? transformedPricePoint.push(val) : '';
          });
          setSelectedPricePoint(transformedPricePoint);
          apiData.price_point = pricePointData;
          setApiData(apiData);
        }
      }

      if (
        get(props.filterValues, 'site_id', false)
      ) {
        if (get(filters, 'site_id', [])) {
          let selectedPickupsiteTemp = [];
          let siteData = props.sites;

          siteData = uniqBy([...siteData], 'id');
          siteData.forEach(val => {
            if (get(filters, `site_id[${props.companyId}]`))
              get(filters, `site_id[${props.companyId}]`).includes(val.id) ? selectedPickupsiteTemp.push(val) : '';
          });
          setSelectedPickupsite(selectedPickupsiteTemp);
          let newApiData = {...apiData}
          newApiData.site = siteData;
          setApiData(newApiData);
        }
      }

      if (has(filters, 'delivery_start_date__gte') || has(filters, 'delivery_end_date__lte')) {
        setDeliveryEndDate(filters.delivery_end_date__lte);
        setDeliveryStartDate(filters.delivery_start_date__gte);
      }

      if(has(filters, 'process_on__gte') || has(filters, 'process_on__gte') ) {
        setTransferStartDate(filters.process_on__gte);
        setTransferEndDate(filters.process_on__lte);
      }

      if (has(filters, 'freight_pickup__date_time__gte') && !props.isFMFilters) {
        let startDateTime = moment.utc(get(filters, 'freight_pickup__date_time__gte', '')).local().format('YYYY-MM-DD');
        let endDateTime = moment.utc(get(filters, 'freight_delivery__date_time__lte', '')).local().format('YYYY-MM-DD');
        if (!moment(startDateTime).isValid())
          startDateTime = '';
        if (!moment(endDateTime).isValid())
          endDateTime = '';
        setDeliveryEndDate(endDateTime);
        setDeliveryStartDate(startDateTime);
      }

      if (has(filters, 'freight_pickup__date_time__gte')) {
        let minDateTime = get(filters, 'freight_pickup__date_time__gte', '') ? moment.utc(get(filters, 'freight_pickup__date_time__gte', '')).local().format('YYYY-MM-DD') : '';
        if (!moment(minDateTime).isValid())
          minDateTime = '';
        setMinPlannedPickupDate(minDateTime);
      }

      if (has(filters, 'freight_pickup__date_time__lte')) {
        let dateTime = get(filters, 'freight_pickup__date_time__lte', '') ? moment.utc(get(filters, 'freight_pickup__date_time__lte', '')).local().format('YYYY-MM-DD') : '';
        if (!moment(dateTime).isValid())
        dateTime = '';
        setMaxPlannedPickupDate(dateTime);
      }

      if (has(filters, 'freight_delivery__date_time__gte')) {
        let minDateTime = get(filters, 'freight_delivery__date_time__gte', '') ? moment.utc(get(filters, 'freight_delivery__date_time__gte', '')).local().format('YYYY-MM-DD') : '';
        if (!moment(minDateTime).isValid())
          minDateTime = '';
        setMinPlannedDeliveryDate(minDateTime);
      }

      if (has(filters, 'freight_delivery__date_time__lte')) {
        let maxDateTime = get(filters, 'freight_delivery__date_time__lte', '') ? moment.utc(get(filters, 'freight_delivery__date_time__lte', '')).local().format('YYYY-MM-DD') : '';
        if (!moment(maxDateTime).isValid())
          maxDateTime = '';
        setMaxPlannedDeliveryDate(maxDateTime);
      }

      if (has(filters, 'outload__date_time__gte')) {
        let minDateTime = get(filters, 'outload__date_time__gte', '') ? moment.utc(get(filters, 'outload__date_time__gte', '')).local().format('YYYY-MM-DD') : '';
        if (!moment(minDateTime).isValid())
          minDateTime = '';
        setMinPickupLoadDate(minDateTime);
      }


      if (has(filters, 'outload__date_time__lte')) {
        let maxDateTime = get(filters, 'outload__date_time__lte', '') ? moment.utc(get(filters, 'outload__date_time__lte', '')).local().format('YYYY-MM-DD') : '';
        if (!moment(maxDateTime).isValid())
          maxDateTime = '';
        setMaxPickupLoadDate(maxDateTime);
      }

      if (has(filters, 'inload__date_time__gte')) {
        let minDateTime = get(filters, 'inload__date_time__gte', '') ? moment.utc(get(filters, 'inload__date_time__gte', '')).local().format('YYYY-MM-DD') : '';
        if (!moment(minDateTime).isValid())
          minDateTime = '';
        setMinDeliveryLoadDate(minDateTime);
      }

      if (has(filters, 'inload__date_time__lte')) {
        let maxDateTime = get(filters, 'inload__date_time__lte', '') ? moment.utc(get(filters, 'inload__date_time__lte', '')).local().format('YYYY-MM-DD') : '';
        if (!moment(maxDateTime).isValid())
          maxDateTime = '';
        setMaxDeliveryLoadDate(maxDateTime);
      }


      if (has(filters, 'updated_at__gte')) {
        let minDateTime = get(filters, 'updated_at__gte', '') ? moment.utc(get(filters, 'updated_at__gte', '')).local().format('YYYY-MM-DD') : '';
        if (!moment(minDateTime).isValid())
          minDateTime = '';
        setAfterDate(minDateTime);
      }
      if (has(filters, 'updated_at__lte')) {
        let maxDateTime = get(filters, 'updated_at__lte', '') ? moment.utc(get(filters, 'updated_at__lte', '')).local().format('YYYY-MM-DD') : '';
        if (!moment(maxDateTime).isValid())
          maxDateTime = '';
        setBeforeDate(maxDateTime);
      }
      if (has(filters, 'pack__load__date_time__gte')) {
        let minDateTime = get(filters, 'pack__load__date_time__gte', '') ? moment.utc(get(filters, 'pack__load__date_time__gte', '')).local().format('YYYY-MM-DD') : '';
        if (!moment(minDateTime).isValid())
          minDateTime = '';
        setMinPackDate(minDateTime);
      }
      if (has(filters, 'pack__load__date_time__lte')) {
        let maxDateTime = get(filters, 'pack__load__date_time__lte', '') ? moment.utc(get(filters, 'pack__load__date_time__lte', '')).local().format('YYYY-MM-DD') : '';
        if (!moment(maxDateTime).isValid())
          maxDateTime = '';
        setMaxPackDate(maxDateTime);
      }
      if (has(filters, 'freight_container__pack_by_date__gte')) {
        let minDateTime = get(filters, 'freight_container__pack_by_date__gte', '') ? moment.utc(get(filters, 'freight_container__pack_by_date__gte', '')).local().format('YYYY-MM-DD') : '';
        if (!moment(minDateTime).isValid())
          minDateTime = '';
        setMinPackDate(minDateTime);
      }
      if (has(filters, 'freight_container__pack_by_date__lte')) {
        let maxDateTime = get(filters, 'freight_container__pack_by_date__lte', '') ? moment.utc(get(filters, 'freight_container__pack_by_date__lte', '')).local().format('YYYY-MM-DD') : '';
        if (!moment(maxDateTime).isValid())
          maxDateTime = '';
        setMaxPackDate(maxDateTime);
      }
      if (has(filters, 'freight_container__deliver_by_date__gte')) {
        let minDateTime = get(filters, 'freight_container__deliver_by_date__gte', '') ? moment.utc(get(filters, 'freight_container__deliver_by_date__gte', '')).local().format('YYYY-MM-DD') : '';
        if (!moment(minDateTime).isValid())
          minDateTime = '';
        setMinDeliverDate(minDateTime);
      }
      if (has(filters, 'freight_container__deliver_by_date__lte')) {
        let maxDateTime = get(filters, 'freight_container__deliver_by_date__lte', '') ? moment.utc(get(filters, 'freight_container__deliver_by_date__lte', '')).local().format('YYYY-MM-DD') : '';
        if (!moment(maxDateTime).isValid())
          maxDateTime = '';
        setMaxDeliverDate(maxDateTime);
      }
      if (has(filters, 'season__in')) {
        setSelectedSeason(filters.season__in);
      }

      if (has(filters, 'track__in')) {
        let selectedTrack = [];
        filters.track__in.forEach(val => {
          selectedTrack.push({ id: val, name: val });
        });
        setSelectedTrack(selectedTrack);
      }

      if (get(props.filterValues, 'type_id__in', false)) {
        const orderTypes = [{id: PICKUP_REQUEST_ORDER_TYPE_ID, name: 'Pickup Order'}, {id: DELIVERY_REQUEST_ORDER_TYPE_ID, name: 'Delivery Order'}];
        let requestOrderTypes = []
        orderTypes.forEach(obj => {
          includes(filters.type_id__in, obj.id) ? requestOrderTypes.push(obj) : '';
        });
        setRequestOrderType(requestOrderTypes);
      }

      if (has(props.filterValues, 'delivery_start_date_range'))
        predefinedDateRangeFilters.deliveryStartDateRange = get(props.filters, 'delivery_start_date_range')
      if (has(props.filterValues, 'delivery_end_date_range'))
        predefinedDateRangeFilters.deliveryEndDateRange = get(props.filters, 'delivery_end_date_range')
      if (has(props.filterValues, 'updated_at_date_range'))
        predefinedDateRangeFilters.updatedAtDateRange = get(props.filters, 'updated_at_date_range')
      if (has(props.filterValues, 'load_date_range'))
        predefinedDateRangeFilters.loadDateRange = get(props.filters, 'load_date_range')
      if (has(props.filterValues, 'process_on_date_range'))
        predefinedDateRangeFilters.processOnDateRange = get(props.filters, 'process_on_date_range')
      if (has(props.filterValues, 'freight_pickup_date_range'))
        predefinedDateRangeFilters.freightPickupDateRange = get(props.filters, 'freight_pickup_date_range')
      if (has(props.filterValues, 'freight_delivery_date_range'))
        predefinedDateRangeFilters.freightDeliveryDateRange = get(props.filters, 'freight_delivery_date_range')
      if (has(props.filterValues, 'outload_date_range'))
        predefinedDateRangeFilters.outloadDateRange = get(props.filters, 'outload_date_range')
      if (has(props.filterValues, 'inload_date_range'))
        predefinedDateRangeFilters.inloadDateRange = get(props.filters, 'inload_date_range')
      if (has(props.filterValues, 'pack_by_date_range'))
        predefinedDateRangeFilters.packByDateRange = get(props.filters, 'pack_by_date_range')
      if (has(props.filterValues, 'deliver_by_date_range'))
        predefinedDateRangeFilters.deliverByDateRange = get(props.filters, 'deliver_by_date_range')
      setPredefinedDateRangeFilters({...predefinedDateRangeFilters})

      if (has(props.filterValues, 'date_type_filters'))
        setFmDateTypeFilters(filter(fmDateTypeOptions, option => includes(get(props.filters, 'date_type_filters', []), option.id)))
      if (has(props.filterValues, 'date_range_filters'))
        setFmDateRangeFilters(get(props.filters, 'date_range_filters', ''))

      if (has(props.filterValues, 'min_custom_date') && get(props.filters, 'date_range_filters', '') == 'custom') {
        let minCustomDate = get(props.filters, 'min_custom_date', '')
        let minDateTime = '';
        if (minCustomDate) {
          minDateTime = moment.utc(minCustomDate).local().format('YYYY-MM-DD');
          if (!moment(minDateTime).isValid())
            minDateTime = '';
        }
        setMinCustomDate(minDateTime)
      }
      if (has(props.filterValues, 'max_custom_date') && get(props.filters, 'date_range_filters', '') == 'custom') {
        let maxCustomDateTime = get(props.filters, 'max_custom_date', '')
        let maxDateTime = '';
        if (maxCustomDateTime) {
          maxDateTime = moment.utc(maxCustomDateTime).local().format('YYYY-MM-DD');
          if (!moment(maxDateTime).isValid())
            maxDateTime = '';
        }
        setMaxCustomDate(maxDateTime)
      }

      props.forceStopLoader();
    }

    callback()
  };

  const resetFilters = () => {
    props.handleFilterState('filters', filtersStruct);
    props.handleFilterState('applyFilters', false);
    props.handleFilterState('openSideDrawer', false);
  };

  const handleFmDateTypeFilterChange = (event, value) => {
    setFmDateTypeFilters(value)
    setFmDateTypeFieldErrors(isEmpty(value) && !isEmpty(fmDateRangeFilters) ? "Please select Date Type for the selected range" : '')
  }

  const handleFmDateRangeFilterChange = filters => {
    setFmDateRangeFilters(filters)
    setFmDateTypeFieldErrors(isEmpty(fmDateTypeFilters) && !isEmpty(filters) ? "Please select Date Type for the selected range" : '')
  }

  const handleSellerNgrsChange = (event, value) => setSelectedSellerNgrs(value)

  const handleBuyerNgrsChange = (event, value) => setSelectedBuyerNgrs(value)

  const setSellerCompanyNgrs = companyIds => {
    if(!companyIds?.length)
      return
    const companyQueryString = map(companyIds, id => `company_ids=${id}`).join('&');
    APIService.ngrs().appendToUrl(`?${companyQueryString}`).get().then(items => {
      let ngrs = map(items, item => pick(item, ['id', 'ngrType', 'ngrNumber', 'company.id']))
      setSellerNgrs(uniqBy(ngrs, 'id'));
    });
  }

  const setBuyerCompanyNgrs = companyIds => {
    if(!companyIds?.length)
      return
    const companyQueryString = map(companyIds, id => `company_ids=${id}`).join('&');
    APIService.ngrs().appendToUrl(`?${companyQueryString}`).get().then(items => {
      let ngrs = map(items, item => pick(item, ['id', 'ngrType', 'ngrNumber', 'company.id']))
      setBuyerNgrs(uniqBy(ngrs, 'id'));
    });
  }

  useEffect(() => {
    transformFromFilters(props.filters);
  }, [props.filters]);

  useEffect(() => {
    if(props.isContractFilters) {
      setSellerCompanyNgrs(compact(map(selectedSeller, seller => seller.id)))
      setBuyerCompanyNgrs(compact(map(selectedBuyer, buyer => buyer.id)))
    }
  }, [selectedSeller, selectedBuyer]);

  useEffect(() => {
    if (!isEmpty(sellerNgrs) && get(props.filters, 'seller__ngr__id__in', false))
      setSelectedSellerNgrs(filter(sellerNgrs, ngr => includes(props.filters.seller__ngr__id__in, ngr.id)))
    if (!isEmpty(buyerNgrs) && get(props.filters, 'buyer__ngr__id__in', false))
      setSelectedBuyerNgrs(filter(buyerNgrs, ngr => includes(props.filters.buyer__ngr__id__in, ngr.id)))
  }, [sellerNgrs, buyerNgrs]);

  return (
    <div className='col-xs-12 padding-reset'>
      <div className='cardForm cardForm--drawer col-xs-12 padding-reset'>
        {has(props.filterValues, 'site__id__in') ? (
          <div className='cardForm-content row col-xs-12 padding-reset'>
            <div className='col-xs-12'>
              <Site
                name='Site'
                token={token}
                selectedSite={selectedPickupsite}
                setSelectedSite={setSelectedPickupsite}
                data={apiData.site}
              />
            </div>
          </div>) : null
        }
        {get(props.filterValues, 'track__in') &&
         <div className='cardForm-content row col-xs-12 padding-reset'>
            <div className='col-xs-12'>
              <Track selectedTrack={selectedTrack} setSelectedTrack={setSelectedTrack} tracks={props.tracks} />
            </div>
          </div>}
        {(get(props.filterValues, 'buyer__company__id__in', false) || get(props.filterValues, 'commodity_contract__buyer__company__id__in', false)) &&
          (get(props.filterValues, 'seller__company__id__in', false) || get(props.filterValues, 'commodity_contract__seller__company__id__in', false)) ? (
          <React.Fragment>
            <div className='cardForm-content row col-xs-12 padding-reset'>
            <div className='col-xs-6'>
              <Company name='Seller' setSelectedCompany={setSelectedSeller} selectedCompany={selectedSeller} data={apiData.company} allCompanies={props.allCompanies} />
            </div>
            <div className='col-xs-6'>
              <Company name='Buyer' setSelectedCompany={setSelectedBuyer} selectedCompany={selectedBuyer} data={apiData.company} allCompanies={props.allCompanies} />
            </div>
          </div>
          {
            props.isContractFilters && has(props.filterValues, 'buyer__ngr__id__in') && has(props.filterValues, 'seller__ngr__id__in') &&
            <div className='cardForm-content row col-xs-12 padding-reset'>
              <div className='col-xs-6'>
                <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}>Seller NGR</h4>
                <Autocomplete
                  id='sellerNgrs'
                  variant='outlined'
                  multiple
                  disableCloseOnSelect
                  options={sellerNgrs || []}
                  getOptionLabel={option => option?.ngrNumber}
                  onChange={handleSellerNgrsChange}
                  renderInput={params => <TextField {...params} placeholder='Select Seller' variant='outlined'  />}
                  value={filter(sellerNgrs, item => includes(map(selectedSellerNgrs, ngr => ngr?.id), get(item, 'id'))) }
                />
              </div>
              <div className='col-xs-6'>
                <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}>Buyer NGR</h4>
                <Autocomplete
                  id='buyerNgrs'
                  variant='outlined'
                  multiple
                  disableCloseOnSelect
                  options={buyerNgrs || []}
                  getOptionLabel={option => option?.ngrNumber}
                  onChange={handleBuyerNgrsChange}
                  renderInput={params => <TextField {...params} placeholder='Select Buyer' variant='outlined'  />}
                  value={filter(buyerNgrs, item => includes(map(selectedBuyerNgrs, ngr => ngr?.id), get(item, 'id'))) }
                />
              </div>

            </div>
          }
        </React.Fragment>
        ) : (
          get(props.filterValues, 'customer__company__id__in') &&
            <div className='cardForm-content row col-xs-12 padding-reset'>
            <div className={props.isPMFilters ? 'col-xs-12' : 'col-xs-6'}>
              {props.isPMFilters ?
                <CompanyWithNgr
                  name='Customer'
                  token={token}
                  selectedStockOwner={selectedCustomer}
                  setSelectedStockOwner={setSelectedCustomer}
                  selectedNgrs={selectedPackMovementNgrs}
                  setSelectedNgrs={setSelectedPackMovementNgrs}
                  ngrs={packNgrs}
                  setNgrs={setPackNgrs}
                  data={apiData.company}
              /> :
                <Company name='Customer' setSelectedCompany={setSelectedCustomer} selectedCompany={selectedCustomer} data={apiData.company} />}
            </div>
            {!get(props, 'showOrderTypes') ? (
            !props.isPMFilters && (<div className='col-xs-6'>
              <Company
                name={props.isPackOrderFilter ? 'Shipping Line' : 'Freight Provider'}
                setSelectedCompany={props.isPackOrderFilter ? setSelectedShippingLine : setSelectedFreightProvider}
                selectedCompany={props.isPackOrderFilter ? selectedShippingLine : selectedFreightProvider}
                data={apiData.company}
              />
            </div>)) : (
            <div className='col-xs-6'>
              <RequestOrderTypes
                token={token}
                requestOrderType={requestOrderType}
                setRequestOrderType={setRequestOrderType}
              />
            </div>
            )
            }
          </div>
        )}
        {
          get(props.filterValues, 'buyer__company__id__in', false) && !get(props.filterValues, 'seller__company__id__in', false) &&
            <div className='cardForm-content row col-xs-12 padding-reset'>
            <div className='col-xs-12'>
              <Company name='Buyer' setSelectedCompany={setSelectedBuyer} selectedCompany={selectedBuyer} data={apiData.company} allCompanies={props.allCompanies}/>
            </div>
          </div>
        }
        {get(props.filterValues, 'status__in') &&
         <div className='cardForm-content row col-xs-12 padding-reset'>
            <div className={props.isContractFilters ? 'col-xs-6' : 'col-xs-12'}>
              <Status selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} statuses={props.statusTemp} label="Status" id="status.AutoComplete" />
            </div>
            { props.isContractFilters &&
            <div className='col-xs-6'>
              <Season setSelectedSeason={setSelectedSeason} selectedSeason={selectedSeason} />
            </div>
            }
          </div>}
        {get(props.filterValues, 'invoiced_for') &&
         <div className='cardForm-content row col-xs-12 padding-reset'>
            <div className='col-xs-12'>
              <Status selectedStatus={invoicedForStatus} setSelectedStatus={setInvoicedForStatus} statuses={props.invoicedStatuses} label="Invoiced For" id="invoicedStatus.AutoComplete"/>
            </div>
          </div>}
        {get(props.filterValues, 'commodity__id__in') && !get(props, 'commodityWithoutGrade') &&
          <Commodity
            token={token}
            setSelectedCommodity={setSelectedCommodity}
            selectedCommodities={selectedCommodities}
            selectedGrade={selectedGrade}
            setSelectedGrade={setSelectedGrade}
            data={apiData.commodity}
          />}


        {get(props.filterValues, 'commodity__id__in') && get(props, 'commodityWithoutGrade') &&
          <CommoditiesWithoutGrade
            token={token}
            setSelectedCommodity={setSelectedCommodity}
            selectedCommodities={selectedCommodities}
            data={apiData.commodity}
          />}
        {
          props.isCompanyFilters && get(props.filterValues, 'primary_business__in') &&
          <PrimaryBusinessType
            token={token}
            setSelectedPrimaryBusiness={setSelectedPrimaryBusiness}
            selectedPrimaryBusiness={selectedPrimaryBusiness}
            filters={props.filters}
          />
        }
          { props.isCompanyFilters && has(props.filterValues, 'approved_buyers') &&
            <ApprovedBuyers
              token={token}
              setSelectedApprovedBuyers={setSelectedApprovedBuyers}
              selectedApprovedBuyers={selectedApprovedBuyers}
              filters={props.filters}
              enableTransferToApprovedBuyers={props.enableTransferToApprovedBuyers}
          />}

        {get(props.filterValues, 'invoicing__in', false) ? (
          <div className='cardForm-content row col-xs-12 padding-reset'>
            <div className='col-xs-6'>
              <Season setSelectedSeason={setSelectedSeason} selectedSeason={selectedSeason} />
            </div>
            <div className='col-xs-6'>
              <Invoicing
                token={token}
                selectedInvoicing={selectedInvoicing}
                setSelectedInvoicing={setSelectedInvoicing}
                invoicing={props.invoicingTemp}
              />
            </div>
          </div>) : (
          get(props.filterValues, 'planned_truck__id__in', false) ? (
            <div className='cardForm-content row col-xs-12 padding-reset'>
              <div className='col-xs-6'>
                <Season setSelectedSeason={setSelectedSeason} selectedSeason={selectedSeason} />
              </div>
              <div className='col-xs-6'>
                <Rego
                  name='Rego'
                  token={token}
                  selectedRego={selectedPlannedRego}
                  setSelectedRego={setselectedPlannedRego}
                  data={apiData.rego} />
              </div>
            </div>) : (
            get(props.filterValues, 'season__in') && !props.isContractFilters && !props.isPackOrderFilter &&
                <div className='cardForm-content row col-xs-12 padding-reset'>
              <div className='col-xs-12'>
                <Season setSelectedSeason={setSelectedSeason} selectedSeason={selectedSeason} />
              </div>
            </div>))
        }
        {
          props.isPackOrderFilter &&
          <div className='cardForm-content row col-xs-12 padding-reset'>
              <div className='col-xs-6'>
                <Season setSelectedSeason={setSelectedSeason} selectedSeason={selectedSeason} />
              </div>
              <div className='col-xs-6'>
                <Site
                  name='Pack Site'
                  token={token}
                  selectedSite={selectedPackSite}
                  setSelectedSite={setSelectedPackSite}
                  data={apiData.site}
                />
            </div>
          </div>
        }

        <div className='cardForm-content row col-xs-12 padding-reset'>
          {get(props.filterValues, 'price_point__id__in', false) ? (
            <div className='col-xs-6'>
              <PricePoint selectedPricePoint={selectedPricePoint} setSelectedPricePoint={setSelectedPricePoint} data={apiData.price_point} />
            </div>
          ) : null}
          {
            get(props.filterValues, 'administration__invoicing__in', false) ? (
              <div className='col-xs-6'>
                <Invoicing
                  token={token}
                  selectedInvoicing={selectedInvoicing}
                  setSelectedInvoicing={setSelectedInvoicing}
                  invoicing={props.invoicingTemp}
                />
              </div>
            ) : null
          }
        </div>

        {
          get(props.filterValues, 'freight_delivery__consignee__handler__id__in', get(props.filterValues, 'consignee__handler__id__in', false)) && !props.isFMFilters ? (
            <div className='cardForm-content row col-xs-12 padding-reset'>
              {props.isPMFilters && <div className='col-xs-6'>
                <Company
                  name='Pack Provider'
                  setSelectedCompany={setSelectedFreightProvider}
                  selectedCompany={selectedFreightProvider}
                  data={apiData.company}
                />
              </div>}
              <div className='col-xs-6'>
                <Site
                  name={props.isPMFilters ? 'Pack Site' : 'Pickup Site'}
                  token={token}
                  selectedSite={selectedPickupsite}
                  setSelectedSite={setSelectedPickupsite}
                  data={apiData.site}
                />
              </div>

              {!props.isPMFilters ? (<div className='col-xs-6'>
                <Site
                  name='Delivery Site'
                  token={token}
                  selectedSite={selectedDeliverysite}
                  setSelectedSite={setSelectedDeliverysite}
                  data={apiData.site}
                />
              </div>): null}
            </div>
          ) : null
        }
        {
          props.isFMFilters ? (
              <>
                <SiteWithStorages
                  name='Pickup Site'
                  token={token}
                  selectedStorage={selectedPickupStorage}
                  setSelectedStorage={setSelectedPickupStorage}
                  selectedSite={selectedPickupsite}
                  setSelectedSite={setSelectedPickupsite}
                  data={apiData.site}
                  storages={pickupStorages}
                  setStorages={setPickupStorages}
                />

                <SiteWithStorages
                  name='Delivery Site'
                  token={token}
                  selectedSite={selectedDeliverysite}
                  setSelectedSite={setSelectedDeliverysite}
                  selectedStorage={selectedDeliveryStorage}
                  setSelectedStorage={setSelectedDeliveryStorage}
                  data={apiData.site}
                  storages={deliveryStorages}
                  setStorages={setDeliveryStorages}
                />
              </>
          ) : null
        }
        {
          props.isFMFilters ? (
              <>
                <CompanyWithNgr
                  name='Pickup Stock Owner'
                  token={token}
                  selectedStockOwner={selectedPickupStockOwner}
                  setSelectedStockOwner={setSelectedPickupStockOwner}
                  selectedNgrs={selectedPickupNgrs}
                  setSelectedNgrs={setSelectedPickupNgrs}
                  ngrs={pickupNgrs}
                  setNgrs={setPickupNgrs}
                  data={apiData.stockOwner}
                />
                <CompanyWithNgr
                  name='Delivery Stock Owner'
                  token={token}
                  selectedStockOwner={selectedDeliveryStockOwner}
                  setSelectedStockOwner={setSelectedDeliveryStockOwner}
                  selectedNgrs={selectedDeliveryNgrs}
                  setSelectedNgrs={setSelectedDeliveryNgrs}
                  ngrs={deliveryNgrs}
                  setNgrs={setDeliveryNgrs}
                  data={apiData.stockOwner}
                />
              </>
          ) : null
          }

        {
          has(props.filterValues, 'delivery_end_date__lte') || (has(props.filterValues, 'freight_delivery__date_time__lte') && !has(props.filterValues, 'outload__date_time__gte')) ?
            (<div className='cardForm-content row col-xs-12 padding-reset' style={{paddingTop: '10px', paddingBottom: '10px'}}>
              <div className='col-xs-12'>
                <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}>Delivery Date Range</h4>
              </div>
              <div className='col-xs-6' style={{paddingTop: '10px'}}>
                <PreDefinedDateRangeSelector id='deliveryStartDateRange' name='Delivery Start Date After' predefinedDateRangeFilters={predefinedDateRangeFilters} setDateRange={setPredefinedDateRangeFilters}/>
                { (get(predefinedDateRangeFilters, 'deliveryStartDateRange') === 'custom') &&
                  <DeliveryDate name={get(props.filters, 'planned_truck__id__in', false) ? 'Pickup Date After' : 'Delivery Start Date After'} deliveryDate={deliveryStartDate} setDeliveryDate={setDeliveryStartDate} style={{paddingTop: '15px'}} />
                }
              </div>
              <div className='col-xs-6' style={{paddingTop: '10px'}}>
                <PreDefinedDateRangeSelector id='deliveryEndDateRange' name="Delivery End Date Range" predefinedDateRangeFilters={predefinedDateRangeFilters} setDateRange={setPredefinedDateRangeFilters}/>
                { (get(predefinedDateRangeFilters, 'deliveryEndDateRange') === 'custom') &&
                  <DeliveryDate name={get(props.filters, 'planned_truck__id__in', false) ? 'Delivery Date Before' : 'Delivery End Date Before'} deliveryDate={deliveryEndDate} setDeliveryDate={setDeliveryEndDate} style={{paddingTop: '15px'}} />
                }
              </div>
            </div>) : null
        }
        {
          props.isPackOrderFilter &&
          <div className='cardForm-content row col-xs-12 padding-reset' style={{paddingTop: '15px', paddingBottom: '15px'}}>
          <div className="col-xs-12">
            <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}>Pack By Range</h4>
              <PreDefinedDateRangeSelector id='packByDateRange' predefinedDateRangeFilters={predefinedDateRangeFilters} setDateRange={setPredefinedDateRangeFilters}/>
          </div>
          {(get(predefinedDateRangeFilters, 'packByDateRange') === 'custom') &&
            <>
              <div className="col-xs-6">
                <LoadDate name='Min Pack By Date' loadDate={minPackDate} setLoadDate={setMinPackDate} style={{paddingTop: '15px'}}/>
              </div>
              <div className="col-xs-6">
                <LoadDate name='Max Pack By Date' loadDate={maxPackDate} setLoadDate={setMaxPackDate} style={{paddingTop: '15px'}}/>
              </div>
            </>
          }
          <div className="col-xs-12">
            <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}>Deliver By Range</h4>
              <PreDefinedDateRangeSelector id='deliverByDateRange' predefinedDateRangeFilters={predefinedDateRangeFilters} setDateRange={setPredefinedDateRangeFilters}/>
          </div>
          {(get(predefinedDateRangeFilters, 'deliverByDateRange') === 'custom') &&
            <>
              <div className="col-xs-6">
                <LoadDate name='Min Deliver By Date' loadDate={minDeliverDate} setLoadDate={setMinDeliverDate} style={{paddingTop: '15px'}}/>
              </div>
              <div className="col-xs-6">
                <LoadDate name='Max Deliver By Date' loadDate={maxDeliverDate} setLoadDate={setMaxDeliverDate} style={{paddingTop: '15px'}}/>
              </div>
            </>
          }
        </div>
        }
        {
          props.isSiteLoadsFilter && (
            <React.Fragment>
              <div className="cardForm-content row col-xs-12 padding-reset">
                <div className="col-xs-12 no-right-padding">
                  <CompanyWithNgr
                    name='Customer'
                    token={token}
                    selectedStockOwner={selectedStockOwners}
                    setSelectedStockOwner={setSelectedStockOwners}
                    selectedNgrs={selectedSiteLoadNgrs}
                    setSelectedNgrs={setSelectedSiteLoadsNgr}
                    ngrs={customerNgrs}
                    setNgrs={setCustomerNgrs}
                    ngrPlaceholder='Select Customer'
                  />
                </div>
                <div className="col-xs-6">
                  <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}> Type </h4>
                  <LoadType  name="load_type" data={loadTypeData} setSelectedLoadTypes={setSelectedLoadTypes} selectedLoadTypes={selectedLoadTypes} />
                </div>
                <div className="col-xs-6">
                  <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}> Sub Type </h4>
                  <LoadSubtype name="load_sub_type" data={loadSubTypeData} setSelectedLoadSubTypes={setSelectedLoadSubTypes} selectedLoadSubTypes={selectedLoadSubTypes} />
                </div>
              </div>
              <div className='cardForm-content row col-xs-12 padding-reset' style={{paddingTop: '15px', paddingBottom: '15px'}}>
                <div className="col-xs-12">
                  <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}>Load Date Range</h4>
                    <PreDefinedDateRangeSelector id='loadDateRange' predefinedDateRangeFilters={predefinedDateRangeFilters} setDateRange={setPredefinedDateRangeFilters}/>
                </div>
                {(get(predefinedDateRangeFilters, 'loadDateRange') === 'custom') &&
                  <>
                    <div className="col-xs-6">
                      <LoadDate name='Min Load Date' loadDate={minLoadCreationDate} setLoadDate={setMinLoadCreationDate} style={{paddingTop: '15px'}}/>
                    </div>
                    <div className="col-xs-6">
                      <LoadDate name='Max Load Date' loadDate={maxLoadCreationDate} setLoadDate={setMaxLoadCreationDate} style={{paddingTop: '15px'}}/>
                    </div>
                  </>
                }
              </div>
            </React.Fragment>
          )
        }
        {props.isFMFilters &&
          <div>
            <div className='cardForm-content row col-xs-12 padding-reset'>
              <div className='col-xs-6'>
                <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}>Date Type Filters</h4>
                <Autocomplete
                  id='dateType'
                  name='Date Type'
                  multiple
                  options={fmDateTypeOptions}
                  getOptionLabel={option => option.name}
                  renderInput={params =>
                     <TextField
                      {...params}
                      variant='outlined'
                      fullWidth
                      error ={isEmpty(fmDateTypeFilters) && !isEmpty(fmDateRangeFilters)}
                      helperText={fmDateTypeFieldErrors}
                    />}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id && value?.id}
                  value={fmDateTypeFilters}
                  onChange={handleFmDateTypeFilterChange}
                />
              </div>
              <div className='col-xs-6'>
                <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}>Date Ranges</h4>
                <PreDefinedDateRangeSelector id='fmDateRange' setDateRange={handleFmDateRangeFilterChange} isMandatory={!isEmpty(fmDateTypeFilters)} dateRangeFilters={fmDateRangeFilters}/>
              </div>
            </div>
            {
              fmDateRangeFilters == 'custom' &&
              <div className='cardForm-content row col-xs-12 padding-reset'>
                <div className='col-xs-6'>
                  <DeliveryDate name='Min Date' deliveryDate={minCustomDate} setDeliveryDate={setMinCustomDate} style={{paddingTop: '15px'}}/>
                </div>
                <div className='col-xs-6'>
                  <DeliveryDate name='Max Date' deliveryDate={maxCustomDate} setDeliveryDate={setMaxCustomDate} style={{paddingTop: '15px'}}/>
                </div>
              </div>
            }
            {
              has(props.filterValues, 'freight_pickup__date_time__gte') || has(props.filterValues, 'freight_pickup__date_time__lte') ?
                (<div className='cardForm-content row col-xs-12 padding-reset'>
                  <div className='col-xs-12'>
                    <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}>Planned Pickup Date Range</h4>
                      <PreDefinedDateRangeSelector id='freightPickupDateRange' predefinedDateRangeFilters={predefinedDateRangeFilters} setDateRange={setPredefinedDateRangeFilters}/>
                  </div>
                  {(get(predefinedDateRangeFilters, 'freightPickupDateRange') === 'custom') &&
                  <>
                    <div className='col-xs-6'>
                      <DeliveryDate name='Min Planned Pickup Date' deliveryDate={minPlannedPickupDate} setDeliveryDate={setMinPlannedPickupDate} style={{paddingTop: '15px'}}/>
                    </div>
                    <div className='col-xs-6'>
                      <DeliveryDate name='Max Planned Pickup Date' deliveryDate={maxPlannedPickupDate} setDeliveryDate={setMaxPlannedPickupDate} style={{paddingTop: '15px'}}/>
                    </div>
                  </>
                  }
                </div>) : null
            }
            {
              has(props.filterValues, 'freight_delivery__date_time__gte') || has(props.filterValues, 'freight_delivery__date_time__lte') ?
                (<div className='cardForm-content row col-xs-12 padding-reset'>
                  <div className='col-xs-12'>
                    <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}>Planned Delivery Date Range</h4>
                      <PreDefinedDateRangeSelector id='freightDeliveryDateRange' predefinedDateRangeFilters={predefinedDateRangeFilters} setDateRange={setPredefinedDateRangeFilters}/>
                  </div>
                  {(get(predefinedDateRangeFilters, 'freightDeliveryDateRange') === 'custom') &&
                    <>
                      <div className='col-xs-6'>
                        <DeliveryDate name='Min Planned Delivery Date' deliveryDate={minPlannedDeliveryDate} setDeliveryDate={setMinPlannedDeliveryDate} style={{paddingTop: '15px'}}/>
                      </div>
                      <div className='col-xs-6'>
                        <DeliveryDate name='Max Planned Delivery Date' deliveryDate={maxPlannedDeliveryDate} setDeliveryDate={setMaxPlannedDeliveryDate} style={{paddingTop: '15px'}}/>
                      </div>
                    </>
                  }
                </div>) : null
            }
            {
              has(props.filterValues, 'outload__date_time__gte') || has(props.filterValues, 'outload__date_time__lte') ?
                (<div className='cardForm-content row col-xs-12 padding-reset'>
                  <div className='col-xs-12'>
                    <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}>Actual Pickup Date Range</h4>
                      <PreDefinedDateRangeSelector id='outloadDateRange' predefinedDateRangeFilters={predefinedDateRangeFilters} setDateRange={setPredefinedDateRangeFilters}/>
                  </div>
                  {(get(predefinedDateRangeFilters, 'outloadDateRange') === 'custom') &&
                    <>
                      <div className='col-xs-6'>
                        <DeliveryDate name='Min Pickup Load Date' deliveryDate={minPickupLoadDate} setDeliveryDate={setMinPickupLoadDate} style={{paddingTop: '15px'}}/>
                      </div>
                      <div className='col-xs-6'>
                        <DeliveryDate name='Max Pickup Load Date' deliveryDate={maxPickupLoadDate} setDeliveryDate={setMaxPickupLoadDate} style={{paddingTop: '15px'}}/>
                      </div>
                    </>
                  }
                </div>) : null
            }
            {
              has(props.filterValues, 'inload__date_time__gte') || has(props.filterValues, 'inload__date_time__lte') ?
                (<div className='cardForm-content row col-xs-12 padding-reset'>
                  <div className='col-xs-12'>
                    <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}>Actual Delivery Date Range</h4>
                      <PreDefinedDateRangeSelector id='inloadDateRange' predefinedDateRangeFilters={predefinedDateRangeFilters} setDateRange={setPredefinedDateRangeFilters}/>
                  </div>
                  {(get(predefinedDateRangeFilters, 'inloadDateRange') === 'custom') &&
                    <>
                      <div className='col-xs-6'>
                        <DeliveryDate name='Min Delivery Load Date' deliveryDate={minDeliveryLoadDate} setDeliveryDate={setMinDeliveryLoadDate} style={{paddingTop: '15px'}}/>
                      </div>
                      <div className='col-xs-6'>
                        <DeliveryDate name='Max Delivery Load Date' deliveryDate={maxDeliveryLoadDate} setDeliveryDate={setMaxDeliveryLoadDate} style={{paddingTop: '15px'}}/>
                      </div>
                    </>
                  }
                </div>) : null
            }
          </div>
        }

        {props.isPMFilters ?
         (<div className='cardForm-content row col-xs-12 padding-reset' style={{paddingTop: '15px', paddingBottom: '15px'}}>
            <div className='col-xs-12'>
              <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}>Pack Date Range</h4>
                <PreDefinedDateRangeSelector id='packByDateRange' predefinedDateRangeFilters={predefinedDateRangeFilters} setDateRange={setPredefinedDateRangeFilters}/>
            </div>
            {(get(predefinedDateRangeFilters, 'packByDateRange') === 'custom') &&
              <>
                <div className='col-xs-6'>
                  <DeliveryDate name='Min Pack Date' deliveryDate={minPackDate} setDeliveryDate={setMinPackDate} style={{paddingTop: '15px'}}/>
                </div>
                <div className='col-xs-6'>
                  <DeliveryDate name='Max Pack Date' deliveryDate={maxPackDate} setDeliveryDate={setMaxPackDate} style={{paddingTop: '15px'}}/>
                </div>
              </>
            }
          </div>) : null
        }

        {has(props.filterValues, 'updated_at__gte') || has(props.filterValues, 'updated_at__lte') ?
         (<div className='cardForm-content row col-xs-12 padding-reset' style={{paddingTop: '10px', paddingBottom: '10px'}}>
            <div className='col-xs-12'>
              <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}>Last Updated Date</h4>
                <PreDefinedDateRangeSelector id='updatedAtDateRange' predefinedDateRangeFilters={predefinedDateRangeFilters} setDateRange={setPredefinedDateRangeFilters}/>
            </div>
            {(get(predefinedDateRangeFilters, 'updatedAtDateRange') === 'custom') &&
              <div className='col-xs-6'>
                <DeliveryDate name='After' deliveryDate={afterDate} setDeliveryDate={setAfterDate} style={{paddingTop: '15px'}}/>
              </div>
            }
            {(get(predefinedDateRangeFilters, 'updatedAtDateRange') === 'custom') &&
              <div className='col-xs-6'>
                <DeliveryDate name='Before' deliveryDate={beforeDate} setDeliveryDate={setBeforeDate} style={{paddingTop: '15px'}}/>
              </div>
            }
          </div>) : null
        }

        {
          has(props.filterValues, 'site_id') ? (
            <div className='cardForm-content row col-xs-12 padding-reset'>
              <div className='col-xs-12'>
                <Site
                  name='Site'
                  token={token}
                  selectedSite={selectedPickupsite}
                  setSelectedSite={setSelectedPickupsite}
                  data={apiData.site}
                />
              </div>
            </div>) : null
        }
        {
          has(props.filterValues, 'for_company__id__in') &&
            <div className='cardForm-content row col-xs-12 padding-reset'>
            <div className='col-xs-12'>
              <Company
                name='Customers'
                setSelectedCompany={setSelectedCustomer}
                selectedCompany={selectedCustomer}
                data={apiData.company}
              />
            </div>
          </div>
        }

        {
          has(props.filterValues, 'type__in') ? (
            <div className='cardForm-content row col-xs-12 padding-reset'>
              <div className='col-xs-12'>
                <Type
                  token={token}
                  selectedType={selectedType}
                  setSelectedType={setSelectedType}
                  data={props.type_options}
                />
              </div>
            </div>) : null
        }

        {
          props.isTitleTransferFilters ? (
            <div className='cardForm-content row col-xs-12 padding-reset' style={{paddingTop: '15px', paddingBottom: '15px'}}>
              <div className='col-xs-12'>
                <h4 style={{ margin: '10px 0px', fontWeight: 'normal' }}>Title Transfer Date Range</h4>
                  <PreDefinedDateRangeSelector id='processOnDateRange' predefinedDateRangeFilters={predefinedDateRangeFilters} setDateRange={setPredefinedDateRangeFilters}/>
              </div>
              {(get(predefinedDateRangeFilters, 'processOnDateRange') === 'custom') &&
                <>
                  <div className='col-xs-6'>
                    <TransferDate name="Min Title Transfer Date" setTransferDateChange={setTransferStartDate} value={transferStartDate} style={{paddingTop: '15px'}}/>
                  </div>
                  <div className='col-xs-6'>
                    <TransferDate name="Max Title Transfer Date" setTransferDateChange={setTransferEndDate} value={transferEndDate} style={{paddingTop: '15px'}}/>
                  </div>
                </>
              }
            </div>
          ) : null
        }

        <div className='col-sm-12 cardForm-action top15 padding-reset'>
          <Tooltip title='Reset applied filters' placement='top'>
            <span>
              <CommonButton label='RESET' default={true} variant='outlined' onClick={resetFilters} />
            </span>
          </Tooltip>

          <Tooltip title='Clear temporary saved filter' placement='top'>
            <span>
              <CommonButton label='Cancel' secondary onClick={() => props.handleFilterState('openSideDrawer', false)} variant="contained" />
            </span>
          </Tooltip>

          <Tooltip title='Apply Filters' placement='top'>
            <span>
              <CommonButton type='submit' label='APPLY' primary={true} variant="contained" onClick={combineFiltersAndSave} />
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default Filters;
