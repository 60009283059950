import React from 'react';
import { connect } from 'react-redux';
import { Button, Divider, Paper, Dialog, DialogActions, DialogContent, Tooltip, Checkbox, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { get, isEmpty, find, reject, set, map, filter, includes, remove, uniq, uniqBy, flattenDeep, has, isEqual, isUndefined } from 'lodash';
import APIService from '../../services/APIService';
import { currentUserCompany, getCountryFormats, attachCSVEventListener, defaultViewAction, getCountryLabel, generateIdentifier } from '../../common/utils';
import { forceStopLoader, isLoading, setDownloadBar } from '../../actions/main';
import InvoicePayableTable from '../../containers/InvoicePayableTable';
import { FILTER_KEYS_TO_EXCLUDE, FREIGHT_RECEIVABLE_ITEM_COLUMNS, INVOICE_FILTER_KEYS_MAPPING, PREDEFINED_DATE_RANGE_FILTER_KEYS } from '../../common/constants';
import IconButton from '@mui/material/IconButton';
import Create from '@mui/icons-material/Create';
import moment from 'moment';
import CommonDatePicker from '../common/CommonDatePicker';
import alertifyjs from 'alertifyjs';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import EmailAutocomplete from '../common/autocomplete/EmailAutocomplete';
import CommonListingButton from '../common/CommonListingButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import SideDrawer from '../common/SideDrawer';
import CustomHeaderOptions from '../common/CustomHeaderOptions';
import InvoiceFilters from '../common/InvoiceFilters';

const CUSTOM_HEADER_EDIT = {
  fontSize: '12px',
  textDecoration: 'underline',
  cursor: 'pointer',
  marginLeft: '10px'
};

const DEFAULT_FILTERS = {
  'payee__company_id__in': [],
  'contract__commodity__id__in': '',
  'payment_due_date_range': '',
  'payment_due_date__gte': '',
  'payment_due_date__lte': ''
}

class FreightInvoicePayable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceItems: [],
      isFetchingInvoices: false,
      companyDirectory: [],
      invoiceItemsGroupedByPayee: [],
      isFetchingCompanyDirectory: false,
      selectedInvoiceItems: [],
      openDialog: false,
      selectedRecipients: undefined,
      recipientsDialogOpen: false,
      sellerEmployeeData: undefined,
      showRecipientsError: false,
      csvPopup: false,
      isFilteredCsv: false,
      customColumns: true,
      customColumnNames: {},
      customHeaderOptions: false,
      customColumnTitle: undefined,
      applyFilters: false,
      openSideDrawer: false,
      filters: {},
      bankProcessingDate: {
        value: undefined,
        error: ''
      },
    }

    this.handleGeneratePaymentRunClick = this.handleGeneratePaymentRunClick.bind(this);
    this.generatePaymentRun = this.generatePaymentRun.bind(this);
    this.handleBankProcessingDateChange = this.handleBankProcessingDateChange.bind(this);
  }

  componentDidMount() {
    this._attachCSVEventListener();
    this.fetchInvoices();
    APIService.profiles()
      .filters('freight_invoice_payable')
      .get()
      .then(res => {
        this.setState({
          filters: get(res, 'freight_invoice_payable', {}) || DEFAULT_FILTERS,
        });
      });
  }

  onDownloadResponse(message) {
    this.props.dispatch(setDownloadBar(message, true, this.onCloseDownloadResponse));
  }

  _attachCSVEventListener() {
    attachCSVEventListener('freight_payables-csv-ready', 'Freight Invoice Payables', this.onDownloadResponse);
  }

  fetchInvoices() {
    this.props.dispatch(isLoading('LoadingPayables'))
    if (!this.state.isFetchingInvoices) {
      this.setState({ isFetchingInvoices: true }, async () => {
        const company = currentUserCompany();
        APIService.invoices().appendToUrl(`freight-payable-items/?company_id=${company.id}`).get().then(response =>
          this.setState({ invoiceItems: response, isFetchingInvoices: false }, () => {
            if (isEmpty(this.state.companyDirectory))
              this.getCompanyDirectory()
            else
              this.setPayableItems();
          }));
      })
    }
  }

  generatePaymentRun() {
    if (isUndefined(this.state.bankProcessingDate.value)) {
      const newState = {...this.state};
      newState.bankProcessingDate.error = 'This field is required';
      this.setState(newState);
    }
    else {
      this.props.isLoading();
      this.setState({openDialog: false});
      let selectedInvoiceItems = [...this.state.selectedInvoiceItems];
      remove(selectedInvoiceItems, selectedInvoiceItem => selectedInvoiceItem.isChemicalItem);

      let invoiceItems = selectedInvoiceItems.reduce((obj, objectKey) => ({ ...obj, [objectKey.id]: {}}), {});
      let minPaymentDueObj = selectedInvoiceItems.sort((a, b) => new Date(a.paymentDue) - new Date(b.paymentDue));
      let identifier = generateIdentifier('paymentRun');
      const company = currentUserCompany();
      let data = {
        'invoiceItems': invoiceItems,
        'identifier' : identifier,
        'payer': {
          'companyId': get(company, 'id'),
          'role': 'payer'
        },
        'paymentDue': moment(get(minPaymentDueObj, '0.paymentDue'), 'DD/MM/YYYY').format('YYYY-MM-DD hh:mm:ss'),
        'bankProcessingDate': this.state.bankProcessingDate.value,
      };
      APIService.invoices()
        .appendToUrl(`payment-runs/?company_id=${company.id}`)
        .post(data)
        .then((res) => {
          if (res.errors) {
            if(res.errors[0].includes('recreated'))
              alertifyjs.error(res.errors[0] + ', reloading...', 2, () => window.location.reload());
            else
              alertifyjs.error(res.errors[0]);

          }
          else {
            alertifyjs.success("Payment Run created successfully");
            this.props.forceStopLoader();
            window.location = '#/invoices/payment-runs';
          }
        });
    }
  }

  handleBankProcessingDateChange(value) {
    const newState = {...this.state};
    newState.bankProcessingDate.value = value;
    newState.bankProcessingDate.error = '';
    this.setState(newState);
  }

  toFormattedDate = date => {
    if (date) {
      const mDate = moment(date)
      return mDate.isValid() ? mDate.format(getCountryFormats().date) : date
    }
    return date
  }

  setPayableItems() {
    const { invoiceItems } = this.state
    if (!isEmpty(this.state.companyDirectory)) {
      map(invoiceItems, obj => {
        let payer = find(this.state.companyDirectory, { id: obj.companyId });
        obj.payerName = get(payer, 'name');
        obj.allSelected = false;
        if (obj.recipients && !isEmpty(obj.recipients)) {
          let recipientDisplayStr = "";
          map(obj.recipients, recipient => {
            if (isEmpty(recipientDisplayStr))
              recipientDisplayStr = recipientDisplayStr + recipient.email;
            else
              recipientDisplayStr = recipientDisplayStr + ", " + recipient.email;
          });
          obj['recipientDisplay'] = 'Recipients: (' + recipientDisplayStr + ')';
        }
        map(obj.items, invoiceItem => {
          invoiceItem.paymentDue = this.toFormattedDate(invoiceItem.paymentDue)
        })
      })
      this.setState({ invoiceItemsGroupedByPayee: invoiceItems });
    }
    this.props.dispatch(forceStopLoader())
  }

  getCompanyDirectory() {
    if (!this.state.isFetchingCompanyDirectory)
      this.setState({ isFetchingCompanyDirectory: true }, async () => {
        APIService.companies().appendToUrl('directory/names/?excludeGroups=true').get().then(companies =>
          this.setState({ companyDirectory: companies, isFetchingCompanyDirectory: false }, this.setPayableItems));
      })
  }

  isSelected = invoiceItem => Boolean(find(this.state.selectedInvoiceItems, { id: invoiceItem.id }))

  updateSelectedInvoiceItem = (selected, isChecked, obj) => {
    const newState = { ...this.state };
    newState.selectedInvoiceItems = isChecked ? [...this.state.selectedInvoiceItems, selected] : reject(this.state.selectedInvoiceItems, { id: selected.id });
    let allPresent = obj.items.every(item => newState.selectedInvoiceItems.some(selectedItem => selectedItem.id === item.id));
    var index = this.state.invoiceItemsGroupedByPayee.findIndex(item => item.companyId === obj.companyId);
    set(newState, `invoiceItemsGroupedByPayee.${index}.allSelected`, allPresent);
    this.setState(newState);
  }

  onSelectAllToggle = (event, value, obj) => {
    const newState = { ...this.state };
    var index = this.state.invoiceItemsGroupedByPayee.findIndex(item => item.companyId === obj.companyId);
    set(newState, `invoiceItemsGroupedByPayee.${index}.allSelected`, value);
    if (value)
      newState.selectedInvoiceItems = [...this.state.selectedInvoiceItems, ...obj.items];
    else {
      let itemIds = map(obj.items, 'id');
      newState.selectedInvoiceItems = this.state.selectedInvoiceItems.filter(item => !itemIds.includes(item.id));
    }
    this.setState(newState);
  }

  handleEmailRecipients(companyId) {
    var index = this.state.invoiceItemsGroupedByPayee.findIndex(item => item.companyId === companyId);
    const selectedRecipients = get(this.state.invoiceItemsGroupedByPayee[index], 'recipients');
    this.setState({ selectedRecipients: selectedRecipients })
    APIService
      .profiles()
      .appendToUrl('employees-signature/')
      .get(null, null, { company_ids: companyId })
      .then(employees => {
        const contactsWithEmail = map(filter(employees, employee => employee.email), employee => ({ ...employee, title: `${employee.name} (${employee.email})`, value: employee.email }))
        this.setState({ sellerEmployeeData: contactsWithEmail, recipientsDialogOpen: true })
      })
  }

  onDialogClose = () => this.setState({ recipientsDialogOpen: false });

  onRecipientsChange = (event, selected) => {
    if (selected && selected.length <= 3)
      this.setState({ showRecipientsError: false });
    this.setState({ selectedRecipients: selected });
  }

  submitEmailRecipients = () => {
    if (this.state.selectedRecipients.length > 3) {
      this.setState({ showRecipientsError: true });
    }
    else {
      this.setState({ recipientsDialogOpen: false, showRecipientsError: false });
      const companyId = get(this.state.selectedRecipients, '0.companyId');
      var index = this.state.invoiceItemsGroupedByPayee.findIndex(item => item.companyId === companyId);
      var invoiceIds = map(this.state.invoiceItemsGroupedByPayee[index].items, item => item.invoiceId);
      const data = {
        'selectedRecipients': this.state.selectedRecipients,
        'invoiceIds': invoiceIds,
      }
      APIService
        .invoices()
        .appendToUrl('email-recipients/')
        .put(data)
        .then(() => window.location.reload());
    }
  }

  fetchCSVData = (callback) => {
    const company = currentUserCompany();
    var param = this.state.isFilteredCsv ? 'show_filters' : '';
    if (this.state.customColumns) {
      param = param.length == 0 ? param + 'custom_csv' : param + '&custom_csv';
    }
    this.props.dispatch(setDownloadBar('Your Freight Payables CSV is getting prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true))
    const service = APIService.invoices();
    service.appendToUrl(`freight-payables/csv/?company_id=${company.id}&${param}`);
    service
      .get(this.props.token, {
        'Content-Type': 'text/csv',
        Accept: 'text/csv',
      })
      .then(() => this.setState({ csvPopup: false }));
    if (callback) {
      callback();
    }
  };

  customCsvEnabled(isFilteredCsv) {
    const newState = { ...this.state };
    newState.isFilteredCsv = isFilteredCsv;
    if (this.props.currentUser.company.enableCustomCsv) {
      newState.csvPopup = true;
      this.setState(newState);
    }
    else {
      newState.customColumns = false;
      this.setState(newState, this.fetchCSVData);
    }
  }

  async editCustomHeaderOptions() {
    const columnNames = await APIService.profiles().appendToUrl(`${this.props.currentUser.id}/report-preferences/freight_invoice_payables_csv/`).get();
    this.setState({ customColumnNames: columnNames, customHeaderOptions: true });
  }

  updateColumnCount(count) {
    this.setState({ customColumnTitle: `Edit Columns (${count})` });
  }

  handleFilters = bool => {
    this.setState({
      applyFilters: bool,
      openSideDrawer: bool,
    });
  };

  handleFilterState = (key, value) => {
    this.setState({ [key]: value }, () => {
      if (key === 'applyFilters' && value) {
        const { filters } = this.state;
        delete filters['commodity__id__in'];
        APIService
          .profiles()
          .filters()
          .post({ freight_invoice_payable: filters }, this.props.token)
          .then(res => {
            this.setState({ filters: get(res, 'filters.freight_invoice_payable', {}) }, () => {
              this.fetchInvoices();
            })
          });
      }
    });
  };

  customFilterValueExist = filterKeys => filterKeys.some(key => Boolean(get(this.state.filters, key)))

  filterCriteria = (key, value) => includes(FILTER_KEYS_TO_EXCLUDE, key) ? false : includes(PREDEFINED_DATE_RANGE_FILTER_KEYS, key) && value === 'custom' ? this.customFilterValueExist(get(INVOICE_FILTER_KEYS_MAPPING, key)) : value.length !== 0;

  getActionsOptionMapperListItems() {
    return [defaultViewAction];
  }

  handleGeneratePaymentRunClick() {
    this.setState(prevState => ({
      unverifiedNgrData: uniqBy(
        flattenDeep(map(prevState.selectedInvoiceItems, selectedItem => {
          const sellerNgrDetails = get(selectedItem, 'sellerNgrDetails');
          if (!get(sellerNgrDetails, 'isVerified')) {
            const { ngrType, ngrNumber, bankDetails } = sellerNgrDetails;

            if (isEmpty(bankDetails)) {
              return {
                companyName: '',
                ngrNumber,
                ngrType,
                shareholderPercent: '',
                bsbNumber: '',
                name: '',
                accountName: '',
                accountNumber: '',
              }
            }
            else if (isEqual(ngrType, 'shared')) {
              return map(bankDetails, item => {
                const { companyName, shareholderPercent, bsbNumber, name, accountName, accountNumber } = item;
                return {
                  companyName,
                  ngrNumber,
                  ngrType,
                  shareholderPercent,
                  bsbNumber,
                  name,
                  accountName,
                  accountNumber,
                };
              });
            } else {
              const [item] = bankDetails;
              const { companyName, shareholderPercent, bsbNumber, name, accountName, accountNumber } = item;
              return {
                companyName,
                ngrNumber,
                ngrType,
                shareholderPercent,
                bsbNumber,
                name,
                accountName,
                accountNumber,
              };
            }
          }
        })).filter(Boolean),
        JSON.stringify
      ),
    }));

    const company = currentUserCompany();
    let itemsWithIdenticalInvoices = {};
    let xeroMissingContacts = []
    const checkXeroContactStatus = company.xeroEnabled;
    let selectedInvoiceItems = [...this.state.selectedInvoiceItems];
    remove(selectedInvoiceItems, selectedInvoiceItem => selectedInvoiceItem.isChemicalItem);
    map(selectedInvoiceItems, selectedItem => {
      let invoice = get(selectedItem, 'invoice');
      if (checkXeroContactStatus) {
        const details = find(this.state.invoiceItems, invoiceItem => find(invoiceItem.items, item => item.ref === selectedItem.ref))
        if (details?.companyId && !details.xeroContactStatus) {
          const payee = find(this.state.companyDirectory, { id: details.companyId });
          xeroMissingContacts.push(payee.name)
        }
      }
      if (!has(itemsWithIdenticalInvoices, invoice)) {
        let invoiceItemsForSelectedItemInvoice = this.state.invoiceItems.filter(item => get(item, 'invoice') === invoice);
        if (invoiceItemsForSelectedItemInvoice.length > 1) {
          itemsWithIdenticalInvoices[invoice] = {};
          itemsWithIdenticalInvoices[invoice]['unselected'] = invoiceItemsForSelectedItemInvoice.filter(item => !this.state.selectedInvoiceItems.find(_item => item?.itemType === 'customitem' ? _item.description === item.description : _item.ref === item.ref)).map(item => item?.itemType === 'customitem' ? `${item.ref} - ${item.description}` : item.ref);
          itemsWithIdenticalInvoices[invoice]['selected'] = invoiceItemsForSelectedItemInvoice.filter(item => this.state.selectedInvoiceItems.find(_item => item?.itemType === 'customitem' ? _item.description === item.description : _item.ref === item.ref)).map(item => item?.itemType === 'customitem' ? `${item.ref} - ${item.description}` : item.ref);
        }
      }
    });

    xeroMissingContacts = uniq(xeroMissingContacts)

    let resultArray = []
    map(Object.entries(itemsWithIdenticalInvoices), item => {
      let invoice = get(item, '0');
      let selectedItems = get(item, '1.selected');
      let unselectedItems = get(item, '1.unselected');
      if (!isEmpty(unselectedItems)) {
        let unselectedVerb = unselectedItems.length > 1 ? 'have' : 'has';
        let selectedVerb = selectedItems.length > 1 ? 'have' : 'has';
        unselectedItems = `<ul><li>${unselectedItems.join('</li><li>')}</ul>`
        let itemRefs = `${invoice}: ${selectedItems.toString()} ${selectedVerb} been selected but ${unselectedItems} ${unselectedVerb} not been selected.`
        resultArray.push(itemRefs);
      }
    });

    let warningContent = '';
    if (!isEmpty(resultArray)) {
      resultArray = '<li>' + resultArray.join('</li><li>')
      warningContent = `<ul>${resultArray}</url>`
    }
    if (!isEmpty(xeroMissingContacts)) {
      const missingContactsText = '<li>' + xeroMissingContacts.join('</li><li>')
      warningContent += `<br/><br/><div>Following counter parties does not exist in Xero as Contacts:</div><ul>${missingContactsText}</ul>`
    }

    if (warningContent) {
      alertifyjs.confirm(
        'Warning',
        `<div>
            ${warningContent}
            <br/><br/>
            <div>
              Do you want to continue to create Payment Run ?
            </div>
        </div>`,
        () => this.setState({ openDialog: true }),
        () => { }
      );
    }
    else {
      this.setState({ openDialog: true });
    }
  }

  render() {
    const {
      invoiceItemsGroupedByPayee, selectedInvoiceItems,
      recipientsDialogOpen, selectedRecipients, sellerEmployeeData
    } = this.state
    const disablePaymentRun = !get(selectedInvoiceItems, 'length', 0) > 0;
    return (
      <Paper className="paper-table">
        <div>
          <span style={{ paddingLeft: '10px', fontWeight: 'bold', fontSize: '20px', marginTop: '8px' }}>
            Freight Payable
          </span>

          <CommonListingButton
            defaultHandler={() => this.customCsvEnabled(false)}
            showMenus={!isEmpty(Object.entries(this.state.filters).filter(val => get(val, '1.length', 0) !== 0))}
            optionMapper={[
              { name: 'Complete List', fx: () => this.customCsvEnabled(false) },
              { name: 'Filtered List', fx: () => this.customCsvEnabled(true) },
            ]}
            title='Download Contents of the table in a CSV'
            name='Export'
          />
          <Tooltip title='Apply filters' placement='top'>
            <Button
              value={this.state.applyFilters}
              variant="contained"
              type='button'
              onClick={() => this.handleFilters(true)}
              color='primary'
              className='add-button'
              style={{ float: 'right', marginLeft: '10px' }}
            >
              <FilterListIcon style={{ paddingRight: '5px' }} />
              FILTERS{' '}
              {+!isEmpty(Object.entries(this.state.filters).filter(val => this.filterCriteria(val[0], val[1])))
                ? `(${Object.entries(this.state.filters).filter(val => this.filterCriteria(val[0], val[1])).length})`
                : ''}
            </Button>
          </Tooltip>
          <Button
            variant="contained"
            type='button'
            color='primary'
            onClick={this.handleGeneratePaymentRunClick}
            style={{ float: 'right', marginLeft: '10px' }}
            disabled={disablePaymentRun}
          >
            Generate Payment Run
          </Button>
          <CommonListingButton
            showMenus
            showDownLoadIcon={false}
            optionMapper={this.getActionsOptionMapperListItems()}
            title='Actions'
            name='Actions'
          />
          <Dialog open={this.state.csvPopup} onClose={() => this.setState({ csvPopup: false })} aria-labelledby='form-dialog-title' fullWidth>
            <DialogTitleWithCloseIcon
              onClose={() => this.setState({ csvPopup: false })}
              closeButtonStyle={{ marginTop: '0px' }}
              id='form-dialog-title'>
              Download Freight Invoice Payable Data
            </DialogTitleWithCloseIcon>
            <DialogContent style={{ marginTop: '15px' }}>
              <span style={{ float: 'left' }}>Select checkbox for custom csv download</span>
              <div className='col-sm-6 padding-reset' style={{ marginTop: '10px' }}>
                <Checkbox
                  id={'custom-headers'}
                  checked={this.state.customColumns}
                  style={{ height: '40px' }}
                  onChange={() => this.setState({ customColumns: !this.state.customColumns })}
                />
                Custom Columns
                <a onClick={() => this.editCustomHeaderOptions()} hidden={!this.state.customColumns} style={CUSTOM_HEADER_EDIT}>View & Update</a>
              </div>
              <SideDrawer
                isOpen={this.state.customHeaderOptions}
                title={this.state.customColumnTitle}
                onClose={() => this.setState({ customHeaderOptions: false })}
                size="small"
              >
                <CustomHeaderOptions
                  customColumns={this.state.customColumnNames}
                  closeDrawer={() => this.setState({ customHeaderOptions: false })}
                  user={this.props.currentUser}
                  csv_type="freight_invoice_payables_csv"
                  updateColumnCount={(count) => this.updateColumnCount(count)}
                />
              </SideDrawer>
            </DialogContent>
            <DialogActions>
              <Button
                type='button'
                onClick={() => this.setState({ csvPopup: false })}
                variant='outlined'>
                Cancel
              </Button>
              <Button type='button' onClick={() => this.fetchCSVData()} color='primary' variant='outlined'>
                Download
              </Button>
            </DialogActions>
          </Dialog>
          {this.state.applyFilters && (
            <SideDrawer isOpen={this.state.openSideDrawer} title='Filters' size='big' onClose={() => this.handleFilters(false)} app='filters'>
              <InvoiceFilters
                handleFilterState={this.handleFilterState}
                filters={this.state.filters}
                freightPayableInvoice
              />
            </SideDrawer>
          )}
          <Divider light style={{ marginLeft: '-10px', marginRight: '-10px', marginTop: '20px' }} />
          {!isEmpty(invoiceItemsGroupedByPayee) &&
            map(invoiceItemsGroupedByPayee, (obj, index) => (
              <div key={index}>
                <div style={{ marginLeft: '-10px', marginRight: '-10px', background: '#F5F5F5', height: '50px' }}>
                  <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <span style={{ paddingLeft: '15px', fontSize: '20px', lineHeight: '50px' }}>{obj.payerName}</span>
                    {
                      get(obj, 'recipientDisplay') &&
                      <span style={{ paddingLeft: '15px', fontSize: '15px' }}>{get(obj, 'recipientDisplay')}</span>
                    }
                    <IconButton
                      onClick={() => this.handleEmailRecipients(obj.companyId)}
                      size="large">
                      <Create fontSize="small" />
                    </IconButton>
                  </span>
                </div>
                <div style={{ marginTop: '10px' }}>
                  <InvoicePayableTable
                    items={obj.items}
                    columns={[
                      {
                        header: 'All', checkbox: true, className: 'xxsmall', onChange: (selected, isChecked) => this.updateSelectedInvoiceItem(selected, isChecked, obj),
                        func: this.isSelected, selectAll: true, checked: obj.allSelected, onSelectAll: (event, value) => this.onSelectAllToggle(event, value, obj)
                      },
                      ...FREIGHT_RECEIVABLE_ITEM_COLUMNS
                    ]}
                    isSubscriber={obj.isSubscriber}
                  />
                </div>
                {
                  recipientsDialogOpen &&
                  <Dialog open keepMounted fullWidth onClose={this.onDialogClose}>
                    <DialogTitleWithCloseIcon
                      onClose={this.onDialogClose}
                      closeButtonStyle={{ marginTop: '0px' }}
                      id='form-dialog-title'>
                      Update Recipients
                    </DialogTitleWithCloseIcon>
                    <DialogContent>
                      <React.Fragment>
                        <div>
                          {'Update Recipients'}
                        </div>
                        <div>
                          <EmailAutocomplete
                            options={sellerEmployeeData}
                            onChange={this.onRecipientsChange}
                            selected={selectedRecipients}
                          />
                        </div>
                        {
                          this.state.showRecipientsError &&
                          <div style={{ marginTop: '3px', color: '#FF0000', fontSize: '15px' }}>
                            You can select only 3 emails.
                          </div>
                        }
                      </React.Fragment>
                    </DialogContent>
                    <DialogActions>
                      <Button color='default' onClick={this.onDialogClose}>Cancel</Button>
                      <Button onClick={this.submitEmailRecipients}>Proceed</Button>
                    </DialogActions>
                  </Dialog>
                }
              </div>
            ))
          }
        </div>
        <Dialog
          open={this.state.openDialog}
          onClose={() => this.setState({ openDialog: false })}
          aria-labelledby="form-dialog-title"
          fullWidth
          PaperProps={{ style: { maxWidth: !isEmpty(this.state.unverifiedNgrData) ? '1400px' : '600px' } }}
        >
          <DialogTitleWithCloseIcon
            onClose={() => this.setState({ openDialog: false })}
            closeButtonStyle={{ marginTop: '0px' }}
            id='form-dialog-title'>
            Payment Run
          </DialogTitleWithCloseIcon>
          <DialogContent style={{ marginTop: '15px' }}>
            <span style={{ float: 'left' }}>
              This will generate invoices and a payment run for the selected items.
            </span>
            <div style={{ marginTop: '35px', marginBottom: '20px' }}>
              <CommonDatePicker
                id='bankProcessingDate'
                floatingLabelText='Bank Processing Date'
                onChange={this.handleBankProcessingDateChange}
                errorText={this.state.bankProcessingDate.error}
                value={this.state.bankProcessingDate.value}
                minDate={new Date()}
              />
            </div>
            {!isEmpty(this.state.unverifiedNgrData) && <div>
              <span style={{ marginTop: '35px' }}>
                The following NGR&apos;s bank details are not verified from NGR.com. Please verify the account details before making the payment.
              </span>
              <Table style={{ border: "none" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Company Name</TableCell>
                    <TableCell>NGR Number</TableCell>
                    <TableCell>NGR Type</TableCell>
                    <TableCell>Shareholder Percent</TableCell>
                    <TableCell>{`${getCountryLabel('bsb')} Number`}</TableCell>
                    <TableCell>Bank Name</TableCell>
                    <TableCell>Account Name</TableCell>
                    <TableCell>Account Number</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {map(this.state.unverifiedNgrData, row => (
                    <TableRow key={row.ngrNumber}>
                      <TableCell>{row.companyName}</TableCell>
                      <TableCell>{row.ngrNumber}</TableCell>
                      <TableCell>{row.ngrType}</TableCell>
                      <TableCell>{row.shareholderPercent}</TableCell>
                      <TableCell>{row.bsbNumber}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.accountName}</TableCell>
                      <TableCell>{row.accountNumber}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>}

          </DialogContent>
          <DialogActions>
            <Button
              type='button'
              onClick={() => this.setState({ openDialog: false })}
              variant='outlined'>
              Cancel
            </Button>
            <Button type='button' onClick={this.generatePaymentRun} color='primary' variant='contained'>
              OK
            </Button>
          </DialogActions>
        </Dialog>

      </Paper>
    )
  }
}

const mapStateToProps = state => {
  return {
    breadcrumbs: state.main.breadcrumbs,
    currentUser: state.main.user.user,
  };
};

const mapDispatchToProps = dispatch => ({
  isLoading: (waitForComponent) => dispatch(isLoading(waitForComponent)),
  forceStopLoader: () => dispatch(forceStopLoader()),
  setDownloadBar: (message, isOpen, onClose) => dispatch(setDownloadBar(message, isOpen, onClose)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FreightInvoicePayable);
