import React from 'react';
import { connect } from 'react-redux';
import { Virtuoso } from 'react-virtuoso'
import Paper from '@mui/material/Paper';
import { isLoading, setBreadcrumbs, setHeaderText, forceStopLoader, setDownloadBar, setLoadingText } from '../../actions/main';
import {
  Button, Checkbox, Dialog, DialogActions, DialogContent, Divider, Tooltip, FormControlLabel, Skeleton, Badge, Chip
} from '@mui/material';
import ExpandIcon from '@mui/icons-material/Expand';
import CollapseIcon from '@mui/icons-material/UnfoldLess';
import InvoicePayableTable from '../../containers/InvoicePayableTable';
import { get, isEmpty, find, findIndex, reject, isEqual, set, isUndefined, has, map, filter, uniq, remove, uniqBy, includes, without, orderBy, compact, flatten, forEach, sum, intersection } from 'lodash';
import APIService from '../../services/APIService';
import { currentUserCompany, generateIdentifier, getCountryFormats, getCountryConfig, getCountryLabel } from '../../common/utils';
import moment from 'moment';
import { FILTER_KEYS_TO_EXCLUDE, INVOICE_FILTER_KEYS_MAPPING, PAYABLE_INVOICE_ITEMS_COLUMNS, PREDEFINED_DATE_RANGE_FILTER_KEYS, WARNING_ORANGE } from '../../common/constants';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import alertifyjs from 'alertifyjs';
import WarningIcon from '@mui/icons-material/Warning';
import FilterListIcon from '@mui/icons-material/FilterList';
import SideDrawer from '../common/SideDrawer';
import InvoiceFilters from '../common/InvoiceFilters';
import CommonDatePicker from '../common/CommonDatePicker';
import CommonListingButton from '../common/CommonListingButton';
import { attachCSVEventListener } from '../../common/utils';
import IconButton from '@mui/material/IconButton';
import Create from '@mui/icons-material/Create';
import EmailAutocomplete from '../common/autocomplete/EmailAutocomplete';
import { FREIGHT_CONTRACT_ITEM_TYPE, LOAD_ITEM_TYPE } from './Constants';
import CustomHeaderOptions from '../common/CustomHeaderOptions';
import { Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import { isCompanyGrower } from '../../common/utils';
import { getCountryCurrency } from '../../common/utils';
import InvoiceGrainLevies from './InvoiceGrainLevies';

const gradeMismatchInfoIcon = item => {
  if(!item?.isGradeMatchingWithContract && includes(["freightcontract", "titletransfer"], item?.itemType))
    return (
      <Tooltip arrow title={`The grade in this invoice item doesn't match with the Contract grade(s) - ${item?.contractGrades?.join(', ')}`}>
        <IconButton
          className='new-releases-button'
          onClick={() => alertifyjs.alert('Warning', `The grade in this invoice item doesn't match with the Contract grade(s) - ${item?.contractGrades?.join(', ')}`,
            () => { }
          )}
          size="small">
          <WarningIcon fontSize="small"
            style={{ cursor: 'pointer', color: WARNING_ORANGE }}
          >
          </WarningIcon>
        </IconButton>
      </Tooltip>
    )
  return null
}

const CUSTOM_HEADER_EDIT = {
  fontSize: '12px',
  textDecoration: 'underline',
  cursor: 'pointer',
  marginLeft: '10px'
};

const DEFAULT_FILTERS = {
  'payee__company_id__in': [],
  'contract__commodity__id__in': '',
  'payment_due_date_range': '',
  'payment_due_date__gte': '',
  'payment_due_date__lte': ''
}

class InvoicePayable extends React.Component {
  constructor(props) {
    super(props);
    const config = getCountryConfig()
    this.state = {
      selectedPayeeCompanyIds: [],
      expanded: [],
      loadingItemsFor: [],
      invoicesItems: undefined,
      selectedInvoiceItems: [],
      invoiceItemsGroupedByPayee: [],
      allInvoiceItems: [],
      count: 0,
      levyEprMapping: {},
      openDialog: false,
      applyFilters: false,
      openSideDrawer: false,
      filters: {},
      levyEprPreferences: undefined,
      bankProcessingDate: {
        value: undefined,
        error: ''
      },
      recipientsDialogOpen: false,
      sellerEmployeeData: undefined,
      selectedRecipients: undefined,
      showRecipientsError: false,
      isFetchingInvoices: false,
      csvPopup: false,
      isFilteredCsv: false,
      customColumns: true,
      customColumnNames: {},
      customHeaderOptions: false,
      customColumnTitle: undefined,
      unverifiedNgrData: [],
      gstRate: config?.invoicing?.gstRate,
    };
    this.generatePaymentRun = this.generatePaymentRun.bind(this);
    this.handleBankProcessingDateChange = this.handleBankProcessingDateChange.bind(this);
    this.handleGeneratePaymentRunClick = this.handleGeneratePaymentRunClick.bind(this);
    this.editCustomHeaderOptions = this.editCustomHeaderOptions.bind(this);
  }

  componentDidMount() {
    this._attachCSVEventListener();
    this.setHeaderAndBreadcrumbs();
    this.fetchInvoices();
    APIService.profiles()
      .filters('invoice_payable')
      .get()
      .then(res => {
        this.setState({
          filters: get(res, 'invoice_payable') || DEFAULT_FILTERS,
        });
      });
  }

  setHeaderAndBreadcrumbs() {
    const breadcrumbs = [{ text: 'Invoices Payable' }];
    const headerText = 'Invoices Payable';
    this.props.setHeaderText(headerText);
    if (!isEqual(this.props.breadcrumbs, breadcrumbs)) {
      this.props.setBreadcrumbs(breadcrumbs);
    }
  }

  getInvoiceItemsFrom = items => {
    remove(items, item => item.isChemicalItem);

    return items.reduce((obj, objectKey) => ({ ...obj, [objectKey.id]: {'levies': objectKey.levyItems.reduce((acc, levy) => (acc[levy.levyType] = { "total": objectKey.levy ? levy.total : undefined, "isCustomAdjustment": levy?.isCustomAdjustment}, acc), {}), 'epr': objectKey.epr}}), {});
  }

  generatePaymentRun() {
    if (isUndefined(this.state.bankProcessingDate.value)) {
      const newState = {...this.state};
      newState.bankProcessingDate.error = 'This field is required';
      this.setState(newState);
    }
    else {
      this.setState({openDialog: false}, () => {
        this.props.isLoading('GeneratePaymentRun');
        this.props.setLoadingText('Generating Payment Run, this may take a while. DO NOT close this window. Please continue with other work on a separate window/tab.')
        let payeeCompanyIds = []
        let invoiceItems = {}
        let paymentDueDate;
        if(!isEmpty(this.state.selectedPayeeCompanyIds)) {
          const withItems = filter(this.state.invoiceItemsGroupedByPayee, payee => payee?.items?.length)
          const withItemsCompanyIds = map(withItems, 'companyId')
          forEach(this.state.selectedPayeeCompanyIds, payeeCompanyId => {
            if(withItemsCompanyIds.includes(payeeCompanyId))
              invoiceItems = {
                ...invoiceItems,
                ...(this.getInvoiceItemsFrom(find(this.state.invoiceItemsGroupedByPayee, {companyId: payeeCompanyId})?.items) || [])
              }
            else
              payeeCompanyIds.push(payeeCompanyId)
          })
        }
        if(!isEmpty(this.state.selectedInvoiceItems)) {
          let selectedInvoiceItems = [...this.state.selectedInvoiceItems];
          invoiceItems = {...invoiceItems, ...this.getInvoiceItemsFrom(selectedInvoiceItems)}
          let minPaymentDueObj = selectedInvoiceItems.sort((a, b) => new Date(a.paymentDue) - new Date(b.paymentDue));
          paymentDueDate = moment(get(minPaymentDueObj, '0.paymentDue'), 'DD/MM/YYYY').format('YYYY-MM-DD hh:mm:ss')
        }
        let identifier = generateIdentifier('paymentRun');
        const company = currentUserCompany();
        let data = {
          'invoiceItems': invoiceItems,
          'payeeCompanyIds': payeeCompanyIds,
          'identifier' : identifier,
          'payer': {
            'companyId': get(company, 'id'),
            'role': 'payer'
          },
          'paymentDue': paymentDueDate,
          'bankProcessingDate': this.state.bankProcessingDate.value,
          'includeLevyAdjustments': this.includeLevyAdjustments(),
        };
        APIService.invoices()
          .appendToUrl(`payment-runs/?company_id=${company.id}`)
          .post(data)
          .then((res) => {
            this.props.setLoadingText(null)
            if (res.errors) {
              if(res.errors[0].includes('recreated'))
                alertifyjs.error(res.errors[0] + ', reloading...', 2, () => window.location.reload());
              else
                alertifyjs.error(res.errors[0]);

            }
            else {
              alertifyjs.success("Payment Run created successfully");
              this.props.forceStopLoader();
              window.location = '#/invoices/payment-runs';
            }
          });
      });
    }
  }

  async getLevyEprPreferences(companyId) {
    await APIService.companies().appendToUrl(`${companyId}/payment-run-levy-epr-preferences/`).get().then(response => {
      this.setState({levyEprPreferences: response});
    });
  }

  async fetchInvoices() {
    this.props.dispatch(isLoading('LoadingPayables'))
    if(!this.state.isFetchingInvoices) {
      this.setState({isFetchingInvoices: true}, async () => {
        const company = currentUserCompany();
        await this.getLevyEprPreferences(company.id)
        this.props.dispatch(isLoading('LoadingPayables'))
        APIService.invoices().appendToUrl(`payable-items/?company_id=${company.id}&items=false`).get().then(response =>
          this.setState({
            invoicesItems: response,
            isFetchingInvoices: false,
            expanded: response.length == 1  ? [response[0].companyName]: []
          }, () => {
            this.getPayableInvoiceItems()
          }));
      })
    }
  }

  fetchItems = (event, payeeCompanyId, payeeName) => {
    event.preventDefault()
    event.stopPropagation()
    if(payeeName && this.state.expanded.includes(payeeName)) {
      this.setState({expanded: without(this.state.expanded, payeeName)})
    }
    else if(payeeCompanyId && !this.state.expanded.includes(payeeName) && !this.state.loadingItemsFor.includes(payeeCompanyId)) {
      this._fetchItems(payeeCompanyId, payeeName)
    }
  }

  _fetchItems = (payeeCompanyId, payeeName=null, onComplete=null) => {
    if(payeeCompanyId && !this.state.loadingItemsFor.includes(payeeCompanyId)) {
      this.setState({expanded: uniq([...this.state.expanded, payeeName]), loadingItemsFor: uniq([...this.state.loadingItemsFor, payeeCompanyId])}, () => {
        const company = currentUserCompany();
        let payee = filter(this.state.invoicesItems, {companyId: payeeCompanyId})
        payee = payee.length > 1 && payeeName ? find(payee, {companyName: payeeName}) : payee[0]
        if(!isEmpty(payee.items)) {
          const newState = {...this.state}
          newState.loadingItemsFor = without(newState.loadingItemsFor, payeeCompanyId)
          this.setState(newState, () => {
            if (typeof onComplete === 'function') {
              onComplete();
            }
          })
        } else {
          APIService.invoices()
            .appendToUrl(`payable-items/?company_id=${company.id}&payee_company_id=${payeeCompanyId}&only_items=true`)
            .get()
            .then(response => {
              const newState = {...this.state}
              payee.items = response[0]?.items || []
              payee.chemicalApplicationItems = response[0]?.chemicalApplicationItems || []
              payee = {...payee, ...response[0]}
              newState.loadingItemsFor = without(newState.loadingItemsFor, payeeCompanyId)
              this.setState(newState, () => {
                this.getPayableInvoiceItems(payeeCompanyId, false)
                if (typeof onComplete === 'function') {
                  onComplete();
                }
              })
            })
            .catch(() => {
              const newState = {...this.state}
              newState.loadingItemsFor = without(newState.loadingItemsFor, payeeCompanyId)
              this.setState(newState, () => {
                if (typeof onComplete === 'function') {
                  onComplete();
                }
              })
            });
        }
      })
    } else if (typeof onComplete === 'function') {
      onComplete();
    }
  }

  handleValueChange(item, value, key) {
    let formattedValue = value ? parseFloat(value) : 0
    if (formattedValue > 0) {
      formattedValue = -1 * formattedValue;
    }
    const newState = {...this.state};
    let isEpr = key === 'epr';
    map(this.state.invoiceItemsGroupedByPayee, (obj, index) => {
      var itemIndex = findIndex(obj?.items, itemObj => itemObj?.id === item.id);
      if (itemIndex !== -1) {
        let total = get(this.state.invoiceItemsGroupedByPayee, `${index}.items.${itemIndex}.total`);
        let prevValue = get(this.state.invoiceItemsGroupedByPayee, `${index}.items.${itemIndex}.${key}`);
        let prevTotalValue = prevValue;
        let formattedValueTotal = formattedValue;
        if (isEpr) {
          let gst = get(this.state.invoiceItemsGroupedByPayee, `${index}.items.${itemIndex}.gst`);
          let prevValueGst = prevValue * this.state.gstRate;
          prevTotalValue = prevValue + prevValueGst;
          gst -= prevValueGst;
          let formattedValueGst = formattedValue * this.state.gstRate;
          formattedValueTotal = formattedValue + formattedValueGst;
          gst += formattedValueGst;
          set(newState.invoiceItemsGroupedByPayee, `${index}.items.${itemIndex}.gst`, gst.toFixed(2));
        }
        total -= prevTotalValue;
        if(formattedValueTotal)
          total += formattedValueTotal;

        set(newState.invoiceItemsGroupedByPayee, `${index}.items.${itemIndex}.${key}`, formattedValue);
        set(newState.invoiceItemsGroupedByPayee, `${index}.items.${itemIndex}.total`, total);
        let itemId = get(obj.items, `${itemIndex}.id`);
        set(newState.levyEprMapping, `${obj.companyId}.${itemId}.${key}`, formattedValue);
        return;
      }
    });
    let selectedItemIndex = findIndex(this.state.selectedInvoiceItems, itemObj => itemObj.id === item.id);
    if (selectedItemIndex !== -1) {
      let total = get(this.state.selectedInvoiceItems, `${selectedItemIndex}.total`);
      let prevValue = get(this.state.selectedInvoiceItems, `${selectedItemIndex}.${key}`);
      let prevTotalValue = prevValue;
      let formattedValueTotal = formattedValue;
      if (isEpr) {
        let gst = get(this.state.selectedInvoiceItems, `${selectedItemIndex}.gst`);
        let prevValueGst = prevValue * this.state.gstRate;
        prevTotalValue = prevValue + prevValueGst;
        gst -= prevValueGst;
        let formattedValueGst = formattedValue * this.state.gstRate;
        formattedValueTotal = formattedValue + formattedValueGst;
        gst += formattedValueGst;
        set(newState.selectedInvoiceItems, `${selectedItemIndex}.gst`, gst.toFixed(2));
      }
      total = (total - prevTotalValue) + formattedValueTotal;
      set(newState.selectedInvoiceItems, `${selectedItemIndex}.${key}`, formattedValue);
      set(newState.selectedInvoiceItems, `${selectedItemIndex}.total`, total);
    }
    this.setState(newState);
  }


  toFormattedDate = date => {
    if(date) {
      const mDate = moment(date)
      return mDate.isValid() ? mDate.format(getCountryFormats().date) : date
    }
    return date
  }

  includeLevyAdjustments = () => {
    if (has(currentUserCompany(), 'includeLevyAdjustments'))
      return get(currentUserCompany(), 'includeLevyAdjustments')
    return true;
  };

  getPayableInvoiceItems(payeeCompanyId, canForceStopLoader = true) {
    const { invoicesItems } = this.state;
    let allInvoiceItems = [];
    const newState = {...this.state};
    const company = currentUserCompany();
    if (!isEmpty(invoicesItems)) {
      let items = invoicesItems
      if(payeeCompanyId)
        items = filter(invoicesItems, {companyId: payeeCompanyId})
      map(items, obj => {
        if (typeof obj === 'object') {
          obj.payeeName = obj.companyName
          let levyEprPreference = get(this.state.levyEprPreferences, obj.companyId);
          let defaultValue = get(this.state.levyEprPreferences, 'defaultValue', true) ? !isCompanyGrower(company) && obj.isGrower : false
          obj.levy = get(levyEprPreference, 'levy', defaultValue);
          obj.epr = get(levyEprPreference, 'epr', defaultValue);
          if (obj.recipients && !isEmpty(obj.recipients)) {
            let recipientDisplayStr = "";
            map(obj.recipients, recipient => {
              if (isEmpty(recipientDisplayStr))
                recipientDisplayStr = recipientDisplayStr + recipient.email;
              else
                recipientDisplayStr = recipientDisplayStr + ", " + recipient.email;
            });
            obj['recipientDisplay'] = 'Recipients: (' + recipientDisplayStr + ')';
          }
          obj.allSelected = false;
          allInvoiceItems = [...allInvoiceItems, ...(obj.items || [])];
          newState.levyEprMapping[obj.companyId] = {};
          map(obj.items, invoiceItem => {
            invoiceItem.paymentDue = this.toFormattedDate(invoiceItem.paymentDue)
            invoiceItem.levy = invoiceItem.levy || 0;
            invoiceItem.epr = invoiceItem.epr || 0;
            invoiceItem.carry = invoiceItem.carry || 0;
            let eprTotal = invoiceItem.epr + (invoiceItem.epr * this.state.gstRate);
            invoiceItem.isEditable = invoiceItem.invoiceStatus === 'draft' && !get(invoiceItem, 'isBlendingFees');
            if (!this.includeLevyAdjustments() && !isEmpty(invoiceItem.levyItems)) {
              let adjustmentsTotal = invoiceItem.levyItems.reduce((total, item) => total + item.adjustedPriceValue, 0);
              invoiceItem.levy = invoiceItem.levy - adjustmentsTotal;
              const updatedLevyItems = invoiceItem.levyItems.map(item => {
                return {
                  ...item,
                  total: item.total - item.adjustedPriceValue
                };
              });
              invoiceItem.levyItems = [...updatedLevyItems];
            }
            invoiceItem.total = invoiceItem.total + invoiceItem.levy + eprTotal + invoiceItem.carry;
            if (invoiceItem.isEditable) {
              if (!obj.levy && invoiceItem.levy)
                invoiceItem.total -= invoiceItem.levy;
              if (!obj.epr && eprTotal)
                invoiceItem.total -= eprTotal;
            }
            newState.levyEprMapping[obj.companyId][invoiceItem.id] = {};
            newState.levyEprMapping[obj.companyId][invoiceItem.id] = { levy: invoiceItem.levy, epr: invoiceItem.epr};
            if (!obj.levy && invoiceItem.isEditable) {
              delete invoiceItem.levy;
            }
            if (!obj.epr && invoiceItem.isEditable) {
              delete invoiceItem.epr;
            }
            if(invoiceItem.itemType === 'customitem') {
              delete invoiceItem.carry
              delete invoiceItem.epr
              delete invoiceItem.levy
            }
          });
          obj.chemicalApplicationItems = isEmpty(obj.chemicalApplicationItems) ? [] : obj.chemicalApplicationItems
          obj.chemicalApplicationItems.map(chemicalApplicationItem => {
            let index = findIndex(obj?.items, item => item.itemId === chemicalApplicationItem.chemicalAppliedOnLoadId || item.itemId === chemicalApplicationItem.freightMovementId);
            if (index !== -1) {
              let blendLoadItem = get(obj.items, index);
              chemicalApplicationItem.ref = get(blendLoadItem, 'ref');
              chemicalApplicationItem.itemUrl = get(blendLoadItem, 'itemUrl');
              chemicalApplicationItem.loadRef = get(blendLoadItem, 'loadRef');
              chemicalApplicationItem.contract = get(blendLoadItem, 'contract');
              chemicalApplicationItem.contractUrl = get(blendLoadItem, 'contractUrl');
              chemicalApplicationItem.invoiceUrl = get(blendLoadItem, 'invoiceUrl');
              chemicalApplicationItem.confirmedInvoice = get(blendLoadItem, 'confirmedInvoice');
              chemicalApplicationItem.contractInvoicing = get(blendLoadItem, 'contractInvoicing');
              chemicalApplicationItem.paymentDue = get(blendLoadItem, 'paymentDue');
              chemicalApplicationItem.tonnageDisplayName = get(blendLoadItem, 'tonnageDisplayName');
              chemicalApplicationItem.rateDisplayName = `${getCountryCurrency()} ${chemicalApplicationItem.rate} / ${get(chemicalApplicationItem, 'adjustments.loadUnit')}`;
              chemicalApplicationItem.levy = 0;
              chemicalApplicationItem.epr = 0;
              chemicalApplicationItem.carry = 0;
              chemicalApplicationItem.sellerNgrDetails = get(blendLoadItem, 'sellerNgrDetails');
              obj.items.splice(index + 1, 0, chemicalApplicationItem);
              allInvoiceItems.push(chemicalApplicationItem);
            }
          });
        }
      });
    }
    newState.allInvoiceItems = [...allInvoiceItems];
    newState.count = allInvoiceItems.length;
    newState.invoiceItemsGroupedByPayee = invoicesItems;
    this.setState(newState, () => {
      if(canForceStopLoader)
        this.props.dispatch(forceStopLoader());
      if(this.state.selectedPayeeCompanyIds.includes(payeeCompanyId)) {
        const payee = find(this.state.invoiceItemsGroupedByPayee, {companyId: payeeCompanyId})
        if(payee && payee?.items?.length)
          this.onSelectAllToggle(null, true, payee)
      }
    });
  }

  updateSelectedInvoiceItem = (selected, isChecked, obj) => {
    if (selected.isBlended) {
      if (isChecked) {
        let relatedItems = this.state.allInvoiceItems.filter(item => item.freightMovementId && item.freightMovementId === selected.freightMovementId && includes([LOAD_ITEM_TYPE, FREIGHT_CONTRACT_ITEM_TYPE], item.itemType) && item.id !== selected.id);
        let items = [...relatedItems, selected];
        var index = findIndex(this.state.invoiceItemsGroupedByPayee, item => item.companyId === obj.companyId);
        const newState = {...this.state};
        map(this.state.invoiceItemsGroupedByPayee[index].items, (item, itemIndex) => {
          if (item.isChemicalItem && item.freightMovementId === selected.freightMovementId) {
            set(newState.invoiceItemsGroupedByPayee, `${index}.items.${itemIndex}.isCheckedItem`, true);
          }
        })
        newState.selectedInvoiceItems = [...this.state.selectedInvoiceItems, ...items];
        this.setState(newState, () => this.updateSelectedPayeeCompanyIdsFromSelectedItem(obj));
      }
      else {
        const newState = {...this.state};
        newState.selectedPayeeCompanyIds = without(newState.selectedPayeeCompanyIds, obj.companyId)
        remove(newState.selectedInvoiceItems, selectedItem => selectedItem.freightMovementId && selectedItem.freightMovementId === selected.freightMovementId && includes([LOAD_ITEM_TYPE, FREIGHT_CONTRACT_ITEM_TYPE], selectedItem.itemType));
        var objIndex = findIndex(this.state.invoiceItemsGroupedByPayee, item => item.companyId === obj.companyId);
        map(this.state.invoiceItemsGroupedByPayee[objIndex].items, (item, itemIndex) => {
          if (item.isChemicalItem && item.freightMovementId === selected.freightMovementId) {
            set(newState.invoiceItemsGroupedByPayee, `${objIndex}.items.${itemIndex}.isCheckedItem`, false);
          }
        })
        this.setState(newState, () => this.updateSelectedPayeeCompanyIdsFromSelectedItem(obj));
      }
    }
    else if (selected.itemType === LOAD_ITEM_TYPE) {
      if (isChecked) {
        let relatedItems = this.state.allInvoiceItems.filter(item => item.titleTransferId && item.titleTransferId === selected.titleTransferId && item.itemType === LOAD_ITEM_TYPE && item.id !== selected.id);
        let items = [...relatedItems, selected];
        this.setState({selectedInvoiceItems: [...this.state.selectedInvoiceItems, ...items]}, () => this.updateSelectedPayeeCompanyIdsFromSelectedItem(obj));
      }
      else {
        const newState = {...this.state};
        remove(newState.selectedInvoiceItems, selectedItem => selectedItem.titleTransferId && selectedItem.titleTransferId === selected.titleTransferId && selectedItem.itemType === LOAD_ITEM_TYPE);
        this.setState(newState, () => this.updateSelectedPayeeCompanyIdsFromSelectedItem(obj));
      }
    }
    else {
      this.setState({selectedInvoiceItems: isChecked ? [...this.state.selectedInvoiceItems, selected] : reject(this.state.selectedInvoiceItems, {id: selected.id})}, () => this.updateSelectedPayeeCompanyIdsFromSelectedItem(obj));
    }
  }

  updateSelectedPayeeCompanyIdsFromSelectedItem = payee => {
    const [isSome, isAll] = this.isSomeSelectedForPayee(payee)
    const isAlreadySelectedPayee = this.state.selectedPayeeCompanyIds.includes(payee.companyId)
    if(isAll && !isAlreadySelectedPayee)
      this.setState({selectedPayeeCompanyIds: [...this.state.selectedPayeeCompanyIds, payee.companyId] })
    else if(isSome && isAlreadySelectedPayee)
      this.setState({selectedPayeeCompanyIds: without(this.state.selectedPayeeCompanyIds, payee.companyId) })
    else if (!isAll && !isSome && isAlreadySelectedPayee)
      this.setState({selectedPayeeCompanyIds: without(this.state.selectedPayeeCompanyIds, payee.companyId) })
  }

  isSelected = (invoiceItem, payee) => Boolean(this.state.selectedPayeeCompanyIds?.includes(payee?.companyId) || find(this.state.selectedInvoiceItems, {id: invoiceItem.id}) || invoiceItem.isCheckedItem);

  onSelectAllToggle = (event, value, obj) => {
    const newState = {...this.state};
    var index = findIndex(this.state.invoiceItemsGroupedByPayee, item => item.companyId === obj.companyId);
    set(newState, `invoiceItemsGroupedByPayee.${index}.allSelected`, value);
    if (value) {
      newState.selectedInvoiceItems = [...this.state.selectedInvoiceItems, ...obj.items];
    }
    else {
      let itemIds = map(obj.items, 'id');
      newState.selectedInvoiceItems = this.state.selectedInvoiceItems.filter(item => !itemIds.includes(item.id));
    }
    this.setState(newState);
  }

  isSomeSelectedForPayee = payee => {
    const itemIds = map(payee?.items, 'id') || []
    if(itemIds.length) {
      const selected = filter(this.state.selectedInvoiceItems, item => itemIds.includes(item.id) && isEqual(payee.companyName, item.companyName));
      return [Boolean(selected.length && selected.length !== itemIds.length), Boolean(selected.length && selected.length === itemIds.length)]
    }
    return [false, false] // some not all, all
  }

  handleCheckboxChange(event, obj, key) {
    event.preventDefault()
    event.stopPropagation()
    const company = currentUserCompany();
    const data = {}
    var index = findIndex(this.state.invoiceItemsGroupedByPayee, item => item.companyId === obj.companyId);
    let value = !get(obj, key);
    data['payee_company_id'] = obj.companyId;
    data['settings'] = {};
    data['settings'][key] = value;
    APIService.companies()
      .appendToUrl(`${company.id}/payment-run-levy-epr-preferences/`)
      .put({paymentRunEprLevyPreference: data})
      .then(() => {
        const newState = {...this.state};
        if (value) {
          map(this.state.invoiceItemsGroupedByPayee[index].items, (item, itemIndex) => {
            if (item.isEditable) {
              let keyValue = get(this.state.levyEprMapping, `${obj.companyId}.${item.id}.${key}`);
              let currentTotal = item.total ? parseFloat(item.total) : 0;
              let newTotal = currentTotal + keyValue;
              if (key === 'epr') {
                let currentGst = item.gst ? parseFloat(item.gst) : 0;
                let eprGst = keyValue * this.state.gstRate;
                let newGst = currentGst + eprGst;
                newTotal += eprGst
                set(newState.invoiceItemsGroupedByPayee, `${index}.items.${itemIndex}.gst`, newGst.toFixed(2));
              }
              var selectedItemIndex = findIndex(this.state.selectedInvoiceItems, selectedItem => selectedItem.id === item.id);
              if (selectedItemIndex !== -1) {
                set(newState.selectedInvoiceItems, `${selectedItemIndex}.${key}`, keyValue);
              }
              set(newState.invoiceItemsGroupedByPayee, `${index}.items.${itemIndex}.${key}`, keyValue);
              set(newState.invoiceItemsGroupedByPayee, `${index}.items.${itemIndex}.total`, newTotal.toFixed(2));
            }
          });
        }
        else {
          map(this.state.invoiceItemsGroupedByPayee[index].items, (item, itemIndex) => {
            if (item.isEditable) {
              let currentTotal = item.total ? parseFloat(item.total) : 0;
              let keyValue = get(this.state.invoiceItemsGroupedByPayee, `${index}.items.${itemIndex}.${key}`);
              let newTotal = currentTotal - keyValue;
              if (key === 'epr') {
                let currentGst = item.gst;
                let eprGst = item.epr * this.state.gstRate;
                let newGst = currentGst - eprGst;
                newTotal -= eprGst
                set(newState.invoiceItemsGroupedByPayee, `${index}.items.${itemIndex}.gst`, newGst.toFixed(2));
              }
              var selectedItemIndex = findIndex(this.state.selectedInvoiceItems, selectedItem => selectedItem.id === item.id);
              if (selectedItemIndex !== -1) {
                set(newState.selectedInvoiceItems, `${selectedItemIndex}.${key}`, null);
              }
              set(newState.invoiceItemsGroupedByPayee, `${index}.items.${itemIndex}.total`, newTotal.toFixed(2));
              delete newState.invoiceItemsGroupedByPayee[index].items[itemIndex][key];
            }
          });
        }
        set(newState.invoiceItemsGroupedByPayee, `${index}.total`, sum(newState.invoiceItemsGroupedByPayee[index].items.map(i => parseFloat(i.total))))
        set(newState.invoiceItemsGroupedByPayee, `${index}.${key}`, value);
        this.setState(newState);
      })
    return false
  }

  handleFilters = bool => {
    this.setState({
      applyFilters: bool,
      openSideDrawer: bool,
      expanded: [],
      loadingItemsFor: []
    });
  };

  handleFilterState = (key, value) => {
    this.setState({[key]: value}, () => {
      if(key === 'applyFilters' && value) {
        const { filters } = this.state;
        delete filters['commodity__id__in'];
        APIService
          .profiles()
          .filters()
          .post({ invoice_payable: filters }, this.props.token)
          .then(res => {
            this.setState({selectedItems: [], selectedPayeeCompanyIds: [], filters: get(res, 'filters.invoice_payable', {})}, () => {
              this.props.isLoading();
              this.fetchInvoices();
            })
          });
      }
    });
  };

  handleBankProcessingDateChange(value) {
    const newState = {...this.state};
    newState.bankProcessingDate.value = value;
    newState.bankProcessingDate.error = '';
    this.setState(newState);
  }

  onCloseDownloadResponse() {
    this.props.setDownloadBar(false);
  }

  onDownloadResponse(message) {
    this.props.setDownloadBar(message, true, this.onCloseDownloadResponse);
  }
  _attachCSVEventListener() {
    attachCSVEventListener('payables-csv-ready', 'Invoice Payables', this.onDownloadResponse);
  }

  fetchCSVData = (callback) => {
    const company = currentUserCompany();
    var param = this.state.isFilteredCsv ? 'show_filters': '';
    if (this.state.customColumns) {
      param = param.length == 0 ? param + 'custom_csv' : param + '&custom_csv';
    }
    const { setDownloadBar } = this.props;
    setDownloadBar('Your Payables CSV is getting prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true);
    const service = APIService.invoices();
    service.appendToUrl(`payables/csv/?company_id=${company.id}&${param}`);
    service
      .get(this.props.token, {
        'Content-Type': 'text/csv',
        Accept: 'text/csv',
      })
      .then(() => this.setState({csvPopup: false}));
    if (callback) {
      callback();
    }
  };

  handleGeneratePaymentRunClick() {
    const unverifiedNgrsForCompanies = uniqBy([
      ...flatten(this.state.selectedPayeeCompanyIds?.map(companyId => find(this.state.invoiceItemsGroupedByPayee, {companyId: companyId})?.unverifiedNgrs)),
      ...compact(map(this.state.selectedInvoiceItems, item => item?.sellerNgrDetails?.isVerified ? null : item.sellerNgrDetails))
    ], 'ngrNumber')

    this.setState({
      unverifiedNgrData: map(unverifiedNgrsForCompanies, sellerNgrDetails => {
        const { ngrType, ngrNumber, bankDetails } = sellerNgrDetails;
        if (isEmpty(bankDetails)) {
          return {
            companyName: '',
            ngrNumber,
            ngrType,
            shareholderPercent: '',
            bsbNumber: '',
            name: '',
            accountName: '',
            accountNumber: '',
          }
        }
        else if (isEqual(ngrType, 'shared')) {
          return map(bankDetails, item => {
            const { companyName, shareholderPercent, bsbNumber, name, accountName, accountNumber } = item;
            return {
              companyName,
              ngrNumber,
              ngrType,
              shareholderPercent,
              bsbNumber,
              name,
              accountName,
              accountNumber,
            };
          });
        } else {
          const [item] = bankDetails;
          const { companyName, shareholderPercent, bsbNumber, name, accountName, accountNumber } = item;
          return {
            companyName,
            ngrNumber,
            ngrType,
            shareholderPercent,
            bsbNumber,
            name,
            accountName,
            accountNumber,
          };
        }
      }),
    });

    const company = currentUserCompany();
    let itemsWithIdenticalInvoices = {};
    let xeroMissingContacts = []
    let distanceMoreThanX = []
    let invoiceItemsWithUnMatchedGradesWithContract = []
    let minDistanceForWarning = 600
    const checkXeroContactStatus = company.xeroEnabled;
    let selectedInvoiceItems = [...this.state.selectedInvoiceItems, ...flatten(compact(map(this.state.selectedPayeeCompanyIds, companyId => find(this.state.invoiceItemsGroupedByPayee, {companyId: companyId})?.items)))];
    selectedInvoiceItems = uniq(selectedInvoiceItems)
    remove(selectedInvoiceItems, selectedInvoiceItem => selectedInvoiceItem.isChemicalItem);
    map(selectedInvoiceItems, selectedItem => {
      let invoice = get(selectedItem, 'invoice');
      if(selectedItem?.estimatedDistance && selectedItem.estimatedDistance > minDistanceForWarning && selectedItem?.levy !== undefined)
        distanceMoreThanX.push(selectedItem)
      if(checkXeroContactStatus) {
        const details = find(this.state.invoicesItems, invoiceItem => find(invoiceItem.items, item => item.ref === selectedItem.ref))
        if(details?.companyId && !details.xeroContactStatus) {
          xeroMissingContacts.push(details.companyName)
        }
      }
      if (!get(selectedItem, 'isGradeMatchingWithContract')){
        invoiceItemsWithUnMatchedGradesWithContract.push(selectedItem)
      }
      if (!has(itemsWithIdenticalInvoices, invoice)) {
        let invoiceItemsForSelectedItemInvoice = this.state.allInvoiceItems.filter(item => get(item, 'invoice') === invoice);
        if (invoiceItemsForSelectedItemInvoice.length > 1) {
          itemsWithIdenticalInvoices[invoice] = {};
          itemsWithIdenticalInvoices[invoice]['unselected'] = invoiceItemsForSelectedItemInvoice.filter(item => !this.state.selectedInvoiceItems.find(_item=> item?.itemType === 'customitem' ? _item.description === item.description : _item.ref === item.ref)).map(item => item?.itemType === 'customitem' ? `${item.ref} - ${item.description}` : item.ref);
          itemsWithIdenticalInvoices[invoice]['selected'] = invoiceItemsForSelectedItemInvoice.filter(item => this.state.selectedInvoiceItems.find(_item=> item?.itemType === 'customitem' ? _item.description === item.description : _item.ref === item.ref)).map(item => item?.itemType === 'customitem' ? `${item.ref} - ${item.description}` : item.ref);
        }
      }
    });

    xeroMissingContacts = uniq(xeroMissingContacts)

    let resultArray = []
    map(Object.entries(itemsWithIdenticalInvoices), item => {
      let invoice = get(item, '0');
      let selectedItems = get(item, '1.selected');
      let unselectedItems = get(item, '1.unselected');
      if (!isEmpty(unselectedItems)) {
        let unselectedVerb = unselectedItems.length > 1 ? 'have' : 'has';
        let selectedVerb = selectedItems.length > 1 ? 'have' : 'has';
        unselectedItems = `<ul><li>${unselectedItems.join('</li><li>')}</ul>`
        let itemRefs = `${invoice}: ${selectedItems.toString()} ${selectedVerb} been selected but ${unselectedItems} ${unselectedVerb} not been selected.`
        resultArray.push(itemRefs);
      }
    });

    let warningContent = '';
    if(!isEmpty(resultArray)) {
      resultArray = '<li>' + resultArray.join('</li><li>')
      warningContent = `<ul>${resultArray}</url>`
    }
    if(!isEmpty(xeroMissingContacts)) {
      const missingContactsText = '<li>' + xeroMissingContacts.join('</li><li>')
      warningContent += `<div>Following counter parties does not exist in Xero as Contacts:</div><ul>${missingContactsText}</ul>`
    }
    if(!isEmpty(distanceMoreThanX)) {
      const text = '<li>' + distanceMoreThanX.map(item => `${item.ref} - ${item.estimatedDistance} km`).join('</li><li>')
      warningContent += `<div>Following Movements have distance more than 600 km:</div><ul>${text}</ul>`
    }
    if(!isEmpty(invoiceItemsWithUnMatchedGradesWithContract)){
      const text = '<li>' + invoiceItemsWithUnMatchedGradesWithContract.map(item => `${item.ref} - ${item.contractGrades}`).join('</li><li>')
      warningContent += `<div>One or more invoice items contain a grade that doesn't match with their Contract grade(s)</div><ul>${text}</ul>`
    }

    if (warningContent) {
      alertifyjs.confirm(
        'Warning',
        `<div>
            ${warningContent}
            <div>
              <b>Do you want to continue to create Payment Run?</b>
            </div>
        </div>`,
        () => this.setState({openDialog: true}),
        () => { }
      );
    }
    else {
      this.setState({openDialog: true});
    }
  }

  handleEmailRecipients(event, companyId) {
    event.stopPropagation()
    event.preventDefault()
    var index = findIndex(this.state.invoiceItemsGroupedByPayee, item => item.companyId === companyId);
    const selectedRecipients = get(this.state.invoiceItemsGroupedByPayee[index], 'recipients');
    this.setState({selectedRecipients: selectedRecipients}, () => {
      APIService
        .profiles()
        .appendToUrl('employees-signature/')
        .get(null, null, {company_ids: companyId})
        .then(employees => {
          const contactsWithEmail = map(filter(employees, employee => employee.email), employee => ({...employee, title: `${employee.name} (${employee.email})`, value: employee.email}))
          this.setState({sellerEmployeeData: contactsWithEmail, recipientsDialogOpen: true})
        })
    })
    return false
  }

  onRecipientsChange = (event, selected) => {
    this.setState({selectedRecipients: selected, showRecipientsError: !(selected && selected.length <= 3)});
  }

  onDialogClose = () => this.setState({recipientsDialogOpen: false});

  onSubmit = () => {
    if (this.state.selectedRecipients.length > 3) {
      this.setState({showRecipientsError: true});
    }
    else {
      this.setState({recipientsDialogOpen: false, showRecipientsError: false}, () => {
        const companyId = get(this.state.selectedRecipients, '0.companyId');
        var index = findIndex(this.state.invoiceItemsGroupedByPayee, item => item.companyId === companyId);
        var invoiceIds = map(this.state.invoiceItemsGroupedByPayee[index].items, item => item.invoiceId);
        const data = {
          'selectedRecipients': this.state.selectedRecipients,
          'invoiceIds': invoiceIds,
        }
        APIService
          .invoices()
          .appendToUrl('email-recipients/')
          .put(data)
          .then(() => window.location.reload());
      });
    }
  }

  customCsvEnabled(isFilteredCsv) {
    const newState = {...this.state};
    newState.isFilteredCsv = isFilteredCsv;
    if (this.props.currentUser.company.enableCustomCsv) {
      newState.csvPopup = true;
      this.setState(newState);
    }
    else {
      newState.customColumns = false;
      this.setState(newState, this.fetchCSVData);
    }
  }

  async editCustomHeaderOptions() {
    const columnNames = await APIService.profiles().appendToUrl(`${this.props.currentUser.id}/report-preferences/invoice_payable_csv/`).get();
    this.setState({customColumnNames: columnNames, customHeaderOptions: true});
  }

  updateColumnCount(count) {
    this.setState({customColumnTitle: `Edit Columns (${count})`});
  }

  customFilterValueExist = filterKeys => filterKeys.some(key => Boolean(get(this.state.filters, key)))

  filterCriteria = (key, value) => includes(FILTER_KEYS_TO_EXCLUDE, key) ? false : includes(PREDEFINED_DATE_RANGE_FILTER_KEYS, key) && value === 'custom' ? this.customFilterValueExist(get(INVOICE_FILTER_KEYS_MAPPING, key)) : value.length !== 0;

  updateLevies = (item, levies) => {
    const newState = {...this.state};
    map(this.state.invoiceItemsGroupedByPayee, (obj, index) => {
      var itemIndex = findIndex(obj?.items, itemObj => itemObj.id === item.id);
      if (itemIndex !== -1) {
        set(newState.invoiceItemsGroupedByPayee, `${index}.items.${itemIndex}.levyItems`, levies);
        return;
      }
    });
    let selectedItemIndex = findIndex(this.state.selectedInvoiceItems, itemObj => itemObj.id === item.id);
    if (selectedItemIndex !== -1)
      set(newState.selectedInvoiceItems, `${selectedItemIndex}.levyItems`, levies);
    this.setState(newState, () => {
      const levyTotal = levies.reduce((accumulator, levy) => accumulator + levy.total, 0);
      this.handleValueChange(item, levyTotal, 'levy');
    })
  }

  onSelectAllItems = event => {
    event.persist();
    const isChecked = event.target.checked;
    const newState = {...this.state};

    if (isChecked) {
      newState.selectedPayeeCompanyIds = this.allPayeeCompanyIds();
      const expandedItems = this.state.invoiceItemsGroupedByPayee
          .filter(payee => this.state.expanded.includes(payee.companyId))
          .reduce((allItems, payee) => [...allItems, ...(payee.items || [])], []);
      const allItems = [...this.state.selectedInvoiceItems, ...expandedItems];
      newState.selectedInvoiceItems = uniqBy(allItems, 'id');
    } else {
        newState.selectedPayeeCompanyIds = [];
        newState.selectedInvoiceItems = [];
    }
    this.setState({
      selectedPayeeCompanyIds: newState.selectedPayeeCompanyIds,
      selectedInvoiceItems: newState.selectedInvoiceItems
    });
  }

  onSelectPayee = (event, companyId) => {
    event.persist()
    const isChecked = event.target.checked
    this.setState({selectedPayeeCompanyIds: isChecked ? [...this.state.selectedPayeeCompanyIds, companyId] : without(this.state.selectedPayeeCompanyIds, companyId)}, () => {
      const payee = find(this.state.invoiceItemsGroupedByPayee, {companyId: companyId})
      if(payee && payee?.items?.length)
        this.onSelectAllToggle(event, isChecked, payee)
    })
  }

  allPayeeCompanyIds = () => compact(map(this.state.invoiceItemsGroupedByPayee, 'companyId'))

  onExpandAll = event => {
    event.stopPropagation()
    event.preventDefault()
    const items = compact(orderBy(this.state.invoiceItemsGroupedByPayee, 'companyName'))

    if(items?.length) {
      this.props.dispatch(isLoading('LoadingExpandAll'))

      let remainingRequests = items.length

      this.setState({expanded: [...map(items, 'companyName')]}, () => {
        if(remainingRequests === 0) {
          this.props.dispatch(forceStopLoader())
          return
        }

        const onItemComplete = () => {
          remainingRequests--
          if(remainingRequests <= 0) {
            this.props.dispatch(forceStopLoader())
          }
        }

        forEach(items, item => this._fetchItems(item.companyId , item.companyName, onItemComplete))
      })
    }
  }

  onCollapseAll = () => this.setState({expanded: []})

  getSelectedSummary = () => {
    const { invoiceItemsGroupedByPayee, selectedPayeeCompanyIds, selectedInvoiceItems } = this.state
    let count = selectedInvoiceItems?.length || 0
    let total = sum(selectedInvoiceItems?.map(i => parseFloat(i?.total || 0))) || 0

    forEach(selectedPayeeCompanyIds, payeeCompanyId => {
      const payee = find(invoiceItemsGroupedByPayee, {companyId: payeeCompanyId})
      if(!payee.items?.length || !intersection(selectedInvoiceItems?.map(item => item.id), payee?.items?.map(item => item.id))?.length) {
      count += payee.totalItems
      total += parseFloat(payee.total) || 0
      }
    })
    return {count, total}
  }


  render() {
    const { invoiceItemsGroupedByPayee, sellerEmployeeData, recipientsDialogOpen, selectedRecipients, selectedPayeeCompanyIds } = this.state;
    const disablePaymentRun = selectedPayeeCompanyIds.length === 0 && !get(this.state.selectedInvoiceItems, 'length', 0) > 0;
    let payableColumns = [...PAYABLE_INVOICE_ITEMS_COLUMNS];
    payableColumns.splice(10, 0, { key: 'levy', header: 'Levy', className: 'xsmall', editComponent: InvoiceGrainLevies, isColumnEditable: true, type: 'currency', editableFieldType: 'number', updateData: this.updateLevies},)
    payableColumns.splice(2, 0, { header: '', className: 'xxsmall', sortable: false, formatter: gradeMismatchInfoIcon})
    const allPayeeCompanyIds = this.allPayeeCompanyIds()
    const invoiceItems = orderBy(invoiceItemsGroupedByPayee, obj => obj?.payeeName?.toLowerCase())
    const isExpandAll = Boolean(this.state.expanded?.length === this.state.invoiceItemsGroupedByPayee?.length && this.state.expanded?.length)
    const isEverythingSelected = Boolean(selectedPayeeCompanyIds.length && selectedPayeeCompanyIds.length == allPayeeCompanyIds.length)
    const isSomeOfEverythingSelected = Boolean(selectedPayeeCompanyIds.length && selectedPayeeCompanyIds.length != allPayeeCompanyIds.length)
    const { count, total} = this.getSelectedSummary()
    const collapseButtonLabel =  invoiceItemsGroupedByPayee?.length > 1 ? 'Collapse All': "Collapse"
    const expandButtonLabel = invoiceItemsGroupedByPayee?.length > 1 ? "Expand All": "Expand"

    return (
      <Paper className="paper-table">
        <div>
          <div style={{display: 'inline-flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
            <div style={{display: 'inline-flex', alignItems: 'center'}}>
              <span style={{ fontWeight: 'bold', fontSize: '20px' }}>
                Payable
                <FormControlLabel onClick={this.onSelectAllItems} sx={{marginLeft: '16px'}} control={<Checkbox indeterminate={isSomeOfEverythingSelected} checked={isEverythingSelected} />} label="Select All" />
                <Chip
                  icon={isExpandAll ? <CollapseIcon fontSize='inherit' /> : <ExpandIcon fontSize='inherit' />}
                  label={isExpandAll ? collapseButtonLabel : expandButtonLabel}
                  onClick={isExpandAll ? this.onCollapseAll : this.onExpandAll}
                />
                {
                  count > 0 &&
                    <span style={{fontSize: '1rem', marginLeft: '20px', fontWeight: 'normal'}}>
                      Selected Items: <b style={{marginRight: '10px'}}>{count.toLocaleString()}</b> Total: <b>{`${getCountryCurrency()} ${Number(total.toFixed(2)).toLocaleString()}`}</b>
                  </span>
                }
              </span>
            </div>
            <div style={{display: 'inline-flex', alignItems: 'center'}}>
              <Button
                variant="contained"
                type='button'
                color='primary'
                onClick={this.handleGeneratePaymentRunClick}
                style={{ float: 'right', marginLeft: '10px' }}
                disabled={disablePaymentRun}
              >
                Generate Payment Run
              </Button>
              <Tooltip title='Apply filters' placement='top'>
                <Button
                  value={this.state.applyFilters}
                  variant="contained"
                  type='button'
                  onClick={() => this.handleFilters(true)}
                  color='primary'
                  className='add-button'
                  style={{ float: 'right', marginLeft: '10px' }}
                >
                  <FilterListIcon style={{ paddingRight: '5px' }} />
                  FILTERS{' '}
                  {+!isEmpty(Object.entries(this.state.filters).filter(val => this.filterCriteria(val[0], val[1])))
                   ? `(${Object.entries(this.state.filters).filter(val => this.filterCriteria(val[0], val[1])).length})`
                   : ''}
                </Button>
              </Tooltip>
              <CommonListingButton
                defaultHandler={() => this.customCsvEnabled(false)}
                showMenus={!isEmpty(Object.entries(this.state.filters).filter(val => get(val, '1.length', 0) !== 0))}
                optionMapper={[
                  { name: 'Complete List', fx: () => this.customCsvEnabled(false) },
                  { name: 'Filtered List', fx: () => this.customCsvEnabled(true) },
                ]}
                title='Download Contents of the table in a CSV'
                name='Export'
              />
            </div>
          </div>
          <Divider light style={{ marginLeft: '-10px', marginRight: '-10px', marginTop: '8px' }} />
          {
            !isEmpty(invoiceItemsGroupedByPayee) &&
              <Virtuoso
                data={invoiceItems}
                style={{ height: '100vh' }}
                itemContent={(index, obj) => {
                  const isExpanded = this.state.expanded.includes(obj.companyName)
                  const [isSomeSelected,] = this.isSomeSelectedForPayee(obj)
                  return (
                    <div id={index} key={index}>
                      <div style={{ marginLeft: '-10px', marginRight: '-10px', background: '#F5F5F5', height: '50px' }}>
                        <Tooltip title={isExpanded ? 'Click to collapse' : 'Click to expand and view items'}>
                          <span style={{display: 'inline-flex', alignItems: 'center', cursor: 'pointer', width: 'calc(100% - 400px)'}} onClick={event => this.fetchItems(event, obj.companyId, obj.payeeName)}>
                            <span style={{ paddingLeft: '15px', fontSize: '20px', lineHeight: '50px' }}>{obj.payeeName}</span>
                            <span style={{ paddingLeft: '15px', fontSize: '15px', lineHeight: '50px', marginTop: '1px' }}>
                              {
                                <>
                                  {
                                    get(obj, 'recipientDisplay') ? get(obj, 'recipientDisplay') : ''
                                  }
                                  <Tooltip title='Edit Recipients'>
                                    <IconButton disabled={!isExpanded} size='small' onClick={(event) => this.handleEmailRecipients(event, obj.companyId)} sx={get(obj, 'recipientDisplay') ? {marginLeft: '8px'} : {}}>
                                      <Create fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              }
                            </span>
                          </span>
                        </Tooltip>
                        <div style={{ float: "right", lineHeight: '50px', maxWidth: '400px' }}>
                          <FormControlLabel
                            sx={{marginRight: '20px'}}
                            control={
                              <Badge badgeContent={obj.totalItems} color='secondary' sx={{'.MuiBadge-badge': {left: '-20px', top: '16px'}}} anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}>
                                <Checkbox
                                  sx={{padding: '6px'}}
                                  indeterminate={isSomeSelected}
                                  checked={selectedPayeeCompanyIds.includes(obj.companyId)}
                                  onChange={event => this.onSelectPayee(event, obj.companyId)}
                                  size='small'
                                />
                              </Badge>
                            }
                            label="Select All"
                          />
                          <FormControlLabel
                            style={{marginRight: '20px'}}
                            control={
                              <Checkbox
                                sx={{padding: '6px'}}
                                checked={obj.levy}
                                onChange={event => this.handleCheckboxChange(event, obj, 'levy')}
                                disabled={!isExpanded}
                                size='small'
                              />
                            }
                            label="Levies"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{padding: '6px'}}
                                checked={obj.epr}
                                onChange={event => this.handleCheckboxChange(event, obj, 'epr')}
                                disabled={!isExpanded}
                                size='small'
                              />
                            }
                            label="EPR"
                          />
                        </div>
                      </div>
                      <div style={isExpanded ? {} : { marginTop: '10px' }}>
                        {
                          (isEmpty(obj.items) || !isExpanded) ?
                            (
                              isExpanded ? <Skeleton width='100%' height={100} /> : <div />
                            ) :
                            <InvoicePayableTable
                              height={(((obj.items?.length || 0) + 1) * 65) + 16 + 'px'}
                              items={obj.items}
                              columns={[
                                {
                                  size: 'small',
                                  sx:{padding: 0},
                                  align: 'left',
                                  header: 'All',
                                  checkbox: true,
                                  className: 'xxxsmall',
                                  onChange:(selected, isChecked) => this.updateSelectedInvoiceItem(selected, isChecked, obj),
                                  func: item => this.isSelected(item, obj),
                                  selectAll: true,
                                  checked: selectedPayeeCompanyIds?.includes(obj.companyId),
                                  indeterminate: isSomeSelected,
                                  onSelectAll: event => this.onSelectPayee(event, obj.companyId)
                                },
                                ...payableColumns
                              ]}
                              handleValueChange={(item, value, key) => this.handleValueChange(item, value, key)}
                            />
                        }
                      </div>
                    </div>
                  )
                }}
              />
          }
        </div>
        {
          recipientsDialogOpen &&
            <Dialog open keepMounted fullWidth onClose={this.onDialogClose}>
              <DialogTitleWithCloseIcon
                onClose={this.onDialogClose}
                closeButtonStyle={{ marginTop: '0px' }}
                id='form-dialog-title'>
                Update Recipients
              </DialogTitleWithCloseIcon>
              <DialogContent>
                <React.Fragment>
                  <div>
                    <EmailAutocomplete
                      options={sellerEmployeeData}
                      onChange={this.onRecipientsChange}
                      selected={selectedRecipients}
                    />
                  </div>
                  {
                    this.state.showRecipientsError &&
                      <div style={{ marginTop: '3px', color: '#FF0000', fontSize: '15px' }}>
                        You can select only 3 emails.
                      </div>
                  }
                </React.Fragment>
              </DialogContent>
              <DialogActions>
                <Button color='default' onClick={this.onDialogClose}>Cancel</Button>
                <Button onClick={this.onSubmit}>Proceed</Button>
              </DialogActions>
            </Dialog>
        }
        <Dialog
          open={this.state.openDialog}
          onClose={() => this.setState({ openDialog: false })}
          aria-labelledby="form-dialog-title"
          fullWidth
          PaperProps={{ style: { maxWidth: !isEmpty(this.state.unverifiedNgrData) ? '1400px' : '600px' } }}
        >
          <DialogTitleWithCloseIcon
            onClose={() => this.setState({openDialog: false})}
            closeButtonStyle={{ marginTop: '0px' }}
            id='form-dialog-title'>
            Payment Run
          </DialogTitleWithCloseIcon>
          <DialogContent style={{ marginTop: '15px' }}>
            <span style={{float: 'left'}}>
              This will generate invoices and a payment run for the selected items.
            </span>
            <div style={{ marginTop:'35px', marginBottom: '20px' }}>
              <CommonDatePicker
                id='bankProcessingDate'
                floatingLabelText='Bank Processing Date'
                onChange={this.handleBankProcessingDateChange}
                errorText={this.state.bankProcessingDate.error}
                value={this.state.bankProcessingDate.value}
                minDate={new Date()}
              />
            </div>
            {
              !isEmpty(this.state.unverifiedNgrData) &&
                <div>
                  <span style={{ marginTop: '35px' }}>
                    The following NGR&apos;s bank details are not verified from NGR.com. Please verify the account details before making the payment.
                  </span>
                  <Table style={{ border: "none" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Company Name</TableCell>
                        <TableCell>NGR Number</TableCell>
                        <TableCell>NGR Type</TableCell>
                        <TableCell>Shareholder Percent</TableCell>
                        <TableCell>{`${getCountryLabel('bsb')} Number`}</TableCell>
                        <TableCell>Bank Name</TableCell>
                        <TableCell>Account Name</TableCell>
                        <TableCell>Account Number</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {map(this.state.unverifiedNgrData, row => (
                        <TableRow key={row.ngrNumber}>
                          <TableCell>{row.companyName}</TableCell>
                          <TableCell>{row.ngrNumber}</TableCell>
                          <TableCell>{row.ngrType}</TableCell>
                          <TableCell>{row.shareholderPercent}</TableCell>
                          <TableCell>{row.bsbNumber}</TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.accountName}</TableCell>
                          <TableCell>{row.accountNumber}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
            }
          </DialogContent>
          <DialogActions>
            <Button
              type='button'
              onClick={() => this.setState({openDialog: false})}
              variant='outlined'>
              Cancel
            </Button>
            <Button type='button' onClick={this.generatePaymentRun} color='primary' variant='contained'>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.csvPopup} onClose={() => this.setState({csvPopup: false})} aria-labelledby='form-dialog-title' fullWidth>
          <DialogTitleWithCloseIcon
            onClose={() => this.setState({csvPopup: false})}
            closeButtonStyle={{ marginTop: '0px' }}
            id='form-dialog-title'>
            Download Invoice Payable Data
          </DialogTitleWithCloseIcon>
          <DialogContent style={{ marginTop: '15px' }}>
            <span style={{float: 'left'}}>Select checkbox for custom csv download</span>
            <div className='col-sm-6 padding-reset' style={{marginTop: '10px'}}>
              <Checkbox
                id={'custom-headers'}
                checked={this.state.customColumns}
                style={{ height: '40px' }}
                onChange={() => this.setState({customColumns: !this.state.customColumns})}
              />
              Custom Columns
              <a onClick={() => this.editCustomHeaderOptions()} hidden={!this.state.customColumns} style={CUSTOM_HEADER_EDIT}>View & Update</a>
            </div>
            <SideDrawer
              isOpen={this.state.customHeaderOptions}
              title={this.state.customColumnTitle}
              onClose={() => this.setState({customHeaderOptions: false})}
              size="small"
            >
              <CustomHeaderOptions
                customColumns={this.state.customColumnNames}
                closeDrawer={() => this.setState({customHeaderOptions: false})}
                user={this.props.currentUser}
                token={this.props.token}
                csv_type="invoice_payable_csv"
                updateColumnCount={(count) => this.updateColumnCount(count)}
              />
            </SideDrawer>
          </DialogContent>
          <DialogActions>
            <Button
              type='button'
              onClick={() => this.setState({csvPopup: false})}
              variant='outlined'>
              Cancel
            </Button>
            <Button type='button' onClick={() => this.fetchCSVData()} color='primary' variant='outlined'>
              Download
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.applyFilters && (
          <SideDrawer isOpen={this.state.openSideDrawer} title='Filters' size='big' onClose={() => this.handleFilters(false)} app='filters'>
            <InvoiceFilters
              handleFilterState={this.handleFilterState}
              filters={this.state.filters}
              payableInvoice={true}
            />
          </SideDrawer>
        )}
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    invoiceItems: state.companies.invoices.invoiceItemsGroupedByPayees,
    breadcrumbs: state.main.breadcrumbs,
    currentUser: state.main.user.user,
  };
};

const mapDispatchToProps = dispatch => ({
  setBreadcrumbs: breadcrumbs => dispatch(setBreadcrumbs(breadcrumbs)),
  setHeaderText: text => dispatch(setHeaderText(text)),
  setLoadingText: text => dispatch(setLoadingText(text)),
  isLoading: (waitForComponent) => dispatch(isLoading(waitForComponent)),
  forceStopLoader: () => dispatch(forceStopLoader()),
  setDownloadBar: (message, isOpen, onClose) => dispatch(setDownloadBar(message, isOpen, onClose)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePayable);
