import { connect } from 'react-redux';
import GenericTable from '../components/GenericTable';
import { clickEditStorageIcon, archiveStorages, removeStorage } from '../actions/companies/storages';
import { isSystemCompany, currentUser } from '../common/utils';
import reject from 'lodash/reject';
import { farmSideDrawerForTransfer } from '../actions/companies/farms';
import { showSideDrawerForMerge } from '../actions/companies/index';

const mapStateToProps = (state, props) => {
  let columns = [
    { key: 'name', header: 'Storage Name', },
    { key: 'typeName', header: 'Type' },
    { key: 'size', header: `Size (${currentUser()?.unit})` },
    {
      key: 'address.address', header: 'Address', map: {
        name: 'address.address',
        lat: 'address.latitude',
        lng: 'address.longitude'
      }
    }
  ];
  const config = {
    columns: columns,
    items: reject(state.companies.storages.items, { isGate: true }),
    orderBy: 'name',
    order: 'asc',
    triggeredForceStopLoader: state.companies.storages.items.length == 1,
    optionsItems: [],
    displayIDColumn: 'name'
  };

  if (isSystemCompany())
    config.optionsItems.push({ key: 'transfer', text: 'Transfer' });

  if (isSystemCompany())
    config.optionsItems.push({ key : 'merge', text: 'Merge' });

  if(props?.canActOnStorage)
    config.optionsItems.push({ key: 'delete', text: 'Delete' });

  return config;
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleDefaultCellClick: (item) => {
      dispatch(clickEditStorageIcon(item.id));
    },
    handleOptionClick: (index, key, id, item) => {
      if (key === 'transfer') {
        dispatch(farmSideDrawerForTransfer(item, true));
      }
      if (key === 'delete') {
        dispatch(archiveStorages(item, removeStorage));
      }
      if (key === 'merge') {
        dispatch(showSideDrawerForMerge(item, true));
      }
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericTable);
