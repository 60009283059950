import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab } from '@mui/material';
import { forceStopLoader } from '../../actions/main';
import ImpexDocsSetupInstructions from './ImpexDocsSetupInstructions';


class ImpexDocs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tab: 0,
    }
  }

  handleTabChange = (event, value) => this.setState({tab: value})

  componentDidUpdate() {
    if (this.props.isLoading)
      this.props.forceStopLoader()
  }

  render() {
    const { tab } = this.state
    const tabs = [
      {id: 0, label: 'Instructions'},
    ]
    return (
        <div className="subTab">
          <Tabs indicatorColor="primary" className="subTab-header" value={tab} onChange={this.handleTabChange}>
            {
              tabs.map(_tab => (
                <Tab key={_tab.label} label={_tab.label}  className={tab !== _tab.id ? 'unselected-subtab' : ''} />
              ))
            }
          </Tabs>
          {
            tab === 0 &&
            <div className="subTab-container">
              <ImpexDocsSetupInstructions company={this.props.company}/>
            </div>
          }
        </div>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.main.isLoading,
})

const mapDispatchToProps = dispatch => ({
  forceStopLoader: () => dispatch(forceStopLoader()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ImpexDocs);
