import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material/';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import { positiveDecimalFilter } from '../../common/input-filters';
import CommonTextField from './CommonTextField';

class AdditionalMassLimitFields extends React.Component {
  constructor(props) {
    super(props);

    this.RESTRICTED_CODES = [
      {id: 'R', name: 'R'},
      {id: 'R1', name: 'R1'},
      {id: 'R2', name: 'R2'},
      {id: 'R3', name: 'R3'},
    ]
  }

  render() {
    const {
      permitChecked, accreditationNumberChecked, loadSharing, disableLoadSharing, notice, restrictedChecked,
      truckErrors, accreditationNumber, accreditationNumberFieldId,
      restrictedFieldId, restricted, declaredMassLimit, declaredMassLimitFieldId, permitNumberFieldId,
      permitNumber, isPBSMassLimitPermit
    } = this.props;

    return (
      <React.Fragment>
        <div className="col-xs-12" style={{paddingLeft: '0px'}}>
          <div className="col-xs-4">
            <FormControlLabel
              control={
                <Checkbox
                  id='permitChecked'
                  color='primary'
                  checked={permitChecked}
                  onChange={this.props.handlePermitClick}
                  disabled={notice}
                />
              }
              label='I have a Permit'
            />
          </div>
          <div className="col-xs-4">
            <FormControlLabel
              control={
                <Checkbox
                  id='accreditationNumberChecked'
                  color='primary'
                  checked={accreditationNumberChecked}
                  onChange={this.props.handleAccreditationNumberClick}
                  disabled={isPBSMassLimitPermit}
                />
              }
              label='I have an accreditation number'
            />
          </div>
          <div className="col-xs-4">
            <FormControlLabel
              control={
                <Checkbox
                  id='loadSharing'
                  color='primary'
                  checked={Boolean(loadSharing)}
                  onChange={this.props.handleLoadSharingClick}
                  disabled={disableLoadSharing}
                />
              }
              label='Load Sharing Axle Absent'
            />
          </div>
        </div>
        <div className="col-xs-12" style={{paddingLeft: '0px'}}>
          <div className="col-xs-6">
            <FormControlLabel
              control={
                <Checkbox
                  id='restrictedChecked'
                  color='primary'
                  checked={restrictedChecked}
                  onChange={this.props.handleRestrictedClick}
                  disabled={notice}
                />
              }
              label='Restricted'
            />
          </div>
        </div>
        {permitChecked &&
          <div className="col-sm-12 form-wrap" style={{paddingLeft: '0px'}}>
            <div className='col-sm-6 form-wrap-70'>
              <CommonTextField
                id={permitNumberFieldId}
                label="Permit Number"
                value={permitNumber}
                onChange={this.props.handleTruckDetailsChange}
                helperText={truckErrors.permitNumber}
              />
            </div>
            <div className='col-sm-6 form-wrap-70'>
              <CommonTextField
                id={declaredMassLimitFieldId}
                label="Declared Mass Limit"
                value={declaredMassLimit}
                onChange={this.props.handleTruckDetailsChange}
                onKeyDown={event => positiveDecimalFilter(event, 2, 999.99)}
                helperText={truckErrors.declaredMassLimit}
              />
            </div>
          </div>
        }
        {accreditationNumberChecked &&
          <div className="col-sm-12 form-wrap" style={{paddingLeft: '0px'}}>
            <div className='col-sm-6 form-wrap-70'>
              <CommonTextField
                id={accreditationNumberFieldId}
                label="Accreditation Number"
                value={accreditationNumber}
                onChange={this.props.handleTruckDetailsChange}
                helperText={truckErrors.accreditationNumber}
              />
            </div>
          </div>
        }
        {restrictedChecked &&
          <div className="col-sm-12 form-wrap" style={{paddingLeft: '0px'}}>
            <div className='col-sm-6 form-wrap-70'>
              <CommonAutoSelect
                id={restrictedFieldId}
                label='Restricted Code'
                placeholder="Select Restricted Code..."
                items={this.RESTRICTED_CODES}
                value={restricted}
                onChange={this.props.handleTruckDetailsSelectValueChange}
                dataSourceConfig={{id: 'id', text: 'name'}}
                errorText={truckErrors.restricted}
              />
            </div>
            <div className='col-sm-6 form-wrap-70'>
              <CommonTextField
                id={declaredMassLimitFieldId}
                label="Declared Mass Limit"
                value={declaredMassLimit}
                onChange={this.props.handleTruckDetailsChange}
                onKeyDown={event => positiveDecimalFilter(event, 2, 999.99)}
                helperText={truckErrors.declaredMassLimit}
              />
            </div>
          </div>
        }
        {!restrictedChecked && !permitChecked && loadSharing &&
          <div className='col-sm-12 form-wrap' style={{paddingLeft: '0px'}}>
            <div className='col-sm-6 form-wrap-70'>
            <CommonTextField
              id={declaredMassLimitFieldId}
              label="Declared Mass Limit"
              value={declaredMassLimit}
              onChange={this.props.handleTruckDetailsChange}
              onKeyDown={event => positiveDecimalFilter(event, 2, 999999.99)}
              helperText={truckErrors.declaredMassLimit}
            />
            </div>
          </div>
        }
      </React.Fragment>
    )
  }
}

export default AdditionalMassLimitFields;