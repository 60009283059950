import {
  GET_ORDERS_SUCCESS,
  GET_PAGINATED_FREIGHT_ORDERS_SUCCESS,
  GET_ORDER_FIXTURES_SUCCESS,
  SET_SELECTED_ORDER_ID,
  ADD_ORDER_FAILURE,
  START_FETCHING_SELECTED_ORDER,
  RESET_IS_FETCHING_SELECTED_ORDER, RECEIVE_ORDER,
  RECEIVE_ALLOCATION,
  GET_PAGINATED_ORDERS_SUCCESS,
  FREIGHT_ORDER_ACTION_OPTIONS,
  CAN_RAISE_VOID_REQUEST_FOR_ORDER,
  CAN_RAISE_VOID_AND_DUPLICATE_REQUEST_FOR_ORDER,
  CLEAR_ORDER, SHOULD_FETCH_ORDER, CLICKED_OPTION,
  SET_SELECTED_ALLOCATION_ID,
  CAN_CLOSE_OUT_FOR_ORDER,
  ALLOCATED_CONTRACT_ORDER_WARNING,
  CAN_ASSIGN_PARENT_FOR_ORDER,
} from '../../actions/companies/orders';
import {ORDER_CONFIRMATION_SUCCESS, ORDER_REJECTION_SUCCESS} from "../../actions/companies/freights";

const initialState = {
  items: [],
  types: [],
  paymentTerms: [],
  statuses: [],
  tolerances: [],
  selectedOrderId: undefined,
  selectedAllocationId: undefined,
  selectedOrder: undefined,
  selectedAllocation: undefined,
  serverErrors: [],
  cogOrders: [],
  confirmedOrRejectedOrder: undefined,
  subItems: undefined,
  canRaiseVoidRequestForOrder: false,
  shouldFetchOrder:false,
  clickedOption: null,
  canCloseOutForOrder: false,
  canAssignParentForSelectedFreightOrder: false,
  orderWarningFlag: false,
  orderWarningList: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case ALLOCATED_CONTRACT_ORDER_WARNING:
    return {
      ...state,
      orderWarningFlag: action.orderWarningFlag,
      orderWarningList: action.orderWarningList,
    };
  case GET_ORDERS_SUCCESS:
    return {
      ...state,
      items: action.orders,
      serverErrors: [],
    };
  case GET_PAGINATED_FREIGHT_ORDERS_SUCCESS:
    return {
      ...state,
      items: action.paginatedOrders.results,
      paginationData: action.paginatedOrders,
      serverErrors: [],
    };
  case GET_PAGINATED_ORDERS_SUCCESS:
    return {
      ...state,
      cogOrders: action.paginatedOrders.results,
      paginationData: action.paginatedOrders,
      serverErrors: [],
    };
  case SET_SELECTED_ORDER_ID:
    return {
      ...state,
      selectedOrderId: action.orderId,
    };
  case GET_ORDER_FIXTURES_SUCCESS:
    return {
      ...state,
      types: action.fixtures.freightContractType,
      paymentTerms: action.fixtures.paymentTerm,
      statuses: action.fixtures.statuses,
      tolerances: action.fixtures.tolerance,
      serverErrors: [],
    };
  case ADD_ORDER_FAILURE:
    return {
      ...state,
      serverErrors: action.errors,
    };
  case START_FETCHING_SELECTED_ORDER:
    return {
      ...state,
      isFetchingSelectedOrder: true,
    };
  case RESET_IS_FETCHING_SELECTED_ORDER:
    return {
      ...state,
      isFetchingSelectedOrder: false,
    };
  case RECEIVE_ORDER:
    return {
      ...state,
      selectedOrder: action.item,
      selectedOrderId: action.item ? action.item.id : null,
      serverErrors: [],
      isFetchingSelectedOrder: false,
    };
  case RECEIVE_ALLOCATION:
    return {
      ...state,
      selectedAllocation: action.item,
      selectedAllocationId: action.item ? action.item.id : null,
      serverErrors: [],
    };
  case ORDER_CONFIRMATION_SUCCESS:
    return {
      ...state,
      confirmedOrRejectedOrder: action.order,
      serverErrors: action.errors,
    };
  case ORDER_REJECTION_SUCCESS:
    return {
      ...state,
      confirmedOrRejectedOrder: action.order,
      serverErrors: action.errors,
    };
  case FREIGHT_ORDER_ACTION_OPTIONS:
    return {
      ...state,
      subItems: action.subItems,
    };
  case CAN_RAISE_VOID_REQUEST_FOR_ORDER:
    return {
    ...state,
    canRaiseVoidRequestForOrder: action.flag,
  };
  case CAN_RAISE_VOID_AND_DUPLICATE_REQUEST_FOR_ORDER:
    return {
      ...state,
      canRaiseVoidAndDuplicateRequestForOrder: action.flag,
    };
  case CLEAR_ORDER:
    return {
    ...state,
    canRaiseVoidRequestForOrder: false,
    shouldFetchOrder: false,
    selectedOrder: undefined,
  };
  case SHOULD_FETCH_ORDER:
    return {
      ...state,
      shouldFetchOrder: action.flag,
    };
  case CLICKED_OPTION:
    return {
      ...state,
      clickedOption: action.option,
    };
  case SET_SELECTED_ALLOCATION_ID:
      return {
        ...state,
        selectedAllocationId: action.orderId,
      };
  case CAN_CLOSE_OUT_FOR_ORDER:
    return {
      ...state,
      canCloseOutForOrder: action.flag,
    };
  case CAN_ASSIGN_PARENT_FOR_ORDER:
    return {
      ...state,
      canAssignParentForSelectedFreightOrder: action.flag,
    };
  default:
    return {
      ...state
    };
  }
};

export default reducer;
