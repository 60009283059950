import React from 'react';
import { connect } from 'react-redux';
import GenericTable from '../components/GenericTable';
import { convertEpochToDateFormat } from '../common/momentUtilities';
import { isLoading } from '../actions/main';
import {
  getPaginatedInvoicesResponse,
  getInvoicesWeb, handleInvoicesOptionsMapper
} from '../actions/companies/invoices';
import capitalize from 'lodash/capitalize';
import {
  toDateFormat, regeneratePDF,
} from "../common/utils";
import APIService from '../services/APIService';
import includes from 'lodash/includes';
import {INVOICES_TABLE_COLUMN_LIMIT, INVOICE_TABLE_GLOBAL_LISTING_HEADERS} from '../common/constants';
import { getActionMenuOptions } from '../components/invoices/utils';
import { get, find, isEmpty} from 'lodash';
import InvoicesActions from '../components/invoices/InvoicesActions';
import { getCustomColumns } from '../actions/companies/index';

class InvoicesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: "",
    };
  }

  componentDidMount() {
    this.props.getCustomColumns('invoices_table');
  }

  closeAddSideForm = () =>{
    this.setState({item: undefined});
  };

  openAddSideForm = item =>{
    this.setState({item: item});
  };

  render() {

    return (
      <div>
        <GenericTable
          {...this.props}
          items={this.props.warehouseInvoices || this.props.items}
          paginationData={this.props.warehouseInvoicePaginationData || this.props.paginationData}
          openAddSideForm={this.openAddSideForm}
          closeAddSideForm={this.closeAddSideForm}
        />
        <InvoicesActions {...this.props} />
      </div>
    );
  }

}


const mapDispatchToProps = dispatch => {
  return {
    handleOptionClick: (event, item, baseEntity) => {
      if(get(item, 'key') === 'regenerate_pdf' && get(baseEntity, 'id')) {
        const service = APIService.invoices(baseEntity.id).appendToUrl('regenerate-pdfs/');
        return regeneratePDF(service);
      } else {
        dispatch(handleInvoicesOptionsMapper(event, item, baseEntity, null,'invoiceTable'));
      }
    },
    handleDefaultCellClick: invoice => {
      dispatch(isLoading('invoiceDetail'));
      document.location.hash = `/invoices/${invoice.id}/details`;
    },
    navigateTo: (url, params) => {
      const order_id = get(params, 'order_id', '');
      const contract_id = get(params, 'contract_id', '');
      dispatch(getInvoicesWeb(contract_id, url, null, true, null, null, order_id));
    },
    changePageSize: (url, pageSize) => {
      if (includes(url, '?')){
        url = `${url}&page_size=${pageSize}`;
      } else {
        url = `${url}?page_size=${pageSize}`;
      }
      dispatch(getInvoicesWeb(null, url, null, true));
    },
    getSearchSortUrl: (pageSize, page, searchText, orderBy, order, _, includeVoid) => {
      const service = APIService.invoices();
      service.appendToUrl(`web/?page_size=${pageSize}`);

      if (page) {
        service.appendToUrl(`&page=${page}`);
      }
      if(searchText) {
        service.appendToUrl(`&search=${searchText}`);
      }
      if(orderBy) {
        service.appendToUrl(`&order_by=${orderBy}&order=${order}`);
      }
      if(includeVoid) {
        service.appendToUrl(`&include_void=${includeVoid}`);
      }

      return service.URL;
    },
    getCustomColumns: tableType => dispatch(getCustomColumns(tableType)),
    dispatch
  };
};

const mapStateToProps = state => {
  get(state.companies, 'invoices.invoices', []).forEach((i) => {
    i.dueDate = convertEpochToDateFormat(i.paymentDueDate);
    i['paidOn'] = i.paymentDate ? toDateFormat(i.paymentDate) : '-';
    i['createdOn'] = toDateFormat(new Date(i.createdAt));
    i['status'] = capitalize(i.status);
    if(i['type'] === 'Freight'){
      i['type'] = 'Freight Invoice';
    }
    else if(i['type'] === 'WarehouseFee' || i['type'] === 'Warehouse Fee Invoice'){
      i['type'] = 'Warehouse Fee Invoice';
    }
    else{
      let invoiceType = 'Commodity Contract Invoice';
      i['type'] = i.type === invoiceType || i.type === 'Brokerage' || i.type ==='Freight Invoice' ? i.type : invoiceType;
    }
  });
  const clickedOption = state.companies.invoices.clickedOption;
  let customColumns = [...INVOICE_TABLE_GLOBAL_LISTING_HEADERS];
  const tableColumnsOrder = get(state.companies, 'companies.tableColumns');
  if (tableColumnsOrder && !isEmpty(tableColumnsOrder)) {
    customColumns = [];
    tableColumnsOrder.forEach(val => {
      const obj = find(INVOICE_TABLE_GLOBAL_LISTING_HEADERS, {key: val});
      if (obj) {
        customColumns.push(obj);
      }
    });
  }
  if (customColumns.length > INVOICES_TABLE_COLUMN_LIMIT) {
    customColumns = customColumns.splice(0, INVOICES_TABLE_COLUMN_LIMIT);
  }

  return {
    displayIDColumn: 'identifier',
    clearSearch: getPaginatedInvoicesResponse,
    columns: customColumns,
    globalSearch: true,
    handleDefaultCellClick: true,
    items: state.companies.invoices.invoices,
    optionsItems: getActionMenuOptions,
    order: 'desc',
    orderBy: 'createdAt',
    paginationData: state.companies.invoices.paginationData,
    rowHighlightedMap: {'isHighlighted': 'delhi-blue'},
    scrollToTopOnUpdate: false,
    selectedInvoice: state.companies.invoices.selectedInvoice,
    canAddPayment: state.companies.invoices.canAddPayment,
    selectedInvoiceId: state.companies.invoices.selectedInvoiceId,
    useNestedOptionMenu: true,
    invoiceId: state.companies.invoices.invoiceId,
    isPdfDownloadable: state.companies.invoices.isPdfDownloadable,
    flag: state.companies.invoices.flag,
    token: state.main.user.token,
    canMarkComplete: state.companies.invoices.canMarkComplete,
    voidFilter: true,
    clickedOption
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesTable);
