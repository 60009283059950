import { connect } from 'react-redux';
import GenericTable from '../components/GenericTable';
import { getApprovedBuyers, deleteApprovedBuyers } from '../actions/company-settings/approved-buyers';
import APIService from '../services/APIService';
import { includes } from 'lodash';
import { currentUserCompany, getCompanyIdFromCurrentRoute, isSiteEmployee } from '../common/utils';

const mapStateToProps = state => {
  return {
    columns: [
      { key: 'name', header: 'Company Name', className: 'small' },
      { key: 'abn', header: 'ABN', className: 'xsmall' },
      { key: 'mobile', header: 'Phone', className: 'xsmall' },
      { key: 'address', header: 'Address', className: 'xsmall' },
    ],
    items: state.companySettings.approvedBuyers.items,
    scrollToTopOnUpdate: false,
    handleDefaultCellClick: false,
    optionsItems: isSiteEmployee() ? [] : [{ key: 'delete', text: 'Remove' }],
    clearSearch: getApprovedBuyers,
    paginationData: state.companySettings.approvedBuyers.paginationData,
    globalSearch: true,
    displayIDColumn: 'name'
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleOptionClick: (index, key, id, item) => {
      if (key == 'delete') {
        dispatch(deleteApprovedBuyers(item.id));
      }
    },

    navigateTo: url => {
      dispatch(getApprovedBuyers(true, url));
    },

    changePageSize: (url, pageSize) => {
      if (includes(url, '?')) {
        url = `${url}&page_size=${pageSize}`;
      } else {
        url = `${url}?page_size=${pageSize}`;
      }
      dispatch(getApprovedBuyers(true, url));
    },
    getSearchSortUrl: (pageSize, page, searchText, orderBy, order) => {
      const approvedBuyerService = APIService.companies(getCompanyIdFromCurrentRoute() || currentUserCompany().id);
      approvedBuyerService.appendToUrl('web/approved-buyers/');
      approvedBuyerService.appendToUrl(`?page_size=${pageSize}`);
      if (page) {
        approvedBuyerService.appendToUrl(`&page=${page}`);
      }
      if (searchText) {
        approvedBuyerService.appendToUrl(`&search=${searchText}`);
      }
      if (orderBy) {
        approvedBuyerService.appendToUrl(`&order_by=${orderBy}&order=${order}`);
      }

      return approvedBuyerService.URL;
    },
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GenericTable);
