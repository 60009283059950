import { connect } from 'react-redux';
import GenericTable from '../components/GenericTable';
import { clickEditSpecialConditionIcon } from '../actions/companies/conditions';

const mapStateToProps = (state) => {
  var conditions = state.companies.conditions.specialConditions;
  conditions.forEach(condition => {
    condition.defaultDisplayText = condition.default ? 'Yes' : 'No';
  });
  return {
    columns: [
      { key: 'module', header: 'Type', className: 'xlarge capitalize'},
      { key: 'name', header: 'Name', className: 'xlarge'},
      { key: 'details', header: 'Details', className: 'xlarge'},
      { key: 'defaultDisplayText', header: 'Default', className: 'small'},
    ],
    items: state.companies.conditions.specialConditions,
    noRecordFoundText : 'No Special Condition Exists. Create your first Special Condition.',
    scrollToTopOnUpdate: false,
    editColumnClass: 'xsmall',
    orderBy: 'type',
    order: 'asc',
    displayIDColumn: 'name'
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    handleDefaultCellClick: (rowNum) => {
        dispatch(clickEditSpecialConditionIcon(rowNum));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericTable);
