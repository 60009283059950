import React from 'react';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import set from 'lodash/set';
import get from 'lodash/get';
import isNaN from 'lodash/isNaN';
import mapValues from 'lodash/mapValues';
import includes from 'lodash/includes';
import { positiveDecimalFilter } from '../../common/input-filters';
import CommonTextField from '../common/CommonTextField';
import { startsWith } from 'lodash';
import { Button, Divider } from '@mui/material';
import { PRIMARY_SPECS_LIMIT } from '../../common/constants';

class SpecParametersValue extends React.Component {
  constructor(props) {
    super(props);
    this.specsSetUpFlag = true;
    this.state = {
      specs: this.getStateSpecsByCommoditySpecs(props.commoditySpecs),
      showMoreSpecs: false
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.commoditySpecs, prevProps.commoditySpecs)) {
      const newStateSpecs = this.getStateSpecsByCommoditySpecs(this.props.commoditySpecs);
      this.setState(state => ({ ...state, specs: newStateSpecs }));
    }
    if (!isEqual(this.props.fieldsSpecs, prevProps.fieldsSpecs) || this.specsSetUpFlag) {
      this.specsSetUpFlag = false;
      const newStateSpecs = this.getStateSpecsBySpecsFields(this.props.fieldsSpecs);
      if (!isEqual(this.state.specs, newStateSpecs)) {
        this.setState(state => ({ ...state, specs: newStateSpecs }));
      }
    }
  }

  handleChange(event) {
    const specCode = event.target.id;
    const specValue = isNaN(event.target.value) ? null : event.target.value;
    const newState = {...this.state};
    set(newState.specs, specCode, specValue);
    this.setState(
      newState,
      () => this.props.onChange(
        specCode, specValue && specValue !== '.' ? parseFloat(specValue) : null
      )
    );
  }

  getStateSpecsByCommoditySpecs(commoditySpecs) {
    const stateSpecs = {};

    if (!isEmpty(commoditySpecs)) {
      forEach(commoditySpecs, (spec) => {
        stateSpecs[spec.code] = '';
      });
    }

    return stateSpecs;
  }

  getStateSpecsBySpecsFields(specsFields) {
    const stateSpecs = this.state.specs;

    if (!isEmpty(specsFields)) {
      return mapValues(specsFields, specField => get(specField, 'value', specField));
    }

    return stateSpecs;
  }

  render() {
    const { showSecondarySpecs } = this.props;
    const primarySpecs = this.state.specs ? Object.fromEntries(Object.entries(this.state.specs).slice(0, PRIMARY_SPECS_LIMIT)) : {};
    const secondarySpecs = this.state.specs ? Object.fromEntries(Object.entries(this.state.specs).slice(PRIMARY_SPECS_LIMIT)) : {};
    return (
      <React.Fragment>
      {
      map(primarySpecs, (value, specCode) => {
        return (
            <div className={get(this.props, 'className') ? this.props.className : "col-xs-4 form-wrap"} style={get(this.props, 'style')} key={specCode}>
              <CommonTextField
                id={specCode}
                name={specCode}
                label={`${specCode}${this.props.allOptional ? ' (Optional)' : get(this.props.fieldsSpecs, `[${specCode}].validators`, []).length > 1 ? '' : includes(['IMPU', 'COIL'], specCode) ? '': ' (Optional)'}`}
                value={this.state.specs[specCode]}
                onChange={this.handleChange}
                helperText={get(this.props, `errorTexts.${specCode}`, '')}
                additionalHelperText={get(this.props, `warningTexts.${specCode}`, '')}
                onKeyDown={ event => positiveDecimalFilter(event, 2, 9999.99) }
                disabled={this.props.disabled}
                showWarningColorForWarningText={startsWith(get(this.props, `warningTexts.${specCode}`, ''), 'GTA') && !get(this.props, `errorTexts.${specCode}`, '')}
              />
            </div>
        );
      })}
      {
        showSecondarySpecs && Object.entries(secondarySpecs).length !== 0 &&
        <Button size="small" onClick={() => this.setState({showMoreSpecs: !this.state.showMoreSpecs})} color="primary" style={{display: "flex", justifyContent: "flex-end", width: '100%', paddingRight: '15px'}}>
          {this.state.showMoreSpecs ? 'Show Less' : 'Show More'}
        </Button>
      }
      {this.state.showMoreSpecs &&
      <>
        <Divider style={{display: 'inline-block', width: '96%', marginLeft: '15px', marginBottom: '25px'}} />
        {
        map(secondarySpecs, (value, specCode) => {
          return (
              <div className={get(this.props, 'className') ? this.props.className : "col-xs-4 form-wrap"} style={get(this.props, 'style')} key={specCode}>
                <CommonTextField
                  id={specCode}
                  name={specCode}
                  label={`${specCode}${this.props.allOptional ? ' (Optional)' : get(this.props.fieldsSpecs, `[${specCode}].validators`, []).length > 1 ? '' : includes(['IMPU', 'COIL'], specCode) ? '': ' (Optional)'}`}
                  value={this.state.specs[specCode]}
                  onChange={this.handleChange}
                  helperText={get(this.props, `errorTexts.${specCode}`, '')}
                  additionalHelperText={get(this.props, `warningTexts.${specCode}`, '')}
                  onKeyDown={ event => positiveDecimalFilter(event, 2, 9999.99) }
                  disabled={this.props.disabled}
                  showWarningColorForWarningText={startsWith(get(this.props, `warningTexts.${specCode}`, ''), 'GTA') && !get(this.props, `errorTexts.${specCode}`, '')}
                />
              </div>
          );
        })}
      </>
      }
    </React.Fragment>
    );
  }
}

export default SpecParametersValue;
